import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManPowerUnassignService } from './man-power-unassign.service';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';
import * as _ from 'lodash';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-man-power-unassign',
  templateUrl: './man-power-unassign.component.html',
  styleUrls: ['./man-power-unassign.component.css']
})
export class ManPowerUnassignComponent implements OnInit {
  @ViewChild('manPowerAssignViewRef') public manPowerAssignViewRef: any;
  @ViewChild('manPowerAssignEditRef') public manPowerAssignEditRef: any;
  @ViewChild('filterRef') public filterRef: any;

  // tslint:disable-next-line: no-inferrable-types
  public dateRangeText: string = '';

  manPowerList = [];
  modalRef: NgbModalRef = null;
  selectedDateForView = null;
  selectedShift = null;
  selectedShifts = [];
  selectedManpower = null;
  editFurtherDates = [];

  headerDateList = [];
  editHeaderDateList = [];

  todayDate = new Date();
  firstDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 1);
  lastDay = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, 0);
  fromDate: any;
  toDate: any;

  constructor(
    private manPowerUnassignService: ManPowerUnassignService,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.fromDate = this.datePipe.transform(this.firstDay, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(this.lastDay, 'yyyy-MM-dd');

    this.getManPowerList();
  }

  getMonthWithDay(startDate: string, endDate: string): string {
    const startDay = moment(startDate).date();
    const endDay = moment(endDate).date();
    const actualStartDate = moment(startDate).format('MMMM').slice(0, 3);
    const actualEndDate = moment(endDate).format('MMMM').slice(0, 3);

    return `${actualStartDate}-${startDay} to ${actualEndDate}-${endDay}`;
  }

  getManPowerList(filterJson?) {

    let filter;
    if (filterJson) {
      this.fromDate = new Date(filterJson.Sdate);
      this.toDate = new Date(filterJson.Edate);
      filter = {
        UserId: GLOBAL.USER_ID,
        Startdate: filterJson.Sdate,
        Enddate: filterJson.Edate,
        // ShiftTypeGroup_Code: filterJson.ShiftTypeGroup_Code,
        // Plant_Code: filterJson.Plant_Code,
        // Company_Code: filterJson.Company_Code,
      };
    } else {
      filter = {
        UserId: GLOBAL.USER_ID,
        Startdate: this.fromDate,
        Enddate: this.toDate,
        // ShiftTypeGroup_Code: null,
        // Plant_Code: null,
        // Company_Code: null,
      };
    }


    const MANPOWER_REQ_ADDREMOVE_INPUT = {

      Filter: filter,
      Fields: [
        {
          KeyName: 'Plant_Code',
          DisplayName: 'PlantName'
        },
        {
          KeyName: 'Unit_Code',
          DisplayName: 'UnitName'
        },
        {
          KeyName: 'UnitDeptID',
          DisplayName: 'DepartmentName'
        },
        {
          KeyName: 'SectionRelId',
          DisplayName: 'Section3'
        },
        {
          KeyName: 'CategoryWorkmenMIL_Code',
          DisplayName: 'Category'
        },
        {
          KeyName: 'Shift_Code',
          DisplayName: 'ShiftName'
        },
        {
          KeyName: 'Date',
          DisplayName: '',
          ExtraFields: [
            'TotalRequirement']
        },
        {

          KeyName: 'RequestedBy_Code',
          DisplayName: 'RequestedBy',
          ExtraFields: [
            'Requirement']

        },
        {
          KeyName: 'Contractor_Code',
          DisplayName: 'ContractorName',
          ExtraFields: [
            'Allot']
        }
      ]

    };

    this.dateRangeText = this.getMonthWithDay(this.fromDate, this.toDate);

    this.manPowerUnassignService.getMonthlyRequition(MANPOWER_REQ_ADDREMOVE_INPUT).subscribe((res) => {
      this.generateList(res);
    });
  }

  generateList(res) {
    const tempManPowerList = [];
    const tempHeaderDateList = [];
    res.forEach((plant) => {
      plant.Values.forEach((unit) => {
        unit.Values.forEach((department) => {
          department.Values.forEach((section3) => {
            section3.Values.forEach((category) => {
              const data = {
                Plant_Code: plant.Plant_Code,
                PlantName: plant.PlantName,
                Unit_Code: unit.Unit_Code,
                UnitName: unit.UnitName,
                UnitDeptID: department.UnitDeptID,
                DepatmentName: department.DepartmentName,
                SectionRelId: section3.SectionRelId,
                Section3: section3.Section3,
                CategoryWorkmenMIL_Code: category.CategoryWorkmenMIL_Code,
                Category: category.Category,
                rowSpan: category.Values.length + 1,
                Shifts: category.Values,
                totalDates: [],
                totalMap: {}
              };

              const totalDates = [];
              const totalMap = {};

              category.Values.forEach((shift) => {

                const tempShiftValues = [];
                const tmpFromDate = new Date(moment(this.fromDate).format('YYYY-MM-DDT00:00:00'));
                const tmptoDate = new Date(moment(this.toDate).format('YYYY-MM-DDT00:00:00'));
                while (tmpFromDate <= tmptoDate) {
                  const fromDateStr = moment(tmpFromDate).format('YYYY-MM-DDT00:00:00');
                  let shiftDateFound = shift.Values.find(item => item.Date === fromDateStr);
                  let shiftDate = fromDateStr;

                  if (shiftDateFound) {
                    shiftDate = shiftDateFound.Date;
                  } else {
                    shiftDateFound = { skipColumns: 1 };//Date: new Date(tmpFromDate)
                  }

                  if (totalDates.indexOf(shiftDate) === -1) {
                    totalDates.push(shiftDate);
                  }

                  let total = totalMap[shiftDate];
                  if (!total) {
                    total = 0;
                  }

                  if (!shiftDateFound.skipColumns) {
                    shiftDateFound.dateObj = new Date(shiftDate);
                    if (shiftDateFound.dateObj && shiftDateFound.dateObj > this.todayDate) {
                      shiftDateFound.canEdit = true;
                    } else {
                      shiftDateFound.canEdit = false;
                    }

                    shiftDateFound.Values.forEach((req) => {
                      req.rowSpan = req.Values.length + 1;
                    });

                    total += shiftDateFound.TotalRequirement;
                  }

                  totalMap[shiftDate] = total;
                  tempShiftValues.push(shiftDateFound);

                  tmpFromDate.setDate(tmpFromDate.getDate() + 1);
                }

                shift.Values = tempShiftValues;
              });

              data.totalDates = totalDates;
              data.totalMap = totalMap;

              tempManPowerList.push(data);
            });
          });
        });
      });
    });

    const tmpFromDate = new Date(moment(this.fromDate).format('YYYY-MM-DDT00:00:00'));
    const tmptoDate = new Date(moment(this.toDate).format('YYYY-MM-DDT00:00:00'));
    while (tmpFromDate <= tmptoDate) {
      tempHeaderDateList.push(moment(tmpFromDate).format('YYYY-MM-DDT00:00:00'));
      tmpFromDate.setDate(tmpFromDate.getDate() + 1);
    }

    this.manPowerList = tempManPowerList;
    this.headerDateList = tempHeaderDateList;
  }

  onView(dateObj, shift, manPower) {
    this.selectedDateForView = dateObj;
    this.selectedShift = shift;
    this.selectedManpower = manPower;
    this.modalRef = this.modalService.open(this.manPowerAssignViewRef,
      { size: 'lg', backdrop: 'static' });
  }

  onCloseViewModel() {
    this.selectedDateForView = null;
    this.selectedShift = null;
    this.selectedManpower = null;
    this.modalRef.close('click');
  }

  onEdit(shift, manPower, dateIndex) {
    this.selectedShift = shift;
    this.selectedManpower = manPower;
    this.editFurtherDates = this.selectedShift.Values.slice(dateIndex, this.selectedShift.Values.length);
    this.selectedShift.Values.forEach((date) => {
      date.reduceCount = 0;
    });
    this.modalRef = this.modalService.open(this.manPowerAssignEditRef,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-full-size' });
  }

  onBulkEdit(manPower) {
    this.selectedShifts = manPower.Shifts;
    manPower.Shifts.forEach((shift) => {
      shift.Values.forEach((date) => {
        date.reduceCount = 0;
      });
    });
    this.selectedManpower = manPower;
    this.editFurtherDates = null;
    this.modalRef = this.modalService.open(this.manPowerAssignEditRef,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-full-size' });
  }

  onCloseEditModel() {
    this.selectedDateForView = null;
    this.selectedShift = null;
    this.selectedShifts = [];
    this.selectedManpower = null;
    this.modalRef.close('click');
  }

  applyReduceToAll(index, date, shiftDates) {
    const reduceCount = date.reduceCount;

    if (!reduceCount || !reduceCount.trim()) {
      return;
    }

    const reduceCountNumb = parseInt(reduceCount, 10);
    for (let i = index; i < shiftDates.length; i++) {
      const tempDate = shiftDates[i];
      if (tempDate.Date && tempDate.TotalRequirement && reduceCountNumb <= tempDate.TotalRequirement) {
        tempDate.reduceCount = `${reduceCountNumb} `;
      }
    }
  }

  openFilter() {
    this.modalRef = this.modalService.open(this.filterRef,
      { size: 'lg', backdrop: 'static' });
  }

  public onSubmitFilter(value: any): void {
    this.modalRef.close('click');
    this.getManPowerList(value.JSONText);

    //  this.getRequistionData(value);
    //  this.getManPowerAllocation(value);
    // this.selectedDate = value.JSONText.Date;
    // this.getPlantData(value);
  }

  generateManpowerShiftRelations(list, manpower, shift, shiftDates) {
    for (let i = 0; i < shiftDates.length; i++) {
      const tempDate = shiftDates[i];
      const reduceCount = tempDate.reduceCount;
      if (reduceCount === '' || reduceCount > tempDate.TotalRequirement) {
        return false;
      }

      // if (reduceCount > 0) {
      list.push({
        Shift_Code: shift.Shift_Code,
        CategoryWorkmenMIL_Code: manpower.CategoryWorkmenMIL_Code,
        // Date: moment(tempDate.Date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        Date: moment(tempDate.Date).format('YYYY-MM-DD'),
        Total: reduceCount,
        Section_Code: manpower.SectionRelId
      });
      // }
    }

    return true;
  }

  onSave() {
    const model = {
      ManPowerType: 856,
      Company_Code: 101,
      Plant_Code: this.selectedManpower.Plant_Code,
      Unit_Code: this.selectedManpower.Unit_Code,
      Department_Code: this.selectedManpower.UnitDeptID,
      SectionCodes: [
        this.selectedManpower.SectionRelId
      ],
      dateRange: null,
      CategoryWorkmenMIL_Code: [
        this.selectedManpower.CategoryWorkmenMIL_Code
      ],
      WeekOffExclude: '',
      HolidayExclude: '',
      StartDate: '',
      EndDate: '',
      Status: 'Submitted',
      ManpowerShiftRelations: [],
      UserId: Number(GLOBAL.USER_ID),
      RoleCode: Number(localStorage.getItem('RoleCode'))
    };

    if (this.editFurtherDates) {
      const reduceData = this.editFurtherDates.filter(a=>a.reduceCount!=0).map(a=>a.Date);
      model.StartDate = moment( _.min(reduceData)).format(this.sharedService.datePickerSettings.requestFormat);
      model.EndDate =  moment( _.max(reduceData)).format(this.sharedService.datePickerSettings.requestFormat);
      // model.EndDate = moment(this.editFurtherDates[this.editFurtherDates.length - 1].Date)
      //   .format(this.sharedService.datePickerSettings.requestFormat);
      const isSuccess = this.generateManpowerShiftRelations(
        model.ManpowerShiftRelations,
        this.selectedManpower,
        this.selectedShift,
        this.editFurtherDates
      );
      if (!isSuccess) {
        alert('Please insert value and should be less then TotalRequirement');
        return false;
      }
    } else {
      for (let i = 0; i < this.selectedManpower.Shifts.length; i++) {
        const shift = this.selectedManpower.Shifts[i];
        if (i === 0) {
          const startDate = shift.Values.find(item => item.canEdit).Date;
          model.StartDate = moment(startDate).format(this.sharedService.datePickerSettings.requestFormat);
          model.EndDate = moment(shift.Values[shift.Values.length - 1].Date)
            .format(this.sharedService.datePickerSettings.requestFormat);
        }
        const isSuccess = this.generateManpowerShiftRelations(
          model.ManpowerShiftRelations,
          this.selectedManpower,
          shift,
          shift.Values
        );
        if (!isSuccess) {
          alert('Please insert value and should be less then TotalRequirement');
          return false;
        }
      }
    }

    console.log('model', model);

    this.manPowerUnassignService.approveAndSavePowerRequisition(model).subscribe((response: any) => {
      if (response) {
        this.onCloseEditModel();
        this.getManPowerList();
      }
    });
  }
}
