import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import {
  AgFilterCellRenderComponent
} from '../../core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { ManPowerService } from '../man-power.service';
import { GridOptions, GridApi } from 'ag-grid-community';
import {
  ReportingDateTimeGridCellRendererComponent
} from '../reporting-date-time-grid-cell-renderer/reporting-date-time-grid-cell-renderer.component';
import { ModalConfig, BackdropColor, ModelDialogClass, ManPowerList } from '../model/man-power.model';
import { SelectStepsComponent } from '../../core/components/select-steps/select-steps.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { AgNumberCountComponent } from 'src/app/core/components/grid-wrapper/helper/ag-number-count/ag-number-count.component';
import { PlantMaster, SectionList } from '../man-power.modal';
import { DropDownCellEditorComponent } from '../drop-down-cell-editor/drop-down-cell-editor.component';
import { GLOBAL } from 'src/app/app.globals';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyManPowerContentComponent } from './daily-man-power-content/daily-man-power-content.component';
import {
  AgFilterManPowerAssignAllocationComponent
} from './ag-filter-man-power-assign-allocation/ag-filter-man-power-assign-allocation.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';

const itemsUnit: any[] = [];
const itemsShift: any[] = [];
const itemsNatureOfWork: any[] = [];
const itemsDepartMentWork: any[] = [];
const itemsSectionWork: any[] = [];
const subject: Subject<any> = new Subject();
const plantSubject: Subject<any> = new Subject();

@Component({
  selector: 'app-daily-man-power-location-assign',
  templateUrl: './daily-man-power-location-assign.component.html',
  styleUrls: ['./daily-man-power-location-assign.component.css']
})

export class DailyManPowerLocationAssignComponent implements OnInit {
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  public selectedDate: any;
  public alertOption: SweetAlertOptions = {};
  public alertText: string;
  public itemsPlant = [];
  private modalRef: NgbModalRef;
  private plantCode: number;
  private parentSkillCode: number;
  private tradeCode: number;
  private unitCode: number;
  private itemPlantFieldValue: string;
  private itemSectionFieldValue: string;
  private itemShieftFieldValue: string;
  public itemsUnit = [];
  private sectionData = [];
  public itemsDerpartment = [];
  private itemShiftData = [];
  public contractorCode: any;
  public config: any;
  public gridAPI: GridApi;
  public stepsData: any[];
  public manPowerList: any[] = [];
  public labourTrainingDetailData: any[] = [];
  public manPowerColumnDefs: any[];
  public requistionColumnDefs: any;
  public contractorColumnDefs: any[];
  public workOrderColumnDefs: any[];
  public numberOfRowsPerPage = 10;
  public rowData: any;
  public formGroup: FormGroup;
  public requistionRowData: any[];
  private componentRef: any;
  private workOrderCode: string;
  public rowIndex: number;
  private natureOfWork: any[];
  private skill: any[];
  total: number;
  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };
  private unitDepartmentSectionTreeData: any[] = [];
  // defaultColDef: {
  //   editable: false,
  //   enableRowGroup: true,
  //   enablePivot: true,
  //   enableValue: true,
  //   sortable: true,
  //   resizable: true,
  //   filter: true
  // },
  /** this grid option is use inside the ag grid lib to config */
  public gridOptions: GridOptions = <GridOptions>{
    // suppressRowClickSelection: true,
    //   alwaysShowVerticalScroll: true,
    suppressHorizontalScroll: false,
    // groupSelectsChildren: true,
    // debug: true,
    // rowSelection: 'multiple',
    // rowGroupPanelShow: 'always',
    // pivotPanelShow: 'always',
    // enableRangeSelection: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      // params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      // params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };


  public requistionGridOptions: GridOptions = <GridOptions>{
    suppressHorizontalScroll: false,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    onGridReady: (params) => {
      this.gridAPI = params.api;
    },
    onGridSizeChanged: (params) => {
      // params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };
  constructor(
    private manPowerService: ManPowerService, private formBuilder: FormBuilder, private modalService: NgbModal) {
    this.stepsData = [];
    this.plantCode = 0;
    this.unitCode = 0;
    this.itemSectionFieldValue = '';
    this.itemShieftFieldValue = '';
    this.requistionRowData = [];
    this.workOrderCode = null;
    this.itemPlantFieldValue = '';

    this.contractorColumnDefs = [
      {
        headerName: '#', field: '',
        width: 350, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterManPowerAssignAllocationComponent
      },
      {
        headerName: 'Vendor Code', field: 'ShortCode',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Contractor', field: 'Company',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Contact Name', field: '',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'PAN', field: 'PANNo',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'PF', field: '',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'ESIC', field: 'PANNo',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      {
        headerName: 'Registration State', field: '', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];

    this.workOrderColumnDefs = [
      {
        headerName: '#', field: '',
        width: 350, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Vendor Code', field: 'ShortCode',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Contractor', field: 'Company',
        width: 250, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Work Order No', field: 'WorkOrderNo', width: 350, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Person', field: 'Person', width: 250, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Issue Date', field: 'OrderStartDate', width: 400, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Validity Date', field: 'OrderEndDate', width: 400, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Owner', field: 'Owner', width: 250, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'HOD', field: 'HOD', width: 250, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Status', field: 'Status', width: 250, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.config = {
      contractor: { contractorColumnDefs: this.contractorColumnDefs, labelText: 'Contractor', placeholderText: 'Select Contractor' },
      workOrder: { workOrderColumnDefs: this.workOrderColumnDefs, labelText: 'Work Order', placeholderText: 'Select Work Order' },
    };
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      selectedSection: ['', Validators.required],
      inPunch: ['', Validators.required],
      outPunch: ['', Validators.required]
    });
    this.manPowerService.subject.subscribe((value: any) => {
      this.stepsComp.openModel();
    });
    this.setStepArray();
  }

  public onDateChange($event: any): void {

  }

  public onSubmit(value: any) {

    if (value.fieldData.hasOwnProperty('contractor')) {
      this.contractorCode = value.fieldData.contractor.ContractorVendor_Code;
    }
    if (value.fieldData.hasOwnProperty('workOrder')) {
      this.workOrderCode = value.fieldData.workOrder.WorkOrder_Code;
    }
  }

  public onSubmitAction(value: any) {

  }

  public onSubmitFilter(value: any): void {

    //  this.getRequistionData(value);
    // this.getManPowerAllocation(value);
    this.selectedDate = value.JSONText.Date;
    this.getPlantData(value);
  }

  public getRequistionData(manPowerList: ManPowerList[], dataItem, shiftRequistionData): void {
    /** Open the popup model */
    // tslint:disable-next-line: max-line-length
    // manPowerList = manPowerList.concat(manPowerList.map(item=> {return {...item}}), manPowerList.map(item=> {return {...item}}), manPowerList.map(item=> {return {...item}}));
    // manPowerList[3].IsApproved = 1;
    // manPowerList[4].IsApproved = 1;
    // manPowerList[5].IsApproved = 3;
    // manPowerList[6].IsApproved = 3;
    // tslint:disable-next-line: prefer-const
    let newList = this.getManpowerListByStatusSort(manPowerList);

    this.modalRef = this.modalService.open(DailyManPowerContentComponent, {
      backdropClass: 'light-blue-backdrop',
      windowClass: 'dark-modal modal-panel', size: 'lg', centered: true,
    });
    this.modalRef.componentInstance.date = this.selectedDate;
    this.modalRef.componentInstance.contractorCode = dataItem.contractorCode;
    this.modalRef.componentInstance.plantCode = dataItem.plantCode;
    this.modalRef.componentInstance.companyCode = dataItem.companyCode;
    this.modalRef.componentInstance.unitDepartmentSectionTreeData = this.unitDepartmentSectionTreeData;
    this.modalRef.componentInstance.itemsPlant = this.itemsPlant;
    this.modalRef.componentInstance.itemsNatureOfWork = this.natureOfWork;
    this.modalRef.componentInstance.manPowerList = newList;
    this.modalRef.componentInstance.manPowerListCopy = newList;
    this.modalRef.componentInstance.labourTrainingDetailData = this.labourTrainingDetailData;
    shiftRequistionData.forEach((item) => {
      item.RemaingCopy = item.Remaing;
    });
    // const shiftRequistionData2 = [
    //   {
    //     "ManpowerRequisiton_Code": 100,
    //     "Shift_Code": 101,
    //     "Requirement": 14,
    //     "CategoryWorkmenMIL_Code": 931,
    //     "Remaing": 5,
    //     "Date": "2020-06-01T00:00:00",
    //     "Allocation": 14,
    //     "Short_Code": 'G102PKING',
    //     "Section_Code": 105
    //   }
    // ];
    this.modalRef.componentInstance.shiftRequistionData = shiftRequistionData;
    this.modalRef.componentInstance.onFormControl(null, true);
    console.log('manPowerList', manPowerList);
    this.modalRef.componentInstance.formGroup = this.formGroup;
    this.modalRef.componentInstance.itemsDerpartment = this.itemsDerpartment;
    this.modalRef.componentInstance.bindCategoryList();
    this.modalRef.componentInstance.bindShortCodeList();
    this.modalRef.componentInstance.bindUnitFilterList();
    this.modalRef.componentInstance.bindShiftFilterList();
  }

  private getManpowerListByStatusSort(manpowerList: any[]): any[] {
    const approvedList = manpowerList.filter(item => item.IsApproved === 1);
    const pendingList = manpowerList.filter(item => item.IsApproved === 2);
    const allocatedList = manpowerList.filter(item => item.IsApproved === 3);

    return [].concat(pendingList, allocatedList, approvedList);
  }

  /**
   * @descriptions Invoke this method when click on expire list.
   */
  private openAlert(): void {

    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

  private getManPowerAllocation(value: any): void | any {

    console.log('getManPowerAllocationValue', value);
    //  const promise = new Promise<any>((resolve, reject) => {
    const dataItem: {
      date: string, shiftTypeGroupCode: number, companyCode: number, plantCode: number, contractorCode: number,
      workOrderCode: number, licenseCode: number, WCPolicy: number, subContractorCode: number, subWorkOrderCode: number,
      subLicenseCode: number, subWCPolicyCode: number
    } = {
      date: (value.JSONText.Date === 'Invalid date') ? '' : value.JSONText.Date,
      shiftTypeGroupCode: value.JSONText.ShiftGroupType_Code ? value.JSONText.ShiftGroupType_Code : null,
      companyCode: value.JSONText.Company_Code ? value.JSONText.Company_Code : null,
      plantCode: value.JSONText.Plant_Code ? value.JSONText.Plant_Code : null,
      // contractorCode: 117,
      contractorCode: value.JSONText.Contractors ? value.JSONText.Contractors : null,
      workOrderCode: value.JSONText.workOrder_Code ? value.JSONText.workOrder_Code : null,
      licenseCode: value.JSONText.license_Code ? value.JSONText.license_Code : null,
      WCPolicy: value.JSONText.WCPolicy ? value.JSONText.WCPolicy : null,
      subContractorCode: value.JSONText.subContractors ? value.JSONText.subContractors : null,
      subWorkOrderCode: value.JSONText.subWorkOrder_Code ? value.JSONText.subWorkOrder_Code : null,
      subLicenseCode: value.JSONText.subLicense_Code ? value.JSONText.subLicense_Code : null,
      subWCPolicyCode: value.JSONText.subWCPolicy ? value.JSONText.subWCPolicy : null,
    };
    //
    const dataItemUnit: { search: string, plantCode: number } = { search: '', plantCode: null };
    const departmentItemUnit: { search: string, plantCode: number, unitCode: number } = { search: '', plantCode: null, unitCode: null };
    const sectionItem: { search: string, plantCode: number, unitCode: number, departmentCode: number } =
      { search: '', plantCode: null, unitCode: null, departmentCode: null };
    // tslint:disable-next-line: max-line-length
    const shiftItem: { search: string, plantCode: number, shiftTypeGroupCode: number } = {
      search: '', plantCode: null, shiftTypeGroupCode: null
    };

    const getManPowerAllocationData = this.manPowerService.getDailyManPowerAssignLocation(dataItem);
    const shiftRequistionData = this.manPowerService.getDailyShiftRequistionData(dataItem);
    console.log('getManPowerAllocationData', getManPowerAllocationData);
    // const unitData = this.manPowerService.unitMaster(dataItemUnit);
    // const departmentData = this.manPowerService.getDepartments(departmentItemUnit.plantCode, departmentItemUnit.unitCode);
    // const sectionTreeData = this.manPowerService.getDepartmentPlantUnitSectionTree(
    //   sectionItem.plantCode, sectionItem.unitCode, sectionItem.departmentCode);
    const PLANT_UNIT_DEP_REQUEST =
    // tslint:disable-next-line:ter-indent
    {
      Filter: {
        UserId: GLOBAL.USER_ID
      },
      Fields: [
        {
          KeyName: 'Plantcode0',
          DisplayName: 'Plant0'
        },
        {
          KeyName: 'Unitcode0',
          DisplayName: 'Unit0'
        },
        {
          KeyName: 'UnitDeptID0',
          DisplayName: 'Department0',
          ExtraFields: [
            'Departmentcode0'
          ]
        },
        {
          KeyName: 'LevelID0',
          DisplayName: 'SectionName0',
          ExtraFields: [
            'Sectioncode0'
          ]
        },
        {
          KeyName: 'LevelID1',
          DisplayName: 'SectionName1',
          ExtraFields: [
            'Sectioncode1'
          ]
        },
        {
          KeyName: 'LevelID2',
          DisplayName: 'SectionName2',
          ExtraFields: [
            'Sectioncode2',
            'Short_Code'
          ]
        }
      ]
    };
    console.log('API eMerge2', PLANT_UNIT_DEP_REQUEST);
    // tslint:disable-next-line:max-line-length
    const unitDepartmentSectionTreeData: any = this.manPowerService.getDepartmentPlantUnitSectionTree(dataItem.plantCode, null, null, PLANT_UNIT_DEP_REQUEST);
    const shiftData = this.manPowerService.shiftMaster(shiftItem);
    // const natureDepartmentData = this.manPowerService.getNatureOfWorks();
    // const skillData = this.manPowerService.getSkills();



    /** Here join all subscribe services */
    // tslint:disable-next-line: deprecation
    forkJoin(getManPowerAllocationData, unitDepartmentSectionTreeData, shiftData, shiftRequistionData).
      subscribe(([getManPowerAllocationData, unitDepartmentSectionTreeData, shiftData, shiftRequistionData]:
        [any, any[], any[], any[]]) => {
        const manpowerdata = getManPowerAllocationData.Table;
        this.total = getManPowerAllocationData.Table.length;
        this.labourTrainingDetailData = getManPowerAllocationData.Table1;
        if (manpowerdata.length > 0) {

          this.unitDepartmentSectionTreeData = unitDepartmentSectionTreeData;

          manpowerdata.forEach((manPowerItem: any, index: number) => {

            const shiftControlKey = manPowerItem.Shift_code || manPowerItem.Shift_Code ? shiftData.filter((shiftData: any) =>
            (manPowerItem.Plant_Code === shiftData.Plant_Code
              && (shiftData.Shift_code === manPowerItem.Shift_Code || shiftData.Shift_Code === manPowerItem.Shift_Code)))[0]
              && shiftData.filter((shiftData: any) =>
                // tslint:disable-next-line: max-line-length
                (manPowerItem.Plant_Code === shiftData.Plant_Code && (shiftData.Shift_code === manPowerItem.Shift_Code || shiftData.Shift_Code === manPowerItem.Shift_Code)))[0].Shift_Code : null;
            // console.log('ashdkjsahdjks', this.getSectionTreeName(sectionTreeData, false,
            //   manPowerItem.Plant_Code, manPowerItem.Unit_Code, manPowerItem.Department_Code, manPowerItem.Section_Code));
            /*
            const plantItems = unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
              .map((e) => { return { id: e.Plant_Code, name: e.Name }; });

            const unitItems = unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
              .find(itm => itm.Plant_Code === dataItem.plantCode).UnitTree
              .map((e) => { return { id: e.Unit_Code, name: e.Name }; });

            const departmentItems = !manPowerItem.Unit_Code ? [] :
              unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
                .find(itm => itm.Plant_Code === dataItem.plantCode).UnitTree
                .find(itm => itm.Unit_Code === manPowerItem.Unit_Code).DepartmentTree
                .map((e) => { return { id: e.Department_Code, name: e.Name }; });

            let sectionCode3 = null;
            let sectionCode2 = null;
            let sectionCode1 = null;
            const sectionItemsTemp = (manPowerItem.Unit_Code && manPowerItem.Department_Code && manPowerItem.Section_Code) ?
              unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
                .find(itm => itm.Plant_Code === dataItem.plantCode).UnitTree
                .find(itm => itm.Unit_Code === manPowerItem.Unit_Code).DepartmentTree
                .find(itm => itm.Department_Code === manPowerItem.Department_Code).SectionTree
                .find((inner1) => {
                  return inner1.SectionList.find((inner2) => {
                    return inner2.SectionList.find((inner3) => {
                      if (inner3.SectionPlantRelationId === manPowerItem.Section_Code) {
                        sectionCode3 = inner3.SectionPlantRelationId;
                        sectionCode2 = inner2.SectionPlantRelationId;
                        sectionCode1 = inner1.SectionPlantRelationId;
                        return true;
                      }
                    });
                  });
                })
              : [];

            manPowerItem.Section_Code1 = sectionCode1;
            manPowerItem.Section_Code2 = sectionCode2;

            const sectionItems1 = (manPowerItem.Unit_Code && manPowerItem.Department_Code) ?
              unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
                .find(itm => itm.Plant_Code === dataItem.plantCode).UnitTree
                .find(itm => itm.Unit_Code === manPowerItem.Unit_Code).DepartmentTree
                .find(itm => itm.Department_Code === manPowerItem.Department_Code).SectionTree
                .map((e) => { return { id: e.SectionPlantRelationId, name: e.Name }; }) : [];

            const sectionItems2 = (manPowerItem.Unit_Code && manPowerItem.Department_Code && manPowerItem.Section_Code1) ?
              unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
                .find(itm => itm.Plant_Code === dataItem.plantCode).UnitTree
                .find(itm => itm.Unit_Code === manPowerItem.Unit_Code).DepartmentTree
                .find(itm => itm.Department_Code === manPowerItem.Department_Code).SectionTree
                .find(itm => itm.SectionPlantRelationId === manPowerItem.Section_Code1).SectionList
                .map((e) => { return { id: e.SectionPlantRelationId, name: e.Name }; }) : [];

            const sectionItems3 = (manPowerItem.Unit_Code && manPowerItem.Department_Code
              && manPowerItem.Section_Code1 && manPowerItem.Section_Code2) ?
              unitDepartmentSectionTreeData.find(itm => itm.Company_Code === dataItem.companyCode).PlantTree
                .find(itm => itm.Plant_Code === dataItem.plantCode).UnitTree
                .find(itm => itm.Unit_Code === manPowerItem.Unit_Code).DepartmentTree
                .find(itm => itm.Department_Code === manPowerItem.Department_Code).SectionTree
                .find(itm => itm.SectionPlantRelationId === manPowerItem.Section_Code1).SectionList
                .find(itm => itm.SectionPlantRelationId === manPowerItem.Section_Code2).SectionList
                .map((e) => { return { id: e.SectionPlantRelationId, name: e.Name }; }) : [];

            // Object.assign(manPowerItem, {
            manPowerItem.plantControlKey = manPowerItem.Plant_Code;
            manPowerItem.unitControlKey = unitItems.length > 0 ? manPowerItem.Unit_Code : null;
            manPowerItem.departmentControlKey = departmentItems.length > 0 ? manPowerItem.Department_Code : null;
            manPowerItem.sectionControlKey1 = sectionItems1.length > 0 ? manPowerItem.Section_Code1 : null;
            manPowerItem.sectionControlKey2 = sectionItems2.length > 0 ? manPowerItem.Section_Code2 : null;
            manPowerItem.sectionControlKey3 = sectionItems3.length > 0 ? manPowerItem.Section_Code : null;
            */

            let sectionCode3 = null;
            let sectionCode2 = null;
            let sectionCode1 = null;

            let plantCode = null;
            let unitCode = null;
            let departmentCode = null;
            let sectionPlantRelationId1 = null;
            let sectionPlantRelationId2 = null;
            let sectionPlantRelationId3 = null;

            let plantCodeList = [];
            let unitCodeList = [];
            let departmentCodeList = [];
            let sectionPlantRelationId1List = [];
            let sectionPlantRelationId2List = [];
            let sectionPlantRelationId3List = [];

            plantCodeList = unitDepartmentSectionTreeData.map((e) => { return { id: e.Plantcode0, name: e.Plant0 }; });
            const plantFound = this.unitDepartmentSectionTreeData.find(item => item.Plantcode0 === dataItem.plantCode);
            if (plantFound) {
              plantCode = plantFound.Plantcode0;
              unitCodeList = plantFound.Values.map((e) => { return { id: e.Unitcode0, name: e.Unit0 }; });

              const unitFound = plantFound.Values.find(item => item.Unitcode0 === manPowerItem.Unit_Code);
              if (unitFound) {
                unitCode = unitFound.Unitcode0;
                departmentCodeList = unitFound.Values.map((e) => { return { id: e.UnitDeptID0, name: e.Department0 }; });

                const departmentFound = unitFound.Values.find(item => item.UnitDeptID0 === manPowerItem.Department_Code);
                if (departmentFound) {
                  departmentCode = departmentFound.UnitDeptID0;
                  sectionPlantRelationId1List = departmentFound.Values.map((e) => { return { id: e.Sectioncode0, name: e.SectionName0 }; });

                  if (manPowerItem.Unit_Code && manPowerItem.Department_Code && manPowerItem.Section_Code) {
                    departmentFound.Values.find((inner1) => {
                      return inner1.Values.find((inner2) => {
                        return inner2.Values.find((inner3) => {
                          if (inner3.Sectioncode2 === manPowerItem.Section_Code) {
                            sectionCode3 = inner3.LevelID2;
                            sectionCode2 = inner2.Sectioncode1;
                            sectionCode1 = inner1.Sectioncode0;
                            return true;
                          }
                        });
                      });
                    });
                  }

                  manPowerItem.Section_Code1 = sectionCode1;
                  manPowerItem.Section_Code2 = sectionCode2;

                  const section1Found = departmentFound.Values.find(item => item.Sectioncode0 === manPowerItem.Section_Code1);
                  if (section1Found) {
                    sectionPlantRelationId1 = section1Found.Sectioncode0;
                    sectionPlantRelationId2List = section1Found.Values.map((e) => { return { id: e.Sectioncode1, name: e.SectionName1 }; });

                    const section2Found = section1Found.Values.find(item => item.Sectioncode1 === manPowerItem.Section_Code2);
                    if (section2Found) {
                      sectionPlantRelationId2 = section2Found.Sectioncode1;
                      // tslint:disable-next-line: max-line-length
                      sectionPlantRelationId3List = section2Found.Values.map((e) => { return { id: e.LevelID2, name: e.SectionName2 }; });

                      const section3Found = section2Found.Values.find(item => item.LevelID2 === manPowerItem.Section_Code);
                      if (section3Found) {
                        sectionPlantRelationId3 = section3Found.LevelID2;
                      }
                    }
                  }
                }
              }
            }

            // Object.assign(manPowerItem, {
            manPowerItem.plantControlKey = manPowerItem.Plant_Code;
            manPowerItem.unitControlKey = unitCodeList.length > 0 ? manPowerItem.Unit_Code : null;
            manPowerItem.departmentControlKey = departmentCodeList.length > 0 ? manPowerItem.Department_Code : null;
            manPowerItem.sectionControlKey1 = sectionPlantRelationId1List.length > 0 ? manPowerItem.Section_Code1 : null;
            manPowerItem.sectionControlKey2 = sectionPlantRelationId2List.length > 0 ? manPowerItem.Section_Code2 : null;
            manPowerItem.sectionControlKey3 = sectionPlantRelationId3List.length > 0 ? manPowerItem.Section_Code : null;
            manPowerItem.shiftControlKey = shiftControlKey;

            /** nature of control */

            manPowerItem.natureOfWorkControlKey = this.natureOfWork.length > 0 ? manPowerItem.NatureOfWork_Code : null;
            manPowerItem.skillControlKey = manPowerItem.Skill_Code;

            // manPowerItem.unitItems = unitItems;
            manPowerItem.unitItems = unitCodeList;
            /** department array item */
            // manPowerItem.departMentItems = departmentItems;
            manPowerItem.departMentItems = departmentCodeList;
            /** shift array item */

            // console.log('shiftData-a', shiftData);
            manPowerItem.shiftItems = shiftData.filter((shiftData: any) =>
              (manPowerItem.Plant_Code === shiftData.Plant_Code)).map(item =>
                ({ id: item.Shift_Code, name: item.ShiftName }));

            /** nature array item */
            // manPowerItem.NatureofWorkItems = manPowerItem.NatureOfWork ? manPowerItem.NatureOfWork : [];
            // manPowerItem.SkillItems = manPowerItem.Skill_Code ? manPowerItem.Skill_Code : [];
            manPowerItem.itemsSkill = this.natureOfWork.filter(itm => itm.id === manPowerItem.NatureOfWork_Code)
              .map((e) => { return { id: e.skillId, name: e.skillName }; });
            // manPowerItem.sectionItems1 = sectionItems1;
            // manPowerItem.sectionItems2 = sectionItems2;
            // manPowerItem.sectionItems3 = sectionItems3;
            manPowerItem.sectionItems1 = sectionPlantRelationId1List;
            manPowerItem.sectionItems2 = sectionPlantRelationId2List;
            manPowerItem.sectionItems3 = sectionPlantRelationId3List;
            //   sectionItems: manPowerItem.Section_Code ?
            //     this.calBackBindSectionEdit(sectionTreeData, false,
            //       manPowerItem.Plant_Code, manPowerItem.Unit_Code, manPowerItem.Department_Code) : []
            // });
          });
          this.getRequistionData(manpowerdata, dataItem, shiftRequistionData);
        } else {
          this.alertOption = {
            title: 'OOPS!!!',
            html: `<span class='customClass'>Data Not Found.</span>`,
            type: 'error'
          },
            setTimeout(() => {
              this.openAlert();
            }, 10);
        }
      });
  }

  private getSectionTreeName(response: any[], isEdit: boolean, plantCode: number,
    unitCode: number, departmentCode: number, sectionCode: number): string {

    let itemData = '';
    response.forEach((val) => {
      val.PlantTree.filter(plantItem => plantItem.Plant_Code === plantCode).forEach((plant) => {
        plant.UnitTree.filter(unitItem => unitItem.Unit_Code === unitCode).forEach((unit) => {
          unit.DepartmentTree.filter(departmentItem => departmentItem.Department_Code === departmentCode).forEach((department) => {
            const index = department.SectionTree.findIndex((sectionItem: any) => (sectionItem.SectionPlantRelationId === sectionCode));
            if (index === -1) {
              department.SectionTree.forEach((sectionItem: any) => {
                const index = sectionItem.SectionList.findIndex((sectionItem: any) => (sectionItem.SectionPlantRelationId === sectionCode));
                if (index !== -1) {
                  sectionItem.SectionList[index].Name;
                  sectionItem.SectionList[index].IsCheck = true;
                }
              });
            } else {
              department.SectionTree[index].SectionList[0].IsCheck = true;
              itemData = department.SectionTree[index].Name;
            }
          });
        });
      });
    });
    return itemData;
  }

  /**
   * Invoke this method and it is set array list data of `AllContractor` for `rowData`.
   */
  private setStepArray(): void {

    const firstSteps = {
      id: 1,
      type: 'grid',
      title: 'Manpower Requistion List',
      button: { skip: false, cancel: false, submit: true, prev: false, next: false, close: true },
      header: true,
      footer: false,
      isAboveElementRef: false,
      isBelowElementRef: false,
      columnDef: this.requistionColumnDefs,
      rowData: this.requistionRowData,
      gridOptions: this.requistionGridOptions
    };
    this.stepsData = [firstSteps];
  }

  public onPlantItemSelect(selectedDropDownValue: { id: number, name: string }, unitData: any): void {

    this.plantCode = selectedDropDownValue.id;
    this.getUnitData(selectedDropDownValue, unitData);
  }

  public onUnitItemSelect(selectedDropDownValue: { id: number, name: string }, departmentData: any): void {

    this.unitCode = selectedDropDownValue.id;
    this.getDepartMentData(selectedDropDownValue, departmentData);
  }

  public onDepartmentItemSelect(selectedDropDownValue: { id: number, name: string }, sectionData: any): void {
    // this.getSectionData(selectedDropDownValue, sectionData);
  }

  public onSectionItemSelect(selectedDropDownValue: { id: number, name: string }, shiftData: any): void {
    this.getShiftData(selectedDropDownValue, shiftData);
  }

  public onShiftItemSelect(selectedDropDownValue: { id: number, name: string }, natureOfWorkData: any): void {
    // this.getNatureOfWorkData(selectedDropDownValue, natureOfWorkData);
  }

  public onNatureOfWorkItemSelect(selectedDropDownValue: { id: number, name: string }, unitData: any): void {
    // this.getUnitData(selectedDropDownValue, unitData);
  }

  public getUnitData(plantCodes: { id: number, name: string }, unitDataFun: any): void {
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number } = { search: '', plantCode: plantCodes.id };
    this.manPowerService.unitMaster(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Unit_Code, name: item.Name });
        });
        this.itemsUnit = array;
        unitDataFun.unitItems = array;
        this.itemPlantFieldValue = plantCodes.name;
      }
    });
  }

  public callBackGetUnitData(plantCodes: number, resolveInnerPromise: Function): { id: number, name: string }[] {
    // tslint:disable-next-line: max-line-length
    const array: { id: number, name: string }[] = [];
    const dataItem: { search: string, plantCode: number } = { search: '', plantCode: plantCodes };
    this.manPowerService.unitMaster(dataItem).subscribe((value: any) => {
      if (value) {
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Unit_Code, name: item.Name });
        });
        resolveInnerPromise();
      }
    });
    return array;
  }

  public getPlantData(filterData: any): void {
    // tslint:disable-next-line: max-line-length
    const dataItem: PlantMaster = { search: '', company: '', stateCode: '', citycode: '', countryCode: '', unitType: '', ownership: '', locatedAt: '', iSESICApplicable: '' };
    this.manPowerService.plantMaster(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Plant_Code, name: item.Plant });
        });
        this.itemsPlant = array;
        this.getNatureOfWorkData(filterData);
      }
    });
  }

  public getDepartMentData(unitCodes: { id: number, name: string }, unitDataFun: any): void {
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, unitCode: number } = { search: '', plantCode: this.plantCode, unitCode: unitCodes.id };
    // this.manPowerService.departMentMaster(dataItem).subscribe((value: any) => {
    this.manPowerService.getDepartments(dataItem.plantCode, dataItem.unitCode).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Unit_Code, name: item.Name });
        });
        this.itemsDerpartment = array;
        unitDataFun.departMentItems = array;
        this.itemPlantFieldValue = unitCodes.name;
      }
    });
  }

  public callBackGetDepartMentData(departmentParameter: {
    plantCode: number, unitCode: number
  }, resolveInnerPromise): { id: number, name: string }[] {
    const array: { id: number, name: string }[] = [];
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, unitCode: number } = { search: '', plantCode: departmentParameter.plantCode, unitCode: departmentParameter.unitCode };
    // this.manPowerService.departMentMaster(dataItem).subscribe((value: any) => {
    this.manPowerService.getDepartments(dataItem.plantCode, dataItem.unitCode).subscribe((value: any) => {
      if (value) {
        value.forEach((item: any, index: number) => {
          array.push({ id: item.Unit_Code, name: item.Name });
        });
        resolveInnerPromise();
      }
    });
    return array;
  }

  public callBackGetShiftData(shiftParmeterData: {
    plantCode: number, sectionCode: number
  }, resolveInnerPromise): { id: number, name: string }[] {
    const array: { id: number, name: string }[] = [];
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, shiftTypeGroupCode: number } = {
      search: '', plantCode: shiftParmeterData.plantCode, shiftTypeGroupCode: shiftParmeterData.sectionCode
    };
    this.manPowerService.shiftMaster(dataItem).subscribe((value: any) => {
      if (value) {
        value.forEach((item: any, index: number) => {
          array.push({ id: item.Shift_Code, name: item.Shift });
        });
        resolveInnerPromise();
      }
    });
    return array;
  }

  public getShiftData(shiftCodes: { id: number, name: string }, unitDataFun: any): void {
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, shiftTypeGroupCode: number } = { search: '', plantCode: this.plantCode, shiftTypeGroupCode: shiftCodes.id };
    this.manPowerService.shiftMaster(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.forEach((item: any, index: number) => {
          array.push({ id: item.Shift_Code, name: item.Shift });
        });
        this.itemShiftData = array;
        unitDataFun.shiftItems = array;
        this.itemShieftFieldValue = shiftCodes.name;
      }
    });
  }

  public getNatureOfWorkData(filterData: any): void {
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string } = { search: '' };
    this.manPowerService.natureOfWorkAndSkill(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string, skillId: number, skillName: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({
            id: item.NatureOfWork_Code,
            name: item.NatureOfWorkName,
            skillId: item.Skill_Code,
            skillName: item.SkillName
          });
        });
        this.natureOfWork = array;
        this.getManPowerAllocation(filterData);
      }
    });
  }

  public getSkillData(filterData: any): void {
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, parentSkillCode: number, tradeCode: number } =
      { search: '', plantCode: this.plantCode, parentSkillCode: this.parentSkillCode, tradeCode: this.tradeCode };
    this.manPowerService.skillmaster(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Skill_Code, name: item.SkillName });
        });
        this.skill = array;
        this.getManPowerAllocation(filterData);
      }
    });
  }

  public callBackGetNatureOfWorkData(resolveInnerPromise: Function): { id: number, name: string }[] {
    // tslint:disable-next-line: max-line-length
    const array: { id: number, name: string }[] = [];
    const dataItem: { search: string } = { search: '' };
    this.manPowerService.natureOfWork(dataItem).subscribe((value: any) => {
      if (value) {
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.NatureOfWork_Code, name: item.Name });
        });
        resolveInnerPromise();
      }
    });
    return array;
  }

  public callBackGetSkillData(resolveInnerPromise: Function): { id: number, name: string }[] {
    // tslint:disable-next-line: max-line-length
    const array: { id: number, name: string }[] = [];
    const dataItem: { search: string, plantCode: number, parentSkillCode: number, tradeCode: number }
      = { search: '', plantCode: this.plantCode, parentSkillCode: this.parentSkillCode, tradeCode: this.tradeCode };
    this.manPowerService.skillmaster(dataItem).subscribe((value: any) => {
      if (value) {
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Skill_Code, name: item.SkillName });
        });
        resolveInnerPromise();
      }
    });
    return array;
  }

  public callBackGetSectionData(departmentItem: { departmentCode: number, unitCode: number, plantCode: number }): any[] {
    // tslint:disable-next-line: max-line-length
    let someData = null;
    const dataItem: SectionList = {
      search: '', parentSectionCode: '', departmentCode: departmentItem.departmentCode,
      unitCode: departmentItem.unitCode, plantCode: departmentItem.plantCode, companyCode: ''
    };
    const PLANT_UNIT_DEP_REQUEST =
    // tslint:disable-next-line:ter-indent
    {
      Filter: {
        UserId: GLOBAL.USER_ID
      },
      Fields: [
        {
          KeyName: 'Plantcode0',
          DisplayName: 'Plant0'
        },
        {
          KeyName: 'Unitcode0',
          DisplayName: 'Unit0'
        },
        {
          KeyName: 'UnitDeptID0',
          DisplayName: 'Department0',
          ExtraFields: [
            'Departmentcode0'
          ]
        },
        {
          KeyName: 'LevelID0',
          DisplayName: 'SectionName0',
          ExtraFields: [
            'Sectioncode0'
          ]
        },
        {
          KeyName: 'LevelID1',
          DisplayName: 'SectionName1',
          ExtraFields: [
            'Sectioncode1'
          ]
        },
        {
          KeyName: 'LevelID2',
          DisplayName: 'SectionName2',
          ExtraFields: [
            'Sectioncode2',
            'Short_Code'
          ]
        }
      ]
    };
    console.log('API eMerge4', PLANT_UNIT_DEP_REQUEST);

    this.manPowerService.getDepartmentPlantUnitSectionTree(
      dataItem.plantCode, dataItem.unitCode, dataItem.departmentCode, PLANT_UNIT_DEP_REQUEST).subscribe((value: any) => {
        if (value) {
          const array: { id: number, name: string }[] = [];
          value.forEach((item: any, index: number) => {
            array.push({ id: item.Section_Code, name: item.Name });
          });
          // someData = this.calBackBindSectionEdit(someData, false);
          someData = null;
        }
      });
    return someData;
  }

  private calBackBindSectionEdit(response: any[], isEdit: boolean, plantCode: number, unitCode: number, departmentCode: number): any[] {
    const convertedData = [];
    response.forEach((val) => {
      val.PlantTree.filter(plantItem => plantItem.Plant_Code === plantCode).forEach((plant) => {
        plant.UnitTree.filter(unitItem => unitItem.Unit_Code === unitCode).forEach((unit) => {
          unit.DepartmentTree.filter(departmentItem => departmentItem.Department_Code === departmentCode).forEach((department) => {
            department.SectionTree.forEach((section1) => {
              convertedData.push(section1);
            });
          });
        });
      });
    });
    return this.callBackBindSection1Tree(convertedData, isEdit);
  }

  private callBackBindSection1Tree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            itemName: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            children: this.callBackBindSection2Tree(subDataFilter, val.SectionPlantRelationId, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            itemName: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            checked: false
          }
        );
      }
    });
    return convertedData;
  }

  private callBackBindSectionTree(filteredData, section1Code, section2Code, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      convertedData.push(
        {
          section1Code,
          section2Code,
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          itemName: val.Name,
          currentname: 'section3',
          isActive: false,
          selected: false,
          checked: false
        }
      );
    });
    return convertedData;
  }

  private callBackBindSection2Tree(filteredData, section1Code, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            section1Code,
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            itemName: val.Name,
            currentname: 'section2',
            isActive: false,
            selected: false,
            children: this.callBackBindSectionTree(subDataFilter, section1Code, val.SectionPlantRelationId, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            section1Code,
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            itemName: val.Name,
            currentname: 'section2',
            isActive: false,
            selected: false,
            checked: false
          }
        );
      }
    });
    return convertedData;
  }
}
