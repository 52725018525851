import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.css']
})
export class EditEmailComponent implements OnInit {
  @Output() public handleClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() conData: any;
  email: any = '';
  constructor() { }

  ngOnInit() {
    console.log('conData', this.conData);
    this.email = this.conData.EmailID;
  }
  public onClose(data?: any): void {
    this.handleClose.emit(data);
  }

  next(data) {
    data.control.markAsUntouched();
    if (data.valid) {
      const req = {
        Thirdparty_Code: this.conData.ContractorVendor_Code,
        NewEmailID: data.value,
        RequireType: 0,
        ActionType: 0,
        UserId: GLOBAL.USER_ID
      }
      this.onClose(req);      
    }

  }

}
