import { Component, EventEmitter, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { SharedService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { WizardStep, AgActionCheckboxRendererComponent } from 'src/app/core/components';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AttendanceService } from '../../attendance.service';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { Contractor, FilterTypeEnum } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { LabourWeekOFfPrsenter } from '../labourweekoffchange-presenter/labourweekoffchange.presenter';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { GridApi, GridOptions } from 'ag-grid-community';
import { WeekOffCheckboxRenderComponent } from '../week-off-checkbox-render/week-off-checkbox-render.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { forkJoin, Observable } from 'rxjs';
import { getMinDateFromCode } from 'src/app/common/utils';
import { map } from 'rxjs/operators';
declare var $;
const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-bulk-weekoff-change',
  templateUrl: './bulk-weekoff-change.component.html',
  styleUrls: ['./bulk-weekoff-change.component.css'],
  viewProviders: [LabourWeekOFfPrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ]
})
export class BulkWeekoffChangeComponent implements OnInit {
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  @ViewChild('bulkWeekoffForm') bulkWeekoffForm: NgForm;
  @Output() public save1: EventEmitter<void>;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  public formGroup: FormGroup;
  public weeklyOffDate: Date;
  public weeklyOffShiftDate: Date;
  // tslint:disable-next-line: variable-name
  WeekOffdays = [{ Name: 'SUN' }, { Name: 'MON' }, { Name: 'TUE' }, { Name: 'WED' }, { Name: 'THU' }, { Name: 'FRI' }, { Name: 'SAT' }];
  pWeekOffdays = [{ Name1: 'SUN' }, { Name1: 'MON' },
  { Name1: 'TUE' }, { Name1: 'WED' }, { Name1: 'THU' },
  { Name1: 'FRI' }, { Name1: 'SAT' }];
  wizardSteps: WizardStep[] = [];
  public checkList: any = [];
  selectedCount = 0;
  private defaultColDef;
  private defaultColDef1;
  private editType;
  selectedRows: any[] = [];
  rowDataAll = [];
  rowDataAll1 = [];
  rowClassRules;
  requiredFlag = false;
  private isRowSelectable;
  getRowNodeId;
  groupingMeta = [''];
  gridColumnApi;
  gridApi: GridApi;
  statementType: any;
  gridOptions: GridOptions;
  columnDefs;
  rowData = [];
  rowData1 = [];
  formData: any;
  weekOffdata: any;
  show = false;
  show1 = false;
  periodicchange = false;
  permanent = false;
  upcomingweekoffchange = false;
  previousweekoffchange = false;
  public savedFilters: any[] = [];
  public activeFilter: string;
  public pagecode: number;
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  public selectedTabId: any;
  public companyList: any[] = [];
  public plantList: any[] = [];
  public filterTabList: any[] = [];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  private selectedFilterData: any = { JSONText: {} };
  public filterNameFormGroup: FormGroup;
  private filterType: FilterTypeEnum;

  public get minWeeklyOffDate$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG218FV_1', 'MOD115PG218DV_2')));
  }
  public get minWeeklyOffShiftDate$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG218_V1_FV_1', 'MOD115PG218_V1_DV_2')));
  }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }
  onGridReady(params) {
    this.gridApi = params.api;
  }
  dateValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('MM/DD/YYYY');
    }
    return '';
  }

  datetimeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('MM/DD/YYYY HH:mm');
    }

    return '';
  }
  constructor(public sharedService: SharedService,
    public router: Router,
    public attendanceService: AttendanceService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private departmenttrainingPrsenter: LabourWeekOFfPrsenter,
    private fb: FormBuilder,
    private logHelperService: LogHelperService
  ) {
    this.formGroup = this.buildWeekOffForm();
    this.show1 = true;
    this.save1 = new EventEmitter();
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      // groupRowRenderer: (params) => {
      //   return params.node.key;
      // },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true;
      },
      // doesExternalFilterPass: node => node.data.isVisible,
      doesExternalFilterPass: node => true,
      onGridReady: (params) => {
        // this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: false,
      pagination: false,
      headerHeight: 40,
      // isFullWidthCell: (rowNode) => {
      //     return rowNode.data.fullWidth;
      // },
    };
    // this.getRowNodeId = function (data) {
    //   return data;
    // };
  }
  setColumnDef() {
    this.rowClassRules = {};
    // this.rowClassRules = {
    //   'group-row': (params) => {
    //     return params.data.groupLevel === 1;
    //   },
    // };
    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
    this.columnDefs = [
      {
        headerName: '', field: 'isSelected', width: 30,
        // pinned: 'left',
        sortable: false, suppressMenu: true,
        cellRendererFramework: WeekOffCheckboxRenderComponent,
        filter: false,
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
        headerComponentFramework: WeekOffCheckboxRenderComponent,
        headerComponentParams: {
          test: 'parent'
        }
        // cellRenderer: WeekOffCheckboxRenderComponent
        // cellRenderer: (params) => {
        //   var input = document.createElement('input');
        //   input.type = "checkbox";
        //   input.checked = params.data.isSelected;
        //   input.addEventListener('change', e => {
        //     this.onCheckboxClick(params);
        //   });
        //   // event => {
        //   //   // debugger
        //   //   params.value = !params.value;
        //   //   params.node.data.fieldName = params.value;
        //   // });
        //   return input;
        // }
      },
      {
        headerName: 'GatePassNo', field: 'GatePassNo', width: 70, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Full Name', field: 'FullName', width: 150, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Company', field: 'Company',
        width: 150, headerComponentParams: { enableSorting: false }, suppressMenu: true,
        filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Issue Date', field: 'IssueDate', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Valid Upto Date', field: 'ValidUptoDate', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Department Name', field: 'DepartmentName', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'IsWo', field: 'IsWo', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      {
        headerName: 'WO-Status', field: 'ActualWoStatus', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'WO_Change', field: 'ChangeWOStatus', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      }
      // {
      //   headerName: 'IsPresentHalf', field: 'IsPresentHalf', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      // {
      //   headerName: 'IsLeave', field: 'IsLeave', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      // {
      //   headerName: 'IsSuspended', field: 'IsSuspended', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      // {
      //   headerName: 'IsNotApplicable', field: 'IsNotApplicable', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      // {
      //   headerName: 'IsHoliday', field: 'IsHoliday', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // },
      // {
      //   headerName: 'ID', field: 'AttendanceStatusID', width: 50, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true
      // }
    ];
  }


  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();
    this.pagecode = 362;
    this.getALLSavedFilterDescripationUserWise(this.pagecode);
    this.getCompany();
    const userId = GLOBAL.USER_ID;
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (userId && roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  onWeeklyOffDateChange(dateSelectionType: number): void {
    const weekOffDate = this.formGroup.get('JSONText').get('weekOffDate').value;
    const weekOffShiftDate = this.formGroup.get('JSONText').get('weekOffShiftDate').value;
    this.weeklyOffDate = weekOffDate ? new Date(weekOffDate) : null;
    this.weeklyOffShiftDate = weekOffShiftDate ? new Date(weekOffShiftDate) : null;

    if (weekOffDate && weekOffShiftDate) {
      if (new Date(weekOffDate).toDateString() === new Date(weekOffShiftDate).toDateString()) {
        if (dateSelectionType === 1) {
          this.formGroup.get('JSONText').get('weekOffDate').setValue(null);
        } else {
          this.formGroup.get('JSONText').get('weekOffShiftDate').setValue(null);
        }
        this.showAlert('warning', `You can not select same date.`, 'Warning');
        return;
      }
    }
    if (dateSelectionType === 1) {
      this.checkExistingWeekOffDate(this.formGroup.get('JSONText').get('weekOffDate').value);
    } else {
      this.checkExistingWeekOffShiftDate(this.formGroup.get('JSONText').get('weekOffShiftDate').value);
    }
  }

  checkExistingWeekOffDate({ weekOffDate, GatepassNo }: any): Observable<any> {
    const requestData = {
      User_Id: GLOBAL.USER_ID,
      IsList: null,
      GatepassNo,
      ActualWODate: moment(weekOffDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat)
    };

    return this.attendanceService.checkExistingWeekOffDate(requestData);
  }

  checkExistingWeekOffShiftDate({ weekOffShiftDate, GatepassNo }: any): Observable<any> {
    const requestData = {
      User_Id: GLOBAL.USER_ID,
      IsList: null,
      GatepassNo,
      ActualWODate: moment(weekOffShiftDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat)
    };

    return this.attendanceService.checkExistingWeekOffShiftDate(requestData);
  }

  onCheckboxClick(event: any, data: any): void {
    if (data) {
      const currentRecord = this.rowData.find((item: any) => item.Labour_Code === data.Labour_Code);
      if (currentRecord) {
        if (event.currentTarget.checked) {
          if (this.checkWeekOfValidation(data, true)) {
            const rowIndex = this.rowData.findIndex(item => item.Labour_Code === data.Labour_Code);
            this.rowData[rowIndex].isSelected = false;
            return;
          }
        }
      }
      currentRecord.isSelected = event.currentTarget.checked;
    } else {
      let isAlertShow: boolean;
      this.rowData = this.rowData.map((item: any) => {
        if (event.currentTarget.checked) {
          if (this.checkWeekOfValidation(item, false)) {
            // event.node.setSelected(false);
            if (!isAlertShow) {
              this.showAlert('warning', `Some records failed to check.`, 'Warning');
              isAlertShow = true;
            }
            return { ...item, isSelected: false };
          }
        }
        return { ...item, isSelected: event.currentTarget.checked };
      });
    }
  }

  onRowSelected(event) {
    const selected = event.node.isSelected();
    if (selected) {
      const nodeData = event.data;
      if (this.checkWeekOfValidation(nodeData, true)) {
        event.node.setSelected(false);
      }
    }
    if (event.data.parent) {
      //   // event.api.deselectAll();
      //   console.log('onRowSelected Group', event);
      //   let isAlertShow: boolean;
      //   this.gridOptions.api.forEachNode((node) => {
      //     // if (node.data.ContractorName === event.data.group) {
      //     //   // Master open detail.  You could also call node.setSelected( true ); for alternate design.
      //     //   // node.setSelected(event.node.selected);
      //     // }
      //     const nodeData = node.data;
      //     if (this.checkWeekOfValidation(nodeData)) {
      //       node.setSelected(false);
      //     }
      //     if (!isAlertShow) {
      //       this.showAlert('warning', `Some records failed to check.`, 'Warning');
      //       isAlertShow = true;
      //     }
      //   });
      // } else {
      //   const selected = event.node.isSelected();
      //   if (selected) {
      //     const nodeData = event.data;
      //     if (this.checkWeekOfValidation(nodeData, true)) {
      //       event.node.setSelected(false);
      //     }
      //   }
    }
  }

  private checkWeekOfValidation(data: any, isShowAlert?: boolean): boolean {
    let isPreventCheckbox: boolean;
    switch (true) {
      case data.IsWo === 1 && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift on week off day`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.ChangeIsWo && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift on week off day`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.Present && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift on present day`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsPresentHalf && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift on present day`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsLeave && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning',
          `You can't shift week of on leave applied day.`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsHoliday && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift week of on Holiday`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsSuspended && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift week of on Suspended date`, 'Warning');
        isPreventCheckbox = true;
        break;
      case data.IsNotApplicable && data.ChangeWOStatus !== 'A':
        isShowAlert && this.showAlert('warning', `You can't shift week off on Not Applicable date.`, 'Warning');
        isPreventCheckbox = true;
        break;
      default:
        isPreventCheckbox = data.ChangeWOStatus === 'P' ? true : false;
    }
    return isPreventCheckbox;
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  // public onFilterTab(filterObj: any): void {
  //   // console.log('filterObj', filterObj);
  //   this.activeFilter = filterObj.name;
  //   this.selectedTabId = filterObj.id;
  //   this.manageVisibilityOfSteps();
  // }
  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }
  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }
  getCompany() {
    this.attendanceService.getCompanies().subscribe((response: any[]) => {

      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }
  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  getPlants(companyCode: any): void {
    this.attendanceService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
    });
  }

  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.attendanceService.getContractorsbydefault(userId, roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getContractors() {
    //
    this.attendanceService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }
  private getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.attendanceService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
          this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
        }
      }
    });
  }

  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.attendanceService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
      }
    });
  }

  getWCPolicyBySubContractorCode(contractorCode: any): void {
    this.attendanceService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.subContractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('SubWCPolicy').setValue(this.selectedFilterData.JSONText.SubWCPolicy);
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.attendanceService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subContractorLicenseList = response;
      this.formGroup.get('JSONText').get('SubLicense_Code').setValue(this.selectedFilterData.JSONText.SubLicense_Code);
    });
  }

  private getSubContractors(workOrderNo: any = ''): void {
    // this.attendanceService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
    //   this.subContractors = response;
    // });
    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    this.attendanceService.getContractors(contractorCode, workOrderNo).subscribe((response: any[]) => {
      this.subContractors = response;
      this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
        this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
          this.selectedFilterData.JSONText.plantcode);
        this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
      }
    });
  }

  getLicensesByContractorCode(contractorCode: any): void {
    this.attendanceService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.contractorLicenseList = response;
      this.formGroup.get('JSONText').get('License_Code').setValue(this.selectedFilterData.JSONText.License_Code);
    });
  }

  getWCPolicyByContractorCode(contractorCode: any): void {
    this.attendanceService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.contractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('WCPolicy').setValue(this.selectedFilterData.JSONText.WCPolicy);
    });
  }
  onContractorChange() {
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('License_Code').setValue(null);
    this.formGroup.get('JSONText').get('WCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      this.getLicensesByContractorCode(contractorCode);
      this.getWCPolicyByContractorCode(contractorCode);
    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get('JSONText').get('WorkOrder_Code').value;
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
    }
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  public onSaveFilter(): void {
    console.log('form data', this.formGroup.getRawValue());
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    console.log('saved data', this.reportFilterData);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();
    this.openModal();
  }

  public onAddfilter(): void {
    console.log('form data open modal', this.formGroup.getRawValue());
    const value = this.formGroup.getRawValue();
    const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
    delete selectedFilterDataObj.weekOffDate;
    delete selectedFilterDataObj.weekOffShiftDate;
    const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
      && item.Report_Code !== this.selectedFilterData.Report_Code);
    // if (filterTypeObj) {
    //   this.statementType = 'Insert';
    // }
    console.log('form data open modal', selectedFilterDataObj);

    if (!filterTypeObj) {
      selectedFilterDataObj.id = this.selectedTabId;
      const data = {
        Report_Code: this.selectedFilterData.Report_Code || '',
        Page_Code: 362,
        FilterName: value.FilterName,
        JSONTextDetails: JSON.stringify(selectedFilterDataObj)
      };
      if (this.selectedFilterData.Report_Code) {
        this.attendanceService.updateFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      } else {
        this.attendanceService.addFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      }
    } else {
      alert('This filter is already exist.');
    }
  }

  public deleteFilterItem(filterObj: any): void {
    this.attendanceService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(this.pagecode);
    });
  }

  private getALLSavedFilterDescripationUserWise(pagecode: number) {
    this.attendanceService.getALLSavedFilterDescripationUserWise(pagecode).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();
    // this.reportFilterData = null;
    // this.selectedFilterData = null;

    // this.contractorCode = null;
    // this.workOrderNo = null;
    // this.subContractors = [];
    // this.workOrders = [];
    // // this.contractors=[];
    // setTimeout(() => {
    //   this.contractors = [...this.contractors];
    // }, 100);

    // this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  public onFilterNameClick(filterObj: any) {

    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.departmenttrainingPrsenter.bindForm(this.formGroup, filterObj);
  }

  public onFilterTab(filterObj: any): void {

    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
        .get('Contractors').value.length > 1)) {
      this.logHelperService.logError({
        message: 'Please first fullfill data in contractor.'
      });
      return;
    }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  // tslint:disable-next-line: function-name
  RegularWeekOff($event) {

    if ($event.target.checked === true) {
      this.show = true;
      this.show1 = false;
      this.upcomingweekoffchange = false;
      this.previousweekoffchange = false;
    } else {
      this.show = false;
    }
  }

  // tslint:disable-next-line: function-name
  Specificweekoffs($event) {
    if ($event.target.checked === true) {
      this.show1 = true;
      this.show = false;
      this.periodicchange = false;
      this.permanent = false;
    } else {
      this.show1 = false;
    }
  }

  // tslint:disable-next-line: function-name
  PeriodicWOChange($event) {

    if ($event.target.checked === true) {
      this.periodicchange = true;
      this.permanent = false;
      this.show1 = false;

    } else {
      this.periodicchange = false;
    }
  }

  // tslint:disable-next-line: function-name
  PermanentWOChange($event) {

    if ($event.target.checked === true) {
      this.permanent = true;
      this.periodicchange = false;
      this.show1 = false;

    } else {
      this.permanent = false;
    }
  }

  // tslint:disable-next-line: function-name
  Upcomingweekoff($event) {

    if ($event.target.checked === true) {
      this.upcomingweekoffchange = true;
      this.previousweekoffchange = false;

    } else {
      this.upcomingweekoffchange = false;
    }
  }

  // tslint:disable-next-line: function-name
  Previousweekoffs($event) {

    if ($event.target.checked === true) {
      this.previousweekoffchange = true;
      this.upcomingweekoffchange = false;
    } else {
      this.previousweekoffchange = false;
    }
  }
  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
  }
  onCloseAction() {
    this.activeModal.close();
  }
  displayrange(event) {
    const formValue = this.formGroup.getRawValue();
    console.log('xyz', this.formGroup.getRawValue());
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.AplicableDate = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.ENDDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const d = new Date(formValue.JSONText.AplicableDate);
    const a = formatDate(formValue.JSONText.AplicableDate, 'dd-MM-yyyy', 'en-US');
    const e = formatDate(formValue.JSONText.ENDDate, 'dd-MM-yyyy', 'en-US');
    const dayName = days[d.getDay()];
    const alter = `All ${dayName} from ${a} to ${e} will be marked as Weekly Off`;
    alert(alter);

  }


  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();

    if (!formValue.JSONText.weekOffDate && !formValue.JSONText.weekOffShiftDate) {
      this.logHelperService.logError({
        message: 'Please Select Week off and week off shift date.'
      });

      return;
    }
    if (!formValue.JSONText.Contractors) {
      this.logHelperService.logError({
        message: 'Please select Contractor'
      });

      return;
    }

    formValue.JSONText.LabourCodes = [];
    // for (let index = 0; index < this.selectedRows.length; index++) {
    //   const element = this.selectedRows[index];
    //   formValue.JSONText.LabourCodes.push(element['Labour_Code']);
    // }
    formValue.JSONText.LabourCodes = this.rowData.filter(item => item.isSelected).map(item => item.Labour_Code);
    if (!formValue.JSONText.LabourCodes.length) {
      this.logHelperService.logError({
        message: 'Please select minimum one record'
      });

      return;
    }
    // const checkWODate = this.checkExistingWeekOffDate({
    //   weekOffDate: this.formGroup.get('JSONText').get('weekOffDate').value,
    //   // Gatepassno: this.formGroup.get('JSONText').get('Gatepassno').value,
    //   Gatepassno: null
    // });
    // const checkWOShiftDate = this.checkExistingWeekOffShiftDate({
    //   weekOffShiftDate: this.formGroup.get('JSONText').get('weekOffShiftDate').value,
    //   // Gatepassno: this.formGroup.get('JSONText').get('Gatepassno').value,
    //   Gatepassno: null
    // });

    // forkJoin([checkWODate, checkWOShiftDate]).subscribe(([existWODate, existWOShiftDate]) => {
    //   if (existWODate && existWODate.length > 0) {
    //     this.logHelperService.logError({
    //       message: 'Week off date already exists.'
    //     });
    //     this.formGroup.get('JSONText').get('weekOffDate').setValue(null);

    //     return;
    //   }
    //   if (existWOShiftDate && existWOShiftDate.length > 0) {
    //     this.logHelperService.logError({
    //       message: 'Week off shift date already exists.'
    //     });
    //     this.formGroup.get('JSONText').get('weekOffShiftDate').setValue(null);

    //     return;
    //   }

    console.log('xyz', this.formGroup.getRawValue());
    // this.selectedRows = this.gridApi.getSelectedRows();
    // this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.FromDate = moment(formValue.JSONText.FromDate).format(this.sharedService.datePickerSettings.requestFormat);
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.AplicableDate = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      // tslint:disable-next-line:max-line-length
      formValue.JSONText.ENDDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);

    }
    // tslint:disable-next-line:max-line-length
    // formValue.JSONText.AplicableDate = moment(formValue.JSONText.AplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    // formValue.JSONText.ToDate = moment(formValue.JSONText.ToDate).format(this.sharedService.datePickerSettings.requestFormat);
    // tslint:disable-next-line:max-line-length
    // formValue.JSONText.AplicableDate = moment(formValue.JSONText.AplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    // formValue.JSONText.ENDDate = moment(formValue.JSONText.ENDDate).format(this.sharedService.datePickerSettings.requestFormat);
    formValue.JSONText.FromDate = formValue.JSONText.weekOffDate ?
      moment(formValue.JSONText.weekOffDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    // formValue.JSONText.AplicableDate = formValue.JSONText.weekOffShiftDate ?
    //   moment(formValue.JSONText.weekOffShiftDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    // formValue.JSONText.ENDDate = formValue.JSONText.weekOffDate ?
    //   moment(formValue.JSONText.weekOffDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    formValue.JSONText.ToDate = formValue.JSONText.weekOffShiftDate ?
      moment(formValue.JSONText.weekOffShiftDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    formValue.JSONText.AplicableDate = moment(formValue.JSONText.AplicableDate).format(this.sharedService.datePickerSettings.requestFormat);

    // if (formValue.JSONText.weekofftype != null) {
    if (formValue.JSONText.Contractors != null) {
      if (formValue.JSONText.LabourCodes.length > 0) {
        this.attendanceService.submitrequestWeekOFFData(formValue.JSONText).subscribe((response) => {
          if (response.Status) {
            this.activeModal.close();
            this.logHelperService.logSuccess({
              message: 'Accociate week Off change request submitted successfully'
            });
            // this.router.navigate(['attendance/manual-punch']);
            this.save1.emit();
            // alert('Attendance data imported successfully.');
          } else {
            this.logHelperService.logError({
              message: 'Accociate week Off change request Not Submitted '
            });
          }
        });
      } else {
        this.logHelperService.logError({
          message: 'Please Select Alteast One Record'
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select Contractor'
      });
    }
    // } else {
    //   this.logHelperService.logError({
    //     message: 'Please Select Week Off Type'
    //   });
    // }

    // });
    // } else {
    //   this.logHelperService.logError({
    //     message: 'Please Select Week off and week off shift date.'
    //   });
    // }
  }

  getLabourFilterdata() {
    const formValue = this.formGroup.getRawValue();
    formValue.JSONText.weekOffDate = formValue.JSONText.weekOffDate ?
      moment(formValue.JSONText.weekOffDate).format(this.sharedService.datePickerSettings.requestFormat) : null;
    formValue.JSONText.weekOffShiftDate = formValue.JSONText.weekOffShiftDate ?
      moment(formValue.JSONText.weekOffShiftDate).format(this.sharedService.datePickerSettings.requestFormat) : null;

    this.attendanceService.getActiveLabourDetails(formValue.JSONText).subscribe((res: any) => {
      console.log(res.Data.Table);
      /// this.rowData = res.Data.Table;
      if (res.Data.Table.length > 0) {
        // this.rowData = res.Data.Table;
        this.rowDataAll = res.Data.Table;
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res.Data.Table);
        console.log('tempRowData', tempRowData);

        // this.rowData = tempRowData;
        this.rowData = res.Data.Table;
        this.rowData.forEach((item: any) => {
          // item.isSelected = true;

          // item.IsWO = 1;
          // item.IsLeave = 1;
          // item.IsHoliday = 1;
          // item.IsSuspended = 1;
          // item.IsNotApplicable = 1;
        });
        // this.rowData[2].IsWO = 1;
      } else {
        this.rowData = [];
      }
      // for (let index = 0; index < this.rowData.length; index++) {
      //   const element = this.rowData[index];
      //   element['selected'] = false;
      // }
    });
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      // {
      //   id: 2,
      //   name: 'Company',
      //   active: false
      // },
      {
        id: 2,
        name: 'Contractor',
        active: false
      },
      {
        id: 3,
        name: 'Sub-Contractor',
        active: false
      },
      {
        id: 4,
        name: 'Accociate Selection',
        active: false
      },
    ];
  }
  private buildWeekOffForm(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        Date: [null],
        groupBy: [null],
        punchingWiseFilter: [null],
        labourCode: [null],
        dataOrderBy: [null],
        CurrentStatus: '',
        Company_Code: [null],
        Plant_Code: [null],
        Unit_Code: [null],
        Department_Code: [null],
        CategoryWorkmenMIL_Code: [null],
        WorkmenCategoty_Code: [null],
        Section_Code: [null],
        sectionList: [null],
        workmanCategoryList: [null],
        ShiftGroupType_Code: [null],
        Shift_Code: [null],
        StartDate: [null],
        EDate: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        Gender: [null],
        Skill_Code: [null],
        Trade_Code: [null],
        QualificationMasterList_Code: [null],
        TechQualificationMasterList_Code: [null],
        QualificationStreamMasterList_Code: [null],
        Religion_Code: [null],
        Cast_Code: [null],
        IsDomicial: [null],
        FirstAIDTraning: [null],
        FireFighter: [null],
        SAge: [null],
        EAge: [null],
        JobType: [null],
        NatureOfWork_Code: [null],
        WageCriteria: [null],
        ContactortMenType: [null],
        ExpiredMedicalPeriod: [null],
        ExpiredFireTraining: [null],
        PoliceVerifed: [null],
        LabourDemandType: [null],
        dateRange: [null],
        Page: [null],
        contAndSubCont: [null],
        formControlName: [null],
        isAttendanceStatus: [null],
        isApprove: [null],
        UserID: GLOBAL.USER_ID,
        JoiningOrResigning: [null],
        allmasterorspecific: [null],
        MTestOrITORPV: [null],
        ExcludeContractor: [null],
        previouswookoffmoovedate: [null],
        previousmoovedate: [null],
        upcomingwookoffmoovedate: [null],
        upcomingmoovedate: [null],
        pAplicableDate: [null],
        pWeekOffday: [null],
        AplicableDate: [null],
        WeekOffday: [null],
        ToDate: [null],
        FromDate: [null],
        Previousweekoff: [null],
        upcomingweekoff: [null],
        specificRequestDate: [null],
        permanentChange: [null],
        periodicChange: [null],
        RequestDate: [null],
        Specificweekoff: [null],
        Regularweekoff: [null],
        upcomingorprevious: [null],
        periodicorparmenent: [null],
        weekofftype: ['2'],
        LabourCodes: [null],
        ENDDate: [null],
        Labour_Code1: [null],
        weekOffDate: [null],
        weekOffShiftDate: [null]
      })
    });
  }
}
