import { DatePipe } from "@angular/common";
// tslint:disable-next-line: max-line-length
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  NgZone,
  Input,
  TemplateRef,
  Inject,
  ChangeDetectorRef,
} from "@angular/core";
import {
  WizardStep,
  Checklist,
  FileDoc,
  FileUploadService,
} from "src/app/core/components";
import { NgForm, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Subscription, Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/core/services";
import { LaboursService } from "../labours.service";
import { GLOBAL } from "src/app/app.globals";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import * as _ from "lodash";
import { ImageCroppedEvent, ImageCropperComponent } from "ngx-image-cropper";
import { SharedDataService } from "src/app/core/services/shared-data.service";
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import * as moment from "moment";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BaseCloseSelectDropdown } from "src/app/core/base-classes/base-close-select-dropdown";
export let PHOTO_URL;
import { ToastUiImageEditorComponent } from "ngx-tui-image-editor";
import { WebcamInitError } from "ngx-webcam";
import { LabourProfileImageComponent } from "./labour-profile-image/labour-profile-image.component";
import { title } from "process";
import { EntryfordayService } from "src/app/reports/daily-attendance/entryfortheday/entryforday.service";
import { MasterItemCode } from "../../department-training/department-training.model";
import { takeUntil } from "rxjs/operators";
import { TradeMasterParams } from "src/app/common/common-api.model";
import { CommonApiService } from "src/app/common/common-api.service";
import { percentageValidator } from '../../../core/validators/percentage.validator';
import { CommonService } from '../../../common/common.service';

const browserWindow = window || {};
const browserWindowEnv = browserWindow["__env"] || {};
PHOTO_URL = browserWindowEnv.PHOTO_URL;
PHOTO_URL = `${PHOTO_URL}`;
const dateFormat = "DD-MM-YYYY";

export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
let fileToReturn: File;
@Component({
  selector: "app-create-sub-labour",
  templateUrl: "./create-sub-labour.component.html",
  styleUrls: ["./create-sub-labour.component.css"],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }],
  // { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class CreateSubLabourComponent
  extends BaseCloseSelectDropdown
  implements OnInit, OnDestroy, AfterViewInit {
  imageChangedEvent: any = "";
  croppedImage: any = "";
  savedCroppedImage: any = "../assets/Image/Icon/default.png";
  showCropper = false;
  public labourDetailsForm: FormGroup;

  // @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  formChangesSubscription: Subscription;
  stepDataSubscription: Subscription;
  @ViewChild("labourForm") labourForm: NgForm;
  @ViewChild("uploadDocumentTemplateRef") uploadDocumentTemplateRef: TemplateRef<any>;
  @ViewChild("appvalidationChecklist") appvalidationChecklist: any;
  
  @ViewChild(ToastUiImageEditorComponent)
  editorComponent: ToastUiImageEditorComponent;
  public tuiImageEditorOptions: any = {
    usageStatistics: false,
  };
  datePickerPickerMode: string;
  datePickerPlaceHolder: string;
  epfFormat: string;
  epfPlaceHolder: string;
  esicFormat: string;
  esicPlaceHolder: string;
  private onDestroy: Subject<any>;
  checkList: Checklist[] = [];
  public castList: any[] = [];
  wizardSteps: any[] = [];
  contractorTypes: any[] = [];
  districts = [];
  districtsLocal = [];
  uploadedFiles: FileDoc[] = [];
  copyUploadedFiles: FileDoc[] = []
  uploadedPhoto: FileDoc[] = [];
  photopath: any;
  photoname: any;
  contractorContactPersons = [];
  hideAddContactPerson = false;
  disableAddContactPerson = true;
  hideUpdateContactPerson = true;
  isDisabledVerificationDate = false;
  isIssueDates = true;
  role = 118;
  gender = [];
  //   ShiftGroupList = [];
  getPassRequests = [];
  shiftGroup = [];
  skillType = [];
  sectionData = [];
  sectionData1 = [];
  sectionData2 = [];
  departmentData = [];
  stateData = [];
  pinCodeData = [];
  workCategory = [];
  workmanCategory = [];
  validityperiod = [];
  allLabourDoc = [];
  allTrade = [];
  plants = [];
  units = [];
  postOfficeData = [];
  cityData = [];
  districtData = [];
  planData: any;
  masterData = [];
  sectionAllData = [];
  maritalStatus = [];
  companyData = [];
  labRelationship = [];
  accountTypes = [];
  yesNoData1 = [];
  yesNoData2 = [];
  // localtypeOfAddress = [];
  // permenenttypeOfAddress = [];
  firestatustype: any;
  statustype: any;
  relationType: any;
  bloodGroupType: any;
  documentType: any;
  labRelationshipAll: any = [];
  accountTypesAll: any;
  worksitedataALLData: any;
  worksitedata: any;
  gendertypes: any;
  religionTypes = [];
  identityType: any[];
  identityTypes = [];
  disabilityTypes = [];
  languageList = [];
  drivingicenceType: any[];
  drivingLicenceType: any[];
  qualifications: any[];
  technicalQualifications: any[];
  qulificationstreams: any[];
  bankNames: any[];
  // accountTypes: any[];
  bankPaymentTypes: any[];
  addressTypes: any[];
  familyState: any[];
  // familyCity: any;
  country: any;
  permanentState = [];

  lcountries = [];
  pcountries = [];
  contactcountries = [];
  familycountries = [];
  lStates = [];
  pStates = [];
  contactStates = [];
  familyStates = [];
  lDistricts = [];
  pDistricts = [];
  contactDistricts = [];
  familyDistrict = [];
  lCities = [];
  pCities = [];
  contactCities = [];
  familyCity = [];
  lPos = [];
  pPos = [];
  contactlPos = [];
  familylPos = [];
  lVillages = [];
  pVillages = [];

  workSites = [];

  localPostOffice: any;
  localVillage: any;
  documentTypes = [];
  erIStatus: any[];
  eRILevel: { Id: number; Name: string }[];
  labourDemandTypes: { Id: number; Name: string }[];
  skillTypes: any;
  workOrderTypes: any[];
  yesNoData: { Id: number; Name: string }[];
  cardType: any[];
  regularweekly: { Id: number; Name: string }[];
  // address: { Id: number, Name: string; }[];
  editMode = false;
  editLabourCode: string;
  wagecriteria: { Id: number; Name: string }[];
  nationality: { Id: number; Name: string }[];
  // nationalityData: { Id: number; Name: any; }[];
  nationalityData = [];
  nationalityData1: any;
  addresstype: { Id: number; Name: string }[];
  // typeOfAddress: any;
  typeOfAddress: any[];
  addressCode: any;
  labourCode: any;
  workSiteData: any;
  gatePassRequestCode: any;
  hideUpdatePOI = true;
  hideUpdateDrivingLicenseButton = true;
  show = true;
  show1 = false;
  show2 = false;
  dateofExtension = false;
  dateofExtension1 = false;
  epfno = false;
  showEPFNo = false;
  showESICNo = false;
  esicno = false;
  pfuanno = true;
  pfatpresent = true;
  esicatpresent = true;
  editPOIIndex: number;
  editDrivingLicenseIndex: number;
  disableAddPOI = true;
  identityDetails = [];
  masterItemDataSubscription: Subscription;
  drivingLicenceTypes = [];
  labourContactPersonViews = [];
  familyInfoData = [];
  nomineeInfoData = [];
  educationalQualificationList = [];
  editContactDetailsIndex = -1;
  hideUpdateContactDetails = true;
  hideUpdateFamilyDetails = true;
  editLabourData;
  section1;
  section2;
  section3;
  contactTypes = [];
  globalSettingsCodeArr = [];
  backGroundCheck;
  backGroundCheckDocument;
  bankAccountDetail;
  bankAccountDocument;
  tradeCodeData;
  drivingLicenseRequiredCode;
  drivingLicenseRequiredDocumentCode;
  referenceContactRequiredCode;
  emergencyContactRequiredCode;
  medicalDocumentRequiredCode1;
  medicalDocumentRequiredCode2;
  public issuedate = false;
  public isshift = false;
  public validitydate = false;
  public policevaliditydate = false;
  public isepfverified = false;
  public isesicverified = false;
  public isEPFVerified = false;
  public esicverifydate = false;
  public epfverifydate = false;
  public esicvalue1: boolean;
  public esicvalue: boolean;
  public activeModal: NgbModalRef;

  public unitDepartmentSectionTreeData: any[] = [];
  public sectionTreeList: any[] = [];
  public sectionTreeResponses: any[] = [];
  myTheme: any;
  public nomineeForList: any[] = [];
  public familyDetailsFormGroup: FormGroup;
  public nomineeDetailsFormGroup: FormGroup;
  public editNomineeIndex: number;
  public isAddNomineeDetails = true;
  public editFamilyIndex: number;
  public isAddFamilyDetails = true;
  public showNomineePercentage: boolean;
  public showNomineeFamilyDropdown: boolean;
  // work experience details
  public workExperienceList: any[] = [];
  public workExperienceFormGroup: FormGroup;
  public editWorkExperienceIndex: number;
  public isAddWorkExperience = true;

  // qualification details
  public showNewQualification = true;
  public qualificationList: any[] = [];
  public qualificationFormGroup: FormGroup;
  public editQualificationIndex: number;
  public isAddQualification = true;
  public educationTypeList: any[] = [];
  public adharCardRequired: number;
  public allowedFileTypes: any = "";
  public maxSizeAllowed: any = "";
  globalSettings: any;
  isContractor: any = false;
  tradeMasterParams: TradeMasterParams;
  loginUserId: any;
  isIdentityArrDataAvailable = false;

  public finalCheckList: any[] = [] // this is being updated by the this.checklist in the checkContactDetailsRequired function line number 3492 issue is due to change detection issue
  constructor(
    private labourService: LaboursService,
    private logHelperService: LogHelperService,
    private activatedRoute: ActivatedRoute,
    public sharedService: SharedService,
    public router: Router,
    private fileUploadService: FileUploadService,
    public sharedDataService: SharedDataService,
    private modalService: NgbModal,
    private changeDetect: ChangeDetectorRef,
    @Inject("Window") window: Window,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    zone: NgZone,
    private entryfordayService: EntryfordayService,
    private commonApiService: CommonApiService,
    private commonService: CommonService
  ) {
    super(window, zone);
    this.onDestroy = new Subject();
    this.datePickerPlaceHolder =
      this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode =
      this.sharedService.datePickerSettings.pickerMode;
    // EPF format
    this.epfFormat = this.sharedService.globalInputMask.EPFFormat;
    this.epfPlaceHolder = this.sharedService.globalInputMask.EPFPlaceHolder;
    // ESIC format
    this.esicFormat = this.sharedService.globalInputMask.ESICFormat;
    this.esicPlaceHolder = this.sharedService.globalInputMask.ESICPlaceHolder;

    this.nomineeForList = this.getNominationForList();

    this.familyDetailsFormGroup = this.buildFamilyDetailsFormGroup();
    this.nomineeDetailsFormGroup = this.buildNomineeDetailsFormGroup();
    this.workExperienceFormGroup = this.buildWorkExperienceDetailsFormGroup();
    this.qualificationFormGroup = this.buildQualificationDetailsFormGroup();
    this.educationTypeList = this.getEducationTypeList();

    this.languageList = this.getLanguageList();

    this.tradeMasterParams = {
      UserId: GLOBAL.USER_ID
        ? parseInt(GLOBAL.USER_ID)
        : 0,
    };
  }

  /**
   * @description
   * @author Amit Mahida
   * @memberof CreateSubWorkOrderComponent
   */
  ngOnInit() {
    this.loadGlobalSettings();
    this.getCasts();
    //
    this.labourService.getFormData().subscribe((res: any) => {
      this.esicvalue1 = res;
    });
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      if (res) {
        this.globalSettings = res.Data.Table;
        this.maxSizeAllowed =
          Number(
            this.globalSettings.find((item) => item.Code === "DOCSIZEL1").Value
          ) * 1024;
        this.allowedFileTypes = this.globalSettings.find(
          (item) => item.Code === "DOCFORMATL1"
        ).Value;
      }
    });
    const roles = JSON.parse(localStorage.getItem("Role"));
    this.isContractor = roles.some((item) => item.Role_Code === 119);
    this.esicvalue = this.esicvalue1;

    this.labourService.getData().subscribe((res: any) => {
      this.labourDetailsForm = res;
    });
    this.commonService.getJobTypes().subscribe((res: any) => {
      this.labourDemandTypes = res[0].Values;
    });
    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
    //   this.isepfverified = false;
    //   this.isesicverified = false;
    //   this.esicverifydate = false;
    //   this.epfverifydate = false;
    //   this.isEPFVerified = false;
    // } else {
    //   this.isepfverified = true;
    //   this.isesicverified = false;
    //   this.esicverifydate = true;
    //   this.epfverifydate = true;
    //   this.isEPFVerified = true;
    // }

    // this.nationalityData1 = this.labourDetailsForm.controls.nationality.value;
    // this.nationalityData = [{ Id: 1, Name: this.nationalityData1 }];

    // this.labourForm.controls['NationalityMasterList_Code'].setValue(this.nationalityData[0].Id);

    this.sharedDataService.getMasterData().subscribe((masterList: any) => {
      if (masterList && masterList.Data && masterList.Data.Table.length > 0) {
        this.masterData = masterList.Data.Table;

        const status = this.masterData.filter(
          (list) => list.Parent_Code === 610
        );
        this.firestatustype = this.bindDataforMasterItem(status);
        this.statustype = this.bindDataforMasterItem(status);
        this.gendertypes = this.masterData.filter(
          (list) => list.Parent_Code === 302
        );
        this.relationType = this.masterData.filter(
          (list) => list.Parent_Code === 198
        );
        this.bloodGroupType = this.masterData.filter(
          (list) => list.Parent_Code === 585
        );
        this.documentType = this.masterData.filter(
          (list) => list.Parent_Code === 585
        );
        this.labRelationshipAll = this.masterData.filter(
          (list) => list.Parent_Code === 198
        );
        this.maritalStatus = this.masterData.filter(
          (list) => list.Parent_Code === 294
        );
        this.religionTypes = this.masterData.filter(
          (list) => list.Parent_Code === 362
        );
        this.identityTypes = this.masterData.filter(
          (list) => list.Parent_Code === 575
        );
        this.drivingLicenceType = this.masterData.filter(
          (list) => list.Parent_Code === 194
        );
        this.qualifications = this.masterData.filter(
          (list) => list.Parent_Code === 331
        );
        this.technicalQualifications = this.masterData.filter(
          (list) => list.Parent_Code === 342 && list.IsDelete === false
        );
        this.qulificationstreams = this.masterData.filter(
          (list) => list.Parent_Code === 352
        );
        this.bankNames = this.masterData.filter(
          (list) => list.Parent_Code === 145
        );
        this.accountTypesAll = this.masterData.filter(
          (list) => list.Parent_Code === 213
        );
        this.bankPaymentTypes = this.masterData.filter(
          (list) => list.Parent_Code === 303
        );
        this.typeOfAddress = this.masterData.filter(
          (list) => list.MasterItem_Code === 558 || list.MasterItem_Code === 559
        );
        this.erIStatus = this.masterData.filter(
          (list) => list.Parent_Code === 598
        );
        this.drivingLicenceTypes = this.masterData.filter(
          (list) => list.Parent_Code === 194
        );
        this.eRILevel = [
          { Id: 1, Name: "Managerial/ SuperWiser" },
          { Id: 0, Name: "Non Managerial" },
        ];
        this.yesNoData = [
          { Id: 1, Name: "Yes" },
          { Id: 0, Name: "No" },
        ];
        this.addresstype = [
          { Id: 1, Name: "Local Address" },
          { Id: 0, Name: "Permenent Address" },
        ];
        this.workOrderTypes = this.masterData.filter(
          (list) => list.Parent_Code === 226
        );
        this.cardType = this.masterData.filter(
          (list) => list.Parent_Code === 601
        );
        this.workSites = this.masterData.filter(
          (list) => list.Parent_Code === 612
        );
        this.contactTypes = this.masterData.filter(
          (list) => list.Parent_Code === 560
        );
        this.workCategory = this.masterData.filter(
          (list) => list.Parent_Code === 618
        );
        this.workmanCategory = this.masterData.filter(
          (list) => list.Parent_Code === 858
        );
        this.validityperiod = this.masterData.filter(
          (list) => list.Parent_Code === 875
        );
        this.wagecriteria = [
          { Id: 1, Name: "Wage / Salary < 21000.00" },
          { Id: 0, Name: "Wage / Salary ≥ 21000.00" },
        ];
        // this.nationalityData = [{ Id: 1, Name: 'Indian' }, { Id: 0, Name: 'Foreigner' }];
        this.nationalityData = this.masterData.filter(
          (list) => list.Parent_Code === 479
        );
        this.regularweekly = [
          { Id: 1, Name: "Sun" },
          { Id: 2, Name: "Mon" },
          { Id: 3, Name: "Tue" },
          { Id: 4, Name: "Wed" },
          { Id: 5, Name: "Thu" },
          { Id: 6, Name: "Fri" },
          { Id: 7, Name: "Sat" },
        ];
        this.labRelationship = [];
        this.labRelationshipAll.forEach((val) => {
          if (val.Name === "Father" || val.Name === "Husband") {
            this.labRelationship.push(val);
          }
        });
        this.accountTypes = [];
        this.accountTypesAll.forEach((val) => {
          if (val.Name === "Saving" || val.Name === "Current") {
            this.accountTypes.push(val);
          }
        });
        if (masterList.length) {
          this.labourForm.reset();

          // this.address = [
          //   { Id: 1, Name: 'Local Address' }, { Id: 2, Name: 'Permenent Address' }];

          // this.router.navigate(['labour/create-sub-labour']);
          // this.labourForm.controls.LAddressTypeMasterList_Code.setValue(558);
          // this.labourForm.controls.PAddressTypeMasterList_Code.setValue(559);
          // this.labourForm.controls.LAddressTypeMasterList_Code.value === 558;
          // this.labourForm.controls.PAddressTypeMasterList_Code.value === 559;

          // this.localtypeOfAddress = [];
          // this.permenenttypeOfAddress = [];
          // this.typeOfAddress.forEach((val) => {
          //   //
          //   if (val.Name === 'Local Address') {
          //     this.localtypeOfAddress.push(val);
          //     if (this.localtypeOfAddress.length === 1) {
          //       this.labourForm.controls['LAddressTypeMasterList_Code'].setValue(this.localtypeOfAddress[0].MasterItem_Code);
          //       // this.labourForm.controls['LAddressTypeMasterList_Code'].value === this.localtypeOfAddress[0].MasterItem_Code;
          //       // this.labourForm.control.value['LAddressTypeMasterList_Code'] === this.localtypeOfAddress[0].MasterItem_Code;

          //     }
          //   } else if (val.Name === 'Permenent Address') {
          //     this.permenenttypeOfAddress.push(val);
          //     if (this.permenenttypeOfAddress.length === 1) {
          //       this.labourForm.controls['PAddressTypeMasterList_Code'].setValue(this.permenenttypeOfAddress[0].MasterItem_Code);
          //       // this.labourForm.controls['PAddressTypeMasterList_Code'].value === this.permenenttypeOfAddress[0].MasterItem_Code;
          //       // this.labourForm.control.value['PAddressTypeMasterList_Code'] === this.permenenttypeOfAddress[0].MasterItem_Code;

          //     }
          //   }
          // });

          // this.labourForm.controls.ER1StatusMasterItem_Code.setValue(599);
        }
        // this.router.navigateByUrl('/CreateSubLabourComponent', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/labour/create-sub-labour']);
        // });

        // this.router.routeReuseStrategy.shouldReuseRoute = function () {
        //   return true;
        // };
      }
    });

    this.stepDataSubscription =
      this.sharedService.stepFinalDataSubject.subscribe((data) => { });

    this.formChangesSubscription = this.labourForm.form.valueChanges.subscribe(
      (form) => {
        if (
          form["TypeOfIdentityMasterItem_Code"] &&
          form["NameInIdentity"] &&
          form["IdentiyNo"]
        ) {
          this.disableAddPOI = false;
        } else {
          this.disableAddPOI = true;
        }
      }
    );

    this.getSkillType();
    // this.getPlants();
    // this.getUnits();
    this.getCompanyData();
    this.getDepartmentPlantUnitSectionData();
    // this.getCountry(); if call when zipcode didnt get
    // this.getDepartmentData();
    // this.getSectionData();
    this.getAllTrade();
    // this.getAddressCode(); // kh commeted api not found 30-04-2021
    // this.getLabourCode(); // kh commeted api not found 30-04-2021
    // this.getCategory();
    // this.getGatePassRequestCode(); // kh commeted api not found 30-04-2021
    this.shiftGroupData();
    this.changeIdentitytype();
    this.getDisabilityTypes();

    // this.checkAge();
    // this.getMasterItemListData();

    // this.masterItemDataSubscription = this.sharedService.getMasterData().subscribe((masterList: any) => {
    //   //
    //   this.masterData = masterList;

    //   this.masterData = masterList;
    //   const status = this.masterData.filter(list => list.Parent_Code === 610);
    //   this.firestatustype = this.bindDataforMasterItem(status);
    //   this.statustype = this.bindDataforMasterItem(status);
    //   this.gendertypes = this.masterData.filter(list => list.Parent_Code === 302);
    //   this.relationType = this.masterData.filter(list => list.Parent_Code === 198);
    //   this.bloodGroupType = this.masterData.filter(list => list.Parent_Code === 585);
    //   this.documentType = this.masterData.filter(list => list.Parent_Code === 585);
    //   this.labRelationshipAll = this.masterData.filter(list => list.Parent_Code === 198);
    //   this.maritalStatus = this.masterData.filter(list => list.Parent_Code === 294);
    //   this.religionTypes = this.masterData.filter(list => list.Parent_Code === 362);
    //   this.identityTypes = this.masterData.filter(list => list.Parent_Code === 575);
    //   this.drivingLicenceType = this.masterData.filter(list => list.Parent_Code === 194);
    //   this.qualifications = this.masterData.filter(list => list.Parent_Code === 331);
    //   this.technicalQualifications = this.masterData.filter(list => list.Parent_Code === 342);
    //   this.qulificationstreams = this.masterData.filter(list => list.Parent_Code === 352);
    //   this.bankNames = this.masterData.filter(list => list.Parent_Code === 145);
    //   this.accountTypes = this.masterData.filter(list => list.Parent_Code === 145);
    //   this.bankPaymentTypes = this.masterData.filter(list => list.Parent_Code === 303);
    //   this.typeOfAddress = this.masterData.filter(list => list.MasterItem_Code === 558 || list.MasterItem_Code === 559);
    //   this.erIStatus = this.masterData.filter(list => list.Parent_Code === 598);
    //   this.drivingLicenceTypes = this.masterData.filter(list => list.Parent_Code === 194);
    //   this.eRILevel = [{ Id: 1, Name: 'Managerial/ SuperWiser' }, { Id: 0, Name: 'Non Managerial' }];
    //   this.labourDemandTypes = [{ Id: 0, Name: 'General' }, { Id: 1, Name: 'Shutdown' }];
    //   this.yesNoData = [{ Id: 1, Name: 'Yes' }, { Id: 0, Name: 'No' }];
    //   this.workOrderTypes = this.masterData.filter(list => list.Parent_Code === 226);
    //   this.cardType = this.masterData.filter(list => list.Parent_Code === 601);
    //   this.workSites = this.masterData.filter(list => list.Parent_Code === 612);
    //   this.contactTypes = this.masterData.filter(list => list.Parent_Code === 560);
    //   this.wagecriteria = [{ Id: 1, Name: 'ESIC Applicable' }, { Id: 0, Name: 'E.C Policy Applicable' }];
    //   this.nationalityData = [{ Id: 1, Name: 'Indian' }, { Id: 0, Name: 'Foreigner' }];
    //   this.regularweekly = [
    //     { Id: 1, Name: 'Sun' },
    //     { Id: 2, Name: 'Mon' }, { Id: 3, Name: 'Tue' },
    //     { Id: 4, Name: 'Wed' }, { Id: 5, Name: 'Thu' },
    //     { Id: 6, Name: 'Fri' }, { Id: 7, Name: 'Sat' }];
    //   this.labRelationship = [];
    //   this.labRelationshipAll.forEach((val) => {
    //     //
    //     if (val.Name === 'Father' || val.Name === 'Husband') {
    //       this.labRelationship.push(val);
    //     }
    //   });
    //   if (masterList.length) {
    //     this.labourForm.reset();
    //     this.labourForm.controls.LAddressTypeMasterList_Code.setValue(558);
    //     this.labourForm.controls.PAddressTypeMasterList_Code.setValue(559);
    //     this.labourForm.controls.ER1StatusMasterItem_Code.setValue(599);
    //   }
    // });

    this.initCheckList();


    const res = {
      Page_Code: 196,
      RequireType: 1,
      ActionType: 0,
      UserId: GLOBAL.USER_ID,
      Search: null,
      SelectedField: "Name,DocumentType_Code,page_code",
    };
    this.labourService.getDocumentTypesPageModuleWise(res).subscribe((docTypes: any) => {
        this.documentTypes = docTypes;
      });

    // this.disableCompliancePageControl();

    setTimeout(() => {
      this.labourForm.form.get("DisabilityType_Code").setValue(null);
      this.labourForm.form.get("DisabilityType_Code").disable();
    }, 10);

    this.editLabourCode = this.activatedRoute.snapshot.paramMap.get("id");
    if (this.editLabourCode) {
      this.editMode = true;
      this.labourService
        .getLabourByCode(this.editLabourCode)
        .subscribe((response: any) => {
          const labour = response.Data;
          if (!labour) {
            return;
          }
          this.editLabourData = labour;
          this.labourForm.reset();
          // Step-1 start
          // this.labourForm.controls['Company'].setValue(labour.Company_Code);
          // Labour personal details :
          this.labourForm.controls["FirstName"].setValue(labour.FirstName);
          this.labourForm.controls["HindiFirstName"].setValue(
            labour.HindiFirstName
          );
          this.labourForm.controls["MiddleName"].setValue(labour.MiddleName);
          this.labourForm.controls["HindiMiddleName"].setValue(
            labour.HindiMiddleName
          );
          this.labourForm.controls["LastName"].setValue(labour.LastName);
          this.labourForm.controls["HindiLastName"].setValue(
            labour.HindiLastName
          );
          this.labourForm.controls["RelationMasterList_Code"].setValue(
            labour.RelationMasterList_Code
          );
          this.labourForm.controls["DOB"].setValue(labour.DOB);
          this.labourForm.controls["PlaceofBirth"].setValue(
            labour.PlaceofBirth
          );
          this.calculateAge();
          const mobileNumber = labour.MobileNumber;
          const isValidMobileNumber = /^[6-9]\d{9}$/.test(mobileNumber) && mobileNumber.length === 10;
          this.labourForm.controls["MobileNumber"].setValue(isValidMobileNumber ? mobileNumber : null);
          this.labourForm.controls["maritualStatus"].setValue(
            labour.MaritalStatusMasterList_Code
          );
          this.labourForm.controls["IdentificationMark"].setValue(
            labour.IdentificationMark
          );
          this.labourForm.controls["GenderMasterList_Code"].setValue(
            labour.GenderMasterList_Code
          );
          this.labourForm.controls["ReligionMasterList_Code"].setValue(
            labour.ReligionMasterList_Code
          );
          this.labourForm.controls["Cast"].setValue(labour.CastMasterList_Code);
          this.labourForm.controls["NationalityMasterList_Code"].setValue(
            labour.NationalityMasterList_Code
          );
          this.changeNationality()
          this.labourForm.controls["BloodGroup"].setValue(labour.BloodGroup);
          this.labourForm.controls["IllnessDetails"].setValue(
            labour.IllnessDetails
          );
          this.labourForm.controls["isConvicted"].setValue(labour.isConvicted);
          // this.labourForm.controls["Language_Code"].setValue(
          //   labour.Language_Code
          // );

          // this.labourForm.controls["TechnicalCourse"].setValue(
          //   labour.TechnicalCourse
          // );
          if (labour.Hlanguage) {
            this.labourForm.controls["Language_Code"].setValue(1);
          } else if (labour.Elanguage) {
            this.labourForm.controls["Language_Code"].setValue(2);
          } else {
            this.labourForm.controls["Language_Code"].setValue(3);
          }
          if (this.labourForm.controls["language"]) {
            this.labourForm.controls["language"].setValue(labour.language);
          }
          // this.labourForm.controls["TechnicalCourse"].setValue(
          //   labour.TechnicalCourse
          // );

          // Proof Of Identity :
          this.identityDetails = labour.IdentityReferenceViews;
          // Qualification & Experience Details :
          this.labourForm.controls["QualificationMasterList_Code"].setValue(
            labour.QualificationMasterList_Code
          );
          this.labourForm.controls["TechQualificationMasterList_Code"].setValue(
            labour.TechQualificationMasterList_Code
          );
          this.labourForm.controls[
            "QualificationStreamMasterList_Code"
          ].setValue(labour.QualificationStreamMasterList_Code);
          // this.labourForm.controls['TotalExpr'].setValue(labour.TotalExpr);
          // this.labourForm.controls['PreviousOrg'].setValue(labour.PreviousOrg);

          // Proof Of Address :
          // this.labourForm.controls['LAddressTypeMasterList_Code'].setValue(labour.AddressRefrenceViews[0].AddressTypeMasterList_Code);
          this.labourForm.controls["LAddress1"].setValue(
            labour.AddressRefrenceViews[0].Address1
          );
          this.labourForm.controls["LAddress2"].setValue(
            labour.AddressRefrenceViews[0].Address2
          );
          this.labourForm.controls["LAddress3"].setValue(
            labour.AddressRefrenceViews[0].Address3
          );
          this.labourForm.controls["LZipCode"].setValue(
            labour.AddressRefrenceViews[0].ZipCode
          );
          this.getCountry().subscribe((res: any) => {
            this.lcountries = res;
            this.contactcountries = res;
            this.familycountries = res;

            this.labourForm.controls["LCountry_Code"].setValue(
              labour.AddressRefrenceViews[0].Country_Code
            );

            this.getStatesByCountryId().subscribe((res: any) => {
              this.lStates = res;
              this.labourForm.controls["LState_Code"].setValue(
                labour.AddressRefrenceViews[0].State_Code
              );

              this.getDistrictsByState().subscribe((res: any) => {
                this.lDistricts = res;
                this.labourForm.controls["LDistrict_Code"].setValue(
                  labour.AddressRefrenceViews[0].District_Code
                );
                this.getCitiesByDistrict().subscribe((res: any) => {
                  this.lCities = res;
                  this.labourForm.controls["LCity_Code"].setValue(
                    labour.AddressRefrenceViews[0].City_Code
                  );
                  this.getPostOfficeByCity().subscribe((res: any) => {
                    this.lPos = res;
                    this.labourForm.controls["LPostOffice_Code"].setValue(
                      labour.AddressRefrenceViews[0].PostOffice_Code
                    );

                    this.getVillage().subscribe((res: any) => {
                      this.lVillages = res.Data.Table;
                      this.labourForm.controls["LVillage_Code"].setValue(
                        labour.AddressRefrenceViews[0].Village_Code
                      );
                    })
                  })

                });
              });

            });
          });
          // this.labourForm.controls['PAddressTypeMasterList_Code'].setValue(labour.AddressRefrenceViews[1].AddressTypeMasterList_Code);
          this.labourForm.controls["PAddress1"].setValue(
            labour.AddressRefrenceViews[1].Address1
          );
          this.labourForm.controls["PAddress2"].setValue(
            labour.AddressRefrenceViews[1].Address2
          );
          this.labourForm.controls["PAddress3"].setValue(
            labour.AddressRefrenceViews[1].Address3
          );
          this.labourForm.controls["PZipCode"].setValue(
            labour.AddressRefrenceViews[1].ZipCode
          );
          this.getCountry().subscribe((res: any) => {
            this.pcountries = res;
            this.labourForm.controls["PCountry_Code"].setValue(
              labour.AddressRefrenceViews[1].Country_Code
            );
            this.getStatesByCountryIdP().subscribe((res: any) => {
              this.pStates = res;
              this.labourForm.controls["PState_Code"].setValue(
                labour.AddressRefrenceViews[1].State_Code
              );

              this.getDistrictsByStateP().subscribe((res: any) => {
                this.pDistricts = res;
                this.labourForm.controls["PDistrict_Code"].setValue(
                  labour.AddressRefrenceViews[1].District_Code
                );

                this.getCitiesByDistrictP().subscribe((res: any) => {
                  this.pCities = res;
                  this.labourForm.controls["PCity_Code"].setValue(
                    labour.AddressRefrenceViews[1].City_Code
                  );

                  this.getPostOfficeByCityP().subscribe((res: any) => {
                    this.pPos = res;
                    this.labourForm.controls["PPostOffice_Code"].setValue(
                      labour.AddressRefrenceViews[1].PostOffice_Code
                    );

                    this.getVillageP().subscribe((res: any) => {
                      this.pVillages = res.Data.Table;
                      this.labourForm.controls["PVillage_Code"].setValue(
                        labour.AddressRefrenceViews[1].Village_Code
                      );
                    })
                  })
                });
              });
            });
          });


          // Step-1 Background Check :
          this.labourForm.controls["Company_Code"].setValue(
            labour.GatePassRequestView.Company_Code
          );
          this.labourForm.controls["Plant_Code"].setValue(
            labour.GatePassRequestView.Plant_Code
          );
          this.getUnits(labour.GatePassRequestView.Plant_Code);
          this.labourForm.controls["Unit_Code"].setValue(
            labour.GatePassRequestView.Unit_Code
          );
          this.getDepartments(labour.GatePassRequestView.Unit_Code);
          this.labourForm.controls["department"].setValue(
            labour.GatePassRequestView.Department_Code
          );
          this.getSections(labour.GatePassRequestView.Department_Code);
          this.labourForm.controls["Section_Code"].setValue(
            labour.GatePassRequestView.Section_Code
          );
          this.getSections1(labour.GatePassRequestView.Section_Code);
          this.labourForm.controls["Section_Code1"].setValue(
            labour.GatePassRequestView.Section_Code1
          );
          this.getSections2(labour.GatePassRequestView.Section_Code1);
          this.labourForm.controls["Section_Code2"].setValue(
            labour.GatePassRequestView.Section_Code2
          );

          this.labourForm.controls["PoliceVerificationDate"].setValue(
            labour.GatePassRequestView.PoliceVerificationDate
          );
          this.labourForm.controls["PoliceValidityDate"].setValue(
            labour.GatePassRequestView.PoliceValidityDate
          );

          // Step-1 Background Check end:
          this.labourForm.controls["Email1"].setValue(labour.Email1);
          this.labourForm.controls["Email2"].setValue(labour.Email2);
          this.labourForm.controls["Email3"].setValue(labour.Email3);
          this.labourForm.controls["Height"].setValue(labour.Height);
          this.labourForm.controls["Weight"].setValue(labour.Weight);
          this.labourForm.controls["IsPhysicallyChallenge"].setValue(
            labour.IsPhysicallyChallenge
          );
          this.onSpeciallyAbledChange();
          this.labourForm.controls["DisabilityType_Code"].setValue(
            labour.DisabilityType_Code
          );

          // step-1 work experiences
          this.workExperienceList = labour.LabourWorkExperiences || [];

          // education qualification list
          this.qualificationList = labour.LabourQualifications || [];
          if (this.qualificationList && this.qualificationList.length) {
            this.qualificationList.forEach((item: any) => {
              const educationType = this.educationTypeList.find(
                (edcType) =>
                  edcType.MasterItem_Code === item.EducationTypeMLT_Code
              );
              // item.EducationName = educationType ? educationType.Name : "";
            });
          }

          // Step-1 end

          // Step-2 start
          // Official Information :
          this.labourForm.controls["JoiningDate"].setValue(
            labour.GatePassRequestView.JoiningDate
          );
          this.labourForm.controls["IssueDate"].setValue(
            labour.GatePassRequestView.IssueDate
          );
          this.labourForm.controls["ValidUptoDate"].setValue(
            labour.GatePassRequestView.ValidUptoDate
          );
          this.labourForm.controls["LabourDemandTypeMasterItem_Code"].setValue(
            labour.GatePassRequestView.LabourDemandTypeMasterItem_Code
          );
          this.labourForm.controls["Skill_Code"].setValue(
            labour.GatePassRequestView.Skill_Code
          );
          this.labourForm.controls["OldGatePassNo"].setValue(
            labour.GatePassRequestView.OldGatePassNo
          );
          this.labourForm.controls["WorkCategoryMIT_Code"].setValue(
            labour.GatePassRequestView.WorkCategoryMIT_Code
          );
          this.labourForm.controls["CategoryMIL_Code"].setValue(
            labour.GatePassRequestView.CategoryMIL_Code
          );
          this.labourForm.controls["WorksiteMasterItemList_Code"].setValue(
            labour.WorkSiteCategoryCodes[0]
          );
          this.labourForm.controls["ValidityPeriod_Code"].setValue(
            labour.WorkSiteCodes[0]
          );
          // this.labourForm.controls['WorksiteMasterItemList_Code'].setValue(labour.WorkSiteCodes[0]);

          this.labourForm.controls["Trade_Code"].setValue(
            labour.GatePassRequestView.Trade_Code ? labour.GatePassRequestView.Trade_Code : null
          );

          //this.checkDrivingLicenseDetailsRequired();
          this.qualificationList = labour.LabourQualifications;
          this.workExperienceList = labour.LabourWorkExperiences;

          // Step-2 end
          this.yesNoData1 = [];
          if (this.wagecriteria) {
            if (labour.GatePassRequestView.ESICWageCriteria === true) {
              this.wagecriteria.forEach((val) => {
                //
                if (val.Name === "Wage / Salary < 21000.00") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["ESICWageCriteria"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
              });
            } else {
              // this.yesNoData1 = [];
              this.wagecriteria.forEach((val) => {
                //
                if (val.Name === "Wage / Salary ≥ 21000.00") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["ESICWageCriteria"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
              });
            }
          }

          // Step-3 start
          // Compliance Information :
          this.yesNoData1 = [];
          if (this.yesNoData) {
            if (labour.GatePassRequestView.IsPFMembership === true) {
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "Yes") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["IsPFMembership"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
              });
            } else {
              // this.yesNoData1 = [];
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "No") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["IsPFMembership"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
              });
            }
          }

          const isPFMember = this.labourForm.controls["IsPFMembership"].value;
          if (isPFMember) {
            this.labourForm.form.get("isAlreadyPFMember").enable();
            this.labourForm.form.get("PFUANNo").enable();
            this.labourForm.form.get("PreviousPFNo").enable();
            this.labourForm.form.get("PreviousExitDate").enable();
            this.labourForm.form.get("CertificateNo").enable();
            this.labourForm.form.get("PPOno").enable();
            //this.labourForm.form.get('EPFNo').enable();

            if (
              labour.GatePassRequestView.PFUANNo &&
              labour.GatePassRequestView.PreviousPFNo &&
              labour.GatePassRequestView.PreviousExitDate
            ) {
              this.labourForm.form.get("isAlreadyPFMember").setValue(true);
            }
            this.onAlreadyPFMemberChange();
            this.labourForm.form
              .get("PFUANNo")
              .setValue(labour.GatePassRequestView.PFUANNo);
            this.labourForm.form
              .get("PreviousPFNo")
              .setValue(labour.GatePassRequestView.PreviousPFNo);
            this.labourForm.form
              .get("PreviousExitDate")
              .setValue(labour.GatePassRequestView.PreviousExitDate);
            this.labourForm.form
              .get("CertificateNo")
              .setValue(labour.GatePassRequestView.CertificateNo);
            this.labourForm.form
              .get("PPOno")
              .setValue(labour.GatePassRequestView.PPOno);
          }

          // if (labour.GatePassRequestView.PreviouslyWorkInOrg === true) {
          //   this.yesNoData1 = [];
          //   this.yesNoData.forEach((val) => {
          //     //
          //     if (val.Name === 'Yes') {
          //       this.yesNoData1.push(val);
          //       if (this.yesNoData1.length === 1) {
          //         this.labourForm.controls['PreviouslyWorkInOrg'].setValue(this.yesNoData1[0].Id);
          //       }
          //     }
          //   });
          // } else {
          //   this.yesNoData1 = [];
          //   this.yesNoData.forEach((val) => {
          //     //
          //     if (val.Name === 'No') {
          //       this.yesNoData1.push(val);
          //       if (this.yesNoData1.length === 1) {
          //         this.labourForm.controls['PreviouslyWorkInOrg'].setValue(this.yesNoData1[0].Id);
          //       }
          //     }
          //   });
          // }
          this.yesNoData1 = [];
          if (this.yesNoData) {
            if (labour.GatePassRequestView.IsESICMembership === true) {
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "Yes") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["IsESICMembership"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
              });
            } else {
              // this.yesNoData1 = [];
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "No") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["IsESICMembership"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
              });
            }
          }
          const isESICMembership =
            this.labourForm.controls["IsESICMembership"].value;
          if (isESICMembership) {
            this.labourForm.form.get("ESICBranchOffice").enable();
            this.labourForm.form.get("Dispensary").enable();
            this.labourForm.form.get("ESICRegistrationDate").enable();
            //this.labourForm.form.get('ESICNo').enable();

            this.labourForm.form
              .get("ESICBranchOffice")
              .setValue(labour.GatePassRequestView.ESICBranchOffice);
            this.labourForm.form
              .get("Dispensary")
              .setValue(labour.GatePassRequestView.Dispensary);
            this.labourForm.form
              .get("ESICRegistrationDate")
              .setValue(labour.GatePassRequestView.ESICRegistrationDate);
          }
          this.yesNoData1 = [];
          if (this.yesNoData) {
            if (labour.GatePassRequestView.ESICVerificationBy === 1) {
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "Yes") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["ESICVerificationBy"].setValue(
                      this.yesNoData1[0].Id
                    );
                    if (
                      JSON.parse(localStorage.getItem("Role"))[0].Role_Code ===
                      118
                    ) {
                      this.esicatpresent = false;
                      this.dateofExtension1 = false;
                      this.showESICNo = true;
                      this.pfuanno = true;
                      this.isesicverified = false;
                      this.esicverifydate = false;
                    } else {
                      this.esicatpresent = false;
                      this.dateofExtension1 = false;
                      this.showESICNo = false;
                      this.pfuanno = false;
                      this.isesicverified = false;
                      this.esicverifydate = false;
                    }
                  }
                }
              });
            } else {
              // this.yesNoData1 = [];
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "No") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["ESICVerificationBy"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
                if (isESICMembership) {
                  this.showESICNo = true;
                }
              });
            }
          }

          this.yesNoData1 = [];
          if (this.yesNoData) {
            if (labour.GatePassRequestView.IsEPFVerified === true) {
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "Yes") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["IsEPFVerified"].setValue(
                      this.yesNoData1[0].Id
                    );
                    if (
                      JSON.parse(localStorage.getItem("Role"))[0].Role_Code ===
                      118
                    ) {
                      this.pfatpresent = false;
                      this.dateofExtension = false;
                      this.showEPFNo = true;
                      this.pfuanno = true;
                      this.isEPFVerified = false;
                      this.epfverifydate = false;
                    } else {
                      this.pfatpresent = false;
                      this.dateofExtension = false;
                      this.showEPFNo = false;
                      this.pfuanno = false;
                      this.isEPFVerified = false;
                      this.epfverifydate = false;
                    }
                  }
                }
              });
            } else {
              // this.yesNoData1 = [];
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "No") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    this.labourForm.controls["IsEPFVerified"].setValue(
                      this.yesNoData1[0].Id
                    );
                  }
                }
                if (isPFMember) {
                  this.showEPFNo = true;
                }
              });
            }
          }

          this.yesNoData1 = [];
          if (this.yesNoData) {
            if (labour.GatePassRequestView.isDGMSVerify === true) {
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "Yes") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    // this.labourForm.controls['isDGMSVerify'].setValue(this.yesNoData1[0].Id);
                  }
                }
              });
            } else {
              // this.yesNoData1 = [];
              this.yesNoData.forEach((val) => {
                //
                if (val.Name === "No") {
                  this.yesNoData1.push(val);
                  if (this.yesNoData1.length === 1) {
                    // this.labourForm.controls['isDGMSVerify'].setValue(this.yesNoData1[0].Id);
                  }
                }
              });
            }
          }
          this.labourForm.controls["PFExtendedDate"].setValue(
            labour.GatePassRequestView.PFExtendedDate
          );
          this.labourForm.controls["ESICExtendedDate"].setValue(
            labour.GatePassRequestView.ESICExtendedDate
          );
          this.labourForm.controls["EPFNo"].setValue(
            labour.GatePassRequestView.EPFNo
          );
          this.labourForm.controls["ESICNo"].setValue(
            labour.GatePassRequestView.ESICNo
          );
          this.labourForm.controls["PFUANNo"].setValue(
            labour.GatePassRequestView.PFUANNo
          );
          this.labourForm.controls["ESICVerificationDate"].setValue(
            labour.GatePassRequestView.ESICVerificationDate
          );
          this.labourForm.controls["verificationDate"].setValue(
            labour.GatePassRequestView.PFVerificationDate
          );
          // Bank Account Details :
          // step - 5

          // step - 6
          if (labour.DocumentImageMasterViewModals) {
            this.uploadedFiles = [];
            labour.DocumentImageMasterViewModals.forEach((file) => {
              this.uploadedFiles.push({
                ID: file.ID,
                file: {
                  lastModified: file.ModifiedDate,
                  name: file.UploadFileName,
                },
                documentTypeCode: file.DocumentTypeMasterList_Code,
                isUploaded: true,
                url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder,
              });
            });
            this.copyUploadedFiles = this.uploadedFiles;
          }
          // step - 6 End
          //this.savedCroppedImage = PHOTO_URL + labour.Photo;
          this.savedCroppedImage = labour.Photo;
          // this.savedCroppedImage = labour.Photo || "";

          if (labour.BankDetailReferenceView) {
            this.labourForm.controls["BankNameMasterItem_Code"].setValue(
              labour.BankDetailReferenceView.BankNameMasterItem_Code
            );
            this.labourForm.controls["AccountTypeMasterList_Code"].setValue(
              labour.BankDetailReferenceView.AccountTypeMasterList_Code
            );
            this.labourForm.controls["NameInBank"].setValue(
              labour.BankDetailReferenceView.NameInBank
            );
            this.labourForm.controls["AccountNumber"].setValue(
              labour.BankDetailReferenceView.AccountNumber
            );
            this.labourForm.controls["BranchName"].setValue(
              labour.BankDetailReferenceView.BranchName
            );
            this.labourForm.controls["IFSCCode"].setValue(
              labour.BankDetailReferenceView.IFSCCode
            );
            this.labourForm.controls["BranchCode"].setValue(
              labour.BankDetailReferenceView.BranchCode
            );
            this.labourForm.controls["PaymentType"].setValue(
              labour.BankDetailReferenceView.AccountTypeMasterList_Code
            );
          }
          // this.labourForm.controls['DGMCMinesCode'].setValue(labour.GatePassRequestView.DGMCMinesCode);
          // this.labourForm.controls['dgmsVerificationDate'].setValue(labour.GatePassRequestView.DGMCVerificationdate);
          // this.labourForm.controls['DGMSVerificationCode'].setValue(labour.GatePassRequestView.DGMCVerifiedBy);
          // Step-3 end

          // Step-4 start
          // Contact Detail :
          this.labourContactPersonViews = labour.LabourContactPersonViews;
          // Step-4 end

          // step - 5
          // Family Member Information :

          let familyIndex = 1;
          // labour.LabourFamilyMasterViews.forEach((element) => {
          //   this.familyInfoData.push({
          //     LabourFamily_Code: element.LabourFamily_Code,
          //     Name: element.Name,
          //     Profession: element.Profession,
          //     NationalityCountry_Code: element.NationalityCountry_Code,
          //     FamilyRelationMasterList_Code: element.FamilyRelationMasterList_Code,
          //     Remark: element.Remark,
          //     // DOB: this.datePipe.transform(element.DOB, 'dd-MM-yyyy'),
          //     DOB: element.DOB,
          //     Relation: element.Relation,
          //     zipCode: element.zipCode,
          //     country: element.country,
          //     state: element.state,
          //     district: element.district,
          //     city: element.city,
          //     PostOffice_Code: element.PostOffice_Code,
          //     Gender: element.Gender,
          //     FamilyBloodGroup: element.FamilyBloodGroup,
          //   });
          //   familyIndex++;
          // });
          this.familyInfoData = labour.LabourFamilyMasterViews;

          // step- 5 nominee details
          this.nomineeInfoData = labour.LabourNomineeMasterViews || [];

          // if (labour.LabourFamilyMasterViews[0]) {
          //   this.labourForm.controls['Name'].setValue(labour.LabourFamilyMasterViews[0].Name);
          //   this.labourForm.controls['Profession'].setValue(labour.LabourFamilyMasterViews[0].Profession);
          //   this.labourForm.controls['FMI_DOB'].setValue(labour.LabourFamilyMasterViews[0].DOB);
          //   this.labourForm.controls['NationalityCountry_Code'].setValue(labour.LabourFamilyMasterViews[0].NationalityCountry_Code);
          //   this.labourForm.controls['RelationMasterList_Code'].setValue(labour.LabourFamilyMasterViews[0].RelationMasterList_Code);
          //   this.labourForm.controls['Remark'].setValue(labour.LabourFamilyMasterViews[0].Remark);
          //   // this.labourForm.controls['AddressTypeMasterList_Code']
          //   //              .setValue(labour.LabourFamilyMasterViews[0].AddressTypeMasterList_Code);
          //   // this.labourForm.controls['addressType3'].setValue(labour.LabourFamilyMasterViews[0].Address1);
          //   // this.labourForm.controls['Address2'].setValue(labour.LabourFamilyMasterViews[0].Address2);
          //   // this.labourForm.controls['Address3'].setValue(labour.LabourFamilyMasterViews[0].Address3);
          //   this.labourForm.controls['zipCode'].setValue(labour.LabourFamilyMasterViews[0].Zipcode);
          //   this.labourForm.controls['country'].setValue(labour.LabourFamilyMasterViews[0].Country_Code);
          //   this.getStatesByCountryIdF();
          //   this.labourForm.controls['state'].setValue(labour.LabourFamilyMasterViews[0].State_Code);
          //   this.getDistrictsByStateF();
          //   this.labourForm.controls['district'].setValue(labour.LabourFamilyMasterViews[0].District_Code);
          //   this.getCitiesByDistrictF();
          //   this.labourForm.controls['city'].setValue(labour.LabourFamilyMasterViews[0].City_Code);
          //   this.getPostOfficeByCityF();
          //   this.labourForm.controls['PostOffice_Code'].setValue(labour.LabourFamilyMasterViews[0].PostOffice_Code);
          //   this.labourForm.controls['Gender'].setValue(labour.LabourFamilyMasterViews[0].Gender);
          //   this.labourForm.controls['FamilyBloodGroup'].setValue(labour.LabourFamilyMasterViews[0].BloodGroup);
          // }
        });
    } else {
      this.editMode = false;
      this.nomineeDetailsFormGroup.disable();
      // this.disableCompliancePageControl()
      this.getCountry().subscribe((res: any) => {
        this.lcountries = res;
        this.pcountries = res;
        this.contactcountries = res;
        this.familycountries = res;
      });
    }

    this.tuiImageEditorOptions = {
      ...this.tuiImageEditorOptions,
      theme: this.myTheme,
      includeUI: {
        loadImage: {
          path: "",
          name: "labour-profile",
        },
      },
    };
  }

  //--mg
  initCheckList(): void {
    this.checkList = [
      {
        id: 1,
        text: "Associate Details",
        open: true,
        childrens: [
          {
            text: "Associate Personal Details",
            title: "Associate Personal Details",
            desc: "First Name, Father / Husband, Last Name, Relationship, Date Of Birth & Gender is required.",
            isValid: () => {
              return (
                this.labourForm.controls.FirstName.valid &&
                this.labourForm.controls.MiddleName.valid &&
                this.labourForm.controls.LastName.valid &&
                this.labourForm.controls.RelationMasterList_Code.valid &&
                this.labourForm.controls.DOB.valid &&
                this.labourForm.controls.GenderMasterList_Code.valid &&
                this.labourForm.controls.maritualStatus.valid &&
                this.labourForm.controls.NationalityMasterList_Code.valid
              );
              // && this.labourForm.controls.BloodGroup.valid;
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.FirstName.dirty ||
                  this.labourForm.controls.FirstName.touched) &&
                  this.labourForm.controls.FirstName.invalid) ||
                ((this.labourForm.controls.MiddleName.dirty ||
                  this.labourForm.controls.MiddleName.touched) &&
                  this.labourForm.controls.MiddleName.invalid) ||
                ((this.labourForm.controls.LastName.dirty ||
                  this.labourForm.controls.LastName.touched) &&
                  this.labourForm.controls.LastName.invalid) ||
                ((this.labourForm.controls.RelationMasterList_Code.dirty ||
                  this.labourForm.controls.RelationMasterList_Code.touched) &&
                  this.labourForm.controls.RelationMasterList_Code.invalid) ||
                ((this.labourForm.controls.DOB.dirty ||
                  this.labourForm.controls.DOB.touched) &&
                  this.labourForm.controls.DOB.invalid) ||
                ((this.labourForm.controls.GenderMasterList_Code.dirty ||
                  this.labourForm.controls.GenderMasterList_Code.touched) &&
                  this.labourForm.controls.GenderMasterList_Code.invalid) ||
                ((this.labourForm.controls.maritualStatus.dirty ||
                  this.labourForm.controls.maritualStatus.touched) &&
                  this.labourForm.controls.maritualStatus.invalid) ||
                ((this.labourForm.controls.NationalityMasterList_Code.dirty ||
                  this.labourForm.controls.NationalityMasterList_Code
                    .touched) &&
                  this.labourForm.controls.NationalityMasterList_Code.invalid)
              );
              // || ((this.labourForm.controls.BloodGroup.dirty || this.labourForm.controls.BloodGroup.touched)
              //   && this.labourForm.controls.BloodGroup.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          {
            text: "Identity Details",
            title: "Identity Details",
            desc: `Age & Address Proof (Aadhar Card, Vorter ID, Retion Card, Passport, NPR),
            Photo Proof (PAN, Aadhar Card, Vorter ID, Ration Card, Passport, NPR) & Aadhar Card is required.`,
            isValid: () => {
              return this.isIdentityDetailsAvailable() === true;
            },
            hasError: () => {
              return this.isIdentityDetailsAvailable() === false;
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          // {
          //   text: 'Background Check',
          //   title: 'Background Check',
          //   desc: `Police Verification Date & Validity Date is required.`,
          //   isValid: () => {
          //     return this.labourForm.controls.PoliceVerificationDate.valid
          //       && this.labourForm.controls.PoliceValidityDate.valid;
          //   },
          //   hasError: () => {
          //     return ((this.labourForm.controls.PoliceVerificationDate.dirty || this.labourForm.controls.PoliceVerificationDate.touched)
          //       && this.labourForm.controls.PoliceVerificationDate.invalid);
          //       || ((this.labourForm.controls.PoliceValidityDate.dirty || this.labourForm.controls.PoliceValidityDate.touched)
          //         && this.labourForm.controls.PoliceValidityDate.invalid);
          //   },
          //   isMandatory: true,
          //   isVisible: true,
          //   step: '1',
          // },

          {
            text: "Education",
            title: "Education",
            desc: this.showNewQualification
              ? "Qualification details are required."
              : `Qualification, Qulification Stream & Technical Qulification is required.`,
            isValid: () => {
              if (this.showNewQualification) {
                return !!this.qualificationList.length;
              }
              return (
                this.labourForm.controls.QualificationMasterList_Code.valid &&
                this.labourForm.controls.QualificationStreamMasterList_Code
                  .valid &&
                this.labourForm.controls.TechQualificationMasterList_Code.valid
              );
            },
            hasError: () => {
              if (this.showNewQualification) {
                return !this.qualificationList.length;
              }
              return (
                ((this.labourForm.controls.QualificationMasterList_Code.dirty ||
                  this.labourForm.controls.QualificationMasterList_Code
                    .touched) &&
                  this.labourForm.controls.QualificationMasterList_Code
                    .invalid) ||
                ((this.labourForm.controls.QualificationStreamMasterList_Code
                  .dirty ||
                  this.labourForm.controls.QualificationStreamMasterList_Code
                    .touched) &&
                  this.labourForm.controls.QualificationStreamMasterList_Code
                    .invalid) ||
                ((this.labourForm.controls.TechQualificationMasterList_Code
                  .dirty ||
                  this.labourForm.controls.TechQualificationMasterList_Code
                    .touched) &&
                  this.labourForm.controls.TechQualificationMasterList_Code
                    .invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          {
            text: "Local Address",
            title: "Local Address",
            desc: `Address line 1, Country, State, District, City, Post Office, Village, Zipcode is required.`,
            isValid: () => {
              return (
                this.labourForm.controls.LAddress1.valid &&
                this.labourForm.controls.LCountry_Code.valid &&
                this.labourForm.controls.LState_Code.valid &&
                this.labourForm.controls.LDistrict_Code.valid &&
                this.labourForm.controls.LCity_Code.valid &&
                this.labourForm.controls.LPostOffice_Code.valid &&
                // && this.labourForm.controls.LVillage_Code.valid
                this.labourForm.controls.LZipCode.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.LAddress1.dirty ||
                  this.labourForm.controls.LAddress1.touched) &&
                  this.labourForm.controls.LAddress1.invalid) ||
                ((this.labourForm.controls.LCountry_Code.dirty ||
                  this.labourForm.controls.LCountry_Code.touched) &&
                  this.labourForm.controls.LCountry_Code.invalid) ||
                ((this.labourForm.controls.LState_Code.dirty ||
                  this.labourForm.controls.LState_Code.touched) &&
                  this.labourForm.controls.LState_Code.invalid) ||
                ((this.labourForm.controls.LDistrict_Code.dirty ||
                  this.labourForm.controls.LDistrict_Code.touched) &&
                  this.labourForm.controls.LDistrict_Code.invalid) ||
                ((this.labourForm.controls.LCity_Code.dirty ||
                  this.labourForm.controls.LCity_Code.touched) &&
                  this.labourForm.controls.LCity_Code.invalid) ||
                ((this.labourForm.controls.LPostOffice_Code.dirty ||
                  this.labourForm.controls.LPostOffice_Code.touched) &&
                  this.labourForm.controls.LPostOffice_Code.invalid) ||
                // || ((this.labourForm.controls.LVillage_Code.dirty || this.labourForm.controls.LVillage_Code.touched)
                //   && this.labourForm.controls.LVillage_Code.invalid)
                ((this.labourForm.controls.LZipCode.dirty ||
                  this.labourForm.controls.LZipCode.touched) &&
                  this.labourForm.controls.LZipCode.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          {
            text: "Domicile Address",
            title: "Domicile Address",
            desc: `Address line 1, Country, State, District, City, Post Office, Village, Zipcode is required.`,
            isValid: () => {
              return (
                this.labourForm.controls.PAddress1.valid &&
                this.labourForm.controls.PCountry_Code.valid &&
                this.labourForm.controls.PState_Code.valid &&
                this.labourForm.controls.PDistrict_Code.valid &&
                this.labourForm.controls.PCity_Code.valid &&
                this.labourForm.controls.PPostOffice_Code.valid &&
                // && this.labourForm.controls.PVillage_Code.valid
                this.labourForm.controls.PZipCode.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.PAddress1.dirty ||
                  this.labourForm.controls.PAddress1.touched) &&
                  this.labourForm.controls.PAddress1.invalid) ||
                ((this.labourForm.controls.PCountry_Code.dirty ||
                  this.labourForm.controls.PCountry_Code.touched) &&
                  this.labourForm.controls.PCountry_Code.invalid) ||
                ((this.labourForm.controls.PState_Code.dirty ||
                  this.labourForm.controls.PState_Code.touched) &&
                  this.labourForm.controls.PState_Code.invalid) ||
                ((this.labourForm.controls.PDistrict_Code.dirty ||
                  this.labourForm.controls.PDistrict_Code.touched) &&
                  this.labourForm.controls.PDistrict_Code.invalid) ||
                ((this.labourForm.controls.PCity_Code.dirty ||
                  this.labourForm.controls.PCity_Code.touched) &&
                  this.labourForm.controls.PCity_Code.invalid) ||
                ((this.labourForm.controls.PPostOffice_Code.dirty ||
                  this.labourForm.controls.PPostOffice_Code.touched) &&
                  this.labourForm.controls.PPostOffice_Code.invalid) ||
                // || ((this.labourForm.controls.PVillage_Code.dirty || this.labourForm.controls.PVillage_Code.touched)
                //   && this.labourForm.controls.PVillage_Code.invalid)
                ((this.labourForm.controls.PZipCode.dirty ||
                  this.labourForm.controls.PZipCode.touched) &&
                  this.labourForm.controls.PZipCode.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },
        ],
      },
      {
        id: 2,
        text: "Official Details",
        open: true,
        childrens: [
          {
            text: "Associate Demand Type",
            title: "Associate Demand Type",
            desc: "Associate Demand Type is required.",
            isValid: () => {
              return this.labourForm.controls.LabourDemandTypeMasterItem_Code
                .valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.LabourDemandTypeMasterItem_Code
                  .dirty ||
                  this.labourForm.controls.LabourDemandTypeMasterItem_Code
                    .touched) &&
                this.labourForm.controls.LabourDemandTypeMasterItem_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "ESIC Wage Criteria",
            title: "ESIC Wage Criteria",
            desc: "ESIC Wage Criteria is required",
            isValid: () => {
              return this.labourForm.controls.ESICWageCriteria.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.ESICWageCriteria.dirty ||
                  this.labourForm.controls.ESICWageCriteria.touched) &&
                this.labourForm.controls.ESICWageCriteria.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Skill Type",
            title: "Skill Type",
            desc: "Skill Type is required",
            isValid: () => {
              return this.labourForm.controls.Skill_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.Skill_Code.dirty ||
                  this.labourForm.controls.Skill_Code.touched) &&
                this.labourForm.controls.Skill_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Organization Details",
            title: "Organization Details",
            desc: "Select Company,Plant,Unit,Department and Section is required.",
            isValid: () => {
              return (
                this.labourForm.controls.Plant_Code.valid &&
                this.labourForm.controls.Unit_Code.valid &&
                this.labourForm.controls.department.valid &&
                this.labourForm.controls.Section_Code.valid &&
                this.labourForm.controls.Section_Code1.valid &&
                this.labourForm.controls.Section_Code2.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.Plant_Code.dirty ||
                  this.labourForm.controls.Plant_Code.touched) &&
                  this.labourForm.controls.Plant_Code.invalid) ||
                ((this.labourForm.controls.Unit_Code.dirty ||
                  this.labourForm.controls.Unit_Code.touched) &&
                  this.labourForm.controls.Unit_Code.invalid) ||
                ((this.labourForm.controls.department.dirty ||
                  this.labourForm.controls.department.touched) &&
                  this.labourForm.controls.department.invalid) ||
                ((this.labourForm.controls.Section_Code.dirty ||
                  this.labourForm.controls.Section_Code.touched) &&
                  this.labourForm.controls.Section_Code.invalid) ||
                ((this.labourForm.controls.Section_Code1.dirty ||
                  this.labourForm.controls.Section_Code1.touched) &&
                  this.labourForm.controls.Section_Code1.invalid) ||
                ((this.labourForm.controls.Section_Code2.dirty ||
                  this.labourForm.controls.Section_Code2.touched) &&
                  this.labourForm.controls.Section_Code2.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Work Site",
            title: "Work Site",
            desc: "Work Site is required",
            isValid: () => {
              return this.labourForm.controls.WorksiteMasterItemList_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.WorksiteMasterItemList_Code.dirty ||
                  this.labourForm.controls.WorksiteMasterItemList_Code
                    .touched) &&
                this.labourForm.controls.WorksiteMasterItemList_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Trade/Designation",
            title: "Trade/Designation",
            desc: "Trade/Designation is required",
            isValid: () => {
              return this.labourForm.controls.Trade_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.Trade_Code.dirty ||
                  this.labourForm.controls.Trade_Code.touched) &&
                this.labourForm.controls.Trade_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Work Category",
            title: "Work Category ",
            desc: "Work Category is required",
            isValid: () => {
              return this.labourForm.controls.WorkCategoryMIT_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.WorkCategoryMIT_Code.dirty ||
                  this.labourForm.controls.WorkCategoryMIT_Code.touched) &&
                this.labourForm.controls.WorkCategoryMIT_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Associate Category",
            title: "Associate Category",
            desc: "Associate Category is required",
            isValid: () => {
              return this.labourForm.controls.CategoryMIL_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.CategoryMIL_Code.dirty ||
                  this.labourForm.controls.CategoryMIL_Code.touched) &&
                this.labourForm.controls.CategoryMIL_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },

          // {
          //   text: 'Shift Group and Week-Off',
          //   title: 'Shift Group and Week-Off',
          //   desc: 'Shift Group and Week-Off is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
          // {
          //   text: 'Domicile Status',
          //   title: 'Domicile Status',
          //   desc: 'Domicile Status is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
          // {
          //   text: 'Emergency Contact',
          //   title: 'Emergency Contact',
          //   desc: 'Emergency Contactis is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
          // {
          //   text: 'Referance Contact',
          //   title: 'Referance Contact',
          //   desc: 'Referance Contact is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
        ],
      },
      {
        id: 3,
        text: "Complaince Details",
        open: true,
        childrens: [
          {
            text: "Cover Under PF",
            title: "Cover Under PF",
            desc: "Cover Under PF is required.",
            isValid: () => {
              if (this.labourForm.controls.IsPFMembership.disabled) {
                return true;
              }
              return this.labourForm.controls.IsPFMembership.valid;
            },
            hasError: () => {
              if (this.labourForm.controls.IsPFMembership.disabled) {
                return false;
              }
              return (
                (this.labourForm.controls.IsPFMembership.dirty ||
                  this.labourForm.controls.IsPFMembership.touched) &&
                this.labourForm.controls.IsPFMembership.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Cover Under ESIC",
            title: "Cover Under ESIC",
            desc: "Cover Under ESIC is required",
            isValid: () => {
              if (this.labourForm.controls.IsESICMembership.disabled) {
                return true;
              }
              return this.labourForm.controls.IsESICMembership.valid;
            },
            hasError: () => {
              if (this.labourForm.controls.IsESICMembership.disabled) {
                return false;
              }
              return (
                (this.labourForm.controls.IsESICMembership.dirty ||
                  this.labourForm.controls.IsESICMembership.touched) &&
                this.labourForm.controls.IsESICMembership.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "UAN Number",
            title: "UAN Number",
            desc: "UAN Number is required.",
            isValid: () => {
              return this.labourForm.controls.PFUANNo.valid;
            },
            hasError: () => {
              if (!this.labourForm.controls.isAlreadyPFMember) {
                return false;
              }
              return (
                (this.labourForm.controls.PFUANNo.dirty ||
                  this.labourForm.controls.PFUANNo.touched) &&
                this.labourForm.controls.PFUANNo.invalid
              );
            },
            isMandatory: true,
            isVisible: false,
            step: "2",
          },
          {
            text: "Previous PF No",
            title: "Previous PF No",
            desc: "Previous PF No is required.",
            isValid: () => {
              return this.labourForm.controls.PreviousPFNo.valid;
            },
            hasError: () => {
              if (!this.labourForm.controls.isAlreadyPFMember) {
                return false;
              }
              return (
                (this.labourForm.controls.PreviousPFNo.dirty ||
                  this.labourForm.controls.PreviousPFNo.touched) &&
                this.labourForm.controls.PreviousPFNo.invalid
              );
            },
            isMandatory: true,
            isVisible: false,
            step: "2",
          },
          {
            text: "Date of exit of previous member ID",
            title: "Date of exit of previous member ID",
            desc: "Date of exit of previous member ID is required.",
            isValid: () => {
              return this.labourForm.controls.PreviousExitDate.valid;
            },
            hasError: () => {
              if (!this.labourForm.controls.isAlreadyPFMember) {
                return false;
              }
              return (
                (this.labourForm.controls.PreviousExitDate.dirty ||
                  this.labourForm.controls.PreviousExitDate.touched) &&
                this.labourForm.controls.PreviousExitDate.invalid
              );
            },
            isMandatory: true,
            isVisible: false,
            step: "2",
          },
        ],
      },

      {
        id: 4,
        text: "Documents Details",
        open: true,
        childrens: [
          {
            text: "Aadhar Card",
            title: "Aadhar Card",
            desc: "Aadhar Card is required",
            isValid: () => {
              if (this.uploadedFiles.length > 0) {
                const isAadhar = this.uploadedFiles.some(
                  (a) => a.documentTypeCode === 117
                );
                if (isAadhar) {
                  return true;
                }
              }
            },
            hasError: () => {
              if (this.uploadedFiles.length === 0) {
                return false;
                // tslint:disable-next-line: no-else-after-return
              } else {
                const isAadhar = this.uploadedFiles.some(
                  (a) => a.documentTypeCode === 117
                );
                if (!isAadhar) {
                  return false;
                }
              }
            },
            isMandatory: true,
            isVisible: true,
            step: "6",
          },
          {
            text: "Profile Photo",
            title: "Profile Photo",
            desc: "Profile Photo is required",
            isValid: () => {
              if (
                this.croppedImage &&
                (this.savedCroppedImage && this.savedCroppedImage != null)
              ) {
                return true;
              }
            },
            hasError: () => {
              if (!this.croppedImage) {
                return false;
              }
            },
            isMandatory: true,
            isVisible: true,
            step: "6",
          },
        ],
      },
    ];
  }

  loadGlobalSettings(): void {
    this.sharedService.getGlobalSettings().subscribe((settings: any) => {
      this.fillCheckList();
      this.fillWizardSteps();
      if (settings && settings.Data && settings.Data.Table) {
        this.globalSettingsCodeArr = settings.Data.Table;
        this.tradeCodeData = settings.Data.Table.find((i) => i.Code === 'MOD152PG195DRVLICTRADE');
        this.drivingLicenseRequiredCode = settings.Data.Table.find((i) => i.Code === 'MOD152PG195DRVLIC');
        this.drivingLicenseRequiredDocumentCode = settings.Data.Table.find((i) => i.Code === 'MOD152PG195DRVLICDOC');
        this.referenceContactRequiredCode = settings.Data.Table.find((i) => i.Code === 'MOD152PG198PROREFC');
        this.emergencyContactRequiredCode = settings.Data.Table.find((i) => i.Code === 'MOD152PG197PROEMG');
        this.medicalDocumentRequiredCode1 = settings.Data.Table.find((i) => i.Code === 'MOD152PG195MEDCERT');
        this.medicalDocumentRequiredCode2 = settings.Data.Table.find((i) => i.Code === 'MOD152PG195MEDCERTSTAGE');
        this.checkAdharCardRequired(settings.Data.Table);
        this.checkBackGroundCheckRequired(settings.Data.Table);
        this.checkBankAccountDetailsRequired(settings.Data.Table);
        this.checkFamilyAndNomineeDetailsRequired(settings.Data.Table);
        this.checkMedicalDocumentRequired();
        this.checkDrivingLicenseDetailsRequired();
        this.checkContactDetailsRequired();
      }
    });
  }

  private fillCheckList() {
    this.checkList = [
      {
        id: 1,
        text: "Associate Details",
        open: true,
        childrens: [
          {
            text: "Associate Personal Details",
            title: "Associate Personal Details",
            desc: "First Name, Father / Husband, Last Name, Relationship, Date Of Birth , Gender , Cast, Marital status, Nationality, Blood group & Trade/Designation is required.",
            isValid: () => {
              return (
                this.labourForm.controls.FirstName.valid &&
                this.labourForm.controls.MiddleName.valid &&
                this.labourForm.controls.LastName.valid &&
                this.labourForm.controls.RelationMasterList_Code.valid &&
                this.labourForm.controls.DOB.valid &&
                this.labourForm.controls.GenderMasterList_Code.valid &&
                this.labourForm.controls.maritualStatus.valid &&
                this.labourForm.controls.NationalityMasterList_Code.valid &&
                this.labourForm.controls.BloodGroup.valid &&
                this.labourForm.controls.Cast.valid &&
                this.labourForm.controls.Trade_Code.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.FirstName.dirty ||
                  this.labourForm.controls.FirstName.touched) &&
                  this.labourForm.controls.FirstName.invalid) ||
                ((this.labourForm.controls.MiddleName.dirty ||
                  this.labourForm.controls.MiddleName.touched) &&
                  this.labourForm.controls.MiddleName.invalid) ||
                ((this.labourForm.controls.LastName.dirty ||
                  this.labourForm.controls.LastName.touched) &&
                  this.labourForm.controls.LastName.invalid) ||
                ((this.labourForm.controls.RelationMasterList_Code.dirty ||
                  this.labourForm.controls.RelationMasterList_Code.touched) &&
                  this.labourForm.controls.RelationMasterList_Code.invalid) ||
                ((this.labourForm.controls.DOB.dirty ||
                  this.labourForm.controls.DOB.touched) &&
                  this.labourForm.controls.DOB.invalid) ||
                ((this.labourForm.controls.GenderMasterList_Code.dirty ||
                  this.labourForm.controls.GenderMasterList_Code.touched) &&
                  this.labourForm.controls.GenderMasterList_Code.invalid) ||
                ((this.labourForm.controls.maritualStatus.dirty ||
                  this.labourForm.controls.maritualStatus.touched) &&
                  this.labourForm.controls.maritualStatus.invalid) ||
                ((this.labourForm.controls.NationalityMasterList_Code.dirty ||
                  this.labourForm.controls.NationalityMasterList_Code
                    .touched) &&
                  this.labourForm.controls.NationalityMasterList_Code.invalid) ||
                ((this.labourForm.controls.BloodGroup.dirty ||
                  this.labourForm.controls.BloodGroup.touched) &&
                  this.labourForm.controls.BloodGroup.invalid) ||
                ((this.labourForm.controls.Cast.dirty ||
                  this.labourForm.controls.Cast.touched) &&
                  this.labourForm.controls.Cast.invalid) ||
                ((this.labourForm.controls.Trade_Code.dirty ||
                  this.labourForm.controls.Trade_Code.touched) &&
                  this.labourForm.controls.Trade_Code.invalid)
              );

            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },
          {
            text: "Identity Details",
            title: "Identity Details",
            desc: `Age & Address Proof (Aadhar Card, Vorter ID, Retion Card, Passport, NPR),
            Photo Proof (PAN, Aadhar Card, Vorter ID, Ration Card, Passport, NPR) & Aadhar Card is required.`,
            isValid: () => {
              if (this.identityDetails.length) {
                this.isIdentityArrDataAvailable = true;
                return this.isIdentityDetailsAvailable() === true;
              }
              return false;
            },
            hasError: () => {
              if (this.identityDetails.length) {
                return this.isIdentityDetailsAvailable() === false;
              }
              if (!this.isIdentityArrDataAvailable) return null;
              return true;
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          // {
          //   text: 'Background Check',
          //   title: 'Background Check',
          //   desc: `Police Verification Date & Validity Date is required.`,
          //   isValid: () => {

          //     return this.labourForm.controls.PoliceVerificationDate.valid
          //       && this.labourForm.controls.PoliceValidityDate.valid;
          //   },
          //   hasError: () => {
          //     return ((this.labourForm.controls.PoliceVerificationDate.dirty || this.labourForm.controls.PoliceVerificationDate.touched)
          //       && this.labourForm.controls.PoliceVerificationDate.invalid)
          //       || ((this.labourForm.controls.PoliceValidityDate.dirty || this.labourForm.controls.PoliceValidityDate.touched)
          //         && this.labourForm.controls.PoliceValidityDate.invalid);
          //   },
          //   isMandatory: true,
          //   isVisible: true,
          //   step: '1',
          // },

          {
            text: "Education",
            title: "Education",
            desc: this.showNewQualification
              ? "Qualification details are required."
              : `Qualification, Qulification Stream & Technical Qulification is required.`,
            isValid: () => {


              if (this.showNewQualification) {
                return !!this.qualificationList.length;
              }
              return (
                this.labourForm.controls.QualificationMasterList_Code.valid &&
                this.labourForm.controls.QualificationStreamMasterList_Code
                  .valid &&
                this.labourForm.controls.TechQualificationMasterList_Code.valid
              );
            },
            hasError: () => {

              // if (this.showNewQualification) {
              //   return !this.qualificationList.length;
              // }
              return (
                ((this.labourForm.controls.QualificationMasterList_Code.dirty ||
                  this.labourForm.controls.QualificationMasterList_Code
                    .touched) &&
                  this.labourForm.controls.QualificationMasterList_Code
                    .invalid) ||
                ((this.labourForm.controls.QualificationStreamMasterList_Code
                  .dirty ||
                  this.labourForm.controls.QualificationStreamMasterList_Code
                    .touched) &&
                  this.labourForm.controls.QualificationStreamMasterList_Code
                    .invalid) ||
                ((this.labourForm.controls.TechQualificationMasterList_Code
                  .dirty ||
                  this.labourForm.controls.TechQualificationMasterList_Code
                    .touched) &&
                  this.labourForm.controls.TechQualificationMasterList_Code
                    .invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          {
            text: "Local Address",
            title: "Local Address",
            desc: "Address line 1, Country, State, District, City, Post Office, Village, Zipcode is required.",
            isValid: () => {
              return (
                this.labourForm.controls.LAddress1.valid &&
                this.labourForm.controls.LCountry_Code.valid &&
                this.labourForm.controls.LState_Code.valid &&
                this.labourForm.controls.LDistrict_Code.valid &&
                this.labourForm.controls.LCity_Code.valid &&
                this.labourForm.controls.LPostOffice_Code.valid &&
                // && this.labourForm.controls.LVillage_Code.valid
                this.labourForm.controls.LZipCode.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.LAddress1.dirty ||
                  this.labourForm.controls.LAddress1.touched) &&
                  this.labourForm.controls.LAddress1.invalid) ||
                ((this.labourForm.controls.LCountry_Code.dirty ||
                  this.labourForm.controls.LCountry_Code.touched) &&
                  this.labourForm.controls.LCountry_Code.invalid) ||
                ((this.labourForm.controls.LState_Code.dirty ||
                  this.labourForm.controls.LState_Code.touched) &&
                  this.labourForm.controls.LState_Code.invalid) ||
                ((this.labourForm.controls.LDistrict_Code.dirty ||
                  this.labourForm.controls.LDistrict_Code.touched) &&
                  this.labourForm.controls.LDistrict_Code.invalid) ||
                ((this.labourForm.controls.LCity_Code.dirty ||
                  this.labourForm.controls.LCity_Code.touched) &&
                  this.labourForm.controls.LCity_Code.invalid) ||
                ((this.labourForm.controls.LPostOffice_Code.dirty ||
                  this.labourForm.controls.LPostOffice_Code.touched) &&
                  this.labourForm.controls.LPostOffice_Code.invalid) ||
                // || ((this.labourForm.controls.LVillage_Code.dirty || this.labourForm.controls.LVillage_Code.touched)
                //   && this.labourForm.controls.LVillage_Code.invalid)
                ((this.labourForm.controls.LZipCode.dirty ||
                  this.labourForm.controls.LZipCode.touched) &&
                  this.labourForm.controls.LZipCode.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },

          {
            text: "Domicile Address",
            title: "Domicile Address",
            desc: `Address line 1, Country, State, District, City, Post Office, Village, Zipcode is required.`,
            isValid: () => {
              return (
                this.labourForm.controls.PAddress1.valid &&
                this.labourForm.controls.PCountry_Code.valid &&
                this.labourForm.controls.PState_Code.valid &&
                this.labourForm.controls.PDistrict_Code.valid &&
                this.labourForm.controls.PCity_Code.valid &&
                this.labourForm.controls.PPostOffice_Code.valid &&
                // && this.labourForm.controls.PVillage_Code.valid
                this.labourForm.controls.PZipCode.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.PAddress1.dirty ||
                  this.labourForm.controls.PAddress1.touched) &&
                  this.labourForm.controls.PAddress1.invalid) ||
                ((this.labourForm.controls.PCountry_Code.dirty ||
                  this.labourForm.controls.PCountry_Code.touched) &&
                  this.labourForm.controls.PCountry_Code.invalid) ||
                ((this.labourForm.controls.PState_Code.dirty ||
                  this.labourForm.controls.PState_Code.touched) &&
                  this.labourForm.controls.PState_Code.invalid) ||
                ((this.labourForm.controls.PDistrict_Code.dirty ||
                  this.labourForm.controls.PDistrict_Code.touched) &&
                  this.labourForm.controls.PDistrict_Code.invalid) ||
                ((this.labourForm.controls.PCity_Code.dirty ||
                  this.labourForm.controls.PCity_Code.touched) &&
                  this.labourForm.controls.PCity_Code.invalid) ||
                ((this.labourForm.controls.PPostOffice_Code.dirty ||
                  this.labourForm.controls.PPostOffice_Code.touched) &&
                  this.labourForm.controls.PPostOffice_Code.invalid) ||
                // || ((this.labourForm.controls.PVillage_Code.dirty || this.labourForm.controls.PVillage_Code.touched)
                //   && this.labourForm.controls.PVillage_Code.invalid)
                ((this.labourForm.controls.PZipCode.dirty ||
                  this.labourForm.controls.PZipCode.touched) &&
                  this.labourForm.controls.PZipCode.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },
        ],
      },
      {
        id: 2,
        text: "Official Details",
        open: true,
        childrens: [
          {
            text: "Associate Demand Type",
            title: "Associate Demand Type",
            desc: "Associate Demand Type is required.",
            isValid: () => {
              return this.labourForm.controls.LabourDemandTypeMasterItem_Code
                .valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.LabourDemandTypeMasterItem_Code
                  .dirty ||
                  this.labourForm.controls.LabourDemandTypeMasterItem_Code
                    .touched) &&
                this.labourForm.controls.LabourDemandTypeMasterItem_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "ESIC Wage Criteria",
            title: "ESIC Wage Criteria",
            desc: "ESIC Wage Criteria is required",
            isValid: () => {
              return this.labourForm.controls.ESICWageCriteria.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.ESICWageCriteria.dirty ||
                  this.labourForm.controls.ESICWageCriteria.touched) &&
                this.labourForm.controls.ESICWageCriteria.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Skill Type",
            title: "Skill Type",
            desc: "Skill Type is required",
            isValid: () => {
              return this.labourForm.controls.Skill_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.Skill_Code.dirty ||
                  this.labourForm.controls.Skill_Code.touched) &&
                this.labourForm.controls.Skill_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Organization Details",
            title: "Organization Details",
            desc: "Select Company,Plant,Unit,Department and Section is required.",
            isValid: () => {
              return (
                this.labourForm.controls.Plant_Code.valid &&
                this.labourForm.controls.Unit_Code.valid &&
                this.labourForm.controls.department.valid &&
                this.labourForm.controls.Section_Code.valid &&
                this.labourForm.controls.Section_Code1.valid &&
                this.labourForm.controls.Section_Code2.valid
              );
            },
            hasError: () => {
              return (
                ((this.labourForm.controls.Plant_Code.dirty ||
                  this.labourForm.controls.Plant_Code.touched) &&
                  this.labourForm.controls.Plant_Code.invalid) ||
                ((this.labourForm.controls.Unit_Code.dirty ||
                  this.labourForm.controls.Unit_Code.touched) &&
                  this.labourForm.controls.Unit_Code.invalid) ||
                ((this.labourForm.controls.department.dirty ||
                  this.labourForm.controls.department.touched) &&
                  this.labourForm.controls.department.invalid) ||
                ((this.labourForm.controls.Section_Code.dirty ||
                  this.labourForm.controls.Section_Code.touched) &&
                  this.labourForm.controls.Section_Code.invalid) ||
                ((this.labourForm.controls.Section_Code1.dirty ||
                  this.labourForm.controls.Section_Code1.touched) &&
                  this.labourForm.controls.Section_Code1.invalid) ||
                ((this.labourForm.controls.Section_Code2.dirty ||
                  this.labourForm.controls.Section_Code2.touched) &&
                  this.labourForm.controls.Section_Code2.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Work Site",
            title: "Work Site",
            desc: "Work Site is required",
            isValid: () => {
              return this.labourForm.controls.WorksiteMasterItemList_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.WorksiteMasterItemList_Code.dirty ||
                  this.labourForm.controls.WorksiteMasterItemList_Code
                    .touched) &&
                this.labourForm.controls.WorksiteMasterItemList_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          // {
          //   text: "Trade/Designation",
          //   title: "Trade/Designation",
          //   desc: "Trade/Designation is required",
          //   isValid: () => {
          //     return this.labourForm.controls.Trade_Code.valid;
          //   },
          //   hasError: () => {
          //     // return this.labourForm.controls.Trade_Code.valid;
          //     return (
          //       ((this.labourForm.controls.Trade_Code.dirty ||
          //         this.labourForm.controls.Trade_Code.touched) &&
          //       this.labourForm.controls.Trade_Code.invalid)
          //     );
          //   },
          //   isMandatory: true,
          //   isVisible: true,
          //   step: "2",
          // },
          {
            text: "Work Category",
            title: "Work Category",
            desc: "Work Category is required",
            isValid: () => {
              return this.labourForm.controls.WorkCategoryMIT_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.WorkCategoryMIT_Code.dirty ||
                  this.labourForm.controls.WorkCategoryMIT_Code.touched) &&
                this.labourForm.controls.WorkCategoryMIT_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Associate Category",
            title: "Associate Category",
            desc: "Associate Category is required",
            isValid: () => {
              return this.labourForm.controls.CategoryMIL_Code.valid;
            },
            hasError: () => {
              return (
                (this.labourForm.controls.CategoryMIL_Code.dirty ||
                  this.labourForm.controls.CategoryMIL_Code.touched) &&
                this.labourForm.controls.CategoryMIL_Code.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },

          // {
          //   text: 'Shift Group and Week-Off',
          //   title: 'Shift Group and Week-Off',
          //   desc: 'Shift Group and Week-Off is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
          // {
          //   text: 'Domicile Status',
          //   title: 'Domicile Status',
          //   desc: 'Domicile Status is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
          // {
          //   text: 'Emergency Contact',
          //   title: 'Emergency Contact',
          //   desc: 'Emergency Contactis is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
          // {
          //   text: 'Referance Contact',
          //   title: 'Referance Contact',
          //   desc: 'Referance Contact is required',
          //   isValid: () => {
          //     return null;
          //   },
          //   hasError: () => {
          //     return false;
          //   },
          //   isMandatory: false,
          //   isVisible: false,
          //   step: '1',
          // },
        ],
      },
      {
        id: 3,
        text: "Complaince Details",
        open: true,
        childrens: [
          {
            text: "Cover Under PF",
            title: "Cover Under PF",
            desc: "Cover Under PF is required.",
            isValid: () => {
              if (this.labourForm.controls.IsPFMembership.disabled) {
                return true;
              }
              return this.labourForm.controls.IsPFMembership.valid;
            },
            hasError: () => {
              if (this.labourForm.controls.IsPFMembership.disabled) {
                return false;
              }
              return (
                (this.labourForm.controls.IsPFMembership.dirty ||
                  this.labourForm.controls.IsPFMembership.touched) &&
                this.labourForm.controls.IsPFMembership.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "Cover Under ESIC",
            title: "Cover Under ESIC",
            desc: "Cover Under ESIC is required",
            isValid: () => {
              if (this.labourForm.controls.IsESICMembership.disabled) {
                return true;
              }
              return this.labourForm.controls.IsESICMembership.valid;
            },
            hasError: () => {
              if (this.labourForm.controls.IsESICMembership.disabled) {
                return false;
              }
              return (
                (this.labourForm.controls.IsESICMembership.dirty ||
                  this.labourForm.controls.IsESICMembership.touched) &&
                this.labourForm.controls.IsESICMembership.invalid
              );
            },
            isMandatory: true,
            isVisible: true,
            step: "2",
          },
          {
            text: "UAN Number",
            title: "UAN Number",
            desc: "UAN Number is required.",
            isValid: () => {
              return this.labourForm.controls.PFUANNo.valid;
            },
            hasError: () => {
              if (!this.labourForm.controls.isAlreadyPFMember) {
                return false;
              }
              return (
                (this.labourForm.controls.PFUANNo.dirty ||
                  this.labourForm.controls.PFUANNo.touched) &&
                this.labourForm.controls.PFUANNo.invalid
              );
            },
            isMandatory: true,
            isVisible: false,
            step: "2",
          },
          {
            text: "Previous PF No",
            title: "Previous PF No",
            desc: "Previous PF No is required.",
            isValid: () => {
              return this.labourForm.controls.PreviousPFNo.valid;
            },
            hasError: () => {
              if (!this.labourForm.controls.isAlreadyPFMember) {
                return false;
              }
              return (
                (this.labourForm.controls.PreviousPFNo.dirty ||
                  this.labourForm.controls.PreviousPFNo.touched) &&
                this.labourForm.controls.PreviousPFNo.invalid
              );
            },
            isMandatory: true,
            isVisible: false,
            step: "2",
          },
          {
            text: "Date of exit of previous member ID",
            title: "Date of exit of previous member ID",
            desc: "Date of exit of previous member ID is required.",
            isValid: () => {
              return this.labourForm.controls.PreviousExitDate.valid;
            },
            hasError: () => {
              if (!this.labourForm.controls.isAlreadyPFMember) {
                return false;
              }
              return (
                (this.labourForm.controls.PreviousExitDate.dirty ||
                  this.labourForm.controls.PreviousExitDate.touched) &&
                this.labourForm.controls.PreviousExitDate.invalid
              );
            },
            isMandatory: true,
            isVisible: false,
            step: "2",
          },
        ],
      },
      {
        id: 4,
        text: "Documents Details",
        open: true,
        childrens: [
          {
            text: "Aadhar Card",
            title: "Aadhar Card",
            desc: "Aadhar Card is required",
            isValid: () => {
              if (this.uploadedFiles.length > 0) {
                if (this.labourForm.controls['NationalityMasterList_Code'].value === 569) {
                  const isPassport = this.uploadedFiles.some(
                    (a) => a.documentTypeCode === 122
                  );
                  if (isPassport) {
                    return true;
                  }
                } else {
                  const isAadhar = this.uploadedFiles.some(
                    (a) => a.documentTypeCode === 117
                  );
                  if (isAadhar) {
                    return true;
                  }
                }
              }
            },
            hasError: () => {
              if (this.uploadedFiles.length === 0) {
                return false;
                // tslint:disable-next-line: no-else-after-return
              } else {
                const isAadhar = this.uploadedFiles.some(
                  (a) => a.documentTypeCode === 117
                );
                if (!isAadhar) {
                  return false;
                }
              }
            },
            isMandatory: true,
            isVisible: true,
            step: "6",
          },
          {
            text: "Profile Photo",
            title: "Profile Photo",
            desc: "Profile Photo is required",
            isValid: () => {
              if (
                this.croppedImage || this.savedCroppedImage !== "../assets/Image/Icon/default.png"
                // && (this.savedCroppedImage && this.savedCroppedImage != null)
              ) {
                return true;
              }
            },
            hasError: () => {
              if (this.croppedImage == '' || this.croppedImage == null || this.savedCroppedImage == '' || this.savedCroppedImage == null) {
                return false;
              }
            },
            isMandatory: true,
            isVisible: true,
            step: "6",
          },
        ],
      },
    ];
  }

  private fillWizardSteps() {
    this.wizardSteps = [
      {
        id: "labourDetails",
        name: "Associate Details",
        isValid: () => {
          return (
            this.labourForm.controls.FirstName && this.labourForm.controls.FirstName.valid &&
            this.labourForm.controls.MiddleName.valid &&
            this.labourForm.controls.LastName.valid &&
            this.labourForm.controls.RelationMasterList_Code.valid &&
            this.labourForm.controls.DOB.valid &&
            this.labourForm.controls.GenderMasterList_Code.valid &&
            this.labourForm.controls.maritualStatus.valid &&
            this.labourForm.controls.NationalityMasterList_Code.valid &&
            // && this.labourForm.controls.BloodGroup.valid
            // Identity details
            this.isIdentityDetailsAvailable() === true &&
            // Education check
            this.labourForm.controls.QualificationMasterList_Code.valid &&
            this.labourForm.controls.QualificationStreamMasterList_Code.valid &&
            this.labourForm.controls.TechQualificationMasterList_Code.valid &&
            // local Address
            this.labourForm.controls.LAddress1.valid &&
            this.labourForm.controls.LCountry_Code.valid &&
            this.labourForm.controls.LState_Code.valid &&
            this.labourForm.controls.LDistrict_Code.valid &&
            this.labourForm.controls.LCity_Code.valid &&
            this.labourForm.controls.LPostOffice_Code.valid &&
            // && this.labourForm.controls.LVillage_Code.valid
            this.labourForm.controls.LZipCode.valid &&
            // Permenant Address
            this.labourForm.controls.PAddress1.valid &&
            this.labourForm.controls.PCountry_Code.valid &&
            this.labourForm.controls.PState_Code.valid &&
            this.labourForm.controls.PDistrict_Code.valid &&
            this.labourForm.controls.PCity_Code.valid &&
            this.labourForm.controls.PPostOffice_Code.valid &&
            // && this.labourForm.controls.PVillage_Code.valid
            this.labourForm.controls.PZipCode.valid &&
            this.checkBackgroundCheckRequiredForWizard() &&
            this.checkBankAccountRequiredForWizard() &&
            this.checkDrivingLicenseRequiredForWizard()
          );
        },
      },
      {
        id: "officalDetails",
        name: "Offical Details",
        // isValid: () => {
        //   return true;
        // },
        isValid: () => {
          // return true;
          let valid = true;
          if (
            this.labourForm.controls.LabourDemandTypeMasterItem_Code.valid &&
            this.labourForm.controls.Plant_Code.valid &&
            this.labourForm.controls.Unit_Code.valid &&
            this.labourForm.controls.department.valid &&
            this.labourForm.controls.Section_Code.valid &&
            this.labourForm.controls.Section_Code1.valid &&
            this.labourForm.controls.Section_Code2.valid &&
            this.labourForm.controls.ESICWageCriteria.valid &&
            this.labourForm.controls.Skill_Code.valid &&
            this.labourForm.controls.WorksiteMasterItemList_Code.valid &&
            this.labourForm.controls.Trade_Code.valid &&
            this.labourForm.controls.WorkCategoryMIT_Code.valid &&
            this.labourForm.controls.CategoryMIL_Code.valid
          ) {
            valid = true;
          } else {
            valid = false;
          }
          return valid;
        },
      },
      {
        id: "complianceDetails",
        name: "Compliance Details",
        // isValid: () => {
        //   return true;
        // },
        isValid: () => {
          if (this.labourForm.controls.IsESICMembership.disabled) {
            return true;
          }
          if (this.labourForm.controls.IsPFMembership.disabled) {
            return true;
          }
          if (this.labourForm.controls.isAlreadyPFMember.value) {
            return (
              this.labourForm.controls.PFUANNo.valid &&
              this.labourForm.controls.PreviousPFNo.valid &&
              this.labourForm.controls.PreviousExitDate.valid
            );
          }
          // return true;
          return (
            this.labourForm.controls.IsPFMembership.valid &&
            this.labourForm.controls.IsESICMembership.valid
          );
        },
      },
      {
        id: "contactDetails",
        name: "Contact Details",
        isValid: () => {
          if (this.emergencyContactRequiredCode &&
            this.referenceContactRequiredCode &&
            +this.emergencyContactRequiredCode.Value === 1 &&
            +this.referenceContactRequiredCode.Value === 1) {
            const emergencyContactRequired = this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 561);
            const referenceContactRequired = this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 562);
            if (emergencyContactRequired && referenceContactRequired) {
              return true;
            }
            return false;
          }
          if (this.emergencyContactRequiredCode && +this.emergencyContactRequiredCode.Value === 1) {
            const emergencyContactRequired = this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 561)
            if (emergencyContactRequired) {
              return true;
            }
            return false;
          }
          if (this.referenceContactRequiredCode && +this.referenceContactRequiredCode.Value === 1) {
            const referenceContactRequired = this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 562)
            if (referenceContactRequired) {
              return true;
            }
            return false;
          }
          return true;
        },
      },
      {
        id: "familyDetails",
        name: "Family Details",
        isValid: () => {
          const familyAndNomineeRequiredCode = this.globalSettingsCodeArr.find((item) => item.Code === 'MOD152PG195NOMINI');
          if (familyAndNomineeRequiredCode && +familyAndNomineeRequiredCode.Value === 1) {
            return this.familyInfoData.length >= 1;
          } else {
            return true;
          }
        },
      },
      {
        id: "nomineeDetails",
        name: "Nominee Details",
        isValid: () => {
          const familyAndNomineeRequiredCode = this.globalSettingsCodeArr.find((item) => item.Code === 'MOD152PG195NOMINI');
          if (familyAndNomineeRequiredCode && +familyAndNomineeRequiredCode.Value === 1) {
            return this.nomineeInfoData.length >= 1;
          } else {
            return true;
          }
        },
      },
      {
        id: "documentDetails",
        name: "Document Details",
        isValid: () => {
          this.checkBackgroundCheckRequiredDocumentForWizard() &&
            this.checkBankAccountRequiredDocumentForWizard() &&
            this.checkMedicalTestRequiredDocumentForWizard() &&
            this.checkDrivingLicenseRequiredDocumentForWizard()
        },
      },
    ];
  }

  private checkBackgroundCheckRequiredForWizard() {
    if (this.backGroundCheck && +this.backGroundCheck.Value === 1) {
      return this.labourForm.controls.PoliceVerificationDate.valid
        && this.labourForm.controls.PoliceValidityDate.valid;
    } else {
      return true;
    }
  }

  private checkBackgroundCheckRequiredDocumentForWizard() {
    if (this.backGroundCheckDocument && +this.backGroundCheckDocument.Value === 1) {
      if (this.uploadedFiles.length > 0) {
        const isPoliceVerificationDocument = this.uploadedFiles.some(
          (a) => a.documentTypeCode === 115
        );
        if (isPoliceVerificationDocument) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  private checkBankAccountRequiredForWizard() {
    if (this.bankAccountDetail && +this.bankAccountDetail.Value === 1) {
      return this.labourForm.controls.BankNameMasterItem_Code.valid
        && this.labourForm.controls.AccountTypeMasterList_Code.valid
        && this.labourForm.controls.NameInBank.valid
        && this.labourForm.controls.AccountNumber.valid
        && this.labourForm.controls.IFSCCode.valid
        && this.labourForm.controls.PaymentType.valid
    } else {
      return true;
    }
  }

  private checkBankAccountRequiredDocumentForWizard() {
    if (this.bankAccountDocument && +this.bankAccountDocument.Value === 1) {
      if (this.uploadedFiles.length > 0) {
        const isBankAccountDocument = this.uploadedFiles.some(
          (a) => [154, 118].includes(a.documentTypeCode)
        );
        if (isBankAccountDocument) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  private checkMedicalTestRequiredDocumentForWizard() {
    if (+this.medicalDocumentRequiredCode1.Value === 1 && +this.medicalDocumentRequiredCode2.Value === 0) {
      if (this.uploadedFiles.length > 0) {
        const isBankAccountDocument = this.uploadedFiles.some(
          (a) => a.documentTypeCode === 116
        );
        if (isBankAccountDocument) {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  // private checkDrivingLicenseRequiredDocumentForWizard() {
  //   if (!this.labourForm.controls['Trade_Code'].value) {
  //     return false;
  //   }

  //   const arr = this.tradeCodeData.Value.split(",");
  //   if (+this.drivingLicenseRequiredCode.Value === 1 &&
  //     this.labourForm.controls['Trade_Code'].value &&
  //     arr.includes(this.labourForm.controls['Trade_Code'].value.toString())) {
  //     const isDrivingLicenseDocument = this.uploadedFiles.some(
  //       (a) => a.documentTypeCode === 124
  //     );
  //     if (isDrivingLicenseDocument) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  private checkDrivingLicenseRequiredDocumentForWizard(): boolean {
    if (!this.labourForm.controls['Trade_Code'].value) {
      return false;
    }
    const tradeCodeArray = this.tradeCodeData.Value.split(",");
    const tradeCodeValue = this.labourForm.controls['Trade_Code'].value.toString();

    if (
      +this.drivingLicenseRequiredCode.Value === 1 &&
      tradeCodeValue &&
      tradeCodeArray.includes(tradeCodeValue)
    ) {
      return this.uploadedFiles.some((file) => file.documentTypeCode === 124);
    }
    return true;
  }

  private checkDrivingLicenseRequiredForWizard() {
    if (!this.labourForm.controls['Trade_Code'].value) {
      return false;
    }

    const arr = this.tradeCodeData.Value.split(",");
    if (+this.drivingLicenseRequiredCode.Value === 1 &&
      this.labourForm.controls['Trade_Code'].value &&
      arr.includes(this.labourForm.controls['Trade_Code'].value.toString())) {
      const drivingLicenseCode = this.drivingLicenceTypes.map((item) => item.Name);
      const drivingLicenseAvailable = this.identityDetails.filter((item) => {
        if (drivingLicenseCode.includes(item.DocName)) {
          return item;
        }
      })
      return !!drivingLicenseAvailable.length;
    } else {
      return true;
    }
  }

  private checkMedicalDocumentRequired() {
    if (+this.medicalDocumentRequiredCode1.Value === 1 && +this.medicalDocumentRequiredCode2.Value === 0) {
      this.checkList[3].childrens.push({
        text: 'Medical Test',
        title: 'Medical Test',
        desc: `Medical Test Document is required.`,
        isValid: () => {
          if (this.uploadedFiles.length > 0) {
            const isBankAccountDocument = this.uploadedFiles.some(
              (a) => a.documentTypeCode === 116
            );
            if (isBankAccountDocument) {
              return true;
            }
          }
        },
        hasError: () => {
          if (this.uploadedFiles.length === 0) {
            return false;
          } else {
            const isBankAccountDocument = this.uploadedFiles.some(
              (a) => a.documentTypeCode === 116
            );
            if (!isBankAccountDocument) {
              return false;
            }
          }
        },
        isMandatory: true,
        isVisible: true,
        step: '7',
      });
    }
  }

  private checkAdharCardRequired(table) {
    const adharCardRequiredObj = table.find(
      (item) => item.Code === "LABOUR_001"
    );
    if (adharCardRequiredObj) {
      this.adharCardRequired = +adharCardRequiredObj.Value;
    }
  }

  private checkBackGroundCheckRequired(table) {
    this.backGroundCheck = table.find(
      (item) => item.Code === 'MOD152PG195PROBGC'
    );
    if (+this.backGroundCheck.Value === 1) {
      console.log('inside background');
      this.checkList[0].childrens.push({
        text: 'Background Check',
        title: 'Background Check',
        desc: `Police Verification Date & Validity Date is required.`,
        isValid: () => {
          return !!this.labourForm.controls.PoliceVerificationDate.value
            && !!this.labourForm.controls.PoliceValidityDate.value;
        },
        hasError: () => {
          return ((this.labourForm.controls.PoliceVerificationDate.dirty || this.labourForm.controls.PoliceVerificationDate.touched)
            && this.labourForm.controls.PoliceVerificationDate.invalid)
            || ((this.labourForm.controls.PoliceValidityDate.dirty || this.labourForm.controls.PoliceValidityDate.touched)
              && this.labourForm.controls.PoliceValidityDate.invalid);
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      });
    }
    this.backGroundCheckDocument = table.find(
      (item) => item.Code === 'MOD152PG195PROBGCDOC'
    );
    if (+this.backGroundCheckDocument.Value === 1) {
      this.checkList[3].childrens.push({
        text: 'Background Check',
        title: 'Background Check',
        desc: `Police Verification Document is required.`,
        isValid: () => {
          if (this.uploadedFiles.length > 0) {
            const isPoliceVerificationDocument = this.uploadedFiles.some(
              (a) => a.documentTypeCode === 115
            );
            if (isPoliceVerificationDocument) {
              return true;
            }
          }
        },
        hasError: () => {
          if (this.uploadedFiles.length === 0) {
            return false;
          } else {
            const isPoliceVerificationDocument = this.uploadedFiles.some(
              (a) => a.documentTypeCode === 115
            );
            if (!isPoliceVerificationDocument) {
              return false;
            }
          }
        },
        isMandatory: true,
        isVisible: true,
        step: '6',
      });
    }
  }

  private checkBankAccountDetailsRequired(table) {
    this.bankAccountDetail = table.find(
      (item) => item.Code === 'MOD152PG195PROBANMND'
    );
    if (+this.bankAccountDetail.Value === 1) {
      this.checkList[0].childrens.push({
        text: 'Bank Account Details',
        title: 'Bank Account Details',
        desc: `Bank Name, Account Type, Name As Per Bank Record, Account Number, ISFC Code & Payment Type is required.`,
        isValid: () => {
          return this.labourForm.controls.BankNameMasterItem_Code.valid
            && this.labourForm.controls.AccountTypeMasterList_Code.valid
            && this.labourForm.controls.NameInBank.valid
            && this.labourForm.controls.AccountNumber.valid
            && this.labourForm.controls.IFSCCode.valid
            && this.labourForm.controls.PaymentType.valid
        },
        hasError: () => {
          return ((this.labourForm.controls.BankNameMasterItem_Code.dirty || this.labourForm.controls.BankNameMasterItem_Code.touched)
            && this.labourForm.controls.BankNameMasterItem_Code.invalid)
            || ((this.labourForm.controls.AccountTypeMasterList_Code.dirty || this.labourForm.controls.AccountTypeMasterList_Code.touched)
              && this.labourForm.controls.AccountTypeMasterList_Code.invalid)
            || ((this.labourForm.controls.NameInBank.dirty || this.labourForm.controls.NameInBank.touched)
              && this.labourForm.controls.NameInBank.invalid)
            || ((this.labourForm.controls.AccountNumber.dirty || this.labourForm.controls.AccountNumber.touched)
              && this.labourForm.controls.AccountNumber.invalid)
            || ((this.labourForm.controls.IFSCCode.dirty || this.labourForm.controls.IFSCCode.touched)
              && this.labourForm.controls.IFSCCode.invalid)
            || ((this.labourForm.controls.PaymentType.dirty || this.labourForm.controls.PaymentType.touched)
              && this.labourForm.controls.PaymentType.invalid)
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      });
    }
    this.bankAccountDocument = table.find(
      (item) => item.Code === 'MOD152PG195PROBANMNDDOC'
    );
    if (+this.bankAccountDocument.Value === 1) {
      this.checkList[3].childrens.push({
        text: 'Bank Account Details',
        title: 'Bank Account Details',
        desc: `Bank Account Document is required.`,
        isValid: () => {
          if (this.uploadedFiles.length > 0) {
            const isBankAccountDocument = this.uploadedFiles.some(
              (a) => [154, 118].includes(a.documentTypeCode)
            );
            if (isBankAccountDocument) {
              return true;
            }
          }
        },
        hasError: () => {
          if (this.uploadedFiles.length === 0) {
            return false;
          } else {
            const isBankAccountDocument = this.uploadedFiles.some(
              (a) => [154, 118].includes(a.documentTypeCode)
            );
            if (!isBankAccountDocument) {
              return false;
            }
          }
        },
        isMandatory: true,
        isVisible: true,
        step: '6',
      });
    }
  }

  private checkFamilyAndNomineeDetailsRequired(table) {
    const familyAndNomineeRequiredCode = table.find((item) => item.Code === 'MOD152PG195NOMINI');
    if (familyAndNomineeRequiredCode && +familyAndNomineeRequiredCode.Code === 1) {
      const familyItem = {
        id: 5,
        text: "Family Details",
        open: true,
        childrens: [
          {
            text: "Minimum One Member",
            title: "Minimum One Member",
            desc: "One family member detail is required",
            isValid: () => {
              return this.familyInfoData.length >= 1;
            },
            hasError: () => {
              return false;
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },
        ],
      };
      this.checkList.splice(3, 0, familyItem);

      const nomineeDetails = {
        id: 6,
        text: "Nominee Details",
        open: true,
        childrens: [
          {
            text: "Minimum One Nominee",
            title: "Minimum One Nominee",
            desc: "One nominee detail is required",
            isValid: () => {
              return this.nomineeInfoData.length >= 1;
            },
            hasError: () => {
              return false;
            },
            isMandatory: true,
            isVisible: true,
            step: "1",
          },
        ],
      }
      this.checkList.splice(4, 0, nomineeDetails);
    }
  }

  private checkDrivingLicenseDetailsRequired() {
    if (+this.drivingLicenseRequiredCode.Value === 1) {
      this.checkList[0].childrens.push({
        text: 'Driving License',
        title: 'Driving License',
        desc: `Driving license mandatory for driver.`,
        isValid: () => {
          const arr = this.tradeCodeData.Value.split(",") || [];
          if (this.labourForm.controls['Trade_Code'].value && arr.includes(this.labourForm.controls['Trade_Code'].value.toString())) {
            const drivingLicenseCode = this.drivingLicenceTypes.map((item) => item.Name);
            const drivingLicenseAvailable = this.identityDetails.filter((item) => {
              if (drivingLicenseCode.includes(item.DocName)) {
                return item;
              }
            })
            if (drivingLicenseAvailable.length) {
              return true;
            } else {
              return false;
            }
          }

          if (this.labourForm.controls['Trade_Code'].value && !arr.includes(this.labourForm.controls['Trade_Code'].value.toString())) {
            const drivingLicenseCode = this.drivingLicenceTypes.map((item) => item.Name);
            const drivingLicenseAvailable = this.identityDetails.filter((item) => {
              if (drivingLicenseCode.includes(item.DocName)) {
                return item;
              }
            })
            return !!drivingLicenseAvailable.length
          }

          return false;
        },
        hasError: () => {
          const arr = this.tradeCodeData.Value.split(",") || [];
          if (this.labourForm.controls['Trade_Code'].value && arr.includes(this.labourForm.controls['Trade_Code'].value.toString())) {
            const drivingLicenseCode = this.drivingLicenceTypes.map((item) => item.Name);
            const drivingLicenseAvailable = this.identityDetails.filter((item) => {
              if (drivingLicenseCode.includes(item.DocName)) {
                return item;
              }
            })
            return !drivingLicenseAvailable.length
          }
          return false;
        },
        isMandatory: true,
        isVisible: true,
        isPartiallyValid: true,
        step: '7',
      });
    }
    
    if (+this.drivingLicenseRequiredDocumentCode.Value === 1) {
      this.checkList[3].childrens.push({
        text: 'Driving License',
        title: 'Driving License',
        desc: `Driving license mandatory for driver.`,
        isValid: () => {
          if (this.uploadedFiles.length > 0) {
            return this.uploadedFiles.some((a) => a.documentTypeCode === 124);
          }
        },
        hasError: () => {
          if (!this.uploadedFiles.length)
            return false;
          const arr = this.tradeCodeData.Value.split(",");
          if (this.uploadedFiles.length > 0) {
            return !this.uploadedFiles.some((a) => a.documentTypeCode === 124);
          }
        },
        isMandatory: true,
        isVisible: true,
        isPartiallyValid: true,
        step: '7',
      });
    }
  }

  private checkContactDetailsRequired() {
    if (+this.emergencyContactRequiredCode.Value === 1 || +this.referenceContactRequiredCode.Value === 1) {
      const contactDetails = {
        id: 7,
        text: "Contact Details",
        open: true,
        childrens: [],
      }
      this.checkList.splice(3, 0, contactDetails);
      if (+this.emergencyContactRequiredCode.Value === 1) {
        this.checkList[3].childrens.push({
          text: "Emergency Contact Details",
          title: "Emergency Contact Details",
          desc: "Emergency Contact is required",
          isValid: () => {
            return this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 561);
          },
          hasError: () => {
            if (!this.editLabourCode) {
              return false;
            } else {
              return !this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 561);
            }
          },
          isMandatory: true,
          isVisible: true,
          step: "1",

        })
      }
      if (+this.emergencyContactRequiredCode.Value === 1) {
        this.checkList[3].childrens.push({
          text: "Reference Contact Details",
          title: "Reference Contact Details",
          desc: "Reference Contact details is required",
          isValid: () => {
            return this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 562);
          },
          hasError: () => {
            if (!this.editLabourCode) {
              return false;
            } else {
              return !this.labourContactPersonViews.find((item) => item.ContactTypeMasterItem_Code === 562);
            }
          },
          isMandatory: true,
          isVisible: true,
          step: "2",
        })
      }
    }
    this.finalCheckList = this.checkList
  }

  private disableCompliancePageControl(): void {
    setTimeout(() => {
      this.labourForm.form.get("isAlreadyPFMember").disable();
      this.labourForm.form.get("PFUANNo").disable();
      this.labourForm.form.get("PreviousPFNo").disable();
      this.labourForm.form.get("PreviousExitDate").disable();
      this.labourForm.form.get("CertificateNo").disable();
      this.labourForm.form.get("PPOno").disable();

      this.labourForm.form.get("ESICBranchOffice").disable();
      this.labourForm.form.get("Dispensary").disable();
      this.labourForm.form.get("ESICRegistrationDate").disable();
    }, 1000);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.labourForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    return invalid;
  }

  isIdentityDetailsAvailable() {
    let available = false;
    if (this.labourForm.controls['NationalityMasterList_Code'].value === 480) {
      if (
        this.identityDetails.filter(
          (element) => element.TypeOfIdentityMasterItem_Code === 577
        ).length
      ) {
        available = true;
      }
    }
    if (this.labourForm.controls['NationalityMasterList_Code'].value === 569) {
      if (
        this.identityDetails.filter(
          (element) => element.TypeOfIdentityMasterItem_Code === 580
        ).length
      ) {
        available = true;
      }
    }


    // if (this.identityDetails.filter(element => element.TypeOfIdentityMasterItem_Code === 576).length
    //   && (this.identityDetails.filter(element => element.TypeOfIdentityMasterItem_Code !== 576).length === 0)) {
    //   // PAN card
    //   available = false;
    // }

    // if (this.identityDetails.filter(element => element.TypeOfIdentityMasterItem_Code === 576).length
    //   && this.identityDetails.filter(element => element.TypeOfIdentityMasterItem_Code !== 576).length) {
    //   // PAN card & other
    //   available = true;
    // }
    return available;
  }

  changeIdentitytype() {
    if (
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"] === 577
    ) {
      this.show = true;
      this.show1 = false;
      this.show2 = false;
    } else if (
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"] === 576 ||
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"] === 578 ||
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"] === 579 ||
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"] === 638
    ) {
      this.show1 = true;
      this.show = false;
      this.show2 = false;
    } else if (
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"] === 580
    ) {
      this.show2 = true;
      this.show = false;
      this.show1 = false;
    }
  }

  changecoverunderPF() {
    this.labourForm.form.get("isAlreadyPFMember").setValue(false);
    this.labourForm.form.get("PFUANNo").setValue("");
    this.labourForm.form.get("PreviousPFNo").setValue("");
    this.labourForm.form.get("PreviousExitDate").setValue("");
    this.labourForm.form.get("CertificateNo").setValue("");
    this.labourForm.form.get("PPOno").setValue("");

    this.labourForm.form.get("isAlreadyPFMember").disable();
    this.labourForm.form.get("PFUANNo").disable();
    this.labourForm.form.get("PreviousPFNo").disable();
    this.labourForm.form.get("PreviousExitDate").disable();
    this.labourForm.form.get("CertificateNo").disable();
    this.labourForm.form.get("PPOno").disable();

    this.labourForm.form.get("EPFNo").setValue("");
    if (this.labourForm.control.value["IsPFMembership"] === 1) {
      this.showEPFNo = true;
      this.dateofExtension = false;
      this.epfno = false;

      this.labourForm.form.get("isAlreadyPFMember").enable();
      this.labourForm.form.get("PFUANNo").enable();
      this.labourForm.form.get("PFUANNo").setValidators([Validators.required]);
      this.labourForm.form.get("PFUANNo").updateValueAndValidity();
      this.labourForm.form.get("PreviousPFNo").enable();
      this.labourForm.form.get("PreviousPFNo").setValidators([Validators.required]);
      this.labourForm.form.get("PreviousPFNo").updateValueAndValidity();
      this.labourForm.form.get("PreviousExitDate").enable();
      this.labourForm.form.get("CertificateNo").enable();
      this.labourForm.form.get("PPOno").enable();
    } else if (this.labourForm.control.value["IsPFMembership"] === 0) {
      this.epfno = false;
      this.showEPFNo = false;
      this.dateofExtension = true;
    } else {
      this.showEPFNo = false;
      this.dateofExtension = false;
      this.epfno = false;
    }

    this.onAlreadyPFMemberChange();
  }

  changecoverunderESIC() {
    this.labourForm.form.get("ESICBranchOffice").setValue("");
    this.labourForm.form.get("Dispensary").setValue("");
    this.labourForm.form.get("ESICRegistrationDate").setValue("");

    this.labourForm.form.get("ESICBranchOffice").disable();
    this.labourForm.form.get("Dispensary").disable();
    this.labourForm.form.get("ESICRegistrationDate").disable();

    this.labourForm.form.get("ESICNo").setValue("");
    if (this.labourForm.control.value["IsESICMembership"] === 1) {
      this.showESICNo = true;
      this.dateofExtension1 = false;
      this.esicno = false;

      this.labourForm.form.get("ESICBranchOffice").enable();
      this.labourForm.form.get("Dispensary").enable();
      this.labourForm.form.get("ESICRegistrationDate").enable();
    } else if (this.labourForm.control.value["IsESICMembership"] === 0) {
      this.esicno = false;
      this.showESICNo = false;
      this.dateofExtension1 = true;
    } else {
      this.showESICNo = false;
      this.dateofExtension1 = false;
      this.esicno = false;
    }
  }

  // tslint:disable-next-line: function-name
  SameLocalnadPermanentAddress($event) {
    if ($event.target.checked === true) {
      this.labourForm.controls["PAddress1"].setValue(
        this.labourForm.controls.LAddress1.value
      );
      this.labourForm.controls["PAddress2"].setValue(
        this.labourForm.controls.LAddress2.value
      );
      this.labourForm.controls["PAddress3"].setValue(
        this.labourForm.controls.LAddress3.value
      );
      this.labourForm.controls["PZipCode"].setValue(
        this.labourForm.controls.LZipCode.value
      );
      this.getCountry().subscribe((res: any) => {
        this.pcountries = res;
        this.labourForm.controls["PCountry_Code"].setValue(
          this.labourForm.controls.LCountry_Code.value
        );
        this.getStatesByCountryIdP().subscribe((res: any) => {
          this.pStates = res;
          this.labourForm.controls["PState_Code"].setValue(
            this.labourForm.controls.LState_Code.value
          );
          this.getDistrictsByStateP().subscribe((res: any) => {
            this.pDistricts = res;
            this.labourForm.controls["PDistrict_Code"].setValue(
              this.labourForm.controls.LDistrict_Code.value
            );

            this.getCitiesByDistrictP().subscribe((res: any) => {
              this.pCities = res;
              this.labourForm.controls["PCity_Code"].setValue(
                this.labourForm.controls.LCity_Code.value
              );
              this.getPostOfficeByCityP().subscribe((res: any) => {
                this.pPos = res;
                this.labourForm.controls["PPostOffice_Code"].setValue(
                  this.labourForm.controls.LPostOffice_Code.value
                );
                this.getVillageP().subscribe((res: any) => {
                  this.pVillages = res.Data.Table;
                  this.labourForm.controls["PVillage_Code"].setValue(
                    this.labourForm.controls.LVillage_Code.value
                  );
                })
              })
            });
          });
        });
      });
    } else {
      this.labourForm.controls["PAddress1"].setValue(null);
      this.labourForm.controls["PAddress2"].setValue(null);
      this.labourForm.controls["PAddress3"].setValue(null);
      this.labourForm.controls["PZipCode"].setValue(null);
      this.labourForm.controls["PCountry_Code"].setValue(null);
      this.labourForm.controls["PState_Code"].setValue(null);
      this.labourForm.controls["PDistrict_Code"].setValue(null);
      this.labourForm.controls["PCity_Code"].setValue(null);
      this.labourForm.controls["PPostOffice_Code"].setValue(null);
      this.labourForm.controls["PVillage_Code"].setValue(null);
    }
  }

  // changeNationality() {
  //   if (this.labourForm.control.value['NationalityMasterList_Code'] === 0) {
  //     if (this.labourForm.control.value['TypeOfIdentityMasterItem_Code'] !== 580 ||
  //      !this.labourForm.control.value['TypeOfIdentityMasterItem_Code']) {
  //       this.logHelperService.logError({
  //         message: 'Please select Passport'
  //       });
  //     }
  //   }
  // }

  bindDataforMasterItem(filteredData) {
    const convertedData = [];
    filteredData.forEach((element: any) => {
      const subData = [];
      const subDataFilter = this.masterData.filter(
        (list) => list.Parent_Code === element.MasterItem_Code
      );
      if (subDataFilter.length > 0) {
        convertedData.push({
          id: element.MasterItem_Code,
          name: element.Name,
          children: this.bindDataforMasterItem(subDataFilter),
        });
      } else {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name });
      }
    });
    return convertedData;
  }

  private checkNomineeDetailsPercentageTotal(): boolean {
    let isCheck: boolean = true;
    const percentageAllowedList = ["EPF", "Gratuity"];
    const filteredList = this.nomineeInfoData.filter((item) =>
      percentageAllowedList.includes(item.NominationFor)
    );
    if (filteredList.length) {
      for (const value of percentageAllowedList) {
        const nominationForList = filteredList.filter(
          (item) => item.NominationFor === value
        );
        if (nominationForList.length) {
          const total = nominationForList
            .map((item) => item.NominationPer || 0)
            .reduce((a, b) => a + b, 0);
          if (total !== 100) {
            this.logHelperService.logError({
              title: `Invalid Percentage!`,
              message: `${value} should be equal to 100%.`,
            });
            isCheck = false;
            break;
          }
        }
      }
    }
    return isCheck;
  }

  isAdharCardOrPassportRequiredDocument() {
    if (this.labourForm.controls['NationalityMasterList_Code'].value === 569) {
      const isPassport = this.uploadedFiles.some(
        (a) => a.documentTypeCode === 122
      );
      if (isPassport) {
        return true;
      }
      return false;
    } else {
      const isAdhardRequired =
        this.adharCardRequired === 0 ||
        (this.adharCardRequired === 2 && !this.editLabourCode);
      if (isAdhardRequired) {
        const adharCardDocument = this.uploadedFiles.find(
          (item) => item.documentTypeCode === 117
        );
        if (!adharCardDocument) {
          return false;
        }
        return true;
      }
      return true;
    }
  }

  checkAllMandatoryDocumentIsAvailable() {
    return this.uploadedFiles.length &&
      this.isAdharCardOrPassportRequiredDocument() &&
      this.checkBackgroundCheckRequiredDocumentForWizard() &&
      this.checkBankAccountRequiredDocumentForWizard() &&
      this.checkMedicalTestRequiredDocumentForWizard() &&
      this.checkDrivingLicenseRequiredDocumentForWizard()
  }

  selectedPaymentType:any
  onchangePaymentType(selectedPaymentType){
    this.selectedPaymentType = selectedPaymentType
  }

  onSubmit(formData, redirect = false, newRecord = false) {
    if (!this.checkNomineeDetailsPercentageTotal()) {
      return;
    }

    if (!this.checkAllMandatoryDocumentIsAvailable()) {
      this.logHelperService.logError({
        message: "Please upload all required documents.",
      });
      return;
    }

    if (this.savedCroppedImage === "../assets/Image/Icon/default.png") {
      this.logHelperService.logError({
        message: "Please upload profile photo.",
      });
      return;
    }

    // const labour = formData;
    let hindiLanguage = false;
    let englishLanguage = false;
    let otherLanguage = "";
    if (formData.Language_Code === 1) {
      hindiLanguage = true;
    } else if (formData.Language_Code === 2) {
      englishLanguage = true;
    } else if (formData.Language_Code === 3) {
      otherLanguage = formData.language;
    }

    const labour: any = {
      // Step-1 start

      Company_Code: formData.Company_Code,

      // Labour personal details :
      FirstName: formData.FirstName,
      HindiFirstName: formData.HindiFirstName,
      MiddleName: formData.MiddleName,
      HindiMiddleName: formData.HindiMiddleName,
      LastName: formData.LastName,
      HindiLastName: formData.HindiLastName,
      RelationMasterList_Code: formData.RelationMasterList_Code,
      // DOB: formData.DOB,
      DOB: formData.DOB ? moment(formData.DOB).format(this.sharedService.datePickerSettings.requestFormat) : null,
      PlaceofBirth: formData.PlaceofBirth,
      MobileNumber: formData.MobileNumber,
      MaritalStatusMasterList_Code: formData.maritualStatus,
      IdentificationMark: formData.IdentificationMark,
      GenderMasterList_Code: formData.GenderMasterList_Code,
      ReligionMasterList_Code: formData.ReligionMasterList_Code,
      CastMasterList_Code: formData.Cast,
      NationalityMasterList_Code: formData.NationalityMasterList_Code,
      BloodGroup: formData.BloodGroup,
      PaymentTypeMasterList_Code: formData.PaymentType,
      CreateBy: GLOBAL.USER_ID,
      UserId: GLOBAL.USER_ID,
      userEmail: localStorage.getItem("UserEmailId"),

      IllnessDetails: formData.IllnessDetails,
      isConvicted: formData.isConvicted,
      Hlanguage: hindiLanguage,
      Elanguage: englishLanguage,
      language: otherLanguage,
      TechnicalCourse: formData.TechnicalCourse,
      //Photo : fileToReturn.name,

      // Proof Of Identity :
      IdentityReferenceViews: this.identityDetails,

      // Qualification & Experience Details :
      QualificationMasterList_Code: formData.QualificationMasterList_Code,
      TechQualificationMasterList_Code:
        formData.TechQualificationMasterList_Code,
      QualificationStreamMasterList_Code:
        formData.QualificationStreamMasterList_Code,
      TotalExpr: formData.TotalExpr,
      PreviousOrg: formData.PreviousOrg,

      // Bank Account Details :
      BankDetailReferenceView: {
        BankNameMasterItem_Code: formData.BankNameMasterItem_Code,
        AccountTypeMasterList_Code: formData.AccountTypeMasterList_Code,
        NameInBank: formData.NameInBank,
        AccountNumber: formData.AccountNumber,
        BranchName: formData.BranchName,
        IFSCCode: formData.IFSCCode,
        BranchCode: formData.BranchCode,
        MasterItem_Code: this.selectedPaymentType ? this.selectedPaymentType : 323,
        CreateBy: GLOBAL.USER_ID,
      },

      // Proof Of Address :
      AddressRefrenceViews: [
        {
          // AddressTypeMasterList_Code: formData.LAddressTypeMasterList_Code,
          AddressTypeMasterList_Code: 558,
          Address1: formData.LAddress1,
          Address2: formData.LAddress2,
          Address3: formData.LAddress3,
          ZipCode: formData.LZipCode,
          Country_Code: formData.LCountry_Code,
          State_Code: formData.LState_Code,
          District_Code: formData.LDistrict_Code,
          City_Code: formData.LCity_Code,
          PostOffice_Code: formData.LPostOffice_Code,
          Village_Code: formData.LVillage_Code,
          // Address_Code: formData.LAddress_Code || null,
          // Reference_Code: formData.LReference_Code || null,
          CreateBy: GLOBAL.USER_ID,
        },
        {
          // AddressTypeMasterList_Code: formData.PAddressTypeMasterList_Code,
          AddressTypeMasterList_Code: 559,
          Address1: formData.PAddress1,
          Address2: formData.PAddress2,
          Address3: formData.PAddress3,
          ZipCode: formData.PZipCode,
          Country_Code: formData.PCountry_Code,
          State_Code: formData.PState_Code,
          District_Code: formData.PDistrict_Code,
          City_Code: formData.PCity_Code,
          PostOffice_Code: formData.PPostOffice_Code,
          Village_Code: formData.PVillage_Code,
          // Address_Code: formData.PAddress_Code || null,
          // Reference_Code: formData.PReference_Code || null,
          CreateBy: GLOBAL.USER_ID,
        },
      ],
      Email1: formData.Email1,
      Email2: formData.Email2,
      Email3: formData.Email3,
      Height: formData.Height,
      Weight: formData.Weight,
      DisabilityType_Code: formData.DisabilityType_Code,
      IsPhysicallyChallenge: formData.IsPhysicallyChallenge,

      // work experience
      LabourWorkExperiences: this.workExperienceList,
      // qualification list
      LabourQualifications: this.qualificationList,

      // Step-1 end

      GatePassRequestView: {
        // Step-1 Background Check :
        PoliceVerificationDate: formData.PoliceVerificationDate ? moment(formData.PoliceVerificationDate).format(this.sharedService.datePickerSettings.requestFormat) : null,
        PoliceValidityDate: formData.PoliceValidityDate ? moment(formData.PoliceValidityDate).format(this.sharedService.datePickerSettings.requestFormat) : null,
        // Step-1 Background Check end:

        // Step-2 start
        // Official Information :
        JoiningDate: formData.JoiningDate ? moment(formData.JoiningDate).format(this.sharedService.datePickerSettings.requestFormat) : null,
        // IssueDate: formData.IssueDate,
        IssueDate: formData.IssueDate
          ? moment(formData.IssueDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        ER1LevelMasterItem_Code: formData.ER1LevelMasterItem_Code,
        ValidUptoDate: formData.ValidUptoDate
          ? moment(formData.ValidUptoDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        ER1StatusMasterItem_Code: formData.ER1StatusMasterItem_Code,
        LabourDemandTypeMasterItem_Code:
          formData.LabourDemandTypeMasterItem_Code,
        Skill_Code: formData.Skill_Code,
        PreviouslyWorkInOrg: formData.PreviouslyWorkInOrg,
        OldGatePassNo: formData.OldGatePassNo,
        IsMobileAllow: formData.IsMobileAllow || true,
        // Line & Location of Work Details :
        Plant_Code: formData.Plant_Code,
        Unit_Code: formData.Unit_Code,
        Department_Code: formData.department,
        Section_Code2: formData.Section_Code2,
        Section_Code1: formData.Section_Code1,
        Section_Code: formData.Section_Code,
        Trade_Code: formData.Trade_Code,
        WorkCategoryMIT_Code: formData.WorkCategoryMIT_Code,
        CategoryMIL_Code: formData.CategoryMIL_Code,
        // WorkOrderTypeMasterItem_Code: formData.WorkOrderTypeMasterItem_Code || 228,

        // Attendance Information :
        // CardTypeMasterList_Code: formData.CardTypeMasterList_Code,
        // RegularWeekOff: formData.RegularWeekOff,
        // Step-2 end

        // Step-3 start
        // Compliance Information :
        IsPFMembership: formData.IsPFMembership,
        IsESICMembership: formData.IsESICMembership,
        PFExtendedDate: formData.PFExtendedDate
          ? moment(formData.PFExtendedDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        ESICExtendedDate: formData.ESICExtendedDate
          ? moment(formData.ESICExtendedDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        EPFNo: formData.EPFNo,
        ESICNo: formData.ESICNo,
        PFUANNo: formData.PFUANNo,
        PreviousPFNo: formData.PreviousPFNo,
        PreviousExitDate: formData.PreviousExitDate
          ? moment(formData.PreviousExitDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        CertificateNo: formData.CertificateNo,
        PPOno: formData.PPOno,
        ESICBranchOffice: formData.ESICBranchOffice,
        Dispensary: formData.Dispensary,
        ESICRegistrationDate: formData.ESICRegistrationDate
          ? moment(formData.ESICRegistrationDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        ESICVerificationBy: formData.ESICVerificationBy,
        IsEPFVerified: formData.IsEPFVerified,
        ESICVerificationDate: formData.ESICVerificationDate
          ? moment(formData.ESICVerificationDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        PFVerificationDate: formData.verificationDate
          ? moment(formData.verificationDate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        DGMCMinesCode: formData.DGMCMinesCode,
        DGMCVerificationdate: formData.DGMCVerificationdate
          ? moment(formData.DGMCVerificationdate).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null,
        IsDGMCVerified: formData.isDGMSVerify,
        DGMCVerifiedBy: formData.DGMSVerificationCode,
        CreateBy: GLOBAL.USER_ID,
        // ESICWageCriteria: 0 || 1,
        // ESICWageCriteria: this.esicvalue,
        ESICWageCriteria: formData.ESICWageCriteria,

        // Step-3 end
      },

      WorkSiteCategoryCodes: [formData.WorksiteMasterItemList_Code],

      WorkSiteCodes: [formData.ValidityPeriod_Code],

      LabourContractorRelationView: {
        CreateBy: GLOBAL.USER_ID,
        ModifiedBy: GLOBAL.USER_ID,
      },

      // Step-4 start
      // Contact Detail :
      LabourContactPersonViews: this.labourContactPersonViews,
      // Step-4 end
      FamilyMemberInfo: this.familyInfoData,
      // step- 5
      // nominee details
      LabourNomineeMasterViews: this.nomineeInfoData,
      // step - 6
      // Family Member Information :
      LabourFamilyMasterViews: [
        {
          Name: formData.Name,
          Profession: formData.Profession,
          // DOB: formData.FMI_DOB,
          DOB: formData.DOB
            ? moment(formData.FMI_DOB).format(
              this.sharedService.datePickerSettings.requestFormat
            )
            : null,
          NationalityCountry_Code: formData.NationalityCountry_Code,
          Gender: formData.Gender,
          RelationMasterList_Code: formData.FamilyRelationMasterList_Code,
          BloodGroup: formData.FamilyBloodGroup,
          Remark: formData.Remark,
          // AddressTypeMasterList_Code: formData.AddressTypeMasterList_Code,
          Address1: formData.addressType3,
          Address2: formData.addressType3,
          Address3: formData.addressType3,
          Country_Code: formData.country,
          State_Code: formData.state,
          District_Code: formData.district,
          City_Code: formData.city,
          PostOffice_Code: formData.postOffice,
          Zipcode: formData.zipCode,
          CreateBy: GLOBAL.USER_ID,
        },
      ],
      // step - 7
      DocumentImageMasterViewModals: [],
      // step - 6
    };

    // used to upload the file
    if (this.uploadedFiles.length) {
      this.uploadedFiles.forEach((file) => {
        if (file.isUploaded) {
          labour.DocumentImageMasterViewModals.push({
            ID: file.ID,
            DocumentTypeMasterList_Code: file.documentTypeCode,
          });
        } else {
          labour.DocumentImageMasterViewModals.push({
            DocumentTypeMasterList_Code: file.documentTypeCode,
          });
        }
      });
    }
    const uploadParams = {
      Labour_Code: "",
    };

    if (this.photopath) {
      this.uploadedPhoto = this.photopath;
    }

    if (this.editMode) {
      this.editLabourCode = this.activatedRoute.snapshot.paramMap.get("id");
      labour.Labour_Code = this.editLabourCode;
      labour.Photo = this.savedCroppedImage;
      this.labourService.updateLabour(labour).subscribe((result: any) => {
        uploadParams.Labour_Code = result.Data;

        if (this.uploadedFiles && this.uploadedFiles.length > 0) {
          if (uploadParams.Labour_Code) {
            this.fileUploadService
              .uploadFiles(
                this.uploadedFiles,
                uploadParams,
                GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_LABOUR_DOC
              )
              .subscribe((res) => {
                if (redirect) {
                  this.router.navigate(["labour/labours"]);
                }
                // tslint:disable-next-line:brace-style
                else if (newRecord) {
                  this.router.navigate(["labour/create-sub-labour"]);
                } else {
                  this.labourForm.reset();
                  this.clearForm();
                }
              });
          }
          // if (uploadParams.Labour_Code) {

          // this.fileUploadService.uploadPhoto(this.uploadedPhoto, uploadParams, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPDATE_LABOUR_PHOTO)
          //   .subscribe((res) => {
          //     if (redirect) {
          //       this.router.navigate(['labours']);
          //     } else {
          //       this.labourForm.reset();
          //       this.clearForm();
          //     }
          //   });

          // let paylaod  = {
          //   Labour_Code : uploadParams.Labour_Code,
          //   name : this.photopath.name
          // }

          // this.labourService.updateLabourPhoto(paylaod).subscribe((res) => {

          // })
          // }
        } else {
          if (redirect) {
            this.router.navigate(["labour/labours"]);
          } else if (newRecord) {
            this.router.navigate(["labour/create-sub-labour"]);
          } else {
            this.labourForm.reset();
            this.clearForm();
          }
        }
        // if (uploadParams.Labour_Code) {
        //   this.fileUploadService.uploadPhoto(this.uploadedPhoto, uploadParams, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_LABOUR_PHOTO)
        //     .subscribe((res) => {
        //       if (redirect) {
        //         this.router.navigate(['labours']);
        //       } else {
        //         this.labourForm.reset();
        //         this.clearForm();
        //       }
        //     });
        // }
        this.logHelperService.logSuccess({
          message: "Associate Update successfully",
        });
        // this.router.navigate(['labours']);
      });
    } else {

      labour.Photo = this.savedCroppedImage;

      this.labourService.addLabour(labour).subscribe((result: any) => {
        uploadParams.Labour_Code = result.Data;
        if (this.uploadedFiles && this.uploadedFiles.length > 0) {
          if (uploadParams.Labour_Code) {
            this.fileUploadService
              .uploadFiles(
                this.uploadedFiles,
                uploadParams,
                GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_LABOUR_DOC
              )
              .subscribe((res) => {
                if (redirect) {
                  this.router.navigate(["labour/labours"]);
                } else if (newRecord) {
                  this.router.navigate(["labour/create-sub-labour"]);
                } else {
                  this.labourForm.reset();
                  this.clearForm();
                }
              });
          }
          if (uploadParams.Labour_Code) {
            this.fileUploadService
              .uploadPhoto(
                this.uploadedPhoto,
                uploadParams,
                GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_LABOUR_PHOTO
              )
              .subscribe((res) => {
                if (redirect) {
                  this.router.navigate(["labour/labours"]);
                } else if (newRecord) {
                  this.router.navigate(["labour/create-sub-labour"]);
                } else {
                  this.labourForm.reset();
                  this.clearForm();
                }
              });
          }
        } else {
          if (redirect) {
            this.router.navigate(["labour/labours"]);
          } else if (newRecord) {
            this.router.navigate(["labour/create-sub-labour"]);
          } else {
            this.labourForm.reset();
            this.clearForm();
          }
        }
        this.logHelperService.logSuccess({
          message: "Associate addded successfully",
        });
        this.labourForm.reset();
        this.clearForm();
      });
    }
  }

  public onAlreadyPFMemberChange(): void {
    const isAlreadyPFMember = this.labourForm.form.get("isAlreadyPFMember").value;

    this.labourForm.form.get("PFUANNo").enable();
    this.labourForm.form.get("PreviousPFNo").enable();
    this.labourForm.form.get("PreviousExitDate").enable();
    this.labourForm.form.get("CertificateNo").enable();
    this.labourForm.form.get("PPOno").enable();

    if (isAlreadyPFMember) {
      this.setControlValidations("PFUANNo");
      this.setControlValidations("PreviousPFNo");
      this.setControlValidations("PreviousExitDate");
      this.setComplianceTabValidations();
    } else {
      this.setControlValidations("PFUANNo", true);
      this.setControlValidations("PreviousPFNo", true);
      this.setControlValidations("PreviousExitDate", true);
      this.labourForm.form.get("PFUANNo").setValue(null);
      this.labourForm.form.get("PreviousPFNo").setValue(null);
      this.labourForm.form.get("PreviousExitDate").setValue(null);
      this.labourForm.form.get("CertificateNo").setValue(null);
      this.labourForm.form.get("PPOno").setValue(null);

      this.labourForm.form.get("PFUANNo").disable();
      this.labourForm.form.get("PreviousPFNo").disable();
      this.labourForm.form.get("PreviousExitDate").disable();
      this.labourForm.form.get("CertificateNo").disable();
      this.labourForm.form.get("PPOno").disable();

      this.clearComplianceTabValidations();
    }
  }

  private setComplianceTabValidations(): void {
    const complianceCheckListObj = this.checkList.find((item) => item.id === 3);
    if (complianceCheckListObj) {
      complianceCheckListObj.childrens.forEach((item: any) => {
        item.isVisible = true;
      });
    }
  }

  private clearComplianceTabValidations(): void {
    const complianceCheckListObj = this.checkList.find((item) => item.id === 3);
    if (complianceCheckListObj) {
      complianceCheckListObj.childrens.forEach((item: any) => {
        if (
          [
            "UAN Number",
            "Previous PF No",
            "Date of exit of previous member ID",
          ].includes(item.text)
        )
          item.isVisible = false;
      });
    }
  }

  private setControlValidations(
    controlId: string,
    clearValidations: boolean = false
  ): void {
    if (clearValidations) {
      this.labourForm.form.get("PreviousExitDate").clearValidators();
      this.labourForm.form.get("PreviousExitDate").updateValueAndValidity();
      return;
    }
    this.labourForm.form.get(controlId).setValidators([Validators.required]);
    this.labourForm.form.get(controlId).updateValueAndValidity();
  }

  getGatePassRequestCode() {
    return this.labourService.getGatePassRequest().subscribe((data: any) => {
      this.gatePassRequestCode = data.Table.Data;
    });
  }
  getWorkSiteData() {
    this.labourService.getWorkSiteData().subscribe((data: any) => {
      this.workSiteData = data;
    });
  }
  getLabourCode() {
    return this.labourService.getLabourCode().subscribe((data: any) => {
      this.labourCode = data;
    });
  }

  // Kh Not find Api in webapi project 30-04-2021
  // getAddressCode() {
  //   return this.labourService.getaAddressCode().subscribe((data: any) => {
  //     this.addressCode = data;
  //   });
  // }

  selected_lCountries: any;
  getCountry() {
    return this.labourService.getCountry();
  }


  getStatesByCountryId() {
    return this.labourService.getStatesByCountryId(this.labourForm.controls["LCountry_Code"].value || 100);
  }

  changeStatesByCountryId() {
    this.getStatesByCountryId().subscribe((res: any) => {
      this.lStates = res;
      if (!this.labourForm.controls["LCountry_Code"].value) {
        this.selected_lCountries = null;
        this.selected_lStates = null;
        this.selected_lDistricts = null;
        this.selected_lCitites = null;
        this.selected_lPostOffice = null;
        this.labourForm.controls['LVillage_Code'].setValue(null);
        this.lStates = [];
        this.lDistricts = [];
        this.lCities = [];
        this.lPos = [];
        this.lVillages = [];
      }
    })
  }

  selected_lDistricts: any;
  getDistrictsByState() {
    return this.labourService.getDistrictsByState(this.labourForm.controls["LState_Code"].value || 100);
  }

  changeDistrictsByState() {
    this.getDistrictsByState().subscribe((res: any) => {
      this.lDistricts = res;
      if (!this.labourForm.controls["LState_Code"].value) {
        this.selected_lStates = null;
        this.selected_lDistricts = null;
        this.selected_lCitites = null;
        this.selected_lPostOffice = null;
        this.labourForm.controls['LVillage_Code'].setValue(null);
        this.lDistricts = [];
        this.lCities = [];
        this.lPos = [];
        this.lVillages = [];
      }
    })
  }

  selected_lCitites: any;
  getCitiesByDistrict() {
    return this.labourService.getCitiesByDistrict(this.labourForm.controls["LDistrict_Code"].value || 100);
  }

  changeCitiesByDistrict() {
    this.getCitiesByDistrict().subscribe((res: any) => {
      this.lCities = res;
      if (!this.labourForm.controls["LDistrict_Code"].value) {
        this.selected_lDistricts = null;
        this.selected_lCitites = null;
        this.selected_lPostOffice = null;
        this.labourForm.controls['LVillage_Code'].setValue(null);
        this.lCities = [];
        this.lPos = [];
        this.lVillages = [];
      }
    })
  }

  getPostOfficeByCity() {
    return this.labourService.getPOsByCity(this.labourForm.controls["LCity_Code"].value || 100)
  }

  changePostOfficeByCity() {
    this.getPostOfficeByCity().subscribe((res: any) => {
      this.lPos = res;
      if (!this.labourForm.controls["LCity_Code"].value) {
        this.selected_lCitites = null;
        this.selected_lPostOffice = null;
        this.labourForm.controls['LVillage_Code'].setValue(null);
        this.lPos = [];
        this.lVillages = [];
      }
    });
  }

  // family dropdowns
  getStatesByCountryIdF(stateCode?: any) {
    return this.labourService
      .getStatesByCountryId(this.familyDetailsFormGroup.get("Country_Code").value || 100)
  }

  changeStatesByCountryIdF() {
    this.getStatesByCountryIdF().subscribe((res: any) => {
      this.familyStates = res;
      if (!this.familyDetailsFormGroup.controls["Country_Code"].value) {
        this.familyStates = [];
        this.familyDistrict = [];
        this.familyCity = [];
        this.familylPos = [];
        this.familyDetailsFormGroup.controls['State_Code'].setValue(null);
        this.familyDetailsFormGroup.controls['District_Code'].setValue(null);
        this.familyDetailsFormGroup.controls['City_Code'].setValue(null);
        this.familyDetailsFormGroup.controls['PostOffice_Code'].setValue(null);
      }
    })
  }

  getDistrictsByStateF(districtCode?: any) {
    return this.labourService
      .getDistrictsByState(this.familyDetailsFormGroup.get("State_Code").value || 100)
  }

  changeDistrictsByStateF() {
    this.getDistrictsByStateF().subscribe((res: any) => {
      this.familyDistrict = res;
      if (!this.familyDetailsFormGroup.controls["State_Code"].value) {
        this.familyDistrict = [];
        this.familyCity = [];
        this.familylPos = [];
        this.familyDetailsFormGroup.controls['District_Code'].setValue(null);
        this.familyDetailsFormGroup.controls['City_Code'].setValue(null);
        this.familyDetailsFormGroup.controls['PostOffice_Code'].setValue(null);
      }
    })
  }

  getCitiesByDistrictF(cityCode?: any) {
    return this.labourService
      .getCitiesByDistrict(this.familyDetailsFormGroup.get("District_Code").value || 100)
  }

  changeCitiesByDistrictF() {
    this.getCitiesByDistrictF().subscribe((res: any) => {
      this.familyCity = res;
      if (!this.familyDetailsFormGroup.controls["District_Code"].value) {
        this.familyCity = [];
        this.familylPos = [];
        this.familyDetailsFormGroup.controls['City_Code'].setValue(null);
        this.familyDetailsFormGroup.controls['PostOffice_Code'].setValue(null);
      }
    })
  }


  getPostOfficeByCityF(postOfficeCode?: any) {
    return this.labourService.getPOsByCity(this.familyDetailsFormGroup.get("City_Code").value || 100)
  }

  changePostOfficeByCityF() {
    this.getPostOfficeByCityF().subscribe((res: any) => {
      this.familylPos = res;
      if (!this.familyDetailsFormGroup.controls["City_Code"].value) {
        this.familylPos = [];
        this.familyDetailsFormGroup.controls['PostOffice_Code'].setValue(null);
      }
    })
  }

  selected_lPostOffice
  getVillage() {
    return this.labourService.getVillageByPostOffice(this.labourForm.value["LPostOffice_Code"] || 100);
  }
  changeVillage() {
    this.getVillage().subscribe((res: any) => {
      this.lVillages = res.Data.Table;
      if (!this.labourForm.controls["LPostOffice_Code"].value) {
        this.selected_lPostOffice = null;
        this.labourForm.controls['LVillage_Code'].setValue(null);
        this.lVillages = [];
      }
    });
  }
  // getVillageC() {
  //   this.labourService.getVillageByPostOffice(this.labourForm.value['PostOffice_Code'] || 100).subscribe((res: any) => {
  //     this.contactVillages = res.Data.Table;
  //   });
  // }
  getStatesByCountryIdP() {
    return this.labourService.getStatesByCountryId(this.labourForm.control.value["PCountry_Code"] || 100);
  }

  changeStatesByCountryIdP() {
    this.getStatesByCountryIdP().subscribe((res: any) => {
      this.pStates = res;
      if (!this.labourForm.control.value["PCountry_Code"]) {
        this.pStates = [];
        this.pDistricts = [];
        this.pCities = [];
        this.pPos = [];
        this.pVillages = [];
        this.labourForm.controls['PState_Code'].setValue(null);
        this.labourForm.controls['PDistrict_Code'].setValue(null);
        this.labourForm.controls['PCity_Code'].setValue(null);
        this.labourForm.controls['PPostOffice_Code'].setValue(null);
        this.labourForm.controls['PVillage_Code'].setValue(null);
      }
    })
  }

  getDistrictsByStateP() {
    return this.labourService.getDistrictsByState(this.labourForm.controls["PState_Code"].value || 100);
  }

  changeDistrictsByStateP() {
    this.getDistrictsByStateP().subscribe((res: any) => {
      this.pDistricts = res;
      if (!this.labourForm.control.value["PState_Code"]) {
        this.pDistricts = [];
        this.pCities = [];
        this.pPos = [];
        this.pVillages = [];
        this.labourForm.controls['PDistrict_Code'].setValue(null);
        this.labourForm.controls['PCity_Code'].setValue(null);
        this.labourForm.controls['PPostOffice_Code'].setValue(null);
        this.labourForm.controls['PVillage_Code'].setValue(null);
      }
    })
  }

  getCitiesByDistrictP() {
    return this.labourService.getCitiesByDistrict(this.labourForm.controls["PDistrict_Code"].value || 100)
  }

  changeCitiesByDistrictP() {
    this.getCitiesByDistrictP().subscribe((res: any) => {
      this.pCities = res;
      if (!this.labourForm.control.value["PDistrict_Code"]) {
        this.pCities = [];
        this.pPos = [];
        this.pVillages = [];
        this.labourForm.controls['PCity_Code'].setValue(null);
        this.labourForm.controls['PPostOffice_Code'].setValue(null);
        this.labourForm.controls['PVillage_Code'].setValue(null);
      }
    })
  }

  getPostOfficeByCityP() {
    return this.labourService.getPOsByCity(this.labourForm.controls["PCity_Code"].value || 100)
  }

  changePostOfficeByCityP() {
    this.getPostOfficeByCityP().subscribe((res: any) => {
      this.pPos = res;
      if (!this.labourForm.control.value["PCity_Code"]) {
        this.pPos = [];
        this.pVillages = [];
        this.labourForm.controls['PPostOffice_Code'].setValue(null);
        this.labourForm.controls['PVillage_Code'].setValue(null);
      }
    })
  }
  getVillageP() {
    return this.labourService.getVillageByPostOffice(this.labourForm.value["PPostOffice_Code"] || 100)
  }

  changeVillageP() {
    this.getVillageP().subscribe((res: any) => {
      this.pVillages = res.Data.Table;
      if (!this.labourForm.control.value["PPostOffice_Code"]) {
        this.labourForm.controls['PVillage_Code'].setValue(null);
      }
    })
  }

  getDisabilityTypes() {
    this.sharedDataService.getMasterData().subscribe((res: any) => {
      if (res && res.Data && res.Data.Table) {
        this.disabilityTypes = res.Data.Table.filter(
          (list) => list.Parent_Code === 846
        );
        if (this.editLabourData) {
          this.labourForm.form
            .get("DisabilityType_Code")
            .setValue(this.editLabourData.DisabilityType_Code);
        }
      }
    });
  }

  //   getCategory() {
  //     //
  //     return this.labourService.getWorkCategory().subscribe((res: any) => {
  //       this.workCategory = res.Data.Table;
  //     });
  //   }
  getCompanyData() {
    return this.labourService.getCompanyData().subscribe((res: any) => {
      this.companyData = res.Data.Table;
      if (this.companyData.length === 1) {
        // this.labourForm.control.value.Company;
        this.labourForm.controls["Company_Code"].setValue(
          this.companyData[0].Company_Code
        );
        this.getPlantTreeStructure();
      } else {
        this.companyData = res.Data.Table;
      }
    });
  }

  getStatesByCountryIdC() {
    return this.labourService
      .getStatesByCountryId(
        this.labourForm.control.value["Country_Code"] || 100
      );
  }

  changeStatesByCountryIdC() {
    this.getStatesByCountryIdC().subscribe((res: any) => {
      this.contactStates = res;
      if (!this.labourForm.control.value["Country_Code"]) {
        this.contactStates = [];
        this.contactDistricts = [];
        this.contactCities = [];
        this.contactlPos = [];
        this.labourForm.controls['State_Code'].setValue(null);
        this.labourForm.controls['District_Code'].setValue(null);
        this.labourForm.controls['City_Code'].setValue(null);
        this.labourForm.controls['PostOffice_Code'].setValue(null);
      }
    })
  }

  getPostOfficeByCityC() {
    return this.labourService
      .getPOsByCity(this.labourForm.control.value["City_Code"] || 100)
  }

  changePostOfficeByCityC() {
    this.getPostOfficeByCityC().subscribe((res: any) => {
      this.contactlPos = res;
      if (!this.labourForm.control.value["City_Code"]) {
        this.contactlPos = [];
        this.labourForm.controls['PostOffice_Code'].setValue(null);
      }
    })
  }

  getDistrictsByStateC() {
    return this.labourService
      .getDistrictsByState(this.labourForm.control.value["State_Code"] || 100)
  }

  changeDistrictsByStateC() {
    this.getDistrictsByStateC().subscribe((res: any) => {
      this.contactDistricts = res;
      if (!this.labourForm.control.value["State_Code"]) {
        this.contactDistricts = [];
        this.contactCities = [];
        this.contactlPos = [];
        this.labourForm.controls['District_Code'].setValue(null);
        this.labourForm.controls['City_Code'].setValue(null);
        this.labourForm.controls['PostOffice_Code'].setValue(null);
      }
    })
  }

  getCitiesByDistrictC() {
    return this.labourService
      .getCitiesByDistrict(
        this.labourForm.control.value["District_Code"] || 100
      )
  }

  changeCitiesByDistrictC() {
    this.getCitiesByDistrictC().subscribe((res: any) => {
      this.contactCities = res;
      if (!this.labourForm.control.value["District_Code"]) {
        this.contactCities = [];
        this.contactlPos = [];
        this.labourForm.controls['City_Code'].setValue(null);
        this.labourForm.controls['PostOffice_Code'].setValue(null);
      }
    })
  }
  //   getMasterItemListData() {
  //     this.labourService.getMasterlistData().subscribe((res: any) => {
  //     //   this.labRelationship = res.Data.Table;
  //       this.masterData = res.Data.Table;

  //       const status = this.masterData.filter(list => list.Parent_Code === 610);
  //       this.firestatustype = this.bindDataforMasterItem(status);
  //       this.statustype = this.bindDataforMasterItem(status);
  //       this.gendertypes = this.masterData.filter(list => list.Parent_Code === 302);
  //       this.relationType = this.masterData.filter(list => list.Parent_Code === 198);
  //       this.bloodGroupType = this.masterData.filter(list => list.Parent_Code === 585);
  //       this.documentType = this.masterData.filter(list => list.Parent_Code === 585);
  //       this.labRelationshipAll = this.masterData.filter(list => list.Parent_Code === 198);
  //       this.maritalStatus = this.masterData.filter(list => list.Parent_Code === 294);
  //       this.religionTypes = this.masterData.filter(list => list.Parent_Code === 362);
  //       this.identityTypes = this.masterData.filter(list => list.Parent_Code === 575);
  //       this.drivingLicenceType = this.masterData.filter(list => list.Parent_Code === 194);
  //       this.qualifications = this.masterData.filter(list => list.Parent_Code === 331);
  //       this.technicalQualifications = this.masterData.filter(list => list.Parent_Code === 342);
  //       this.qulificationstreams = this.masterData.filter(list => list.Parent_Code === 352);
  //       this.bankNames = this.masterData.filter(list => list.Parent_Code === 145);
  //       this.accountTypes = this.masterData.filter(list => list.Parent_Code === 145);
  //       this.bankPaymentTypes = this.masterData.filter(list => list.Parent_Code === 303);
  //       this.typeOfAddress = this.masterData.filter(list => list.MasterItem_Code === 558 || list.MasterItem_Code === 559);
  //       this.erIStatus = this.masterData.filter(list => list.Parent_Code === 598);
  //       this.drivingLicenceTypes = this.masterData.filter(list => list.Parent_Code === 194);
  //       this.eRILevel = [{ Id: 1, Name: 'Managerial/ SuperWiser' }, { Id: 0, Name: 'Non Managerial' }];
  //       this.labourDemandTypes = [{ Id: 0, Name: 'General' }, { Id: 1, Name: 'Shutdown' }];
  //       this.yesNoData = [{ Id: 1, Name: 'Yes' }, { Id: 0, Name: 'No' }];
  //       this.workOrderTypes = this.masterData.filter(list => list.Parent_Code === 226);
  //       this.cardType = this.masterData.filter(list => list.Parent_Code === 601);
  //       this.workSites = this.masterData.filter(list => list.Parent_Code === 612);
  //       this.contactTypes = this.masterData.filter(list => list.Parent_Code === 560);
  //       this.wagecriteria = [{ Id: 1, Name: 'ESIC Applicable' }, { Id: 0, Name: 'E.C Policy Applicable' }];
  //       this.nationalityData = [{ Id: 1, Name: 'Indian' }, { Id: 0, Name: 'Foreigner' }];
  //       this.regularweekly = [
  //         { Id: 1, Name: 'Sun' },
  //         { Id: 2, Name: 'Mon' }, { Id: 3, Name: 'Tue' },
  //         { Id: 4, Name: 'Wed' }, { Id: 5, Name: 'Thu' },
  //         { Id: 6, Name: 'Fri' }, { Id: 7, Name: 'Sat' }];
  //       this.labRelationship = [];
  //       this.labRelationshipAll.forEach((val) => {
  //         //
  //         if (val.Name === 'Father' || val.Name === 'Husband') {
  //           this.labRelationship.push(val);
  //         }
  //       });
  //       if (res.Data.Table.length) {
  //         this.labourForm.reset();
  //         this.labourForm.controls.LAddressTypeMasterList_Code.setValue(558);
  //         this.labourForm.controls.PAddressTypeMasterList_Code.setValue(559);
  //         this.labourForm.controls.ER1StatusMasterItem_Code.setValue(599);
  //       }
  //     });
  //   }

  /**
   * GET plant REQUEST
   * @author Sonal Prajapati
   */
  // getPlants() {
  //   return this.labourService.getPlants().subscribe((res: any) => {
  //     this.plants = res.Data.Table;
  //     if (this.plants.length === 1) {
  //       this.labourForm.controls['Plant_Code'].setValue(this.plants[0].Plant_Code);
  //     } else {
  //       this.plants = res.Data.Table;
  //     }
  //   });
  // }

  // getUnits() {
  //   return this.labourService.getUnits(this.labourForm.control.value['Plant_Code'] || 100).subscribe((res: any) => {
  //     this.units = res.Data.Table;
  //     if (this.units.length === 1) {
  //       this.labourForm.controls['Unit_Code'].setValue(this.units[0].Unit_Code);
  //     } else {
  //       this.units = res.Data.Table;
  //     }
  //   });
  // }

  /** get shift Group data */
  shiftGroupData() {
    return this.labourService.getShiftGroupData().subscribe((res: any) => {
      this.isshift = false;
      this.shiftGroup = res.Data.Table;
    });
  }

  //   role

  getSkillType() {
    return this.labourService.getSkillType().subscribe((response: any) => {
      this.skillTypes = response;
    });
  }

  onSectionDataChange1() {
    // return this.labourService.getSectionData(null, null, this.labourForm.control.value['Plant_Code'],
    //   this.labourForm.control.value['department'], this.labourForm.control.value['Unit_Code'],
    //   this.labourForm.control.value['Company_Code']).subscribe((res: any) => {
    //     this.sectionAllData = res.Data.Table;
    //     this.sectionData = this.sectionAllData.filter(list => list.Level === 0);
    //     this.sectionData1 = this.sectionAllData.filter(list => list.Level === 1);
    //     this.sectionData2 = this.sectionAllData.filter(list => list.Level === 2);
    //     this.section1 = res.Data.Table[0].Parent_Code;
    //   });
  }

  getSubSection1() {
    return this.labourService
      .getSectionData1(
        this.section1,
        this.labourForm.control.value["Section_Code"],
        this.labourForm.control.value["Plant_Code"],
        this.labourForm.control.value["department"],
        this.labourForm.control.value["Unit_Code"],
        this.labourForm.control.value["Company_Code"]
      )
      .subscribe((res: any) => {
        this.sectionData1 = res.Data.Table;
        this.section2 = res.Data.Table[0].Parent_Code;
      });
  }

  getSubSection2() {
    return this.labourService
      .getSectionData1(
        this.section2,
        this.labourForm.control.value["Section_Code1"],
        this.labourForm.control.value["Plant_Code"],
        this.labourForm.control.value["department"],
        this.labourForm.control.value["Unit_Code"],
        this.labourForm.control.value["Company_Code"]
      )
      .subscribe((res: any) => {
        this.sectionData2 = res.Data.Table;
        this.section3 = res.Data.Table[0].Parent_Code;
      });
  }

  getDepartmentData() {
    return this.labourService
      .getDepartmentData(
        this.labourForm.control.value["Plant_Code"],
        this.labourForm.control.value["Unit_Code"],
        this.labourForm.control.value["Company_Code"] || 100
      )
      .subscribe((res: any) => {
        this.departmentData = res.Data.Table;
      });
  }
  getStateData() {
    this.labourService.getStateData().subscribe((data: []) => {
      this.stateData = data;
    });
  }

  getPinCodeData() {
    this.labourService.getPinCodeData().subscribe((data: []) => {
      this.pinCodeData = data;
    });
  }

  getAllLabourDoc() {
    this.labourService.getAllLabourDoc().subscribe((data: []) => {
      this.allLabourDoc = data;
    });
  }

  getAllTrade() {
    return this.commonApiService
      .getAllTradeMaster(this.tradeMasterParams)
      .subscribe((res: any) => {
        this.allTrade = res;
      });
  }

  getPlanData() {
    this.labourService.getPlants().subscribe((data: []) => {
      this.planData = data;
    });
  }


  getPostOfficeData() {
    this.labourService.getPlants().subscribe((data: []) => {
      this.postOfficeData = data;
    });
  }
  getCityData() {
    this.labourService.getPlants().subscribe((data: []) => {
      this.cityData = data;
    });
  }
  getDistrictData() {
    this.labourService.getPlants().subscribe((data: []) => {
      this.districtData = data;
    });
  }

  onCancel() {
    this.router.navigate(["labour/labours"]);
  }

  saveAndNew() {
    this.onSubmit(this.labourForm.value, true);
    // this.router.navigate(['labour/labours']);
    // this.clearForm();
  }

  save() {
    this.onSubmit(this.labourForm.value, true);
    // this.router.navigate(['labour/labsaveAndNewours']);
    // this.clearForm();
  }

  updateAndNew() {
    this.onSubmit(this.labourForm.value, false, true);
    // this.router.navigate(['labour/labours']);
    // this.clearForm();
  }

  clearForm() {
    this.labourForm.reset();
    this.identityDetails = [];
  }

  onFilesChanged(newFiles: any) {
    const set = new Set(newFiles.filter(item => {
      if (item.ID) {
        return item.ID
      }
    }));
    const result = [];

    for (const item of this.copyUploadedFiles) {
      if (!set.has(item.ID)) {
        result.push(item);
      }
    }

    if (result.length && result[0].hasOwnProperty('$id')) {
      this.labourService.deleteTableData(result[0].ID, this.editLabourCode, 6).subscribe((res) => {
      })
    }
    // this.uploadedFiles = newFiles;
  }

  addIdentityDetails(index?: number, isUpdate?: boolean) {
    if (
      !this.labourForm.control.value["TypeOfIdentityMasterItem_Code"]
    ) {
      this.logHelperService.logError({
        message: "Please select any identity type",
      });
      return;
    }

    const identityType =
      this.labourForm.control.value["TypeOfIdentityMasterItem_Code"];
    if (identityType && !isUpdate) {
      const existItem = this.identityDetails.find(
        (item) => item.TypeOfIdentityMasterItem_Code === identityType
      );
      if (existItem) {
        this.logHelperService.logError({
          message:
            "This identity type is already selected! Please select another one.",
        });
        return;
      }
    }

    const nameInIdentity = this.labourForm.control.value["NameInIdentity"];
    if (!nameInIdentity) {
      this.logHelperService.logError({
        message: "Please enter Name as per Identity Proof",
      });
      return;
    }

    const identiyNo = this.labourForm.control.value["IdentiyNo"];
    if (!identiyNo) {
      this.logHelperService.logError({
        message: "Please enter Identity Number",
      });
      return;
    }

    if (this.labourForm.control.value.TypeOfIdentityMasterItem_Code === 580) {
      if (!this.labourForm.control.value.PassportRegistrationDate) {
        this.logHelperService.logError({
          message: "Please enter passport registration date",
        });
        return;
      }

      if (!this.labourForm.control.value.PassportValidityDate) {
        this.logHelperService.logError({
          message: "Please enter passport validity date",
        });
        return;
      }
    }
    this.hideUpdatePOI = true;


    if (
      typeof index !== "undefined"
    ) {
      this.identityDetails[index] = {
        TypeOfIdentityMasterItem_Code:
          this.labourForm.control.value["TypeOfIdentityMasterItem_Code"],
        // tslint:disable-next-line:triple-equals
        DocName: this.identityTypes.find(
          (a) =>
            a.MasterItem_Code ==
            this.labourForm.control.value["TypeOfIdentityMasterItem_Code"]
        ).Name,
        NameInIdentity: this.labourForm.control.value["NameInIdentity"],
        IdentiyNo: this.labourForm.control.value["IdentiyNo"],
        RegistrationDate: this.labourForm.control.value["PassportRegistrationDate"],
        ExpireDate: this.labourForm.control.value["PassportValidityDate"],
        CreateBy: GLOBAL.USER_ID,
      };
      this.labourForm.controls["TypeOfIdentityMasterItem_Code"].reset();
      this.labourForm.controls["NameInIdentity"].reset();
      this.labourForm.controls["IdentiyNo"].reset();
      if (this.labourForm.control.value.TypeOfIdentityMasterItem_Code === 580) {
        this.labourForm.controls["PassportRegistrationDate"].reset();
        this.labourForm.controls["PassportValidityDate"].reset();
      }
      return;
    }

    if (this.labourForm.control.value["TypeOfIdentityMasterItem_Code"]) {
      this.identityDetails.push({
        TypeOfIdentityMasterItem_Code:
          this.labourForm.control.value["TypeOfIdentityMasterItem_Code"],
        DocName: this.identityTypes.find(
          (a) =>
            a.MasterItem_Code ==
            this.labourForm.control.value["TypeOfIdentityMasterItem_Code"]
        ).Name,
        NameInIdentity: this.labourForm.control.value["NameInIdentity"],
        IdentiyNo: this.labourForm.control.value["IdentiyNo"],
        RegistrationDate: this.labourForm.control.value["PassportRegistrationDate"],
        ExpireDate: this.labourForm.control.value["PassportValidityDate"],
      });
      this.labourForm.controls["TypeOfIdentityMasterItem_Code"].reset();
      this.labourForm.controls["NameInIdentity"].reset();
      this.labourForm.controls["IdentiyNo"].reset();
      if (this.labourForm.control.value.TypeOfIdentityMasterItem_Code === 580) {
        this.labourForm.controls["PassportRegistrationDate"].reset();
        this.labourForm.controls["PassportValidityDate"].reset();
      }
    }
  }

  addDrivingLicenseDetails(index?: number, isUpdate?: boolean) {
    if (
      !this.labourForm.control.value.drivingLicenceType
    ) {
      this.logHelperService.logError({
        message: "Please select any diriving license type",
      });
      return;
    }

    const drivingLicenceType =
      this.labourForm.control.value["drivingLicenceType"];
    if (drivingLicenceType && !isUpdate) {
      const existItem = this.identityDetails.find(
        (item) => item.TypeOfIdentityMasterItem_Code === drivingLicenceType
      );
      if (existItem) {
        this.logHelperService.logError({
          message:
            "This licence type is already selected! Please select another one.",
        });
        return;
      }
    }

    if (
      !this.labourForm.form.get("drivingLiceneceName").value
    ) {
      this.logHelperService.logError({
        message: "Please enter driving license name",
      });
      return;
    }

    if (
      !this.labourForm.form.get("licenceBatchNumber").value
    ) {
      this.logHelperService.logError({
        message: "Please enter license batch number",
      });
      return;
    }

    if (
      !this.labourForm.form.get("licenceValidity").value
    ) {
      this.logHelperService.logError({
        message: "Please enter validity date",
      });
      return;
    }

    this.hideUpdateDrivingLicenseButton = true;

    if (
      typeof index !== "undefined"
    ) {
      this.identityDetails[index] = {
        TypeOfIdentityMasterItem_Code:
          this.labourForm.control.value["drivingLicenceType"],
        DocName: this.drivingLicenceTypes.find(
          (a) =>
            a.MasterItem_Code ==
            this.labourForm.control.value["drivingLicenceType"]
        ).Name,
        NameInIdentity: this.labourForm.control.value["drivingLiceneceName"],
        IdentiyNo: this.labourForm.control.value["licenceBatchNumber"],
        ExpireDate: this.labourForm.control.value["licenceValidity"] || null,
      };
      this.labourForm.controls["drivingLicenceType"].reset();
      this.labourForm.controls["drivingLiceneceName"].reset();
      this.labourForm.controls["licenceBatchNumber"].reset();
      if (this.labourForm.control.value["licenceValidity"]) {
        this.labourForm.controls["licenceValidity"].reset();
      }
      this.changeDetect.detectChanges();
      return;
    }


    if (this.labourForm.control.value.drivingLicenceType) {
      const docObj = this.drivingLicenceTypes.find(
        (a) =>
          a.MasterItem_Code ==
          this.labourForm.control.value.drivingLicenceType
      );
      this.identityDetails.push({
        TypeOfIdentityMasterItem_Code:
          this.labourForm.control.value["drivingLicenceType"],
        DocName: docObj ? docObj.Name : "",
        NameInIdentity: this.labourForm.control.value["drivingLiceneceName"],
        IdentiyNo: this.labourForm.control.value["licenceBatchNumber"],
        ExpireDate: this.labourForm.control.value["licenceValidity"] || null,
      });
      this.labourForm.controls["drivingLicenceType"].reset();
      this.labourForm.controls["drivingLiceneceName"].reset();
      this.labourForm.controls["licenceBatchNumber"].reset();
      if (this.labourForm.control.value["licenceValidity"]) {
        this.labourForm.controls["licenceValidity"].reset();
      }
      this.changeDetect.detectChanges();
    }
  }

  editPOI(index, data) {
    const identityNameArr = this.identityTypes.map((item) => item.Name);
    if (identityNameArr.includes(data.DocName)) {
      this.hideUpdatePOI = false;
      this.editPOIIndex = index;

      this.labourForm.controls["TypeOfIdentityMasterItem_Code"].setValue(
        this.identityDetails[index].TypeOfIdentityMasterItem_Code
      );
      this.labourForm.controls["NameInIdentity"].setValue(
        this.identityDetails[index].NameInIdentity
      );
      this.labourForm.controls["IdentiyNo"].setValue(
        this.identityDetails[index].IdentiyNo
      );

      if (this.labourForm.controls["TypeOfIdentityMasterItem_Code"].value === 580) {
        setTimeout(() => {
          this.labourForm.controls["PassportRegistrationDate"].setValue(
            this.identityDetails[index].RegistrationDate
          );
          this.labourForm.controls["PassportValidityDate"].setValue(
            this.identityDetails[index].ExpireDate
          );
        }, 0)
      }

    } else {
      this.hideUpdateDrivingLicenseButton = false;
      this.editDrivingLicenseIndex = index;

      if (
        typeof index !== "undefined" &&
        this.identityDetails[index].hasOwnProperty("ExpireDate")
      ) {
        this.labourForm.controls["drivingLicenceType"].setValue(
          this.identityDetails[index].TypeOfIdentityMasterItem_Code
        );
        this.labourForm.controls["drivingLiceneceName"].setValue(
          this.identityDetails[index].NameInIdentity
        );
        this.labourForm.controls["licenceBatchNumber"].setValue(
          this.identityDetails[index].IdentiyNo
        );
        this.labourForm.controls["licenceValidity"].setValue(
          this.identityDetails[index].ExpireDate
        );
      }
    }
  }

  updatePOI() {
    this.addIdentityDetails(this.editPOIIndex, true);
  }

  updateDrivingLicenseDetails() {
    this.addDrivingLicenseDetails(this.editDrivingLicenseIndex, true);
  }

  resetIdentityDetails() {
    this.hideUpdatePOI = true;
    this.labourForm.controls['TypeOfIdentityMasterItem_Code'].reset();
    this.labourForm.controls['NameInIdentity'].reset();
    this.labourForm.controls['IdentiyNo'].reset();
  }

  resetDrivingLicenseDetails() {
    this.hideUpdateDrivingLicenseButton = true;
    this.labourForm.controls['drivingLicenceType'].reset();
    this.labourForm.controls['drivingLiceneceName'].reset();
    this.labourForm.controls['licenceBatchNumber'].reset();
    if (this.labourForm.control.value["licenceValidity"] && this.labourForm.control.value["RegistrationDate"]) {
      this.labourForm.control.value["licenceValidity"].reset();
      this.labourForm.control.value["RegistrationDate"].reset();
    }
  }

  deletePOI(index, identity) {
    if (identity.hasOwnProperty('$id')) {
      this.labourService.deleteTableData(identity.ID, this.editLabourCode, 0).subscribe((res) => {
      })
    }
    this.identityDetails.splice(index, 1);
    this.changeDetect.detectChanges();
  }

  calculateAge(checkAge: boolean = true) {
    let dob = this.labourForm.control.value["DOB"];
    if (dob) {
      if (typeof dob === "string") {
        dob = moment(dob);
      }
      const dateOfBirth: Date = new Date(dob.toDate());

      const maxDate: Date = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth() + 1,
        new Date().getDate()
      );

      if (dateOfBirth > maxDate && checkAge) {
        this.logHelperService.logError({
          message: "Associate aged below 18 and above 60 is not allowed.",
        });
        // this.labourForm.control.value['Age'].reset();
        this.labourForm.controls["Age"].value.setValue(null);
      } else {
        var currentDate = moment(new Date(), "DD-MM-YYYY");
        var birthDate = moment(dob, "DD-MM-YYYY");

        var years = currentDate.diff(birthDate, "year");
        birthDate.add(years, "years");

        var months = currentDate.diff(birthDate, "months");
        birthDate.add(months, "months");

        if (months == 0) {
          var countMonths = "";
        } else if (months == 1) {
          var countMonths = "1 month ";
        } else {
          var countMonths = months + " months ";
        }

        var days = currentDate.diff(birthDate, "days");
        if (days == 0) {
          var countDays = "";
        } else if (days == 1) {
          var countDays = "1 day";
        } else {
          var countDays = days + " days";
        }

        var diffAge = years + " years " + countMonths + countDays;
        this.labourForm.controls["Age"].setValue(diffAge);
      }
    } else if (checkAge) {
      if (this.labourForm.controls["Age"] === undefined) {
        this.labourForm.controls["Age"].setValue(null);
        this.changeDetect.detectChanges();
        this.logHelperService.logError({
          message: "Associate aged below 18 and above 60 is not allowed.",
        });
      } else {
        this.labourForm.controls["Age"].reset();
      }
    }
    // if (typeof event === 'string') {
    //   this.labourForm.controls['Age'].setValue(this.sharedService.calculateAge(new Date(event)));
    // } else if (event) {
    //   this.labourForm.controls['Age'].setValue(this.sharedService.calculateAge(event.toDate()));
    //   // this.checkAge();
    //   if (this.labourForm.control['Age'] === undefined) {
    //     this.logHelperService.logError({
    //       message: 'Associate aged below 18 and above 60 is not allowed.'
    //     });
    //     this.labourForm.control['Age'].reset();
    //   }

    // }
  }

  // checkAge() {
  //   if (this.labourForm.control['Age'] === undefined) {
  //     {
  //       this.logHelperService.logError({
  //         message: 'Associate aged below 18 and above 60 is not allowed.'
  //       });
  //       this.labourForm.control['Age'].reset();
  //     }
  //   }
  //   return true;
  // }

  adddate(event) {
    if (typeof event === "string") {
      this.labourForm.controls["PoliceValidityDate"].setValue(
        this.sharedService.adddate(new Date(event))
      );
    } else if (event) {
      this.labourForm.controls["PoliceValidityDate"].setValue(
        this.sharedService.adddate(event.toDate())
      );
    }
    this.isDisabledVerificationDate = true;
  }

  addContactDetails(index: number) {
    if (this.labourForm.controls['ContactTypeMasterItem_Code'].value
      && this.labourForm.controls['FullName'].value
      && this.labourForm.controls['Mobile'].value
      && this.labourForm.controls['ZipCode'].value
      && this.labourForm.controls['Country_Code'].value
      && this.labourForm.controls['State_Code'].value
      && this.labourForm.controls['District_Code'].value
      && this.labourForm.controls['City_Code'].value
      && this.labourForm.controls['PostOffice_Code'].value) {

      const relationName = this.relationType.find(
        (item) =>
          item.MasterItem_Code === this.labourForm.control.value.Relation
      );
      if (typeof index !== "undefined") {
        const contactType = this.contactTypes.find(
          (a) =>
            a.MasterItem_Code ===
            this.labourForm.control.value["ContactTypeMasterItem_Code"]
        );
        this.labourContactPersonViews[index] = {
          ContactTypeMasterItem_Code:
            this.labourForm.control.value.ContactTypeMasterItem_Code,
          // tslint:disable-next-line:max-line-length
          ID: this.labourContactPersonViews[index].ID
            ? this.labourContactPersonViews[index].ID
            : 0,
          ContactType: contactType ? contactType.Name : "",
          Address1: this.labourForm.control.value.Address1,
          FullName: this.labourForm.control.value.FullName,
          Address2: this.labourForm.control.value.Address2,
          RelationName: relationName ? relationName.Name : "",
          Relation: this.labourForm.control.value.Relation,
          Address3: this.labourForm.control.value.Address3,
          Mobile: this.labourForm.control.value.Mobile,
          ZipCode: this.labourForm.control.value.ZipCode,
          Country_Code: this.labourForm.control.value.Country_Code,
          State_Code: this.labourForm.control.value.State_Code,
          District_Code: this.labourForm.control.value.District_Code,
          City_Code: this.labourForm.control.value.City_Code,
          PostOffice_Code: this.labourForm.control.value.PostOffice_Code,
        };
        this.cancelContactDetails();
        this.hideUpdateContactDetails = true;
        this.changeDetect.detectChanges();
      } else {
        const contactDetails = this.contactTypes.find(
          (a) =>
            a.MasterItem_Code ==
            this.labourForm.control.value["ContactTypeMasterItem_Code"]
        );
        this.labourContactPersonViews.push({
          ContactTypeMasterItem_Code:
            this.labourForm.control.value.ContactTypeMasterItem_Code,
          ContactType: contactDetails ? contactDetails.Name : "",
          Address1: this.labourForm.control.value.Address1 ? this.labourForm.control.value.Address1 : '',
          FullName: this.labourForm.control.value.FullName,
          Address2: this.labourForm.control.value.Address2 ? this.labourForm.control.value.Address2 : '',
          RelationName: relationName ? relationName.Name : "",
          Relation: this.labourForm.control.value.Relation,
          Address3: this.labourForm.control.value.Address3 ? this.labourForm.control.value.Address3 : '',
          Mobile: this.labourForm.control.value.Mobile,
          ZipCode: this.labourForm.control.value.ZipCode,
          Country_Code: this.labourForm.control.value.Country_Code,
          State_Code: this.labourForm.control.value.State_Code,
          District_Code: this.labourForm.control.value.District_Code,
          City_Code: this.labourForm.control.value.City_Code,
          PostOffice_Code: this.labourForm.control.value.PostOffice_Code,
        });
        this.cancelContactDetails();
        this.changeDetect.detectChanges();
      }
      return;
    } else {
      this.logHelperService.logError({
        message: 'Please fill all required details.'
      });

    }
  }

  editContactDetails(index) {
    this.editContactDetailsIndex = index;
    this.hideUpdateContactDetails = false;
    this.labourForm.controls["ContactTypeMasterItem_Code"].setValue(
      this.labourContactPersonViews[index].ContactTypeMasterItem_Code
    );
    this.labourForm.controls["Address1"].setValue(
      this.labourContactPersonViews[index].Address1
    );
    this.labourForm.controls["FullName"].setValue(
      this.labourContactPersonViews[index].FullName
    );
    this.labourForm.controls["Address2"].setValue(
      this.labourContactPersonViews[index].Address2
    );
    this.labourForm.controls["Relation"].setValue(
      this.labourContactPersonViews[index].Relation
    );
    this.labourForm.controls["Address3"].setValue(
      this.labourContactPersonViews[index].Address3
    );
    this.labourForm.controls["Mobile"].setValue(
      this.labourContactPersonViews[index].Mobile
    );
    this.labourForm.controls["ZipCode"].setValue(
      this.labourContactPersonViews[index].ZipCode
    );
    this.labourForm.controls["Country_Code"].setValue(
      this.labourContactPersonViews[index].Country_Code
    );
    this.getStatesByCountryIdC().subscribe((res: any) => {
      this.contactStates = res;
      this.labourForm.controls["State_Code"].setValue(
        this.labourContactPersonViews[index].State_Code
      );
      this.getDistrictsByStateC().subscribe((res: any) => {
        this.contactDistricts = res;
        this.labourForm.controls["District_Code"].setValue(
          this.labourContactPersonViews[index].District_Code
        );

        this.getCitiesByDistrictC().subscribe((res: any) => {
          this.contactCities = res;
          this.labourForm.controls["City_Code"].setValue(
            this.labourContactPersonViews[index].City_Code
          );

          this.getPostOfficeByCityC().subscribe((res: any) => {
            this.contactlPos = res;
            this.labourForm.controls["PostOffice_Code"].setValue(
              this.labourContactPersonViews[index].PostOffice_Code
            );
          })
        })
      })
    })






    // this.labourForm.controls['Country_Code'].setValue(this.labourContactPersonViews[index].Country_Code);
    // this.labourForm.controls['State_Code'].setValue(this.labourContactPersonViews[index].State_Code);
    // this.labourForm.controls['District_Code'].setValue(this.labourContactPersonViews[index].District_Code);
    // this.labourForm.controls['City_Code'].setValue(this.labourContactPersonViews[index].City_Code);
    // this.labourForm.controls['PostOffice_Code'].setValue(this.labourContactPersonViews[index].PostOffice_Code);
  }

  updateContactDetails() {
    this.addContactDetails(this.editContactDetailsIndex);
  }

  cancelContactDetails() {
    this.labourForm.controls["ContactTypeMasterItem_Code"].reset();
    this.labourForm.controls["Address1"].reset();
    this.labourForm.controls["FullName"].reset();
    this.labourForm.controls["Address2"].reset();
    this.labourForm.controls["Relation"].reset();
    this.labourForm.controls["Address3"].reset();
    this.labourForm.controls["Mobile"].reset();
    this.labourForm.controls["ZipCode"].reset();
    this.labourForm.controls["Country_Code"].reset();
    this.labourForm.controls["State_Code"].reset();
    this.labourForm.controls["District_Code"].reset();
    this.labourForm.controls["City_Code"].reset();
    this.labourForm.controls["PostOffice_Code"].reset();
    this.hideUpdateContactDetails = true;
  }

  deleteContactDetails(index, identity) {
    if (identity.hasOwnProperty('$id')) {
      this.labourService.deleteTableData(identity.ID, this.editLabourCode, 3).subscribe((res) => {
      })
    }
    this.labourContactPersonViews.splice(index, 1);
    this.changeDetect.detectChanges();
  }

  deleteFamilyInfo(index: any, familyObj: any) {
    const existFamilyMmber = this.nomineeInfoData.find(
      (item) => item.LabourFamily_Code === familyObj.LabourFamily_Code
    );
    if (existFamilyMmber) {
      this.logHelperService.logError({
        message: "Please delete the nominee details.",
      });
      return;
    }
    if (familyObj.hasOwnProperty('$id')) {
      this.labourService.deleteTableData(familyObj.ID, this.editLabourCode, 4).subscribe((res) => {
      })
    }

    this.familyInfoData.splice(index, 1);
    Array.isArray(this.familyInfoData) && !this.familyInfoData.length && this.resetNomineeDetailAndDisableForm()
    this.changeDetect.detectChanges();
  }

  resetNomineeDetailAndDisableForm(): void {
    this.nomineeDetailsFormGroup.disable();
    this.nomineeInfoData = []
  }

  ngAfterViewInit() {
    this.disableCompliancePageControl()
    // this.labourForm.reset();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name
    );
    this.photopath = fileToReturn;
    this.photoname = this.imageChangedEvent.target.files[0].name;

    return fileToReturn;
  }

  base64ToFile(data, filename) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady() { }
  loadImageFailed() { }
  rotateLeft(imageCropper: ImageCropperComponent) {
    imageCropper.rotateLeft();
  }
  rotateRight(imageCropper: ImageCropperComponent) {
    imageCropper.rotateRight();
  }
  flipHorizontal(imageCropper: ImageCropperComponent) {
    imageCropper.flipHorizontal();
  }
  flipVertical(imageCropper: ImageCropperComponent) {
    imageCropper.flipVertical();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    // this.formChangesSubscription.unsubscribe();
    // this.stepDataSubscription.unsubscribe();
    // this.masterItemDataSubscription.unsubscribe();
  }

  stepChange({ currentStep, nextStep }) {
    // tslint:disable-next-line: deprecation

    let element;
    this.showNomineeFamilyDropdown = false;
    setTimeout(() => {
      switch (nextStep) {
        case 0:
          element = document.getElementById("labourDetails");
          element.scrollIntoView();
          break;
        case 1:
          element = document.getElementById("officalDetails");
          element.scrollIntoView();
          break;
        case 2:
          element = document.getElementById("complianceDetails");
          element.scrollIntoView();
          break;
        case 3:
          element = document.getElementById("contactDetails");
          element.scrollIntoView();
          break;
        case 4:
          element = document.getElementById("familyDetails");
          element.scrollIntoView();
          break;
        case 5:
          element = document.getElementById("nomineeDetails");
          element.scrollIntoView();
          this.showNomineeFamilyDropdown = true;
          break;
        case 6:
          element = document.getElementById("documentDetails");
          element.scrollIntoView();
          break;
      }
    }, 300);
  }

  captureImage(): void {
    this.activeModal = this.modalService.open(LabourProfileImageComponent, {
      size: "lg",
      backdrop: "static",
    });
    this.activeModal.componentInstance.image = this.savedCroppedImage;
    this.activeModal.componentInstance.croppedImage.subscribe((arg: any) => {
      this.savedCroppedImage = arg;

      this.croppedImage = arg;
      this.activeModal.close();
    });

    this.activeModal.componentInstance.closeModal.subscribe(() => {
      this.activeModal.close();
    });
  }

  saveCroppedImage(): void {
    this.savedCroppedImage = this.croppedImage;
    this.activeModal.close();
  }

  public onCompanyChange(): void {
    this.plants = [];
    this.units = [];
    this.departmentData = [];
    this.sectionData = [];
    this.sectionData1 = [];
    this.sectionData2 = [];
    this.sectionTreeList = [];

    //--mg
    this.labourForm.form.controls["Plant_Code"].setValue(null);
    this.labourForm.form.controls["Unit_Code"].setValue(null);
    this.labourForm.form.controls["department"].setValue(null);
    this.labourForm.form.controls["Section_Code"].setValue(null);
    this.labourForm.form.controls["Section_Code1"].setValue(null);
    this.labourForm.form.controls["Section_Code2"].setValue(null);

    // // this.manPowerForm.controls['State_Code'].setValue(null);
    // this.manPowerForm.controls['Plant_Code'].setValue(null);
    // this.manPowerForm.controls['Unit_Code'].setValue(null);
    // this.manPowerForm.controls['Department_Code'].setValue(null);
    // this.manPowerForm.controls['SectionCodes'].setValue(null);
    // this.manPower.State_Code = null;
    // // this.manPower.Plant_Code = null;
    // this.manPower.Unit_Code = null;
    // this.manPower.Department_Code = null;
    // this.manPower.SectionCodes = null;

    const companyCode = this.labourForm.controls["Company_Code"].value;
    // if (companyCode) {
    // 	this.getStates();
    // }
    this.getPlantTreeStructure(); //--mg
  }

  getPlantTreeStructure() {
    this.unitDepartmentSectionTreeData = [];
    const PLANT_UNIT_DEP_REQUEST =
    // tslint:disable-next-line:ter-indent
    {
      // tslint:disable-next-line:ter-indent
      Filter: {
        UserId: GLOBAL.USER_ID,
      },
      // tslint:disable-next-line:ter-indent
      Fields: [
        {
          KeyName: "Plantcode0",
          DisplayName: "Plant0",
        },
        {
          KeyName: "Unitcode0",
          DisplayName: "Unit0",
        },
        {
          KeyName: "UnitDeptID0",
          DisplayName: "Department0",
          ExtraFields: ["Departmentcode0"],
        },
        {
          KeyName: "LevelID0",
          DisplayName: "SectionName0",
          ExtraFields: ["Sectioncode0"],
        },
        {
          KeyName: "LevelID1",
          DisplayName: "SectionName1",
          ExtraFields: ["Sectioncode1"],
        },
        {
          KeyName: "LevelID2",
          DisplayName: "SectionName2",
          ExtraFields: ["Sectioncode2", "Short_Code"],
        },
      ],
      // tslint:disable-next-line:ter-indent
    };
    this.getDepartmentPlantUnitSectionData(); //-mg
    // this.labourService.getDepartmentPlantUnitSectionTree().subscribe((response: any[]) => {
  }

  public getDepartmentPlantUnitSectionData() {
    this.labourService
      .getDepartmentPlantUnitSectionData()
      .subscribe((response: any[]) => {
        this.unitDepartmentSectionTreeData = response;
        // this.getUnits();
        // this.plants = this.unitDepartmentSectionTreeData.map((x: any) =>
        // { return { Plant_Code: x.Plantcode0, Plant: x.Plant0, Values: x.Values }; });
        this.getPlants();
      });
  }

  public onPlantChange(): void {
    this.units = [];
    this.departmentData = [];
    this.sectionData = [];
    this.sectionData1 = [];
    this.sectionData2 = [];

    this.labourForm.form.controls["Unit_Code"].setValue(null);
    this.labourForm.form.controls["department"].setValue(null);
    this.labourForm.form.controls["Section_Code"].setValue(null);
    this.labourForm.form.controls["Section_Code1"].setValue(null);
    this.labourForm.form.controls["Section_Code2"].setValue(null);

    if (this.editLabourData) {
      this.editLabourData.Unit_Code = null;
      this.editLabourData.Department_Code = null;
      this.editLabourData.Section_Code = null;
      this.editLabourData.Section_Code1 = null;
      this.editLabourData.Section_Code2 = null;
    }

    const plantCode = this.labourForm.form.controls["Plant_Code"].value;
    this.getUnits(plantCode);
    // }
  }

  public onUnitChange(): void {
    this.departmentData = [];
    this.sectionData = [];
    this.sectionData1 = [];
    this.sectionData2 = [];

    this.labourForm.form.controls["department"].setValue(null);
    this.labourForm.form.controls["Section_Code"].setValue(null);
    this.labourForm.form.controls["Section_Code1"].setValue(null);
    this.labourForm.form.controls["Section_Code2"].setValue(null);

    if (this.editLabourData) {
      this.editLabourData.Department_Code = null;
      this.editLabourData.Section_Code = null;
      this.editLabourData.Section_Code1 = null;
      this.editLabourData.Section_Code2 = null;
    }

    // .find(itm => itm.Unitcode0 === companyCode).Values;
    const unitCode = this.labourForm.form.controls["Unit_Code"].value;
    this.getDepartments(unitCode);
  }

  public onDepartmentChange(): void {
    this.sectionData = [];
    this.sectionData1 = [];
    this.sectionData2 = [];

    this.labourForm.form.controls["Section_Code"].setValue(null);
    this.labourForm.form.controls["Section_Code1"].setValue(null);
    this.labourForm.form.controls["Section_Code2"].setValue(null);

    if (this.editLabourData) {
      this.editLabourData.Section_Code = null;
      this.editLabourData.Section_Code1 = null;
      this.editLabourData.Section_Code2 = null;
    }

    const departmentCode = this.labourForm.form.controls["department"].value;
    this.getSections(departmentCode);
  }

  public onSectionDataChange(): void {
    this.sectionData1 = [];
    this.sectionData2 = [];

    this.labourForm.form.controls["Section_Code1"].setValue(null);
    this.labourForm.form.controls["Section_Code2"].setValue(null);

    if (this.editLabourData) {
      this.editLabourData.Section_Code1 = null;
      this.editLabourData.Section_Code2 = null;
    }

    const sectionCode = this.labourForm.form.controls["Section_Code"].value;
    this.getSections1(sectionCode);
  }

  public onSectionData1Change(): void {
    this.sectionData2 = [];

    this.labourForm.form.controls["Section_Code2"].setValue(null);

    if (this.editLabourData) {
      this.editLabourData.Section_Code2 = null;
    }

    const sectionCode = this.labourForm.form.controls["Section_Code1"].value;
    this.getSections2(sectionCode);
  }

  public getPlants(): void {
    this.plants = this.unitDepartmentSectionTreeData.map((x: any) => {
      return { Plant_Code: x.Plantcode0, Plant: x.Plant0, Values: x.Values };
    });
    if (this.editLabourData && this.editLabourData.Plant_Code) {
      this.labourForm.form.controls["Plant_Code"].setValue(
        this.editLabourData.Plant_Code
      );
    } else if (this.labourForm.form.controls["Company_Code"].value && Array.isArray(this.plants) && this.plants.length === 1) { //--mg
      this.labourForm.form.controls["Plant_Code"].setValue(
        this.plants[0].Plant_Code
      );
      this.getUnits(this.plants[0].Plant_Code);
    }
  }

  public getUnits(plantCode: any): void {
    const plantData = this.plants.find((itm) => itm.Plant_Code === plantCode);
    this.units = plantData ? plantData.Values : [];
    if (this.units.length === 1) {
      this.labourForm.form.controls["Unit_Code"].setValue(
        this.units[0].Unitcode0
      );
      this.getDepartments(this.units[0].Unitcode0)
    } else if (this.editLabourData && this.editLabourData.Unit_Code) {
      this.labourForm.form.controls["Unit_Code"].setValue(
        this.editLabourData.Unit_Code
      );
    }
  }

  public getDepartments(unitCode: any): void {
    const unitData = this.units.find((itm) => itm.Unitcode0 === unitCode);
    this.departmentData = unitData ? unitData.Values : [];
    if (this.departmentData.length === 1) {
      this.labourForm.form.controls["department"].setValue(
        this.departmentData[0].Departmentcode0
      );
      this.getSections(this.labourForm.form.controls["department"].value);
    } else if (this.editLabourData && this.editLabourData.Department_Code) {
      this.labourForm.form.controls["department"].setValue(
        this.editLabourData.Department_Code
      );
    }
  }

  selected_SCode: any;
  public getSections(departmentCode: any): void {
    const department = this.departmentData.find(
      (item) => item.Departmentcode0 === departmentCode
    );
    this.sectionData = department ? department.Values : [];
    if (this.sectionData.length == 1) {
      this.selected_SCode = this.sectionData[0].Sectioncode0;
      this.labourForm.form.controls["Section_Code"].setValue(
        this.selected_SCode
      );
      this.getSections1(this.selected_SCode);
    }
    if (this.editLabourData && this.editLabourData.Section_Code) {
      this.labourForm.form.controls["Section_Code"].setValue(
        this.editLabourData.Section_Code
      );
    }
  }

  selected_SCode1: any;
  public getSections1(sectionCode: any): void {
    const sectionObj = this.sectionData.find(
      (item) => item.Sectioncode0 === sectionCode
    );
    this.sectionData1 = sectionObj ? sectionObj.Values : [];

    if (this.sectionData1.length == 1) {
      this.selected_SCode1 = this.sectionData1[0].Sectioncode1;
      this.labourForm.form.controls["Section_Code1"].setValue(
        this.selected_SCode1
      );
      this.getSections2(this.selected_SCode);
    }

    if (this.editLabourData && this.editLabourData.Section_Code1) {
      this.labourForm.form.controls["Section_Code1"].setValue(
        this.editLabourData.Section_Code1
      );
    }
  }

  selected_SCode2: any;
  public getSections2(sectionCode: any): void {
    const sectionObj = this.sectionData1.find(
      (item) => item.Sectioncode1 === sectionCode
    );
    this.sectionData2 = sectionObj ? sectionObj.Values : [];
    if (this.sectionData2.length == 1) {
      this.selected_SCode2 = this.sectionData2[0].Sectioncode2;
      this.labourForm.form.controls["Section_Code2"].setValue(
        this.selected_SCode2
      );
    }
    if (this.editLabourData && this.editLabourData.Section_Code2) {
      this.labourForm.form.controls["Section_Code2"].setValue(
        this.editLabourData.Section_Code2
      );
    }
  }

  private getSectionList(
    plantCode: any,
    unitCode: any,
    departmentCode: any
  ): void {
    // this.manPowerService.getDepartmentPlantUnitSectionTree(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
    // this.labourForm.controls['SectionCodes'].setValue(this.manPower.SectionCodes);
    this.sectionTreeResponses = this.unitDepartmentSectionTreeData;
    this.bindSectionEdit(
      this.unitDepartmentSectionTreeData,
      false,
      this.labourForm.value
    );
    // this.labourForm.form.get('SectionCodes').setValue([200]);
    // });
  }

  bindSectionEdit(response: any[], isEdit: boolean, formData) {
    // let resp = response;
    const resp = this.unitDepartmentSectionTreeData
      .find((itm) => itm.Plantcode0 === formData.Plant_Code)
      .Values.find((itm) => itm.Unitcode0 === formData.Unit_Code)
      .Values.find(
        (itm) => itm.UnitDeptID0 === formData.Department_Code
      ).Values;
    // .map((e) => { return { Department_Code: e.UnitDeptID0, Name: e.Department0 }; });
    const convertedData = [];
    resp.forEach((val) => {
      // val.Values.forEach((plant) => {
      //   plant.Values.forEach((unit) => {
      //     unit.Values.forEach((department) => {
      //       department.Values.forEach((section1) => {
      convertedData.push(val);

      // section1.SectionList.forEach((sectionList2) => {
      //   sectionList2.SectionList.forEach((sectionList3) => {

      //   });
      // });
    });
    //     });
    //   });
    // });
    // });

    this.sectionTreeList = this.bindSection1Tree(convertedData, isEdit);
  }

  bindCompanyTree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.PlantTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          id: val.Company_Code,
          companyCode: val.Company_Code,
          name: val.Name,
          currentname: "company",
          isActive: false,
          selected: false,
          children: this.bindPlantTree(subDataFilter, val.Company_Code, isEdit),
        });
      } else {
        convertedData.push({
          id: val.Company_Code,
          companyCode: val.Company_Code,
          name: val.Name,
          currentname: "company",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindPlantTree(filteredData, companyCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val: any, key) => {
      const subDataFilter = val.UnitTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          companyCode,
          id: val.Company_Code,
          plantCode: val.Plant_Code,
          name: val.Name,
          currentname: "plant",
          isActive: false,
          selected: false,
          children: this.bindUnitTree(
            subDataFilter,
            companyCode,
            val.Plant_Code,
            isEdit
          ),
        });
      } else {
        convertedData.push({
          companyCode,
          id: val.Company_Code,
          plantCode: val.Plant_Code,
          name: val.Name,
          currentname: "plant",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindUnitTree(filteredData, companyCode, plantCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.DepartmentTree;
      if (subDataFilter.length > 0) {
        convertedData.push({
          companyCode,
          plantCode,
          id: val.Unit_Code,
          unitCode: val.Unit_Code,
          name: val.Name,
          currentname: "unit",
          isActive: false,
          selected: false,
          children: this.bindDepartmentTree(
            subDataFilter,
            companyCode,
            plantCode,
            val.Unit_Code,
            isEdit
          ),
        });
      } else {
        convertedData.push({
          companyCode,
          plantCode,
          id: val.Unit_Code,
          unitCode: val.Unit_Code,
          name: val.Name,
          currentname: "unit",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindDepartmentTree(
    filteredData,
    companyCode,
    plantCode,
    unitCode,
    isEdit: boolean
  ) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionTree;
      if (subDataFilter.length > 0) {
        const childrens = ([] = this.bindSection1Tree(subDataFilter, isEdit));
        convertedData.push({
          companyCode,
          plantCode,
          unitCode,
          id: val.Department_Code,
          sectionCode: val.Department_Code,
          name: val.Name,
          currentname: "department",
          isActive: false,
          selected: false,
          children: childrens,
          // isCheck: filteredData.length === 1 ? true : false
        });
      } else {
        convertedData.push({
          companyCode,
          plantCode,
          unitCode,
          id: val.Department_Code,
          sectionCode: val.Department_Code,
          name: val.Name,
          currentname: "department",
          isActive: false,
          selected: false,
          checked: isEdit,
        });
      }
    });
    return convertedData;
  }

  bindSection1Tree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.Values;
      if (subDataFilter.length > 0) {
        const childrens = ([] = this.bindSection2Tree(
          subDataFilter,
          val.Sectioncode0,
          isEdit,
          filteredData.length
        ));
        convertedData.push({
          id: val.Sectioncode0,
          sectionCode: val.Sectioncode0,
          itemName: val.SectionName0,
          currentname: "section1",
          isActive: false,
          selected: false,
          children: childrens,
          isCheck:
            filteredData.length === 1 && childrens.length === 0 ? true : false,
        });
      } else {
        let isCheck = false;

        const sectionCodes = this.labourForm.controls["SectionCodes"].value;
        if (sectionCodes.length > 0) {
          if (sectionCodes.find((item) => item.id === val.Sectioncode0)) {
            isCheck = true;
          }
        } else if (filteredData.length === 1) {
          isCheck = true;
        }
        convertedData.push({
          id: val.Sectioncode0,
          sectionCode: val.Sectioncode0,
          itemName: val.Name,
          currentname: "section1",
          isActive: false,
          selected: false,
          checked: isCheck,
        });
      }
    });
    return convertedData;
  }

  bindSection2Tree(
    filteredData,
    section1Code,
    isEdit: boolean,
    firstLevelLength
  ) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.Values;
      if (subDataFilter.length > 0) {
        const childrens = ([] = this.bindSectionTree(
          subDataFilter,
          section1Code,
          val.Sectioncode1,
          isEdit,
          firstLevelLength,
          filteredData.length
        ));
        convertedData.push({
          section1Code,
          id: val.Sectioncode1,
          sectionCode: val.Sectioncode1,
          itemName: val.SectionName1,
          currentname: "section2",
          isActive: false,
          selected: false,
          children: childrens,
          isCheck:
            firstLevelLength === 1 &&
              filteredData.length === 1 &&
              childrens.length === 0
              ? true
              : false,
        });
      } else {
        let isCheck = false;

        const sectionCodes = this.labourForm.controls["SectionCodes"].value;
        if (sectionCodes.length > 0) {
          if (sectionCodes.find((item) => item.id === val.Sectioncode1)) {
            isCheck = true;
          }
        } else if (firstLevelLength === 1 && filteredData.length === 1) {
          isCheck = true;
        }
        convertedData.push({
          section1Code,
          id: val.Sectioncode1,
          sectionCode: val.Sectioncode1,
          itemName: val.SectionName1,
          currentname: "section2",
          isActive: false,
          selected: false,
          checked: isCheck,
        });
      }
    });
    return convertedData;
  }

  bindSectionTree(
    filteredData,
    section1Code,
    section2Code,
    isEdit: boolean,
    firstLevelLength,
    secondeLevelLength
  ) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      let isCheck = false;
      const sectionCodes = this.labourForm.controls["SectionCodes"].value;
      if (sectionCodes && sectionCodes.length > 0) {
        if (sectionCodes.find((item) => item.id === val.LevelID2)) {
          isCheck = true;
        }
      } else if (
        firstLevelLength === 1 &&
        secondeLevelLength === 1 &&
        filteredData.length === 1
      ) {
        isCheck = true;
      }
      convertedData.push({
        section1Code,
        section2Code,
        id: val.LevelID2,
        sectionCode: val.LevelID2,
        itemName: val.SectionName2,
        currentname: "section3",
        isActive: false,
        selected: false,
        checked: isCheck,
      });
    });
    return convertedData;
  }

  getRelationName(id) {
    const relationData = this.labRelationshipAll.find(
      (a) => a.MasterItem_Code === id
    );
    return relationData ? relationData.Name || "" : "";
  }

  public editFamilyInfo(index: number): void {
    if (index >= 0) {
      const familyData = this.familyInfoData[index];
      this.editFamilyIndex = index;
      this.isAddFamilyDetails = false;
      this.familyDetailsFormGroup.patchValue({
        ...familyData,
        FMI_DOB: familyData.DOB,
        isSameAddress: !familyData.Address1,
      });

      this.onFamilySameAddressChange();

      if (familyData.State_Code) {
        this.getStatesByCountryIdF(familyData.State_Code);
      }
    }
  }

  addFamilyDetailDetailsOld(index?: number) {
    if (
      !this.labourForm.control.value["Name"] &&
      !this.labourForm.control.value["FamilyRelationMasterList_Code"] &&
      !this.labourForm.control.value["FMI_DOB"]
    ) {
      this.logHelperService.logError({
        message: "Please fill any detail.",
      });
    } else {
      if (typeof index !== "undefined") {
        this.familyInfoData[index] = {
          Name: this.labourForm.control.value.Name,
          Profession: this.labourForm.control.value.Profession,
          NationalityCountry_Code:
            this.labourForm.control.value.NationalityCountry_Code,
          FamilyRelationMasterList_Code:
            this.labourForm.control.value.FamilyRelationMasterList_Code,
          RelationMasterList_Code:
            this.labourForm.control.value.FamilyRelationMasterList_Code,
          Remark: this.labourForm.control.value.Remark,
          Relation: this.getRelationName(
            this.labourForm.control.value.FamilyRelationMasterList_Code
          ),
          // DOB: this.datePipe.transform(this.labourForm.control.value.FMI_DOB, 'dd-MM-yyyy'),
          DOB: this.labourForm.control.value.FMI_DOB
            ? moment(this.labourForm.control.value.FMI_DOB).format(
              this.sharedService.datePickerSettings.requestFormat
            )
            : null,
          Address1: this.labourForm.control.value.addressType3,
          Address2: this.labourForm.control.value.addressType3,
          Address3: this.labourForm.control.value.addressType3,
          Zipcode: this.labourForm.control.value.zipCode,
          Country_Code: this.labourForm.control.value.country,
          State_Code: this.labourForm.control.value.state,
          District_Code: this.labourForm.control.value.district,
          City_Code: this.labourForm.control.value.city,
          PostOffice_Code: this.labourForm.control.value.postOffice,
          Gender: this.labourForm.control.value.Gender,
          BloodGroup: this.labourForm.control.value.FamilyBloodGroup,
          MonthIncome: this.labourForm.control.value.MonthIncome,
          CreateBy: GLOBAL.USER_ID,
        };

        this.labourForm.controls["Name"].reset();
        this.labourForm.controls["Profession"].reset();
        this.labourForm.controls["NationalityCountry_Code"].reset();
        this.labourForm.controls["FamilyRelationMasterList_Code"].reset();
        this.labourForm.controls["Remark"].reset();
        this.labourForm.controls["zipCode"].reset();
        this.labourForm.controls["country"].reset();
        this.labourForm.controls["state"].reset();
        this.labourForm.controls["district"].reset();
        this.labourForm.controls["city"].reset();
        this.labourForm.controls["PostOffice_Code"].reset();
        this.labourForm.controls["Gender"].reset();
        this.labourForm.controls["FamilyBloodGroup"].reset();
      } else {
        const familyObj = {
          Name: this.labourForm.control.value.Name,
          Profession: this.labourForm.control.value.Profession,
          NationalityCountry_Code:
            this.labourForm.control.value.NationalityCountry_Code,
          FamilyRelationMasterList_Code:
            this.labourForm.control.value.FamilyRelationMasterList_Code,
          RelationMasterList_Code:
            this.labourForm.control.value.FamilyRelationMasterList_Code,
          Remark: this.labourForm.control.value.Remark,
          Relation: this.getRelationName(
            this.labourForm.control.value.FamilyRelationMasterList_Code
          ),
          DOB: this.labourForm.control.value.FMI_DOB
            ? moment(this.labourForm.control.value.FMI_DOB).format(
              this.sharedService.datePickerSettings.requestFormat
            )
            : null,
          Address1: this.labourForm.control.value.addressType3,
          Address2: this.labourForm.control.value.addressType3,
          Address3: this.labourForm.control.value.addressType3,
          Zipcode: this.labourForm.control.value.zipCode,
          Country_Code: this.labourForm.control.value.country,
          State_Code: this.labourForm.control.value.state,
          District_Code: this.labourForm.control.value.district,
          City_Code: this.labourForm.control.value.city,
          PostOffice_Code: this.labourForm.control.value.postOffice,
          Gender: this.labourForm.control.value.Gender,
          BloodGroup: this.labourForm.control.value.FamilyBloodGroup,
          MonthIncome: this.labourForm.control.value.MonthIncome,
          CreateBy: GLOBAL.USER_ID,
        };

        if (this.editLabourCode) {
          this.labourService
            .addFamilyMember({ ...familyObj, Labour_Code: this.editLabourCode })
            .subscribe((response: any) => {
              if (response && response.Data) {
                this.familyInfoData.push({
                  ...familyObj,
                  LabourFamily_Code: response.Data,
                });
                this.resetFamilyDetails();

                this.changeDetect.detectChanges();
              } else {
                this.logHelperService.logError({
                  message: "Family member is not added sucessfully.",
                });
              }
            });
        } else {
          // const lastIndex = this.familyInfoData.length - 1;
          this.familyInfoData.push(familyObj);
          this.resetFamilyDetails();

          this.changeDetect.detectChanges();
        }
      }
      return;
    }
  }

  public addFamilyDetails(): void {
    if (this.familyDetailsFormGroup.valid) {
      const formValue = this.familyDetailsFormGroup.getRawValue();
      formValue.DOB = formValue.FMI_DOB
        ? moment(formValue.FMI_DOB).format(
          this.sharedService.datePickerSettings.requestFormat
        )
        : null;
      formValue.Relation = this.getRelationName(
        formValue.FamilyRelationMasterList_Code
      );
      formValue.RelationMasterList_Code =
        formValue.FamilyRelationMasterList_Code;
      formValue.CreateBy = GLOBAL.USER_ID;

      if (this.editLabourCode) {
        this.labourService
          .addFamilyMember({ ...formValue, Labour_Code: this.editLabourCode })
          .subscribe((response: any) => {
            if (response && response.Data) {
              this.logHelperService.logSuccess({
                message: "Family member is added successfully.",
              });
              this.familyInfoData.push({
                ...formValue,
                Labour_Code: this.editLabourCode,
                LabourFamily_Code: response.Data,
              });
              this.resetFamilyDetails();

              this.changeDetect.detectChanges();
            } else {
              this.logHelperService.logError({
                message: "Family member is not added sucessfully.",
              });
            }
          });
      } else {
        this.familyInfoData.push(formValue);
        this.resetFamilyDetails();

        this.changeDetect.detectChanges();
      }
      this.nomineeDetailsFormGroup.enable();
    } else {
      this.logHelperService.logError({
        message: "Please select all the required fields.",
      });
    }
  }

  public updateFamilyDetails(): void {
    if (this.editFamilyIndex >= 0) {
      if (this.familyDetailsFormGroup.valid) {
        const formValue = this.familyDetailsFormGroup.getRawValue();
        (formValue.DOB = formValue.FMI_DOB
          ? moment(formValue.FMI_DOB).format(
            this.sharedService.datePickerSettings.requestFormat
          )
          : null),
          (formValue.Relation = this.getRelationName(
            formValue.FamilyRelationMasterList_Code
          ));
        formValue.RelationMasterList_Code =
          formValue.FamilyRelationMasterList_Code;
        formValue.UserID = GLOBAL.USER_ID;

        const currentEditObj = {
          ...this.familyInfoData[this.editFamilyIndex],
          ...formValue,
        };

        const { isSameAddress, ...requestObj } = currentEditObj;

        if (this.editLabourCode) {
          this.labourService
            .updateFamilyMember(requestObj)
            .subscribe((response: any) => {
              // if (response && response.Data) {
              this.logHelperService.logSuccess({
                message: "Family member is updated successfully.",
              });
              this.familyInfoData[this.editFamilyIndex] = currentEditObj;
              this.resetFamilyDetails();

              this.changeDetect.detectChanges();
              // } else {
              //   this.logHelperService.logError({
              //     message: 'Family member is not added successfully.'
              //   });
              // }
            });
        } else {
          this.familyInfoData[this.editFamilyIndex] = currentEditObj;

          this.resetFamilyDetails();

          this.changeDetect.detectChanges();
        }
      } else {
        this.logHelperService.logError({
          message: "Please select all the required fields.",
        });
      }
    }
  }

  public cancelFamilyDetails() {
    if (!this.isAddFamilyDetails) {
      this.isAddFamilyDetails = !this.isAddFamilyDetails
    }
    this.resetFamilyDetails();
  }

  onFamilySameAddressChange(): void {
    const isSameAddress =
      this.familyDetailsFormGroup.get("isSameAddress").value;
    if (isSameAddress) {
      this.familyDetailsFormGroup.get("Address1").clearValidators();
      this.familyDetailsFormGroup.get("Address1").updateValueAndValidity();
      this.familyDetailsFormGroup.get("Address2").clearValidators();
      this.familyDetailsFormGroup.get("Address2").updateValueAndValidity();
      this.familyDetailsFormGroup.get("Address3").clearValidators();
      this.familyDetailsFormGroup.get("Address3").updateValueAndValidity();

      this.familyDetailsFormGroup.get("Address1").setValue(null);
      this.familyDetailsFormGroup.get("Address2").setValue(null);
      this.familyDetailsFormGroup.get("Address3").setValue(null);
      this.familyDetailsFormGroup.get("Country_Code").setValue(null);
      this.familyDetailsFormGroup.get("State_Code").setValue(null);
      this.familyDetailsFormGroup.get("District_Code").setValue(null);
      this.familyDetailsFormGroup.get("City_Code").setValue(null);
      this.familyDetailsFormGroup.get("PostOffice_Code").setValue(null);
      this.familyDetailsFormGroup.get("Zipcode").setValue(null);

      this.familyDetailsFormGroup.get("Address1").disable();
      this.familyDetailsFormGroup.get("Address2").disable();
      this.familyDetailsFormGroup.get("Address3").disable();
      this.familyDetailsFormGroup.get("Country_Code").disable();
      this.familyDetailsFormGroup.get("State_Code").disable();
      this.familyDetailsFormGroup.get("District_Code").disable();
      this.familyDetailsFormGroup.get("City_Code").disable();
      this.familyDetailsFormGroup.get("PostOffice_Code").disable();
      this.familyDetailsFormGroup.get("Zipcode").disable();
    } else {
      this.familyDetailsFormGroup
        .get("Address1")
        .setValidators([Validators.required]);
      this.familyDetailsFormGroup.get("Address1").updateValueAndValidity();
      this.familyDetailsFormGroup
        .get("Address2")
        .setValidators([Validators.required]);
      this.familyDetailsFormGroup.get("Address2").updateValueAndValidity();
      this.familyDetailsFormGroup
        .get("Address3")
        .setValidators([Validators.required]);
      this.familyDetailsFormGroup.get("Address3").updateValueAndValidity();

      this.familyDetailsFormGroup.get("Address1").enable();
      this.familyDetailsFormGroup.get("Address2").enable();
      this.familyDetailsFormGroup.get("Address3").enable();
      this.familyDetailsFormGroup.get("Country_Code").enable();
      this.familyDetailsFormGroup.get("State_Code").enable();
      this.familyDetailsFormGroup.get("District_Code").enable();
      this.familyDetailsFormGroup.get("City_Code").enable();
      this.familyDetailsFormGroup.get("PostOffice_Code").enable();
      this.familyDetailsFormGroup.get("Zipcode").enable();
    }
  }

  private resetFamilyDetails(): void {
    this.familyDetailsFormGroup.reset();
    this.familyDetailsFormGroup.patchValue({ isSameAddress: false });
    this.isAddFamilyDetails = true;
    this.onFamilySameAddressChange();
  }

  public addNomineeDetails(): void {
    const errors = this.nomineeDetailsFormGroup.get("NominationPer").errors;
    if (errors && errors.max) {
      this.logHelperService.logError({
        message: "Invalid Percentage! You can not enter more than 100%.",
      });

      return;
    }

    if (!this.nomineeDetailsFormGroup.valid) {
      this.logHelperService.logError({
        message: "Please select all the required fields.",
      });

      return;
    }
    const formValue = this.nomineeDetailsFormGroup.getRawValue();
    // const isCheckPercentage = ['EPF', 'Gratuity'].includes(formValue.NominationFor);
    // if (isCheckPercentage) {
    //   const nomineePercentageAllList = this.nomineeInfoData.filter(item => item.NominationFor === formValue.NominationFor).map(item => item.NominationPer || 0);
    //   if (nomineePercentageAllList.length) {
    //     const total = nomineePercentageAllList.reduce((a, b) => a + b) + Number(formValue.NominationPer);
    //     if (total !== 100) {
    //       this.logHelperService.logError({
    //         title: `Invalid Percentage!`,
    //         message: `${formValue.NominationFor} should be equal to 100%.`
    //       });
    //     }
    //   }
    // }
    const checkDuplicate = this.nomineeInfoData.some(
      (item) =>
        item.LabourFamily_Code === formValue.LabourFamily_Code &&
        item.NominationFor === formValue.NominationFor
    );
    if (checkDuplicate) {
      this.logHelperService.logError({
        message: `Family member for ${formValue.NominationFor} is already exist.`,
      });
      return;
    }
    const familyMemberObj = this.familyInfoData.find(
      (item) => item.LabourFamily_Code === formValue.LabourFamily_Code
    );
    if (familyMemberObj) {
      formValue.Name = familyMemberObj.Name;
      formValue.RelationshipMasterList_Code =
        familyMemberObj.RelationshipMasterList_Code;
      formValue.Relation = familyMemberObj.Relation;
      formValue.NominationPer = formValue.NominationPer
        ? +formValue.NominationPer
        : null;
      this.nomineeInfoData = this.nomineeInfoData || [];
      this.nomineeInfoData.push(formValue);

      this.nomineeDetailsFormGroup.reset();
      this.isAddNomineeDetails = true;
      this.changeDetect.detectChanges();
    } else {
      this.logHelperService.logError({
        message: "Family member does not exist.",
      });
    }
  }

  public updateNomineeDetails(): void {
    if (this.editNomineeIndex >= 0) {
      const errors = this.nomineeDetailsFormGroup.get("NominationPer").errors;
      if (errors && errors.max) {
        this.logHelperService.logError({
          message: "Invalid Percentage! You can not enter more than 100%.",
        });

        return;
      }

      if (!this.nomineeDetailsFormGroup.valid) {
        this.logHelperService.logError({
          message: "Please select all the required fields.",
        });

        return;
      }

      const formValue = this.nomineeDetailsFormGroup.getRawValue();
      const duplicateItemIndex = this.nomineeInfoData.findIndex(
        (item) =>
          item.LabourFamily_Code === formValue.LabourFamily_Code &&
          item.NominationFor === formValue.NominationFor
      );
      if (
        duplicateItemIndex !== -1 &&
        duplicateItemIndex !== this.editNomineeIndex
      ) {
        this.logHelperService.logError({
          message: `Family member for ${formValue.NominationFor} is already exist.`,
        });
        return;
      }
      const familyMemberObj = this.familyInfoData.find(
        (item) => item.LabourFamily_Code === formValue.LabourFamily_Code
      );
      if (familyMemberObj) {
        formValue.Name = familyMemberObj.Name;
        formValue.RelationshipMasterList_Code =
          familyMemberObj.RelationshipMasterList_Code;
        formValue.Relation = familyMemberObj.Relation;
        formValue.NominationPer = formValue.NominationPer
          ? +formValue.NominationPer
          : null;

        this.nomineeInfoData = this.nomineeInfoData || [];
        const currentEditObj = this.nomineeInfoData[this.editNomineeIndex];
        this.nomineeInfoData[this.editNomineeIndex] = {
          ...currentEditObj,
          ...formValue,
        };

        this.editNomineeIndex = null;
        this.nomineeDetailsFormGroup.reset();
        this.isAddNomineeDetails = true;
        this.changeDetect.detectChanges();
      }
    }
  }

  public cancelNomineeDetails() {
    if (!this.isAddNomineeDetails) {
      this.isAddNomineeDetails = !this.isAddNomineeDetails;
    }
    this.nomineeDetailsFormGroup.reset();
  }

  public editNomineeInfo(index: number): void {
    if (index >= 0) {
      this.editNomineeIndex = index;
      this.isAddNomineeDetails = false;
      this.nomineeDetailsFormGroup.patchValue({
        ...this.nomineeInfoData[this.editNomineeIndex],
      });

      setTimeout(() => {
        this.onNominationForChange(false);
      });
    }
  }

  public deleteNomineeInfo(index: number, nomineeInfo: any): void {
    if (index >= 0) {
      this.editNomineeIndex = null;
      this.isAddNomineeDetails = true;
      this.nomineeDetailsFormGroup.reset();
      if (nomineeInfo.hasOwnProperty('$id')) {
        this.labourService.deleteTableData(nomineeInfo.ID, this.editLabourCode, 5).subscribe((res) => {
        })
      }
      this.nomineeInfoData = this.nomineeInfoData.filter(
        (item, currentIndex) => currentIndex !== index
      );
    }
  }

  public onNominationForChange(clearValue: boolean = true): void {
    if (clearValue) {
      this.nomineeDetailsFormGroup.get("NominationPer").setValue("");
    }
    const nomineeFor = this.nomineeDetailsFormGroup.get("NominationFor").value;
    this.showNomineePercentage = ["EPF", "Gratuity"].includes(nomineeFor);
    if (this.showNomineePercentage) {
      this.nomineeDetailsFormGroup
        .get("NominationPer")
        .setValidators([Validators.required, Validators.max(100)]);
      this.nomineeDetailsFormGroup
        .get("NominationPer")
        .updateValueAndValidity();
    } else {
      this.nomineeDetailsFormGroup.get("NominationPer").clearValidators();
      this.nomineeDetailsFormGroup
        .get("NominationPer")
        .updateValueAndValidity();
    }
  }

  // work experience details start

  public addWorkExperience(): void {
    if (this.workExperienceFormGroup.valid) {
      const formValue = this.workExperienceFormGroup.getRawValue();
      this.workExperienceList = this.workExperienceList || [];
      this.workExperienceList.push(formValue);

      this.workExperienceFormGroup.reset();
    } else {
      this.logHelperService.logError({
        message: "Please enter all the required fields.",
      });
    }
  }

  public updateWorkExperience(): void {
    if (this.workExperienceFormGroup.valid) {
      const formValue = this.workExperienceFormGroup.getRawValue();
      const currentEditObj =
        this.workExperienceList[this.editWorkExperienceIndex];
      this.workExperienceList[this.editWorkExperienceIndex] = {
        ...currentEditObj,
        ...formValue,
      };

      this.editWorkExperienceIndex = null;
      this.workExperienceFormGroup.reset();
      this.isAddWorkExperience = true;
    } else {
      this.logHelperService.logError({
        message: "Please enter all the required fields.",
      });
    }
  }

  public cancelWorkExperience() {
    if (!this.isAddWorkExperience) {
      this.isAddWorkExperience = !this.isAddWorkExperience;
    }
    this.workExperienceFormGroup.reset();
  }

  public editWorkExperience(index: number): void {
    this.editWorkExperienceIndex = index;
    this.workExperienceFormGroup.patchValue({
      ...this.workExperienceList[this.editWorkExperienceIndex],
    });
    this.isAddWorkExperience = false;
  }

  public deleteWorkExperience(index: number, workExperience): void {
    this.editWorkExperienceIndex = null;
    this.isAddWorkExperience = true;
    this.workExperienceFormGroup.reset();
    if (workExperience.hasOwnProperty('$id')) {
      this.labourService.deleteTableData(workExperience.ID, this.editLabourCode, 2).subscribe((res) => {
      })
    }
    this.workExperienceList = this.workExperienceList.filter(
      (item, currentIndex) => currentIndex !== index
    );
  }

  // qualification details start

  public addQualification(): void {
    if (this.qualificationFormGroup.valid) {
      const formValue = this.qualificationFormGroup.getRawValue();
      this.qualificationList = this.qualificationList || [];
      const educationTypeObj = this.educationTypeList.find(
        (item) => item.MasterItem_Code === formValue.EducationTypeMLT_Code
      );
      if (educationTypeObj) {

        this.qualificationList.push({
          ...formValue,
          EducationName: educationTypeObj.Name,
        });

        this.qualificationFormGroup.reset();
      }

    } else {
      this.logHelperService.logError({
        message: "Please enter all the required fields.",
      });
    }
  }

  public updateQualification(): void {
    if (this.qualificationFormGroup.valid) {
      const formValue = this.qualificationFormGroup.getRawValue();
      const educationTypeObj = this.educationTypeList.find(
        (item) => item.MasterItem_Code === formValue.EducationTypeMLT_Code
      );
      if (educationTypeObj) {
        formValue.EducationName = educationTypeObj.Name;

        const currentEditObj =
          this.qualificationList[this.editQualificationIndex];
        this.qualificationList[this.editQualificationIndex] = {
          ...currentEditObj,
          ...formValue,
        };

        this.editQualificationIndex = null;
        this.qualificationFormGroup.reset();
        this.isAddQualification = true;
      }
    } else {
      this.logHelperService.logError({
        message: "Please enter all the required fields.",
      });
    }
  }

  public cancelQualification() {
    if (!this.isAddQualification) {
      this.isAddQualification = !this.isAddQualification;
    }
    this.qualificationFormGroup.reset();
  }

  public editQualification(index: number): void {
    this.editQualificationIndex = index;
    this.qualificationFormGroup.patchValue({
      ...this.qualificationList[this.editQualificationIndex],
    });
    this.isAddQualification = false;
  }

  public deleteQualification(index: number, qualification): void {
    this.editQualificationIndex = null;
    this.isAddQualification = true;
    this.qualificationFormGroup.reset();
    if (qualification.hasOwnProperty('$id')) {
      this.labourService.deleteTableData(qualification.ID, this.editLabourCode, 1).subscribe((res) => {
      })
    }
    this.qualificationList = this.qualificationList.filter(
      (item, currentIndex) => currentIndex !== index
    );
  }

  public onSpeciallyAbledChange(): void {
    const isSpeciallyAbled = this.labourForm.form.get(
      "IsPhysicallyChallenge"
    ).value;
    if (isSpeciallyAbled) {
      this.labourForm.form.get("DisabilityType_Code").enable();
      this.labourForm.form.get("DisabilityType_Code").setValue(null);
    } else {
      this.labourForm.form.get("DisabilityType_Code").disable();
      this.labourForm.form.get("DisabilityType_Code").setValue(null);
    }
  }

  public inputHandler(e: any) {
    const { value, maxLength } = e.target;
    if (String(value).length >= maxLength) {
      e.preventDefault();
      return;
    }
  }

  private getNominationForList(): any[] {
    return ["EPF", "EPS", "EPI", "Gratuity", "ESIC"];
  }

  private buildFamilyDetailsFormGroup(): FormGroup {
    return this.fb.group({
      Name: [null, [Validators.required]],
      Profession: [null],
      FMI_DOB: [null, [Validators.required]],
      FamilyRelationMasterList_Code: [null, [Validators.required]],
      RelationMasterList_Code: [null],
      Gender: [null, [Validators.required]],
      Address1: [null, [Validators.required]],
      Address2: [null, [Validators.required]],
      Address3: [null, [Validators.required]],
      isSameAddress: [false],
      BloodGroup: [null],
      NationalityCountry_Code: [null],
      Remark: [null],
      Country_Code: [null],
      State_Code: [null],
      District_Code: [null],
      City_Code: [null],
      PostOffice_Code: [null],
      Zipcode: [null],
      MonthIncome: [null],
    });
  }
  private buildNomineeDetailsFormGroup(): FormGroup {
    return this.fb.group({
      NominationFor: [null],
      LabourFamily_Code: [null],
      // NominationFor: [null, [Validators.required]],
      // LabourFamily_Code: [null, [Validators.required]],
      // RelationshipMasterList_Code: [null],
      // NominationPer: [null],
      NominationPer: [null, [Validators.max(100)]],
      // MentalIllness: [false],
      // Minor: [false],
    });
  }

  private buildWorkExperienceDetailsFormGroup(): FormGroup {
    return this.fb.group({
      CompanyName: [null, [Validators.required]],
      Address: [null, [Validators.required]],
      FormYear: [null, [Validators.required]],
      ToYear: [null, [Validators.required]],
      LeavingReason: [null, [Validators.required]],
    });
  }

  private buildQualificationDetailsFormGroup(): FormGroup {
    return this.fb.group({
      EducationTypeMLT_Code: [null, [Validators.required]],
      SchoolCollege: [null, [Validators.required]],
      PasssingYear: [null, [Validators.required]],
      ClassGrade: [null, [Validators.required]],
      Medium: [null, [Validators.required]],
      Percentage: [null, [percentageValidator()]],
      TechnicalCourse: [null]

    });
  }

  private getEducationTypeList(): any[] {
    return [
      {
        Name: "5th",
        MasterItem_Code: "332",
      },
      {
        Name: "8th",
        MasterItem_Code: "333",
      },
      {
        Name: "10th",
        MasterItem_Code: "334",
      },
      {
        Name: "12th",
        MasterItem_Code: "335",
      },
      {
        Name: "Graduation",
        MasterItem_Code: "337",
      },
      {
        Name: "Others",
        MasterItem_Code: "341",
      },
    ];
  }

  private getLanguageList(): any[] {
    return [
      {
        Name: "Hindi",
        MasterItem_Code: 1,
      },
      {
        Name: "English",
        MasterItem_Code: 2,
      },
      {
        Name: "Other",
        MasterItem_Code: 3,
      },
    ];
  }
  getCasts(): void {
    this.entryfordayService
      .getMasterItemByCode(MasterItemCode.CASTS)
      .pipe(takeUntil(this.destroy))
      .subscribe((response: any[]) => {
        this.castList = response;
      });
  }
  selected_lStates: any;

  fetchData_fromZipcode(zipcode: any): void {
    if (zipcode.target.value.length == 6)
      this.labourService.getDataFromZipcode(zipcode.target.value)
        .subscribe((res: any) => {
          const resObj = res;
          if (res.length == 1) {
            this.selected_lCountries = resObj[0].Country_Code;

            this.getStatesByCountryId().subscribe((res: any) => {
              this.lStates = res;
              this.labourForm.controls["LState_Code"].setValue(
                resObj[0].Values[0].State_Code
              );

              this.getDistrictsByState().subscribe((res: any) => {
                this.lDistricts = res;
                this.labourForm.controls["LDistrict_Code"].setValue(
                  resObj[0].Values[0].Values[0].District_Code
                );

                this.getCitiesByDistrict().subscribe((res: any) => {
                  this.lCities = res;
                  this.labourForm.controls["LCity_Code"].setValue(
                    resObj[0].Values[0].Values[0].Values[0].City_Code
                  );

                  this.getPostOfficeByCity().subscribe((res: any) => {
                    this.lPos = res;
                    this.labourForm.controls["LPostOffice_Code"].setValue(
                      resObj[0].Values[0].Values[0].Values[0].Values[0].PostOffice_Code
                    );

                    this.getVillage().subscribe((res: any) => {
                      this.lVillages = res.Data.Table;
                      this.labourForm.controls["LVillage_Code"].setValue(
                        resObj[0].Values[0].Values[0].Values[0].Values[0].Values[0].Pincode_Code
                      );
                    })
                  });
                });
              });
            });
          }
        }, error => {
        });

  }

  fetchData_fromZipcodeForPAddress(zipcode: any): void {
    if (zipcode.target.value.length == 6)
      this.labourService.getDataFromZipcode(zipcode.target.value)
        .subscribe((res: any) => {
          const resObj = res;
          if (res.length == 1) {
            this.labourForm.controls['PCountry_Code'].setValue(resObj[0].Country_Code);

            this.getStatesByCountryIdP().subscribe((res: any) => {
              this.pStates = res;
              this.labourForm.controls['PState_Code'].setValue(resObj[0].Values[0].State_Code);

              this.getDistrictsByStateP().subscribe((res: any) => {
                this.pDistricts = res;
                this.labourForm.controls['PDistrict_Code'].setValue(resObj[0].Values[0].Values[0].District_Code);

                this.getCitiesByDistrictP().subscribe((res: any) => {
                  this.pCities = res;
                  this.labourForm.controls['PCity_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].City_Code);

                  this.getPostOfficeByCityP().subscribe((res: any) => {
                    this.pPos = res;
                    this.labourForm.controls['PPostOffice_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].Values[0].PostOffice_Code);

                    this.getVillageP().subscribe((res: any) => {
                      this.pVillages = res.Data.Table;
                      this.labourForm.controls['PVillage_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].Values[0].Values[0].Pincode_Code);
                    })
                  })
                });
              });
            });
          }
        }, error => { });
  }

  fetchData_fromZipcodeForContactDetail(zipcode: any) {
    if (zipcode.target.value.length == 6)
      this.labourService.getDataFromZipcode(zipcode.target.value)
        .subscribe((res: any) => {
          const resObj = res;
          if (res.length == 1) {
            this.labourForm.controls['Country_Code'].setValue(resObj[0].Country_Code)

            this.getStatesByCountryIdC().subscribe((res: any) => {
              this.contactStates = res;
              this.labourForm.controls['State_Code'].setValue(resObj[0].Values[0].State_Code);

              this.getDistrictsByStateC().subscribe((res: any) => {
                this.contactDistricts = res;
                this.labourForm.controls['District_Code'].setValue(resObj[0].Values[0].Values[0].District_Code);

                this.getCitiesByDistrictC().subscribe((res: any) => {
                  this.contactCities = res;
                  this.labourForm.controls['City_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].City_Code);

                  this.getPostOfficeByCityC().subscribe((res: any) => {
                    this.contactlPos = res;
                    this.labourForm.controls['PostOffice_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].Values[0].PostOffice_Code);
                  })
                });
              });
            });
          }
        }, error => { });
  }

  fetchData_fromZipcodeForFamilyDetail(zipcode: any) {
    if (zipcode.target.value.length == 6)
      this.labourService.getDataFromZipcode(zipcode.target.value)
        .subscribe((res: any) => {
          const resObj = res;
          if (res.length == 1) {
            this.familyDetailsFormGroup.controls['Country_Code'].setValue(resObj[0].Country_Code)

            this.getStatesByCountryIdF().subscribe((res: any) => {
              this.familyStates = res;
              this.familyDetailsFormGroup.controls['State_Code'].setValue(resObj[0].Values[0].State_Code);

              this.getDistrictsByStateF().subscribe((res: any) => {
                this.familyDistrict = res;
                this.familyDetailsFormGroup.controls['District_Code'].setValue(resObj[0].Values[0].Values[0].District_Code);

                this.getCitiesByDistrictF().subscribe((res: any) => {
                  this.familyCity = res;
                  this.familyDetailsFormGroup.controls['City_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].City_Code);

                  this.getPostOfficeByCityF().subscribe((res: any) => {
                    this.familylPos = res;
                    this.familyDetailsFormGroup.controls['PostOffice_Code'].setValue(resObj[0].Values[0].Values[0].Values[0].Values[0].PostOffice_Code);
                  })
                });
              });
            });
          }
        }, error => { });
  }

  changeNationality() {
    if (this.labourForm.controls['NationalityMasterList_Code'].value === 569) {
      this.checkList.forEach((item) => {
        if (item.id === 4) {
          item.childrens[0].title = "Passport";
          item.childrens[0].text = "Passport";
          item.childrens[0].desc = "Passport is required";
        }
      });
    } else {
      this.checkList.forEach((item) => {
        if (item.id === 4) {
          item.childrens[0].title = "Aadhar Card";
          item.childrens[0].text = "Aadhar Card";
          item.childrens[0].desc = "Aadhar Card is required";
        }
      });
    }
  }
}
