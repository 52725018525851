import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { GLOBAL } from "../app.globals";

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient) {}

  // public getAllDataSet(opts): Observable<any[]> {
    
  //   let lastUpdatedTimeParam = "";

  //   if (opts.endDate) {
  //     lastUpdatedTimeParam = `&lastUpdateTime=${opts.endDate}`;
  //   }

  //   const headers = {};
  //   if (opts.endDate) {
  //     headers[GLOBAL.IGNORE_LOADER] = "true";
  //   }
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET}?UserID=${opts.UserID}&StartDate=${opts.FDATE}&EndDate=${opts.TDATE}` + `&Flag=${opts.Flag}${lastUpdatedTimeParam}`;
  //   return this.http.get<any[]>(url, { headers }).pipe(map((response: any) => response.Data));
  // }

  // public getRealTimeHeadCount(opts): Observable<any[]> {
   
  //   let lastUpdatedTimeParam = "";

  //   if (opts.lastUpdateTime) {
  //     lastUpdatedTimeParam = `&lastUpdateTime=${opts.lastUpdateTime}`;
  //   }

  //   const headers = {};
  //   if (opts.lastUpdateTime) {
  //     headers[GLOBAL.IGNORE_LOADER] = "true";
  //   }
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_REALTIME_HEADCOUNT}`;
  //   return this.http.post<any[]>(url, opts, { headers });
  //   // .pipe(
  //   //   map((response: any) => response.Data));
  // }
 
  // public getAllInHeadCount(startDate, endDate, userId): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_INHEADCOUNT}`;
  //   return this.http.post(url, { startDate, endDate, userId }).pipe(map((response: any) => response));
  // }

  // public getAllOutHeadCount(startDate, endDate, userId): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_OutHEADCOUNT}`;
  //   return this.http
  //     .post(url, { startDate, endDate, userId })
  //     .pipe(map((response: any) => response));
  // }

  public getAllRequirementAndShortage(date, userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_REQUIREMENT_AND_SHORTAGE}`;
    return this.http
      .post(url, { date, userId })
      .pipe(map((response: any) => response));
  }
  // public getAllDataSetBudget(): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET1}`;
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => response.Data.Table));
  // }
  public getAllDataSetBudget(userId, startDate, endDate): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET1}`;
    return this.http
      .post(url, {
        UserId: GLOBAL.USER_ID,
        StartDate: startDate,
        EndDate: endDate,
      })
      .pipe(map((response: any) => response));
  }

  public getRealtimeHeadcountSummary(startDate, endDate): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_REALTIME_HEADCOUNT_SUMMARY}`;
    return (
      this.http
        .post(url, {
          UserId: GLOBAL.USER_ID,
          SDate: startDate.replaceAll("-", ""),
          EDate: endDate.replaceAll("-", ""),
          RequireType: 0,
          ActionType: 0,
        })
        // return this.http.post(url, {"SDate":"20220801", "EDate":"20220930", "RequireType":0, "ActionType":0,"UserId":142 }).
        .pipe(map((response: any) => response))
    );
  }

  public getContractorFilter(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_SP_Listing_Contractor_Filter_V1}`;
    return this.http
      .post(url, {
        search: null,
        sDate: null,
        eDate: null,
        selectedField:
          "Company,ContractorVendor_Code,EmailID,ShortCode,PANNo,IsContactor,Status",
        isMain: 1,
        contractor_Code: null,
        ExcludeContractor_Code: null,
        workOrderCode: null,
        userid: GLOBAL.USER_ID,
        Plant_Code: null,
      })
      .pipe(map((response: any) => response));
  }

  public getWorkOrderFilterData(): Observable<any> {
    // const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate='
    //   + '&eDate='
    //   + '&search='
    //   + '&selectedField='
    //   + '&filterType='
    //   + '&condition='
    //   + '&isActive='
    //   + '&workOrderType='
    //   + '&workOrderOwner_Code='
    //   + '&workOrderHOD_Code='
    //   + '&department_Code='
    //   + '&isContractorType='
    //   + '&Plant_Code='
    //   + '&NatureOfWork_Code='
    //   + '&NatureOfWork_Code='
    //   + '&Contractor_Code='
    //   + '&SubContractor_Code='
    //   + '&WorkOrder_Code='
    //   + '&SubWorkOrder_Code='
    //   + '&WorkSiteArea_Code='
    //   + '&IsHRCApplicable='
    //   + '&Status_Code='
      + '&UserId=' + GLOBAL.USER_ID;
    const model = {
      sDate: null,
      eDate: null,
      selectedField: null,
      filterType: null,
      condition: null,
      isActive: null,
      workOrderType: null,
      workOrderOwner_Code: null,
      workOrderHOD_Code: null,
      department_Code: null,
      isContractorType: null,
      Plant_Code: null,
      NatureOfWork_Code: null,
      Contractor_Code: null,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      WorkSiteArea_Code: null,
      IsHRCApplicable: null,
      Status_Code: null,
      UserId: GLOBAL.USER_ID,
    };
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => response.Data.Table));
    return this.http
      .post<any[]>(
        GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER,
        model
      )
      .pipe(map((response: any) => response));
  }

  public getNonComplianceLabourList(startDate, endDate) {
    const model = {
      StartDATE: startDate,
      EndDATE: endDate,
      UserID: GLOBAL.USER_ID,
    };

    return this.http
      .post<any[]>(
        GLOBAL.APIS.MASTER_DATA.GET_DAS_NON_COMPLIANCE_LABOUR_LIST,
        model
      )
      .pipe(map((response: any) => response));
  }

  public getTotalActiveGatePass() {
    const model = {
      UserID: GLOBAL.USER_ID,
    };

    return this.http
      .post<any>(
        GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_TOTAL_ACTIVE_GATEPASS,
        model
      )
      .pipe(map((response: any) => response[0].TotalActive));
  }

  public getWeeklyShiftData(startDate, endDate) {
    const model = {
      StartDATE: startDate,
    };

    return this.http
      .post<any[]>(
        GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_WEEKLY_SHIFT_DATA,
        model
      )
      .pipe(map((response: any) => response));
  }

  public getIndiaJson() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ASSETS.GET_INDIA_JSON}`
    );
  }
  // tslint:disable-next-line: function-name
  public GetEXPolicyDataForDashboard(userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_ECpolicyPolicy_For_Dashboard}?UserID=${userId}`;
    return this.http
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  // tslint:disable-next-line: function-name
  public GetLicenceDataForDashboard(userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_Licence_For_Dashboard}?UserID=${userId}`;
    return this.http
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }
  // tslint:disable-next-line: function-name
  public GetWorkOrderDataForDashboard(userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_WorkOrder_For_Dashboard}?UserID=${userId}`;
    return this.http
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  // tslint:disable-next-line: function-name
  public GetDailyRequirementForDashboard(userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DailyRequirement_For_Dashboard}?UserID=${userId}`;
    return this.http
      .get<any[]>(url)
      .pipe(map((response: any) => response.Data.Table));
  }

  public GetContractorDashboardNotif(userId): Observable<any[]> {
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_Contractor_Notification_Dashboard}?UserID=${userId}`;
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => response.Data.Table));
    // GLOBAL.USER_ID
    const model = {
      Filter: {
        UserId: parseInt(userId),
      },
    };
    return this.http
      .post<any[]>(
        GLOBAL.APIS.MASTER_DATA.GET_Contractor_Notification_Dashboard,
        model
      )
      .pipe(map((response: any) => response));
  }
}
