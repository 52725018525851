import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralPrintPopupComponent } from './general-print-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreModule } from 'src/app/core/core.module';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [
    GeneralPrintPopupComponent
  ],
  imports: [
   CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxPrintModule,
  ],
  entryComponents: [
    GeneralPrintPopupComponent,
  ]
})
export class GeneralPrintPopupModule { }
