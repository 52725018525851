import { Component, OnInit, Output, EventEmitter, ViewChild, HostBinding, TemplateRef, OnDestroy } from '@angular/core';
import {
  YearlyBudgetModel, monthList, siteList, glList, costCenterList,
  yearTypeList, sectionList, ManpowerRequestStatus, RoleCode,
} from './man-power.modal';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SharedService } from '../core/services/shared.service';
import { ManPowerService } from './man-power.service';
import { FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { GLOBAL } from '../app.globals';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-man-power',
  templateUrl: './man-power.component.html',
  styleUrls: ['./man-power.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class ManPowerComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'd-flex flex-column h-100 overflow-hidden';
  @ViewChild('manPowerForm') public manPowerForm: NgForm;
  @ViewChild('actionTemplateRef') actionTemplateRef: TemplateRef<any>;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  public actionFormGroup: FormGroup;
  public isEdit = false;
  public dateRangeLabelText = '';
  public canUserApprovedManPowerRequest: boolean;
  public canUserEdit: boolean;
  @Output() closeModal: EventEmitter<string>;
  @Output() afterSave: EventEmitter<void>;
  public set manPower(value: any) {
    if (value) {
      this.isEdit = true;
      this.manPowerObj = value;
      this.dateRangeLabelText = `${moment(value.StartDate).format('DD-MM-YYYY')} to ${moment(value.EndDate).format('DD-MM-YYYY')}`;
      console.log('set', this.manPower);
      this.bindControlForEdit();
      this.canUserApprovedManPowerRequest = this.manPowerService.canUserApprovedManPowerRequest(value);
      this.canUserEdit = this.manPowerService.canUserEdit(value);
    }
  }

  public get manPower(): any {
    return this.manPowerObj;
  }

  public dateRange: any[];
  weeklyOffDateList: any[] = [];
  holidayDateList: any[] = [];
  siteList = siteList;
  glList = glList;
  manPowerTypeList: any[] = [];
  workmanCategoryList: any[] = [];
  companyList: any[] = [];
  stateList: any[] = [];
  plantList: any = [];
  unitList: any[] = [];
  departmentList: any[] = [];
  sectionList: any[] = sectionList;
  sectionTreeList: any[] = [];
  objMerg: any[] = [];
  costCenterList = costCenterList;
  selectedPlant: 0;
  yearTypeList = yearTypeList;
  unitDepartmentSectionTreeData: any[] = [];
  yearList = [
    '2019-2020',
    '2018-2019',
    '2017-2018',
  ];
  isOpenSectionEntry = false;
  isApprove = true;
  monthList = monthList;
  monthObjectList = [];
  yearlyBudgetObject: YearlyBudgetModel = new YearlyBudgetModel();
  todayDate = new Date();
  showUnitWiseBlock: boolean;
  private selectedSectionList: any[] = [];
  private selectedworkmenCategoryList: any[] = [];
  private sectionTreeResponses = [];
  private manPowerObj = {};
  private modalRef: any;
  // public minDate = new Date(new Date().setDate(new Date().getDate() - 1));

  public minDate: any;
  public maxDate: any;
  public validDateValue: any;
  public globalSettings: any[];

  constructor(public sharedService: SharedService,
    private manPowerService: ManPowerService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) {
    this.closeModal = new EventEmitter();
    this.afterSave = new EventEmitter();
  }

  ngOnInit() {
    // this.canUserApprovedManPowerRequest = this.manPowerService.canUserApprovedManPowerRequest();
    if (!this.isEdit) {
      this.actionFormGroup = this.fb.group({
        Comments: [''],
        Status: [ManpowerRequestStatus.APPROVED]
      });
      this.getAllData();
      setTimeout(() => {
        this.bindControlForEdit();
      }, 100);
    }
    this.sharedService.getGlobalSettings().subscribe((settings: any) => {
      this.globalSettings = settings.Data.Table;
    })

  }

  ngOnDestroy(): void {
    this.closeModalOnDestroy();
  }

  public dateRangeChange(data: any): void {
    const dateRange = this.manPowerForm.form.get('dateRange').value;
    if (this.validDateValue >= 0) {
      const maxDate = moment().add(this.validDateValue, 'days');
      // const dateDiff = moment()
      if (maxDate.toDate().toDateString() === moment().toDate().toDateString()) {

      } else if (maxDate.toDate().toDateString() === dateRange[1].toDate().toDateString()) {

      } else if (maxDate.toDate().toDateString() === moment().toDate().toDateString()) {

      } else if (maxDate > dateRange[1]) {
        this.manPowerForm.form.get('dateRange').setValue(null);
        this.showAlert('error', `You can submit Requisition for after next ${this.validDateValue} days!!`, 'Error');
        return;
      }
    } else {
      const minDate = moment().add(this.validDateValue, 'days');
      if (minDate.toDate().toDateString() === moment().toDate().toDateString()) {

      } else if (minDate.toDate().toDateString() === dateRange[0].toDate().toDateString()) {

      } else if (dateRange[0] < minDate) {
        this.manPowerForm.form.get('dateRange').setValue(null);
        this.showAlert('error', `You can submit Requisition for before ${this.validDateValue} days!!`, 'Error');
        return;
      }

      this.onWeekOffChecked();
      this.onHolidayChecked();
    }

    // const aDate = new Date();
    // aDate.setDate(aDate.getDate() + 2);
    // // aDate.setDate(aDate.getDate() - 100);
    // const daterange0 = dateRange[0] <= aDate;
    // const dateRange1 = dateRange[1];
    // const nextDayallow = 1;
    // if (daterange0 && nextDayallow === 1) {
    //   console.log('dateRange', dateRange);
    //   this.manPowerForm.form.get('dateRange').setValue(null);
    //   this.showAlert('error', 'You can submit Requisition for after next two days!!', 'Error');
    // }
    // if (dateRange) {
    //   console.log('dateRange', dateRange);
    //   const days = dateRange[1].diff(dateRange[0], 'days');
    //   if (days > 31) {
    //     this.manPowerForm.form.get('dateRange').setValue(null);
    //     this.showAlert('error', 'You can select maximum one month.', 'Error');
    //   }
    //   this.onWeekOffChecked();
    //   this.onHolidayChecked();
    // }
  }
  // public dateRangeChange(data: any): void {
  //   const dateRange = this.manPowerForm.form.get('dateRange').value;
  //   const aDate = new Date();
  //   aDate.setDate(aDate.getDate() + 2);
  //   // aDate.setDate(aDate.getDate() - 100);
  //   const daterange0 = dateRange[0] <= aDate;
  //   const dateRange1 = dateRange[1];
  //   const nextDayallow = 1;

  //   if (daterange0 && nextDayallow === 1) {
  //     console.log('dateRange', dateRange);
  //     this.manPowerForm.form.get('dateRange').setValue(null);
  //     this.showAlert('error', 'You can submit Requisition for after next two days!!', 'Error');
  //   }
  //   if (dateRange) {
  //     console.log('dateRange', dateRange);
  //     const days = dateRange[1].diff(dateRange[0], 'days');
  //     if (days > 31) {
  //       this.manPowerForm.form.get('dateRange').setValue(null);
  //       this.showAlert('error', 'You can select maximum one month.', 'Error');
  //     }
  //     this.onWeekOffChecked();
  //     this.onHolidayChecked();
  //   }
  // }

  createShiftSectionList(shifts: any[]): void {
    const sections = [];
    const selectedWorkmanCategoryList: any[] = [];
    const workmanCategory = this.manPower.CategoryWorkmenMIL_Code;
    if (workmanCategory) {
      workmanCategory.forEach((workmanCategoryCode: any) => {
        const categoryItem = this.workmanCategoryList.find((item: any) => item.MasterItem_Code === workmanCategoryCode);
        if (categoryItem) {
          selectedWorkmanCategoryList.push(
            {
              id: workmanCategoryCode,
              name: categoryItem.Name
            }
          );
        }
      });
    }
    this.selectedSectionList.forEach((section: any) => {
      const sectionItem = {
        Id: section.id,
        Name: section.itemName,
        workmanCategoryList: [],
      };
      selectedWorkmanCategoryList.forEach((workmanCategory: any) => {
        sectionItem.workmanCategoryList.push(
          {
            CategoryWorkmenMIL_Code: workmanCategory.id,
            workmanCategoryName: workmanCategory.name,
            shiftList: _.cloneDeep(shifts.filter(s => s.WorkmenCategoryMIT_Code === workmanCategory.id)),
            Date: [],
          },
        );
      });
      sections.push(sectionItem);
    });
    console.log('sections sections', sections);
    this.setSectionData(sections);
  }

  dateTimeChange() {
    // this.enumerateDaysBetweenDates(moment)
    const dateRanges = this.manPowerForm.form.get('dateRange').value;
    const startData = _.cloneDeep(new Date(dateRanges[0]));
    const endDate = _.cloneDeep(new Date(dateRanges[1]));
    const dateRange = this.enumerateDaysBetweenDates(startData, endDate);
    for (let i = 0; i <= this.sectionList.length - 1; i++) {
      this.sectionList[i].Date = _.cloneDeep(dateRange);
      for (let y = 0; y <= this.sectionList[i].shiftList.length - 1; y++) {
        this.sectionList[i][this.sectionList[i].shiftList[y]] = [];
        for (let z = 0; z <= dateRange.length - 1; z++) {
          this.sectionList[i][this.sectionList[i].shiftList[y]].push(_.cloneDeep(10));
        }
      }
    }

  }

  setSectionData(sections) {
    // this.enumerateDaysBetweenDates(moment)
    const dateRanges = this.manPower.dateRange;
    const startData = _.cloneDeep(dateRanges[0]);
    const endDate = _.cloneDeep(dateRanges[1]);
    const dateRange = this.enumerateDaysBetweenDates(startData, endDate);
    console.log('dateRange', dateRange);
    for (const section of sections) {
      for (const workmanCategory of section.workmanCategoryList) {
        workmanCategory.Date = _.cloneDeep(dateRange);
        for (const shift of workmanCategory.shiftList) {
          shift.data = [];
          for (let z = 0; z <= dateRange.length - 1; z++) {
            const date = dateRange[z].displayDate;
            const existShift = this.manPower.ManpowerShiftRelations && this.manPower.ManpowerShiftRelations
              .find(item => (moment(item.Date.date).format('DD-MM-YYYY') === date)
                && item.Shift_Code === shift.Shift_Code && item.CategoryWorkmenMIL_Code === workmanCategory.CategoryWorkmenMIL_Code);
            if (existShift) {
              const data = {
                value: existShift.Total
              };
              shift.data.push(_.cloneDeep(data));
            } else {
              const data = {
                value: 0
              };
              shift.data.push(_.cloneDeep(data));
            }
          }
        }
      }
    }

    console.log('sections', sections);
    this.sectionList = sections;
  }

  enumerateDaysBetweenDates = function (startDate, endDate) {
    const dates = [];
    const currDate = moment(startDate).startOf('day');
    // tslint:disable-next-line: indent
    const now = moment(startDate);
    while (now.isSameOrBefore(endDate)) {
      const currentDate = now.format('DD-MM-YYYY');
      const isHolidayDate = this.holidayDateList.length > 0 ?
        this.holidayDateList.find(item => moment(item.Date).format('DD-MM-YYYY') === currentDate) : false;
      const isWeekOffDate = this.weeklyOffDateList.length > 0 ?
        this.weeklyOffDateList.find(item => moment(item).format('DD-MM-YYYY') === currentDate) : false;
      if (!isHolidayDate && !isWeekOffDate) {
        const dateObj = {
          displayDate: currentDate,
          date: moment(currDate),
          formattedDate: now.format('YYYY-MM-DD')
        };
        dates.push(dateObj);
      }
      now.add(1, 'days');
    }
    return dates;
  };

  public replaceShiftValues(value, shift, sectionIndex, workmanCategoryIndex, shiftIndex, valueIndex) {
    for (let i = valueIndex; i <= this.sectionList[sectionIndex].workmanCategoryList[workmanCategoryIndex]
      .shiftList[shiftIndex].data.length - 1; i++) {
      console.log(this.sectionList[sectionIndex].workmanCategoryList[workmanCategoryIndex].shiftList[shiftIndex].data);
      this.sectionList[sectionIndex].workmanCategoryList[workmanCategoryIndex].shiftList[shiftIndex].data[i].value = value;
    }
  }

  public removeShift(shiftList: any[], shiftIndex: number): void {
    // tslint:disable-next-line:no-parameter-reassignment
    shiftList = shiftList.splice(shiftIndex, 1);
    // shiftList = shiftList.filter((item: any) => item.Shift_Code !== shiftIndex);
  }

  public onCompanyChange(): void {
    this.stateList = [];
    this.plantList = [];
    this.unitList = [];
    this.departmentList = [];
    this.sectionTreeList = [];

    // // this.manPowerForm.controls['State_Code'].setValue(null);
    // this.manPowerForm.controls['Plant_Code'].setValue(null);
    // this.manPowerForm.controls['Unit_Code'].setValue(null);
    // this.manPowerForm.controls['Department_Code'].setValue(null);
    // this.manPowerForm.controls['SectionCodes'].setValue(null);
    // this.manPower.State_Code = null;
    // // this.manPower.Plant_Code = null;
    // this.manPower.Unit_Code = null;
    // this.manPower.Department_Code = null;
    // this.manPower.SectionCodes = null;

    const companyCode = this.manPowerForm.controls['Company_Code'].value;
    // if (companyCode) {
    // 	this.getStates();
    // }
  }

  public onStateChange(): void {
    this.plantList = [];
    this.unitList = [];
    this.departmentList = [];
    this.sectionTreeList = [];

    // this.manPowerForm.controls['Plant_Code'].setValue(null);
    // this.manPowerForm.controls['Unit_Code'].setValue(null);
    // this.manPowerForm.controls['Department_Code'].setValue(null);
    // this.manPowerForm.controls['SectionCodes'].setValue(null);

    // this.manPower.State_Code = null;
    // //   this.manPower.Plant_Code = null;
    // this.manPower.Unit_Code = null;
    // this.manPower.Department_Code = null;
    // this.manPower.SectionCodes = null;

    // const companyCode = this.manPowerForm.controls['Company_Code'].value;
    // const stateCode = this.manPowerForm.controls['State_Code'].value;
    // if (companyCode && stateCode) {
    // this.getPlants();
    // }
  }

  public onPlantChange(): void {
    this.unitList = [];
    this.departmentList = [];
    this.sectionTreeList = [];

    this.manPowerForm.controls['Unit_Code'].setValue(null);
    this.manPowerForm.controls['Department_Code'].setValue(null);
    this.manPowerForm.controls['SectionCodes'].setValue(null);
    this.manPowerForm.controls['dateRange'].setValue(null);

    this.manPower.Unit_Code = null;
    this.manPower.Department_Code = null;
    this.manPower.SectionCodes = null;

    const plantCode = this.manPowerForm.controls['Plant_Code'].value;
    this.manPower.Plant_Code = plantCode;
    if (plantCode) {
      // this.getUnits(plantCode);
      // this.getPlantTreeStructure(plantCode);
      this.getUnits();
      this.restrictDateRange(plantCode);
    }
  }

  private restrictDateRange(plantCode: any): void {
    const settings = this.globalSettings.find(item => item.Plant_Code === plantCode && item.Code === 'MOD152_PG200_100_1');
    if (settings) {
      this.validDateValue = settings.Value;
      if (+settings.Value !== 0) {
        const newDate = new Date(moment().add(+settings.Value, 'days').format('YYYY-MM-DD'));
        // this.minDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate());
        this.minDate = new Date(newDate);
      } else {
        const todayDate = new Date();
        this.minDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, todayDate.getDate());
      }
    }
  }

  getPlantTreeStructure() {
    this.unitDepartmentSectionTreeData = [];
    const PLANT_UNIT_DEP_REQUEST =
    // tslint:disable-next-line:ter-indent
    {
      // tslint:disable-next-line:ter-indent
      Filter: {
        UserId: GLOBAL.USER_ID
      },
      // tslint:disable-next-line:ter-indent
      Fields: [
        {
          KeyName: 'Plantcode0',
          DisplayName: 'Plant0'
        },
        {
          KeyName: 'Unitcode0',
          DisplayName: 'Unit0'
        },
        {
          KeyName: 'UnitDeptID0',
          DisplayName: 'Department0',
          ExtraFields: [
            'Departmentcode0'
          ]
        },
        {
          KeyName: 'LevelID0',
          DisplayName: 'SectionName0',
          ExtraFields: [
            'Sectioncode0'
          ]
        },
        {
          KeyName: 'LevelID1',
          DisplayName: 'SectionName1',
          ExtraFields: [
            'Sectioncode1'
          ]
        },
        {
          KeyName: 'LevelID2',
          DisplayName: 'SectionName2',
          ExtraFields: [
            'Sectioncode2',
            'Short_Code'
          ]
        }
      ]
      // tslint:disable-next-line:ter-indent
    };
    console.log('API eMerge2', PLANT_UNIT_DEP_REQUEST);
    this.manPowerService.getDepartmentPlantUnitSectionTree(null, null, null, PLANT_UNIT_DEP_REQUEST).subscribe((response: any[]) => {
      this.unitDepartmentSectionTreeData = response;
      // this.getUnits();
      this.getPlants();
    });
  }

  public onUnitChange(): void {
    this.departmentList = [];
    this.sectionTreeList = [];

    this.manPowerForm.controls['Department_Code'].setValue(null);
    this.manPowerForm.controls['SectionCodes'].setValue(null);

    this.manPower.Department_Code = null;
    this.manPower.SectionCodes = null;

    const plantCode = this.manPowerForm.controls['Plant_Code'].value;
    const unitCode = this.manPowerForm.controls['Unit_Code'].value;
    if (plantCode && unitCode) {
      this.getDepartments(plantCode, unitCode);
    }
  }

  public onDepartmentChange(): void {
    this.sectionTreeList = [];
    this.manPowerForm.controls['SectionCodes'].setValue(null);
    this.manPower.Department_Code = null;
    this.manPower.SectionCodes = null;

    const companyCode = this.manPowerForm.form.get('Company_Code').value;
    // const stateCode = this.manPowerForm.form.get('State_Code').value;
    const plantCode = this.manPowerForm.controls['Plant_Code'].value;
    const unitCode = this.manPowerForm.controls['Unit_Code'].value;
    const departmentCode = this.manPowerForm.controls['Department_Code'].value;
    if (plantCode && unitCode && departmentCode) {
      // this.getManpowerBudget(companyCode, null, plantCode, departmentCode);
      this.getSectionList(plantCode, unitCode, departmentCode);
    }
  }

  public showSectionEntry() {

    if (this.isEdit) {
      console.log('this.manPower', this.manPower);
      const sectionCodes = this.manPower.SectionCodes.map(item => item.id);
      // const workmenCatCodes = this.manPower.CategoryWorkmenMIL_Code.map(item => item.id);
      const workmenCatCodes = this.manPower.CategoryWorkmenMIL_Code;
      // const workmenCatCodes = this.manPowerForm.controls['CategoryWorkmenMIL_Code'].value;
      this.manPower.dateRange = [this.manPower.StartDate, this.manPower.EndDate];
      this.selectedSectionList = this.manPower.SectionCodes;
      this.selectedworkmenCategoryList = this.manPower.CategoryWorkmenMIL_Code;
      if(this.manPower.lastLevel == "3") {
        this.canUserApprovedManPowerRequest = false;
      }
      this.getAllSectionShiftList(this.manPower.Company_Code, this.manPower.State_Code, this.manPower.Plant_Code,
        this.manPower.Unit_Code, this.manPower.Department_Code, sectionCodes, workmenCatCodes);
    } else {
      if (this.manPowerForm.form.valid) {
        const companyCode = this.manPowerForm.form.get('Company_Code').value;
        // const stateCode = this.manPowerForm.form.get('State_Code').value;
        const plantCode = this.manPowerForm.controls['Plant_Code'].value;
        const unitCode = this.manPowerForm.controls['Unit_Code'].value;
        const departmentCode = this.manPowerForm.controls['Department_Code'].value;
        const sectionCodes = this.manPowerForm.controls['SectionCodes'].value.map(item => item.id);
        // const workmenCatCodes = this.manPowerForm.controls['CategoryWorkmenMIL_Code'].value.map(item => item.id);
        const workmenCatCodes = this.manPowerForm.controls['CategoryWorkmenMIL_Code'].value;

        this.manPowerObj = this.manPowerForm.form.getRawValue();

        this.getAllSectionShiftList(companyCode, null, plantCode, unitCode, departmentCode, sectionCodes, workmenCatCodes);
      } else {
        this.showAlert('error', 'Please select required field(s).', 'Error');
      }

    }
  }

  public onBack(): void {
    this.isOpenSectionEntry = false;
    if (!this.isEdit) {
      this.bindControlValue();
    }
  }

  public approveAndSavePowerRequisition(): void {
    const manPowerObj = { ... this.manPower };
    console.log('form value', manPowerObj);
    console.log('sectionList', this.sectionList);
    if (!manPowerObj.ManpowerRequisiton_Code) {
      // manPowerObj.ManpowerRequisiton_Code = 0;
      // manPowerObj.StartDate = moment(manPowerObj.dateRange[0]).add(1, 'days');
      manPowerObj.StartDate = moment(manPowerObj.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      // manPowerObj.EndDate = moment(manPowerObj.dateRange[1]).add(1, 'days');
      manPowerObj.EndDate = moment(manPowerObj.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
      manPowerObj.Status = 'Submitted';
    }
    manPowerObj.dateRange = null;
    manPowerObj.ManpowerShiftRelations = [];
    manPowerObj.ManpowerShiftRelations = this.getSectionFromSectionList(this.sectionList);
    console.log('manPowerObj.ManpowerShiftRelations', manPowerObj.ManpowerShiftRelations);
    manPowerObj.SectionCodes = manPowerObj.SectionCodes.map(item => item.id);
    manPowerObj.IsAddRequisiton = 1;
    manPowerObj.UserId = localStorage.getItem('UserID');
    manPowerObj.RoleCode = GLOBAL.USER.ROLE_CODE;
    console.log('manPowerObj', manPowerObj);

    if (manPowerObj.ManpowerShiftRelations.filter(item => item.Total === '').length > 0) {
      alert('Please insert value');
      return;
    }

    this.manPowerService.approveAndSavePowerRequisition(manPowerObj).subscribe((response: any) => {
      if (response) {
        this.afterSave.next();
      }
    });
  }

  public onWeekOffChecked(): void {
    this.weeklyOffDateList = [];
    const weekOff = this.manPowerForm.form.get('WeekOffExclude').value;
    const dateRanges = this.manPowerForm.form.get('dateRange').value;
    const plantCode = this.manPowerForm.form.get('Plant_Code').value;
    if (weekOff && dateRanges && plantCode) {
      this.getWeekOffDateList(dateRanges, plantCode);
    }
  }

  public onHolidayChecked(): void {
    this.holidayDateList = [];
    const weekOff = this.manPowerForm.form.get('HolidayExclude').value;
    const dateRanges = this.manPowerForm.form.get('dateRange').value;
    const plantCode = this.manPowerForm.controls['Plant_Code'].value;
    const companyCode = this.manPowerForm.controls['Company_Code'].value;
    if (weekOff && companyCode && plantCode) {
      this.getHolidayDateList(dateRanges, companyCode, plantCode);
    }
  }

  public onCloseAction() {
    this.closeModal.next('click');
  }

  public onSelectSection(data): void {
    console.log('data', data);
    this.selectedSectionList = data;
  }

  public openActionModal(): void {
    this.modalRef = this.modalService.open(this.actionTemplateRef,
      { size: 'sm' }
    );
    this.actionFormGroup = this.fb.group({
      Comments: [''],
      Status: [ManpowerRequestStatus.APPROVED]
    });
  }

  public onSaveAp(): void {
    const formValue = this.actionFormGroup.getRawValue();
    this.manPower.Status = 'Approved';
    this.manPower.Comments = formValue.Comments;

    this.approveAndSavePowerRequisition();
  }
  public onSaveRej(): void {
    const formValue = this.actionFormGroup.getRawValue();
    this.manPower.Status = 'Rejected'; // kh 1809
    this.manPower.Comments = formValue.Comments;

    this.approveAndSavePowerRequisition();
    this.isApprove = false;
  }
  public onSaveHold(): void {
    const formValue = this.actionFormGroup.getRawValue();
    this.manPower.Status = 'On Hold';
    this.manPower.Comments = formValue.Comments;

    this.approveAndSavePowerRequisition();
  }
  // public onSavePending(): void {
  //     const formValue = this.actionFormGroup.getRawValue();
  //     this.manPower.Status = 'Pending';
  //     this.manPower.Comments = formValue.Comments;

  //     this.approveAndSavePowerRequisition();
  //   }

  private getSectionFromSectionList(sectionList: any[]): any[] {

    const manpowerShiftRelations = [];
    console.log('final sectionList', sectionList);
    for (const section of sectionList) {
      section.workmanCategoryList.forEach((workmanCategory: any) => {
        workmanCategory.shiftList.forEach((shift: any) => {
          shift.data.forEach((totalData: any, dataIndex: number) => {

            const date = workmanCategory.Date[dataIndex].displayDate;
            const shiftObject = {
              Shift_Code: shift.Shift_Code,
              CategoryWorkmenMIL_Code: workmanCategory.CategoryWorkmenMIL_Code,
              Date: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              Total: totalData.value,
              Section_Code: section.Id
            };
            manpowerShiftRelations.push(shiftObject);
          });
        });
      });
    }
    return manpowerShiftRelations;
  }

  bindSectionEdit(response: any[], isEdit: boolean, formData) {
    console.log(response);
    // let resp = response;
    const resp = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === formData.Plant_Code).Values
      .find(itm => itm.Unitcode0 === formData.Unit_Code).Values
      .find(itm => itm.UnitDeptID0 === formData.Department_Code).Values;
    // .map((e) => { return { Department_Code: e.UnitDeptID0, Name: e.Department0 }; });
    const convertedData = [];
    resp.forEach((val) => {
      // val.Values.forEach((plant) => {
      //   plant.Values.forEach((unit) => {
      //     unit.Values.forEach((department) => {
      //       department.Values.forEach((section1) => {
      convertedData.push(val);

      // section1.SectionList.forEach((sectionList2) => {
      //   sectionList2.SectionList.forEach((sectionList3) => {

      //   });
      // });
    });
    //     });
    //   });
    // });
    // });
    console.log('convertedData', convertedData);
    this.sectionTreeList = this.bindSection1Tree(convertedData, isEdit);
    console.log('sectionTreeList after', this.sectionTreeList);
  }

  bindCompanyTree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.PlantTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
            currentname: 'company', isActive: false, selected: false,
            children: this.bindPlantTree(subDataFilter, val.Company_Code, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
            currentname: 'company', isActive: false, selected: false, checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindPlantTree(filteredData, companyCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val: any, key) => {
      const subDataFilter = val.UnitTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            companyCode,
            id: val.Company_Code,
            plantCode: val.Plant_Code,
            name: val.Name,
            currentname: 'plant',
            isActive: false,
            selected: false,
            children: this.bindUnitTree(subDataFilter, companyCode, val.Plant_Code, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            companyCode,
            id: val.Company_Code,
            plantCode: val.Plant_Code,
            name: val.Name,
            currentname: 'plant',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindUnitTree(filteredData, companyCode, plantCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.DepartmentTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            companyCode,
            plantCode,
            id: val.Unit_Code,
            unitCode: val.Unit_Code,
            name: val.Name,
            currentname: 'unit',
            isActive: false,
            selected: false,
            children: this.bindDepartmentTree(subDataFilter, companyCode, plantCode, val.Unit_Code, isEdit)
          });
      } else {
        convertedData.push(
          {
            companyCode,
            plantCode,
            id: val.Unit_Code,
            unitCode: val.Unit_Code,
            name: val.Name,
            currentname: 'unit',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindDepartmentTree(filteredData, companyCode, plantCode, unitCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionTree;
      if (subDataFilter.length > 0) {
        const childrens = [] = this.bindSection1Tree(subDataFilter, isEdit);
        convertedData.push(
          {
            companyCode,
            plantCode,
            unitCode,
            id: val.Department_Code,
            sectionCode: val.Department_Code,
            name: val.Name,
            currentname: 'department',
            isActive: false,
            selected: false,
            children: childrens,
            // isCheck: filteredData.length === 1 ? true : false
          }
        );
      } else {
        convertedData.push(
          {
            companyCode,
            plantCode,
            unitCode,
            id: val.Department_Code,
            sectionCode: val.Department_Code,
            name: val.Name,
            currentname: 'department',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindSection1Tree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.Values;
      if (subDataFilter.length > 0) {
        const childrens = [] = this.bindSection2Tree(subDataFilter, val.Sectioncode0, isEdit, filteredData.length);
        convertedData.push(
          {
            id: val.Sectioncode0,
            sectionCode: val.Sectioncode0,
            itemName: val.SectionName0,
            currentname: 'section1',
            isActive: false,
            selected: false,
            children: childrens,
            isCheck: (filteredData.length === 1 && childrens.length === 0) ? true : false,
          }
        );
      } else {
        let isCheck = false;

        const sectionCodes = this.manPowerForm.controls['SectionCodes'].value;
        if (sectionCodes.length > 0) {
          if (sectionCodes.find(item => item.id === val.Sectioncode0)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        } else if (filteredData.length === 1) {
          isCheck = true;
        }
        convertedData.push(
          {
            id: val.Sectioncode0,
            sectionCode: val.Sectioncode0,
            itemName: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  bindSection2Tree(filteredData, section1Code, isEdit: boolean, firstLevelLength) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.Values;
      if (subDataFilter.length > 0) {
        const childrens = [] = this.bindSectionTree(subDataFilter, section1Code, val.Sectioncode1,
          isEdit, firstLevelLength, filteredData.length);
        convertedData.push(
          {
            section1Code,
            id: val.Sectioncode1,
            sectionCode: val.Sectioncode1,
            itemName: val.SectionName1,
            currentname: 'section2',
            isActive: false,
            selected: false,
            children: childrens,
            isCheck: firstLevelLength === 1 && filteredData.length === 1 && childrens.length === 0 ? true : false,
          }
        );
      } else {
        let isCheck = false;

        const sectionCodes = this.manPowerForm.controls['SectionCodes'].value;
        if (sectionCodes.length > 0) {
          if (sectionCodes.find(item => item.id === val.Sectioncode1)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        } else if (firstLevelLength === 1 && filteredData.length === 1) {
          isCheck = true;
        }
        convertedData.push(
          {
            section1Code,
            id: val.Sectioncode1,
            sectionCode: val.Sectioncode1,
            itemName: val.SectionName1,
            currentname: 'section2',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  bindSectionTree(filteredData, section1Code, section2Code, isEdit: boolean, firstLevelLength, secondeLevelLength) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      let isCheck = false;
      const sectionCodes = this.manPowerForm.controls['SectionCodes'].value;
      if (sectionCodes && sectionCodes.length > 0) {
        if (sectionCodes.find(item => item.id === val.LevelID2)) {
          isCheck = true;
          console.log('isCheck', isCheck);
        }
      } else if (firstLevelLength === 1 && secondeLevelLength === 1 && filteredData.length === 1) {
        isCheck = true;
      }
      convertedData.push(
        {
          section1Code,
          section2Code,
          id: val.LevelID2,
          sectionCode: val.LevelID2,
          itemName: val.SectionName2,
          currentname: 'section3',
          isActive: false,
          selected: false,
          checked: isCheck
        }
      );
    });
    return convertedData;
  }

  private getAllData(): void {
    this.getManpowerType();
    this.getWorkManCategoryList();
    this.getCompany();
    this.getPlantTreeStructure();
  }

  private getManpowerType(): void {
    this.manPowerService.getMasterItemByCode(GLOBAL.MASTER_ITEM_CODE.MANPOWER_TYPE).subscribe((response: any[]) => {
      this.manPowerTypeList = response;
      this.manPowerForm.controls['ManPowerType'].setValue(this.manPowerTypeList[0].MasterItem_Code);
      // this.manPowerForm.form.get('ManPowerType').setValue(this.manPowerTypeList[0].ManPowerType);
      // this.manPowerForm.form.get('ManPowerType').setValue(this.manPower.ManPowerType);
    });
  }

  private getWorkManCategoryList(): void {
    this.manPowerService.getMasterItemByCode(GLOBAL.MASTER_ITEM_CODE.WORKMAN_CATEGORY).subscribe((response: any[]) => {
      this.workmanCategoryList = response;
      if (this.manPowerForm && this.workmanCategoryList) {
        if (this.manPower.CategoryWorkmenMIL_Code) {
          this.manPowerForm.form.get('CategoryWorkmenMIL_Code').setValue(this.manPower.CategoryWorkmenMIL_Code);
        } else if (this.workmanCategoryList.length === 1) {
          this.manPowerForm.form.get('CategoryWorkmenMIL_Code').setValue(this.workmanCategoryList[0].MasterItem_Code);
        }
      }
    });
  }

  private getManpowerBudget(companyCode, stateCode, plantCode, departmentCode): void {
    const monthName = this.dateConvertor(new Date());
    this.manPowerService.getManpowerBudget(companyCode, stateCode, plantCode, departmentCode, monthName).subscribe((response: any) => {
      if (response) {
        this.manPowerForm.form.get('BudgetManPower').setValue(response);
      } else {
        this.manPowerForm.form.get('BudgetManPower').setValue(0);
      }
    });
  }

  private dateConvertor(date: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'];
    return `${months[date.getMonth()]}-${date.getFullYear()}`;
  }

  private getWeekOffDateList(dateRanges, plantCode: string) {
    this.manPowerService.getWeekOffDateList(new Date(dateRanges[0]).toDateString(), new Date(dateRanges[1]).toDateString(),
      plantCode).subscribe((response: any[]) => {
        this.weeklyOffDateList = response;
      });
  }

  private getHolidayDateList(dateRanges, companyCode: string, plantCode: string) {
    this.manPowerService.getHolidayDateList(new Date(dateRanges[0]).toDateString(), new Date(dateRanges[1]).toDateString(),
      companyCode, plantCode).subscribe((response: any[]) => {
        this.holidayDateList = response;
      });
  }

  private getCompany(): void {
    this.manPowerService.getCompanies().subscribe((response: any[]) => {
      this.companyList = response;
      this.manPowerForm.controls['Company_Code'].setValue(this.manPower.Company_Code);
      if (this.manPower.Company_Code) {
        // this.getStates();
      } else if (this.companyList.length === 1) {
        this.manPowerForm.form.get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.manPower.Company_Code = this.companyList[0].Company_Code;
        // this.getStates();
      }
    });
  }

  // private getStates(): void {
  // 	this.manPowerService.getState().subscribe((response: any[]) => {
  // 		this.stateList = response;
  // 		this.manPower.State_Code = 111;
  // 		this.manPowerForm.controls['State_Code'].setValue(this.manPower.State_Code);
  // 		if (this.manPower.State_Code) {
  // 			this.getPlants(this.manPower.Company_Code, this.manPower.State_Code);
  // 		} else if (this.stateList.length === 1) {
  // 			this.manPowerForm.form.get('State_Code').setValue(this.stateList[0].State_Code);
  // 			this.getPlants(this.manPower.Company_Code, this.stateList[0].State_Code);
  // 		}
  // 	});
  // }

  private getPlants(): void {

    this.plantList = this.unitDepartmentSectionTreeData.map((x: any) => { return { Plant_Code: x.Plantcode0, Plant: x.Plant0 }; });
    this.manPower.Plant_Code = this.plantList[0].Plant_Code;

    if (this.plantList.length === 1) {

      // this.manPowerForm.controls['Plant_Code'].setValue(this.manPower.Plant_Code);
      this.manPowerForm.form.get('Plant_Code').setValue(this.manPower.Plant_Code);
      // this.manPower.Plant_Code = this.plantList[0].Plant_Code;
      // this.getPlantTreeStructure(this.plantList[0].Plant_Code);
      this.getUnits();
      this.restrictDateRange(this.plantList[0].Plant_Code);
    }


    // this.manPowerService.getPlants(companyCode, stateCode).subscribe((response: any[]) => {
    // 	this.plantList = response;
    // 	this.manPower.Plant_Code = this.plantList[0].Plant_Code;
    // 	this.manPowerForm.controls['Plant_Code'].setValue(this.manPower.Plant_Code);
    // 	this.manPower.Plant_Code = this.plantList[0].Plant_Code;
    // 	this.getPlantTreeStructure(this.plantList[0].Plant_Code);
    // });
  }

  private getUnits(): void {
    const plantCode = this.manPowerForm.controls['Plant_Code'].value;
    const companyCode = this.manPowerForm.form.get('Company_Code').value;
    this.unitList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === plantCode).Values;
    // .find(itm => itm.Unitcode0 === companyCode).Values;

    // if (this.unitList.length === 1) {
    this.manPowerForm.form.get('Unit_Code').setValue(this.unitList[0].Unitcode0);
    this.manPower.Unit_Code = this.unitList[0].Unitcode0;
    // }

    this.manPowerForm.controls['Unit_Code'].setValue(this.manPower.Unit_Code);
    if (this.manPower.Unit_Code) {
      this.getDepartments(plantCode, this.manPower.Unit_Code);
    } else if (this.unitList.length === 1) {
      this.manPowerForm.form.get('Unit_Code').setValue(this.unitList[0].Unit_Code);
      this.getDepartments(plantCode, this.unitList[0].Unit_Code);
    }
  }

  private getDepartments(plantCode: any, unitCode: any): void {
    const companyCode = this.manPowerForm.form.get('Company_Code').value;
    this.departmentList = this.unitDepartmentSectionTreeData.find(itm => itm.Plantcode0 === plantCode).Values
      .find(itm => itm.Unitcode0 === unitCode).Values
      .map((e) => { return { Department_Code: e.UnitDeptID0, Name: e.Department0 }; });
    // if (this.departmentList.length === 1) {
    this.manPowerForm.form.get('Department_Code').setValue(this.departmentList[0].Department_Code);
    this.manPower.Department_Code = this.departmentList[0].Department_Code;
    // }
    if (this.manPower.Department_Code) {
      // this.getManpowerBudget(this.manPower.Company_Code, null, this.manPower.Plant_Code,
      //   this.manPower.Department_Code);
      this.getSectionList(this.manPower.Plant_Code, unitCode, this.manPower.Department_Code);
    }
    // else if (this.departmentList.length === 1) {
    //   this.manPowerForm.form.get('Department_Code').setValue(this.departmentList[0].Department_Code);
    //   this.getManpowerBudget(this.manPower.Company_Code, .State_Code, this.manPower.Plant_Code,
    //     this.departmentList[0].Department_Code);
    //   this.getSectionList(this.manPower.Plant_Code, this.manPower.Unit_Code, this.departmentList[0].Department_Code);
    // }

    // this.manPowerService.getDepartments(plantCode, unitCode).subscribe((response: any[]) => {
    //   this.departmentList = response;
    //   this.manPowerForm.controls['Department_Code'].setValue(this.manPower.Department_Code);
    //   if (this.manPower.Department_Code) {
    //     this.getManpowerBudget(this.manPower.Company_Code, this.manPower.State_Code, this.manPower.Plant_Code,
    //       this.manPower.Department_Code);
    //     this.getSectionList(this.manPower.Plant_Code, this.manPower.Unit_Code, this.manPower.Department_Code);
    //   } else if (this.departmentList.length === 1) {
    //     this.manPowerForm.form.get('Department_Code').setValue(this.departmentList[0].Department_Code);
    //     this.getManpowerBudget(this.manPower.Company_Code, this.manPower.State_Code, this.manPower.Plant_Code,
    //       this.departmentList[0].Department_Code);
    //     this.getSectionList(this.manPower.Plant_Code, this.manPower.Unit_Code, this.departmentList[0].Department_Code);
    //   }
    // });
  }

  private getSectionList(plantCode: any, unitCode: any, departmentCode: any): void {
    // this.manPowerService.getDepartmentPlantUnitSectionTree(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
    // this.manPowerForm.controls['SectionCodes'].setValue(this.manPower.SectionCodes);
    this.sectionTreeResponses = this.unitDepartmentSectionTreeData;
    this.bindSectionEdit(this.unitDepartmentSectionTreeData, false, this.manPowerForm.value);
    // this.manPowerForm.form.get('SectionCodes').setValue([200]);
    // });
  }

  private getAllSectionShiftList(companyCode, stateCode, plantCode, unitCode, departmentCode, sectionCodes, workmanCategoryCodes): void {
    this.manPowerService.getAllSectionShiftList(companyCode, stateCode, plantCode, unitCode, departmentCode, sectionCodes,
      workmanCategoryCodes)
      .subscribe((response: any[]) => {
        this.isOpenSectionEntry = true;
        // this.dateTimeChange();
        this.createShiftSectionList(response);
        // this.bindSectionEdit(response);
      });
  }

  private bindControlForEdit() {
    // this.getAllData();
    setTimeout(() => {
      if (this.manPowerForm) {
        // this.manPowerForm.form.get('State_Code').setValue(null);
        // this.manPowerForm.form.get('Plant_Code').setValue(null);
        // this.manPowerForm.form.get('Unit_Code').setValue(null);
        // this.manPowerForm.form.get('Department_Code').setValue(null);
        // this.manPowerForm.form.get('CategoryWorkmenMIL_Code').setValue(null);
      }
      this.getWorkManCategoryList();

      if (this.manPower.WeekOffExclude) {
        this.getWeekOffDateList([this.manPower.StartDate, this.manPower.EndDate], this.manPower.Plant_Code);
      }
      if (this.manPower.HolidayExclude) {
        this.getHolidayDateList([this.manPower.StartDate, this.manPower.EndDate], this.manPower.Company_Code, this.manPower.Plant_Code);
      }
    }, 500);
  }

  private bindControlValue() {
    setTimeout(() => {
      console.log('this.manPower', this.manPower);
      console.log('sectionTreeList', this.sectionTreeList);

      this.manPowerForm.controls['SectionCodes'].setValue(this.manPower.SectionCodes);

      // this.sectionTreeList = [...this.sectionTreeList];
      // this.getSectionList(this.manPower.Plant_Code, this.manPower.Unit_Code, this.manPower.Department_Code);
      this.bindSectionEdit(this.sectionTreeResponses, true, this.manPower);
      // this.manPowerForm.form.get('BudgetManPower').disable();
      // this.manPowerForm.form.get('BudgetManPower').setValue(this.manPower.BudgetManPower);
      this.manPowerForm.form.get('ManPowerType').setValue(this.manPower.ManPowerType);
      this.manPowerForm.form.get('dateRange').setValue(this.manPower.dateRange);
      this.manPowerForm.form.get('WeekOffExclude').setValue(this.manPower.WeekOffExclude);
      this.manPowerForm.form.get('HolidayExclude').setValue(this.manPower.HolidayExclude);
      this.manPowerForm.form.get('Company_Code').setValue(this.manPower.Company_Code);
      // this.manPowerForm.form.get('State_Code').setValue(this.manPower.State_Code);
      this.manPowerForm.form.get('Plant_Code').setValue(this.manPower.Plant_Code);
      this.manPowerForm.form.get('Unit_Code').setValue(this.manPower.Unit_Code);
      this.manPowerForm.form.get('Department_Code').setValue(this.manPower.Department_Code);
      this.manPowerForm.form.get('CategoryWorkmenMIL_Code').setValue(this.manPower.CategoryWorkmenMIL_Code);

      // this.manPowerForm.form.get('SectionCodes').setValue(this.manPower.SectionCodes);
      console.log('this.manPower.controls', this.manPowerForm.controls);

    }, 500);
  }

  private closeModalOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  updateUnitBudgetAmt(unitWiseDataIndex) {
    this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      element.Amount = (Number(this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount) / 12).toFixed(2);
      return element;
    });
  }

  updateUnitWiseAmount(amountIndex, unitWiseDataIndex) {
    let remainingTotalAmt = 0;
    this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index <= amountIndex) {
        remainingTotalAmt += Number(element.Amount);
      }
    });

    const remainingIndex = 12 - (amountIndex + 1);
    const remainingAmt = Number((this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex].BudgetAmount - remainingTotalAmt)
      / remainingIndex).toFixed(2);
    this.yearlyBudgetObject.plant.UnitList[unitWiseDataIndex]['MonthObjectList'].map((element, index) => {
      if (index > amountIndex) {
        element.Amount = remainingAmt;
      }
      return element;
    });
  }

  addDate(section) {
    // const lastDate = section.Date[section.Date.length - 1];
    // const ndate = moment(lastDate, 'DD-MM-YYYY').add(1, 'days');
    // section.Date.push(ndate.format('DD/MM/YY'));
    // //  section.Date.push(section.Date[section.Date.length - 1]);
    // for (let i = 0; i <= section.shiftList.length - 1; i++) {
    //   section[section.shiftList[i]].push(0);
    // }
    const lastDate = section.Date[section.Date.length - 1].date;
    const endDate = moment(lastDate).add(1, 'days');
    const dateObj = {
      displayDate: endDate.format('DD/MM/YYYY'),
      date: moment(endDate)
    };
    section.Date.push(dateObj);
    for (let i = 0; i <= section.shiftList.length - 1; i++) {
      // section[section.shiftList[i]].push(0);
      section.shiftList[i].data.push({ value: 0 });
    }
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  // ifToggle($event){
  //   const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
  //   if (userRoles.length === 0) {
  //     return false;
  //   }
  //   const deptHead = userRoles.find((item: any) => item.Role_Code === RoleCode.UNITHEAD);
  //   if ($event.Status === ManpowerRequestStatus.REJECT && deptHead){
  //       this.isShow = false;
  //   }
  // }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      // alertRef.then((e) => {
      // });
    }, 10);
  }
}
