import { Component, OnInit, ViewChild } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ManPowerExpandRowComponent } from '../ag-helper/expand-row/expand-row.component';
import { ManPowerService } from '../man-power.service';
import { AgActionCellRendererComponent } from 'src/app/core/components';
import * as moment from 'moment';
import { AgManPowerCellRendererComponent } from '../ag-helper/ag-man-power-cell-renderer/ag-man-power-cell-renderer.component';
import { columnDefs } from 'src/app/dashboard/dashboard.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManPowerComponent } from '../man-power.component';
import { AgActionCellEditRendererComponent } from
  'src/app/core/components/grid-wrapper/helper/ag-action-cell-edit-renderer/ag-action-cell-edit-renderer.component';
import { ManpowerRequestStatus, RoleCode } from '../man-power.modal';
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin, Observable } from 'rxjs';
import { SharedService } from 'src/app/core/services';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { AgIconActionCellRendererComponent } from '../ag-helper/ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { GLOBAL } from 'src/app/app.globals';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { AgManPowerReduceCellRendererComponent }
  from '../ag-helper/ag-man-power-reduce-cell-renderer/ag-man-power-reduce-cell-renderer.component';
import { values } from 'lodash';

@Component({
  selector: 'app-man-power-list',
  templateUrl: './man-power-list.component.html',
  styleUrls: ['./man-power-list.component.css']
})
export class ManPowerListComponent implements OnInit {

  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};

  manPowerData = [];
  currentPunchRowIndex: number;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  columnDefs: any[] = [];
  rowData: any;
  contractorList: any[] = [];
  private onDestroy: Subject<any>;
  private editEnabled = false;
  public canUserAddMP = false;
  constructor(private manPowerService: ManPowerService,
    private modalService: NgbModal,
    private sharedService: SharedService
  ) {
    this.onDestroy = new Subject();
    this.editEnabled = this.manPowerService.isEditAllow();
    this.setColumnDef();
    this.setGridOptions();
  }


  public ngOnInit() {
    this.canUserAddMP = this.manPowerService.canUserAddManPower();
    this.getManPowerList();
  }

  public getManPowerInfo(rowData, rowIndex) {
    if (this.currentPunchRowIndex === rowIndex) {
      // delete row
      const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex);
      expandedRow.data.expanded = false;
      this.gridAPI.updateRowData({ update: [expandedRow.data] });
      const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex + 1);
      this.gridAPI.updateRowData({ remove: [selectedRow.data] });
      this.currentPunchRowIndex = undefined;
    } else {
      // First close previously opend row index
      if (this.currentPunchRowIndex !== undefined) {
        const expandedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex);
        expandedRow.data.expanded = false;
        this.gridAPI.updateRowData({ update: [expandedRow.data] });
        const selectedRow = this.gridAPI.getDisplayedRowAtIndex(this.currentPunchRowIndex + 1);
        if (selectedRow) {
          this.gridAPI.updateRowData({ remove: [selectedRow.data] });
        }
      }
      this.rowData = rowData;
      // add row
      this.currentPunchRowIndex = rowIndex;
      this.gridAPI.updateRowData({
        add: [{
          rowData,
          fullWidth: true
        }],
        addIndex: rowIndex + 1
      });
    }
  }

  public onAddManPower(): void {
    const previousDayallow = 1;
    this.openManPowerModal();
  }
  // public approveAndSavePowerRequisition(data: any): void {
  //   const manPowerObj = { ... this.manPower };
  //   console.log('form value', manPowerObj);
  //   console.log('sectionList', this.sectionList);
  //   if (!manPowerObj.ManpowerRequisiton_Code) {
  //     // manPowerObj.ManpowerRequisiton_Code = 0;
  //     // manPowerObj.StartDate = moment(manPowerObj.dateRange[0]).add(1, 'days');
  //     manPowerObj.StartDate = moment(manPowerObj.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
  //     // manPowerObj.EndDate = moment(manPowerObj.dateRange[1]).add(1, 'days');
  //     manPowerObj.EndDate = moment(manPowerObj.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
  //     manPowerObj.Status = 'Submited';
  //   }
  //   manPowerObj.dateRange = null;
  //   manPowerObj.ManpowerShiftRelations = [];
  //   manPowerObj.ManpowerShiftRelations = this.getSectionFromSectionList(this.sectionList);
  //   console.log('manPowerObj.ManpowerShiftRelations', manPowerObj.ManpowerShiftRelations);
  //   manPowerObj.SectionCodes = manPowerObj.SectionCodes.map(item => item.id);

  //   manPowerObj.UserId = GLOBAL.USER.LOGGED_IN_USER;
  //   manPowerObj.RoleCode = GLOBAL.USER.ROLE_CODE;
  //  console.log('manPowerObj', manPowerObj);
  //   this.manPowerService.approveAndSavePowerRequisition(manPowerObj).subscribe((response: any) => {
  //     // if (response) {
  //     //   this.afterSave.next();
  //     // }
  //   });
  // }
  public saveData(data: any, removedList: any): void {
    // data.loggedInUserId : any;
    data.loggedInUserId = GLOBAL.USER_ID;
    data.loggedInRoleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    // if (this.checkValidDataForSave(data)) {
    data.removedList = removedList;
    this.manPowerService.savePowerRequisitionHRAssign(data).subscribe(
      (response: any) => {
        console.log('save response', response);
        if (response === 1) {
          this.showAlert('success', 'Data Saved Successfully', 'Saved!!!');
          this.getManPowerList();
        }
      }
    );
    // }
  }

  private getSectionFromSectionList(sectionList: any[]): any[] {

    const manpowerShiftRelations = [];
    console.log('final sectionList', sectionList);
    for (const section of sectionList) {
      section.workmanCategoryList.forEach((workmanCategory: any) => {
        workmanCategory.shiftList.forEach((shift: any) => {
          shift.data.forEach((totalData: any, dataIndex: number) => {

            const date = workmanCategory.Date[dataIndex].displayDate;
            const shiftObject = {
              Shift_Code: shift.Shift_Code,
              CategoryWorkmenMIL_Code: workmanCategory.CategoryWorkmenMIL_Code,
              Date: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              Total: totalData.value,
              Section_Code: section.Id
            };
            manpowerShiftRelations.push(shiftObject);
          });
        });
      });
    }
    return manpowerShiftRelations;
  }

  private checkValidDataForSave(data: any): boolean {
    let isValidData = 0;
    for (let a = 0; a < data.workManCategoryList.length; a++) {
      for (let i = 0; i < data.workManCategoryList[a].Dates.length; i++) {
        if (isValidData === 0) {
          const shifts = data.workManCategoryList[a].Dates[i].Shifts;
          for (let j = 0; j < shifts.length; j++) {
            const shiftItem = shifts[j];
            const totalValue = shiftItem.AssignedContractors.reduce((sum, item) => sum + parseInt(item.Assign, 10), 0);
            console.log('totalValue', totalValue);

            if (shiftItem.Requirement !== totalValue) {
              console.log('error');
              isValidData = 1;
              break;
            }
            const zeroAssignValue = shiftItem.AssignedContractors.filter(item => item.Assign !== '' && parseInt(item.Assign, 10) > 0);
            if (zeroAssignValue.length !== shiftItem.AssignedContractors.length) {
              isValidData = 2;
              break;
            }
            const assignedContractor = shiftItem.AssignedContractors.find(item => item.ContractorVendor_Code === null);
            if (assignedContractor) {
              isValidData = 3;
              break;
            }
          }
        } else {
          break;
        }
      }
    }

    if (isValidData === 1) {
      this.showAlert('error', 'Assign man power should be equal to required man power.', 'Invalid data');
    } else if (isValidData === 2) {
      this.showAlert('error', 'Assign man power should be greater than 0.', 'Invalid data');
    } else if (isValidData === 3) {
      this.showAlert('error', 'Please assign contractor for man power request.', 'Invalid data');
    } else if (isValidData === 0) {
      return true;
    }
    return false;
  }

  private getManPowerList(): void {

    // const getManPowerList$: Observable<any[]> = this.manPowerService.getAllManPowerData1();
    const getManPowerList$: Observable<any[]> = this.manPowerService.getAllManPowerData();
    // const getContractors$ = this.manPowerService.getContractors();
    forkJoin(getManPowerList$).subscribe(
      ([manpowerList]: [any[]]) => {
        // this.contractorList = contractorList;
        // const manPowerDt = this.setManpowerRowSpan1(getManPowerList);
        // this.manPowerData = this.setManpowerRowSpan(manpowerList);
        // manpowerList.map((x: any) => {
        // x.isDelete = this.manPowerService.canUserDelete(x);
        // });
        this.manPowerData = manpowerList;
        // console.log('manpower dt', manPowerDt);
        console.log('manpower data', this.manPowerData);
        setTimeout(() => {
          this.gridAPI.sizeColumnsToFit();
        }, 10);
      });
  }

  private setManpowerRowSpan(manpowerList: any[]): any[] {
    let isApprovedProcessAndHRUser = false;
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    const hrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD);
    if (hrHead) {
      isApprovedProcessAndHRUser = true;
    }
    const securityHead = userRoles.find((item: any) => item.Role_Code === RoleCode.SECURITY);
    if (securityHead) {
      isApprovedProcessAndHRUser = true;
    }
    manpowerList.forEach((manpowerItem: any) => {
      manpowerItem.Status = ManpowerRequestStatus.APPROVED;
    });

    console.log('isApprovedProcessAndHRUser 3', isApprovedProcessAndHRUser);
    return manpowerList;
  }

  private findDetailData(manpowerItem: any, contractorList, isAddRequisition: any): any {
    let isApprovedProcessAndHRUser = false;
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    // tslint:disable-next-line: max-line-length
    const hrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD || item.Role_Code === RoleCode.SECURITY || item.Role_Code === RoleCode.HRBP || item.Role_Code === RoleCode.ERTEAM);
    if (hrHead) {
      isApprovedProcessAndHRUser = true;
    }
    // const securityHead = userRoles.find((item: any) => item.Role_Code === RoleCode.SECURITY);
    // if (securityHead) {
    //   isApprovedProcessAndHRUser = true;
    // }

    console.log('isApprovedProcessAndHRUser 2', isApprovedProcessAndHRUser);
    manpowerItem.contractorList = contractorList;
    manpowerItem.Status = ManpowerRequestStatus.APPROVED;
    manpowerItem.workManCategoryList.forEach((workManCategory: any) => {
      workManCategory.Dates = workManCategory.Dates.sort((a, b) => {
        return <any>new Date(a.Date) - <any>new Date(b.Date);
      });
      workManCategory.Dates.forEach((item: any) => {
        item.displayDate = this.dateConvertor(new Date(item.Date));
        item.Shifts.forEach(((shiftItem: any) => {
          shiftItem.AssignedContractors.forEach((assigned: any) => {
            if (assigned.Shortage === 0) {
              assigned.shortageBackgroundColor = 'white';
              assigned.shortageFontColor = 'black';
            } else if (assigned.RefAssignId === 0) {
              const lst = [] = shiftItem.AssignedContractors
                .filter((x: any) => x.RefAssignId === assigned.AssignId && x.MRShiftId === assigned.MRShiftId);
              if (lst && lst.length > 0) {
                assigned.shortageBackgroundColor = 'white';
                assigned.shortageFontColor = 'black';
              } else {
                assigned.shortageBackgroundColor = 'red';
                assigned.shortageFontColor = 'white';
              }
            }
          });
          if (shiftItem.AssignedContractors.length === 0 && isAddRequisition === true) {
            if (isApprovedProcessAndHRUser) {
              shiftItem.AssignedContractors.push({
                Assign: shiftItem.Requirement,
                ContractorVendor_Code: null,
                ContractorName: ''
              });
              item.rowspan = item.rowspan ? item.rowspan + shiftItem.AssignedContractors.length : shiftItem.AssignedContractors.length;
              shiftItem.rowspan = shiftItem.rowspan ? shiftItem.rowspan + shiftItem.AssignedContractors.length
                : shiftItem.AssignedContractors.length;
            } else {
              item.rowspan = item.Shifts.length;
              shiftItem.rowspan = 1;
            }
          } else {
            item.rowspan = item.rowspan ? item.rowspan + shiftItem.AssignedContractors.length : shiftItem.AssignedContractors.length;
            shiftItem.rowspan = shiftItem.rowspan ? shiftItem.rowspan + shiftItem.AssignedContractors.length
              : shiftItem.AssignedContractors.length;
          }
        }));
      });
    });
    return manpowerItem;
  }

  // public calculateRowspan(dateObject) {
  //   let rowspan = 0;
  //   dateObject.Shifts.forEach((shiftItem: any) => {
  //     const assigneeCount = shiftItem.Contractors.length;
  //     rowspan += (assigneeCount > 0 ? assigneeCount : 1);
  //   });

  //   dateObject.rowspan = rowspan;
  // }

  private setManpowerRowSpan1(manpowerList: any[]): any[] {
    let isApprovedProcessAndHRUser = false;
    const userRoles: any[] = JSON.parse(localStorage.getItem('Role'));
    const hrHead = userRoles.find((item: any) => item.Role_Code === RoleCode.HRHEAD);
    if (hrHead) {
      isApprovedProcessAndHRUser = true;
    }
    const securityHead = userRoles.find((item: any) => item.Role_Code === RoleCode.SECURITY);
    if (securityHead) {
      isApprovedProcessAndHRUser = true;
    }
    console.log('isApprovedProcessAndHRUser 1', isApprovedProcessAndHRUser);
    manpowerList.forEach((manpowerItem: any) => {
      manpowerItem.contractorList = this.getContractorsByPlant(manpowerItem.Plant_Code);
      manpowerItem.Status = ManpowerRequestStatus.APPROVED;
      manpowerItem.workManCategoryList.forEach((workManCategory: any) => {
        workManCategory.Dates = workManCategory.Dates.sort((a, b) => {
          return <any>new Date(a.Date) - <any>new Date(b.Date);
        });
        workManCategory.Dates.forEach((item: any) => {
          item.displayDate = this.dateConvertor(new Date(item.Date));
          item.Shifts.forEach(((shiftItem: any) => {
            shiftItem.AssignedContractors.forEach((assigned: any) => {
              if (assigned.Shortage === 0) {
                assigned.shortageBackgroundColor = 'white';
                assigned.shortageFontColor = 'black';
              } else if (assigned.RefAssignId === 0) {
                const lst = [] = shiftItem.AssignedContractors
                  .filter((x: any) => x.RefAssignId === assigned.AssignId && x.MRShiftId === assigned.MRShiftId);
                if (lst && lst.length > 0) {
                  assigned.shortageBackgroundColor = 'white';
                  assigned.shortageFontColor = 'black';
                } else {
                  assigned.shortageBackgroundColor = 'red';
                  assigned.shortageColor = 'white';
                }
              }
            });
            if (shiftItem.AssignedContractors.length === 0) {
              if (isApprovedProcessAndHRUser) {
                shiftItem.AssignedContractors.push({
                  Assign: shiftItem.Requirement,
                  ContractorVendor_Code: null,
                  ContractorName: ''
                });
                item.rowspan = item.rowspan ? item.rowspan + shiftItem.AssignedContractors.length : shiftItem.AssignedContractors.length;
                shiftItem.rowspan = shiftItem.rowspan ? shiftItem.rowspan + shiftItem.AssignedContractors.length
                  : shiftItem.AssignedContractors.length;
              }
            } else {
              item.rowspan = item.rowspan ? item.rowspan + shiftItem.AssignedContractors.length : shiftItem.AssignedContractors.length;
              shiftItem.rowspan = shiftItem.rowspan ? shiftItem.rowspan + shiftItem.AssignedContractors.length
                : shiftItem.AssignedContractors.length;
            }
          }));
        });
      });
    });
    return manpowerList;
  }

  private dateConvertor(date: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'];
    return `${date.getDate()}-${months[date.getMonth()].substr(0, 3)}-${date.getFullYear()}`;
  }

  private getManPowerListOld(): void {
    const getManPowerList$ = this.manPowerService.getAllManPowerData();
    const getContractors$ = this.manPowerService.getContractors();

    forkJoin(getManPowerList$, getContractors$).subscribe(
      ([manpowerList, contractorList]: [any[], any[]]) => {
        this.contractorList = contractorList;
        this.manPowerData = this.setManPowerResponse(manpowerList);
        setTimeout(() => {
          this.gridAPI.sizeColumnsToFit();
        }, 10);
      });
  }

  private setManPowerResponse(response: any[]): any[] {
    const finalGridData = [];
    response.forEach((dataObject: any) => {
      const existObj = finalGridData.find(item => item.RequestNo === dataObject.RequestNo);
      if (existObj) {
        const existDateObj = existObj.childSections.find(item => item.Date === dataObject.Date);
        if (existDateObj) {
          existDateObj.dateChildSections.push({
            ShiftName: dataObject.ShiftName,
            Requrement: dataObject.Requrement,
            Assign: dataObject.Assign || dataObject.Requrement,
            ContractorName: dataObject.ContractorName
          });
        } else {
          existObj.childSections.push(
            {
              Date: dataObject.Date,
              dateChildSections: [
                {
                  Requrement: dataObject.Requrement,
                  ShiftName: dataObject.ShiftName,
                  Assign: dataObject.Assign || dataObject.Requrement,
                  ContractorName: dataObject.ContractorName
                }
              ]
            }
          );
        }
      } else {
        const manPowerObj = {
          // contractorList: this.contractorList, // kh old 18-05-2020
          contractorList: this.getContractorsByPlant(dataObject.Plant),  // kh new 18-05-2020
          ManpowerRequisiton_Code: dataObject.ManpowerRequisiton_Code,
          RequestNo: dataObject.RequestNo,
          RequestDate: dataObject.RequestDate,
          RequestDays: dataObject.RequestDays,
          ManpowerTypeMIL_Code: dataObject.ManpowerTypeMIL_Code,
          ManpowerTypeName: dataObject.ManpowerTypeName,
          RequetedByName: dataObject.RequetedByName,
          RequetedBy: dataObject.RequetedBy,
          LastApproveBy: dataObject.LastApproveBy,
          Plant: dataObject.Plant,
          Unit: dataObject.Unit,
          Department: dataObject.Department,
          SectionL1: dataObject.SectionL1,
          SectionL2: dataObject.SectionL2,
          SectionL3: dataObject.SectionL3,
          // Status: dataObject.Status,
          Status: ManpowerRequestStatus.APPROVED,
          childSections: [
            {
              Date: dataObject.Date,
              dateChildSections: [
                {
                  Requrement: dataObject.Requrement,
                  ShiftName: dataObject.ShiftName,
                  Assign: dataObject.Assign || dataObject.Requrement,
                  ContractorName: dataObject.ContractorName
                }
              ]
            }
          ]
        };
        finalGridData.push(manPowerObj);
      }
    });
    // finalGridData = finalGridData.sort((item: any)=> item.Date);
    finalGridData.forEach((data: any) => {
      data.childSections = data.childSections.sort((a, b) => {
        return <any>new Date(a.Date) - <any>new Date(b.Date);
      });

      data.childSections.forEach((childData: any) => {
        childData.dateChildSections = childData.dateChildSections.sort((a, b) => {
          return <any>new Date(a.Date) - <any>new Date(b.Date);
        });
      });
    });
    console.log('finalGridData', finalGridData);

    return finalGridData;
  }

  private setColumnDef(): void {
    this.columnDefs = [
      {
        headerName: '#', field: 'sortCode', width: 50, minWidth: 50,
        cellRendererFramework: AgIconActionCellRendererComponent,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRendererParams: {
          onClick: this.openAssignmentModal,
          title: 'Assignment',
          width: 30,
          icon: 'fa fa-plus-circle',
          cssClass: 'expand-icon',
          fontSize: 14,
          showCount: true,
        }, pinned: 'left'
      },
      // {
      //   headerName: '#', field: 'sortCode', width: 70,
      //   sortable: false, suppressMenu: true,
      //   cellRendererFramework: ManPowerExpandRowComponent,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Request No', field: 'RequestNo', sortable: true, filter: true,
        width: 150, minWidth: 150,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Request Date', field: 'RequestDate', sortable: true, filter: true,
        width: 90, minWidth: 90,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: (params) => {
          if (!params.data.parent) {
            return moment(params.value).format('DD/MM/YYYY');
          }
        },
      },
      {
        headerName: 'Type', field: 'IsAddRequisiton', sortable: true, filter: true,
        width: 90, minWidth: 90,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        cellRenderer: (params) => {
          if(!params.value) {
            return '<span style="color: red">Reduction</span>';
          } else {
            return '<span style="color: green">New</span>';
          }
        },
      },
      // {
      //   headerName: 'Requested  Days', field: 'RequestNo',
      //   sortable: true, filter: true, width: 150,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Manpower Type', field: 'ManPowerTypeName', sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Plant', field: 'PlantName', width: 100,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Unit', field: 'UnitName', width: 100,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Department', field: 'DepartmentName', width: 200,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Level', field: 'level', width: 70,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Requested By', field: 'RequestedName', width: 150,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Last Action By', field: 'LastApprovedByName', width: 150,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Status', field: 'Status', width: 150,
        sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'Status', field: 'ShortageStatus', width: 100,
      //   sortable: true, filter: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Action', field: '', width: 100,
        headerComponentParams: { enableSorting: false },
        // hide: this.checkValue(this.rowData),
        // cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        // tslint:disable-next-line:object-literal-shorthand
        cellStyle: function (params) {
          // tslint:disable-next-line:triple-equals
          if (params.data.Status == 'Rejected') {
            // Here you can check the value and based on that you can change the Status
            // mark Rejected status then not edit button hide
            return { visibility: 'hidden' };
            // tslint:disable-next-line:no-else-after-return
          } else {
            return { textAlign: 'center' };
          }
        },
        cellRendererFramework: AgActionCellEditRendererComponent,
      }
    ];
  }

  // editEnabledData(params) {
  //   if (this.rowData.Status === 'Rejected') {
  //     this.editEnabled = true;
  //   }
  // }

  private setGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: true,
      paginationPageSize: 10,

      headerHeight: 40,
      frameworkComponents: { fullWidthCellRenderer: AgManPowerCellRendererComponent },
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      fullWidthCellRenderer: 'fullWidthCellRenderer',
      // fullWidthCellRendererParams: {
      //   isDisable: (params) => {
      //     if (params.data.rowData) {
      //       return params.data.rowData.FShift_Code === -2 || params.data.rowData.FShift_Code === -1;
      //     }
      //     return false;
      //   },
      //   punchDetailsUpdated: (punchData) => {
      //     this.punchData = punchData;
      //   }
      // }
    };
  }

  private openManPowerModal(response: any = null): void {
    const modalRef = this.modalService.open(ManPowerComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' }
    );
    if (response) {
      modalRef.componentInstance.manPower = response;
    }
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });

    modalRef.componentInstance.afterSave.subscribe((value: string) => {
      modalRef.close(value);
      this.showAlert('success', 'Data Saved Successfully', 'Saved!!!');
      this.getManPowerList();
    });
  }

  onEdit(params) {
    console.log(params);
    this.manPowerService.getPowerRequistionForApprove(params.ManpowerRequisiton_Code).subscribe((response: any) => {
      console.log('response', response);
      response.lastLevel = this.manPowerData.find(tmp => tmp.ManpowerRequisiton_Code == params.ManpowerRequisiton_Code).LastLevel;
      this.openManPowerModal(response);
    });
  }

  onDelete(params) {
    console.log('delete', params);
    this.manPowerService.deleteManPowerRequest(params).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('delete success', response);
    });
  }

  private getContractors(any: string) {
    this.manPowerService.getContractors(any).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorList = response;
    });
  }

  private getContractorsByPlant(any: string) {
    this.manPowerService.getContractorsByPlant(any).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      const res = response;
      this.contractorList = res.filter((thing, index, self) =>
        index === self.findIndex((t: any) => (
          t.ContractorVendor_Code === thing.ContractorVendor_Code && t.SubContractorVendor_Code === thing.SubContractorVendor_Code
        ))
      );
      // this.contractorList = response.slice(0, 30);
    });
  }
  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      // alertRef.then((e) => {
      // });
    }, 10);
  }

  openAssignmentModal = (params): void => {
    const isAddRequisition = this.manPowerData.find((dt: any) => dt.RequestNo === params.data.RequestNo).IsAddRequisiton
    const serviceCall = isAddRequisition === false ? {
      call: this.manPowerService.getManPowerReduceDatail(params.data.ManpowerRequisiton_Code),
      component: AgManPowerReduceCellRendererComponent
    } : {
      call: this.manPowerService.getManPowerDatail(params.data.ManpowerRequisiton_Code),
      component: AgManPowerCellRendererComponent
    };
    const getManpowerDetail$: Observable<any[]> = serviceCall.call;
    const getContractors$ = this.manPowerService.getContractorsByPlant(params.data.Plant_Code);
    forkJoin(getContractors$, getManpowerDetail$).subscribe(
      ([contractorList, manpowerList]: [any[], any[]]) => {
        const contractorLst = contractorList.filter((thing, index, self) =>
          index === self.findIndex((t: any) => (
            t.ContractorVendor_Code === thing.ContractorVendor_Code && t.SubContractorVendor_Code === thing.SubContractorVendor_Code
          ))
        );
        params.data = this.findDetailData(manpowerList, contractorLst, isAddRequisition);
        const modalRef = this.modalService.open(serviceCall.component,
          { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
        modalRef.componentInstance.agInit(params);
        modalRef.componentInstance.closeModal.subscribe((value: string) => {
          modalRef.close(value);
        });
      });
  }
}
