import {
  AfterViewInit,
  Component,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  AgNumberCountComponent,
  AgActionCellRendererComponent,
  GridWrapperComponent,
} from "../../core/components";
import { DashboardService } from "../dashboard.service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { GLOBAL } from "src/app/app.globals";
import { CookieService } from "ngx-cookie-service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ContractorDashboardGroupRowComponent } from "./contractor-dashboard-group-row/contractor-dashboard-group-row.component";
import { SharedService } from "src/app/core/services";
import { GridApi, GridOptions } from "ag-grid-community";

@Component({
  selector: "app-contractor-dashboard",
  templateUrl: "./contractor-dashboard.component.html",
  styleUrls: ["./contractor-dashboard.component.css"],
})
export class ContractorDashboardComponent implements OnInit, AfterViewInit {
  @ViewChildren("agGrid")
  public gridWrapperComponents: QueryList<GridWrapperComponent>;

  groupingMeta = ["Contractor"];
  //groupingMetaForDailyRquirement = ['PlantName', 'WorkMenCategory'];
  groupingMetaForDailyRquirement = ["Contractor"];
  userId;
  columnDefs: any[];
  columnDefsForLicence: any[];
  columnDefsForWorkOrder: any[];
  columnDefsDailyRequirement: any[];
  rowData = [];
  rowDataForLicence = [];
  rowDataForWorkOrder = [];
  rowDataForDailyRequirement = [];
  numberOfRowsPerPage = 10;
  pageList = [10, 20, 50, 100];
  gridAPI: GridApi;
  rowClassRules;
  showSpinner: boolean;
  constructor(
    private dashboardService: DashboardService,
    private renderer: Renderer2,
    private cookieService: CookieService,
    private sharedService: SharedService
  ) {
    this.userId = GLOBAL.USER_ID;
    this.columnDefs = [
      {
        headerName: "#",
        field: "",
        width: 10,
        cellRendererFramework: AgNumberCountComponent,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 40,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
        },
        cellRendererFramework: ContractorDashboardGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta,
        },
      },
      {
        headerName: "ECPolicy No.",
        field: "Number",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Issue Date",
        field: "IssueDate",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Validity Date",
        field: "ValidityDate",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Expiry Remaining Days",
        field: "Expiry",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
    ];
    this.rowData = [];

    this.columnDefsForLicence = [
      {
        headerName: "#",
        field: "",
        width: 10,
        cellRendererFramework: AgNumberCountComponent,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 40,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
        },
        cellRendererFramework: ContractorDashboardGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta,
        },
      },
      {
        headerName: "Licence NO",
        field: "Number",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Issue Date",
        field: "IssueDate",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Validity Date",
        field: "ValidityDate",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Expiry Remaining Days",
        field: "Expiry",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
    ];
    this.rowDataForLicence = [];

    this.columnDefsForWorkOrder = [
      {
        headerName: "#",
        field: "",
        width: 5,
        cellRendererFramework: AgNumberCountComponent,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
        },
        cellRendererFramework: ContractorDashboardGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta,
        },
      },
      {
        headerName: "WorkOrder NO",
        field: "Number",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Issue Date",
        field: "IssueDate",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Validity Date",
        field: "ValidityDate",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Expiry Remaining Days",
        field: "Expiry",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
    ];
    this.rowDataForWorkOrder = [];

    this.columnDefsDailyRequirement = [
      {
        headerName: "#",
        field: "",
        width: 5,
        cellRendererFramework: AgNumberCountComponent,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Contractor",
        field: "Contractor",
        width: 40,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
        },
        cellRendererFramework: ContractorDashboardGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMetaForDailyRquirement,
        },
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      // {
      //   headerName: 'Plant', field: 'Plant', width: 50,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
      //   colSpan: (params) => {
      //     return params.data.parent ? 12 : 1;
      //   },
      //   cellRendererFramework: ContractorDashboardGroupRowComponent,
      //   cellRendererParams: {
      //     groupingData: this.groupingMetaForDailyRquirement
      //   }
      // },
      {
        headerName: "Category",
        field: "Category",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
        // colSpan: (params) => {
        //   return params.data.parent ? 12 : 1;
        // },
        // cellRendererFramework: ContractorDashboardGroupRowComponent,
        // cellRendererParams: {
        //   groupingData: this.groupingMetaForDailyRquirement
        // }
      },
      // {
      //   headerName: 'Unit', field: 'Unit', width: 50,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: "Department",
        field: "Department",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Section",
        field: "SectionName1",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Requirement",
        field: "Requirement",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Present",
        field: "Present",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
      {
        headerName: "Shortages",
        field: "Shortage",
        width: 50,
        cellStyle: { textAlign: "center" },
        headerClass: "align-center",
      },
    ];
    this.rowDataForDailyRequirement = [];
  }
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return "<div></div>";
  };
  ngOnInit() {
    // this.GetEXPolicyDataForDashboard();
    // this.GetLicenceDataForDashboard();
    // this.GetWorkOrderDataForDashboard();
    // this.GetDailyRequirementForDashboard();
    this.GetContractorDashboardNotif();
  }

  public ngAfterViewInit(): void {
    this.gridWrapperComponents.forEach((item) => {
      console.log("gridWrapperComponent", item);
      this.renderer.addClass(
        item._elementRef.nativeElement,
        "custom-grid-height"
      );
    });
  }
  // tslint:disable-next-line: function-name
  GetEXPolicyDataForDashboard() {
    this.dashboardService
      .GetEXPolicyDataForDashboard(this.userId)
      .subscribe((res: []) => {
        if (res.length > 0) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          // this.rowData = tempRowData;
        }
      });
  }

  // tslint:disable-next-line: function-name
  GetLicenceDataForDashboard() {
    this.dashboardService
      .GetLicenceDataForDashboard(this.userId)
      .subscribe((res: []) => {
        if (res.length > 0) {
          // const tempRowDataForLicence = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          // this.rowDataForLicence = tempRowDataForLicence;
        }
      });
  }

  // tslint:disable-next-line: function-name
  GetWorkOrderDataForDashboard() {
    this.dashboardService
      .GetWorkOrderDataForDashboard(this.userId)
      .subscribe((res: []) => {
        if (res.length > 0) {
          // const tempRowDataForWorkOrder = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          // this.rowDataForWorkOrder = tempRowDataForWorkOrder;
        }
      });
  }

  // tslint:disable-next-line: function-name
  GetDailyRequirementForDashboard() {
    this.dashboardService
      .GetDailyRequirementForDashboard(this.userId)
      .subscribe((res: []) => {
        if (res && res.length > 0) {
          // const tempRowDataForDailyRequirment = this.sharedService.agGridGroupRowOnProperties(this.groupingMetaForDailyRquirement, res);
          // this.rowDataForDailyRequirement = tempRowDataForDailyRequirment;
        }
        // setTimeout(() => {
        //   console.log('gridWrapperComponent', this.gridWrapperComponent);
        //   this.renderer.addClass(this.gridWrapperComponent._elementRef.nativeElement, 'custom-grid-height');
        // }, 500)
      });
  }
  GetContractorDashboardNotif() {
    this.dashboardService
      .GetContractorDashboardNotif(this.userId)
      .subscribe((res: []) => {
        if (res) {
          // work order
          if (res["Table1"].length > 0) {
            const tempRowDataForWorkOrder =
              this.sharedService.agGridGroupRowOnProperties(
                this.groupingMeta,
                res["Table1"]
              );
            this.rowDataForWorkOrder = tempRowDataForWorkOrder;
          }

          // licence
          if (res["Table2"].length > 0) {
            const tempRowDataForLicence =
              this.sharedService.agGridGroupRowOnProperties(
                this.groupingMeta,
                res["Table2"]
              );
            this.rowDataForLicence = tempRowDataForLicence;
          }

          // ecpolicy
          if (res["Table3"].length > 0) {
            const tempRowData = this.sharedService.agGridGroupRowOnProperties(
              this.groupingMeta,
              res["Table3"]
            );
            this.rowData = tempRowData;
          }

          // daily total requirment
          if (res["Table4"].length > 0) {
            const tempRowDataForDailyRequirment =
              this.sharedService.agGridGroupRowOnProperties(
                this.groupingMetaForDailyRquirement,
                res["Table4"]
              );
            this.rowDataForDailyRequirement = tempRowDataForDailyRequirment;
          }
        }
      });
  }
}
