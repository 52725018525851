import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrintGatePassService } from '../print-gate-pass/print-gate-pass.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralPrintPopupService } from './general-print-popup.service';
import { ApiCallService } from 'src/app/service/api-call.service';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-general-print-popup',
  templateUrl: './general-print-popup.component.html',
  styleUrls: ['./general-print-popup.component.css']
})
export class GeneralPrintPopupComponent implements OnInit {
  @ViewChild("templateRef") templateRef: TemplateRef<any>;
  @Input() selectedRowData: any

  public closeModal: EventEmitter<any>;
  public printFormGroup: FormGroup;

  UserID = GLOBAL.USER_ID;
  MultipleRole = JSON.parse(localStorage.getItem("Role"));

  stateList: any[] = [];
  UserDataAccessData: any = [];

  printFormat: any;
  multipleRequestBtnRoles: any;

  showWhilePrinting = false;

  repDataValueBycode: number = 0;
  requestTypeCode: number = 103;

  constructor(
    private modalService: NgbModal,
    private api: ApiCallService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    this.closeModal = new EventEmitter();
  }

  ngOnInit() {
    if (this.selectedRowData.length > 0) {
      this.getStates();
      this.modalService.open(this.templateRef, {
        size: "sm",
        backdrop: "static",
        keyboard: false,
      });
    }

    this.printFormGroup = this.formBuilder.group({
      State: [null, Validators.required],
    });

    this.api.globalSettingCode("").subscribe((res: any) => {
      let filterdata = res.Data.Table.filter(
        (i) => i.Code == "MOD152PG195GPPRINT01"
      );

      this.printFormat = Number(filterdata[0].Value);
      if (!this.printFormat) {
        this.printFormat = 1;
      }
      this.multipleRequestBtnRoles = res.Data.Table.find(
        (i) => i.Code == "MOD52PG195REQVERROLL"
      );

      let filtVal = res.Data.Table.filter((i) => i.Code == "MOD52PG195USER");
      this.repDataValueBycode = filtVal[0].Value;
    });
  }

  getStates(): void {
    let reqObj = {
      UserID: this.UserID,
      RequireType: 1,
    }
    this.api.getState(reqObj).subscribe((response) => {
      this.stateList = this.getUniqueStates(response);
      if (this.stateList.length === 1) {
        this.printFormGroup.get("State").setValue(this.stateList[0].State_Code);
      }
    },
    );
  }

  getUniqueStates(response: any[]): any[] {
    return response.filter(
      (item, index, self) => self.findIndex(t => t.State === item.State) === index
    );
  }

  async getSelectedState(event) {
    if (this.selectedRowData.length !== 0) {
      for (let row of this.selectedRowData) {
        row["selectedState"] = event.State;
      }
    }
  }

  public onPrintCloseAction(): void {
    this.closeModal.next("click");
    this.modalService.dismissAll(this.templateRef);
    this.selectedRowData.forEach((row) => {
      row.isSelected = false;
    });
    this.selectedRowData = [];
  }


  onPrint() {
    this.showWhilePrinting = true;
    console.log(this.selectedRowData);
    let printDetails, popupWin: Window;
    printDetails = document.getElementById("print-section").innerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <style>
              table,
                th,
                td {
                        border-collapse: collapse;
                        font-family: "Trebuchet MS";
                }
  
                tr,
                th,
                td {
                    border: 1px solid black;
                    padding: 0px;
                    text-align: center;
                }
              .pass-title{
                background-color: rgb(188, 143, 143);
              }
              .company-name{
                background-color: deeppink
              }
              .status{
                background-color: yellow
              }
              @media print{
                body {
                  zoom: 68% !important;
                  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
                  color-adjust: exact !important;                 /* Firefox 48 – 96 */
                  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
                  }
                }
          </style>
        </head>
    <body onload="window.print();">${printDetails}</body>
      </html>`);
    popupWin.document.close();
    setTimeout(() => {
      localStorage.setItem("report-data", JSON.stringify(this.selectedRowData));
    }, 100);
  }

  getAge(dob) {
    let age = moment().diff(dob, "years");
    return age;
  }

  getImagePath(imgPath) {
    if (imgPath != null) {
      if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(imgPath)) {
        return imgPath;
      } else {
        return this.sanitizer.bypassSecurityTrustUrl(imgPath);
      }
    } else {
      return "assets/Image/blank-face.jpg";
    }
  }

  showMultipleRequestButton() {
    if (this.MultipleRole) {
      let userRoles = [];
      this.MultipleRole.forEach((e) => {
        userRoles.push(e.Role_Code);
      });
      if (userRoles.findIndex((i) => i == 119) >= 0) {
        return false;
      } else if (
        this.UserDataAccessData.some((el) => el.Role_code == 107) &&
        this.UserDataAccessData.some((el) => el.Role_code == 118)
      ) {
        return true;
      } else if (
        this.multipleRequestBtnRoles &&
        this.multipleRequestBtnRoles.Value
      ) {
        if (
          userRoles.findIndex((i) =>
            this.multipleRequestBtnRoles.Value.includes(i)
          ) >= 0 &&
          this.requestTypeCode == 103
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }
}
