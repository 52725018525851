import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ɵComponentFactory } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contractor, WorkOrder } from './department-training.model';
import { GLOBAL } from 'src/app/app.globals';
import { map } from 'rxjs/operators';
import { DepartmenttrainingAdapter } from './adapter/department-training.adapter';
import { FileDoc } from 'src/app/core/components';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var $: any;

@Injectable()
export class DepartmenttrainingService {
  httpClient: any;
  files: FileDoc[] = [];
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private http: HttpClient,
    private adapter: DepartmenttrainingAdapter) { }

  public createComponent<T>(viewContainerRef: ViewContainerRef, type: Type<T>): ComponentRef<T> {
    const componentFactory: ɵComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(type);
    return viewContainerRef.createComponent(componentFactory);
  }
  public getContractors(excludeContractorCode: string = '', workOrderCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isMain=&
    ExcludeContractor_Code=${excludeContractorCode}&workOrderCode=${workOrderCode}&contractor_Code=`;
    // console.log(url);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORSBYDEFAULT}?UserId=${userId}&RoleCode=${roleCode}`;
    // console.log(url);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getPunchFromReaderDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_PUNCH_FROM_READER}`;
    console.log('URL', url);
    console.log('DATA', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getFormDDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_FORM_D_REPORT}`;
    console.log('GET_FORM_D_REPORT', url);
    console.log('GET_FORM_D_REPORTData', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response.objContractor));
  }
  public getDailyPresentDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_DAILY_PRESENT}`;
    //    console.log('URL getDailyPresentDetails', url);
    //   console.log('DATA getDailyPresentDetails', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getManPowerReqVSAllocationSummary(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_MANPOWERREQVSREQ_SUMMARY_REPORT}`;
    //    console.log('URL getDailyPresentDetails', url);
    //   console.log('DATA getDailyPresentDetails', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response.Data.Table));
  }
  public getMonthlyOTReport(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_MONTHLY_ATT_WITH_OT_REPORT}`;
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response.Data.Table));
  }
  public getLabourPerformanceReportData(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_TIMECARD_DATA}`;
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getTimeCardPlantWiseReportData(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_TIMECARD_PlantWise_DATA}`;
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getDailyUnitwisePresentDetails(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_UNITWISE_PRESENT}`;
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getDailyAbsentPresentReport(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_DAILY_ATTENDANCE_REPORT}`;
    console.log('URL getDailyAbsentPresentReport', url);
    //   console.log('DATA getDailyPresentDetails', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getContractorRegisterReport(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_CONT_REGISTER_REPORT}`;
    console.log('URL getContractorRegisterReport', url);
    //   console.log('DATA getDailyPresentDetails', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getLabourRegisterReport(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_LABOUR_REGISTER_REPORT}`;
    console.log('URL getLabourRegisterReport', url);
    //   console.log('DATA getDailyPresentDetails', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getMonthlyAttendanceReport(data: any): Observable<any> {
    const url = `${GLOBAL.APIS.REPORTS.GET_MONTHLY_ATTENDANCE_REPORT}`;
    console.log('URL getMonthlyAttendanceReport', url);
    // console.log('DATA getDailyPresentDetails', data);
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response));
  }
  public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_WORK_ORDER_BY_CONTRACTOR}?contractorcode=${contractorCode}&plantcode=${plantcode}`;
    return this.http.get<any>(url).pipe(
      map((response: any) => response.Data));
  }

  public getSubContractorByWODataforDA(contractorCode: number, workOrderNo: number): Observable<Contractor[]> {
    const url = `http://cmmsborlapi.spikyarc.com/api/UserManagement/GetSubContractorByWODataforDA?contractorCode=
    ${contractorCode}&workOrder=${workOrderNo}`;
    return this.http.request<Contractor[]>(GLOBAL.HTTP_GET, url).pipe(
      map((items: any[]) => items.map((item: any) => this.adapter.toContractorResponse(item)))
    );
  }
  public getSectionFirstLevel(plantCode: any, unitCode: any, departmentCode: any): Observable<any> {
    // currently there is not data for current user, so set the user id = 1

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SECTION_FIRST_LEVEL}?userId=${GLOBAL.USER_ID}&plantCode=${plantCode}
    &unitCode=${unitCode}&departmentCode=${departmentCode}`;
    console.log(url);
    return this.httpClient.get(url).pipe(map((response: any) => response.Data));
  }

  public getCompanies(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_COMPANY_DATA}?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=
    &State=&City=&Country=`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data.Table));
  }

  getLicenses(contractorCode: any) {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.http.get(url).pipe(map((response: any) => response.Data.Table));
  }

  getWCPolicy(contractorCode: any) {
    const url = `${GLOBAL.APIS.REPORTS.GET_WC_POLICY}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
                &Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=
                &Contractor_Code=${contractorCode}&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=`;
    return this.http.get(url).pipe(map((response: any) => response.Data.Table));
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS_SP}`;
    const payload = {
      "search": null,
      "Company": companyCode || 101,
      "State": null,
      "City": null,
      "Country": null,
      "UnitType": null,
      "selectedField": "Plant,Plant_Code,State_Code,State,SiteMILCode,SITE,Company_Code,IsESICCovered,ESICNumber",
      "OwnerShip": null,
      "LocatedAt": null,
      "IsESICApplicable": null,
      "WorkOrder": null,
      "SubWorkOrder": null,
      "UserID": GLOBAL.USER_ID,
      "RequireType": 0,
      "ActionType": 0
    }
    return this.http
      .post<any[]>(url, payload)
  }
  public getStates(): Observable<any[]> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_STATE}?Search=&CountryCode=`;
    console.log(url);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }
  public getUnits(plantCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?Search=&PlantCode=${plantCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getDesignations(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DESIGNATION}?Search=&companyCode=${companyCode}&GradeCode=&LevelCode=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getMasterItemByCode(code: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${code}&PageCode=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getDepartments(unitCode: any): Observable<any[]> {

    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS_FTR}?unitCode=${unitCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  public getDepartmentss(plantCode: any = '', unitCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENTS}?Search=&PlantCode=${plantCode}&UnitCode=${unitCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  // public getDepartmentPlantUnitSectionTree(plantCode: any, unitCode: any, departmentCode: any): Observable<any> {
  //   // currently there is not data for current user, so set the user id = 1
  //   // const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE}?userId=${GLOBAL.USER.LOGGED_IN_USER}`;
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TABLE}?userId=${GLOBAL.USER_ID}
  //   &plantCode=${plantCode}&unitCode=${unitCode}&departmentCode=${departmentCode}`;
  //   console.log('url', url);
  //   return this.http.get(url).pipe(map((response: any) => response.Data));
  // }
  getDepartmentPlantUnitSectionTree(plantCode: any, unitCode: any, departmentCode: any): Observable<any> {
    const model = {
      UserId: GLOBAL.USER_ID,
      PlantCode: plantCode,
      UnitCode: unitCode,
      DepartmentCode: departmentCode
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TABLE}`;
    return this.httpClient.post(url, model);
  }
  public getSections(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SECTION}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS_SP}`;
    const payload = {
      "Search": null,
      "RequiredFields": "NatureOfWork_Code,Name,",
      "RequireType": 0,
      "ActionType": 0,
      "UserId": GLOBAL.USER_ID
    }
    return this.http
      .post<any[]>(url, payload)
      .pipe(map((response: any) => response));
  }

  public getShiftTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTTYPES}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }
  public getShiftGroupTypes(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTGROUPTYPES}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }
  public getShifts(plantCode: any, shiftTypeGroupCode: any): Observable<any[]> {

    console.log('getShifts:plantCode', plantCode);
    console.log('getShifts:shiftTypeGroupCode', shiftTypeGroupCode);
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SHIFTS}?Search=&PlantCode=${plantCode}&ShiftTypeGroupCode=${shiftTypeGroupCode}`;
    console.log('getShifts:url', url);
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  public getSkills(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_SKILLS}?Search=&PlantCode=&ParentSkillCode=&TradeCode=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response));
  }

  public addFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.ADD_FILTER}?User_Code=${GLOBAL.USER_ID}
    &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
    return this.http.post(url, {}).pipe(
      map((response: any) => response));
  }

  public updateFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.UPDATE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${GLOBAL.USER_ID}
    &Page_Code=${data.Page_Code}&FilterName=${data.FilterName}&JSONTextDetails=${data.JSONTextDetails}`;
    return this.http.post(url, {}).pipe(
      map((response: any) => response));
  }

  public deleteFilter(data: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.DELETE_FILTER}?Report_Code=${data.Report_Code}&User_Code=${GLOBAL.USER_ID}`;
    return this.http.post(url, {}).pipe(
      map((response: any) => response));
  }

  public getALLSavedFilterDescripationUserWise(): Observable<any[]> {
    const url = `${GLOBAL.APIS.REPORTS.GET_FILTER}?User_Code=${GLOBAL.USER_ID}`;
    return this.http.get(url).pipe(map((response: any) => response
      .map((item: any) => this.adapter.parseJsonString(item))));
  }

  // getAllDepartmentTrainingData() {
  //   const queryString = $.param({
  //     UserID: GLOBAL.USER_ID,
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_TRAINING_DATA}?${queryString}`,
  //     httpOptions
  //   );
  // }

  public getAllDepartmentTrainingData(): Observable<any[]> {
    const queryString = $.param({
      UserID: GLOBAL.USER_ID,
    });
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_TRAINING_DATA}?${queryString}`;
    return this.http.get(url).pipe(map((response: any) => response.Data.Table
      .map((item: any) => this.adapter.parseJsonString(item))));
  }


  public getAllCGMPTtTrainingData(): Observable<any[]> {
    const data = {
      UserID: GLOBAL.USER_ID
    }
    const url = GLOBAL.APIS.LABOUR_MANAGEMENT.GET_CGMPT_TRAINING_DATA;
    return this.http.post(url, data).pipe(map((response: any) => response
      .map((item: any) => this.adapter.parseJsonString(item))));
  }
  // public getAllDepartmentTrainingData(): Observable<any[]> {
  //   const model = {
  //     // Search: '',
  //     UserID: GLOBAL.USER_ID,
  //   };
  //   return this.http.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_TRAINING_DATA}`, model).pipe(
  //     map((response: any) => response));
  // }

  // const queryStrig = $.param({
  // UserID: GLOBAL.USER_ID,
  // });
  // const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_TRAINING_DATA}?${queryString}`;
  // return this.http.get<any[]>(url).pipe(
  // map((response: any) => response.Data.Table));
  // }

  getDocumentTypes() {
    return this.http.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES);
  }
  // tslint:disable-next-line: function-name
  public SaveLabourTrainingDetail(data: any[]): Observable<any[]> {
    const model = {
      Contractorcode: '',
      SubcontractorCode: '',
      WorkOrdercode: '',
      SubWorkOrdercode: '',
      ULC: '',
      GatePassNo: '',
      TrainingtypeMIL_Code: '',
      SectionPlantID: '',
      UserID: '',
    };
    return this.http.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA}`, model).pipe(
      map((response: any) => response));
  }

  public getDeprtmentTrainingDataForFilter(data: any): Observable<any> {

    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_TRAINING_DATA_FOR_FILTER}`;
    return this.http.post<any>(url, data).pipe(
      map((response: any) => response.Data.Table));
  }

  // }
  // tslint:disable-next-line: function-name
  public SaveLabourTrainingWithIndividiauldocDetail(data: any[], files: any, params): Observable<any[]> {


    // this.files = files;

    // const fd = new FormData();
    // const filesToUpload: any = JSON.stringify(this.files);
    // fd.append(String(), filesToUpload, filesToUpload[0]['name']);
    // for (let index = 0; index <= this.files.length; index++) {
    //   const file = this.files[index],
    //      name = file[0].name;

    // }
    // tslint:disable-next-line: ter-arrow-parens
    // Array.from(this.files).forEach(file => {  fd.append('file', file[0][File]['name']); });
    // this.files.forEach((file) => { fd.append('file', this.files[0]['name']); });
    // Array.prototype.forEach.call(this.files, function (file) { fd.append('file', file[0]['name']); });
    // tslint:disable-next-line: ter-arrow-parens
    // const filesToUpload: any = this.getNotUploadedItems();
    // filesToUpload.forEach((fileDoc, index) => {
    //   fd.append(String(index), fileDoc['file'], fileDoc['file']['name']);
    //   // fd.append('Image_Type', `${String(fileDoc.documentTypeCode)},`);
    // });
    // fd.append('files[]', this.files);
    // fd.append(`file${String(index)}`, item, item[0]['name']);
    // const filesToUpload: any = this.files;
    // files.forEach((item: any, index: number) => {
    //   // if (this.files && this.files[0]) {
    //   fd.append(`file${String(index)}`, item, item[0]['name']);
    //   // } else {
    //   //   fd.append(`file${String(index)}`, '');
    //   // }
    // });
    // fd.append('Ischeck', `undefined,`);
    // for (const key in params) {
    //   if (params.hasOwnProperty(key)) {
    //     const param = params[key];
    //     fd.append(key, String(param));
    //   }
    // }

    // let headers = new HttpHeaders({
    //   'Content-Type': ''
    // });
    // headers = headers.delete('Content-Type');
    // const requestOptions = {
    //   headers,
    //   body: fd
    // };

    const formData = this.convertToFormData(data,files);
    //  const formData = new FormData();
    // formData.append('Data', JSON.stringify(data));
    // formData.append('file', JSON.stringify(this.files));
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.SAVE_LABOUR_TRAINING_WITH_INDIVIDUAL_DOC}`;
    return this.http.post(url, formData).pipe(
      map((response: any) => response));
  }

  public convertToFormData(requestBody: any[],files): FormData {
    const formData = new FormData();
    if (requestBody) {
      formData.append('Data', JSON.stringify(requestBody));
      files.forEach((element,index) => {
        formData.append(`file${String(index)}`, element[0], element[0]['name']);
      });
    }

    return formData;
  }

  public globalMasterSettingData(code: string) {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?Code=${code}`;
    return this.http.get(url).pipe(map((response: any) => response.Data.Table));
  }
  // getNotUploadedItems() {
  //   return this.files.filter((item: FileDoc) => !item.isUploaded);
  // }
}
