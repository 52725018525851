import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
declare var $: any;
import { Observable } from "rxjs";
import * as moment from "moment";
import { map } from "rxjs/operators";
import { GLOBAL, PLANT_UNIT_DEP_REQUEST } from "src/app/app.globals";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class AttendanceManualPunchService {
  httpClient: any;

  constructor(private http: HttpClient) {}

  getMasterlistData() {
    const model = {
      ShortCode: "",
      Search: "",
      PageCode: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}`,
      model
    );
  }
  // return this.http.get(`${GLOBAL.APIS.MASTER_DATA.
  // GET_MASTERITEMLIST}?ShortCode=&Search=&PageCode=`);
  // }

  /**
   * getSkillType
   * @author Sonal Prajapati
   */
  getSkillType() {
    const model = {
      Search: "",
      Plant_Code: "",
      ParentSkill_Code: "",
      Trade_Code: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES}`,
      model
    );
  }
  // tslint:disable-next-line: prefer-template
  //  return this.http.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES
  //  + '?Search=&PlantCode=&ParentSkillCode=&TradeCode=');
  // }

  /**
   * get All trades
   * @author Sonal Prajapati
   */
  // getAllTrade() {
  //   const model = {
  //     Search: '',
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.COMMON.GET_ALL_TRADE}`, model);
  // }
  // tslint:disable-next-line: prefer-template
  // return this.http.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_TRADE}?Search=`);
  // }

  public formatToServerDate(date) {
    let a = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    if (a === "Invalid date") {
      a = null;
    }
    return a;
  }

  // Import Labour HR data
  public importDataHr(data): Observable<any> {
    // const reqData = this.prepareReqData(data, userEmail);
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_IMPORT_LABOUR_HR}`,
      httpOptions
    );
  }

  public getPincodeDetails(pincodeList): Observable<any> {
    const model = {
      Search: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.MASTER_DATA.GET_PINCODE_DETAILS}`,
      model
    );
  }
  //  const pincodes = pincodeList.join(',');
  //  return this.http.get(`${GLOBAL.APIS.MASTER_DATA.GET_PINCODE_DETAILS}?zipcode=${pincodes}`);
  // }

  public getDepartmentPlantUnitSectionTree(): Observable<any> {
    const queryString = $.param({
      userId: GLOBAL.USER_ID,
    });
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA}?${queryString}`;
    return this.http.get(url).pipe(map((response: any) => response));
  }
  public getDepartmentPlantUnitSectionData(): Observable<any> {
    PLANT_UNIT_DEP_REQUEST.Filter.UserId = GLOBAL.USER_ID;
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA}`;
    return this.http.post(url, PLANT_UNIT_DEP_REQUEST);
  }
  public validateWorkOrderContractor(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_WORK_ORDER_CONTACTOR}`,
      httpOptions
    );
  }

  public validateLicenceContractor(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_LICENCE_CONTACTOR}`,
      httpOptions
    );
  }

  public validateECPolicyContractor(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_EC_POLICY_CONTACTOR}`,
      httpOptions
    );
  }

  public validateULC(list): Observable<any> {
    httpOptions["body"] = JSON.stringify(list);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_ULC}`,
      httpOptions
    );
  }

  public validateGatePass(list): Observable<any> {
    httpOptions["body"] = JSON.stringify(list);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS}`,
      httpOptions
    );
  }

  public validateGatePassULC(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS_ULC}`,
      httpOptions
    );
  }

  // Import Manual Attendance Data
  public importManualAttendance(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.IMPORT_MANUAL_ATTENDANCE_LIST}`,
      httpOptions
    );
  }
  // Import Manual Attendance Data
  public importManualAttendanceAndApprove(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.IMPORT_MANUAL_ATTENDANCE_AND_APPROVE_LIST}`,
      httpOptions
    );
  }
  public validateDateAndPinTime(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.VALIDATE_DATE_AND_PINTIME}`,
      httpOptions
    );
  }

  public validateDateAndPoutTime(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.VALIDATE_DATE_AND_POUTTIME}`,
      httpOptions
    );
  }
  public validateDateAndUinTime(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.VALIDATE_DATE_AND_UINTIME}`,
      httpOptions
    );
  }
  public validateDateAndUoutTime(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.VALIDATE_DATE_AND_UOUTTIME}`,
      httpOptions
    );
  }
}
