import {
  Component,
  OnInit,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  TemplateRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';

import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
// import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

import { SharedService } from 'src/app/core/services';
import { MasterItemCode, Contractor } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { ReportViewListComponent } from 'src/app/reports/daily-attendance/report-view-list/report-view-list.component';
import { ManPowerService } from '../man-power.service';
import { ManPowerPrsenter } from '../presenter/man-power-presenter';
import { contractorColumnDefsItem, workOrderColumnDefsitem } from '../utility/utility';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { GLOBAL } from 'src/app/app.globals';
import { OTApprovalListModalComponent } from './approval-list-modal/approval-list-modal.component';
import { forkJoin, Observable } from 'rxjs';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { getMinDateFromCode } from 'src/app/common/utils';
import { map } from 'rxjs/operators';

declare var $;
const dateFormat = 'DD-MM-YYYY';
const date1: Date = new Date();
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-ot-approval-list',
  templateUrl: './ot-approval-list.component.html',
  styleUrls: ['./ot-approval-list.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class ManpowerOTApprovalComponent implements OnInit {

  public get minDate$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG358FV_1', 'MOD115PG358DV_2')));
  }

  public config: any;
  public contractorColumnDefs: any[];
  public workOrderColumnDefs: any[];
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  public activeFilter: string;
  // shiftGroupTypeList: { Id: number; ShiftGroupName: string; }[];
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  /** This output event emmiter invoke when previous button trigger */
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onSubmitClick: EventEmitter<any> = new EventEmitter();
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  public savedFilters: any[] = [];
  public groupByList: any[] = [];
  public companyList: any[] = [];
  public shiftGroupTypeList: any[] = [];
  public plantList: any[] = [];
  public unitList: any[] = [];
  public plantLit: any[] = [];
  public departmentList: any[] = [];
  public sectionList: any[] = [];
  public companyNatureofWorkList: any[] = [];
  public shiftTypeList: any[] = [];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  public genderList: any[] = [];
  public skillList: any[] = [];
  public designationList: any[] = [];
  public qualificationList: any[] = [];
  public technicalQualificationList: any[] = [];
  public qualificationStreamList: any[] = [];
  public religionList: any[] = [];
  public castList: any[] = [];
  public jobTypeList: any[] = [];
  public labourNatureOfWorkList: any[] = [];
  public workMenTypeList: any[] = [];
  public shiftGroupByList: any[] = [];
  private reference: any;
  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  public alertText: string;
  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;
  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;
  private filterType: FilterTypeEnum;
  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  private contractorCode: number;
  private workOrderNo: number;
  public roleCode: any;

  public pagecode = 201;
  public minDate: any;

  constructor(
    private manPowerService: ManPowerService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private entryForThePrsenter: ManPowerPrsenter,
    public sharedService: SharedService,
    private logService: LogHelperService
  ) {

    this.formGroup = this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        dateRange: [null, Validators.required],
        Plant_Code: [null],
        Unit_Code: [null],
        Company_Code: [null]
      })
    });

    this.config = {
      contractor: { contractorColumnDefs: contractorColumnDefsItem, labelText: 'Contractor', placeholderText: 'Select Contractor' },
      workOrder: { workOrderColumnDefs: workOrderColumnDefsitem, labelText: 'Work Order', placeholderText: 'Select Work Order' },
    };
  }

  ngOnInit() {
    // this.createReference(this.entryForThePrsenter.buildForm());
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    // this.formGroup.get('JSONText').get('Date').setValue(date1);

    // this.shiftGroupTypeList = [{ Id: 1, ShiftGroupName: 'A' }, { Id: 0, ShiftGroupName: 'GEN' },
    //  { Id: 1, ShiftGroupName: 'B' }, { Id: 0, ShiftGroupName: 'C' }];
    this.getAllData();
    // this.getALLSavedFilterDescripationUserWise();
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.roleCode = GLOBAL.USER.ROLE_CODE;
    // this.manageVisibilityOfSteps();
    const userId = GLOBAL.USER_ID;
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (userId && roleCode === 119) {

      this.getContractorsByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
    // this.formGroup.get('JSONText').get('Date').setValue(new Date(2019, 1, 1));
  }

  public onPlantChange(): void {
    this.unitList = [];
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (plantCode) {
      this.getUnits(plantCode);
    }
  }

  private getAllData() {
    this.getCompany();
  }

  public filterSubmit(): void {
  }

  getCompany() {
    //
    this.manPowerService.getCompanies().subscribe((response: any[]) => {
      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }

  getShifttypegroup() {

    this.manPowerService.getShiftTypeGroups().subscribe((response: any[]) => {
      //
      this.shiftGroupTypeList = response;
      this.formGroup.get('JSONText').get('ShiftTypeGroup_Code').setValue(this.selectedFilterData.JSONText.ShiftTypeGroup_Code);
      // if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
      //   this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      //   this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      // }
    });
  }

  getContractors() {

    this.manPowerService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.Plant_Code);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }
  // getContractors() {
  //   this.entryfordayService.getContractors().subscribe((response: Contractor[]) => {
  //     this.contractors = response;
  //     this.contractors2 = response;
  //     if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
  //       this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
  //       this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.Plant_Code);
  //       this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
  //       this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
  //     }
  //   });
  // }

  // getContractorsByDefault() {
  //   this.manPowerService.getContractorsbydefault().subscribe((response: Contractor[]) => {
  //     this.contractors = response;
  //     this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].ContractorVendor_Code);
  //     if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
  //       this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
  //       this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
  //       this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
  //     }
  //   });
  // }

  getContractorsByDefault(userId: any, roleCode: any) {

    this.manPowerService.getContractorsbydefault(userId, roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      // this.contractors2 = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].ContractorVendor_Code);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getLicensesByContractorCode(contractorCode: any): void {
    this.manPowerService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.contractorLicenseList = response;
      this.formGroup.get('JSONText').get('License_Code').setValue(this.selectedFilterData.JSONText.License_Code);
    });
  }

  getWCPolicyByContractorCode(contractorCode: any): void {
    this.manPowerService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.contractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('WCPolicy').setValue(this.selectedFilterData.JSONText.WCPolicy);
    });
  }

  getPlants(companyCode: any): void {
    //
    this.manPowerService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.plantList[0].Company_Code);
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
      if (this.selectedFilterData.JSONText.Plant_Code) {
        this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
      }
    });
  }
  // getPlants(companyCode: any): void {
  //   this.entryfordayService.getPlants(companyCode).subscribe((response: any[]) => {
  //     this.plantList = response;
  //     this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
  //     if (this.selectedFilterData.JSONText.Plant_Code) {
  //       this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
  //     }
  //   });
  // }

  // getPlants(companyCode: any): void {
  //   //
  //   this.manPowerService.getPlants(companyCode, '').subscribe((response: any[]) => {
  //     this.plantList = response;
  //     this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
  //     if (this.selectedFilterData.JSONText.Plant_Code) {
  //       this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
  //     }
  //   });
  // }

  getUnits(plantCode: any): void {
    this.manPowerService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get('Unit_Code').setValue(this.selectedFilterData.JSONText.Unit_Code);
    });
  }

  getDepartments(plantCode: any = '', unitCode: any = ''): void {
    this.manPowerService.getDepartments(plantCode, unitCode).subscribe((response: any[]) => {
      this.departmentList = response;
      this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
    });
  }

  getDesignations(companyCode: string): void {
    this.manPowerService.getDesignations(companyCode).subscribe((response: any[]) => {
      this.designationList = response;
      this.formGroup.get('JSONText').get('Trade_Code').setValue(this.selectedFilterData.JSONText.Trade_Code);
    });
  }

  getGender(): void {
    this.manPowerService.getMasterItemByCode(MasterItemCode.GENDER).subscribe((response: any[]) => {
      this.genderList = response;
      this.formGroup.get('JSONText').get('Gender').setValue(this.selectedFilterData.JSONText.Gender);
    });
  }

  getShiftGroupBy(search: string): void {
    this.manPowerService.getShiftGroupby(search).subscribe((response: any[]) => {
      this.shiftGroupByList = response;
      this.formGroup.get('JSONText').get('shiftGroup').setValue(this.selectedFilterData.JSONText.shiftGroup);
    });
  }

  getSkill(): void {
    this.manPowerService.getSkills().subscribe((response: any[]) => {
      this.skillList = response;
      this.formGroup.get('JSONText').get('Skill_Code').setValue(this.selectedFilterData.JSONText.Skill_Code);
    });
  }

  getQualifications(): void {
    this.manPowerService.getMasterItemByCode(MasterItemCode.QUALIFICATION).subscribe((response: any[]) => {
      this.qualificationList = response;
      this.formGroup.get('JSONText').get('QualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationMasterList_Code);
    });
  }

  getTechnicalQualifications(): void {
    this.manPowerService.getMasterItemByCode(MasterItemCode.TECHNICALQUALIFICATION).subscribe((response: any[]) => {
      this.technicalQualificationList = response;
      this.formGroup.get('JSONText').get('TechQualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.TechQualificationMasterList_Code);
    });
  }

  getQualificationStreams(): void {
    this.manPowerService.getMasterItemByCode(MasterItemCode.QUALIFICATIONSTREAM).subscribe((response: any[]) => {
      this.qualificationStreamList = response;
      this.formGroup.get('JSONText').get('QualificationStreamMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationStreamMasterList_Code);
    });
  }

  getReligions(): void {
    this.manPowerService.getMasterItemByCode(MasterItemCode.RELIGION).subscribe((response: any[]) => {
      this.religionList = response;
      this.formGroup.get('JSONText').get('Religion_Code').setValue(this.selectedFilterData.JSONText.Religion_Code);
    });
  }

  getCasts(): void {
    this.manPowerService.getMasterItemByCode(MasterItemCode.CASTS).subscribe((response: any[]) => {
      this.castList = response;
      this.formGroup.get('JSONText').get('Cast_Code').setValue(this.selectedFilterData.JSONText.Cast_Code);
    });
  }

  getSections(): void {
    this.manPowerService.getSections().subscribe((response: any[]) => {
      this.sectionList = response;
      this.formGroup.get('JSONText').get('Section_Code').setValue(this.selectedFilterData.JSONText.Section_Code);
    });
  }

  getShiftTypes(): void {
    this.manPowerService.getShiftTypes().subscribe((response: any[]) => {
      // this.shiftGroupTypeList = response;
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(this.selectedFilterData.JSONText.ShiftGroupType_Code);
    });
  }

  getShifts(): void {
    this.manPowerService.getShifts().subscribe((response: any[]) => {
      this.shiftTypeList = response;
      // this.formGroup.get('JSONText').get('QualificationStreamMasterList_Code').
      // setValue(this.selectedFilterData.JSONText.QualificationStreamMasterList_Code);
    });
  }

  getNatureOfWorks(): void {
    this.manPowerService.getNatureOfWorks().subscribe((response: any[]) => {
      this.companyNatureofWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  getWorkManNatureOfWorks(): void {
    this.manPowerService.getNatureOfWorks().subscribe((response: any[]) => {
      this.labourNatureOfWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
      {
        value: '1',
        label: 'Contractor PO Wise'
      },
      {
        value: '1',
        label: 'Contractor and Department Wise'
      },
      {
        value: '1',
        label: 'Contractor, PO and Department Wise'
      }
    ];
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    // this.formGroup.get('JSONText').get('subContractorCode').setValue(null);
    // this.formGroup.get('JSONText').get('subContractorWorkOrderNo').setValue(null);
    // // this.workOrders = [];
    // this.subContractors = [];
    // this.subContractorWorkOrders = [];
    if (companyCode) {
      this.getPlants(companyCode);
      this.getDesignations(companyCode);
    }
  }

  onContractorChange() {
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('License_Code').setValue(null);
    this.formGroup.get('JSONText').get('WCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      this.getLicensesByContractorCode(contractorCode);
      this.getWCPolicyByContractorCode(contractorCode);
    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get('JSONText').get('WorkOrder_Code').value;
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
    }
  }

  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  public onContractorSaveEmitter(data: any) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  // public onSubContractorChange() {
  //   const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
  //   this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
  //   this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
  //   this.subContractorWorkOrders = [];
  //   if (subContractorCode) {
  //     this.getSubWorkOrdersBySubContractorCode(subContractorCode);
  //     this.getWCPolicyBySubContractorCode(subContractorCode);
  //     this.getLicensesBySubContractorCode(subContractorCode);
  //   }
  // }

  public onSubContractorChange() {
    const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.subContractorWorkOrders = [];
    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantcode);
      this.getWCPolicyBySubContractorCode(subContractorCode);
      this.getLicensesBySubContractorCode(subContractorCode);
    }
  }

  public onSubContractorSaveEmitter(data: any) {
    console.log('saved data', data);
    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  // getSubWorkOrderListEmitter(contractorCode: number) {
  //   if (isNullOrUndefined(this.reportFilterData)) {
  //     // this.reportFilterData = new ReportFilterModel();
  //   }
  //   this.reportFilterData.contractorCode = contractorCode;
  //   if (contractorCode) {
  //     this.getWorkOrdersByContractorCode(contractorCode);
  //   }
  // }

  getSubWorkOrderListEmitter(contractorCode: number, plantcode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
    }
  }

  public onSaveFilter(): void {
    // console.log('form data', this.formGroup.getRawValue());
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();
    this.openModal();
  }

  // Creates a reference of your initial value
  private createReference(obj: any) {
    this.reference = obj.get('JSONText');
  }

  /*** Here Question why this create open modal */
  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue().JSONText;
    if (!this.formGroup.valid) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please first fill up mandatory field.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
      return;
    }

    const requestObj = {
      StartDate: moment(formValue.dateRange[0]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      EndDate: moment(formValue.dateRange[1]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      Plant_Code: formValue.Plant_Code || null,
      // Unit_Code: formValue.Unit_Code || null,
      UserID: GLOBAL.USER_ID
    };

    const otApproval$ = this.manPowerService.getOTApprovalData(requestObj);
    const shortCodeObj = {
      UserId: GLOBAL.USER_ID,
      PlantCode: null,
      UnitCode: null,
      DepartmentCode: null
    };
    const shortCode$ = this.manPowerService.getShortCodeList(shortCodeObj);
    const requestLevelRequestCode = {
      RequestType: 116,
      Priority: 1,
      PlantCode: null,
      UserID: GLOBAL.USER_ID
    };

    const requestLevels$ = this.manPowerService.getRequestLevels(requestLevelRequestCode);
    forkJoin([otApproval$, shortCode$, requestLevels$]).subscribe(([otApproval, shortCode, requestLevels]) => {
      if (otApproval && otApproval.length > 0) {
        this.openReportViewModal(otApproval, shortCode, requestLevels);
      } else {
        this.logService.logError({
          message: 'No data found'
        });
      }
    });
  }

  openReportViewModal(response: any[], shortCode: any[], requestLevels: any[]): void {
    const modalRef = this.modalService.open(OTApprovalListModalComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel modal-body-scroll' });
    modalRef.componentInstance.requestLevels = requestLevels;
    modalRef.componentInstance.shortCodeList = shortCode;
    modalRef.componentInstance.reportData = this.updateOTApprovalList(response);;

    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.showAlert.subscribe((value: string) => {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>${value}</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    });

    modalRef.componentInstance.selectedItems.subscribe((items: any[]) => {
      console.log('items', items);
      const list = this.convertToRequestList(items);
      this.manPowerService.saveOTApprovalData(list).subscribe((response: any) => {
        if (response) {
          this.alertOption = {
            title: '',
            html: `<span class='customClass'>Data is saved successfully.</span>`,
            type: 'success'
          };
          setTimeout(() => {
            this.openAlert();
          }, 10);
          modalRef.close('');
        }
      });
    });
  }

  private convertToRequestList(data: any[]): any[] {
    const list: any[] = []
    data.forEach(({ Plant_Code, LabourMovement_Code, SectionPlant_ID, LabourAttResult_Code, RequestLevel_Code, Request_No, IsRejected }) => {
      if (!Request_No || IsRejected) {
        const dataObj = {
          Request_No: null,
          Plant_Code,
          LabourMovement_Code,
          SectionPlant_ID,
          LabourAttResult_Code,
          IsFinalApproval: false,
          IsRejected: false,
          RequestLevel_Code,
          RequestType_Code: 116,
          Comment: null,
          RequestDate: moment().format(this.sharedService.dateOnlyPickerSettings.requestFormat),
          CreateBy: GLOBAL.USER_ID,
          ModifiedBy: GLOBAL.USER_ID,
          StatusMasterList_Code: 637
        };

        list.push(dataObj);
      }
    });

    return list;
  }

  private updateOTApprovalList(response: any[]): any[] {
    return response.map(item => {
      return {
        ...item,
        isDisabled: item.RequestNo && !item.IsRejected
      }
    })
  }

  public onFilterTab(filterObj: any): void {
    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0)) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please first fullfill data in contractor.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
      return;
    }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  /**
   * @descriptions Invoke this method when click on expire list.
   */
  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

  public onAddfilter(): void {
    console.log('form data open modal', this.formGroup.getRawValue());
    if (this.formGroup.valid) {
      const value = this.formGroup.getRawValue();
      const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
      const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
        && item.Report_Code !== this.selectedFilterData.Report_Code);
      if (!filterTypeObj) {
        selectedFilterDataObj.id = this.selectedTabId;
        const data = {
          Report_Code: this.selectedFilterData.Report_Code || '',
          Page_Code: 5,
          FilterName: value.FilterName,
          JSONTextDetails: JSON.stringify(selectedFilterDataObj)
        };
        if (this.selectedFilterData.Report_Code) {
          this.manPowerService.updateFilter(data).subscribe((response: any) => {
            if (response.Status) {
              this.getALLSavedFilterDescripationUserWise(this.pagecode);
              this.modalRef.close();
              this.clearData();
            }
          });
        } else {
          this.manPowerService.addFilter(data).subscribe((response: any) => {
            if (response.Status) {
              this.getALLSavedFilterDescripationUserWise(this.pagecode);
              this.modalRef.close();
              this.clearData();
            }
          });
        }
      } else {
        alert('This filter is already exist.');
      }
    }
  }

  public deleteFilterItem(filterObj: any): void {
    this.manPowerService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(filterObj);
    });
  }

  private getALLSavedFilterDescripationUserWise(filterObj: any) {
    this.manPowerService.getALLSavedFilterDescripationUserWise(filterObj).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();
    // this.reportFilterData = null;
    // this.selectedFilterData = null;

    // this.contractorCode = null;
    // this.workOrderNo = null;
    // this.subContractors = [];
    // this.workOrders = [];
    // // this.contractors=[];
    // setTimeout(() => {
    //   this.contractors = [...this.contractors];
    // }, 100);

    // this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onChangeShiftData(): void {

  }

  public onFilterNameClick(filterObj: any) {
    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.entryForThePrsenter.bindForm(this.formGroup, filterObj);
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  // private getWorkOrdersByContractorCode(contractorCode: any): void {
  //   this.manPowerService.getWorkOrdersByContractorCode(contractorCode).subscribe((response: any[]) => {
  //     this.contractorWorkOrders = response;
  //     if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
  //       this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
  //       if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
  //         this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
  //       }
  //     }
  //   });
  // }

  private getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.manPowerService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
          this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
        }
      }
    });
  }

  // private getSubWorkOrdersBySubContractorCode(contractorCode: any): void {
  //   this.manPowerService.getWorkOrdersByContractorCode(contractorCode).subscribe((response: any[]) => {
  //     this.subContractorWorkOrders = response;
  //     if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
  //       this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
  //     }
  //   });
  // }

  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.manPowerService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
      }
    });
  }

  getWCPolicyBySubContractorCode(contractorCode: any): void {
    this.manPowerService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.subContractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('SubWCPolicy').setValue(this.selectedFilterData.JSONText.SubWCPolicy);
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.manPowerService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subContractorLicenseList = response;
      this.formGroup.get('JSONText').get('SubLicense_Code').setValue(this.selectedFilterData.JSONText.SubLicense_Code);
    });
  }

  // private getSubContractors(workOrderNo: any = ''): void {
  //   // this.manPowerService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
  //   //   this.subContractors = response;
  //   // });
  //   this.manPowerService.getContractors('', workOrderNo).subscribe((response: any[]) => {
  //     this.subContractors = response;
  //     this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
  //     if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
  //       this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
  //       this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
  //       this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
  //     }
  //   });
  // }

  private getSubContractors(workOrderNo: any = ''): void {
    // this.entryfordayService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
    //   this.subContractors = response;
    // });
    this.manPowerService.getContractors('', workOrderNo).subscribe((response: any[]) => {
      this.subContractors = response;
      this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
        this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
          this.selectedFilterData.JSONText.Plant_Code);
        this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
      }
    });
  }

  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      }
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }

}
