import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../core/components/select-steps/select-steps-model/select-steps.model';
import {
  AgNumberCountComponent,
  AgActionCellRendererComponent,
  SelectStepsComponent,
} from '../../core/components';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { CompanyCommonsMasterService } from 'src/app/company-commons-master/company-commons-master.service';
import { GLOBAL } from 'src/app/app.globals';
declare var $;
const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'HH:MM';
// const date1: Date = new Date();

export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: timeFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-associate-accident-details',
  templateUrl: './associate-accident-details.component.html',
  styleUrls: ['./associate-accident-details.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }],
})
export class AssociateAccidentDetailsComponent implements OnInit {
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('agGrid') agGrid: any;
  accidentData: any;

  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public modalRef: any;
  public gridAPI: GridApi;
  public numberOfRowsPerPage = 10;
  private selectedFilterData: any = { JSONText: {} };
  /// public shiftMasterForm: FormGroup;
  accidentDetailForm = new FormGroup({
    // Accident_Code: new FormControl(''),
    DateOfNotice: new FormControl(''),
    TimeOfNotice: new FormControl(''),
    Contractors: new FormControl(''),
    ULC: new FormControl(''),
    // GatePassNo: new FormControl(''),
    AccidentPlace: new FormControl(''),
    AccidentDateTime: new FormControl(''),
    CauseOfInjury: new FormControl(''),
    NatureOfInjury: new FormControl(''),
    InjuryPersonName: new FormControl(''),
    PersonName: new FormControl(''),
    Address: new FormControl(''),
    Occupation: new FormControl(''),
    EntryPersonName: new FormControl(''),
    EntryPersonAddress: new FormControl(''),
    Designation: new FormControl(''),
    WitnessName1: new FormControl(''),
    WitnessAddress1: new FormControl(''),
    WitnessOccupation1: new FormControl(''),
    WitnessName2: new FormControl(''),
    WitnessAddress2: new FormControl(''),
    WitnessOccupation2: new FormControl(''),
    Remarks: new FormControl(''),
  });
  masterData = [];
  public contractorlist: any[] = [];
  public companyData: any[] = [];
  editaccidentCode1: string;
  public countries: any[] = [];
  public empList: any[] = [];
  public districts: any[] = [];
  public cities: any[] = [];
  public pPos: any[] = [];
  statementType: any;
  show = false;
  editMode = false;
  plantName: any;
  shortCode: any;
  shortname: any;
  name: any;
  save = false;
  update = false;

  columnDefs = [
    {
      headerName: '#', field: '', width: 10,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent,
      cellStyle: { textAlign: 'center' },
    },

    {
      headerName: 'Accident Code', field: 'Accident_Code', width: 40, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Code', field: 'Code', width: 30, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Associate Name', field: 'FullName', width: 60, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Date Of Notice', field: 'DateNotice', width: 40, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Time Of Notice', field: 'TimeOfNotice', width: 30, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Injury Person Name', field: 'InjuryPersonName', width: 50, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Accident Date Time', field: 'AccidentDateTime', width: 40, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
    {
      headerName: 'Entry Person Name', field: 'EntryPersonName', width: 50, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },cellStyle: { textAlign: 'center' },
    },
   
    {
      headerName: 'Action', field: '', width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent,
      cellStyle: { textAlign: 'center' },
    }
  ];

  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };
  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };


  constructor(private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private logHelperService: LogHelperService,
    private activatedRoute: ActivatedRoute,
    public sharedDataService: SharedDataService,
    private modalService: NgbModal, private service: CompanyCommonsMasterService) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

  }
  // tslint:disable-next-line: function-name
  IfCheck($event) {

    if ($event.target.checked === true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  ngOnInit() {
    this.associateAccidentData();
    this.getContractors();
    this.getEmployee();
  }


  associateAccidentData() {
    const model = {
      UserId: GLOBAL.USER_ID,
      Accident_Code: '',
      DateOfNotice: '',
      TimeOfNotice: '',
      Contractor_Code: '',
      ULC: '',
      GatePassNo: '',
      AccidentPlace: '',
      AccidentDateTime: '',
      CauseOfInjury: '',
      NatureOfInjury: '',
      InjuryPersonName: '',
      PersonName: '',
      Address: '',
      Occupation: '',
      EntryPersonName: '',
      EntryPersonAddress: '',
      Designation: '',
      WitnessName1: '',
      WitnessAddress1: '',
      WitnessOccupation1: '',
      WitnessName2: '',
      WitnessAddress2: '',
      WitnessOccupation2: '',
      Remarks: '',
      StatementType: 'Select'
    };

    this.service.associateGetInsertUpdate(model).subscribe((response: any[]) => {

      // return this.service.getUnitwiseDetails().subscribe((response: any) => {
      this.accidentData = response;

      console.log('xyz', (response));

    });

  }

 public openModal(): void {
    this.modalRef = this.modalService.open(this.tempRef,
       { size: 'lg', backdrop: 'static' });
    if (this.editaccidentCode1) {
      this.save = true;
      this.update = false;
    } else {
      this.save = false;
      this.update = false;
    }

  }

  public openModal1(): void {
    this.modalRef = this.modalService.open(this.tempRef,
       { size: 'lg', backdrop: 'static' });
    if (this.editaccidentCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.save = false;
      this.update = false;
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
    this.save = false;
    this.update = false;
  }
  private clearData() {
    this.accidentDetailForm.reset();
  }
  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
    this.save = true;
    this.update = false;
  }

  // getContractors() {
  //   this.service.getContractorsList(.subscribe((response: any) => {
  //     this.contractorlist = response;
  //   });
  // }
  public getContractors(): void {

    this.service.getContractorsList().subscribe((response: any) => {
      this.contractorlist = response;
      // value.Data.Table.forEach((element: any, index: number) => {
      //   this.this.companyMasterData = value.Data.Table;.push({ id: index, ShiftGroup: element.ShiftGroup });
      // });
    });
  }

 onContractorChange() {

    this.empList = [];

    const contractorCode = this.accidentDetailForm.controls['Contractors'].value;
    if (contractorCode && contractorCode.length === 1) {
      this.getEmployee();
    } else {
      this.getEmployee();
    }
  }

  // getEmployee() {
  //   this.service.getContractorwiseLabours(this.accidentDetailForm.controls['Contractors'].value || 100)
  //     .subscribe((response: any) => {
  //       this.empList = response;
  //     });
  // }

  
  getEmployee() {
    const formValue = this.accidentDetailForm.getRawValue();
   
    let contCode = '';

    if (formValue.Contractors) {
      contCode = formValue.Contractors;
    } else {
      contCode = null;
    }


    this.service.getContractorwiseLabours({
      //UserId: GLOBAL.USER_ID,
      contractors: contCode,
      //subContractors: subcontCode,
    }).subscribe((response: any[]) => {

      if (response && response.length > 0) {
        this.empList = response;
      } else {
      }
    
    });

  }
 

  public saveAndNew(): void {
    const value = this.accidentDetailForm.getRawValue();
    const selectedFilterDataObj = this.editaccidentCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    const data = {
      Accident_Code: this.editaccidentCode1,
      DateOfNotice: value.DateOfNotice,
      TimeOfNotice: value.TimeOfNotice,
      Contractor_Code: value.Contractors,
      ULC: value.ULC,
      //GatePassNo: value.GatePassNo,
      AccidentPlace: value.AccidentPlace,
      AccidentDateTime: value.AccidentDateTime,
      CauseOfInjury: value.CauseOfInjury,
      NatureOfInjury: value.NatureOfInjury,
      InjuryPersonName: value.InjuryPersonName,
      PersonName: value.PersonName,
      Address: value.Address,
      Occupation: value.Occupation,
      EntryPersonName: value.EntryPersonName,
      EntryPersonAddress: value.EntryPersonAddress,
      Designation: value.Designation,
      WitnessName1: value.WitnessName1,
      WitnessAddress1: value.WitnessAddress1,
      WitnessOccupation1: value.WitnessOccupation1,
      WitnessName2: value.WitnessName2,
      WitnessAddress2: value.WitnessAddress2,
      WitnessOccupation2: value.WitnessOccupation2,
      Remarks: value.Remarks,
      StatementType: this.statementType,
      UserID: GLOBAL.USER_ID,
    };
    if (selectedFilterDataObj)
    // tslint:disable-next-line: brace-style
    {
      this.service.UpdateAssociateAccidentData(data).subscribe((response: any) => {
        if (response) {
          this.logHelperService.logSuccess({
            message: 'Updated successfully'
          });
          this.modalRef.close();
          this.clearData();
          // this.update = false;
          this.associateAccidentData();
        }
      });
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.service.addAssociateAccidentData(data).subscribe((response: any) => {
        if (response) {
          this.logHelperService.logSuccess({
            message: 'added successfully'
          });
          this.modalRef.close();
          this.clearData();
         // this.save = false;
          this.associateAccidentData();
        }
      });
    }
  }
  //   } else {
  //     this.logHelperService.logError({
  //       message: 'Please Fill Mandotary Fields'
  //     });
  //   }
  // }

  public openAddEditUnitModal($event): void {
    // debugger;
    this.editaccidentCode1 = $event.Accident_Code;

    if (this.editaccidentCode1) {

      this.openModal1();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getAccidentProfile(this.editaccidentCode1).subscribe((response: any) => {

        const shift = response;
        if (!shift) {
          return;
        }
        this.accidentDetailForm.controls['DateOfNotice'].setValue(shift[0].DateOfNotice);
        this.accidentDetailForm.controls['TimeOfNotice'].setValue(shift[0].TimeOfNotice);
        this.accidentDetailForm.controls['Contractors'].setValue(shift[0].Contractor_Code);
        this.accidentDetailForm.controls['ULC'].setValue(shift[0].ULC);
        this.accidentDetailForm.controls['AccidentPlace'].setValue(shift[0].AccidentPlace);
        this.accidentDetailForm.controls['AccidentDateTime'].setValue(shift[0].AccidentDateTime);
        this.accidentDetailForm.controls['CauseOfInjury'].setValue(shift[0].CauseOfInjury);
        this.accidentDetailForm.controls['NatureOfInjury'].setValue(shift[0].NatureOfInjury);
        this.accidentDetailForm.controls['InjuryPersonName'].setValue(shift[0].InjuryPersonName);
        this.accidentDetailForm.controls['PersonName'].setValue(shift[0].PersonName);
        this.accidentDetailForm.controls['Address'].setValue(shift[0].Address);
        this.accidentDetailForm.controls['Occupation'].setValue(shift[0].Occupation);
        this.accidentDetailForm.controls['EntryPersonName'].setValue(shift[0].EntryPersonName);
        this.accidentDetailForm.controls['EntryPersonAddress'].setValue(shift[0].EntryPersonAddress);
        this.accidentDetailForm.controls['Designation'].setValue(shift[0].Designation);
        this.accidentDetailForm.controls['WitnessName1'].setValue(shift[0].WitnessName1);
        this.accidentDetailForm.controls['WitnessAddress1'].setValue(shift[0].WitnessAddress1);
        this.accidentDetailForm.controls['WitnessOccupation1'].setValue(shift[0].WitnessOccupation1);
        this.accidentDetailForm.controls['WitnessName2'].setValue(shift[0].WitnessName2);
        this.accidentDetailForm.controls['WitnessAddress2'].setValue(shift[0].WitnessAddress2);
        this.accidentDetailForm.controls['WitnessOccupation2'].setValue(shift[0].WitnessOccupation2);
        this.accidentDetailForm.controls['Remarks'].setValue(shift[0].Remarks);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  // tslint:disable-next-line: function-name

}


