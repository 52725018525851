import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
declare var $: any;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractorAdapter } from '../contractor-adapter/contractor.adpater';
import { CommonService } from 'src/app/common/common.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class LicencesService {
  constructor(private http: HttpClient, private commonService:CommonService,
    private contractorAdapter: ContractorAdapter) { }

  /**
   * @description used to get all the work order.
   * @author Sonal Prajapati
   * @param contractorType a number type of the contractor.
   */
  getLicenses(contractorType: number) {
    const queryString = $.param({
      userid: GLOBAL.USER_ID,
      // tslint:disable-next-line: object-shorthand-properties-first
      contractorType
    });

    let payload = {
      search: null,
      sDate: null,
      eDate: null,
      selectedField: null,
      contractor_Code: null,
      UserId: GLOBAL.USER_ID,
      // RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      Plant_Code : null,
      filterType: null,
      condition: null,
      State_Code: null,
      Status_Code: null,
      IsActive: null,
      NatureOfLicense_Code: null,
      NatureOfWork_Code: null,
      TotalAccupied: null,
      Contractor_Code: null,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      isContractorType: contractorType,
    }
    // const url = `${GLOBAL.APIS.LICENSE.GET_LICENSE}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
    //             &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
    //             &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=
    //             ${contractorType}&UserId=${userId}`;
    // const url = `${GLOBAL.APIS.LICENSE.SP_LISTING_LICENSE_FILTER}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
    // &Status_Code=&Plant_Code=&IsActive=&NatureOfLicense_Code=&NatureOfWork_Code=&TotalAccupied=
    // &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=
    // ${contractorType}&UserId=${userId}`;

    httpOptions['body'] = payload;
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LICENSE.SP_LISTING_LICENSE_FILTER}`,
      httpOptions
    );
    // return this.http.request(
    //   GLOBAL.HTTP_POST,
    //   url,
    //   httpOptions
    // ).pipe(map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getCompanies(): Observable<any[]> {

    const url = `${GLOBAL.APIS.MASTER_DATA.SP_GET_COMPANY_DATA}`;
    const payload = {
      "search": null,
      "StatusofIndustry": null,
      "isActive": null,
      "sDate": null,
      "eDate": null,
      "EntityType": null,
      "LegalStatus": null,
      "State": null,
      "City ": null,
      "Country": null,
      "RequireType": 0,
      "ActionType": 0,
      "UserId": GLOBAL.USER_ID
    }
    return this.http.post<any[]>(url,payload);
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS_SP}`;
    const payload = {
      "search": null,
      "Company": companyCode,
      "State": null,
      "City": null,
      "Country": null,
      "UnitType": null,
      "selectedField": "Plant,Plant_Code,State_Code,State,SiteMILCode,SITE,Company_Code,IsESICCovered,ESICNumber",
      "OwnerShip": null,
      "LocatedAt": null,
      "IsESICApplicable": null,
      "WorkOrder": null,
      "SubWorkOrder": null,
      "UserID": GLOBAL.USER_ID,
      "RequireType": 0,
      "ActionType": 0
    }
    return this.http
      .post<any[]>(url, payload)
  }
  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    // tslint:disable-next-line: max-line-length
    const url = `${GLOBAL.APIS.LICENSE.GET_CONTRACTORSBY_DEFAULT_SP}`;
    const payload = {
      "RequireType": 0,
      "ActionType": 0,
      "UserId": userId || GLOBAL.USER_ID
    }
    return this.http.post<any[]>(url, payload);
  }
  public getState(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_STATE}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getDocumentTypes() {
    const url = `${GLOBAL.APIS.LICENSE.GET_DOCUMENT_TYPE}`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data));
  }
  public getDocumentTypesPageModuleWise(data) {
    return this.http.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE_V1}`, data);
  }

  public checkLicenseNoAvailability(licenseNo: string, id: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.LICENSE.Check_LicenseNo_Availability}?LicenseNo=${licenseNo}&LicenseCode=${id}`;
    return this.http.get<any[]>(url);
  }

  fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = (reader.result as string).split(',')[1]; // Remove data URI prefix
        resolve(base64String);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file); // Read file as Base64
    });
  };

  public addLicense(licenseObj: any) {
    const payload = {
      "055D8F4B-D2F5-4483-803A-962B4F2CEEC6": { 
        License_Code: "(SELECT @Id)",
        ContractorVendor_Code: licenseObj.ContractorVendor_Code,
        ParentContractorVendor_Code: licenseObj.ParentContractorVendor_Code,
        LicenseNo: licenseObj.LicenseNo,
        LinNumber: licenseObj.LinNumber,
        LicenseTypeMasterList_Code: licenseObj.LicenseTypeMasterList_Code,
        Person: licenseObj.Person,
        StartDate: licenseObj.StartDate,
        EndDate: licenseObj.EndDate ? licenseObj.EndDate._d : null,
        RenewalDate: null,
        ExtensionDate: licenseObj.ExtensionDate ? licenseObj.ExtensionDate._d : null,
        Comment: licenseObj.Comment,
        VerifyDate: null,
        IsVerify: !!licenseObj.Islicenseverified,
        VerifyBy: null,
        SatusMasterList_Code: null,
        Version: 0,
        ParentRefLicense_Code: null,
        CreateBy: licenseObj.CreateBy ? licenseObj.CreateBy : null,
        ModifiedBy: licenseObj.CreateBy ? licenseObj.CreateBy : null,
        IsApproved: 0,
        IsActionTaken: 0,
        //LicenseNatureOfWorkMasterRelation
        "8B5A9A65-010F-458D-A400-9B5D6C449D72": licenseObj.NatureOfWorkList.map((e) => {
          return {

            "ID": "(SELECT @Id)", //Auto Set
            "Sys_Code": null, //Auto Set
            "License_Code": null, //Auto Set
            "NatureOfWork_Code": e,
            "CreateBy": licenseObj.CreateBy ? licenseObj.CreateBy : null,
            "ModifiedBy": licenseObj.CreateBy ? licenseObj.CreateBy : null,
          }
        }),
        //work order
        "A1566D61-0B04-4D65-8DE6-895DC89F6E8D": {
          "ID": "(SELECT @Id)", //Auto Set
          "Sys_Code": null, //Auto Set
          "License_Code": null, //Auto Set
          "ParentWorkOrder_Code": licenseObj.ParentWorkOrderCode,
          "ChildWorkOrder_Code": licenseObj.ChildWorkOrderCode,
          "Plant_Code": licenseObj.Plants,
          "CreateBy": licenseObj.CreateBy ? licenseObj.CreateBy : null,
          "ModifiedBy": licenseObj.CreateBy ? licenseObj.CreateBy : null,
        },
        //Documents
        "4E3A518F-CAF3-4938-A2B0-7C9D2660F85A": licenseObj.filesToUpload.map((fileDoc) => {
          return {
            "ID": null, // Auto Set
            "Referance_Code": null, // Auto Set
            "RefTypeMasterList_Code": "1061", // Static Value
            "DocumentTypeMasterList_Code": fileDoc.documentTypeCode,
            "ParentRootFolder": "Documents\\LicenseDocument\\", // Static value
            "UploadFileName": fileDoc['file']['name'],
            "Size": fileDoc['file']['size'] ? (fileDoc['file']['size'] / 1024).toFixed(2) + ' KB' : null,
            "Page_Code": 192, // Static Value // License Master
            "CreateBy": licenseObj.CreateBy ? licenseObj.CreateBy : null,
            "ModifiedBy": licenseObj.CreateBy ? licenseObj.CreateBy : null,
            "Document": fileDoc.base64str // Base64 string
          };

        })
      }
    }

    return this.commonService.commonInsertUpdateOperation(payload)
  }

  public updateLicense(value: any) {
    const url = `${GLOBAL.APIS.LICENSE.UPDATE_ADDNEW_LICENSE}`;
    return this.http.post(url, value).pipe(map((response: any) => response));
  }

  public uploadDocuments(files: any[], params) {

    const url = `${GLOBAL.APIS.LICENSE.ADD_DOCUMENT}`;
    const userId = GLOBAL.USER_ID;
    const fd = new FormData();
    let docType = '';
    files.forEach((fileDoc, index) => {
      fd.append(`file${String(index)}`, fileDoc['file'], fileDoc['file']['name']);
      docType += files.length - 1 === index ? `${fileDoc.documentTypeCode}` : `${fileDoc.documentTypeCode},`;
    });
    fd.append('Image_Type', docType);
    fd.append('UserID', userId);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    });
    // headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.http.request(GLOBAL.HTTP_POST, url, requestOptions);
  }

  public getLicenseByLicenseCode(licenseCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.LICENSE.GET_LICENCE_BY_CODE_SP}`;
    const payload = {
      "Filter": {
        "license_Code": licenseCode,
        "RequireType": 0,// Satic Value
        "ActionType": 0,// Satic Value
        "UserId": GLOBAL.USER_ID // Logged in user ID
      }
    }
    return this.http.post<any[]>(url,payload)
    // .pipe(  map((response: any) => response.Data));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS_SP}`;
    const payload = {
      "Search": null,
      "RequiredFields": "NatureOfWork_Code,Name,",
      "RequireType": 0,
      "ActionType": 0,
      "UserId": GLOBAL.USER_ID
    }
    return this.http
      .post<any[]>(url, payload)
      .pipe(map((response: any) => response));
  }

  public getVerifyByList(): Observable<any> {
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_LIST}?search=&Company=&Plant=&Designation=&State=&City=&Country=
    &Gender=&isLock=&isActive= &isDelete=&isSA=&Role=&TimeZone=&Language=&IsOrganizationUser=`;
    return this.http.get(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  // public getNatureOfLicense(shor): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${MasterItemEnum.NATURE_OF_LICENSE}`;
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  // }

  public getMasterItemListByCode(shortCode: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${shortCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  }

  public getContractors(isSubContractorList = false, contractorCode: any = '', workOrderCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS_SP}`
    const payload = {
      "search": null,
      "sDate": null,
      "eDate": null,
      "selectedField": null,
      "isMain": isSubContractorList ? "0" : "1",
      "Contractor_Code": contractorCode || null,
      "ExcludeContractor_Code": null,
      "workOrderCode": workOrderCode || null,
      "Plant_Code": null,
      "UserID": GLOBAL.USER_ID,
      "RequirType": null
    }
    return this.http.post<any[]>(url, payload)
  }
  
  public getWorkOrderFilterDataForSubWorkorder(contractorCode:any,subContractorCode: any, workOrderCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate='
    //   + '&eDate='
    //   + '&search='
    //   + '&selectedField='
    //   + '&filterType='
    //   + '&condition='
    //   + '&isActive='
    //   + '&workOrderType='
    //   + '&workOrderOwner_Code='
    //   + '&workOrderHOD_Code='
    //   + '&department_Code='
    //   + '&isContractorType='
    //   + '&Plant_Code='
    //   + '&NatureOfWork_Code='
    //   + '&NatureOfWork_Code='
    //   + '&Contractor_Code='
    //   + '&SubContractor_Code=' + subContractorCode
    //   + '&WorkOrder_Code='
    //   + '&SubWorkOrder_Code='
    //   + '&WorkSiteArea_Code='
    //   + '&IsHRCApplicable='
    //   + '&Status_Code='
    //   + '&UserId=';
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));

    const payload = {
      sDate: null,
      eDate: null,
      selectedField: null,
      filterType: null,
      condition: null,
      isActive: null,
      workOrderType: null,
      workOrderOwner_Code: null,
      workOrderHOD_Code: null,
      department_Code: null,
      isContractorType: null,
      Plant_Code: null,
      NatureOfWork_Code: null,
      Contractor_Code: contractorCode,
      SubContractor_Code: subContractorCode,
      WorkOrder_Code: workOrderCode,
      SubWorkOrder_Code: null,
      WorkSiteArea_Code: null,
      IsHRCApplicable: null,
      Status_Code: null,
      UserId: GLOBAL.USER_ID
    };
	return this.http.post<any[]>(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER, payload).pipe(
        map((response: any) => response));
  }


  public getWorkOrderFilterData(contractorCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate='
    //   + '&eDate='
    //   + '&search='
    //   + '&selectedField='
    //   + '&filterType='
    //   + '&condition='
    //   + '&isActive='
    //   + '&workOrderType='
    //   + '&workOrderOwner_Code='
    //   + '&workOrderHOD_Code='
    //   + '&department_Code='
    //   + '&isContractorType='
    //   + '&Plant_Code='
    //   + '&NatureOfWork_Code='
    //   + '&NatureOfWork_Code='
    //   + '&Contractor_Code=' + contractorCode
    //   + '&SubContractor_Code='
    //   + '&WorkOrder_Code='
    //   + '&SubWorkOrder_Code='
    //   + '&WorkSiteArea_Code='
    //   + '&IsHRCApplicable='
    //   + '&Status_Code='
    //   + '&UserId=';
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));

    const payload = {
      sDate: null,
      eDate: null,
      selectedField: null,
      filterType: null,
      condition: null,
      isActive: null,
      workOrderType: null,
      workOrderOwner_Code: null,
      workOrderHOD_Code: null,
      department_Code: null,
      isContractorType: null,
      Plant_Code: null,
      NatureOfWork_Code: null,
      Contractor_Code: contractorCode,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      WorkSiteArea_Code: null,
      IsHRCApplicable: null,
      Status_Code: null,
      UserId: GLOBAL.USER_ID
    };
    return this.http.post<any[]>(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER, payload).pipe(
      map((response: any) => response));
  }

  // getPOGlobalSetting(code: string): Observable<any> {
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.contractorAdapter.convertResponse<any>(response)));
  // }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }
}
