import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
// tslint:disable-next-line:max-line-length
import { ModalConfig, BackdropColor, ModelDialogClass } from '../../core/components/select-steps/select-steps-model/select-steps.model';
// tslint:disable-next-line:max-line-length
import { SelectStepsComponent, AgActionCellRendererComponent, AgNumberCountComponent, FileUploadService, FileDoc } from '../../core/components';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { CompanyCommonsMasterService } from 'src/app/company-commons-master/company-commons-master.service';
import { CalenderService } from '../calender.service';
import { GLOBAL } from 'src/app/app.globals';
import { nextContext } from '@angular/core/src/render3';
@Component({
  selector: 'app-compliance-tasks',
  templateUrl: './compliance-tasks.component.html',
  styleUrls: ['./compliance-tasks.component.css']
})

export class ComplianceTasksComponent implements OnInit {
  @ViewChild('stepsComponent') public stepsComp: SelectStepsComponent;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('agGrid') agGrid: any;
  rowData: any;
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public modalRef: any;
  public gridAPI: GridApi;
  public numberOfRowsPerPage = 10;
  private selectedFilterData: any = { JSONText: {} };
  /// public shiftMasterForm: FormGroup;
  compliancetaskForm = new FormGroup({
    Company_Code: new FormControl(''),
    Plant_Code: new FormControl(''),
    legislationtype_Code: new FormControl(''),
    compliancetitle: new FormControl(''),
    Natureofcompliance_Code: new FormControl(''),
    StartDate: new FormControl(''),
    EndDate: new FormControl(''),
    ActualDate: new FormControl(''),
    Status_Code: new FormControl(''),
  });
  masterData = [];
  uploadedFiles: FileDoc[] = [];
  public legislationtypelist: any[] = [];
  public actlist: any[] = [];
  public maintainlist: any[] = [];
  public authoritylist: any[] = [];
  public grouplist: any[] = [];
  public plants: any[] = [];
  public natureofcompliancelist: any[] = [];
  public companyData: any[] = [];
  editlegislationCode1: string;
  documentTypes = [];
  public countries: any[] = [];
  public states: any[] = [];
  public districts: any[] = [];
  public cities: any[] = [];
  public pPos: any[] = [];
  show = false;
  editMode = false;
  plantName: any;
  shortCode: any;
  shortname: any;
  save = false;
  update = false;
  columnDefs = [
    {
      headerName: '#', field: '', width: 50,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
    },
    { headerName: 'Name', field: 'NameOfLegislation', sortable: true, width: 270, filter: true },
    { headerName: 'Short Code', field: 'sc', sortable: true, width: 270, filter: true },
    { headerName: 'Short Name', field: 'sm', sortable: true, width: 270, filter: true },
    { headerName: 'lawcategory', field: 'lawcategory', sortable: true, width: 270, filter: true },
    { headerName: 'lawson', field: 'lawson', sortable: true, width: 270, filter: true },
    { headerName: 'legislationcat', field: 'legislationcat', sortable: true, width: 270, filter: true },
    {
      headerName: 'Action', field: '',
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
    }

  ];



  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };

  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  constructor(private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private logHelperService: LogHelperService,
    private activatedRoute: ActivatedRoute,
    public sharedDataService: SharedDataService,
    private modalService: NgbModal, private service: CalenderService,
    private fileUploadService: FileUploadService) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

  }
  // tslint:disable-next-line: function-name
  IfCheck($event) {

    if ($event.target.checked === true) {
      this.show = true;
    } else {
      this.show = false;
    }
  }
  ngOnInit() {
    this.getLegislation();
    this.getCompanyData();
    this.getPlants();
    this.getCountry();
    this.getactlist();
    this.sharedDataService.getMasterData().subscribe((masterList: any) => {

      console.log('master data', masterList);
      this.masterData = masterList;

      const legislationtype = this.masterData.filter(list => list.Parent_Code === 135);
      const maintain = this.masterData.filter(list => list.Parent_Code === 402);
      const authority = this.masterData.filter(list => list.Parent_Code === 394);
      const category = this.masterData.filter(list => list.Parent_Code === 427);
      const group = this.masterData.filter(list => list.Parent_Code === 399);
      this.legislationtypelist = this.bindDataforMasterItem(legislationtype);
      this.maintainlist = this.bindDataforMasterItem(maintain);
      this.authoritylist = this.bindDataforMasterItem(authority);
      this.natureofcompliancelist = this.bindDataforMasterItem(category);
      this.grouplist = this.bindDataforMasterItem(group);
      if (masterList.length) {
        this.compliancetaskForm.reset();
      }

    });
    this.service.getDocumentTypes().subscribe((res: any) => {
      this.documentTypes = res.Data;
    });
  }

  bindDataforMasterItem(filteredData) {
    const convertedData = [];
    filteredData.forEach((element: any) => {
      const subData = [];
      const subDataFilter = this.masterData.filter(list => list.Parent_Code === element.MasterItem_Code);
      if (subDataFilter.length > 0) {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name, children: this.bindDataforMasterItem(subDataFilter) });
      } else {
        convertedData.push({ id: element.MasterItem_Code, name: element.Name });
      }
    });
    return convertedData;
  }


  getCompanyData() {
    return this.service.getCompanyMasterData().subscribe((response: any) => {
      this.companyData = response;
      if (this.companyData.length === 1) {
        // this.labourForm.control.value.Company;
        this.compliancetaskForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
      } else {
        this.companyData = response;
      }
    });
  }
  getactlist() {
    return this.service.getact().subscribe((res: any) => {
      this.actlist = res;
    });
  }
  getPlants() {
    return this.service.getPlantMasterData().subscribe((response: any) => {
      this.plants = response;
      if (this.plants.length === 1) {
        this.compliancetaskForm.controls['Plant_Code'].setValue(this.plants[0].Plant_Code);
      } else {
        this.plants = response;
      }
    });
  }
  getLegislation() {
    return this.service.getLegislationforgrid().subscribe((res: any) => {
      this.rowData = res;
    });
  }
  getCountry() {
    return this.service.getCountry().subscribe((response: any) => {
      this.countries = response;
    });
  }
  getStatesByCountryId() {
    return this.service.getStatesByCountryId(this.compliancetaskForm.controls['Country_Code'].value || 100)
      .subscribe((response: any) => {
        this.states = response;
      });
  }
  getDistrictsByState() {
    this.service.getDistrictsByState(this.compliancetaskForm.controls['State_Code'].value || 100).subscribe((response: any) => {
      this.districts = response;
    });
  }
  getCitiesByDistrict() {
    this.service.getCitiesByDistrict(this.compliancetaskForm.controls['District_Code'].value || 100).subscribe((response: any) => {
      this.cities = response;
    });
  }
  getPostOfficeByCity() {
    this.service.getPOsByCity(this.compliancetaskForm.controls['City_Code'].value || 100).subscribe((response: any) => {
      this.pPos = response;
    });
  }
  public openModal(): void {

    this.clearData();
    this.modalRef = this.modalService.open(this.tempRef,
      { size: 'lg', backdrop: 'static' });
    if (this.editlegislationCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;
    }

  }
  // tslint:disable-next-line: use-life-cycle-interface
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
    this.save = false;
    this.update = false;
  }
  private clearData() {
    this.compliancetaskForm.reset();
  }
  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
    this.save = false;
    this.update = false;
  }
  onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }


  public saveAndNew(): void {
    console.log('form data open modal', this.compliancetaskForm.getRawValue());
    const value = this.compliancetaskForm.getRawValue();
    const selectedFilterDataObj = this.editlegislationCode1;

    const data = {
      Task_Code: this.editlegislationCode1,
      TaskParent_Code: value.legislationtype_Code,
      Company_Code: value.Company_Code,
      Plant_Code: value.Plant_Code,
      TaskSection_Code: value.TaskSection_Code,
      Calender_Code: 100,
      TaskName: value.compliancetitle,
      TaskDescription: value.TaskDescription,
      StartDate: value.StartDate,
      EndDate: value.EndDate,
      CloseDate: value.EndDate,
      ActualDateOfSubmission: value.ActualDate,
      StatusMasterList_Code: value.Status_Code,
      Legislation_Code: value.legislationtype_Code,
      ComplianceNatureMasterList_Code: value.Natureofcompliance_Code,
      UserId: GLOBAL.USER_ID,
    };
    const uploadParams = {
      Task_Code: ''
    };
    if (selectedFilterDataObj)
    // tslint:disable-next-line: brace-style
    {
      this.service.addcompliancetask(data).subscribe((response: any) => {
        if (response.Status) {
          this.logHelperService.logSuccess({
            message: 'Plant Updated successfully'
          });
          this.modalRef.close();
          this.clearData();
          this.getLegislation();
        }
      });
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.service.addcompliancetask(data).subscribe((response: any) => {
        {
          uploadParams.Task_Code = response[0].TaskCode;
          if (uploadParams.Task_Code) {
            this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams,
              GLOBAL.APIS.COMPANY_COMMONS_MASTER.UPLOAD_COMPLIANCE_TASK_DOC)
              .subscribe((res) => {
                this.logHelperService.logSuccess({
                  message: 'Nature Of Work Updated Successfully'
                });
              });
            this.clearData();
            this.modalRef.close();
          }
          this.logHelperService.logSuccess({
            message: 'Nature Of Work Updated Successfully'
          });
          this.clearData();
          this.modalRef.close();
        }
      });
    }
  }


  public editlegislationCode($event): void {
    this.editlegislationCode1 = $event.Legislation_Code;

    if (this.editlegislationCode1) {

      this.openModal();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getlegislationProfile(this.editlegislationCode1).subscribe((response: any) => {

        const shift = response;
        if (!shift) {
          return;
        }
        this.compliancetaskForm.controls['legislationtype_Code'].setValue(shift[0].LegislationTypeMasterList_Code);
        this.compliancetaskForm.controls['act_Code'].setValue(shift[0].ParentLegislation_Code);
        this.compliancetaskForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.compliancetaskForm.controls['ShortName'].setValue(shift[0].ShortName);
        this.compliancetaskForm.controls['Country_Code'].setValue(shift[0].Country_Code);
        this.getStatesByCountryId();
        this.compliancetaskForm.controls['State_Code'].setValue(shift[0].State_Code);
        this.compliancetaskForm.controls['legislationname'].setValue(shift[0].NameOfLegislation);
        this.compliancetaskForm.controls['maintainby_Code'].setValue(shift[0].MaintainByMasterList_Code);
        this.compliancetaskForm.controls['authority_Code'].setValue(shift[0].LawsOnMasterList_Code);
        this.compliancetaskForm.controls['EnactmentDate'].setValue(shift[0].Enactment_Date);
        this.compliancetaskForm.controls['legislationcategory_Code'].setValue(shift[0].LegislationCatMasterList_Code);
        this.compliancetaskForm.controls['CameIntoForceDate'].setValue(shift[0].CameIntoForceDate);
        this.compliancetaskForm.controls['groupcategory_Code'].setValue(shift[0].LawCategoryMasterList_Code);
        this.compliancetaskForm.controls['NotifiedDate'].setValue(shift[0].Published_Date);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  // tslint:disable-next-line: function-name
  CheckPlant() {

    // tslint:disable-next-line: ter-indent
    this.service.CheckPlant(this.compliancetaskForm.controls.PlantName.value, this.editlegislationCode1).subscribe((res: any) => {
      this.plantName = res.Data;
      if (this.plantName > 0)
      // tslint:disable-next-line: brace-style
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'This field requires a unique entry. This Plant Name has already been used with other Firm / Company. Please enter different Plant Name.'
        });
        this.compliancetaskForm.controls['PlantName'].reset();
      }
    });
    return true;
  }

  // tslint:disable-next-line: function-name
  CheckShortCode() {

    // tslint:disable-next-line: ter-indent
    this.service.CheckShortCode(this.compliancetaskForm.controls.ShortCode.value, this.editlegislationCode1).subscribe((res: any) => {
      this.shortCode = res.Data;
      if (this.shortCode > 0)
      // tslint:disable-next-line: brace-style
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different ShortCode.'
        });
        this.compliancetaskForm.controls['ShortCode'].reset();
      }
    });
    return true;
  }

  // tslint:disable-next-line: function-name
  CheckShortName() {

    // tslint:disable-next-line: ter-indent
    this.service.CheckShortName(this.compliancetaskForm.controls.ShortName.value, this.editlegislationCode1).subscribe((res: any) => {
      this.shortname = res.Data;
      if (this.shortname > 0)
      // tslint:disable-next-line: brace-style
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'This field requires a unique entry. This Short Name has already been used with other Firm / Company. Please enter different Short Name.'
        });
        this.compliancetaskForm.controls['ShortName'].reset();
      }
    });
    return true;
  }
}
