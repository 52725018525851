import { Component, OnInit, EventEmitter, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ReportViewListGroupRowComponent } from '../../report-view-list-group-row/report-view-list-group-row.component';
import { SharedService } from 'src/app/core/services';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import * as _ from 'lodash';
import { AttendanceRegularizationCellRendererComponent } from '../attendance-regularization-cell-renderer/attendance-regularization-cell-renderer.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceService } from '../../attendance.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-attendance-regularization-view-list',
  templateUrl: './attendance-regularization-view-list.component.html',
  styleUrls: ['./attendance-regularization-view-list.component.css']
})
export class AttendanceRegularizationViewListComponent implements OnInit {
  decimalPipe: any;
  cValue = new Date();
  myDate = formatDate(this.cValue, 'dd-MM-yyyy', 'en-US');

  @Input()
  public set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  public get selectedReportData(): Date {
    return this.selectedReportInfo;
  }

  @Input()
  public set reportData(value: any[]) {
    if (value) {
      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
    }
  }

  public get reportData(): any[] {
    return this.reportValueData;
  }


  rowClassRules;
  public tableRowData: any[] = [];
  public reportDate: string;
  public totalData: number;
  public myMap: any[] = [];
  public myMap1: any[] = [];

  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  @ViewChild('printsection') public printsection: ElementRef<any>;
  @ViewChild('templateRef') public templateRef: TemplateRef<any>;

  public printData: any[] = [];
  public closeModal: EventEmitter<any>;
  public refreshList: EventEmitter<any>;
  public formGroup: FormGroup;
  public modalRef: NgbModalRef;


  shiftList: any[] = [];
  columnDefs: any[] = [];
  rowData = [];
  catGroupSum = [];
  // groupingMeta = ['ContractorName', 'CateName'];
  groupingMeta = ['ContractorNameWithSubContractor', 'CateName'];

  private selectedReportInfo: Date;
  private reportValueData: any[];

  constructor(
    private sharedService: SharedService,
    private modalService: NgbModal,
    private attendanceService: AttendanceService,
    private fb: FormBuilder
  ) {
    this.closeModal = new EventEmitter();
    this.refreshList = new EventEmitter();

    this.formGroup = this.fb.group({
      Shift_Code: [null, [Validators.required]],
      GatepassNo: [null],
      LabourAttResult_Code: [null],
      Plant_Code: [null],
      RowIndex: [null]
    });
  }

  ngOnInit() {
    this.totalData = this.reportValueData.length;
    this.reportDate = this.dateConvertor(this.selectedReportInfo);
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 0;
      },
    };
    this.createColumnDef();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public onPrint(): void {
    // this.closeModal.next();
    const data = {
      selectedReportData: this.selectedReportData,
      reportData: this.reportData
    };
    // this.myMap = this.getGroupRowTableData1(this.reportData);
    this.tableRowData = this.getGroupRowTableData(this.reportData);
    console.log('data--yyy', this.tableRowData);
    setTimeout(() => {
      this.btnPrint.nativeElement.click();
      // localStorage.setItem('report-data', JSON.stringify(data));
      // this.router.navigate(['/reports/unitwise-print-rpt']);
    }, 100);
  }

  /*** Grid Configuration */
  dateValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('MM/DD/YYYY');
    }
    return '';
  }

  timeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('HH:mm');
    }

    return '';
  }

  decimalValueFormatter = (params) => {
    if (params.value) {
      return this.decimalPipe.transform(params.value, '2.2-2');
    }
    return '';
  }


  private createColumnDef(): void {
    this.columnDefs = [
      {
        headerName: 'Srn.', field: '', width: 50,
        sortable: false, suppressMenu: true, cellRenderer: this.rowIndexCellRenderer, pinned: 'left'
      },
      {
        headerName: 'Contractor Name',
        field: 'ContractorNameWithSubContractor', width: 300, visible: false, filter: true,
        // pinned: 'left',
        hide: true
      },
      {
        headerName: 'Details',
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        children: [
          {
            headerName: 'Code', width: 150, field: 'Code', filter: true,
            colSpan: (params) => {
              return params.data.parent ? 12 : 1;
            },
            cellRendererFramework: ReportViewListGroupRowComponent,
            cellRendererParams: {
              groupingData: this.groupingMeta
            },
          },
          {
            headerName: 'Name', field: 'Name',
            width: 350, sortable: false, filter: true,
            cellStyle: { textAlign: 'left' }, headerClass: 'align-center'
          }
        ]
      },
      {
        headerName: 'Location',
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        children: [
          {
            headerName: 'Plant',
            cellStyle: { textAlign: 'left' }, headerClass: 'align-center',
            field: 'PlantName', filter: true, colSpan: (params) => {
              return params.data.parent ? 11 : 1;
            },
            cellRendererFramework: ReportViewListGroupRowComponent,
            cellRendererParams: {
              groupingData: this.groupingMeta
            },
          },
          {
            headerName: 'Unit',
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
            field: 'UnitInLocation', filter: true,

          }
        ]
      },
      {
        headerName: 'Shift Time',
        children: [
          {
            headerName: 'Shift', field: 'ShiftName',
            width: 100, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
            cellRendererFramework: AttendanceRegularizationCellRendererComponent,
            cellRendererParams: {
              clicked: this.onCellClick
            },
          },
          {
            headerName: 'Time', field: 'ShiftTime',
            width: 200, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          }
        ]
      },
      {
        headerName: 'PL Time',
        children: [
          {
            headerName: 'In', field: 'PlantIn',
            width: 100, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
          {
            headerName: 'Out', field: 'PlantOut',
            width: 100, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
        ]
      },
      {
        headerName: 'U Time',
        children: [
          {
            headerName: 'In', field: 'UnitIn',
            width: 100, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
          {
            headerName: 'Out', field: 'UnitOut',
            width: 100, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
        ]
      },
      {
        headerName: 'Attendance Details',
        children: [
          {
            headerName: 'Late In', field: 'LateIn',
            width: 100, sortable: false, suppressMenu: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center',

          },
          {
            headerName: 'Early Out', field: 'EarlyOut',
            width: 150, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
          // {
          //   headerName: 'Work Hrs', field: 'WHR',
          //   width: 150, sortable: false, filter: false,
          //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          // },
          {
            headerName: 'WHs', field: 'ActualWHR',
            width: 150, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
          {
            headerName: 'EWH', field: 'OverTime',
            width: 90, sortable: false, filter: false,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
          {
            headerName: 'Status ', field: 'Status',
            width: 90, sortable: false, filter: true,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
          {
            headerName: 'Approved ', field: 'IsApproved',
            width: 120, sortable: false, filter: true,
            cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
          },
        ]
      },
    ];
  }

  public onCellClick = (data: any, rowIndex: any) => {
    let requestObj: any = {
      GatepassNo: data.Code,
      UserID: GLOBAL.USER_ID,
      Plant: data.Plant_Code,
    };

    this.attendanceService.getGatepassWiseShiftList(requestObj).subscribe((response: any[]) => {
      console.log('this.shiftList', response);
      this.shiftList = response;
      requestObj = {
        ...requestObj,
        Shift_Code: data.FShift_Code,
        LabourAttResult_Code: data.LabourAttResult_Code,
        Plant_Code: data.Plant_Code,
        RowIndex: rowIndex,
      };

      this.formGroup.patchValue(requestObj);

      this.modalRef = this.modalService.open(this.templateRef, {
        size: 'sm'
      });
    });
  }

  public saveShift(): void {
    if (this.formGroup.valid) {
      const formValue = this.formGroup.getRawValue();
      const requestObj = {
        Shift_Code: formValue.Shift_Code,
        GatepassNo: formValue.GatepassNo,
        LabourAttResult_Code: formValue.LabourAttResult_Code,
        UserID: GLOBAL.USER_ID,
        ResultTable: 2
      };

      this.attendanceService.changeShift(requestObj).subscribe((response: any[]) => {
        this.onCloseModal();
        this.refreshList.next();
      });
    }
  }

  public onCloseModal(): void {
    this.modalRef.close();
  }

  private rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }

  onEdit(params) {
    // this.editClick.emit(params);
  }

  onDelete(params) {
    // this.deleteClick.emit(params);
  }
  private dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return '';
    }
    const date = new Date(reportData.Date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
  }
  nestGroupBy = (seq, keys) => {

    // tslint:disable-next-line: curly
    if (!keys.length)
      return seq;
    // tslint:disable-next-line: no-var-keyword
    // tslint:disable-next-line: prefer-const
    let first = keys[0];
    const rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return this.nestGroupBy(value, rest);
    });
  }

  getGroupRowTableData1(reportData: any[]): any[] {
    //  const data = this.nestGroupBy(reportData, ['ContractorName']);
    const data = this.nestGroupBy(reportData, ['ContractorName', 'PlantName', 'UnitInLocation', 'ShiftName']);

    const tableData1 = [];
    return data;
  }
  getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    // let index = 1;
    // reportData =  reportData.filter((item,index)=>index <= 10)
    const groupData = new Set(reportData.map(item => item.ContractorNameWithSubContractor));

    groupData.forEach((groupItem: string) => {
      const index = 1;
      const items = reportData.filter(item => item.ContractorNameWithSubContractor === groupItem);
      // items.forEach((item: any) => {
      //   item.index = index;
      //   index++;
      // });
      const groupCatName = new Set(items.map(cat => cat.CateName));
      const catGroup = [];
      groupCatName.forEach((catEle: string) => {
        let tp = 0;
        let tp1 = 0;
        let tp2 = 0;
        let tp3 = 0;
        const items = reportData.filter(rpt => rpt.ContractorNameWithSubContractor === groupItem
          && rpt.CateName === catEle);


        items.forEach((obj1) => {
          if (obj1.Status === 'P') { tp = tp + 1; } else if (obj1.Status === 'P1') { tp1++; }
          else if (obj1.Status === 'P2') { tp2++; } else if (obj1.Status === 'P3') { tp3++; }
        });

        catGroup.push({
          name: catEle, list: items, totalp: tp, totalp1: tp1, totalp2: tp2, totalp3: tp3

          // catGroup.push({
          //     name: catEle, list: reportData.filter(rpt => rpt.ContractorNameWithSubContractor === groupItem
          //       && rpt.CateName === catEle),

          //     list.forEach((obj) => {

          //       obj.list.forEach((obj1) => {
          //         if (obj1.Status === 'P') { tp = tp + 1; } else if (obj1.Status === 'P1') { tp1++; }
          //         else if (obj1.Status === 'P2') { tp2++; } else if (obj1.Status === 'P3') { tp3++; }
          //       });
          //       catGroup.push({ totalp: tp, totalp1: tp1, totalp2: tp2, totalp3: tp3 });
          //     });

        });
      });
      const rowItems = {
        // items,
        ContractorName: groupItem,
        CatName: catGroup
      };
      tableData.push(rowItems);
    });
    // this.catGroupSum = catGroup;

    return tableData;
  }

  // getGroupRowTableData2(reportData: any[]): any[] {

  //   const tableData = [];
  //   const reportData2 = JSON.parse(JSON.stringify(reportData));
  //   console.log('reportData2-test', reportData2);
  //   const newData = [];
  //   const maxRecordsPerPage = 30;
  //   reportData2.forEach((contractor) => {

  //   });

  //   return newData;

  //   return tableData;
  // }
}
