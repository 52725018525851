import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from '../app.globals';
export let BASE_URL;

const browserWindow = window || {};
const browserWindowEnv = browserWindow["__env"] || {};

BASE_URL = browserWindowEnv.BASE_URL;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  constructor(public http: HttpClient) { }

  //********************  POST REQUEST  ********************
  getState(payload: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/COMMON_SP_UserPlantAccess`;
    return this.http.post<any[]>(url, payload);
  }

  getLoanAndRecoveriesData(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/CLPMS_DamageNLose_Listing`;
    return this.http.post<any[]>(url, opts);
  }

  onSubmitLoanAndRecoveries(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/IUDCommon`;
    return this.http.post<any[]>(url, opts);
  }

  getSubContractors(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_Listing_Contractor_Filter_V1`;
    return this.http.post<any[]>(url, opts);
  }

  getSubWorkOrder(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_Listing_WorkOrder_Filter`;
    return this.http.post<any[]>(url, opts);
  }

  getLabourList(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_LabourName_Suggestion`;
    return this.http.post<any[]>(url, opts);
  }

  getRecoveryType(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/Nesting/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }

  getDateOfRecovery(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/CLPMS_SP_PayrollMonth_By_Date`;
    return this.http.post<any[]>(url, opts);
  }

  getStatesByCountryId(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_COM_GetAllState`;
    return this.http.post<any[]>(url, opts);
  }
  
  getAllContractors(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_Listing_Contractor_Filter_V1`;
    return this.http.post<any[]>(url, httpOptions['body'] = opts);
  }
  
  getLeaveTypeData(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_Listing_LeaveTypeList`;
    return this.http.post<any[]>(url, opts);
  }

  getLeaveTypes(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_COM_GetAllLeaveTypes`;
    return this.http.post<any[]>(url, opts);
  }
  addLeave(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Contractor/AddLeaveType`;
    return this.http.post<any[]>(url, httpOptions['body'] = opts);
  }

  updateEmailID(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/ThirdpartyEmailIdChange`;
    return this.http.post<any[]>(url, httpOptions['body'] = opts);
  }

  getRealTimeHeadCount(opts): Observable<any[]> {
    const headers = opts.lastUpdateTime ? { [GLOBAL.IGNORE_LOADER]: "true" } : {};
    const url = `${BASE_URL}Common/List/SP_Dashboard_RealTime_HeadCount`;
    return this.http.post<any[]>(url, opts, { headers });
  }

  getAllInHeadCount(opts): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_DAS_InHeadCount`;
    return this.http.post(url, opts).pipe(map((response: any) => response));
  }
  
  getAllOutHeadCount(opts): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_DAS_OutHeadCount`;
    return this.http.post(url, opts).pipe(map((response: any) => response));
  }

  public getAllRequirementAndShortage(opts): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_DAS_CountLiveShortage`;
    return this.http.post(url, opts).pipe(map((response: any) => response));
  }

  getWageTypes(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/MultiTableList/MinimumWageConfigSettingList`;
    return this.http.post<any[]>(url, opts);
  }
  getWageTableList(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/CLMS_MinimumWageMasterList`;
    return this.http.post<any[]>(url, opts);
  }
  
  getSkill(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_COM_GetAllSkillMaster`;
    return this.http.post<any[]>(url, opts);
  }
  getZone(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }
  getArea(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }
  getCategory(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }
  getSubCategory(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }
  getGrade(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }
  getQualification(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_GetMasterItemListData_V2`;
    return this.http.post<any[]>(url, opts);
  }
  getTrade(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_COM_GetAllTradeMaster_V1`;
    return this.http.post<any[]>(url, opts);
  }

  getCompany(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_Listing_CompanyMaster_Filter`;
    return this.http.post<any[]>(url, opts);
  }

  getPlant(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_Listing_PlantMaster_Filter`;
    return this.http.post<any[]>(url, opts);
  }

  getAutoStateBind(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_COM_GetStateList_V1`;
    return this.http.post<any[]>(url, opts);
  }

  getCountry(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/SP_COM_GetAllCountry`;
    return this.http.post<any[]>(url, opts);
  }

  getAttendenceApproval(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/CLMS_ApprovedPendingAttendaceList`;
    return this.http.post<any[]>(url, opts);
  }
  refreshContractorAllData(opts: any): Observable<any[]> {
    const url = `${BASE_URL}Common/List/CLMS_CorrectContractorLogin`;
    return this.http.post<any[]>(url, opts);
  }
  

  //********************  GET REQUEST  ********************
  
  public getContractors(excludeContractorCode: string = "", workOrderCode: any = ""): Observable<any[]> {
    const url = `${BASE_URL}Contractor/GetContractorsFilter?search=&sDate=&eDate=&selectedField=&isMain=&
    ExcludeContractor_Code=${encodeURIComponent(excludeContractorCode)}&workOrderCode=${encodeURIComponent(workOrderCode)}&contractor_Code=`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data.Table)
    );
  }
  
  public getWorkOrdersByContractorCode(contractorCode: number, plantcode: number): Observable<any> {
    const url = `${BASE_URL}Contractor/GetWorkOrderbyContractor?contractorcode=${contractorCode}&plantcode=${plantcode}`;
    return this.http.get<any>(url).pipe(map((response: any) => response.Data)
    );
  }

  getContractor(): Observable<any[]> {
    const url = `${BASE_URL}Common/ContractorVendorMaster`;
    return this.http.get<any[]>(url);
  }
  
  getCompanyMasterData(): Observable<any[]> {
    const url = `${BASE_URL}GlobalMaster/GetCompanyMasterData?Search=&StatusOfIndustry=&ISActive=&SDate=&EDate=&EntityType=&Legalstatus=&State=&City=&Country=`;
    return this.http.get<any[]>(url);
  }

  globalSettingCode(opts: any): Observable<any[]> {
    const url = `${BASE_URL}GlobalMaster/GetGlobalSettingData?Code=${opts}`;
    return this.http.get<any[]>(url);
  }

  public getAllDataSet(opts): Observable<any[]> {
    const lastUpdatedTimeParam = opts.lastUpdatedTimeParam ? `&lastUpdateTime=${opts.lastUpdatedTimeParam}` : "";
    const headers = opts.lastUpdatedTimeParam ? { [GLOBAL.IGNORE_LOADER]: "true" } : {};
    const url = `${BASE_URL}Dashboard/GetAllDatScedulesNew?UserID=${opts.UserID}&StartDate=${opts.FDATE}&EndDate=${opts.TDATE}&Flag=${opts.Flag}${lastUpdatedTimeParam}`;
    return this.http.get<any[]>(url, { headers }).pipe(map(response => response));
  }
  
  
}
