// tslint:disable-next-line:max-line-length
import { Component, OnInit, ViewContainerRef, ComponentRef, TemplateRef, ViewChild, Input, ComponentFactoryResolver, EventEmitter } from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';
import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { Contractor, MasterItemCode } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { EntryForThePrsenter } from 'src/app/reports/daily-attendance/entryfortheday/entryfortheday-presenter/entryfortheday.presenter';
import { AttendanceService } from 'src/app/attendance/attendance.service';
// import { AttendanceManualPunchChangeComponent } from '../attendance-manual-punch-change/attendance-manual-punch-change.component';
// tslint:disable-next-line:max-line-length
import { AttendanceManualSubmitComponent } from 'src/app/attendance/attendance-manual/attendance-manual-submit/attendance-manual-submit.component';
// tslint:disable-next-line:max-line-length
import { AttendanceSinglePunchCorrectionComponent } from 'src/app/attendance/attendance-manual/attendance-single-punch-correction/attendance-single-punch-correction.component';
// import { AttendanceManualPunchService } from '../attendance-manual-punch/attendance-manual-punch.service';
// tslint:disable-next-line:max-line-length
import { ReportViewListUnitwiseWhrsComponent } from 'src/app/reports/daily-attendance/rpt-unitwise-whrs-details/report-view-list-unitwise-whrs/report-view-list-unitwise-whrs.component';
import { GridOptions } from 'ag-grid-community';
import { AgActionCellRendererComponent, AgNumberCountComponent, FooterComponent } from 'src/app/core/components';
// tslint:disable-next-line: max-line-length
import { DISABLED, FormArray } from '@angular/forms/src/model';
import { AgDropDownCellRendererComponent } from 'src/app/attendance/ag-helper';
// tslint:disable-next-line:max-line-length
import { AgExceptionalDropDownCellRenderComponent } from '../ag-exceptional-drop-down-cell-render/ag-exceptional-drop-down-cell-render.component';
import { parseTemplate } from '@angular/compiler';
import { ItemsList } from '@ng-select/ng-select/ng-select/items-list';

declare var $;
const dateFormat = 'DD-MM-YYYY';
const date1 = new Date();
date1.setDate(date1.getDate() - 1);
// date1.setMonth(date1.getMonth() - 1);
console.log(date1.toString());
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-report-view-list-exceptional-attendance',
  templateUrl: './report-view-list-exceptional-attendance.component.html',
  styleUrls: ['./report-view-list-exceptional-attendance.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ]
})
export class ReportViewListExceptionalAttendanceComponent implements OnInit {

  public activeFilter: string;
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  @ViewChild('tempRef') public tempRef: any;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  selectedRows: any[] = [];
  rowClassRules;
  attResultCode1 = [];
  attendanceCode = '';
  gridOptions: GridOptions;
  columnDefs = [];
  attGatepass = [];
  attGatepass1 = '';
  attUlc1 = '';
  attUlc = [];
  attStatus1 = '';
  attStatus = [];
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];
  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady(params) {
      this.gridApi = params.api;
    },


    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: false,
    // paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 10,
  };

  public savedFilters: any[] = [];
  public groupByList: any[];
  public companyList: any[];
  public plantList: any[];
  public unitList: any[];
  public plantLit: any[];
  public shiftGroupTypeData: any[] = [];
  public empList: any[] = [];
  public exceptionalReasonList: any[] = [];
  public departmentList: any[];
  public workmanCategoryList: any[];
  public sectionList: any[];
  public companyNatureofWorkList: any[];
  public shiftGroupTypeList: any[];
  public shiftTypeList: any[];
  public shiftList: any[];
  public contractors: any[] = [];
  public contractors2: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  public genderList: any[] = [];
  public skillList: any[] = [];
  public designationList: any[] = [];
  public qualificationList: any[] = [];
  public technicalQualificationList: any[] = [];
  public qualificationStreamList: any[] = [];
  public religionList: any[] = [];
  public castList: any[] = [];
  public jobTypeList: any[] = [];
  public labourNatureOfWorkList: any[] = [];
  public workMenTypeList: any[] = [];
  public stateList: any[] = [];
  selectedReason: any[];
  rowData = [];
  allEmployeeChecked: boolean;
  attendanceStatusList = GLOBAL.STATUS_LIST;
  employeeShiftList: any[] = [];
  getRowNodeId;
  closeModal: EventEmitter<unknown>;
  defaultColDef: { flex: number; };
  isRowSelectable: (rowNode: any) => boolean;
  editType: string;
  gridApi: any;
  show = true;
  isShowShiftWiseRadio = true;
  isShowLabourWiseRadio = true;
  isShowSavebutton = false;
  isShowSubmitbutton = true;
  statusList: any[];
  show1 = true;
  show2 = true;
  status = false;
  gridColumnApi: any;
  save: boolean;
  update: boolean;
  editErrorID1: any;
  rowSelection;
  public numberOfRowsPerPage = 10;
  // public contAndSubContList: any[] = [
  //   { contAndSubCont_Code: 1, name: 'Only Contractor' },
  //   { contAndSubCont_Code: 2, name: 'Only SubContractor' },
  //   { contAndSubCont_Code: 3, name: 'Both' } // selected
  // ];
  public hourlyapplicablelist: any[] = [
    { ExceptionalAttendance: 1, name: 'Late Coming' },
    { ExceptionalAttendance: 2, name: 'Early going' },
    { ExceptionalAttendance: 3, name: 'Both Side' },
  ];
  public individualcommonlist: any[] = [
    { ifIndivudualCommon: 1, name2: 'Individual' },
    { ifIndivudualCommon: 2, name2: 'Common' },
  ];
  public ifstatuschangelist: any[] = [
    { ifStatusChange: 1, name3: 'Shift Status' },
    { ifStatusChange: 2, name3: 'Attendance Status' },
  ];
  public sectionwiselist: any[] = [
    { IsContractorShiftLabour: 1, name1: 'Contractor Wise' },
    { IsContractorShiftLabour: 2, name1: 'Shift Wise' },
    { IsContractorShiftLabour: 3, name1: 'IsLabour' },
  ];
  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  public alertText: string;
  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  public pagecode: number;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;
  private contractorCode: number;
  private workOrderNo: number;
  public roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;

  public get formArray(): any {
    return this.formGroup.get('JSONText').get('employeeData');
  }

  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;

  private filterType: FilterTypeEnum;

  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private entryfordayService: AttendanceService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private entryForThePrsenter: EntryForThePrsenter,
    public sharedService: SharedService) {
    // this.setColumnDef();
    this.formGroup = this.entryForThePrsenter.buildForm();
    this.formGroup.get('JSONText').get('contAndSubCont').setValue(3);
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.closeModal = new EventEmitter();
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {
      // rowNode.data.CanEdit = true;
      return !rowNode.data.CanEdit;
    };
    // this.rowStyle = { background: 'black' };
    this.editType = 'fullRow';
    this.gridOptions = {
      suppressCellSelection: true,
      context: {
        componentParent: this
      },

      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 50;
        }
        return 30;
      },
      // isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true; // !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: false,
      headerHeight: 40,
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      // getRowStyle: (params) => {

      //   if (params.data.IsDeleted === true) {
      //     return { color: 'red' };
      //   }
      //   if (params.data.RefAttPunch_Code > 0) {
      //     return { color: 'blue' };
      //   }
      //   return null;
      // }
    };

  }

  ngOnInit() {
    this.rowSelection = 'multiple';
    // this.getStatusList();
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.pagecode = 335;
    this.getALLSavedFilterDescripationUserWise(this.pagecode);
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();
    const userId = GLOBAL.USER_ID;
    this.formGroup.get('JSONText').get('Date').setValue(date1);
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (userId && roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  selectchange(data: any) {
    if (data) {
      if (data.Name === 'Others') {
        this.formGroup.get('JSONText').get('Reason').setValue('');
      } else {
        this.formGroup.get('JSONText').get('Reason').setValue(data.Name);
      }
    }
  }
  public onCloseModel(): void {
    this.clearData();
    this.modalRef.close('click');
    this.save = false;
    this.update = false;
  }
  onCloseAction() {
    this.activeModal.close();
  }
  public onPlantChange(): void {
    this.unitList = [];
    this.shiftList = [];
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Shift_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (plantCode) {
      this.getUnits(plantCode);
      // this.getShift(plantCode);
      this.getEmployee();
    }
    this.getEmployee();
  }

  private getAllData() {
    this.setGroupByList();
    this.getCompany();
    this.getNatureOfWorks();
    this.getSections();
    this.getContractors();
    this.getShiftTypes();
    this.getGender();
    this.getSkill();
    this.getQualifications();
    this.getTechnicalQualifications();
    this.getQualificationStreams();
    this.getReligions();
    this.getCasts();
    this.getWorkManNatureOfWorks();
    this.getWorkManCategoryList();
    this.getEmployee();
    this.getExceptionalAttendanceReason();
  }

  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };

    // if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
    this.columnDefs = [
      {
        headerName: '', field: '', width: 30,
        pinned: 'left',
        sortable: false, suppressMenu: true,
        cellRenderer: this.rowIndexCellRenderer,
        filter: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressNavigable: true,
        cellClass: 'no-border'

      },
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
      },
      // {
      //   headerName: 'ULC', field: 'ULC', width: 70, filterParams:
      //     { applyButton: true, clearButton: true },
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Code', field: 'GatePassNo', width: 70, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'

      },
      {
        headerName: 'Name', field: 'FullName', width: 150, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Category', field: 'Category', width: 100, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Plant', field: 'Plant', width: 70, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Unit', field: 'Unit', width: 70, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'LateIn', field: 'LateIn', width: 70, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'EarlyOut', field: 'EarlyOut', width: 70, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'WHR', field: 'WHR', width: 100, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Status', field: 'Status', width: 50, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Change Status', field: 'ChangeStatus',
        sortable: false, suppressMenu: true, width: 100,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        hide: this.formGroup.get('JSONText').get('ifStatusChange').value !== '2',
        suppressColumnsToolPanel: true,
        cellRendererFramework: AgExceptionalDropDownCellRenderComponent,
        cellRendererParams: {
          // isDisable: true,
          shifts: GLOBAL.STATUS_LIST,


        }
      },
      {
        headerName: 'Change Shift', field: 'ChangeShift', sortable: false, suppressMenu: true, width: 100,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        hide: this.formGroup.get('JSONText').get('ifStatusChange').value !== '1',
        suppressColumnsToolPanel: true,
        cellRendererFramework: AgDropDownCellRendererComponent,
        cellRendererParams: {
          // isDisable: true,
          shifts: GLOBAL.SHIFTCHANGE_LIST,

        }
      },
      {
        headerName: 'IsManual', field: 'IsManualSet', width: 100, filterParams:
          { applyButton: true, clearButton: true },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'

      }
    ];

  }

  onRowSelected(event) {
    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    }
  }

  public checkAllEmployee(event: any) {
    this.allEmployeeChecked = event.target.checked as boolean;
    this.rowData = this.rowData.map((item: any) => {
      return {
        ...item, isChecked: this.allEmployeeChecked
      };
    });
  }

  public onEmployeeCheck(index: number): void {
    // employee.isChecked = event.target.checked;
    // this.allEmployeeChecked = this.rowData.every(item => item.isChecked);
    const currentFormGroup: FormGroup = this.formArray.controls[index] as FormGroup;
    const isChecked = currentFormGroup.get('isChecked').value;
    const gatepassno = currentFormGroup.get('GatePassNo').value;

    if (isChecked) {
      if (this.rowData[index].IsManualSet || this.rowData[index].IsManualShift) {
        this.logHelperService.logError({
          message: 'You have already Status or Shift changed'
        });
        currentFormGroup.get('isChecked').setValue(false);
        return;
      }
      currentFormGroup.get('AttendanceStatusID').enable();
      currentFormGroup.get('Shift_Code').enable();
      this.getShift(this.rowData[index], this.formGroup.value.JSONText.Plant_Code, gatepassno);


    } else {
      currentFormGroup.get('AttendanceStatusID').setValue(null);
      currentFormGroup.get('AttendanceStatusID').disable();
      currentFormGroup.get('Shift_Code').setValue(null);
      currentFormGroup.get('Shift_Code').disable();
    }
  }

  public onStatusChange(value: any, employee): void {
    employee.attendanceStatus = value ? value.ID : null;
  }

  public onShiftChange(value: any, employee): void {
    employee.shiftStatus = value ? value.ID : null;
  }

  ifCheck($event) {

    if ($event.target.checked === true) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  ifChecked($event) {

    if ($event.target.checked === true) {
      this.show1 = false;
    } else {
      this.show1 = true;
    }
  }

  onExceptionalTypeChange() {
    const exceptionalType = this.formGroup.get('JSONText').get('ifIndivudualCommon').value;
    if (exceptionalType === '1') {
      this.isShowShiftWiseRadio = false;
      this.isShowLabourWiseRadio = true;
      this.formGroup.get('JSONText').get('IsContractorShiftLabour').setValue(null);
      this.formGroup.get('JSONText').get('ifStatusChange').enable();
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').disable();
      this.formGroup.get('JSONText').get('IsContractorShiftLabour').enable();
    } else if (exceptionalType === '2') {

      this.isShowShiftWiseRadio = true;
      this.isShowLabourWiseRadio = false;
      this.formGroup.get('JSONText').get('ifStatusChange').setValue(null);
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(null);
      this.formGroup.get('JSONText').get('ifStatusChange').disable();
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').disable();
    } else {
      this.formGroup.get('JSONText').get('ifStatusChange').enable();
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').enable();
    }
  }

  public onSelectionTypeChange(): void {
    this.formGroup.get('JSONText').get('ShiftGroupType_Code').enable();
    this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').enable();
    const selectionType = this.formGroup.get('JSONText').get('IsContractorShiftLabour').value;
    if (selectionType === '1') {
      this.isShowSavebutton = true;
      this.isShowSubmitbutton = false;
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(null);
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').disable();
      this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').setValue(null);
      this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').disable();
    } else if (selectionType === '2') {
      this.isShowSavebutton = true;
      this.isShowSubmitbutton = false;

    } else if (selectionType === '3') {
      this.isShowSavebutton = false;
      this.isShowSubmitbutton = true;
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(null);
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').disable();
      this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').setValue(null);
      this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').disable();

    } else {

    }
  }

  public onStatusTypeChange(): void {
    const selectionType = this.formGroup.get('JSONText').get('ifStatusChange').value;
    if (selectionType === '1') {

      this.formGroup.get('JSONText').get('isAttendanceStatus').setValue(null);
      this.formGroup.get('JSONText').get('isAttendanceStatus').disable();

    } else if (selectionType === '2') {
      this.formGroup.get('JSONText').get('Shift_Code').setValue(null);
      this.formGroup.get('JSONText').get('Shift_Code').disable();
    } else {
      this.formGroup.get('JSONText').get('isAttendanceStatus').enable();
      this.formGroup.get('JSONText').get('Shift_Code').enable();

    }
  }

  public openClickModal(): void {
    this.clearData();
    this.modalRef = this.modalService.open(this.tempRef,
      { size: 'lg', backdrop: 'static' });
    if (this.editErrorID1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;
    }
  }

  getExceptionalAttendanceReason(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.ExceptionalAttendanceReason).subscribe((response: any[]) => {
      this.exceptionalReasonList = response;
      this.formGroup.get('JSONText').get('ExceptionalAttendance_Code').setValue(this.selectedFilterData.JSONText.MachineType);
    });
  }

  getWorkManCategoryList(): void {
    this.entryfordayService.getMasterItemByCode(GLOBAL.MASTER_ITEM_CODE.WORKMAN_CATEGORY).subscribe((response: any[]) => {
      this.workmanCategoryList = response;
      this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').setValue(this.selectedFilterData.JSONText.CategoryWorkmenMIL_Code);
    });
  }
  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.entryfordayService.getContractorsbydefault(userId, roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      this.contractors2 = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].ContractorVendor_Code);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getCompany() {
    this.entryfordayService.getCompanies().subscribe((response: any[]) => {

      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }

  getShiftTypes(): void {

    this.entryfordayService.getShiftGroupTypes().subscribe((response: any[]) => {
      this.shiftGroupTypeList = response;
      console.log('getShiftGroupTypes', response);
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(this.selectedFilterData.JSONText.ShiftGroupType_Code);
    });
  }

  public onShiftTypeGroupChange(): void {

    this.shiftList = [];
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    const shiftTypeGroupCode = this.formGroup.get('JSONText').get('ShiftGroupType_Code').value;
    if (plantCode && shiftTypeGroupCode) {

      this.getShiftMaster(plantCode, shiftTypeGroupCode);
    }
  }
  private getShiftMaster(plantCode: any, shiftTypeGroupCode: any): void {
    this.entryfordayService.getShifts(plantCode, shiftTypeGroupCode).subscribe((response: any[]) => {
      this.shiftList = response;
      console.log('this.shiftList', response);
    });
  }

  getContractors() {
    this.entryfordayService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
      this.contractors2 = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.Plant_Code);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getLicensesByContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.contractorLicenseList = response;
      this.formGroup.get('JSONText').get('License_Code').setValue(this.selectedFilterData.JSONText.License_Code);
    });
  }

  getWCPolicyByContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.contractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('WCPolicy').setValue(this.selectedFilterData.JSONText.WCPolicy);
    });
  }

  getPlants(companyCode: any): void {
    this.entryfordayService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
      if (this.selectedFilterData.JSONText.Plant_Code) {
        this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
        // this.getShift(this.selectedFilterData.JSONText.Plant_Code);
      }
    });
  }

  // getPlants(companyCode: any): void {
  //   this.entryfordayService.getPlants(companyCode).subscribe((response: any[]) => {
  //     this.plantList = response;
  //     this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
  //     if (this.selectedFilterData.JSONText.Plant_Code) {
  //     } else {
  //       this.plantList = response;
  //     }
  //   });
  // }

  getUnits(plantCode: any): void {
    this.entryfordayService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get('Unit_Code').setValue(this.selectedFilterData.JSONText.Unit_Code);
    });
  }

  getShift(data: any, plantCode: any, gatepassno: any): void {
    this.entryfordayService.getShift(plantCode, gatepassno).subscribe((response: any[]) => {
      // this.shiftList = response;
      data.shiftList = response;
    });
  }

  getDepartments(unitCode: any): void {
    this.entryfordayService.getDepartments(unitCode).subscribe((response: any[]) => {
      this.departmentList = response;
      this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
    });
  }

  getDesignations(companyCode: string): void {
    this.entryfordayService.getDesignations(companyCode).subscribe((response: any[]) => {
      this.designationList = response;
      this.formGroup.get('JSONText').get('Trade_Code').setValue(this.selectedFilterData.JSONText.Trade_Code);
    });
  }

  getGender(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.GENDER).subscribe((response: any[]) => {
      this.genderList = response;
      this.formGroup.get('JSONText').get('Gender').setValue(this.selectedFilterData.JSONText.Gender);
    });
  }

  getSkill(): void {
    this.entryfordayService.getSkills().subscribe((response: any[]) => {
      this.skillList = response;
      this.formGroup.get('JSONText').get('Skill_Code').setValue(this.selectedFilterData.JSONText.Skill_Code);
    });
  }

  getQualifications(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.QUALIFICATION).subscribe((response: any[]) => {
      this.qualificationList = response;
      this.formGroup.get('JSONText').get('QualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationMasterList_Code);
    });
  }

  getTechnicalQualifications(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.TECHNICALQUALIFICATION).subscribe((response: any[]) => {
      this.technicalQualificationList = response;
      this.formGroup.get('JSONText').get('TechQualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.TechQualificationMasterList_Code);
    });
  }

  getQualificationStreams(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.QUALIFICATIONSTREAM).subscribe((response: any[]) => {
      this.qualificationStreamList = response;
      this.formGroup.get('JSONText').get('QualificationStreamMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationStreamMasterList_Code);
    });
  }

  getReligions(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.RELIGION).subscribe((response: any[]) => {
      this.religionList = response;
      this.formGroup.get('JSONText').get('Religion_Code').setValue(this.selectedFilterData.JSONText.Religion_Code);
    });
  }

  getCasts(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.CASTS).subscribe((response: any[]) => {
      this.castList = response;
      this.formGroup.get('JSONText').get('Cast_Code').setValue(this.selectedFilterData.JSONText.Cast_Code);
    });
  }

  getSections(): void {
    this.entryfordayService.getSections().subscribe((response: any[]) => {
      this.sectionList = response;
      this.formGroup.get('JSONText').get('Section_Code').setValue(this.selectedFilterData.JSONText.Section_Code);
    });
  }

  getNatureOfWorks(): void {
    this.entryfordayService.getNatureOfWorks().subscribe((response: any[]) => {
      this.companyNatureofWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  getWorkManNatureOfWorks(): void {
    this.entryfordayService.getNatureOfWorks().subscribe((response: any[]) => {
      this.labourNatureOfWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }


  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
      // {
      //   value: '1',
      //   label: 'Contractor PO Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor and Department Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor, PO and Department Wise'
      // }
    ];
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    // this.formGroup.get('JSONText').get('subContractorCode').setValue(null);
    // this.formGroup.get('JSONText').get('subContractorWorkOrderNo').setValue(null);
    // // this.workOrders = [];
    // this.subContractors = [];
    // this.subContractorWorkOrders = [];
    if (companyCode) {
      this.getPlants(companyCode);
      this.getDesignations(companyCode);
    }
  }

  onContractorChange() {
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('License_Code').setValue(null);
    this.formGroup.get('JSONText').get('WCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      this.getLicensesByContractorCode(contractorCode);
      this.getWCPolicyByContractorCode(contractorCode);
      this.getEmployee();
    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
      this.getEmployee();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get('JSONText').get('WorkOrder_Code').value;
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
      this.getEmployee();
    }
    this.getEmployee();
  }

  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  public onContractorSaveEmitter(data: any) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubContractorChange() {
    const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.subContractorWorkOrders = [];
    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantcode);
      this.getWCPolicyBySubContractorCode(subContractorCode);
      this.getLicensesBySubContractorCode(subContractorCode);
      this.getEmployee();
    }
    this.getEmployee();
  }

  public onSubContractorSaveEmitter(data: any) {
    console.log('saved data', data);
    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  getSubWorkOrderListEmitter(contractorCode: number, plantcode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
    }
  }

  public onSaveFilter(): void {
    console.log('form data', this.formGroup.getRawValue());
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    console.log('saved data', this.reportFilterData);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();
    this.openModal();
  }

  // Data Bind Only Status of absent and working hours > 0 Keyur:07/05/2021
  // public onSubmit(): void {
  //   const formValue = this.formGroup.getRawValue();
  //   formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat);
  //   formValue.JSONText.Page = 'exceptional-attendance';
  //   // formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
  //   // formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
  //   // console.log('StartDate', formValue.JSONText.Date);
  //   // console.log('EndDate', formValue.JSONText.EDate);
  //   console.log('xyz', this.formGroup.getRawValue());
  //   console.log('Date response', formValue);


  //   let contCode = '';
  //   let ptCode = '';
  //   let utCode = '';
  //   let subconcode = '';
  //   let catecode = '';
  //   let natureofwork = '';
  //   let shifttypegroupcode = '';
  //   let shiftcode = '';
  //   // let page = 'exceptional-attendance';


  //   if (formValue.JSONText.Contractors) {
  //     if (this.roleCode = 119) {
  //       const cc = formValue.JSONText.Contractors;
  //       contCode = cc.toString();
  //     } else {

  //       contCode = formValue.JSONText.Contractors.join(',');
  //     }

  //   } else {
  //     contCode = null;
  //   }
  //   if (formValue.JSONText.SubContractors) {
  //     subconcode = formValue.JSONText.SubContractors.join(',');
  //   } else {
  //     subconcode = null;
  //   }
  //   if (formValue.JSONText.Plant_Code) {
  //     ptCode = formValue.JSONText.Plant_Code.join(',');
  //   } else {
  //     ptCode = null;
  //   }
  //   if (formValue.JSONText.Unit_Code) {
  //     utCode = formValue.JSONText.Unit_Code.join(',');
  //   } else {
  //     utCode = null;
  //   }
  //   if (formValue.JSONText.CategoryWorkmenMIL_Code) {
  //     catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
  //   } else {
  //     catecode = null;
  //   }
  //   if (formValue.JSONText.NatureOfWork_Code) {
  //     natureofwork = formValue.JSONText.NatureOfWork_Code.join(',');
  //   } else {
  //     natureofwork = null;
  //   }
  //   if (formValue.JSONText.ShiftGroupType_Code) {
  //     shifttypegroupcode = formValue.JSONText.ShiftGroupType_Code.join(',');
  //   } else {
  //     shifttypegroupcode = null;
  //   }
  //   if (formValue.JSONText.Shift_Code) {
  //     shiftcode = formValue.JSONText.Shift_Code.join(',');
  //   } else {
  //     shiftcode = null;
  //   }
  //   if (formValue.JSONText.CategoryWorkmenMIL_Code) {
  //     catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
  //   } else {
  //     catecode = null;
  //   }
  //   const uid = GLOBAL.USER_ID;
  //   formValue.filterText = {
  //     date: formValue.JSONText.Date,
  //     plant_Code: ptCode,
  //     unit_Code: utCode,
  //     company_Code: null,
  //     UserId: uid,
  //     workMenCategory_Code: catecode,
  //     natureOfWork_Code: natureofwork,
  //     shiftGroupType_Code: shifttypegroupcode,
  //     shift_Code: shiftcode,
  //     includingSubContractor: formValue.JSONText.IncludingSubContractor,
  //     contractors: contCode,
  //     workOrder_Code: null,
  //     license_Code: null,
  //     WCPolicy: null,
  //     ESIC: null,
  //     subContractors: subconcode,
  //     subWorkOrder_Code: null,
  //     subLicense_Code: null,
  //     subWCPolicy: null,
  //     subESIC: null,
  //     gender: null,
  //     skill_Code: null,
  //     trade_Code: null,
  //     Qulification_Code: null,
  //     techQualification_Code: null,
  //     streamQualification_Code: null,
  //     religion_Code: null,
  //     cast_Code: null,
  //     JobType: null,
  //     FirstAIDTraning: null,
  //     IsDomicial: null,
  //     FireFighter: null,
  //     CurrentStatus: null,
  //     SAge: null,
  //     EAge: null,
  //     Wage: null,
  //     ContactortMenType: null,
  //     ExpiredMedicalPeriod: null,
  //     ExpiredFireTraining: null,
  //     PoliceVerifed: null,
  //     Page: formValue.JSONText.Page,
  //   };
  //   console.log('filter', formValue.filterText);
  //   this.entryfordayService.getExceptionalAttendanceDetails(formValue.filterText).subscribe((response: any[]) => {
  //     console.log(response);
  //     this.rowData = response;
  //     this.openReportViewModal(response);

  //     // this.openClickModal();

  //   });
  // }

  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();
    if (formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat)) {
      if (formValue.JSONText.Plant_Code > 0) {
        console.log('xyz', this.formGroup.getRawValue());
        console.log('Date response', formValue);
        // this.getUlcGatepass();
        let contCode = '';
        let ptCode = '';
        let subconcode = '';
        let catecode = '';
        let shifttypegroupcode = '';
        let reason = '';
        let exceptionalattendance = '';
        let ifindividualcommon = '';
        let isContractorShiftLabour = '';
        let isShiftChange = '';
        const statementtype = 'Insert';

        if (formValue.JSONText.Contractors) {
          if (this.roleCode = 119) {
            const cc = formValue.JSONText.Contractors;
            contCode = cc.toString();
          } else {

            contCode = formValue.JSONText.Contractors.join(',');
          }

        } else {
          contCode = null;
        }
        if (formValue.JSONText.SubContractors) {
          subconcode = formValue.JSONText.SubContractors.join(',');
        } else {
          subconcode = null;
        }
        if (formValue.JSONText.Plant_Code) {
          ptCode = formValue.JSONText.Plant_Code.join(',');
        } else {
          ptCode = null;
        }
        if (formValue.JSONText.CategoryWorkmenMIL_Code) {
          catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
        } else {
          catecode = null;
        }
        if (formValue.JSONText.ShiftGroupType_Code) {
          shifttypegroupcode = formValue.JSONText.ShiftGroupType_Code.join(',');
        } else {
          shifttypegroupcode = null;
        }
        if (formValue.JSONText.ExceptionalAttendance) {
          exceptionalattendance = formValue.JSONText.ExceptionalAttendance;
        } else {
          exceptionalattendance = null;
        }
        if (formValue.JSONText.Reason) {
          reason = formValue.JSONText.Reason;
        } else {
          reason = null;
        }
        if (formValue.JSONText.ifIndivudualCommon) {
          ifindividualcommon = formValue.JSONText.ifIndivudualCommon;
        } else {
          ifindividualcommon = null;
        }
        if (formValue.JSONText.IsContractorShiftLabour) {
          isContractorShiftLabour = formValue.JSONText.IsContractorShiftLabour;
        } else {
          isContractorShiftLabour = null;
        }
        if (formValue.JSONText.ifStatusChange) {
          isShiftChange = formValue.JSONText.ifStatusChange;
        } else {
          isShiftChange = null;
        }

        formValue.filterText = {
          Date: formValue.JSONText.Date,
          UserId: GLOBAL.USER_ID,
          ULC: this.attUlc,
          GatePassNo: this.attGatepass,
          plant_Code: ptCode,
          contractors: contCode,
          subContractors: subconcode,
          ShiftTypeGroup_Code: shifttypegroupcode,
          CategoryMIL_Code: catecode,
          Reason: reason,
          ExceptionalAttendance: exceptionalattendance,
          ifIndivudualCommon: ifindividualcommon,
          IsContractorShiftLabour: isContractorShiftLabour,
          StatementType: statementtype,
          AttendanceStatusID: this.attStatus,
          ifStatusChange: isShiftChange

        };

        const selectedData = this.formArray.value.filter(item => item.isChecked).map(
          (item) => {
            return {
              ...item,
              Date: formValue.JSONText.Date,
              UserId: GLOBAL.USER_ID,
              plant_Code: ptCode,
              contractors: contCode,
              subContractors: subconcode,
              ShiftTypeGroup_Code: shifttypegroupcode,
              CategoryMIL_Code: catecode,
              Reason: reason,
              ExceptionalAttendance: exceptionalattendance,
              ifIndivudualCommon: ifindividualcommon,
              IsContractorShiftLabour: isContractorShiftLabour,
              StatementType: statementtype,
              ifStatusChange: isShiftChange

            };
          }
        );
        // const selectedData = this.rowData.filter(item => item.isChecked).map(
        //   (item) => {
        //     return {
        //       ULC: item.ULC,
        //       GatepassNo: item.GatePassNo,
        //       AttendanceStatusID: item.attendanceStatus,
        //       Shift_Code: item.ID,
        //       Date: formValue.JSONText.Date,
        //       UserId: GLOBAL.USER_ID,
        //       plant_Code: ptCode,
        //       contractors: contCode,
        //       subContractors: subconcode,
        //       ShiftTypeGroup_Code: shifttypegroupcode,
        //       CategoryMIL_Code: catecode,
        //       Reason: reason,
        //       ExceptionalAttendance: exceptionalattendance,
        //       ifIndivudualCommon: ifindividualcommon,
        //       IsContractorShiftLabour: isContractorShiftLabour,
        //       StatementType: statementtype,
        //       ifStatusChange: isShiftChange

        //     };
        //   }

        // );
        // const finalData = [];

        // this.attUlc.forEach((value, index) => {
        //   const obj = {
        //     Date: formValue.JSONText.Date,
        //     UserId: GLOBAL.USER_ID,
        //     ULC: value,
        //     GatePassNo: this.attGatepass[index],
        //     plant_Code: ptCode,
        //     contractors: contCode,
        //     subContractors: subconcode,
        //     ShiftTypeGroup_Code: shifttypegroupcode,
        //     CategoryMIL_Code: catecode,
        //     Reason: reason,
        //     ExceptionalAttendance: exceptionalattendance,
        //     ifIndivudualCommon: ifindividualcommon,
        //     IsContractorShiftLabour: isContractorShiftLabour,
        //     StatementType: statementtype,
        //     AttendanceStatusID: this.attStatus[index]
        //   };
        //   finalData.push(obj);
        // });

        console.log('filter', formValue.filterText);
        this.entryfordayService.addExceptionalAttendance(selectedData).subscribe((response: any[]) => {
          console.log('after approve:', response);
          if (response) {
            this.activeModal.close();
            this.logHelperService.logSuccess({
              message: 'Add ExceptionalAttendance successfully'
            });
            // this.rowData = response;
          }
        });
      } else {
        this.logHelperService.logError({
          message: 'Please Select Plant'
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select Date'
      });
    }
    this.clearData();
    this.formGroup.get('JSONText').get('Date').setValue(date1);
    this.getCompany();

  }

  public onSave(): void {
    const formValue = this.formGroup.getRawValue();
    if (formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat)) {
      if (formValue.JSONText.Plant_Code > 0) {
        console.log('xyz', this.formGroup.getRawValue());
        console.log('Date response', formValue);
        let contCode = '';
        let ptCode = '';
        let subconcode = '';
        let catecode = '';
        let shifttypegroupcode = '';
        let reason = '';
        let exceptionalattendance = '';
        let ifindividualcommon = '';
        let isContractorShiftLabour = '';
        let isShiftChange = '';
        const statementtype = 'Insert';

        if (formValue.JSONText.Contractors) {
          if (this.roleCode = 119) {
            const cc = formValue.JSONText.Contractors;
            contCode = cc.toString();
          } else {

            contCode = formValue.JSONText.Contractors.join(',');
          }

        } else {
          contCode = null;
        }
        if (formValue.JSONText.SubContractors) {
          subconcode = formValue.JSONText.SubContractors.join(',');
        } else {
          subconcode = null;
        }
        if (formValue.JSONText.Plant_Code) {
          ptCode = formValue.JSONText.Plant_Code.join(',');
        } else {
          ptCode = null;
        }
        if (formValue.JSONText.CategoryWorkmenMIL_Code) {
          catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
        } else {
          catecode = null;
        }
        if (formValue.JSONText.ShiftGroupType_Code) {
          shifttypegroupcode = formValue.JSONText.ShiftGroupType_Code.join(',');
        } else {
          shifttypegroupcode = null;
        }
        if (formValue.JSONText.ExceptionalAttendance) {
          exceptionalattendance = formValue.JSONText.ExceptionalAttendance;
        } else {
          exceptionalattendance = null;
        }
        if (formValue.JSONText.Reason) {
          reason = formValue.JSONText.Reason;
        } else {
          reason = null;
        }
        if (formValue.JSONText.ifIndivudualCommon) {
          ifindividualcommon = formValue.JSONText.ifIndivudualCommon;
        } else {
          ifindividualcommon = null;
        }
        if (formValue.JSONText.IsContractorShiftLabour) {
          isContractorShiftLabour = formValue.JSONText.IsContractorShiftLabour;
        } else {
          isContractorShiftLabour = null;
        }
        if (formValue.JSONText.ifStatusChange) {
          isShiftChange = formValue.JSONText.ifStatusChange;
        } else {
          isShiftChange = null;
        }

        formValue.filterText = {
          Date: formValue.JSONText.Date,
          UserId: GLOBAL.USER_ID,
          ULC: this.attUlc,
          GatePassNo: this.attGatepass,
          plant_Code: ptCode,
          contractors: contCode,
          subContractors: subconcode,
          ShiftTypeGroup_Code: shifttypegroupcode,
          CategoryMIL_Code: catecode,
          Reason: reason,
          ExceptionalAttendance: exceptionalattendance,
          ifIndivudualCommon: ifindividualcommon,
          IsContractorShiftLabour: isContractorShiftLabour,
          StatementType: statementtype,
          AttendanceStatusID: this.attStatus,
          ifStatusChange: isShiftChange

        };
        console.log('filter', formValue.filterText);
        this.entryfordayService.insertExceptionalAttendance(formValue.filterText).subscribe((response: any[]) => {
          console.log('after approve:', response);
          if (response) {
            this.activeModal.close();
            this.logHelperService.logSuccess({
              message: 'Add ExceptionalAttendance successfully'
            });
            // this.rowData = response;
          }
        });
      } else {
        this.logHelperService.logError({
          message: 'Please Select Plant'
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select Date'
      });
    }
    this.clearData();
    this.formGroup.get('JSONText').get('Date').setValue(date1);
    this.getCompany();

  }

  // ------------getGatePassNoandULC-------------//
  getUlcGatepass() {

    this.selectedRows = this.gridApi.getSelectedRows();
    // this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length === 0) {
      alert('Please select atleast one Compliance row');
      // return;
    } else {
      console.log('this.selectedRows', this.selectedRows);
      this.selectedRows.forEach((data) => {
        // this.attErrorid.push(data.ErrorID);
        this.attUlc.push(data.ULC);
        this.attGatepass.push(data.GatePassNo);
        this.attStatus.push(data.ChangeStatus);
        console.log('this.attUlc', data);
        console.log('this.attGatepass', data);
        console.log('this.attStatus', data);
      });
      // this.attUlc1 = this.attUlc.join(',');
      // this.attGatepass1 = this.attGatepass.join(',');
      // this.attStatus1 = this.attStatus.join(',');
      // console.log('this.attUlc', this.attUlc1);
      // console.log('this.attGatepass', this.attGatepass1);
      // console.log('this.attStatus', this.attStatus1);
    }
  }
  // ------------getGatePassNoandULC-------------//

  // openReportViewModal(response: any[]): void {
  //   const formValue = this.formGroup.getRawValue();
  //   // tslint:disable-next-line: indent
  //   if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
  //     formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
  //     formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
  //   }

  //   const modalRef = this.modalService.open(AttendanceManualSubmitComponent,
  //     { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
  //   modalRef.componentInstance.reportData = response;
  //   modalRef.componentInstance.selectedReportData = formValue.JSONText;
  //   modalRef.componentInstance.closeModal.subscribe((value: string) => {
  //     modalRef.close(value);
  //   });
  // }

  // Update Status Absent to Present Keyur:07/05/2021
  // onApprove() {
  //   this.selectedRows = this.gridApi.getSelectedRows();
  //   this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
  //   if (this.selectedRows.length === 0) {
  //     this.logHelperService.logError({
  //       message: 'Please select atleast one row.'
  //     });
  //     // alert('Please select atleast one row');
  //     // return;
  //   } else {
  //     console.log('this.selectedRows', this.selectedRows);
  //     let attendanceCode = '';
  //     this.selectedRows.forEach((data) => {
  //       this.attResultCode1.push(data.LabourAttResult_Code);
  //       console.log('this.attResultCode', attendanceCode);
  //     });
  //     attendanceCode = this.attResultCode1.join(',');
  //     console.log('this.attResultCode', this.attendanceCode);

  //     this.entryfordayService.updateExceptionalAttendance({ attResultCode: attendanceCode }).subscribe((dt: any[]) => {
  //       console.log('after approve:', dt);
  //       this.logHelperService.logSuccess({
  //         message: 'Data Save Succesfully.'
  //       });
  //       this.closeModal.next();
  //       /// this.getWrongAttendanceList();
  //     });
  //   }
  // }

  getEmployee() {
    const formValue = this.formGroup.getRawValue();
    formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat);
    console.log('Date', formValue.JSONText.Date);
    let contCode = '';
    let subcontCode = '';
    let ptCode = '';
    let utCode = '';

    if (formValue.JSONText.Contractors) {
      contCode = formValue.JSONText.Contractors.join(',');
    } else {
      contCode = null;
    }

    if (formValue.JSONText.Plant_Code) {
      ptCode = formValue.JSONText.Plant_Code.join(',');
    } else {
      ptCode = null;
    }
    if (formValue.JSONText.Unit_Code) {
      utCode = formValue.JSONText.Unit_Code.join(',');
    } else {
      utCode = null;
    }


    if (formValue.JSONText.SubContractors) {
      subcontCode = formValue.JSONText.SubContractors;
    } else {
      subcontCode = null;
    }


    this.entryfordayService.getEmployeewiseExceptionalAttendance({
      UserId: GLOBAL.USER_ID,
      Date: formValue.JSONText.Date,
      plant_Code: ptCode,
      unit_Code: utCode,
      contractors: contCode,
      subContractors: subcontCode,
    }).subscribe((response: any[]) => {

      if (response && response.length > 0) {
        this.rowData = response.map((item: any) => {
          return { ...item, shiftList: [] };
        });
        // this.openReportViewModal(response);
      } else {
        // message for data not found
      }
      this.createEmployeeControls(this.rowData);
      // this.reportValueData = response;
      // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, this.reportValueData);
      // this.rowData = tempRowData;
      // console.log('Output', tempRowData);
      console.log('formArray', this.formGroup);

    });

  }

  public createEmployeeControls(data: any[]): void {
    const control = this.formGroup.get('JSONText').get('employeeData') as FormArray;
    control.controls = [];
    for (const dataObj of data) {
      const formGroup: any = this.initiateForm(dataObj);
      control.push(formGroup);
    }
  }

  private initiateForm(item: any): FormGroup {
    return this.fb.group({
      isChecked: [false],
      ULC: [item.ULC],
      GatePassNo: [item.GatePassNo],
      AttendanceStatusID: [{ value: null, disabled: true }],
      Shift_Code: [{ value: null, disabled: true }]
    });
  }
  // openReportViewModal(response: any[]): void {
  //   const formValue = this.formGroup.getRawValue();
  //   const modalRef = this.modalService.open(ReportViewListExceptionalAttendanceComponent,
  //     { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });

  //   response.forEach((item: any) => {
  //     if (item.SubContractor) {
  //       item.ContractorNameWithSubContractor = `${item.ContractorName}[${item.SubContractor}]`;
  //     } else {
  //       item.ContractorNameWithSubContractor = item.ContractorName;
  //     }
  //   });
  //   modalRef.componentInstance.selectedReportData = formValue.JSONText;
  //   modalRef.componentInstance.reportData = response;
  //   modalRef.componentInstance.closeModal.subscribe((value: string) => {
  //     modalRef.close(value);
  //   });
  // }

  public onFilterTab(filterObj: any): void {
    const selectionType = this.formGroup.get('JSONText').get('IsContractorShiftLabour').value;
    // const exceptionalType = this.formGroup.get('JSONText').get('ifIndivudualCommon').value;
    if (selectionType === '1' && (filterObj.id === 4 || filterObj.id === 5)) {
      return;
    }

    if (selectionType === '2' && (filterObj.id === 3 || filterObj.id === 4 || filterObj.id === 5)) {
      return;
    }

    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
        .get('Contractors').value.length > 1)) {
      this.logHelperService.logError({
        message: 'Please first fullfill data in contractor.'
      });
      return;
    }

    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
    // if (filterObj.id === 5) {
    //   this.openClickModal();
    //   // this.getEmployee();
    if (filterObj.id === 5) {
      // this.setColumnDef();
      // this.gridOptions.columnApi.('ChangeStatus', false) //In that case we hide i
    }
    // }
  }

  public onAddfilter(): void {
    console.log('form data open modal', this.formGroup.getRawValue());
    const value = this.formGroup.getRawValue();
    const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
    const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
      && item.Report_Code !== this.selectedFilterData.Report_Code);
    if (!filterTypeObj) {
      selectedFilterDataObj.id = this.selectedTabId;
      const data = {
        Report_Code: this.selectedFilterData.Report_Code || '',
        Page_Code: 335,
        FilterName: value.FilterName,
        JSONTextDetails: JSON.stringify(selectedFilterDataObj)
      };
      if (this.selectedFilterData.Report_Code) {
        this.entryfordayService.updateFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      } else {
        this.entryfordayService.addFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      }
      // const index = this.savedFilters.indexOf(this.selectedFilterData);
      // if (this.selectedFilterData && index >= 0) {
      //   this.savedFilters[index] = value;
      //   this.modalRef.close();
      //   this.clearData();
      // } else {
      //   const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName);
      //   if (!filterTypeObj) {
      //     // this.savedFilters.push(value);
      //     const data = {
      //       User_Code: GLOBAL.USER.LOGGED_IN_USER,
      //       Page_Code: 5,
      //       FilterName: value.FilterName,
      //       JSONTextDetails: JSON.stringify(value)
      //     };
      //     this.entryfordayService.addFilter(data).subscribe((response: any) => {
      //       if (response.Status) {
      //         this.getALLSavedFilterDescripationUserWise();
      //       }
      //     });
      //     console.log('saved filters', this.savedFilters);

      //     this.modalRef.close();
      //     this.clearData();
      //   } else {
      //     alert('This filter is already exist.');
      //   }
      // }
    } else {
      alert('This filter is already exist.');
    }
  }

  public deleteFilterItem(filterObj: any): void {
    this.entryfordayService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(this.pagecode);
    });
  }

  private getALLSavedFilterDescripationUserWise(pagecode: number) {
    this.entryfordayService.getALLSavedFilterDescripationUserWise(pagecode).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();
    // this.reportFilterData = null;
    // this.selectedFilterData = null;

    // this.contractorCode = null;
    // this.workOrderNo = null;
    // this.subContractors = [];
    // this.workOrders = [];
    // // this.contractors=[];
    // setTimeout(() => {
    //   this.contractors = [...this.contractors];
    // }, 100);

    // this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: any) {

    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.entryForThePrsenter.bindForm(this.formGroup, filterObj);
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  private getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
          this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
        }
      }
    });
  }

  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
      }
    });
  }

  getWCPolicyBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.subContractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('SubWCPolicy').setValue(this.selectedFilterData.JSONText.SubWCPolicy);
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subContractorLicenseList = response;
      this.formGroup.get('JSONText').get('SubLicense_Code').setValue(this.selectedFilterData.JSONText.SubLicense_Code);
    });
  }

  private getSubContractors(workOrderNo: any = ''): void {
    // this.entryfordayService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
    //   this.subContractors = response;
    // });
    this.entryfordayService.getContractors('', workOrderNo).subscribe((response: any[]) => {
      this.subContractors = response;
      this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
        this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
          this.selectedFilterData.JSONText.Plant_Code);
        this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
      }
    });
  }

  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      {
        id: 2,
        name: 'Company',
        active: false
      },
      {
        id: 3,
        name: 'Contractor',
        active: false
      },
      {
        id: 4,
        name: 'Sub-Contractor',
        active: false
      },
      {
        id: 5,
        name: 'Associate',
        active: false
      }
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }

}
