import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AttendanceComponent } from './attendance.component';
import { WeekoffChangeComponent } from './weekoff-change/weekoff-change.component';
import { LabourWeekoffChangesComponent } from './labour-weekoff-changes/labour-weekoff-changes.component';
import { AttendanceApproveComponent } from './attendance-approve/attendance-approve.component';
import { AttendanceApproveFilterComponent } from './attendance-approve/attendance-approve-filter/attendance-approve-filter.component';
import { ApprovedAttendanceComponent } from './approved-attendance/approved-attendance.component';
import { AttendanceManualPunchComponent } from './attendance-manual-punch/attendance-manual-punch.component';
import { AttendanceApproveBysecComponent } from './attendance-approve-bysec/attendance-approve-bysec.component';
import { AttendanceManualPunchChangeComponent } from './attendance-manual-punch-change/attendance-manual-punch-change.component';
// tslint:disable-next-line:max-line-length
import { AttendanceManualPunchChangeFilterComponent } from './attendance-manual-punch-change/attendance-manual-punch-change-filter/attendance-manual-punch-change-filter.component';
import { COFFComponent } from './coff/coff.component';
import { SingleLabourWeekoffChangesComponent } from './sinlge-labour-weekoff-change/sinlge-labour-weekoff-change.component';
import { AttendanceManualComponent } from './attendance-manual/attendance-manual.component';
import { AttendanceManualSubmitComponent } from './attendance-manual/attendance-manual-submit/attendance-manual-submit.component';
import { AttendanceReprocessComponent } from './attendance-reprocess/attendance-reprocess.component';
import { ExceptionalAttendanceComponent } from './exceptional-attendance/exceptional-attendance.component';
// tslint:disable-next-line:max-line-length
import { AttendanceManualPunchManagementComponent } from './attendance-manual-punch-management/attendance-manual-punch-management.component';
import { AttendanceRegularizationComponent } from './attendance-regularization/attendance-regularization.component';
import { UserOTApprovalComponent } from './user-ot-approval/user-ot-approval.component';
import { AuthGuard } from '../auth.guard';
import { SynchronizeUtilityComponent } from './synchronize-utility/synchronize-utility.component';
import { RegularAttendanceApprovalComponent } from './regular-attendance-approval/regular-attendance-approval.component';
import { RegularApprovedAttendanceComponent } from './regular-approved-attendance/regular-approved-attendance.component';
const routes: Routes = [
  {
    path: 'employee-attendance',
    component: AttendanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contractor-attendance',
    component: AttendanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'weekoff-changes',
    component: WeekoffChangeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'labour-weekoff-changes',
    component: LabourWeekoffChangesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approve-attendance-list',
    component: AttendanceApproveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approve-attendance',
    component: AttendanceApproveComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approve-attendance2',
    component: AttendanceApproveFilterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'approved-attendance',
    component: ApprovedAttendanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'manual-punch',
    component: AttendanceManualPunchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'pending-approval',
    component: AttendanceApproveBysecComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'attendance-manual-punch-change',
  //   component: AttendanceManualPunchChangeComponent
  // },
  {
    path: 'manual-wrong-punch-change',
    component: AttendanceManualPunchChangeFilterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sigle-manual-attendance',
    component: AttendanceManualComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exceptional-attendance',
    component: ExceptionalAttendanceComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'coff',
    component: COFFComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'single-labour-weekoff-changes',
    component: SingleLabourWeekoffChangesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance-reprocess',
    component: AttendanceReprocessComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'synchronize-utility',
    component: SynchronizeUtilityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance-manual-punch-management',
    component: AttendanceManualPunchManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'attendance-regularization',
    component: AttendanceRegularizationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-ot-approval',
    component: UserOTApprovalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regular-attendance-approval',
    component: RegularAttendanceApprovalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regular-approved-attendance',
    component: RegularApprovedAttendanceComponent,
    canActivate: [AuthGuard]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttendanceRoutingModule { }
