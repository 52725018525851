// tslint:disable-next-line:max-line-length
import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, NgModel, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, GridOptions } from 'ag-grid-community';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
// tslint:disable-next-line:max-line-length
import { EntryForThePrsenter } from 'src/app/reports/daily-attendance/entryfortheday/entryfortheday-presenter/entryfortheday.presenter';
// tslint:disable-next-line:max-line-length
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
// tslint:disable-next-line:max-line-length
import { Contractor, FilterTypeEnum, ReportFilterData } from 'src/app/reports/common-report.model';
// tslint:disable-next-line:max-line-length
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
// tslint:disable-next-line:max-line-length
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { GLOBAL } from 'src/app/app.globals';
import * as moment from 'moment';
import { AttendanceService } from '../attendance.service';
// tslint:disable-next-line:max-line-length
import { LabourAttendanceReprocessComponent } from './labour-attendance-reprocess/labour-attendance-reprocess.component';
// tslint:disable-next-line:max-line-length
declare var $;
const dateFormat = 'DD-MM-YYYY';
const date1: Date = new Date();
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-attendance-reprocess',
  templateUrl: './attendance-reprocess.component.html',
  styleUrls: ['./attendance-reprocess.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class AttendanceReprocessComponent implements OnInit {
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  public activeFilter: string;
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];
  public savedFilters: any[] = [];
  public groupByList: any[];
  public companyList: any[];
  public plantList: any[];
  public plantLit: any[];
  public contractors: any[] = [];
  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;
  public pagecode: number;
  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;
  private filterType: FilterTypeEnum;
  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  private contractorCode: number;
  private workOrderNo: number;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private attendanceService: AttendanceService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private entryForThePrsenter: EntryForThePrsenter,
    public sharedService: SharedService) {
    this.formGroup = this.entryForThePrsenter.buildForm();
  }

  ngOnInit() {

    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.pagecode = 276;
    this.manageVisibilityOfSteps();
    this.formGroup.get('JSONText').get('Date').setValue(date1);
    const userId = GLOBAL.USER_ID;
    const roleCode = GLOBAL.USER.ROLE_CODE;
    if (userId && roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }
  public changeIncludingData(): void {
    this.formGroup.get('JSONText').get('isApprove').setValue(true);
    this.formGroup.get('JSONText').get('isAttendanceStatus').setValue(true);
  }

  private getAllData() {
    this.setGroupByList();
    this.getCompany();

  }

  getCompany() {
    this.attendanceService.getCompanies().subscribe((response: any[]) => {

      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }
  getContractors() {
    //
    this.attendanceService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }
  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.attendanceService.getContractorsbydefault(userId, roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].Contractors);
      }
    });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
    ];
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  getPlants(companyCode: any): void {
    this.attendanceService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
    });
  }


  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  public onContractorSaveEmitter(data: any) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      if (formValue.JSONText.Contractors != null) {
        const requestObj = {
          company_Code: formValue.JSONText.Company_Code,
          plant_Code: formValue.JSONText.Plant_Code,
          unit_Code: formValue.JSONText.Unit_Code || null,
          Contractor_Code: formValue.JSONText.Contractors,
          SDate: moment(formValue.JSONText.dateRange[0]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
          EDate: moment(formValue.JSONText.dateRange[1]).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
          UserID : GLOBAL.USER_ID
        };

        this.attendanceService.getActiveLabourSyc(requestObj).subscribe((response: any) => {
          this.openReportViewModal(response);
        });
      } else {
        this.logHelperService.logError({
          message: 'Please Select Contractor First'
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select Date First'
      });
    }
  }
  openReportViewModal(response: any[]): void {
    const formValue = this.formGroup.getRawValue();
    // tslint:disable-next-line: indent
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
      formValue.JSONText.SDate = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    }
    const modalRef = this.modalService.open(LabourAttendanceReprocessComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.selectedReportData = formValue.JSONText;
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.save.subscribe(() => {
      modalRef.close('');
      this.formGroup.reset();
    });
  }

  public onFilterTab(filterObj: any): void {

    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
        .get('Contractors').value.length > 1)) {
      this.logHelperService.logError({
        message: 'Please first fullfill data in contractor.'
      });
      return;
    }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();

  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: any) {
    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.entryForThePrsenter.bindForm(this.formGroup, filterObj);
  }

  private openModal() {

    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      {
        id: 2,
        name: 'Company',
        active: false
      },
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }


  bindCompanyTree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.PlantTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
            currentname: 'company', isActive: false, selected: false,
            children: this.bindPlantTree(subDataFilter, val.Company_Code, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
            currentname: 'company', isActive: false, selected: false, checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindPlantTree(filteredData, companyCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val: any, key) => {
      const subDataFilter = val.UnitTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            companyCode,
            id: val.Company_Code,
            plantCode: val.Plant_Code,
            name: val.Name,
            currentname: 'plant',
            isActive: false,
            selected: false,
          }
        );
      } else {
        convertedData.push(
          {
            companyCode,
            id: val.Company_Code,
            plantCode: val.Plant_Code,
            name: val.Name,
            currentname: 'plant',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }
}
