import { GatePassRequestsService } from '../../gate-pass-requests.service';
import { NullTemplateVisitor } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';

@Component({
  selector: 'app-debarred-labour',
  templateUrl: './debarred-labour.component.html',
  styleUrls: ['./debarred-labour.component.css']
})
export class DebarredLabourComponent implements OnInit {
  @Input() params: any;
  fromGroup: FormGroup;
  datePickerPickerMode: any;
  datePickerPlaceHolder: any;
  role = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  IsEndDateDisable = true;

  public editObj: any;

  constructor(public activeModal: NgbActiveModal, public sharedService: SharedService,
    private service: GatePassRequestsService,
    private fb: FormBuilder,
    private logHelperService: LogHelperService) { }
  ngOnInit() {
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.fromGroup = this.fb.group({
      SuspensionType: ['1', [Validators.required]],
      FromDate: [new Date(), [Validators.required]],
      ToDate: [null],
      UserID: GLOBAL.USER_ID,
      ULC: this.params.data.ULC,
      GatePassNo: this.params.data.GatePassNo,
      Reason: [null, [Validators.required]]
    })
    this.getsuspendLabour();
  }

  filterData() {
    this.activeModal.close();
  }

  getsuspendLabour() {
    this.service.getsuspendLabour(this.params.data.ULC).subscribe((res) => {
      if (res) {
        this.editObj = res[0];
        this.editObj.SuspensionType = res[0].IsSuspended ? '1' : '2';
        this.fromGroup.patchValue({
          SuspensionType: res[0].IsSuspended ? '1' : '2',
          FromDate: res[0].StartDate,
          ToDate: res[0].EndDate,
          UserID: GLOBAL.USER_ID,
          ULC: this.params.data.ULC,
          GatePassNo: this.params.data.GatePassNo,
          Reason: res[0].Reason
        });

        // this.fromGroup.get('SuspensionType').disable();
      }
    })
  }

  suspendLabour() {
    const data = this.fromGroup.getRawValue();
    if (!this.fromGroup.valid) {
      this.logHelperService.logError({
        message: 'Please fill all required fields.'
      });
      return;
    }

    if(this.editObj && this.editObj.SuspensionType === '1' && data.SuspensionType === '2'){
      this.logHelperService.logError({
        message: 'Record is already Debar.'
      });
      return;
    }
    if(this.editObj && this.editObj.SuspensionType === '2' && data.SuspensionType === '1'){
      this.logHelperService.logError({
        message: 'Record is already Suspended.'
      });
      return;
    }

    this.service.suspendLabour(data).subscribe(() => {
      const type = +data.SuspensionType == 1 ? 'Debarrded' : 'Suspend';
      this.logHelperService.logSuccess({ message: `Associate ${type} Successfully!` });
      this.activeModal.close();
      this.fromGroup.reset();
    })
  }

  typeChange(val) {
    if (val == 1) {
      this.fromGroup.get('ToDate').disable();
      this.fromGroup.get('ToDate').setValue(null);
      this.IsEndDateDisable = true;
      this.fromGroup.get('ToDate').clearValidators();
      this.fromGroup.controls["ToDate"].updateValueAndValidity();
    } else {
      this.IsEndDateDisable = false;
      this.fromGroup.get('ToDate').enable();
      this.fromGroup.get('ToDate').setValidators(Validators.required);
      this.fromGroup.controls["ToDate"].updateValueAndValidity();
    }

  }
}
