import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "src/app/core/services";
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from "ng-pick-datetime";
import { Contractor } from "src/app/attendance/attendance-approve/attendance-approve.model";
import { MY_MOMENT_FORMATS } from "src/app/core/core.module";
import { EntryForThePrsenter } from "../../entryfortheday/entryfortheday-presenter/entryfortheday.presenter";
import { PaySlipService } from "./pay-slip.service";
declare var $;

@Component({
  selector: 'app-pay-slip',
  templateUrl: './pay-slip.component.html',
  styleUrls: ['./pay-slip.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: "en-IN" },
    PaySlipService
  ],
})
export class PaySlipComponent implements OnInit {

  formGroup: FormGroup;
  filterNameFormGroup: FormGroup;
  companyList: any[] = [];
  plantList: any[] = [];
  stateList: any[] = [];
  contractorsList: any[] = [];
  contractorWorkOrderList: any[] = [];
  subContractorList: any[] = [];
  subContractorWorkOrderList: any[] = [];

  activeFilter: string;
  selectedTabId: any;
  filterTabList: any[] = [];
  selectedFilterData: any = {};

  contAndSubContList: any[] = [
    { contAndSubCont: 1, name: "Only Contractor" },
    { contAndSubCont: 2, name: "Only SubContractor" },
    { contAndSubCont: 3, name: "Both" },
  ];

  userId = localStorage.getItem("UserID");

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    public apiCallService: PaySlipService,
    private entryForThePrsenter: EntryForThePrsenter,
  ) { }

  ngOnInit() {
    const currentMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const currentMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);

    this.formGroup = this.fb.group({
      DateRange: new FormControl([currentMonthStart, currentMonthEnd]),
      ContAndSubCont: new FormControl(null, Validators.required),
      Company: new FormControl(null, Validators.required),
      Plants: new FormControl(null, Validators.required),
      State: new FormControl(null, Validators.required),
      Contractors: new FormControl(null, Validators.required),
      WorkOrder: new FormControl(null),
      SubContractors: new FormControl(null),
      SubWorkOrder: new FormControl(null),
    });

    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.getAllData();
    this.manageVisibilityOfSteps();
  }

  getAllData() {
    this.getCompany();
    this.formGroup.get("ContAndSubCont").setValue(3);
    const roleCode = JSON.parse(localStorage.getItem("Role"))[0].Role_Code;
    if (this.userId && roleCode === 119) {
      this.getContractorsSelectedByDefault(this.userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  getCompany() {
    this.apiCallService.getCompanies().subscribe((response: any[]) => {
      this.companyList = response;
      const companyCode = this.selectedFilterData.Company_Code;
      if (companyCode) {
        this.formGroup.patchValue({ Company: companyCode });
        this.getPlants(companyCode);
      } else if (this.companyList.length === 1) {
        const singleCompany = this.companyList[0].Company_Code;
        this.formGroup.get("Company").disable();
        this.formGroup.patchValue({ Company: singleCompany });
        this.getPlants(singleCompany);
      }
    });
  }

  getPlants(companyCode: any): void {
    this.apiCallService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      const plantCode = this.selectedFilterData.Plant_Code;
      if (plantCode) {
        this.getState(plantCode);
      } else if (this.plantList.length === 1) {
        const singlePlantCode = this.plantList[0].Plant_Code;
        this.formGroup.patchValue({ Plants: singlePlantCode });
        this.getState(singlePlantCode);
      }
    });
  }

  getState(plantCode: any): void {
    this.apiCallService.getStatesList(plantCode).subscribe((response: any[]) => {
      this.stateList = response;
      if (this.stateList.length === 1) {
        const stateCode = this.stateList[0].State_Code;
        this.formGroup.patchValue({ State: stateCode });
      }
    });
  }
  selectedContractor: any
  getContractorsSelectedByDefault(userId: any, roleCode: any) {
    this.apiCallService.getContractorsbydefault(userId, roleCode).subscribe((response) => {
      this.contractorsList = response;
      this.selectedContractor = this.contractorsList && this.contractorsList.length ? this.contractorsList[0] : null;
      if (this.selectedFilterData && this.selectedFilterData.Contractors) {
        this.formGroup.patchValue({ Contractors: this.selectedFilterData.Contractors });
        this.getWorkOrdersByContractorCode(this.selectedFilterData.Contractors, this.selectedFilterData.plantcode);
      } else if (this.contractorsList.length === 1) {
        this.formGroup.patchValue({ Contractors: this.contractorsList[0].ContractorVendor_Code });
        this.getWorkOrdersByContractorCode(this.contractorsList[0].ContractorVendor_Code, this.selectedFilterData.plantcode);
      }
    }
    );
  }

  getContractors(): void {
    this.apiCallService.getContractors().subscribe((response) => {
      this.contractorsList = response;
      if (this.selectedFilterData.Contractors) {
        this.formGroup.patchValue({ Contractors: this.selectedFilterData.Contractors });
        this.getWorkOrdersByContractorCode(this.selectedFilterData.Contractors, this.selectedFilterData.Plant_Code);
      }
    });
  }

  getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.apiCallService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrderList = response;
      if (this.selectedFilterData.WorkOrder_Code) {
        this.formGroup.patchValue({ WorkOrder: this.selectedFilterData.WorkOrder_Code });
        this.getSubContractors(this.selectedFilterData.WorkOrder_Code);
      }
      else if (this.contractorWorkOrderList.length === 1) {
        this.formGroup.patchValue({ WorkOrder: this.contractorWorkOrderList[0].WorkOrder_Code });
        this.getSubContractors(this.contractorWorkOrderList[0].WorkOrder_Code);
      }
    });
  }

  getSubContractors(workOrderNo: string = ""): void {
    this.apiCallService.getContractors("", workOrderNo).subscribe((response: any[]) => {
      this.subContractorList = response;
      const subContractorCode = this.selectedFilterData.SubContractors || (this.subContractorList.length === 1 ? this.subContractorList[0].ContractorVendor_Code : null);
      this.formGroup.patchValue({ SubContractors: subContractorCode });
      if (subContractorCode) {
        this.getSubWorkOrdersBySubContractorCode(subContractorCode, this.selectedFilterData.plantCode || null);
      }
    });
  }

  getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.apiCallService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrderList = response;
      const subWorkOrderControl = this.formGroup.controls['SubWorkOrder'];
      if (!subWorkOrderControl) {
        return;
      }

      const selectedCode = this.selectedFilterData.SubWorkOrder_Code;

      if (selectedCode) {
        subWorkOrderControl.patchValue(selectedCode);
      } else if (this.subContractorWorkOrderList.length === 1) {
        subWorkOrderControl.patchValue(this.subContractorWorkOrderList[0].WorkOrder_Code);
      }
    });
  }

  onCompanyChange() {
    const companyCode = this.formGroup.controls['Company'].value;
    this.formGroup.patchValue({ Plants: null });
    this.selectedFilterData.Plant_Code = null;
    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  onPlantChange() {
    const plantCode = this.formGroup.controls['Plants'].value;
    if (plantCode) {
      this.getState(plantCode);
    }
  }

  onContractorChange($event: any) {
    this.selectedContractor = $event;

    this.formGroup.patchValue({
      WorkOrder: null,
      SubContractors: null,
      SubWorkOrder: null,
    });

    Object.assign(this.selectedFilterData, {
      WorkOrder: null,
      SubContractors: null,
      SubWorkOrder: null,
    });

    this.contractorWorkOrderList = [];
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];

    const contractorCode = this.formGroup.controls['Contractors'].value;
    const plantCode = this.formGroup.controls['Plants'].value;

    if (contractorCode) {
      ['WorkOrder', 'License_Code', 'WCPolicy', 'SubContractors', 'SubWorkOrder']
        .forEach(control => this.formGroup.controls[control].enable());
      this.getWorkOrdersByContractorCode(contractorCode, plantCode);
    } else {
      ['WorkOrder', 'License_Code', 'WCPolicy', 'SubContractors', 'SubWorkOrder']
        .forEach(control => this.formGroup.controls[control].disable());
    }
  }

  onWorkOrderChange() {
    const workOrderNo = this.formGroup.controls['WorkOrder'].value;
    this.formGroup.patchValue({
      SubContractors: null,
      SubWorkOrder: null,
    });

    Object.assign(this.selectedFilterData, {
      SubContractors: null,
      SubWorkOrder: null,
    });

    this.subContractorList = [];
    this.subContractorWorkOrderList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
    }
  }

  selectedSubContractor: any
  onSubContractorChange($event: any) {
    this.selectedSubContractor = $event;

    const subContractorCode = this.formGroup.controls['SubContractors'].value;
    const plantCode = this.formGroup.controls['Plants'].value;

    this.formGroup.patchValue({
      SubWorkOrder: null,
    });

    Object.assign(this.selectedFilterData, {
      SubWorkOrder: null,
    });

    this.subContractorWorkOrderList = [];

    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantCode);
    }
  }

  getControl(controlName: string) {
    return this.formGroup.get(controlName);
  }

  onFilterTab(filterObj: any): void {
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  clearData() {
    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {};
    this.manageVisibilityOfSteps();
  }

  manageVisibilityOfSteps() {
    const children = $("#content-container").children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css("display", "");
        item.active = true;
      } else {
        $(children[index]).css("display", "none");
        item.active = false;
      }
    });
  }

  createFilterTab(): void {
    this.filterTabList = [
      { id: 1, name: "CRITERIA", active: true },
      { id: 2, name: "COMPANY", active: false },
      { id: 3, name: "CONTRACTOR", active: false },
      { id: 4, name: "SUB-CONTRACTOR", active: false },
    ];
  }
}
