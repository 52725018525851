import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import * as moment from 'moment';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeComponent } from 'ng-pick-datetime';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GLOBAL } from 'src/app/app.globals';
import { EnumRoleType } from 'src/app/contractor/const/enum';
import { MasterItemCode } from 'src/app/contractor/contractor.enum';
import { AgActionDeleteCellRendererComponent, Checklist, FileDoc, WizardStep } from 'src/app/core/components';
import { AgFilterCellRenderComponent } from 'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { SharedService } from 'src/app/core/services';
import { SweetAlertOptions } from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { WorkOrdersService } from '../../work-orders.service';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-work-order-form-wizard',
  templateUrl: './work-order-form-wizard.component.html',
  styleUrls: ['./work-order-form-wizard.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class WorkOrderFormWizardComponent implements OnInit, AfterViewInit, OnDestroy {
  isContractore: any = localStorage.getItem('RoleCode');
  enumRoleType: any = EnumRoleType;
  @Output() public closeModal: EventEmitter<string>;
  @ViewChild('workOrderForm') public workOrderForm: NgForm;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  @ViewChild('select') select: NgSelectComponent;
  @ViewChild('areaCoordinatorSelect') areaCoordinatorSelect: NgSelectComponent;
  @ViewChild('OrderComplitedDate1') orderComplitedDate: OwlDateTimeComponent<any>;

  // @ViewChild('EICUser') eicUserElement: ElementRef;
  public eicUserModel = {
    EICUserID: null
  };

  public workOrder: any = {};
  public editMode = false;
  public isSubContractor = false;
  headerTitleName:string='';
  // datepicker control config
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public dateTimeRange: Date[];
  public checkList: Checklist[] = [];
  public wizardSteps: WizardStep[] = [];
  public companyList: any[] = [];
  public contractorList: any[] = [];
  public contractorWorkOrderList: any[] = [];
  public subContractorList: any[] = [];
  public plantList: any[] = [];
  public orderTypeList: any[] = [];
  public orderCategoryList: any[] = [];
  public natureOfWorkList: any[] = [];
  public workSiteAreaList: any[] = [];
  public workTypes: any[] = [];
  public eICUserList: any[] = [];
  public executingDepartmentList: any[] = [];
  public departmentHeadList: any[] = [];
  public areaCoordinatorList: any[] = [];
  public poAgainstLOIList: any[] = [];
  public eicUserList: any[] = [];

  public documentTypes = [];
  public uploadedFiles: FileDoc[] = [];
  public woOwnerRowDataColumnDefs: any;

  // to disabled control
  public isCompanyDisabled: boolean;
  public isContractorDisabled: boolean;

  public workOrderNo: any;

  // grid config
  public woOwnerRowData: any[] = [];
  public woOwnerGridOptions: any;
  public companyPolicyRowData: any[] = [];
  public companyPolicyGridOptions: any;
  public woOwnerGridApi: any;
  public companyPolicyGridApi: any;

  public contractorConfig: any;
  public subContractorConfig: any;
  public workOrderConfig: any;
  public poAgainstLOIConfig: any;
  public departmentHeadConfig: any;
  public eicUserConfig: any;
  public areaCoordinatorConfig: any;
  public ecPolicyConfig: any;

  public companyPolicyValue: any;
  public eicUserValue: any;
  public hrcApplicableList: any[] = [
    {
      label: 'Partial',
      value: '1'
    },
    {
      label: 'Yes',
      value: '2'
    },
    {
      label: 'No',
      value: '3'
    }
  ];

  public isValidStartDate = false;
  public minDate: Date = new Date();
  public minEndDate: Date;
  public isValidEndDate = false;
  public minComplitedDate: Date;
  public alertOption: SweetAlertOptions = {};
  private isAreaCoordinatorTouched = false;
  private isSubContractorTouched = false;
  private isPOAgainstLOITouched = false;
  private isContractorTouched = false;
  private isDepartmentHeadTouched = false;
  private isEicUserTouched = false;
  private isCompanyPolicyTouched = false;
  private onDestroy: Subject<any>;
  private mainContractorWO: any = {};
  public globalSettings: any;
  public isHidePOInput: boolean = true;
  public isContractor = false;
  public allowedFileTypes: any = '';
  public maxSizeAllowed: any = '';
  public contractorCode: any = '';
  public orderType: any = '';
  public allowedStartDate:any='';
  public min_OrderStartDate: any = '';
  public min_OrderEndDate: any = '';
  public max_OrderStartDate: any = '';
  public max_OrderEndDate: any = '';

  roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  userId = GLOBAL.USER_ID;

  constructor(private sharedService: SharedService,
    private workOrderService: WorkOrdersService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      this.sharedService.setGlobalSettings(res);
      if (res) {
        this.globalSettings = res.Data.Table;
        const workOrderSetting = this.globalSettings.find(item => item.Code === 'MOD119PAG191CONWOSER');
        const workOrderSettings = this.globalSettings.find(item => item.Code === 'MOD119PAG191CONWOEC');

        if (workOrderSetting && workOrderSetting.Value === '1') {
          this.isHidePOInput = false;
        }
        else if (workOrderSettings && workOrderSettings.Value === '0') {
          this.isHidePOInput = true;
        }
        this.maxSizeAllowed = Number(this.globalSettings.find(item => item.Code === 'DOCSIZEWO1').Value) * 1024;
        this.allowedFileTypes = this.globalSettings.find(item => item.Code === 'DOCFORMATWO1').Value;
        this.allowedStartDate = this.globalSettings.find(item => item.Code === 'MOD119PG191WODSVSTD').Value;
      }
    });
    const roles = JSON.parse(localStorage.getItem('Role'));
    this.isContractor = roles.some(item => item.Role_Code === 119);
    this.onDestroy = new Subject();
    this.closeModal = new EventEmitter();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.createWOOwnerGridConfig();
    this.createCompanyPolicyGridConfig();
    this.createConfigForContractor();
    this.createConfigForSubContractor();
    this.createConfigForWorkOrder();
    this.createConfigForPOAgainstLOI();
    this.createConfigForDepartmentHead();
    this.createConfigForAreaCoordinator();
    this.createConfigForEICUser();
    this.createConfigForCompanyPolicy();
  }

  public ngOnInit() {
    if (this.checkContractorType()) {
      this.bindWizardSteps();
      setTimeout(() => {
        this.getWorkOrderDetails();
        this.getDocumentTypesPageModuleWise();
        this.bindCheckList();
        // this.setDefaultControlValue();
        // this.getAllData();
        window.addEventListener('scroll', this.onScroll, true);

        if (this.isSubContractor) {
          // this.workOrderForm.controls['WorkOrderNo'].disable();
        }

      }, 10);
    }

  }

  public ngAfterViewInit(): void {
    this.workOrderForm.reset();
  }

  public ngOnDestroy() {
    this.onDestroy.next(true);
    window.removeEventListener('scroll', this.onScroll, true);
  }

  public generateWONo(contractorVendorCode: any): void {
    this.workOrderService.generateWONo(contractorVendorCode).pipe(takeUntil(this.onDestroy)).
      pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
        this.workOrderForm.controls['WorkOrderNo'].patchValue(response);
      });
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  validatePriority(event: any): void {
    const value = event.target.value;
    if (!/^\d$/.test(value)) {
      event.target.value = value.slice(0, -1);  // Remove the last character if it's not a number
    }
  }
  
  public onSubmit(formValue: any, saveAndNew: boolean): void {
    if (this.woOwnerRowData.length === 0) {
      this.showAlert('error', 'Please add EIC user.', 'Error!!!');
      return;
    }

    let workOrderObj: any;
    workOrderObj = { ...this.workOrder, ...formValue };
    const workOrderNo = workOrderObj.WorkOrderNo;
    const workOrderCode = workOrderObj.WorkOrder_Code || '';
    workOrderObj.UserId = GLOBAL.USER_ID;
    workOrderObj.WorkOrderCompanyPolicyViewModals = this.companyPolicyRowData;
    workOrderObj.OrderStartDate = new Date((new Date(workOrderObj.OrderStartDate)).getTime() + (60 * 60 * 24 * 1000));
    this.workOrderService.checkWONoAvailability(workOrderCode, workOrderNo).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any) => {
        if (response.Data === 0) {
          workOrderObj.OrderOwnerUserAndPrioritys = this.woOwnerRowData;
          workOrderObj.IsDirect = true;
          if (this.isSubContractor) {
            workOrderObj.IsDirect = false;
          }

          workOrderObj.WorkOrderCompanyPolicyViewModals = this.companyPolicyRowData;
          workOrderObj.DocumentImageMasterViewModals = [];
          if (workOrderObj.WorkOrder_Code) {
            this.workOrderService.updateWorkOrder(workOrderObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                this.saveDocuments(response, saveAndNew, false);
              }
            );
          } else {
            this.workOrderService.addWorkOrder(workOrderObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                this.saveDocuments(response, saveAndNew, true);
              }
            );
          }
        } else {
          this.alertOption = {
            title: 'Error!!!',
            html: `<span class='customClass'>Work Order number already exist. Please try different work order detail</span>`,
            type: 'error'
          };
          this.openAlert();
        }
      });
  }

  private saveDocuments(response: any, saveAndNew: boolean, isAdd: boolean) {
    const uploadParams = {
      WorkOrder_Code: response
    };
    const filesToUpload = this.getNotUploadedItems(this.uploadedFiles);
    if (uploadParams.WorkOrder_Code && filesToUpload.length > 0) {
      this.workOrderService.uploadDocuments(filesToUpload, uploadParams)
        .pipe(takeUntil(this.onDestroy)).subscribe((res) => {
          if (saveAndNew) {
            this.showAddWorkOrderInfo(isAdd, false);
            this.resetFormAfterSave();
          } else {
            this.showAddWorkOrderInfo(isAdd, true);
          }
        });
    } else if (saveAndNew) {
      this.showAddWorkOrderInfo(isAdd, false);
      this.resetFormAfterSave();
    } else {
      this.showAddWorkOrderInfo(isAdd, true);
    }
  }

  private showAddWorkOrderInfo(isAdd: boolean, redirect: boolean) {
    const message = isAdd ? 'Work order is successfully added.' : 'Work order is successfully updated.';
    this.alertOption = {
      title: 'Success!!!',
      html: `<span class='customClass'>${message}</span>`,
      type: 'success',
      timer: 2000
    };
    this.openAlert();
    if (redirect) {
      setTimeout(() => {
        this.router.navigate(['/contractor/work-orders']);
      }, 3000);
    }
  }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }

  public onCloseModal(reason: string) {
    this.closeModal.emit(reason);
  }

  public saveAndNew(data): void {
    this.onSubmit(this.workOrderForm.value, true);
  }

  public updateAndNew(): void {
    this.onSubmit(this.workOrderForm.value, true);
  }

  public clearForm(): void {
    this.workOrderForm.reset();
  }

  public onCancel() {
    this.router.navigate(['/contractor/work-orders']);
  }

  public onCompanyChange(): void {
    const companyCode = this.workOrderForm.controls['Company_Code'].value;
    if (companyCode) {
      this.getPlants(companyCode);
    } else {
      this.plantList = [];
    }
  }

  public onOrderTypeChange(data: any): void {
    // this.orderType = this.workOrderForm.controls['OrderTypeMaster_Code'].value;
    // if (this.orderType === 228) {
    //   this.workOrderForm.controls['LOIRef_WorkOrder_Code'].disable();
    // } else {
    //   this.workOrderForm.controls['LOIRef_WorkOrder_Code'].enable();
    //   if (this.contractorCode !== '' && this.orderType !== '') {
    //     this.getPOAgainstLOIList(this.contractorCode, this.orderType);
    //   }
    // }
  }

  public onContractorChange(data: any): void {
    this.isContractorTouched = true;
    this.contractorWorkOrderList = [];
    this.subContractorList = [];
    this.contractorCode = this.workOrderForm.controls['ParentContractorVendor_Code'].value;
    this.workOrder.ParentWorkOrder_Code = null;
    if (this.isSubContractor) {
      this.workOrderForm.controls['ParentWorkOrder_Code'].patchValue(null);
      this.workOrderForm.controls['ChildContractorVendor_Code'].patchValue(null);
      this.workOrder.ChildContractorVendor_Code = null;
    }
    if (this.orderType !== '' && this.contractorCode !== '') {
      // this.getPOAgainstLOIList(this.contractorCode, this.orderType);
    }
    if (this.contractorCode && this.isSubContractor) {
      this.generateWONo(this.contractorCode);
      this.getContractorWorkOrder(this.contractorCode);
      this.getSubContractorsByExcludeCode(this.contractorCode);
    }
  }

  public onSubContractorChange(data: any): void {
    this.isSubContractorTouched = true;
  }

  public onPOAgainstLOIChange(data: any): void {
    this.isPOAgainstLOITouched = true;
  }

  public onAreaCoordinatorChange(data: any): void {
  }

  public onDepartmentHeadChange(data: any): void {
    if (!data.userList) return;
    this.isDepartmentHeadTouched = true;
    this.workOrder.HODUser_Code = data.userList.UserCode;
  }

  public onCompanyPolicyChange(data: any): void {
    if (!data.fieldData.ecPolicy) return;
    this.isCompanyPolicyTouched = true;
    this.companyPolicyValue = data.fieldData.ecPolicy;
  }

  public onContractorWOChange(data: any) {
    const workOrderCode = this.workOrderForm.controls['ParentWorkOrder_Code'].value;
    this.workOrderService.getWorkOrderDetails(workOrderCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      let workOrder: any;
      if (response.length > 0) {
        workOrder = response[0];
        this.mainContractorWO = workOrder;
        this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(workOrder.OrderTypeMaster_Code ? parseInt(workOrder.OrderTypeMaster_Code) : workOrder.OrderTypeMaster_Code);
        this.workOrderForm.controls['OrderCategoryMaster_Code'].patchValue(workOrder.OrderCategoryMaster_Code ? parseInt(workOrder.OrderCategoryMaster_Code) : workOrder.OrderCategoryMaster_Code);
        this.workOrderForm.controls['OrderStartDate'].patchValue(workOrder.OrderStartDate);
        this.min_OrderStartDate = workOrder.OrderStartDate;
        this.max_OrderStartDate = new Date((new Date(workOrder.OrderEndDate)).getTime() - (60 * 60 * 24 * 1000));
        this.workOrderForm.controls['OrderEndDate'].patchValue(workOrder.OrderEndDate);
        this.min_OrderEndDate = new Date((new Date(workOrder.OrderStartDate)).getTime() + (60 * 60 * 24 * 1000));
        this.max_OrderEndDate = workOrder.OrderEndDate;
        this.isValidStartDate = true;
        this.minEndDate = new Date((new Date(workOrder.OrderStartDate)).getTime() + (60 * 60 * 24 * 1000));
        this.orderComplitedDate.dtInput.min = workOrder.OrderStartDate;
        this.orderComplitedDate.dtInput.max = workOrder.OrderEndDate;
        this.workOrderForm.controls['OrderComplitedDate'].patchValue(workOrder.OrderComplitedDate);
        this.workOrderForm.controls['Person'].patchValue(workOrder.Person);
        this.workOrderForm.controls['NatureOfWorkMasterListCodes'].patchValue(workOrder.NatureOfWorkMasterListCodes);
        this.workOrderForm.controls['AreaOfWorkMasterListCodes'].patchValue(workOrder.AreaOfWorkMasterListCodes);
        this.workOrderForm.controls['Department_Code'].patchValue(workOrder.Department_Code ? parseInt(workOrder.Department_Code) : workOrder.Department_Code);
        this.workOrderForm.controls['WorkTypeMaster_Code'].patchValue(workOrder.WorkTypeMaster_Code ? parseInt(workOrder.WorkTypeMaster_Code) : workOrder.WorkTypeMaster_Code);
        this.workOrderForm.controls['HODUser_Code'].patchValue(workOrder.HODUser_Code ? parseInt(workOrder.HODUser_Code) : workOrder.HODUser_Code);
        const wrk = parseInt(this.workOrder.LOIRef_WorkOrder_Code, 16);
        this.workOrder.HODUser_Code = workOrder.HODUser_Code;
        this.workOrder.LOIRef_WorkOrder_Code = wrk;
        this.workOrder.AreaCoordinatorUser_Code = workOrder.AreaCoordinatorUser_Code;
      }

    });
  }

  public onGatePassChange(): void {
    if (this.isSubContractor && this.mainContractorWO && this.mainContractorWO.Person) {
      const noOfGatePass = this.workOrderForm.controls['Person'].value;
      if (parseInt(noOfGatePass, 16) > this.mainContractorWO.Person) {
        this.workOrderForm.controls['Person'].patchValue('');
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>No. of gate pass should be less than main contractor work order's no. of gate pass.</span>`,
          type: 'error'
        };
        this.openAlert();
      }
    }
  }

  public onEICUserChange(data: any): void {
    if (!data) return;
    this.isEicUserTouched = true;
    this.eicUserValue = data;
  }

  public addEICUserDetails(priorityRef: any): void {
    if (!this.eicUserModel.EICUserID) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please select any EIC user.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else if (!priorityRef.value) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please enter priority.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else {
      const priority = parseInt(priorityRef.value, 10);
      if (priority <= 5 && priority > 0) {
        const checkExistUser = this.woOwnerRowData.find(rc => rc.OrderOwnerUser_Code === this.eicUserModel.EICUserID);
        const checkExistUserPriority = this.woOwnerRowData.find(rc => rc.PriorityLevel === priority);
        if (!checkExistUser && !checkExistUserPriority) {
          const eicUserObj = this.eICUserList.find(user => user.UserID === this.eicUserModel.EICUserID);
          if (eicUserObj) {
            this.woOwnerRowData.push(
              {
                OrderOwnerUser_Code: this.eicUserModel.EICUserID,
                PriorityLevel: priority,
                UserName: eicUserObj.UserName,
                EmailID: eicUserObj.AlternetEmailID
              });
            // this.woOwnerRowData = [...this.woOwnerRowData];
            this.woOwnerRowData = Array.from(this.woOwnerRowData);
            priorityRef.value = '';
            this.eicUserModel.EICUserID = null;
          }
        } else if (checkExistUser) {
          this.alertOption = {
            title: 'OOPS!!!',
            html: `<span class='customClass'>This user is already exist.</span>`,
            type: 'error'
          };
          this.openAlert();
        } else if (checkExistUserPriority) {
          this.alertOption = {
            title: 'OOPS!!!',
            html: `<span class='customClass'>This priority is already exist.</span>`,
            type: 'error'
          };
          this.openAlert();
        }
      } else {
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>Priority should be between 1 to 5.</span>`,
          type: 'error'
        };
        this.openAlert();
      }
    }
  }

  public deleteWOOwnerData(data: any): void {
    const index = this.woOwnerRowData.indexOf(data);
    if (index >= 0) {
      this.woOwnerRowData = this.woOwnerRowData.filter(item => item !== data);
    }
  }

  public addCompanyPolicy(maxPersonAllowedRef: any): void {

    if (!this.companyPolicyValue) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please select any company policy.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else if (!maxPersonAllowedRef.value) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please enter maximum allowed person.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else {
      const checkexistPolicy = this.companyPolicyRowData.
        find(rc => rc.CompanyECPolicy_Code === this.companyPolicyValue.CompanyECPolicy_Code);
      if (!checkexistPolicy) {
        const companyPolicyObj: any = {
          CompanyECPolicy_Code: this.companyPolicyValue.CompanyECPolicy_Code,
          CompanyName: this.companyPolicyValue.CompanyName,
          ECPolicyNo: this.companyPolicyValue.ECPolicyNo,
          InsurePerson: maxPersonAllowedRef.value,
          IssueDate: this.companyPolicyValue.IssueDate,
          NatureOfWork: this.companyPolicyValue.NatureOfWork,
        };
        this.companyPolicyRowData.push(companyPolicyObj);
        // this.companyPolicyRowData = [...this.companyPolicyRowData];
        this.companyPolicyRowData = Array.from(this.companyPolicyRowData);
        this.companyPolicyValue = null;
        maxPersonAllowedRef.value = '';
      } else {
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>This company policy is already exist.</span>`,
          type: 'error'
        };
        this.openAlert();
      }
    }
  }

  public deleteCompanyPolicyDetails(data: any): void {
    const index = this.companyPolicyRowData.indexOf(data);
    if (index >= 0) {
      this.companyPolicyRowData = this.companyPolicyRowData.filter(item => item !== data);
    }
  }

  public onStartDateChange(): void {
    if (this.workOrderForm.controls['OrderStartDate'].valid) {
      //const startDate = new Date((new Date(this.workOrderForm.controls['OrderStartDate'].value)).getTime() + (60 * 60 * 24 * 1000));
      //this.workOrderForm.controls['OrderStartDate'].patchValue(new Date((new Date(this.workOrderForm.controls['OrderStartDate'].value)).getTime() + (60 * 60 * 24 * 1000)));
      const startDate = this.workOrderForm.controls['OrderStartDate'].value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {

      } else if (startDate < new Date()) {
        if(this.allowedStartDate == 1){
          this.alertOption = {
            title: 'OOPS!!!',
            html: `<span class='customClass'>Please enter current date or greater than current date.</span>`,
            type: 'warning'
          };
          this.openAlert();
        }
      }
      const endDate = this.workOrderForm.controls['OrderEndDate'].value;
      this.isValidStartDate = true;
      this.minEndDate = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      //this.minEndDate = startDate;
      if (startDate > endDate) {
        if (this.workOrderForm.controls['OrderEndDate'].valid) {
          this.workOrderForm.controls['OrderEndDate'].patchValue('');
        }
      }
    } else {
      this.isValidStartDate = false;
      this.workOrderForm.controls['OrderEndDate'].patchValue('');
    }
  }

  public onEndDateChange(): void {
    if (this.workOrderForm.controls['OrderEndDate'].valid) {
      const endDate = this.workOrderForm.controls['OrderEndDate'].value;
      const complitedDate = this.workOrderForm.controls['OrderComplitedDate'].value;
      this.isValidEndDate = true;
      this.minComplitedDate = new Date((new Date(endDate)).getTime() + (60 * 60 * 24 * 1000));
      if (endDate > complitedDate) {
        if (this.workOrderForm.controls['OrderComplitedDate'].valid) {
          this.workOrderForm.controls['OrderComplitedDate'].patchValue('');
        }
      }
    } else {
      this.isValidEndDate = false;
      this.workOrderForm.controls['OrderComplitedDate'].patchValue('');
    }
  }

  public onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  private bindCheckList() {

    const checkListId = 1;
    this.checkList.push(
      {
        id: checkListId,
        text: 'Work Order Entry Checklist',
        open: true,
        childrens: [
          {
            text: 'Company Details',
            title: 'Company Details',
            desc: ' Plant, Executing Department and Department Head is required.',
            isValid: () => {
              const controlsToCheck = ['PlantCodes', 'Department_Code', 'HODUser_Code'];
              if (this.editMode) {
                const invalidFields = controlsToCheck.filter(controlName => this.workOrderForm.controls[controlName].invalid);
                return invalidFields.length === 0;
              }
              return controlsToCheck.every(controlName => this.workOrderForm.controls[controlName].valid);
            },
            hasError: () => {
              return (
                ((this.workOrderForm.controls.PlantCodes.dirty || this.workOrderForm.controls.PlantCodes.touched)
                  && this.workOrderForm.controls.PlantCodes.invalid)
                || ((this.workOrderForm.controls.HODUser_Code.dirty
                  || this.workOrderForm.controls.HODUser_Code.touched)
                  && this.workOrderForm.controls.HODUser_Code.invalid)
                || ((this.workOrderForm.controls.Department_Code.dirty
                  || this.workOrderForm.controls.Department_Code.touched)
                  && this.workOrderForm.controls.Department_Code.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Contractor',
            title: 'Contractor',
            desc: 'Contractor is required.',
            isValid: () => {
              return this.workOrderForm.controls.ParentContractorVendor_Code.valid;
            },
            hasError: () => {
              return ((this.workOrderForm.controls.ParentContractorVendor_Code.dirty
                || this.workOrderForm.controls.ParentContractorVendor_Code.touched)
                && this.workOrderForm.controls.ParentContractorVendor_Code.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: this.isSubContractor ? 'Sub-Contrator Purchase Order Details' : 'Purchase Order Details',
            title: this.isSubContractor ? 'Sub-Contrator Purchase Order Details' : 'Purchase Order Details',
            desc: 'Order type,Order Category, Start Date, End Date, No of gate pass, WO No, Nature of Job/Work is required.',
            isValid: () => {
              return (this.workOrderForm.controls.OrderTypeMaster_Code.valid
                && this.workOrderForm.controls.OrderCategoryMaster_Code.valid
                && this.workOrderForm.controls.WorkOrderNo.valid
                && this.workOrderForm.controls.NatureOfWorkMasterListCodes.valid
                && this.workOrderForm.controls.OrderStartDate.valid
                && this.workOrderForm.controls.OrderEndDate.valid
                && this.workOrderForm.controls.Person.valid);
            },
            hasError: () => {
              return (((this.workOrderForm.controls.OrderTypeMaster_Code.dirty
                || this.workOrderForm.controls.OrderTypeMaster_Code.touched)
                && this.workOrderForm.controls.OrderTypeMaster_Code.invalid)
                || ((this.workOrderForm.controls.NatureOfWorkMasterListCodes.dirty
                  || this.workOrderForm.controls.NatureOfWorkMasterListCodes.touched)
                  && this.workOrderForm.controls.NatureOfWorkMasterListCodes.invalid)
                || ((this.workOrderForm.controls.WorkOrderNo.dirty
                  || this.workOrderForm.controls.WorkOrderNo.touched)
                  && this.workOrderForm.controls.WorkOrderNo.invalid)
                || ((this.workOrderForm.controls.OrderCategoryMaster_Code.dirty
                  || this.workOrderForm.controls.OrderCategoryMaster_Code.touched)
                  && this.workOrderForm.controls.OrderCategoryMaster_Code.invalid)
                || ((this.workOrderForm.controls.OrderStartDate.dirty
                  || this.workOrderForm.controls.OrderStartDate.touched)
                  && this.workOrderForm.controls.OrderStartDate.invalid)
                || ((this.workOrderForm.controls.OrderEndDate.dirty
                  || this.workOrderForm.controls.OrderEndDate.touched)
                  && this.workOrderForm.controls.OrderEndDate.invalid)
                || ((this.workOrderForm.controls.Person.dirty
                  || this.workOrderForm.controls.Person.touched)
                  && this.workOrderForm.controls.Person.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Order Owner',
            title: 'Order Owner',
            desc: 'Order Owner is required.',
            isValid: () => {
              if (this.woOwnerRowData.length > 0) {
                return true;
              }
              return false;
            },
            hasError: () => {
              if (this.woOwnerRowData.length === 0 && this.eicUserModel.EICUserID) {
                return true;
              }
              return false;
            },
            isMandatory: true,
            isVisible: true,
            step: '2',
          }
        ]
      }
    );

    if (this.isSubContractor) {
      const workOrderCheckList = {
        text: 'Work Order',
        title: 'Work Order',
        desc: 'Work Order is required.',
        isValid: () => {
          return this.workOrderForm.controls.ParentWorkOrder_Code.valid;
        },
        hasError: () => {
          return ((this.workOrderForm.controls.ParentWorkOrder_Code.dirty
            || this.workOrderForm.controls.ParentWorkOrder_Code.touched)
            && this.workOrderForm.controls.ParentWorkOrder_Code.invalid);
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(2, 0, workOrderCheckList);

      const subContractorCheckList = {
        text: 'Sub-Contractor',
        title: 'Sub-Contractor',
        desc: 'Sub-Contractor is required.',
        isValid: () => {
          return this.workOrderForm.controls.ChildContractorVendor_Code.valid;
        },
        hasError: () => {
          return ((this.workOrderForm.controls.ChildContractorVendor_Code.dirty
            || this.workOrderForm.controls.ChildContractorVendor_Code.touched)
            && this.workOrderForm.controls.ChildContractorVendor_Code.invalid);
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(3, 0, subContractorCheckList);
    }
  }

  private bindWizardSteps() {
    this.wizardSteps = [
      {
        id: 'workOrderDetails',
        name: 'Purchase Order Details',
        isValid: () => {
          return this.wizard1Validation();
        },
      },
      {
        id: 'woOwnerEICUserDetails',
        name: 'PO Owner / EIC User',
        isValid: () => {
          return this.woOwnerRowData.length > 0;
        },
      }
    ];

    if (!this.isHidePOInput) {
      this.wizardSteps.push(
        {
          id: 'companyPolicyDetails',
          name: 'Company Details',
          isValid: () => {
            return this.wizard1Validation();
          },
        },
      );
    }
    this.wizardSteps.push(
      {
        id: 'documentDetails',
        name: 'Documents',
        isValid: () => { return true; }
      }
    );
  }

  private checkContractorType(): boolean {
    let isValidType = false;
    const id: string = this.activatedRoute.snapshot.params.id;
    if (id) {
      isValidType = true;
      this.editMode = true;
    } else {
      this.workOrder = {};
      const type: string = this.activatedRoute.snapshot.queryParamMap.get('type');
      if (type === '1' || type === '0') {
        this.isSubContractor = type === '0';
        isValidType = true;
      }
    }
    return isValidType;
  }

  private getWorkOrderDetails() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.workOrderService.getWorkOrderByWorkOrderCode(id).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
        if (response.length > 0) {
          this.workOrder = response[0];
          if (!this.workOrder.IsDirect) {
            this.isSubContractor = true;
          }
          // this.setDefaultControlValue();
          this.getAllData();
          this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(this.workOrder.OrderTypeMaster_Code);
          this.workOrderForm.controls['OrderCategoryMaster_Code'].patchValue(this.workOrder.OrderCategoryMaster_Code);
          this.workOrderForm.controls['NatureOfWorkMasterListCodes'].patchValue(this.workOrder.OrderCategoryMaster_Code);
          this.workOrderForm.controls['AreaOfWorkMasterListCodes'].patchValue(this.workOrder.AreaOfWorkMasterListCodes);
          this.workOrderForm.controls['Department_Code'].patchValue(this.workOrder.Department_Code);
          this.workOrderForm.controls['HODUser_Code'].patchValue(this.workOrder.HODUser_Code);
          this.workOrderForm.controls['WorkTypeMaster_Code'].patchValue(this.workOrder.WorkTypeMaster_Code);
          this.workOrderForm.controls['OrderStartDate'].patchValue(this.workOrder.OrderStartDate);
          this.workOrderForm.controls['OrderEndDate'].patchValue(this.workOrder.OrderEndDate);
          this.isValidStartDate = true;
          this.minEndDate = new Date((new Date(this.workOrder.OrderStartDate)).getTime() + (60 * 60 * 24 * 1000));
          this.isValidEndDate = true;
          this.minComplitedDate = new Date((new Date(this.workOrder.OrderEndDate)).getTime() + (60 * 60 * 24 * 1000));
          this.workOrderForm.controls['OrderComplitedDate'].patchValue(this.workOrder.OrderComplitedDate);
          this.workOrderForm.controls['Person'].patchValue(this.workOrder.Person);
          this.workOrderForm.controls['WorkOrderNo'].patchValue(this.workOrder.WorkOrderNo);
          this.headerTitleName = this.workOrder.WorkOrderNo;
          // this.woOwnerRowData = this.workOrder.OrderOwnerUserAndPrioritys ? [...this.workOrder.OrderOwnerUserAndPrioritys] : [];
          this.woOwnerRowData = this.workOrder.OrderOwnerUserAndPrioritys ? Array.from(this.workOrder.OrderOwnerUserAndPrioritys): [];
          // this.companyPolicyRowData = this.workOrder.WorkOrderCompanyPolicyViewModals ? [...this.workOrder.WorkOrderCompanyPolicyViewModals] : [];
          this.companyPolicyRowData = this.workOrder.WorkOrderCompanyPolicyViewModals ? Array.from(this.workOrder.WorkOrderCompanyPolicyViewModals) : [];
          this.uploadedFiles = [];
          if (this.workOrder.DocumentImageMasterViewModals) {
            this.workOrder.DocumentImageMasterViewModals.forEach((file) => {
              this.uploadedFiles.push({
                ID: file.ID,
                file: {
                  lastModified: file.ModifiedDate,
                  name: file.UploadFileName
                },
                documentTypeCode: file.DocumentTypeMasterList_Code,
                isUploaded: true,
                url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder
              });
            });
          }
        }
      });
    } else {
      this.setDefaultControlValue();
      this.getAllData();
    }
  }

  // step-1 code: contractor details

  private setDefaultControlValue() {
    this.workOrderForm.controls['Company_Code'].patchValue(null);
    this.workOrderForm.controls['ParentContractorVendor_Code'].patchValue(this.workOrder.ParentContractorVendor_Code);
    if (this.workOrderForm.controls['ParentWorkOrder_Code']) {
      this.workOrderForm.controls['ParentWorkOrder_Code'].patchValue(null);
    }
    if (this.workOrderForm.controls['ChildContractorVendor_Code']) {
      this.workOrderForm.controls['ChildContractorVendor_Code'].patchValue(null);
    }
    this.workOrderForm.controls['PlantCodes'].patchValue(null);
    this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(null);
    this.workOrderForm.controls['OrderCategoryMaster_Code'].patchValue(null);
    this.workOrderForm.controls['NatureOfWorkMasterListCodes'].patchValue(null);
    if (this.workOrderForm.controls['AreaOfWorkMasterListCodes']) {
      this.workOrderForm.controls['AreaOfWorkMasterListCodes'].patchValue(null);
    }
    this.workOrderForm.controls['Department_Code'].patchValue(null);
    this.workOrderForm.controls['HODUser_Code'].patchValue(null);
    this.workOrderForm.controls['WorkTypeMaster_Code'].patchValue(null);
    this.workOrder.OrderOwnerUserAndPrioritys = [];
    this.workOrder.DocumentImageMasterViewModals = [];

    this.woOwnerRowData = [];
    this.companyPolicyRowData = [];
    this.uploadedFiles = [];
  }

  private wizard1Validation() {
    let flag = false;
    if (this.workOrderForm.controls.Company_Code) {

      flag = this.workOrderForm.controls.PlantCodes.valid
        && this.workOrderForm.controls.ParentContractorVendor_Code.valid
        && this.workOrderForm.controls.OrderTypeMaster_Code.valid
        && this.workOrderForm.controls.OrderCategoryMaster_Code.valid
        && this.workOrderForm.controls.OrderStartDate.valid
        && this.workOrderForm.controls.OrderEndDate.valid
        && this.workOrderForm.controls.Person.valid;
      if (flag) {
        flag = this.checkValidEndDate();
      }
      if (flag && this.isSubContractor) {
        flag = this.workOrderForm.controls.ParentWorkOrder_Code.valid
          && this.workOrderForm.controls.ChildContractorVendor_Code.valid;
      }
    }

    return flag;
  }

  private checkValidEndDate() {
    if (this.workOrderForm.controls.OrderStartDate.value !== '' && this.workOrderForm.controls.OrderEndDate.value !== '') {
      const startDate: Date = new Date(this.workOrderForm.controls.OrderStartDate.value);
      const endDate: Date = new Date(this.workOrderForm.controls.OrderEndDate.value);
      if (startDate > endDate) {
        return false;
      }
    }
    return true;
  }

  // step-2 code: WO Owner
  private createWOOwnerGridConfig() {
    const woOwnerRowDataColumnDefs = [
      {
        headerName: 'EIC Name', field: 'UserName', width: 25,
        sortable: true
      },
      {
        headerName: 'Email ID', field: 'EmailID', width: 25,
        sortable: true

      },
      {
        headerName: 'Priority', field: 'PriorityLevel', width: 25,
        sortable: true
      },
      {
        headerName: 'Action', field: '', width: 25,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionDeleteCellRendererComponent,
        cellRendererParams: {
          deleteDisabled: this.isContractor
        }
      }
    ];
    this.woOwnerGridOptions = {
      columnDefs: woOwnerRowDataColumnDefs,
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 39,
      onGridReady: (params) => {
        this.onWOOwnerGridReady(params);
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      }
    };
  }

  // step-2 code: WO Owner
  private createCompanyPolicyGridConfig() {
    const columnDefs = [
      {
        headerName: 'Policy No', field: 'ECPolicyNo', width: 20,
        sortable: false
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 20,
        sortable: false
      },
      {
        headerName: 'Issue Date', field: 'IssueDate', width: 20,
        sortable: false
      },
      {
        headerName: 'Allowed Max Person', field: 'InsurePerson', width: 20,
        sortable: false
      },
      {
        headerName: 'Action', field: '', width: 20,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionDeleteCellRendererComponent,
        cellRendererParams: {
          deleteDisabled: this.isContractor
        }
      }
    ];

    this.companyPolicyGridOptions = {
      columnDefs,
      onGridReady: (params) => {
        this.onCopmanyPolicyGridReady(params);
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 39
    };
  }

  public onWOOwnerGridReady(params) {
    this.woOwnerGridApi = params.api; // To access the grids API
  }

  public onCopmanyPolicyGridReady(params) {
    this.companyPolicyGridApi = params.api; // To access the grids API
  }

  // it will all api data and assign it to respective list
  private getAllData() {

    this.workOrderService.getCompanies().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.companyList = response;
      if (!isNullOrUndefined(this.workOrder.Company_Code)) {

        this.workOrderForm.controls['Company_Code'].patchValue(this.workOrder.Company_Code);
        this.getPlants(this.workOrder.Company_Code);
      } else {
        this.workOrderForm.controls['Company_Code'].patchValue(null);
        if (this.companyList.length === 1) {
          this.workOrderForm.controls['Company_Code'].patchValue(this.companyList[0].Company_Code);
          // this.workOrderForm.controls['Company_Code'].disable();
          this.getPlants(this.companyList[0].Company_Code);
        }
      }
    });
    if (this.roleCode === 119) {
      this.getContractorsSelectedByDefault(this.userId, this.roleCode);
    } else {
      this.getContractors();
    }
    // if (!this.isSubContractor) {
    //   this.getPOAgainstLOIList();
    // }


    // commentted bcoz using -- SP_GetMasterItemListData_V2 API
    // this.workOrderService.getOrderTypes(MasterItemCode.ORDER_TYPE).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
    //   this.orderTypeList = response;
    //   // this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(this.workOrder.OrderTypeMaster_Code);

    //   // place this code from the getworktype method.
    //   this.workTypes = response;
    //   this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(this.workOrder.OrderTypeMaster_Code);

    // });

    // commentted bcoz using -- SP_GetMasterItemListData_V2 API
    // this.workOrderService.getOrderCategories(MasterItemCode.ORDER_CATEGORY).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
    //   this.orderCategoryList = response;
    //   console.log('old data', response);

    //   this.workOrderForm.controls['OrderCategoryMaster_Code'].patchValue(this.workOrder.OrderCategoryMaster_Code);
    // });

    this.workOrderService.getNatureOfWorks().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.natureOfWorkList = response;
      if (this.workOrder.NatureOfWorkMasterListCodes) {
        this.workOrderForm.controls['NatureOfWorkMasterListCodes'].patchValue(this.workOrder.NatureOfWorkMasterListCodes);
      }
      else {
        if (this.natureOfWorkList.length === 1) {
          this.workOrderForm.controls['NatureOfWorkMasterListCodes'].patchValue(this.natureOfWorkList[0].NatureOfWork_Code);
        }
      }
    });

    // commentted bcoz using -- SP_GetMasterItemListData_V2 API
    // this.workOrderService.getWorkSiteAreas(MasterItemCode.WORK_SITE_AREA).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
    //   this.workSiteAreaList = response;
    //   this.workOrderForm.controls['AreaOfWorkMasterListCodes'].patchValue(this.workOrder.AreaOfWorkMasterListCodes);
    // });
    this.workOrderService.getExecutingDepartments().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.executingDepartmentList = response.Data;
      if(this.workOrder.Department_Code){
        this.workOrderForm.controls['Department_Code'].patchValue(this.workOrder.Department_Code);
      }
      else{
          if(this.executingDepartmentList.length === 1){
              this.workOrderForm.controls['Department_Code'].patchValue(this.executingDepartmentList[0].Department_Code);
          }
      }
    });

    this.workOrderService.getDepartmentHeadList().subscribe((res: any) => {
        console.log("🚀 ~ WorkOrderFormWizardComponent ~ this.workOrderService.getDepartmentHeadList ~ res:", res)
        this.departmentHeadList = res;
        if(this.workOrder.HODUser_Code){
          this.workOrderForm.controls['HODUser_Code'].patchValue(this.workOrder.HODUser_Code);
        }
        else{
          if(this.departmentHeadList.length === 1){
                this.workOrderForm.controls['HODUser_Code'].patchValue(this.departmentHeadList[0].UserID);
          }
        }
      });

      this.getPOAgainstLOIList(null);


    this.workOrderService.getEICUsers(GLOBAL.USER.EMAIL_ID).subscribe((response: any) => {
      this.eICUserList = response;
    });

    // comment bcoz --- this same API call we have in getOrderType method so we can use it.
    // this.workOrderService.getWorkTypes(MasterItemCode.WORK_TYPE).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
    //   this.workTypes = response;
    //   this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(this.workOrder.OrderTypeMaster_Code);
    // });
    // this.workOrderService.getUserList().subscribe((response: any) => {
    //   this.areaCoordinatorList = response;
    //   this.workOrderForm.controls['AreaCoordinatorUser_Code'].patchValue(this.workOrder.AreaCoordinatorUser_Code);
    // });

    let worksOrderCodes:string = MasterItemCode.ORDER_TYPE+','+MasterItemCode.ORDER_CATEGORY+','+MasterItemCode.WORK_SITE_AREA; //+','+MasterItemCode.WORK_TYPE;

    this.workOrderService.getMasterItemListData_v2(worksOrderCodes)
        .subscribe((response:any) => {

          response.forEach(ele => {
            if(ele.Parent_Code == 226) {
              this.orderTypeList = ele.Values;
              this.workTypes = ele.Values;
              this.workOrderForm.controls['OrderTypeMaster_Code'].patchValue(this.workOrder.OrderTypeMaster_Code);
            }

            if(ele.Parent_Code == 230) {

              this.orderCategoryList = ele.Values;
              this.workOrderForm.controls['OrderCategoryMaster_Code'].patchValue(this.workOrder.OrderCategoryMaster_Code);

            }

            if(ele.Parent_Code == 612) {
              this.workSiteAreaList = ele.Values;
              this.workOrderForm.controls['AreaOfWorkMasterListCodes'].patchValue(this.workOrder.AreaOfWorkMasterListCodes);

            }
          });


    });
  }

  private getContractors() {
    this.workOrderService.getContractors().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorList = response;
      if (this.workOrder.ParentContractorVendor_Code) {
        this.workOrderForm.controls['ParentContractorVendor_Code'].patchValue(this.workOrder.ParentContractorVendor_Code);
        if (this.isSubContractor) {
          this.getContractorWorkOrder(this.workOrder.ParentContractorVendor_Code);
          this.getSubContractorsByExcludeCode(this.workOrder.ParentContractorVendor_Code);
        }
      }
      else {
        if (this.contractorList.length === 1) {
          this.workOrderForm.controls['ParentContractorVendor_Code'].patchValue(this.contractorList[0].ContractorVendor_Code);
          if (this.isSubContractor) {
            this.getContractorWorkOrder(this.contractorList[0].ContractorVendor_Code);
            this.getSubContractorsByExcludeCode(this.contractorList[0].ContractorVendor_Code);
          }
        }
      }
    });
  }

  commonContractorWorkOrderBindValue() {
    this.workOrder.ParentContractorVendor_Code = this.contractorList[0].ContractorVendor_Code;
    this.getContractorWorkOrder(this.workOrder.ParentContractorVendor_Code);
    this.getSubContractorsByExcludeCode(this.workOrder.ParentContractorVendor_Code);
  }


  getContractorsSelectedByDefault(userId: any, roleCode: any) {
    this.workOrderService.getContractorsbydefault(userId, roleCode).subscribe((response: any[]) => {
      this.contractorList = response;
      if (this.workOrder.ParentContractorVendor_Code) {
        this.workOrderForm.controls['ParentContractorVendor_Code'].patchValue(this.workOrder.ParentContractorVendor_Code);
        if (this.isSubContractor) {
          this.getContractorWorkOrder(this.workOrder.ParentContractorVendor_Code);
          this.getSubContractorsByExcludeCode(this.workOrder.ParentContractorVendor_Code);
        }
      }
      else {
        this.workOrderForm.controls['ParentContractorVendor_Code'].patchValue(this.contractorList[0].ContractorVendor_Code);
        if (this.isSubContractor) {
          this.getContractorWorkOrder(this.contractorList[0].ContractorVendor_Code);
          this.getSubContractorsByExcludeCode(this.contractorList[0].ContractorVendor_Code);
        }
      }
    });
  }

  private getSubContractorsByExcludeCode(excludeCode: string): void {
    this.workOrderService.getContractors(true,excludeCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.subContractorList = response;
      if (this.workOrder.ChildContractorVendor_Code) {
        this.workOrderForm.controls['ChildContractorVendor_Code'].patchValue(this.workOrder.ChildContractorVendor_Code);
      }
      else {
        if (this.subContractorList.length === 1) {
          this.workOrderForm.controls['ChildContractorVendor_Code'].patchValue(this.subContractorList[0].ContractorVendor_Code);
        }
      }
    });
  }

  private getContractorWorkOrder(contractorCode: number): void {
    this.workOrderService.getWorkOrderFilterData(contractorCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorWorkOrderList = response;
      if(this.workOrder.ParentWorkOrder_Code){
        this.workOrderForm.controls['ParentWorkOrder_Code'].patchValue(this.workOrder.ParentWorkOrder_Code);
      }
      else{
        if(this.contractorWorkOrderList.length === 1){
        this.workOrderForm.controls['ParentWorkOrder_Code'].patchValue(this.contractorWorkOrderList[0].WorkOrder_Code);
        }
      }
    });
  }

  private getPOAgainstLOIList(contractorCode): void {
    //pending validation
    this.workOrderService.getWorkOrderFilterData(null).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.poAgainstLOIList = response;
      // tslint:disable-next-line: radix
      if (this.workOrder.LOIRef_WorkOrder_Code !== undefined) {
        // tslint:disable-next-line: radix
        const wrk = parseInt(this.workOrder.LOIRef_WorkOrder_Code);
        this.workOrderForm.controls['LOIRef_WorkOrder_Code'].patchValue(wrk);
      } else {
        this.workOrderForm.controls['LOIRef_WorkOrder_Code'].patchValue(null);
      }
    });
  }

  private getDocumentTypesPageModuleWise() {
    const res = {
      'Page_Code': 191,
      'RequireType': 1,
      'ActionType': 0,
      'SelectedField':"Name,DocumentType_Code,page_code",
      'Search':null,
      'UserId': GLOBAL.USER_ID
    }

    this.workOrderService.getDocumentTypesPageModuleWiseV1(res).pipe(takeUntil(this.onDestroy)).subscribe((docTypes: any) => {
      this.documentTypes = docTypes;
    });
  }

  private getPlants(companyCode: any): void {
    this.workOrderService.getPlants(companyCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      this.plantList = response;
      if (this.workOrder.PlantCodes) {
        this.workOrderForm.controls['PlantCodes'].patchValue(this.workOrder.PlantCodes);
      }
      else {
        if (this.plantList.length === 1) {
          this.workOrderForm.controls['PlantCodes'].patchValue([this.plantList[0].Plant_Code]);
        }
      }
    });
  }

  // config for select filter component
  private createConfigForContractor(): void {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor Code', field: 'ShortCode',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Company', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.contractorConfig = {
      contractor: { contractorColumnDefs, labelText: 'Contractor', placeholderText: 'Select Contractor' }
    };
  }

  // config for select filter component
  private createConfigForSubContractor(): void {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor', field: 'ShortCode',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Company', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.subContractorConfig = {
      contractor: { contractorColumnDefs, labelText: 'Sub-Contractor', placeholderText: 'Select Sub-Contractor' }
    };
  }

  private createConfigForWorkOrder(): void {
    const workOrderColumnDefs = [
      {
        headerName: 'Work Order Number', field: 'WorkOrderNO',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 150, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Allow Person', field: 'Person', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Nature Of Work', field: 'NatureOfWork', width: 200, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'HOD', field: 'HOD', width: 100, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.workOrderConfig = {
      workOrder: { workOrderColumnDefs, labelText: 'Contractor WO', placeholderText: 'Select Contractor WO' }
    };
  }

  private createConfigForPOAgainstLOI(): void {
    const workOrderColumnDefs = [
      {
        headerName: 'Work Order Number', field: 'WorkOrderNo',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Allow Person', field: 'Person', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Owner', field: 'Owner', width: 70, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.poAgainstLOIConfig = {
      workOrder: { workOrderColumnDefs, labelText: 'PO Against LOI/FOA', placeholderText: 'Select PO Against LOI/FOA' }
    };
  }

  private createConfigForAreaCoordinator(): void {
    const userListColumnDefs = [
      {
        headerName: 'User Name', field: 'UserName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Email Id', field: 'AlternetEmailID',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.areaCoordinatorConfig = {
      userList: { userListColumnDefs, labelText: 'Area Coordinator', placeholderText: 'Select Area Coordinator' }
    };
  }

  private createConfigForDepartmentHead(): void {
    const userListColumnDefs = [
      {
        headerName: 'Full Name', field: 'FullName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Email Id', field: 'AlternetEmailID',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.departmentHeadConfig = {
      userList: { userListColumnDefs, labelText: 'Department Head', placeholderText: 'Select Department Head' }
    };
  }

  private createConfigForEICUser(): void {
    const userListColumnDefs = [
      {
        headerName: 'Full Name', field: 'FullName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Email Id', field: 'AlternetEmailID',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.eicUserConfig = {
      userList: { userListColumnDefs, labelText: 'PO Owner/EIC User', placeholderText: 'Select PO Owner/EIC User' }
    };
  }

  private createConfigForCompanyPolicy(): void {
    const ecPolicyColumnDefs = [
      {
        headerName: 'Company', field: 'CompanyName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Policy no', field: 'ECPolicyNo',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      {
        headerName: 'Issue Date', field: 'IssueDate',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      {
        headerName: 'Nature Of Work', field: 'NatureOfWork',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
    ];
    this.ecPolicyConfig = {
      ecPolicy: { ecPolicyColumnDefs, labelText: 'Company Policy', placeholderText: 'Select Company Policy' }
    };
  }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.select.close();
    }
    if (this.areaCoordinatorSelect && this.areaCoordinatorSelect.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.areaCoordinatorSelect.close();
    }
  }

  private resetFormAfterSave() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      const isDirect = this.workOrder.IsDirect;
      setTimeout(() => {
        if (isDirect) {
          this.router.navigate(['/contractor/work-order/'], { queryParams: { type: '1' } });
        } else {
          this.router.navigate(['/contractor/work-order/'], { queryParams: { type: '0' } });
        }
      }, 500);
    }
    this.workOrderForm.reset();
    this.workOrder = {};
    this.isContractorTouched = false;
    this.isSubContractorTouched = false;
    this.isAreaCoordinatorTouched = false;
    this.isDepartmentHeadTouched = false;
    this.isPOAgainstLOITouched = false;
    this.isDepartmentHeadTouched = false;
    this.isEicUserTouched = false;
    this.isCompanyPolicyTouched = false;
    this.setDefaultControlValue();
  }
}
