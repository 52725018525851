import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { Ng4LoadingSpinnerService } from "src/app/core/components";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { getListFromExcelFile } from "src/app/core/utility/utility";
import { ImportExcelService } from "../import-excel.service";
import { GLOBAL } from "src/app/app.globals";

@Component({
  selector: 'app-upload-bulk-enrollment-delete',
  templateUrl: './upload-bulk-enrollment-delete.component.html',
  styleUrls: ['./upload-bulk-enrollment-delete.component.css']
})
export class UploadBulkEnrollmentDeleteComponent {

  @ViewChild('fileInput') public fileInput: ElementRef<any>;
  @Output() public closeModal: EventEmitter<any>;

  public fileError: string;

  constructor(
    private loadingService: Ng4LoadingSpinnerService,
    private importExcelService: ImportExcelService,
    private logHelperService: LogHelperService
  ) {
    this.closeModal = new EventEmitter();
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  public onFileChange($event) {
    this.validateFile($event.target);
  }

  public onUploadFile() {
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.fileInput.nativeElement.files[0]);
    fileReader.onload = (e) => {
      const list = getListFromExcelFile(fileReader);
      this.saveData(list);
    };
  }

  private saveData(data: any[]) {
    const requestList = data.map(item => item.EnrollmentNo).join(',');
    const requestObj = {
      UEG_Code: requestList,
      UserId: GLOBAL.USER_ID,
      IsLabour: 1,
      RequestType: 1,
      Comment: 'Manul Delete Enrl',
      IsUnique: 1
    }
    this.importExcelService.uploadBulkEnrollmentDelete(requestObj).subscribe(res => {
      if (res) {
        this.logHelperService.logSuccess({
          message: 'Records are uploaded successfully'
        });
        this.closeModal.emit();
      } else {
        this.logHelperService.logError({
          message: 'Failed to upload records'
        })
      }
    })
  }

  private validateFile(target) {
    this.fileError = '';
    if (target.files.length === 0) {
      this.fileError = 'Please select Excel file';
      target.value = '';
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = 'Cannot use multiple files';
      target.value = '';
      return false;
    }

    if (!(target.files[0].name.endsWith('.xlsx') || target.files[0].name.endsWith('.xls'))) {
      this.fileError = 'Invalid File format, please select Excel file only.';
      target.value = '';
      return false;
    }

    return true;
  }

}