// tslint:disable-next-line: max-line-length
import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ComponentRef, TemplateRef, ViewChild, Input } from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';
import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { AttendanceRegularizationViewListComponent } from './attendance-regularization-view-list/attendance-regularization-view-list.component';
import { EntryfordayService } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.service';
import { MasterItemCode } from '../attendance-approve/attendance-approve.model';
import { getMinDateFromCode } from 'src/app/common/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare var $;
const dateFormat = 'DD-MM-YYYY';
// const date1: Date = new Date();
const date1 = new Date();
date1.setDate(date1.getDate() - 1);
// date1.setMonth(date1.getMonth() - 1);
console.log(date1.toString());
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-attendance-regularization',
  templateUrl: './attendance-regularization.component.html',
  styleUrls: ['./attendance-regularization.component.css'],
  // viewProviders: [EntryForThePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class AttendanceRegularizationComponent implements OnInit {

  public get minDate$(): Observable<any> {
    return this.sharedService.globalSettingsDataSource$.pipe(map((res: any) => getMinDateFromCode(res.Data.Table, 'MOD115PG380FV_1', 'MOD115PG380DV_2')));
  }

  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  public activeFilter: string;
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  public savedFilters: any[] = [];
  public groupByList: any[];
  public companyList: any[];
  public plantList: any[];
  public unitList: any[];
  public plantLit: any[];
  public departmentList: any[];
  public sectionList: any[];
  public companyNatureofWorkList: any[];
  public shiftGroupTypeList: any[];
  public shiftTypeList: any[];
  public shiftList: any[];
  public workmanCategoryList: any[];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];
  public genderList: any[] = [];
  public skillList: any[] = [];
  public designationList: any[] = [];
  public qualificationList: any[] = [];
  public technicalQualificationList: any[] = [];
  public qualificationStreamList: any[] = [];
  public religionList: any[] = [];
  public castList: any[] = [];
  public jobTypeList: any[] = [];
  public labourNatureOfWorkList: any[] = [];
  public workMenTypeList: any[] = [];
  public sectionTreeList: any[] = [];
  private sectionTreeResponses = [];
  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;
  public pagecode: number;
  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;
  private filterType: FilterTypeEnum;
  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  private currentSearchObj: any;
  private contractorCode: number;
  private workOrderNo: number;

  public minDate: any;

  public userId = GLOBAL.USER_ID;
  public roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private entryfordayService: EntryfordayService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    public sharedService: SharedService) {
    this.formGroup = this.buildForm();
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildFilterForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.pagecode = 251;
    this.getALLSavedFilterDescripationUserWise(this.pagecode);
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.manageVisibilityOfSteps();
    this.formGroup.get('JSONText').get('Date').setValue(date1);
    const userId = GLOBAL.USER_ID;
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (userId && roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  public onPlantChange(): void {
    this.unitList = [];
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (plantCode && plantCode.length === 1) {
      if (this.formGroup.get('JSONText').get('Unit_Code').disabled) {
        this.formGroup.get('JSONText').get('Unit_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('Department_Code').disabled) {
        this.formGroup.get('JSONText').get('Department_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('Section_Code').disabled) {
        this.formGroup.get('JSONText').get('Section_Code').enable();
      }
      this.getUnits(plantCode);
    } else {
      this.formGroup.get('JSONText').get('Unit_Code').disable();
      this.formGroup.get('JSONText').get('Department_Code').disable();
      this.formGroup.get('JSONText').get('Section_Code').disable();
    }
  }
  public onUnitChange(): void {

    this.departmentList = [];
    this.sectionList = [];
    this.formGroup.get('JSONText').get('Department_Code').setValue(null);
    this.formGroup.get('JSONText').get('Section_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    const unitCode = this.formGroup.get('JSONText').get('Unit_Code').value;
    if (plantCode && plantCode.length === 1 && unitCode && unitCode.length === 1) {
      if (this.formGroup.get('JSONText').get('Department_Code').disabled) {
        this.formGroup.get('JSONText').get('Department_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('Section_Code').disabled) {
        this.formGroup.get('JSONText').get('Section_Code').enable();
      }
      this.getDepartments(unitCode);
    } else {
      this.formGroup.get('JSONText').get('Department_Code').disable();
      this.formGroup.get('JSONText').get('Section_Code').disable();
    }
  }
  public onDepartmentChange(): void {
    this.sectionTreeList = [];
    this.formGroup.get('JSONText').get('Section_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    const unitCode = this.formGroup.get('JSONText').get('Unit_Code').value;
    const departmentCode = this.formGroup.get('JSONText').get('Department_Code').value;
    if (plantCode && plantCode.length === 1 && unitCode && unitCode.length === 1 && departmentCode && departmentCode.length === 1) {
      if (this.formGroup.get('JSONText').get('Section_Code').disabled) {
        this.formGroup.get('JSONText').get('Section_Code').enable();
      }
      this.getSectionList(plantCode, unitCode, departmentCode);
    } else {
      this.formGroup.get('JSONText').get('Section_Code').disable();
    }
  }
  public onShiftTypeGroupChange(): void {

    this.shiftList = [];
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    const shiftTypeGroupCode = this.formGroup.get('JSONText').get('ShiftGroupType_Code').value;
    if (plantCode && shiftTypeGroupCode) {

      this.getShiftMaster(plantCode, shiftTypeGroupCode);
    }
  }
  private getShiftMaster(plantCode: any, shiftTypeGroupCode: any): void {
    this.entryfordayService.getShifts(plantCode, shiftTypeGroupCode).subscribe((response: any[]) => {
      this.shiftList = response;
      console.log('this.shiftList', response);
    });
  }
  // private getSectionList(plantCode: any, unitCode: any, departmentCode: any): void {
  //   this.entryfordayService.getDepartmentPlantUnitSectionTree(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
  //     this.sectionTreeResponses = response;

  //     // this.manPowerForm.form.get('SectionCodes').setValue([200]);
  //   });
  // }
  private getSectionList(plantCode: any, unitCode: any, departmentCode: any): void {
    this.entryfordayService.getDepartmentPlantUnitSectionTree(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
      // this.sectionTreeResponses = response;
      this.bindSectionEdit(response, false);
      // this.manPowerForm.form.get('SectionCodes').setValue([200]);
    });
  }
  // tslint:disable-next-line: indent

  private getSectionFirstLevel(plantCode: any, unitCode: any, departmentCode: any): void {
    this.entryfordayService.getSectionFirstLevel(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
      // this.manPowerForm.controls['SectionCodes'].setValue(this.manPower.SectionCodes);
      //   this.sectionTreeResponses = response;
      //   this.bindSectionEdit(response, false);
      this.sectionList = response;
    });
  }
  private getAllData() {
    this.setGroupByList();
    this.getCompany();
    this.getNatureOfWorks();
    this.getSections();
    this.getShiftTypes();
    this.getContractors();
    this.getGender();
    this.getSkill();
    this.getQualifications();
    this.getTechnicalQualifications();
    this.getQualificationStreams();
    this.getReligions();
    this.getCasts();
    this.getWorkManNatureOfWorks();
    this.getWorkManCategoryList();
  }

  getCompany() {
    this.entryfordayService.getCompanies().subscribe((response: any[]) => {

      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
        this.getDesignations(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }
  getContractors() {
    this.entryfordayService.getContractors().subscribe((response: any[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }
  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.entryfordayService.getContractorsbydefault(userId, roleCode).subscribe((response: any[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].ContractorVendor_Code);
        this.getWorkOrdersByContractorCode(this.contractors[0].ContractorVendor_Code, 0);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getLicensesByContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.contractorLicenseList = response;
      this.formGroup.get('JSONText').get('License_Code').setValue(this.selectedFilterData.JSONText.License_Code);
    });
  }

  getWCPolicyByContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.contractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('WCPolicy').setValue(this.selectedFilterData.JSONText.WCPolicy);
    });
  }

  getPlants(companyCode: any): void {
    this.entryfordayService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
      if (this.selectedFilterData.JSONText.Plant_Code) {
        this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
      }
    });
  }
  // getSectionList(plantCode: any, unitCode: any, departmentCode: any): void {
  //   this.entryfordayService.getDepartmentPlantUnitSectionTree(plantCode, unitCode, departmentCode).subscribe((response: any[]) => {
  //     this.sectionTreeResponses = response;
  //   });
  // }
  getUnits(plantCode: any): void {
    this.entryfordayService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get('Unit_Code').setValue(this.selectedFilterData.JSONText.Unit_Code);
      if (this.selectedFilterData.JSONText.Unit_Code) {
        this.getDepartments(this.selectedFilterData.JSONText.Unit_Code);
      }
    });
  }

  getDepartments(unitCode: any): void {
    this.entryfordayService.getDepartments(unitCode).subscribe((response: any[]) => {
      this.departmentList = response;
      this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
      if (this.selectedFilterData.JSONText.Department_Code) {
        const plantCode = this.selectedFilterData.JSONText.Plant_Code;
        const unitCode = this.selectedFilterData.JSONText.Unit_Code;
        const departmentCode = this.selectedFilterData.JSONText.Department_Code;
        this.getSectionList(plantCode, unitCode, departmentCode);
      }
    });
  }

  getDesignations(companyCode: string): void {
    this.entryfordayService.getDesignations(companyCode).subscribe((response: any[]) => {
      this.designationList = response;
      this.formGroup.get('JSONText').get('Trade_Code').setValue(this.selectedFilterData.JSONText.Trade_Code);
    });
  }

  getGender(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.GENDER).subscribe((response: any[]) => {
      this.genderList = response;
      this.formGroup.get('JSONText').get('Gender').setValue(this.selectedFilterData.JSONText.Gender);
    });
  }

  getSkill(): void {
    this.entryfordayService.getSkills().subscribe((response: any[]) => {
      this.skillList = response;
      this.formGroup.get('JSONText').get('Skill_Code').setValue(this.selectedFilterData.JSONText.Skill_Code);
    });
  }

  getQualifications(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.QUALIFICATION).subscribe((response: any[]) => {
      this.qualificationList = response;
      this.formGroup.get('JSONText').get('QualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationMasterList_Code);
    });
  }

  getTechnicalQualifications(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.TECHNICALQUALIFICATION).subscribe((response: any[]) => {
      this.technicalQualificationList = response;
      this.formGroup.get('JSONText').get('TechQualificationMasterList_Code').
        setValue(this.selectedFilterData.JSONText.TechQualificationMasterList_Code);
    });
  }

  getQualificationStreams(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.QUALIFICATIONSTREAM).subscribe((response: any[]) => {
      this.qualificationStreamList = response;
      this.formGroup.get('JSONText').get('QualificationStreamMasterList_Code').
        setValue(this.selectedFilterData.JSONText.QualificationStreamMasterList_Code);
    });
  }

  getReligions(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.RELIGION).subscribe((response: any[]) => {
      this.religionList = response;
      this.formGroup.get('JSONText').get('Religion_Code').setValue(this.selectedFilterData.JSONText.Religion_Code);
    });
  }

  getCasts(): void {
    this.entryfordayService.getMasterItemByCode(MasterItemCode.CASTS).subscribe((response: any[]) => {
      this.castList = response;
      this.formGroup.get('JSONText').get('Cast_Code').setValue(this.selectedFilterData.JSONText.Cast_Code);
    });
  }

  getSections(): void {
    this.entryfordayService.getSections().subscribe((response: any[]) => {
      this.sectionList = response;
      this.formGroup.get('JSONText').get('Section_Code').setValue(this.selectedFilterData.JSONText.Section_Code);
    });
  }

  getShiftTypes(): void {

    this.entryfordayService.getShiftGroupTypes().subscribe((response: any[]) => {
      this.shiftGroupTypeList = response;
      console.log('getShiftGroupTypes', response);
      this.formGroup.get('JSONText').get('ShiftGroupType_Code').setValue(this.selectedFilterData.JSONText.ShiftGroupType_Code);
    });
  }
  getShifts(plantCode: any, shiftTypeGroupCode: any): void {

    this.entryfordayService.getShifts(plantCode, shiftTypeGroupCode).subscribe((response: any[]) => {
      this.shiftList = response;
      console.log('shiftList', response);
      this.formGroup.get('JSONText').get('Shift_Code').setValue(this.selectedFilterData.JSONText.Shift_Code);

      // this.formGroup.get('JSONText').get('QualificationStreamMasterList_Code').
      // setValue(this.selectedFilterData.JSONText.QualificationStreamMasterList_Code);
    });
  }
  getWorkManCategoryList(): void {
    this.entryfordayService.getMasterItemByCode(GLOBAL.MASTER_ITEM_CODE.WORKMAN_CATEGORY).subscribe((response: any[]) => {
      this.workmanCategoryList = response;
      this.formGroup.get('JSONText').get('CategoryWorkmenMIL_Code').setValue(this.selectedFilterData.JSONText.CategoryWorkmenMIL_Code);
    });
  }
  getNatureOfWorks(): void {
    this.entryfordayService.getNatureOfWorks().subscribe((response: any[]) => {
      this.companyNatureofWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  getWorkManNatureOfWorks(): void {
    this.entryfordayService.getNatureOfWorks().subscribe((response: any[]) => {
      this.labourNatureOfWorkList = response;
      // this.formGroup.get('JSONText').get('NatureOfWork_Code').setValue(this.selectedFilterData.JSONText.NatureOfWork_Code);
    });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
      // {
      //   value: '1',
      //   label: 'Contractor PO Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor and Department Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor, PO and Department Wise'
      // }
    ];
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    // this.formGroup.get('JSONText').get('subContractorCode').setValue(null);
    // this.formGroup.get('JSONText').get('subContractorWorkOrderNo').setValue(null);
    // // this.workOrders = [];
    // this.subContractors = [];
    // this.subContractorWorkOrders = [];
    if (companyCode) {
      this.getPlants(companyCode);
      this.getDesignations(companyCode);
    }
  }

  onContractorChange() {
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('License_Code').setValue(null);
    this.formGroup.get('JSONText').get('WCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      this.getLicensesByContractorCode(contractorCode);
      this.getWCPolicyByContractorCode(contractorCode);
    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get('JSONText').get('WorkOrder_Code').value;
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
    }
  }

  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  public onContractorSaveEmitter(data: any) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubContractorChange() {
    const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.subContractorWorkOrders = [];
    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantcode);
      this.getWCPolicyBySubContractorCode(subContractorCode);
      this.getLicensesBySubContractorCode(subContractorCode);
    }
  }

  public onSubContractorSaveEmitter(data: any) {
    console.log('saved data', data);
    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  getSubWorkOrderListEmitter(contractorCode: number, plantcode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
    }
  }

  public onSaveFilter(): void {
    console.log('form data', this.formGroup.getRawValue());
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    console.log('saved data', this.reportFilterData);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();
    this.formGroup.get('JSONText').get('Date').setValue(date1);

    this.openModal();
  }

  public onSubmit(): void {
    const formValue = this.formGroup.getRawValue();
    formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat);
    formValue.JSONText.Page = '';
    console.log('Date response', formValue);


    let contCode = '';
    let ptCode = '';
    let utCode = '';
    let deptCode = '';
    let secCode = '';
    let subconcode = '';
    let catecode = '';
    let natureofwork = '';
    let shifttypegroupcode = '';
    let shiftcode = '';


    if (formValue.JSONText.Contractors) {
      if (this.roleCode = 119) {
        const cc = formValue.JSONText.Contractors;
        contCode = cc.toString();
      } else {

        contCode = formValue.JSONText.Contractors.join(',');
      }

    } else {
      contCode = null;
    }
    if (formValue.JSONText.SubContractors) {
      subconcode = formValue.JSONText.SubContractors.join(',');
    } else {
      subconcode = null;
    }
    if (formValue.JSONText.Plant_Code) {
      ptCode = formValue.JSONText.Plant_Code.join(',');
    } else {
      ptCode = null;
    }
    if (formValue.JSONText.Unit_Code) {
      utCode = formValue.JSONText.Unit_Code.join(',');
    } else {
      utCode = null;
    }
    if (formValue.JSONText.Department_Code) {
      deptCode = formValue.JSONText.Department_Code.join(',');
    } else {
      deptCode = null;
    }
    if (formValue.JSONText.Section_Code) {
      secCode = formValue.JSONText.Section_Code.join(',');
    } else {
      secCode = null;
    }
    if (formValue.JSONText.CategoryWorkmenMIL_Code) {
      catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
    } else {
      catecode = null;
    }
    if (formValue.JSONText.NatureOfWork_Code) {
      natureofwork = formValue.JSONText.NatureOfWork_Code.join(',');
    } else {
      natureofwork = null;
    }
    if (formValue.JSONText.ShiftGroupType_Code) {
      shifttypegroupcode = formValue.JSONText.ShiftGroupType_Code.join(',');
    } else {
      shifttypegroupcode = null;
    }
    if (formValue.JSONText.Shift_Code) {
      shiftcode = formValue.JSONText.Shift_Code.join(',');
    } else {
      shiftcode = null;
    }

    if (formValue.JSONText.CategoryWorkmenMIL_Code) {
      catecode = formValue.JSONText.CategoryWorkmenMIL_Code.join(',');
    } else {
      catecode = null;
    }
    const uid = GLOBAL.USER_ID;
    formValue.filterText = {
      date: formValue.JSONText.Date,
      plant_Code: ptCode,
      unit_Code: utCode,
      department_Code: deptCode,
      section_Code: secCode,
      company_Code: null,
      UserId: uid,
      workMenCategory_Code: catecode,
      natureOfWork_Code: natureofwork,
      shiftGroupType_Code: shifttypegroupcode,
      shift_Code: shiftcode,
      includingSubContractor: formValue.JSONText.IncludingSubContractor,
      contractors: contCode,
      workOrder_Code: null,
      license_Code: null,
      WCPolicy: null,
      ESIC: null,
      subContractors: subconcode,
      subWorkOrder_Code: null,
      subLicense_Code: null,
      subWCPolicy: null,
      subESIC: null,
      gender: null,
      skill_Code: null,
      trade_Code: null,
      Qulification_Code: null,
      techQualification_Code: null,
      streamQualification_Code: null,
      religion_Code: null,
      cast_Code: null,
      JobType: null,
      FirstAIDTraning: null,
      IsDomicial: null,
      FireFighter: null,
      CurrentStatus: null,
      SAge: null,
      EAge: null,
      Wage: null,
      ContactortMenType: null,
      ExpiredMedicalPeriod: null,
      ExpiredFireTraining: null,
      PoliceVerifed: null,
      Page: null,
    };
    console.log('filter', formValue.filterText);

    this.currentSearchObj = formValue;
    this.entryfordayService.getDailyUnitwisePresentDetails(formValue.filterText).subscribe((response: any[]) => {
      console.log(response);
      response.forEach((item: any) => {
        if (item.SubContractor) {
          item.ContractorNameWithSubContractor = `${item.ContractorName}[${item.SubContractor}]`;
        } else {
          item.ContractorNameWithSubContractor = item.ContractorName;
        }
      });
      this.openReportViewModal(response);
    });
  }

  getDailyUnitwisePresentDetails(): void {
    this.entryfordayService.getDailyUnitwisePresentDetails(this.currentSearchObj.filterText).subscribe((response: any[]) => {
      response.forEach((item: any) => {
        if (item.SubContractor) {
          item.ContractorNameWithSubContractor = `${item.ContractorName}[${item.SubContractor}]`;
        } else {
          item.ContractorNameWithSubContractor = item.ContractorName;
        }
      });
      this.modalRef.componentInstance.reportData = response;
    });
  }

  openReportViewModal(response: any[]): void {
    const formValue = this.formGroup.getRawValue();
    this.modalRef = this.modalService.open(AttendanceRegularizationViewListComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });


    this.modalRef.componentInstance.selectedReportData = formValue.JSONText;
    this.modalRef.componentInstance.reportData = response;
    this.modalRef.componentInstance.refreshList.subscribe((value: string) => {
      this.getDailyUnitwisePresentDetails();
    });
    this.modalRef.componentInstance.closeModal.subscribe((value: string) => {
      this.modalRef.close(value);
    });
  }

  // public onFilterTab(filterObj: any): void {
  //   // console.log('filterObj', filterObj);
  //   this.activeFilter = filterObj.name;
  //   this.selectedTabId = filterObj.id;
  //   this.manageVisibilityOfSteps();
  // }

  public onFilterTab(filterObj: any): void {

    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
        .get('Contractors').value.length > 1)) {
      this.logHelperService.logError({
        message: 'Please first fullfill data in contractor.'
      });
      return;
    }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  public onAddfilter(): void {
    console.log('form data open modal', this.formGroup.getRawValue());
    const value = this.formGroup.getRawValue();
    const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
    const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
      && item.Report_Code !== this.selectedFilterData.Report_Code);
    if (!filterTypeObj) {
      selectedFilterDataObj.id = this.selectedTabId;
      const data = {
        Report_Code: this.selectedFilterData.Report_Code || '',
        Page_Code: 251,
        FilterName: value.FilterName,
        JSONTextDetails: JSON.stringify(selectedFilterDataObj)
      };
      if (this.selectedFilterData.Report_Code) {
        this.entryfordayService.updateFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      } else {
        this.entryfordayService.addFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
            this.formGroup.get('JSONText').get('Date').setValue(date1);

          }
        });
      }
    } else {
      alert('This filter is already exist.');
    }
  }
  public deleteFilterItem(filterObj: any): void {
    this.entryfordayService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(this.pagecode);
    });
  }

  private getALLSavedFilterDescripationUserWise(pagecode: number) {
    this.entryfordayService.getALLSavedFilterDescripationUserWise(pagecode).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();
    // this.reportFilterData = null;
    // this.selectedFilterData = null;

    // this.contractorCode = null;
    // this.workOrderNo = null;
    // this.subContractors = [];
    // this.workOrders = [];
    // // this.contractors=[];
    // setTimeout(() => {
    //   this.contractors = [...this.contractors];
    // }, 100);

    // this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  private buildFilterForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: any) {
    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.formGroup.patchValue(filterObj);
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  private getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
          this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
        }
      }
    });
  }

  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
      }
    });
  }

  getWCPolicyBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.subContractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('SubWCPolicy').setValue(this.selectedFilterData.JSONText.SubWCPolicy);
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subContractorLicenseList = response;
      this.formGroup.get('JSONText').get('SubLicense_Code').setValue(this.selectedFilterData.JSONText.SubLicense_Code);
    });
  }

  // private getSubContractors(workOrderNo: any = ''): void {
  //   // this.entryfordayService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
  //   //   this.subContractors = response;
  //   // });

  //   this.entryfordayService.getContractors('', workOrderNo).subscribe((response: any[]) => {
  //     this.subContractors = response;
  //     this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
  //     if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
  //       this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
  //         this.selectedFilterData.JSONText.plantcode);
  //       this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
  //       this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
  //     }
  //   });
  // }

  private getSubContractors(workOrderNo: any = ''): void {
    const formValue = this.formGroup.getRawValue();
    let contCode = '';
    let ptCode = '';
    let woCode = '';

    if (formValue.JSONText.Contractors) {
      if (this.roleCode = 119) {
        const cc = formValue.JSONText.Contractors;
        contCode = cc.toString();
      } else {

        contCode = formValue.JSONText.Contractors.join(',');
      }


    } else {
      contCode = '0';
    }

    if (formValue.JSONText.Plant_Code) {
      ptCode = formValue.JSONText.Plant_Code.join(',');
    } else {
      ptCode = '0';
    }
    if (formValue.JSONText.WorkOrder_Code) {
      woCode = workOrderNo.toString();
    } else {
      woCode = '0';
    }

    formValue.filterText = {
      plant_Code: ptCode,
      WorkOrder_Code: woCode,
      UserId: GLOBAL.USER_ID,
      contractors: contCode,
    };

    this.entryfordayService
      .getContractorSubContractorData(formValue.filterText)
      .subscribe((response: any[]) => {
        this.subContractors = response;
        this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
          this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
            this.selectedFilterData.JSONText.plantcode);
          this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
          this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        }
      });
  }

  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      {
        id: 2,
        name: 'Company',
        active: false
      },
      {
        id: 3,
        name: 'Contractor',
        active: false
      },
      {
        id: 4,
        name: 'Sub-Contractor',
        active: false
      },
      {
        id: 5,
        name: 'Associate',
        active: false
      }
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }
  bindSectionEdit(response: any[], isEdit: boolean) {
    console.log(response);
    // this.sectionTreeList = this.bindCompanyTree(response, isEdit);
    const convertedData = [];
    response.forEach((val) => {
      val.PlantTree.forEach((plant) => {
        plant.UnitTree.forEach((unit) => {
          unit.DepartmentTree.forEach((department) => {
            department.SectionTree.forEach((section1) => {
              convertedData.push(section1);
            });
          });
        });
      });
    });
    console.log('convertedData', convertedData);
    this.sectionTreeList = this.bindSection1Tree(convertedData, isEdit);
    console.log('sectionTreeList after', this.sectionTreeList);
  }

  bindCompanyTree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.PlantTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
            currentname: 'company', isActive: false, selected: false,
            children: this.bindPlantTree(subDataFilter, val.Company_Code, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
            currentname: 'company', isActive: false, selected: false, checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindPlantTree(filteredData, companyCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val: any, key) => {
      const subDataFilter = val.UnitTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            companyCode,
            id: val.Company_Code,
            plantCode: val.Plant_Code,
            name: val.Name,
            currentname: 'plant',
            isActive: false,
            selected: false,
            children: this.bindUnitTree(subDataFilter, companyCode, val.Plant_Code, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            companyCode,
            id: val.Company_Code,
            plantCode: val.Plant_Code,
            name: val.Name,
            currentname: 'plant',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindUnitTree(filteredData, companyCode, plantCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.DepartmentTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            companyCode,
            plantCode,
            id: val.Unit_Code,
            unitCode: val.Unit_Code,
            name: val.Name,
            currentname: 'unit',
            isActive: false,
            selected: false,
            children: this.bindDepartmentTree(subDataFilter, companyCode, plantCode, val.Unit_Code, isEdit)
          });
      } else {
        convertedData.push(
          {
            companyCode,
            plantCode,
            id: val.Unit_Code,
            unitCode: val.Unit_Code,
            name: val.Name,
            currentname: 'unit',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindDepartmentTree(filteredData, companyCode, plantCode, unitCode, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionTree;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            companyCode,
            plantCode,
            unitCode,
            id: val.Department_Code,
            sectionCode: val.Department_Code,
            name: val.Name,
            currentname: 'department',
            isActive: false,
            selected: false,
            children: this.bindSection1Tree(subDataFilter, isEdit)
          }
        );
      } else {
        convertedData.push(
          {
            companyCode,
            plantCode,
            unitCode,
            id: val.Department_Code,
            sectionCode: val.Department_Code,
            name: val.Name,
            currentname: 'department',
            isActive: false,
            selected: false,
            checked: isEdit
          }
        );
      }
    });
    return convertedData;
  }

  bindSection1Tree(filteredData, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            children: this.bindSection2Tree(subDataFilter, val.SectionPlantRelationId, isEdit)
          }
        );
      } else {
        let isCheck = false;

        const sectionCodes = this.formGroup.get('JSONText').get('Section_Code').value;
        if (sectionCodes) {
          if (sectionCodes.find(item => item.id === val.SectionPlantRelationId)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        }
        convertedData.push(
          {
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  bindSection2Tree(filteredData, section1Code, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            section1Code,
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section2',
            isActive: false,
            selected: false,
            children: this.bindSectionTree(subDataFilter, section1Code, val.SectionPlantRelationId, isEdit)
          }
        );
      } else {
        let isCheck = false;

        const sectionCodes = this.formGroup.get('JSONText').get('Section_Code').value;

        if (sectionCodes) {
          if (sectionCodes.find(item => item.id === val.SectionPlantRelationId)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        }
        convertedData.push(
          {
            section1Code,
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section2',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  bindSectionTree(filteredData, section1Code, section2Code, isEdit: boolean) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      let isCheck = false;
      const sectionCodes = this.formGroup.get('JSONText').get('Section_Code').value;
      if (sectionCodes) {
        if (sectionCodes.find(item => item.id === val.SectionPlantRelationId)) {
          isCheck = true;
          console.log('isCheck', isCheck);
        }
      }
      convertedData.push(
        {
          section1Code,
          section2Code,
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          name: val.Name,
          currentname: 'section3',
          isActive: false,
          selected: false,
          checked: isCheck
        }
      );
    });
    return convertedData;
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        Date: [null],
        groupBy: [null],
        punchingWiseFilter: [null],
        labourCode: [null],
        dataOrderBy: [null],
        CurrentStatus: '',
        Company_Code: [null],
        Plant_Code: [null],
        Unit_Code: [null],
        ULC: [null],
        GatePassNo: [null],
        Department_Code: [null],
        CategoryWorkmenMIL_Code: [null],
        WorkmenCategoty_Code: [null],
        Section_Code: [null],
        sectionList: [null],
        workmanCategoryList: [null],
        ShiftGroupType_Code: [null],
        Shift_Code: [null],
        StartDate: [null],
        EDate: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        Gender: [null],
        Skill_Code: [null],
        Trade_Code: [null],
        QualificationMasterList_Code: [null],
        TechQualificationMasterList_Code: [null],
        QualificationStreamMasterList_Code: [null],
        Religion_Code: [null],
        Cast_Code: [null],
        IsDomicial: [null],
        FirstAIDTraning: [null],
        FireFighter: [null],
        SAge: [null],
        EAge: [null],
        JobType: [null],
        NatureOfWork_Code: [null],
        WageCriteria: [null],
        ContactortMenType: [null],
        ExpiredMedicalPeriod: [null],
        ExpiredFireTraining: [null],
        PoliceVerifed: [null],
        LabourDemandType: [null],
        dateRange: [null],
        Page: [null],
        contAndSubCont: [null],
        earlyOutAndLateIn: [null],
        formControlName: [null],
        isAttendanceStatus: [null],
        isApprove: [null],
        UserID: [],
        JoiningOrResigning: [null],
        allmasterorspecific: [null],
        MTestOrITORPV: [null],
        ExcludeContractor: [null],
        SubContractorName: [null],
        Labour_Code: [null],
        IncludingSubContractor: [null],
        ShiftTypeGroup_Code: [null],
        SRN: [null],
        ExceptionalAttendance: [null],
        Reason: [null],
        IsCompanyDefault: [null],
        ExceptionalAttendance_Code: [null],
        ifIndivudualCommon: [null],
        ChangeStatus: [null],
        IsContractorShiftLabour: [null],
        State_Code: [null],
        ifStatusChange: [{ value: null, disabled: true }],
        employeeData: this.fb.array([])
      }),
    });
  }
}
