import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from 'src/app/app.globals';

@Injectable({
  providedIn: 'root',
})
export class LoanNRecoveriesRegisterFromCService {
  constructor(private http: HttpClient) { }

  userId = localStorage.getItem("UserID");

  private postRequest<T>(url: string, req: object): Observable<T> {
    return this.http.post<T>(url, req).pipe(map((response: T) => response));
  }

  public getCompanies(): Observable<any[]> {
    const req = {
      Search: null,
      StatusOfIndustry: null,
      EntityType: null,
      Legalstatus: null,
      State: null,
      City: null,
      Country: null,
    };
    return this.postRequest<any[]>(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA_SP, req);
  }

  public getPlants(companyCode: string): Observable<any[]> {
    const req = {
      search: null,
      Company: companyCode,
      State: null,
      City: null,
      Country: null,
      UnitType: null,
      OwnerShip: null,
      LocatedAt: null,
      IsESICApplicable: null,
    };
    return this.postRequest<any[]>(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_MASTER_DATA, req);
  }

  public getStatesList(plantCode: string): Observable<any[]> {
    const req = {
      Search: '',
      selectedField: null,
      State_Code: '',
      Plant_Code: plantCode,
      RequireType: 0,
      ActionType: 0,
      UserId: this.userId,
    };
    return this.postRequest<any[]>(GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_STATE_DATA_SP, req);
  }

  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    const url = GLOBAL.APIS.WORK_ORDER.GET_BYDEFAULTCONTRACTORS;
    const params = { UserId: userId, RoleCode: roleCode };
  
    return this.http.get<any[]>(url, { params }).pipe(map(response => response)
    );
  }
  

  public getContractors(excludeContractorCode: string = "", workOrderCode: string | null = null ): Observable<any[]> {
    const isMainFlag = workOrderCode ? "0" : "1";
    const req = {
      search: null,
      sDate: null,
      eDate: null,
      selectedField: null,
      isMain: isMainFlag,
      Contractor_Code: null,
      ExcludeContractor_Code: excludeContractorCode || null,
      workOrderCode: workOrderCode,
      Plant_Code: null,
      UserID: this.userId,
      RequirType: null,
    };

    const url = GLOBAL.APIS.WORK_ORDER.SP_Listing_Contractor_Filter_V1;

    return this.http.post<any[]>(url, req).pipe(map((response: any) => {
        return response;
      })
    );
    
  }

  public getWorkOrdersByContractorCode( contractorCode: number, plantCode: number ): Observable<any> {
    const req = {
      Role_Code: null,
      UserId: null,
      ContractorVendor_Code: contractorCode,
      SubContractorVendor_Code: null,
      ActionType: 1,
      WorkOrder_Code: null,
      Plant_Code: plantCode,
      Search: null,
      selectedField: null,
    };
  
    const url = GLOBAL.APIS.WORK_ORDER.SP_GET_CONTRACTOR_WISE_WORK_ORDER_LIST;
  
    return this.http.post<any>(url, req).pipe(map((response: any) => response || [])
    );
  }

  saveLoanNRecoveriesFormC(reqObj): Observable<any> {
    const url = GLOBAL.APIS.LABOUR_MANAGEMENT.SAVE_LOAN_N_RECOVERIES_FORM_C;
    return this.http.post<any>(url, reqObj).pipe(map((response: any) => response || [])
    );
  }

  printConfig(reqObj): Observable<any> {
    const url = GLOBAL.APIS.COMMON_GLOBAL_VAR.PAGE_NPRINT_CONFIG;
    return this.http.post<any>(url, reqObj).pipe(map((response: any) => response || [])
    );
  }

  damageNLossReport(reqObj): Observable<any> {
    const url = GLOBAL.APIS.LABOUR_MANAGEMENT.DAMAG_N_LOSS_REPORT;
    return this.http.post<any>(url, reqObj).pipe(map((response: any) => response || [])
    );
  }
}