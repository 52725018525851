import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  ViewChild,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, ColumnApi, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { AttendanceService } from 'src/app/attendance/attendance.service';
import { CommonService } from 'src/app/common/common.service';
import { printFormateCss } from 'src/app/common/utils';
import * as moment from 'moment';
import { SharedService } from 'src/app/core/services';

@Component({
  selector: 'app-row-wage-register-form-b',
  templateUrl: './row-wage-register-form-b.component.html',
  styleUrls: ['./row-wage-register-form-b.component.css'],
})
export class RowWageRegisterFormBComponent implements OnInit {
  @Input() reportData: any[] = [];
  @Input() headerData: any;
  @Input() stateList: any;
  @ViewChild("templateStateRef") templateStateRef: TemplateRef<any>;
  @ViewChild('btnPrint') btnPrint: ElementRef<any>;
  @ViewChild("tablePrint") printtableEle: ElementRef;

  closeModal = new EventEmitter<void>();
  actionFormGroup = new FormGroup({
    state: new FormControl(''),
    State_Code: new FormControl(''),
    PaperSize: new FormControl(''),
    PaperLayout: new FormControl(''),
    PaperScalSize: new FormControl(['', [Validators.required, Validators.max(100)]]),
  });

  tableHeaderData: any;
  reportDate: string;
  selectedReportInfo: Date;
  defaultColDef = { flex: 1, sortable: true, filter: true };
  gridApi!: GridApi;
  gridColumnApi!: ColumnApi;
  totalRecords = 0;
  PrintCongfigShow: boolean = false;
  showCenteralPrint = false;
  showStatePrint = false;

  @Input() set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  get selectedReportData(): Date {
    return this.selectedReportInfo;
  }

  reportValueData: any[] = [];
  tableRowData: any[] = [];
  rowData: any[] = [];

  pagecode: any = 104;
  paperSizeList: any = [];
  paperLayoutList: any = [];
  paperScalSizeList = [];
  opratation: any;

  tableCss: string;
  tableWorkOrderData: any


  gridOptions: GridOptions = {
    animateRows: true,
    rowSelection: 'multiple',
    rowHeight: 40,
    headerHeight: 40,
    pagination: false,
    paginationPageSize: 0,
  };

  columnDefs = [
    { headerName: 'Sr. No.', pinned: 'left', headerClass: 'custom-header', field: 'id', width: 80 },
    { headerName: 'Sl. No. in Employee register', pinned: 'left', headerClass: 'custom-header', field: 'GatePassNo' },
    { headerName: 'Name', pinned: 'left', headerClass: 'custom-header', field: 'Name' },
    { headerName: 'Rate of Wage', pinned: 'left', headerClass: 'custom-header', field: 'MINIMUM_WAGE' },
    { headerName: 'No. of Days Worked', headerClass: 'custom-header', field: 'TPD_DAYS' },
    { headerName: 'Overtime hours worked', headerClass: 'custom-header', field: 'OVERTIME_HOURS' },
    // { headerName: 'BASIC+SP ACIAL_ALL', headerClass: 'custom-header', field: 'id' },
    { headerName: 'Special Basic', headerClass: 'custom-header', field: 'SPECIAL_BASIC' },
    { headerName: 'DA', headerClass: 'custom-header', field: 'DA' },
    { headerName: 'Payments Overtime', headerClass: 'custom-header', field: 'OT_PAYOUT' },
    { headerName: 'HRA', headerClass: 'custom-header', field: 'HRA' },
    { headerName: 'Leave @5% of Basic', headerClass: 'custom-header', field: 'LEAVE_PAY' },
    { headerName: 'Interim Bonus @8.33% of Basic', headerClass: 'custom-header', field: 'ADVANCE_BONUS' },
    { headerName: 'Any Other Allowance as per Appointment Letter', headerClass: 'custom-header', field: 'OTHRE_ALW' },
    { headerName: 'Any Other Allowance as per Appointment Letter', headerClass: 'custom-header', field: 'OTHRE1_ALW' },
    { headerName: 'Any Other Allowance as per Appointment Letter', headerClass: 'custom-header', field: 'OTHRE2_ALW' },
    { headerName: 'Total', headerClass: 'custom-header', field: 'TOTAL' },
    { headerName: 'PF', headerClass: 'custom-header', field: 'PF' },
    { headerName: 'ESIC', headerClass: 'custom-header', field: 'ESIC' },
    { headerName: 'Society', headerClass: 'custom-header', field: 'SOCIETY' },
    { headerName: 'Income Tax', headerClass: 'custom-header', field: 'INCOME_TAX' },
    { headerName: 'Insurance', headerClass: 'custom-header', field: 'INSURANCE' },
    // { headerName: 'OTHRE_DEDUCTION', headerClass: 'custom-header', field: 'id' },
    { headerName: 'Others(As per Appointment Letter)', headerClass: 'custom-header', field: 'OTHRE_DEDUCTION1' },
    { headerName: 'Others(As per Appointment Letter)', headerClass: 'custom-header', field: 'OTHRE_DEDUCTION2' },
    { headerName: 'Others(As per Appointment Letter)', headerClass: 'custom-header', field: 'OTHRE_DEDUCTION3' },
    { headerName: 'Recoveries', headerClass: 'custom-header', field: 'RECOVERIES' },
    { headerName: 'Total', headerClass: 'custom-header', field: 'DEDUCT' },
    { headerName: 'Net Payment', headerClass: 'custom-header', field: 'TOT_COST' },
    { headerName: 'Employer Share PF Welfare Fund', headerClass: 'custom-header', field: 'ESPWF' },
    { headerName: 'Receipt by Employee/Bank Transaction ID', headerClass: 'custom-header', field: 'RBEBTID' },
    { headerName: 'Date of Payment', headerClass: 'custom-header', field: 'DOP' },
    { headerName: 'Remarks', headerClass: 'custom-header', field: 'REMARKS' },
  ];

  constructor(
    public attendanceService: AttendanceService,
    private modalService: NgbModal,
    private commonservice: CommonService,
    private sharedService: SharedService,
  ) {
    this.tableCss = printFormateCss();
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(this.tableCss));
  }

  ngOnInit(): void {
    this.initializeData();
    this.getPrintOptionsFunc(this.pagecode);

    const headerPayload = {
      Filter: {
        Plant_Code: this.tableRowData[0].items[0].Plant_Code,
        Page_Code: this.pagecode,
        Refereance_Code: null,
        Contractor_Code: this.tableRowData[0].items[0].Contractor_Code,
        WorkOrder_Code: 3,
        SubContractor_Code: null,
        SubWorkOrder_Code: null,
        License_Code: null,
        SubLicense_Code: null,
        RequireType: 1,
        ActionType: 1,
        UserId: localStorage.getItem('UserID')
      }
    };

    this.sharedService.getTableheaderData(headerPayload).subscribe((response) => {
      this.tableHeaderData = response['Table5'][0];
      this.tableWorkOrderData = response['Table2'][0];
    });
  }

  onCloseAction(): void {
    this.closeModal.emit();
  }

  initializeData(): void {
    if (this.reportData.length) {
      this.reportDate = this.dateConvertor(this.selectedReportInfo);
      this.rowData = this.reportValueData;
      this.tableRowData = this.getGroupedRowData(this.reportData);
      this.rowData = this.tableRowData[0].items.map((item) => ({
        id: item.Id,
        Name: item.Name,
        GatePassNo: item.GatePassNo,
        MINIMUM_WAGE: item.MINIMUM_WAGE,
        TPD_DAYS: item.TPD_DAYS,
        OVERTIME_HOURS: item.OVERTIME_HOURS,
        SPECIAL_BASIC: item.SPECIAL_BASIC,
        DA: item.DA,
        OT_PAYOUT: item.OT_PAYOUT,
        HRA: item.HRA,
        LEAVE_PAY: item.LEAVE_PAY,
        ADVANCE_BONUS: item.ADVANCE_BONUS,
        OTHRE_ALW: item.OTHRE_ALW,
        OTHRE1_ALW: item.OTHRE1_ALW,
        OTHRE2_ALW: item.OTHRE2_ALW,
        TOTAL: item.TOTAL,
        PF: item.PF,
        ESIC: item.ESIC,
        SOCIETY: item.SOCIETY,
        INCOME_TAX: item.INCOME_TAX,
        INSURANCE: item.INSURANCE || '0',
        // OTHRE_DEDUCTION: item.OTHRE_DEDUCTION,
        OTHRE_DEDUCTION1: item.OTHRE_DEDUCTION1 || '0',
        OTHRE_DEDUCTION2: item.OTHRE_DEDUCTION2 || '0',
        OTHRE_DEDUCTION3: item.OTHRE_DEDUCTION3 || '0',
        RECOVERIES: item.RECOVERIES,
        DEDUCT: item.DEDUCT,
        TOT_COST: item.TOT_COST,
        ESPWF: item.ESPWF,
        RBEBTID: item.RBEBTID,
        DOP: item.DOP,
        REMARKS: item.REMARKS,
      }));
      this.totalRecords = this.reportData.length;
    }
  }

  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return "";
    }
    const date = new Date(reportData.Date);
    const date1 = new Date(reportData.EDate);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${date.getDate()}-${months[date.getMonth()]
      }-${date.getFullYear()} To ${date1.getDate()}-${months[date1.getMonth()]
      }-${date1.getFullYear()}`;
  }


  onStateDD(data: any): void {
    this.opratation = data;
    const modalRef = this.modalService.open(this.templateStateRef, { size: "sm" });
  }

  getPrintOptionsFunc(pagecode: any): void {
    this.commonservice.getPrintOptions(pagecode).subscribe((response) => {
      if (response[0].PrintingCongfigShow) {
        this.PrintCongfigShow = response[0].PrintingCongfigShow;
        this.paperSizeList = response[0].PrintPaperSize.split(',');
        this.paperLayoutList = response[0].PrintPaperLayout.split(',');
        this.paperScalSizeList = response[0].PrintScaleSize.split(',');
        this.actionFormGroup.get('PaperSize').setValue(this.paperSizeList[0]);
        this.actionFormGroup.get('PaperLayout').setValue(this.paperLayoutList[0]);
        this.actionFormGroup.get('PaperScalSize').setValue(
          Math.min(parseInt(this.paperScalSizeList[0]), 100)
        );

        if (this.stateList && this.stateList.length === 1) {
          this.actionFormGroup.get('State_Code').setValue(this.stateList[0].State_Code);
        }
      }
    });
  }


  openPrintModal(pagecode: any): void {
    this.getPrintOptionsFunc(pagecode);
    this.showCenteralPrint = true;
  }

  onPrint(): void {
    this.showCenteralPrint = false;
    this.showStatePrint = true;
    const data = {
      selectedReportData: this.selectedReportData,
      reportData: this.reportData
    };

    this.tableRowData = this.getGroupRowTableData(this.reportData);
    setTimeout(() => {
      this.btnPrint.nativeElement.click();
      localStorage.setItem('report-data', JSON.stringify(data));
    }, 100);
  }

  onPrintCentralFormat(): void {
    this.showCenteralPrint = true;
    this.showStatePrint = false;
    const data = {
      selectedReportData: this.selectedReportData,
      reportData: this.reportData
    };
    this.tableRowData = this.getGroupRowTableData(this.reportData);
    setTimeout(() => {
      let printConfOpt;
      if (this.PrintCongfigShow) {
        printConfOpt =
        {
          'PaperSize': this.actionFormGroup.controls.PaperSize.value,
          'PaperLayout': this.actionFormGroup.controls.PaperLayout.value,
          'PaperScalSize': this.actionFormGroup.controls.PaperScalSize.value
        }
      } else {
        printConfOpt =
        {
          'PaperSize': 'A4',
          'PaperLayout': 'portrait',
          'PaperScalSize': 100
        }
      }

      const tableCss = `
        table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
          }
         th {
            background-color: #f2f2f2;
          }`;

      this.printTable('print-section', tableCss, printConfOpt);
      this.showCenteralPrint = false;
      localStorage.setItem('report-data', JSON.stringify(data));
    }, 100);
  }

  printTable(elementId: string, tableCss: string, printConfOpt: any): void {
    const element = document.getElementById(elementId);

    if (!element) {
      console.error(`Element with ID ${elementId} not found`);
      return;
    }

    const recordsPerPage = 50;
    const pages = this.paginateData(this.rowData, recordsPerPage);
    const tableHeaderHtml = element.querySelector('thead').outerHTML || '';
    const tableBodyHtml = this.generatePaginatedTableBody(pages, tableHeaderHtml);
    const printWindow = window.open('', '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=0,left=0,width=800,height=600');
    if (printWindow) {
      const additionalCSS = `
        ${tableCss}
          @media print {
            @page {
              size: A4 landscape; 
              margin: 1cm; 
            }

            body {
              font-size: 12px;
              color: #000;
              margin: 0;
              padding: 0;
              -webkit-print-color-adjust: exact; 
              print-color-adjust: exact;  
            }

            table {
              width: 100%;
              border-collapse: collapse;  
              page-break-inside: auto; 
            }

            thead {
              display: table-header-group; 
            }

            tfoot {
              display: table-footer-group; 
            }

            tr {
              page-break-inside: avoid; 
              page-break-after: auto;
            }

            th, td {
              border: 1px solid black; 
              padding: 5px; 
              text-align: left;  
            }

            .no-print {
              display: none;  
            }

            .page-break {
             page-break-after: always; 
            }

            .table-bordered {
              margin-bottom: 0px; 
            }
          }`;

      printWindow.document.open();
      printWindow.document.write(`
              <html>
              <head>
                <title>Print Preview</title>
                <style>${additionalCSS}</style>
              </head>
              <body>
                ${tableBodyHtml}
              </body>
              </html>
            `);
      printWindow.document.close();

      printWindow.focus();
      printWindow.print();
      printWindow.onafterprint = () => printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  }

  paginateData(data: any[], recordsPerPage: number): any[][] {
    const pages = [];
    for (let i = 0; i < data.length; i += recordsPerPage) {
      pages.push(data.slice(i, i + recordsPerPage));
    }
    return pages;
  }

  generatePaginatedTableBody(pages: any[][], headerHtml: string): string {
    return pages.map((page, index) => `
      <table>
        ${headerHtml}
        <tbody>
          ${page.map(row => `
            <tr>
              <td>${row.id}</td>
              <td>${row.GatePassNo}</td>
              <td>${row.Name}</td>
              <td>${row.MINIMUM_WAGE}</td>
              <td>${row.TPD_DAYS}</td>
              <td>${row.OVERTIME_HOURS}</td>
              <td>${row.SPECIAL_BASIC}</td>
              <td>${row.DA}</td>
              <td>${row.OT_PAYOUT}</td>
              <td>${row.HRA}</td>
              <td>${row.LEAVE_PAY}</td>
              <td>${row.ADVANCE_BONUS}</td>
              <td>${row.OTHRE_ALW}</td>
              <td>${row.OTHRE1_ALW}</td>
              <td>${row.OTHRE2_ALW}</td>
              <td>${row.TOTAL}</td>
              <td>${row.PF}</td>
              <td>${row.ESIC}</td>
              <td>${row.SOCIETY}</td>
              <td>${row.INCOME_TAX}</td>
              <td>${row.INSURANCE ? row.INSURANCE : '0'}</td>
              <td>${row.OTHRE_DEDUCTION ? row.INSURANCE : '0'}</td>
              <td>${row.OTHRE_DEDUCTION1 ? row.INSURANCE : '0'}</td>
              <td>${row.OTHRE_DEDUCTION2 ? row.INSURANCE : '0'}</td>
              <td>${row.OTHRE_DEDUCTION3 ? row.INSURANCE : '0'}</td>
              <td>${row.RECOVERIES}</td>
              <td>${row.DEDUCT}</td>
              <td>${row.TOT_COST}</td>
              <td>${row.ESPWF ? row.ESPWF : ''}</td>
              <td>${row.RBEBTID ? row.ESPWF : ''}</td>
              <td>${row.DOP ? row.ESPWF : ''}</td>
              <td>${row.REMARKS ? row.ESPWF : ''}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
      ${index < pages.length - 1 ? '<div class="page-break"></div>' : ''}
    `).join('');
  }

  getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    const groupData = new Set(reportData.map(item => item.ContractorName));

    groupData.forEach((groupItem: string) => {
      let index = 1;
      const items = reportData.filter(item => item.ContractorName === groupItem);
      items.forEach((item: any) => {
        item.index = index;
        index++;
      });
      const rowItems = {
        items,
        ContractorName: groupItem
      };
      tableData.push(rowItems);
    });
    return tableData;
  }

  // generateDynamicColumnProps() {
  //   return {
  //     minWidth: 100,
  //     cellStyle: { textAlign: 'right' },
  //     sortable: true,
  //     filter: true,
  //   };
  // }

  public exportExcel(): void {
    this.showCenteralPrint = true;
    this.showStatePrint = false;
    let filename = moment(new Date()).format(this.sharedService.datePickerSettings.placeHolder);
    setTimeout(() => {
      const additionalCss = `.text-center{ background-color: #cdeefa !important; border: 1px solid #b5e6f9 !important;}`;
      const combinedCss = this.tableCss + additionalCss
      this.commonservice.exportExcelWithLayout('WorkMenRegisterReport ' + filename, this.printtableEle, combinedCss);
      this.showCenteralPrint = false;
    }, 100);
  }


  onGridReady(params: { api: GridApi; columnApi: ColumnApi }): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getGroupedRowData(data: any[]): any[] {
    const groupedData = _.groupBy(data, 'ContractorName');
    return Object.keys(groupedData).map((key) => ({
      headerName: key,
      items: groupedData[key].map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    }));
  }
}