import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { GLOBAL } from 'src/app/app.globals';
import { CookieService } from 'ngx-cookie-service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private masterItemDataSource: BehaviorSubject<any> = new BehaviorSubject([]);
  public masterItemData$ = this.masterItemDataSource.asObservable();

  private menuListDataSource: Subject<any> = new Subject();

  constructor(private httpClient: HttpClient) {
  }

  globalMasterItemData(): Observable<any> {
    //   const model = {
    //     ShortCode: '',
    //     Search: '',
    //     PageCode: '',
    //   };
    //   return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}`, model);
    // }
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_MASTER}`,
      httpOptions
    );
  }

  globalMasterSettingData() {
    //   const model = {
    //     Code: ''
    //   };
    //   return this.httpClient.post(`${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}`, model).pipe(
    //     map((response: any) => response));

    // }
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?Code=`,
      httpOptions
    );
  }

  setMasterData(data) {
    this.masterItemDataSource.next(data);
    // alert(this.masterItemDataSource.value);
  }

  setMenuList(data) {
    this.menuListDataSource.next(data);
  }

  getMenuList() {
    return this.menuListDataSource.asObservable();
  }
  getMasterData() {
    // alert(this.masterItemDataSource.value);
    return this.masterItemDataSource.asObservable();
  }

  getMenuListData(): Observable<any> {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_MENU_LIST}?userId=${GLOBAL.USER_ID}`,
      httpOptions
    );
  }

}
