import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
declare var $;
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
 
  @Output() public handleClose: EventEmitter<any> = new EventEmitter<any>();
  @Input() reqApprovalInfo:any;
  @Input() approvalWorkFlowDetails:any;
  @Input() UserDataAccessData:any;

  HrComment: any = '';
  userRoleCodes: any = false;
  currentUserRoles : any = []
  //public formGroup: FormGroup;
  commentForm: FormGroup;
  selectedCheckBoxList = [];
  formArray:FormArray;
  isEnableBtn: boolean= true;
  constructor(private logHelperService: LogHelperService,private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.commentForm = this.formBuilder.group({
      userRoleCodes: new FormArray([]),
      HrComment: ['', Validators.required]
  });
    //let newUser = JSON.parse(localStorage.getItem('Role'));;
    //newUser = newUser.filter((newUser, index, self) => index === self.findIndex((t) => (t.Role_Code === newUser.Role_Code)))
    let newUser = this.UserDataAccessData;
    newUser = newUser.filter((newUser, index, self) => index === self.findIndex((t) => (t.Role_code === newUser.Role_code)));
    this.formArray = this.commentForm.get('userRoleCodes') as FormArray;
    
    for (let i = 0; i < newUser.length; i++) {
      const isRoleExist = this.approvalWorkFlowDetails.find(
        (x) => x.Role_Code === newUser[i].Role_code && x.Plant_Code == this.reqApprovalInfo.labourData.Plant_Code
      );
      if(isRoleExist){
        newUser[i].Priority = isRoleExist.Priority;
        newUser[i].isSelected = false;
        this.currentUserRoles.push({
          Name: isRoleExist.Role,
          Role_Code: isRoleExist.Role_Code,
          Priority: isRoleExist.Priority,
          RequestLevel_Code: isRoleExist.RequestLevel_Code,
          isSelected: false,
          isHide: false,
          DisblabeBox: false
        });
      }
    }
    this.currentUserRoles = this.currentUserRoles.sort((a,b) => a.Priority - b.Priority);

    if(this.currentUserRoles.length == 1){
      this.currentUserRoles[0]['isSelected'] = true;
      this.formArray.push(new FormControl(this.currentUserRoles[0].Role_Code));
      this.isEnableBtn = false;
    }
    console.log('user roles')
    console.log(this.currentUserRoles)
    console.log('sectionPlnt_id')
    console.log(this.reqApprovalInfo.labourData.SectionPlant_ID)
    console.log('useraccess')
    console.log(this.UserDataAccessData)
    for (let i = 0; i < this.currentUserRoles.length; i++) {
      if(this.reqApprovalInfo.labourData.ReqApprovalInfo.some(el => el.Role_Code == this.currentUserRoles[i].Role_Code)){
          this.currentUserRoles[i]['isSelected'] = true;
          this.currentUserRoles[i]['DisblabeBox'] = true;
          this.currentUserRoles[i]['isHide'] = true;
          
          //for (let k = 0; k < this.currentUserRoles.length; k++) {
            const indexNo = this.currentUserRoles.findIndex(object => {
              return object.Priority === this.currentUserRoles[i].Priority && object.isHide == false;
            });
            
            if(indexNo !== -1){
              this.currentUserRoles[indexNo]['DisblabeBox'] = true;
              this.currentUserRoles[indexNo]['isHide'] = true;
            }
          //}
      }
      if (this.currentUserRoles.some(obj => obj.RequestLevel_Code < this.currentUserRoles[i].RequestLevel_Code && obj.Priority === this.currentUserRoles[i].Priority) &&
      !this.UserDataAccessData.some(el => el.SectionRelID == this.reqApprovalInfo.labourData.SectionPlant_ID && el.Role_code == this.currentUserRoles[i].Role_Code)) {
        this.currentUserRoles[i]['isSelected'] = false;
          this.currentUserRoles[i]['DisblabeBox'] = true;
      }
      if(!this.UserDataAccessData.some(el => el.SectionRelID == this.reqApprovalInfo.labourData.SectionPlant_ID && el.Role_code == this.currentUserRoles[i].Role_Code)){
        this.currentUserRoles[i]['isHide'] = true;
        this.currentUserRoles[i]['DisblabeBox'] = true;
      }
    }
  }

  onCheckChange(event, roleData, index) {
    
   // const formArray: FormArray = this.commentForm.get('userRoleCodes') as FormArray;
    if(event.target.checked){
      let prevPriority = roleData.Priority - 1;
      this.currentUserRoles[index]['isSelected'] = true;
      const prevPriorityRoles = this.reqApprovalInfo.labourData.ReqApprovalInfo.filter(
        (x) => x.Priority === prevPriority
      );
      if((prevPriorityRoles.length == 0 && this.currentUserRoles.some(el => el.isSelected == false && el.Priority == prevPriority)) || (prevPriorityRoles.length == 0 && this.approvalWorkFlowDetails.some(el => el.Priority == prevPriority && this.currentUserRoles.some(el => el.Priority != prevPriority)))){
        this.logHelperService.logError({ message: 'Please select as per priority!' });
        this.currentUserRoles[index]['isSelected'] = false;
            $(".cls_"+roleData.Role_Code).prop('checked', false);
      }
      
      if(this.currentUserRoles.some(el => el.Priority < roleData.Priority && el.isSelected == false && el.DisblabeBox == false && el.Priority != prevPriority)){
        this.logHelperService.logError({ message: 'Please select as per priority!' });
            this.currentUserRoles[index]['isSelected'] = false;
            $(".cls_"+roleData.Role_Code).prop('checked', false);
      }else{
        this.formArray.push(new FormControl(event.target.value));
        this.isEnableBtn = false;
      }
    }
    else{
        for (let i = 0; i < this.currentUserRoles.length; i++) {
          if(this.currentUserRoles[i].Priority > roleData.Priority && this.currentUserRoles[i].isSelected == true){
            this.currentUserRoles[i]['isSelected'] = false;
            $(".cls_"+this.currentUserRoles[i].Role_Code).prop('checked', false);
            let k: number = 0;
            this.formArray.controls.forEach((ctrl: FormControl) => {
              if(ctrl.value == this.currentUserRoles[i].Role_Code) {
                this.formArray.removeAt(k);
                return;
              }
              k++;
            });
          }else{
            let k: number = 0;
            this.formArray.controls.forEach((ctrl: FormControl) => {
              if(ctrl.value == event.target.value) {
                this.currentUserRoles[index]['isSelected'] = false;
                this.formArray.removeAt(k);
                return;
              }
              k++;
            });
          }
        }
    }
  }
  public onClose(data?: any): void {
    this.handleClose.emit(data);
  }
 
  next() {
            if(!this.commentForm.get('HrComment').valid) {
              this.logHelperService.logError({ message: 'Please enter the comment!' });
              return false;
            }
            if(!this.commentForm.get('userRoleCodes').valid) {
              this.logHelperService.logError({ message: 'Please select role!' });
              return false;
            }
            if (!this.commentForm.invalid) {
             this.onClose(this.commentForm.value);
            }
  }
}
