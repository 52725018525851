import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import { GridOptions } from 'ag-grid-community';
import { AgInputEditCellRendererComponent } from './../ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { AgSelectEditCellRendererComponent } from './../ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component';
import { AgDatePickerEditCellRendererComponent } from './../ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component';
import { AgIconActionCellRendererComponent } from './../ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { CookieService } from 'ngx-cookie-service';
import { ImportExcelService } from '../import-excel.service';
import { AgTimePickerEditCellRendererComponent } from '../ag-timepicker-edit-cell-renderer/ag-timepicker-edit-cell-renderer.component';
import { GLOBAL } from 'src/app/app.globals';

declare const $:  any;

@Component({
  selector: 'app-import-manual-punch',
  templateUrl: './import-manual-punch.component.html',
  styleUrls: ['./import-manual-punch.component.css']
})
export class ImportManualPunchComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  fileError = '';

  public closeModal: EventEmitter<any>;

  maindivclass = 'content Overlapclass';
  userEmail = ''; // GetCokkiesValue(3);
  loading = false;
  maxGridHeight = window.innerHeight - 210;
  fullHeight = `${(window.innerHeight - 110)}px`;
  fileObj = null;
  attendanceData = [];

  gatePassMap = {};
  gatePassNotFoundSet = new Set();
  dateOptions = {
    showWeeks: false
  };

  plantUnitDepartmentTree = [];

  downloadSampleList =  [
    {
      Name: 'Goa',
      Url: 'assets/sample/import/manual-punch/Import-manual-punch_V1_Goa.xlsx',
    }
  ];

  onInputTextChange = (rowIndex, field) => {
    $('div[uib-tooltip-popup]').remove();

    if (field === 'Plant_Code') {
      const row = this.attendanceData[rowIndex];
      row.Unit_Code = null;
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === 'Unit_Code') {
      const row = this.attendanceData[rowIndex];
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    // if (field === 'Department_Code') {
    //   const row = this.attendanceData[rowIndex];
    //   row.SectionPlantRelationId1 = null;
    //   row.SectionPlantRelationId2 = null;
    //   row.SectionPlantRelationId3 = null;
    // }

    // if (field === 'SectionPlantRelationId1') {
    //   const row = this.attendanceData[rowIndex];
    //   row.SectionPlantRelationId2 = null;
    //   row.SectionPlantRelationId3 = null;
    // }

    // if (field === 'SectionPlantRelationId2') {
    //   const row = this.attendanceData[rowIndex];
    //   row.SectionPlantRelationId3 = null;
    // }

    // if (field === 'SectionPlantRelationId3') {
    // }

    const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    const rowNodes = [row]; // params needs an array
    // const params = {
    //   force: true,
    //   rowNodes: rowNodes,
    //   columns: [field]
    // };
    // this.gridOptions.api.refreshCells(params);
    // tslint:disable-next-line
    this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    // this.gridOptions.api.refreshCells({ rowNodes: rowNodes, columns: [field], force: true });
  }

  isColValid = (rowIndex, field) => {
    const returnObj = {
      isValid: true,
      requireValidation: false,
      msg: ''
    };
    let val = '';
    const requireValidationMessage = 'Validation required';

    switch (field) {
      case 'Code':
        val = this.attendanceData[rowIndex][field] || '';
        const ulc = this.gatePassMap[val];
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter ULC.';
          // } else if (this.attendanceData[rowIndex]['PermanentVillageCode'] === null) {
        } else if (!ulc) {
          returnObj.isValid = false;
          if (this.gatePassNotFoundSet.has(val)) {
            returnObj.msg = ' ULC not found in master data. Please contract administrator or change ULC.';
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
        } else {
          this.attendanceData[rowIndex]['ULC'] = ulc ? ulc : null;
        }
        break;
      case 'Plant_Code':
      case 'Unit_Code':
        // case 'Department_Code':
        // case 'Secti onPlantRelationId1':
        // case 'SectionPlantRelationId2':
        // case 'SectionPlantRelationId3':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select ${field}.`;
        }
        break;
      case 'Date':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select Date.`;
        }
        break;
      case 'PInTime':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select In Time.`;
        }
        break;
      case 'POutTime':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select Out Time.`;
        }
        break;
      case 'UInTime':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select In Time.`;
        }
        break;
      case 'UO utTime':
        val = this.attendanceData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select Out Time.`;
        }
        break;
      default:
        break;
    }

    return returnObj;
  }

  deleteRow = (rowIndex) => {
    this.attendanceData.splice(rowIndex, 1);
    this.gridOptions.api.setRowData(this.attendanceData);
  }

  getPlantList = (rowIndex, field) => {

    return this.plantUnitDepartmentTree.map((item) => {
      return { id: item.Plant_Code, text: item.Name };
    });
  }

  getUnitList = (rowIndex,  field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plant_Code === row.Plant_Code).UnitTree
      .map((item) => {
        return { id: item.Unit_Code, text: item.Name };
      });
  }

  getDepartmentList = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plant_Code === row.Plant_Code).UnitTree
      .find(item => item.Unit_Code === row.Unit_Code).DepartmentTree
      .map((item) => {
        return { id: item.UnitDeptRelID, text: item.Name };
      });
  }

  getSection1List = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    return this.plantUnitDepartmentTree
      .find(item => item.Plant_Code === row.Plant_Code).UnitTree
      .find(item => item.Unit_Code === row.Unit_Code).DepartmentTree
      .find(item => item.UnitDeptRelID === row.Department_Code).SectionTree
      .map((item) => {
        return { id: item.SectionPlantRelationId, text: item.Name };
      });
  }

  getSection2List = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code || !row.SectionPlantRelationId1) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);
    return this.plantUnitDepartmentTree
      .find(item => item.Plant_Code === row.Plant_Code).UnitTree
      .find(item => item.Unit_Code === row.Unit_Code).DepartmentTree
      .find(item => item.UnitDeptRelID === row.Department_Code).SectionTree
      .find(item => item.SectionPlantRelationId === row.SectionPlantRelationId1).SectionList
      .map((item) => {
        return { id: item.SectionPlantRelationId, text: item.Name };
      });
  }

  getSection3List = (rowIndex, field) => {
    const row = this.attendanceData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code || !row.SectionPlantRelationId1 || !row.SectionPlantRelationId2) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code,  10);
    row.Department_Code = parseInt(row.Department_Code,  10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1,  10);
    row.SectionPlantRelationId2 = parseInt(row.SectionPlantRelationId2,  10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plant_Code === row.Plant_Code).UnitTree
      .find(item => item.Unit_Code === row.Unit_Code).DepartmentTree
      .find(item => item.UnitDeptRelID === row.Department_Code).SectionTree
      .find(item => item.SectionPlantRelationId === row.SectionPlantRelationId1).SectionList
      .find(item => item.SectionPlantRelationId === row.SectionPlantRelationId2).SectionList
      .map((item) => {
        return { id: item.SectionPlantRelationId, text: item.Name };
      });
  }

  // tslint:disable-next-line
  columnDefs = [
    {
      headerName: '', field: 'act', width: 30, minWidth: 30, maxWidth: 30,
      cellRendererFramework: AgIconActionCellRendererComponent,
      cellRendererParams: {
        onClick: this.deleteRow,
        title: 'Delete',
        width: 30,
        icon: 'fa fa-times',
        cssClass: 'row-delete-icon',
        fontSize: 24,
      }, pinned: 'left'
    },
    {
      headerName: 'Code', field: 'Code', width: 100, minWidth: 120, maxWidth: 120,
      cellRendererFramework: AgInputEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Date', field: 'Date', width: 120, minWidth: 120, maxWidth: 120,
      cellRendererFramework: AgDatePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Plant', field: 'Plant_Code', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgSelectEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
        dataListByIndex: this.getPlantList,
      }
    },
    {
      headerName: 'In-Time', field: 'PInTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Out-Time', field: 'POutTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Unit', field: 'Unit_Code', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgSelectEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
        dataListByIndex: this.getUnitList,
      }
    },
    {
      headerName: 'In-Time', field: 'UInTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    {
      headerName: 'Out-Time', field: 'UOutTime', width: 100, minWidth: 100, maxWidth: 120,
      cellRendererFramework: AgTimePickerEditCellRendererComponent,
      cellRendererParams: {
        onInputTextChange: this.onInputTextChange,
        isColValid: this.isColValid,
      }
    },
    // {
    //   headerName: 'Department', field: 'Department_Code', width: 100, minWidth: 100,
    //   cellRendererFramework: AgSelectEditCellRendererComponent,
    //   cellRendererParams: {
    //     onInputTextChange: this.onInputTextChange,
    //     isColValid: this.isColValid,
    //     dataListByIndex: this.getDepartmentList,
    //   }
    // },
    // {
    //   headerName: 'Section', field: 'SectionPlantRelationId1', width: 100, minWidth: 100,
    //   cellRendererFramework: AgSelectEditCellRendererComponent,
    //   cellRendererParams: {
    //     onInputTextChange: this.onInputTextChange,
    //     isColValid: this.isColValid,
    //     dataListByIndex: this.getSection1List,
    //   }
    // },
    // {
    //   headerName: 'Sub-Section 1', field: 'SectionPlantRelationId2', width: 100, minWidth: 100,
    //   cellRendererFramework: AgSelectEditCellRendererComponent,
    //   cellRendererParams: {
    //     onInputTextChange: this.onInputTextChange,
    //     isColValid: this.isColValid,
    //     dataListByIndex: this.getSection2List,
    //   }
    // },
    // {
    //   headerName: 'Sub-Section 2', field: 'SectionPlantRelationId3', width: 100, minWidth: 100,
    //   cellRendererFramework: AgSelectEditCellRendererComponent,
    //   cellRendererParams: {
    //     onInputTextChange: this.onInputTextChange,
    //     isColValid: this.isColValid,
    //     dataListByIndex: this.getSection3List,
    //   }
    // },
  ];

  // tslint:disable-next-line
  gridOptions: GridOptions = {
    context: {
      componentParent: this
    },
    columnDefs: this.columnDefs,
    rowData: this.attendanceData,
    headerHeight: 39,
    rowHeight: 39,
    onGridReady: (params) => {
      // this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    // onGridReady: (params) => {
    //   params.api.sizeColumnsToFit();
    //   //params.api.setRowData(this.data.ContractorVendor);
    // },
    // onGridSizeChanged: (params) => {
    //   params.api.sizeColumnsToFit();
    // },
    onCellFocused: (params) => {
      const rowIndex = params.rowIndex;
      if (params.column) {
        const field = params.column.getColId();
        this.setFocusInput(rowIndex, field);
      }
    },
    getRowStyle: (params) => {
      let isValid = true;
      const keys = Object.keys(params.data);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(params.node.rowIndex, keys[j]);
        if (!validObj.isValid) {
          isValid = false;
          break;
        }
      }

      if (isValid) {
        this.attendanceData[params.node.rowIndex]['rowValid'] = true;
        return { background: '#33CC00 !important' };
      }

      this.attendanceData[params.node.rowIndex]['rowValid'] = false;
      return { background: '#FFFF95 !important' };
    },
    pagination: false,
    paginationPageSize: 10,
    suppressMovableColumns: true,
    // angularCompileRows: true
  };

  setFocusInput = (rowIndex, field) => {
    // const colInput = $(`#importGrid .ag-body-container [row-index='${rowIndex}'] [col-id=''${field}'] input`);
    // const colSelect = $(`#importGrid .ag-body-container [row-index='${rowIndex}'] [col-id=''${field}'] select`);
    // const colLeftPinInput = $(`#importGrid .ag-pinned-left-cols-container [row-index='${rowIndex}'] [col-id=''${field}'] input`);
    // const colLeftPinSelect = $(`#importGrid .ag-pinned-left-cols-container [row-index='${rowIndex}'] [col-id='${field}'] select`);
    // if (colInput.length > 0) {
    //   colInput.focus();
    // } else if (colSelect.length > 0) {
    //   colSelect.focus();
    // } else if (colLeftPinInput.length > 0) {
    //   colLeftPinInput.focus();
    // } else if (colLeftPinSelect.length > 0) {
    //   colLeftPinSelect.focus();
    // }
  }

  constructor(
    private loadingService: Ng4LoadingSpinnerService,
    private cookieService: CookieService,
    private importExcelService: ImportExcelService
  ) {
    this.closeModal = new EventEmitter();
    this.userEmail = String(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserEmail'));
  }

  ngOnInit() {
    this.loadGlobalSettings();
    this.getPlantUnitDepartmentTree();
    setTimeout(() => {
      // this.bindExcelData(null);
      this.gridOptions.api.sizeColumnsToFit();
    }, 2000);
  }

  public getPlantUnitDepartmentTree() {

    this.importExcelService.getDepartmentPlantUnitSectionTree()
      .subscribe((response) => {
        const companyData = response.find(item => item.Company_Code === 101);
        this.plantUnitDepartmentTree = companyData ? companyData.PlantTree : companyData;
      });
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  onFileChange($event) {
    this.validateFile($event.target);
  }

  onUploadFile() {

    // this.bindExcelData(null);
    if (this.validateFile(this.fileInput.nativeElement)) {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        if (e.target) {
          /* read workbook */
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* save data */
          const fileContent = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null, raw: false });

          if (!fileContent) {
            this.fileError = 'Please select a file to upload.';
            return;
          }
          const keys: any = fileContent.shift();
          const data = fileContent.map((e) => {
            const obj = {};
            for (let i = 0; i < keys.length; i++) {
              obj[keys[i]] = e[i];
            }
            return obj;
          });

          if (data.length === 0) {
            this.loadingService.hide();
            this.fileError = 'AtLeast one record should be availabe in file to upload a file.';
            return;
          }
          if (data.length > 50) {
            this.loadingService.hide();
            this.fileError = 'Maximum 50 Records is allowed to import.';
            return;
          }

          // this.loadingService.hide();
          localStorage.setItem('xlsx_data', JSON.stringify(data));
          this.bindExcelData(this.removeSortCharFromKeys(data));
        }
      };
      this.loadingService.show();
      setTimeout(() => {
        reader.readAsBinaryString(this.fileInput.nativeElement.files[0]);
      }, 1000);
    }
  }

  validateFile(target) {
    this.fileError = '';
    if (target.files.length === 0) {
      this.fileError = 'Please select Excel file';
      target.value = '';
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = 'Cannot use multiple files';
      target.value = '';
      return false;
    }

    if (!(target.files[0].name.endsWith('.xlsx') || target.files[0].name.endsWith('.xls'))) {
      this.fileError = 'Invalid File format, please select Excel file only.';
      target.value = '';
      return false;
    }

    return true;
  }

  removeSortCharFromKeys(data) {
    const newData = [];
    data.forEach((obj) => {
      const newObj = Object.keys(obj).reduce((result, key) => {
        const newKey = key.replace('↓', '').trim();
        result[newKey] = obj[key];
        return result;
      }, Array.isArray(obj) ? [] : {});
      newData.push(newObj);
    });

    return newData;
  }

  bindExcelData(data) {

    const data1 = [{
      Plant: '1111',
    }];
    const data2 = [
      {
        Code: '11200225',
        Date: '1/1/20',
        Plant: 'Goa 1',
        'P-In-Time': '',
        'P-Out-Time': '15:00',
        Unit: 'Unit-1',
        'U-In-Time': '8:30',
        'U-Out-Time': '15:00',
        // Department: 'Accounts',
        // Section: 'Packing',
        // 'Sub-Section 1': 'FFS',
        // 'Sub-Section 2': 'Line 2',
      }
    ];

    const importData = [];
    for (let index = 0; index < data.length; index++) {

      const element = data[index];
      if (element && Object.keys(element).length > 0) {
        let date = (element['Date'] || '').toString().trim();
        date = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (date === 'Invalid date') {
          date = '';
        }

        const plant = (element['Plant'] || '').toString().trim();
        const unit = (element['Unit'] || '').toString().trim();
        // const department = (element['Department'] || '').toString().trim();
        // const section1 = (element['Section'] || '').toString().trim();
        // const section2 = (element['Sub-Section 1'] || '').toString().trim();
        // const section3 = (element['Sub-Section 2'] || '').toString().trim();

        let plantCode = null;
        let unitCode = null;
        // let departmentCode = null;
        // let sectionPlantRelationId1 = null;
        // let sectionPlantRelationId2 = null;
        // let sectionPlantRelationId3 = null;

        const plantFound = this.plantUnitDepartmentTree.find(item => item.Name.toLowerCase() === plant.toLowerCase());
        if (plantFound) {
          plantCode = plantFound.Plant_Code;

          const unitFound = plantFound.UnitTree.find(item => item.Name.toLowerCase() === unit.toLowerCase());
          if (unitFound) {
            unitCode = unitFound.Unit_Code;

            // const departmentFound = unitFound.DepartmentTree.find(item => item.Name.toLowerCase() === department.toLowerCase());
            // if (departmentFound) {
            //   departmentCode = departmentFound.UnitDeptRelID;

            //   const section1Found = departmentFound.SectionTree.find(item => item.Name.toLowerCase() === section1.toLowerCase());
            //   if (section1Found) {
            //     sectionPlantRelationId1 = section1Found.SectionPlantRelationId;

            //     const section2Found = section1Found.SectionList.find(item => item.Name.toLowerCase() === section2.toLowerCase());
            //     if (section2Found) {
            //       sectionPlantRelationId2 = section2Found.SectionPlantRelationId;

            //       const section3Found = section2Found.SectionList.find(item => item.Name.toLowerCase() === section3.toLowerCase());
            //       if (section3Found) {
            //         sectionPlantRelationId3 = section3Found.SectionPlantRelationId;
            //       }
            //     }
            //   }
            // }
          }
        }

        importData.push({
          Code: (element['Code'] || '').toString().trim(),
          Date: date,
          Plant_Code: 100,
          PInTime: (element['P-In-Time'] || '').toString().trim(),
          POutTime: (element['P-Out-Time'] || '').toString().trim(),
          Unit_Code: 100,
          UInTime: (element['U-In-Time'] || '').toString().trim(),
          UOutTime: (element['U-Out-Time'] || '').toString().trim(),
          // Department_Code: departmentCode,
          // SectionPlantRelationId1: sectionPlantRelationId1,
          // SectionPlantRelationId2: sectionPlantRelationId2,
          // SectionPlantRelationId3: sectionPlantRelationId3,
        });

        console.log('importData -------> ', importData);
      }
    }
    this.attendanceData = importData;
    this.gridOptions.api.setRowData(this.attendanceData);
    this.loadingService.hide();
    this.fileInput.nativeElement.value = null;
    this.validateDataByService();
  }

  validateDataByService() {
    const gatePassSet = new Set();

    this.attendanceData.forEach((item) => {

      if (item.Code) {
        gatePassSet.add(item.Code);
      }
    });

    this.validateGatePass(Array.from(gatePassSet), null, null);
  }


  validateData() {
    const returnObj = {
      isValid: true,
      rowIndex: -1,
      field: '',
      msg: ''
    };

    const isValid = true;
    for (let i = 0; i < this.attendanceData.length; i++) {
      const keys = Object.keys(this.attendanceData[i]);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(i, keys[j]);
        if (!validObj.isValid) {
          returnObj.isValid = false;
          returnObj.rowIndex = i;
          returnObj.field = keys[j];
          returnObj.msg = '';
          break;
        }
      }
      if (!returnObj.isValid) {
        break;
      }
    }
    return returnObj;
  }

  getApiRequestData() {
    const data = [];
    this.attendanceData.forEach((row) => {
      const tmpData = {
        UserEmail: this.userEmail,
        UserId: GLOBAL.USER_ID,
        Code: row.Code,
        ULC: row.ULC,
        ContractorCode: null,
        Date: this.formatToServerDate(row.Date),
        Plant_Code: row.Plant_Code ? parseInt(row.Plant_Code, 10) : null,
        InTime: row.PInTime,
        OutTime: row.POutTime,
        Unit_Code: row.Plant_Code ? parseInt(row.Unit_Code, 10) : null,
        InTime1: row.UInTime,
        OutTime1: row.UOutTime,
        // SectionPlantRelId: row.SectionPlantRelationId3 ? parseInt(row.SectionPlantRelationId3, 10) : null, // ?
      };

      data.push(tmpData);
    });

    return data;
  }

  public formatToServerDate(date) {
    if (date) {
      let a = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (a === 'Invalid date') {
        a = null;
      }
      return a;
    }

    return null;
  }

  saveData() {

    const valid = this.validateData();
    if (valid.isValid) {
      this.loading = true;
      this.importExcelService.importManualAttendance(this.getApiRequestData()).subscribe((response) => {
        this.loading = false;
        if (response.Status) {
          this.attendanceData = [];
          this.gridOptions.api.setRowData(this.attendanceData);
          alert('Attendance data imported successfully.');
        } else {
          alert('Error in importing data. Please try later.');
        }
      }, (err) => {
        this.loading = false;
        alert('Error in importing data. Please try later.');
      });

    } else {
      if (valid.msg === '') {
        alert('Please correct data.');
      } else {
        alert(valid.msg);
      }
      setTimeout(() => {
        this.setFocusInput(valid.rowIndex, valid.field);
      }, 2000);
    }
  }

  loadGlobalSettings() {
    // this.importExcelService.getGlobalSettings().then((response) => {
    //   console.log(response.data);
    //   this.isAdhaarMandatory = response.data.ADHAAR01 === 1 ? true : false;
    //   this.isBankMandatory = response.data.BANK01 === 1 ? true : false;
    // }, (err) => {
    //   alert(err);
    // });
  }

  revalidateRow(rowIndex, fieldName) {
    if (fieldName) {
      const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
      const rowNodes = [row]; // params needs an array
      // tslint:disable-next-line
      this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    } else {
      this.gridOptions.api.redrawRows();
    }
  }

  validateGatePass(list, rowIndex, fieldName) {
    let newList = list.filter(item => !this.gatePassMap[item]);
    newList = newList.filter(item => !this.gatePassNotFoundSet.has(item));
    if (newList.length === 0) {
      this.revalidateRow(rowIndex, fieldName);
      return;
    }

    this.importExcelService.validateGatePass(newList)
      .subscribe((res) => {
        if (res.Data) {
          res.Data.forEach((item) => {
            if (item.IsExist) {
              this.gatePassMap[item.GatePassNo] = item.ULC ? item.ULC : 1;
            } else {
              this.gatePassNotFoundSet.add(item.GatePassNo);
            }
          });
        }

        newList.forEach((item) => {
          if (!this.gatePassMap[item]) {
            this.gatePassNotFoundSet.add(item);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }
}
