import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { GatePassRequestsService } from '../../gate-pass-requests.service';
import { VerificationHistoryComponent } from '../verification-history/verification-history.component';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedService } from 'src/app/core/services';
import { getAlertMessageByShortCode } from 'src/app/core/utility/utility';

let userID: string;
userID = GLOBAL.USER_ID;
const today = new Date();
const today1 = (new Date(today)).toLocaleDateString();
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  @Input() resolveObject;
  documentUrl = GLOBAL.BASE_URL_DOC;
  photoUrl = GLOBAL.PHOTO_URL;
  approvalData: any;
  labourData: any;
  approvalOn = {
    firstNameSelected: false,
    firstNameDisable: false,
    firstNameHidden: false,
    lastNameSelected: false,
    lastNameDisable: false,
    lastNameHidden: false,
    middleNameSelected: false,
    middleNameDisable: false,
    middleNameHidden: false,
    dobSelected: false,
    dobDisable: false,
    dobHidden: false,
    voterIdSelected: false,
    voterIdDisable: false,
    voterIdHidden: false,
    aadharCardSelected: false,
    aadharCardDisable: false,
    aadharCardHidden: false,
    drivingLicenseSelected: false,
    drivingLicenseDisable: false,
    drivingLicenseHidden: false,
    uanSelected: false,
    uanDisable: false,
    uanHidden: false,
    panNoSelected: false,
    panNoDisable: false,
    panNoHidden: false,
    bankAccSelected: false,
    bankAccDisable: false,
    bankAccHidden: false,
  };
  public checkdocverify = false;
  documents = [];
  public isverifydisable = false;
  public disableverifybutton = true;
  laboutVerifyParams: any = {};
  selectedULC = 0;
  documentVerifyRequired = false;
  public alertMessages: any[];
  selectedParams: any = {};

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private gatePassRequestsService: GatePassRequestsService,
    private logHelperService: LogHelperService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    this.sharedService.alertMessages$.subscribe((alertMessages: any[]) => {
      this.alertMessages = alertMessages;
    });
    this.approvalData = this.resolveObject.approvalData;
    this.labourData = this.resolveObject.labourData;
    this.selectedULC = this.labourData.ULC;
    // this.getDocumentVerify();
    this.getDocuments();
    if (this.approvalData.ULC) {
      this.checkdocverify = true;
      this.disableverifybutton = false;
    } else {
      this.checkdocverify = false;
      this.disableverifybutton = true;
    }
    this.laboutVerifyParams = {
      AdharCardId: this.approvalData.AadharCard,
      DateofBirth: '',
      FirstName: '',
      LabourCode: '',
      LastName: '',
      MiddleName: '',
      PanNumber: '',
      VoterIdCard: ''
    };
    this.getLabourVerifyData(false, '');
    this.setAction();
  }

getLabourVerifyData(event, control) {
  console.log('::::::::::::::: event.target.checked :::::::::::::::', event.target.checked);
  console.log('::::::::::::::: control :::::::::::::::', control);
  console.log('::::::::::::::: this.resolveObject.labourData :::::::::::::::', this.resolveObject.labourData);
  console.log('::::::::::::::: this.approvalData :::::::::::::::', this.approvalData);
    
  if (event.target.checked) {
    switch (control) {
      case 'aid':
        this.selectedParams.AdharCardId = this.approvalData.AadharCard;
        break;
      case 'dob':
        this.selectedParams.DateofBirth = this.resolveObject.labourData.DOB;
        break;
      case 'fname':
        this.selectedParams.FirstName = this.resolveObject.labourData.FirstName;
        break;
      case 'lname':
        this.selectedParams.LastName = this.resolveObject.labourData.LastName;
        break;
      case 'mname':
        this.selectedParams.MiddleName = this.resolveObject.labourData.MiddleName;
        break;
      case 'pno':
        this.selectedParams.PanNumber = this.approvalData.PanCard;
        break;
      case 'vid':
        this.selectedParams.VoterIdCard = this.resolveObject.labourData.Vatoreid;
        break;
      case 'uan':
        this.selectedParams.VoterIdCard = this.resolveObject.labourData.ULC;
        break;
    }
  } else {
    switch (control) {
      case 'aid':
        delete this.selectedParams.AdharCardId;
        break;
      case 'dob':
        delete this.selectedParams.DateofBirth;
        break;
      case 'fname':
        delete this.selectedParams.FirstName;
        break;
      case 'lname':
        delete this.selectedParams.LastName;
        break;
      case 'mname':
        delete this.selectedParams.MiddleName;
        break;
      case 'pno':
        delete this.selectedParams.PanNumber;
        break;
      case 'vid':
        delete this.selectedParams.VoterIdCard;
        break;
      case 'uan':
        delete this.selectedParams.VoterIdCard;
        break;
    }
  }

  if (Object.keys(this.selectedParams).length > 0) {
    this.gatePassRequestsService.getLabourVerifyData(this.selectedParams).subscribe((labourVerificationData: any) => {
      this.resolveObject['labourVerificationData'] = labourVerificationData.Data;
    });
  } else {
    console.log('No parameters selected for verification.');
  }
}

  

  setAction() {
    this.gatePassRequestsService.globalSettingCode('').subscribe((res: any) => {
      //   let firstNameRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_1')
      //   if(firstNameRoleCode.Value === "1"){
      //     this.approvalOn.firstNameSelected = true;
      //   }else if(firstNameRoleCode.Value === "2"){
      //     this.approvalOn.firstNameDisable = true;
      //     this.approvalOn.firstNameSelected = true;
      //   }else if(firstNameRoleCode.Value === "3"){
      //     this.approvalOn.firstNameHidden = true;
      //   }

      //   let lastNameRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_3')
      //   if(lastNameRoleCode.Value === "1"){
      //     this.approvalOn.lastNameSelected = true;
      //   }else if(lastNameRoleCode.Value === "2"){
      //     this.approvalOn.lastNameDisable = true;
      //     this.approvalOn.lastNameSelected = true;
      //   }else if(lastNameRoleCode.Value === "3"){
      //     this.approvalOn.lastNameHidden = true;
      //   }

      //   let middleNameRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_2')
      //   if(middleNameRoleCode.Value === "1"){
      //     this.approvalOn.middleNameSelected = true;
      //   }else if(middleNameRoleCode.Value === "2"){
      //     this.approvalOn.middleNameDisable = true;
      //     this.approvalOn.middleNameSelected = true;
      //   }else if(middleNameRoleCode.Value === "3"){
      //     this.approvalOn.middleNameHidden = true;
      //   }

      //   let dobRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_4')
      //   if(dobRoleCode.Value === "1"){
      //     this.approvalOn.dobSelected = true;
      //   }else if(dobRoleCode.Value === "2"){
      //     this.approvalOn.dobDisable = true;
      //     this.approvalOn.dobSelected = true;
      //   }else if(dobRoleCode.Value === "3"){
      //     this.approvalOn.dobHidden = true;
      //   }

      //   let voterIdRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_5')
      //   if(voterIdRoleCode.Value === "1"){
      //     this.approvalOn.voterIdSelected = true;
      //   }else if(voterIdRoleCode.Value === "2"){
      //     this.approvalOn.voterIdDisable = true;
      //     this.approvalOn.voterIdSelected = true;
      //   }else if(voterIdRoleCode.Value === "3"){
      //     this.approvalOn.voterIdHidden = true;
      //   }

      let aadharCardRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_6')
      if (aadharCardRoleCode.Value === "1") {
        this.approvalOn.aadharCardSelected = true;
      } else if (aadharCardRoleCode.Value === "2") {
        this.approvalOn.aadharCardDisable = true;
        this.approvalOn.aadharCardSelected = true;
      } else if (aadharCardRoleCode.Value === "3") {
        this.approvalOn.aadharCardHidden = true;
      }

      // let drivingLicenseRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_8')
      // if(drivingLicenseRoleCode.Value === "1"){
      //   this.approvalOn.drivingLicenseSelected = true;
      // }else if(drivingLicenseRoleCode.Value === "2"){
      //   this.approvalOn.drivingLicenseDisable = true;
      //   this.approvalOn.drivingLicenseSelected = true;
      // }else if(drivingLicenseRoleCode.Value === "3"){
      //   this.approvalOn.drivingLicenseHidden = true;
      // }

      let uanRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_9')
      if(uanRoleCode.Value === "1"){
        this.approvalOn.uanSelected = true;
      }else if(uanRoleCode.Value === "2"){
        this.approvalOn.uanDisable = true;
        this.approvalOn.uanSelected = true;
      }else if(uanRoleCode.Value === "3"){
        this.approvalOn.uanHidden = true;
      }

      let panNoRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_10')
      if(panNoRoleCode.Value === "1"){
        this.approvalOn.panNoSelected = true;
      }else if(panNoRoleCode.Value === "2"){
        this.approvalOn.panNoDisable = true;
        this.approvalOn.panNoSelected = true;
      }else if(panNoRoleCode.Value === "3"){
        this.approvalOn.panNoHidden = true;
      }

      let bankAccRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQVERCHE_11')
      if(bankAccRoleCode.Value === "1"){
        this.approvalOn.bankAccSelected = true;
      }else if(bankAccRoleCode.Value === "2"){
        this.approvalOn.bankAccDisable = true;
        this.approvalOn.bankAccSelected = true;
      }else if(bankAccRoleCode.Value === "3"){
        this.approvalOn.bankAccHidden = true;
      }
      let verifyRoleCode = res.Data.Table.find(item => item.Code === 'MOD52PG195REQDOCVER');
      if (verifyRoleCode.Value === "1") {
        this.documentVerifyRequired = true;
      } else {
        this.documentVerifyRequired = false;
      }
      let verifyCheckRoleCode = this.getIsAvailableRole('MOD52PG195REQDOCAPPR', res.Data.Table);
      this.disableverifybutton = verifyCheckRoleCode;
    })
  }

  getIsAvailableRole(data: any, data2: any) {
    const list = data2.find(item => item.Code === data);
    const list2 = list ? data2.find(item => item.Code === data).Role : null;
    const roles = JSON.parse(localStorage.getItem('Role'));
    const roleList: number[] = roles.map((roleItem: any) => roleItem.Role_Code);
    return list2 ? roleList.some(item => list2.includes(item)) : false;
  }

  getDocumentVerify($event, document) {
    console.log('$event', $event, $event.target.checked);
    let index = this.documents.findIndex(item => item.$id === document.$id);
    let verifiedDocument;
    this.approvalData.IsVerifyDoc = $event.target.checked;
    if (this.approvalData.IsVerifyDoc === true) {
      if (index !== -1) {
        this.documents[index].IsVerify = true;
        this.documents[index].VerifyDate = today1;
        this.documents[index].VerifyBy = localStorage.getItem('UserName');
        verifiedDocument = this.documents[index];
        let verifiedDocumentData = {
          ID: verifiedDocument.ID,
          Page_Code: verifiedDocument.Page_Code,
          Referance_Code: verifiedDocument.Referance_Code,
          RefType_Code: null,
          DocType_Code: null,
          DShort_Code: null,
          PhotoImage: null,
          IsVerify: 1,
          VerifyDate: verifiedDocument.VerifyDate,
          RequireType: null,
          ActionType: 3,
          UserID: GLOBAL.USER_ID
        }
        this.gatePassRequestsService.insertDeleteDoc(verifiedDocumentData).subscribe((res: any) => { })
      }
    }
    else {
      if (index !== -1) {
        this.documents[index].IsVerify = false;
      }
    } //else {
    // this.isverifydisable = false;
    // this.approvalData.verificationDate = null;
    // this.approvalData.UserforEPF = null;
    // this.logHelperService.logError({
    //   message: 'Please Verify Document First!!'
    // });
    //}
  }

  labourVerifications() {
    let index = this.documents.findIndex(item => !item.IsVerify);
    if (this.documentVerifyRequired && index > -1) {
      this.logHelperService.logError({ message: getAlertMessageByShortCode(this.alertMessages, 'MSG195_16') });
      return false;
    }
    this.gatePassRequestsService.labourVerifications(
      this.labourData.Labour_Code,
      this.selectedULC || 0,
      this.approvalData.IsVerifyDoc,
      userID).subscribe((res: any) => {

        // this.getDocumentVerify();
        // if (this.verifyOn.IsVerifyDoc) {
        if (res) {
          this.logHelperService.logSuccess({ message: 'Associate Verified' });
          this.activeModal.close(res);
        } else {
          this.logHelperService.logError({ message: 'Associate can\'t Verified!' });
          this.activeModal.close(false);
        }
        // } else {
        //   alert('Please Verify Document First!!');
        // }
      });
  }

  getLabourHistory(labourCode) {
    this.gatePassRequestsService.getHistoryData(labourCode).subscribe((res: any) => {
      const modalRef = this.modalService.open(VerificationHistoryComponent,
        { size: 'lg', backdrop: 'static', windowClass: 'history-window' });
      modalRef.componentInstance.rowData = res.Data.Table;
    });
  }

  getThisVerifiedData(rowData) {
    this.resolveObject.labourVerificationData.forEach((rowData) => {
      rowData.isSelected = false;
    });
    if (rowData.ULC) {
      rowData.isSelected = true;
      this.selectedULC = rowData.ULC;
    } else {
      rowData.isSelected = false;
    }
  }

  getDocuments() {
    this.gatePassRequestsService.getDocumentForLabourVerify(this.resolveObject.labourData.Labour_Code)
      .subscribe((verificationDoc: any) => {
        if (verificationDoc.Status) {
          this.documents = verificationDoc.Data;
        }
      });
  }

}
