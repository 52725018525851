import { Component, ElementRef, EventEmitter, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { LoanNRecoveriesRegisterFromCService } from '../loan-n-recoveries-register-from-c.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { printFormateCss } from 'src/app/common/utils';
import { SharedService } from 'src/app/core/services';


@Component({
  selector: 'app-loan-n-recoveries-from-c',
  templateUrl: './loan-n-recoveries-from-c.component.html',
  styleUrls: ['./loan-n-recoveries-from-c.component.css']
})
export class LoanNRecoveriesFromCComponent implements OnInit {

  selectedReportInfo: any;
  opratation: any;

  @Input() reportData: any[] = [];
  @Input() headerData: any;
  @ViewChild("templateStateRef") templateStateRef: TemplateRef<any>;
  @ViewChild('btnPrint') btnPrint: ElementRef<any>;
  @ViewChild("tablePrint") printtableEle: ElementRef;
  closeModal = new EventEmitter<void>();
  @Input() set selectedReportData(value: Date) {
    if (value) {
      this.selectedReportInfo = value;
    }
  }

  get selectedReportData(): Date {
    return this.selectedReportInfo;
  }

  userId = localStorage.getItem("UserID");
  tableCss:string;
  totalRecords = 0;
  reportDate: string;

  tableRowData: any[] = [];
  rowData: any[] = [];
  reportValueData: any[] = [];

  stateList: any[] = [];
  paperSizeList: any[] = [];
  paperLayoutList: any = [];
  paperScalSizeList = [];

  showCenteralPrint: boolean = false;
  showStatePrint: boolean = false;
  PrintCongfigShow: boolean = false;

  pageCode = 349

  actionFormGroup = new FormGroup({
    State: new FormControl(''),
    PaperSize: new FormControl(''),
    PaperLayout: new FormControl(''),
    PaperScalSize: new FormControl(['', [Validators.required, Validators.max(100)]]),
  });

  gridOptions: GridOptions = {
    animateRows: true,
    rowSelection: 'multiple',
    rowHeight: 40,
    headerHeight: 40,
    pagination: false,
    paginationPageSize: 0,
  };

  columnDefs = [
    { headerName: 'S. No. in Employee Register',pinned: 'left', headerClass: 'custom-header', field: 'GatePassNo' },
    { headerName: 'NAME',pinned: 'left', headerClass: 'custom-header', field: 'Name' },
    { headerName: 'Recovery Type (Damage/Loss/Fine/Advance/Loans)', headerClass: 'custom-header', field: 'RecoveryType' },
    { headerName: 'Particulars', headerClass: 'custom-header', field: 'Particualars' },
    { headerName: 'Date of Damage/ loss', headerClass: 'custom-header', field: 'DateOfDamage' },
    { headerName: 'Amount', headerClass: 'custom-header', field: 'Amount' },
    { headerName: 'Whether show cause issued', headerClass: 'custom-header', field: 'ShowCauseDetails' },
    { headerName: 'Explaination heard in presence of', headerClass: 'custom-header', field: 'PresencePersons' },
    { headerName: 'Number of Installment', headerClass: 'custom-header', field: 'NOI' },
    { headerName: 'First Month/ Year', headerClass: 'custom-header', field: 'FMY' },
    { headerName: 'Last Month/ Year', headerClass: 'custom-header', field: 'LMY' },
    { headerName: 'Date of Complete Recovery', headerClass: 'custom-header', field: 'RecoveryDate' },
    { headerName: 'Remarks', headerClass: 'custom-header', field: 'Remarks' },
  ];

  constructor(
    private modalService: NgbModal,
    public apiCallService: LoanNRecoveriesRegisterFromCService,
    private sharedService: SharedService,
    private renderer: Renderer2
  ) {
    this.tableCss = printFormateCss();
   }

  ngOnInit() {
    this.initializeData();
    this.getPrintOptionsFunc(this.pageCode);
  }

  initializeData() {
    if (this.reportData.length) {
      const startDate = moment(this.selectedReportInfo.DateRange[0]).format('D-MMM-YYYY');
      const endDate = moment(this.selectedReportInfo.DateRange[1]).format('D-MMM-YYYY');
      this.reportDate = `${startDate} TO ${endDate}`;
      this.rowData = this.reportValueData;
      this.tableRowData = this.getGroupedRowData(this.reportData);
      this.rowData = this.tableRowData[0].items.map((item) => ({
        GatePassNo: item.GatePassNo ? item.GatePassNo : '',
        Name: item.Name ? item.Name : '',
        RecoveryType: item.RecoveryType ? item.RecoveryType : '',
        Particualars: item.Particualars ? item.Particualars : '',
        DateOfDamage: item.DateOfDamage ? item.DateOfDamage : '',
        Amount: item.Amount ? item.Amount : '',
        ShowCauseDetails: item.ShowCauseDetails ? item.ShowCauseDetails : '',
        PresencePersons: item.PresencePersons ? item.PresencePersons : '',
        NOI: item.NOI ? item.NOI : '',
        FMY: item.FMY ? item.FMY : '',
        LMY: item.LMY ? item.LMY : '',
        RecoveryDate: item.RecoveryDate ? item.RecoveryDate : '',
        Remarks: item.Remarks ? item.Remarks : ''
      }));
    }
    this.totalRecords = this.reportData.length;
  }

  getGroupedRowData(data: any[]): any[] {
    const groupedData = _.groupBy(data, 'ContractorName');
    return Object.keys(groupedData).map((key) => ({
      headerName: key,
      items: groupedData[key].map((item, index) => ({
        ...item,
        index: index + 1,
      })),
    }));
  }

  onPrintCentralFormat(): void {
    this.showCenteralPrint = true;
    this.showStatePrint = false;
    const data = {
      selectedReportData: this.selectedReportData,
      reportData: this.reportData
    };
    this.tableRowData = this.getGroupRowTableData(this.reportData);
    setTimeout(() => {
      let printConfOpt;
      if (this.PrintCongfigShow) {
        printConfOpt =
        {
          'PaperSize': this.actionFormGroup.controls.PaperSize.value,
          'PaperLayout': this.actionFormGroup.controls.PaperLayout.value,
          'PaperScalSize': this.actionFormGroup.controls.PaperScalSize.value
        }
      } else {
        printConfOpt =
        {
          'PaperSize': 'A5',
          'PaperLayout': 'portrait',
          'PaperScalSize': 100
        }
      }

      const tableCss = `
        table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
          }
         th {
            background-color: #f2f2f2;
          }`;

      this.printTable('print-section', tableCss, printConfOpt);
      this.showCenteralPrint = false;
      localStorage.setItem('report-data', JSON.stringify(data));
    }, 100);
  }

  getGroupRowTableData(reportData: any[]): any[] {
    const tableData = [];
    const groupData = new Set(reportData.map(item => item.ContractorName));

    groupData.forEach((groupItem: string) => {
      let index = 1;
      const items = reportData.filter(item => item.ContractorName === groupItem);
      items.forEach((item: any) => {
        item.index = index;
        index++;
      });
      const rowItems = {
        items,
        ContractorName: groupItem
      };
      tableData.push(rowItems);
    });
    return tableData;
  }

  printTable(elementId: string, tableCss: string, printConfOpt: any): void {
    const element = document.getElementById(elementId);
  
    if (!element) {
      console.error(`Element with ID ${elementId} not found`);
      return;
    }
  
    const recordsPerPage = 50;
    const pages = this.paginateData(this.rowData, recordsPerPage);
    const tableHeaderHtml = element.querySelector('thead').outerHTML || '';
    const tableBodyHtml = this.generatePaginatedTableBody(pages, tableHeaderHtml);
    const printWindow = window.open('', '_blank', 'toolbar=no,scrollbars=yes,resizable=yes,top=0,left=0,width=800,height=600');
    if (printWindow) {
      const additionalCSS = `
        ${tableCss}
        @media print {
          @page {
            size: A5 ${printConfOpt.PaperLayout || 'portrait'};
            margin: 1cm;
          }
  
          body {
            font-size: 12px;
            color: #000;
            margin: 0;
            padding: 0;
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
          }
  
          table {
            width: 100%;
            border-collapse: collapse;
            page-break-inside: auto;
          }
  
          thead {
            display: table-header-group;
          }
  
          tfoot {
            display: table-footer-group;
          }
  
          tr {
            page-break-inside: avoid;
            page-break-after: auto;
          }
  
          th, td {
            border: 1px solid black;
            padding: 5px;
            text-align: left;
          }
  
          .no-print {
            display: none;
          }
  
          .page-break {
            margin-bottom: 1rem;
            page-break-after: always;
          }
  
          .table-bordered {
            margin-bottom: 0px;
          }
        }`;
  
      printWindow.document.open();
      printWindow.document.write(`
        <html>
        <head>
          <title>Print Preview</title>
          <style>${additionalCSS}</style>
        </head>
        <body>
          ${tableBodyHtml}
        </body>
        </html>
      `);
      printWindow.document.close();
  
      printWindow.focus();
      printWindow.print();
      printWindow.onafterprint = () => printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  }
  
  
  generatePaginatedTableBody(pages: any[][], headerHtml: string): string {
    return pages.map((page, index) => `
      <table>
        ${headerHtml}
        <tbody>
          ${page.map(row => `
            <tr>
              <td>${row.GatePassNo}</td>
              <td>${row.Name}</td>
              <td>${row.RecoveryType}</td>
              <td>${row.Particualars}</td>
              <td>${row.DateOfDamage}</td>
              <td>${row.Amount}</td>
              <td>${row.ShowCauseDetails}</td>
              <td>${row.PresencePersons}</td>
              <td>${row.NOI}</td>
              <td>${row.FMY}</td>
              <td>${row.LMY}</td>
              <td>${row.RecoveryDate}</td>
              <td>${row.Remarks}</td>
            </tr>
          `).join('')}
        </tbody>
      </table>
      ${index < pages.length - 1 ? '<div class="page-break"></div>' : ''}
    `).join('');
  }
  
  paginateData(data: any[], recordsPerPage: number): any[][] {
    const pages = [];
    for (let i = 0; i < data.length; i += recordsPerPage) {
      pages.push(data.slice(i, i + recordsPerPage));
    }
    return pages;
  }

  dateConvertor(reportData: any): string {
    if (!reportData.Date) {
      return "";
    }
  }

  getPrintOptionsFunc(pageCode) {
    const printObj = {
      Page_Code: pageCode,
      RequireType: 1,
      ActionType: 1,
      UserID: this.userId
    }

    this.apiCallService.printConfig(printObj).subscribe((response) => {
      if (response[0].PrintingCongfigShow) {

        const { PrintingCongfigShow, PrintPaperSize, PrintPaperLayout, PrintScaleSize } = response[0];

        this.PrintCongfigShow = PrintingCongfigShow;
        this.paperSizeList = PrintPaperSize ? PrintPaperSize.split(',') : null;
        this.paperLayoutList = PrintPaperLayout ? PrintPaperLayout.split(',') : null;
        this.paperScalSizeList = PrintScaleSize ? PrintScaleSize.split(',') : null;

        const patchValues = {
          PaperSize: this.paperSizeList ? this.paperSizeList[0] : null,
          PaperLayout: this.paperLayoutList ? this.paperLayoutList[0] : null,
          PaperScalSize: this.paperScalSizeList ? Math.min(parseInt(this.paperScalSizeList[0]), 100) : 100,
          State: this.stateList.length === 1 ? this.stateList[0].State_Code : null
        };
        this.actionFormGroup.patchValue(patchValues);
      }
    });
  }

  exportExcel(): void {
    this.showCenteralPrint = true;
    this.showStatePrint = false; 
    let filename = moment(new Date()).format(this.sharedService.datePickerSettings.placeHolder);
    setTimeout(() => {
      this.exportExcelWithLayout('Loan/Recoveries Report '+filename, this.printtableEle, this.tableCss);
      this.showCenteralPrint = false;
    }, 100);
  }

  exportExcelWithLayout(name, table, dyncss) {
    if (table && table.nativeElement) {
      let style = "<style>" + dyncss + "</style>";
      let uri = 'data:application/vnd.ms-excel;base64,'
        , template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->` + style + `</head><body><table>${table}</table></body></html>`
        , base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)))
          }
        , format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
          }
      var ctx = { worksheet: 'Sheet1', table: table.nativeElement.innerHTML, fileName: 'test' }
      let link = document.createElement('a');
      link.download = name + '.xls';
      link.href = uri + base64(format(template, ctx));
      link.click();
    } else {
      console.error("Table element not found");
    }
  }
  
 

  onCloseAction(): void {
    this.closeModal.emit();
  }

  onStateDD(data: any): void {
    this.opratation = data;
    this.modalService.open(this.templateStateRef, { size: "sm" });
  }
}