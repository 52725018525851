import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ManPowerService } from '../../man-power.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PlantMaster, SectionList, GetRequestionList, SaveLabourMovement, AttendanceApprove, ShiftList } from '../../man-power.modal';
import { ManPowerList, BackdropColor, ModelDialogClass, ModalConfig } from '../../model/man-power.model';
import { GridOptions, GridApi } from 'ag-grid-community';
import {
  AgFilterCellRenderComponent
} from 'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { SelectStepsComponent } from 'src/app/core/components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AgFilterManPowerAssignAllocationComponent
} from '../ag-filter-man-power-assign-allocation/ag-filter-man-power-assign-allocation.component';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { GLOBAL } from '../../../../app/app.globals';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ReportExportService } from '../../../reports/daily-attendance/report-export.service';
import { DatePipe, formatDate } from '@angular/common';
const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

declare var $;

@Component({
  selector: 'app-daily-man-power-content',
  templateUrl: './daily-man-power-content.component.html',
  styleUrls: ['./daily-man-power-content.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class DailyManPowerContentComponent implements OnInit {
  selectedDepartmentIds: number[];
  listFilterFields = ['GatePassNo', 'FullName', 'Shift', 'Category'];
  listFilterValue = '';
  filterShortCode = null;
  filterUnitCode = null;
  filterStatusCode = null;
  filterShiftTypeGroupCode = null;
  filterShortCodeChanged: Subject<string> = new Subject<string>();
  filterCategory = null;
  hasTrainingData = false;
  trainingData = [];
  userData = [];
  public statusList: any[] = [
    { status_code: 1, name: 'Approved' },
    { status_code: 2, name: 'Pending' },
    { status_code: 3, name: 'Allocated' },
  ];
  total: any;
  cValue = new Date();
  myDate = formatDate(this.cValue, 'dd-MM-yyyy', 'en-US');
  public date: string;
    @ViewChild('dialogerror') private dialogerror: SwalComponent;
  @ViewChild('selectStep') public stepsComp: SelectStepsComponent;
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  @ViewChild('tableHeader') public tableHeader: ElementRef<any>;
  public alertOption: SweetAlertOptions = {};
  public isRequistionList: boolean;
  public filterDataObj: {
    dateTime: string,
    contractorCode: string,
    workOrderCode: string
  };
  public sectionTreeList: any;
  public p: number;
  public companyCode = null;
  public contractorCode = null;
  public unitDepartmentSectionTreeData: any[] = [];
  public itemsPlant = [];
  public itemsNatureOfWork = [];
  private plantCode: number;
  private parentSkillCode: number;
  private tradeCode: number;
  private shiftCode: number;
  private shiftTypeGroupCode: number;
  private shiftGroupCode: number;
  private unitCode: number;
  private departmentCode: number;
  private sectinCode: number;
  private stepsData: any[];
  private sectionData: any[];
  public drpShift = false;
  private itemPlantFieldValue: string;
  private itemSectionFieldValue: string;
  private itemShieftFieldValue: string;
  public itemsUnit = [];
  public itemsNature = [];
  public itemsSkill = [];
  public itemssection = [];
  public itemShift = [];
  public gridAPI: GridApi;
  public itemsDerpartment = [];
  public itemShiftData = [];
  public requistionColumnDefs: any;
  public numberOfRowsPerPage = 10;
  public manPowerList: any[] = [];
  public manPowerListCopy: any[] = [];
  public shiftRequistionData: any[] = [];
  public requistionRowData: any[];
  private workOrderCode: string;
  public formGroup: FormGroup;
  private storeRequistionItem: any;
  private storeRequestNo: string;
  private requisitionSelectedData: any;
  public selectedDate: Date;
  public roleCode: string;
  public commonRequisitionNbr;
  public commonRequisitionSelectedData;
  private isCommonRequistionSelection = false;
  public categoryList = [];
  public shortCodeList = [];
  public shortCodeSuggestion = [];
  public shiftGroupTypeList = [];
  public unitFilterList = [];
  public labourTrainingDetailData = [];
  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };
  public requistionGridOptions: GridOptions = <GridOptions>{
    suppressHorizontalScroll: false,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    onGridReady: (params) => {  
      this.gridAPI = params.api;
    },
    onGridSizeChanged: (params) => {
      // params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };
  constructor(
    private manPowerService: ManPowerService,
    private reportExportService: ReportExportService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe
  ) {
    this.p = 1;
    this.plantCode = null;
    this.stepsData = [];
    this.unitCode = null;
    this.itemSectionFieldValue = '';
    this.itemShieftFieldValue = '';
    this.requistionRowData = [];
    this.workOrderCode = null;
    this.itemPlantFieldValue = '';
    this.isRequistionList = false;
    this.selectedDate = new Date(this.dateConvertor(this.date));
    this.roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code.toString();
    console.log('selectedDate', this.selectedDate);
    // this.formGroup = this.formBuilder.group({
    //   selectedShift: ['', Validators.required],
    //   inPunch: ['', Validators.required],
    //   outPunch: ['', Validators.required]
    // });

    this.filterShortCodeChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterShortCode = value;
        this.onFilterShortCode();
      });

    this.requistionColumnDefs = [
      {
        headerName: '#', field: '',
        width: 50, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }, checkboxSelection: true, suppressMenu: true
        // cellRendererFramework: AgFilterManPowerAssignAllocationComponent
      },
      {
        headerName: 'Req.No', field: 'RequestNo', width: 50,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Req.StartDate', field: 'ReqStartDate', width: 100,
        cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRenderer: (data) => {
          return data.value ? this.datepipe.transform(new Date(data.value),'dd-MM-yyyy') : '';
        }
      },
      {
        headerName: 'Req.EndDate', field: 'ReqEndDate', width: 100,
        cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRenderer: (data) => {
          return data.value ? this.datepipe.transform(new Date(data.value),'dd-MM-yyyy') : '';
        }
      },
      // {
      //   headerName: 'Requested Days', field: 'Requested_Days', width: 100,
      //   filterParams: { applyButton: true, clearButton: true }, sortable: true,
      //   filter: true, cellStyle: {
      //     'align-items': 'center', display: 'flex'
      //   }
      // },
      // {
      //   headerName: 'Requested By', field: 'RequetedBy', width: 100,
      //   filterParams: { applyButton: true, clearButton: true }, sortable: true,
      //   filter: true, cellStyle: {
      //     'align-items': 'center', display: 'flex'
      //   }
      // },

      // {
      //   headerName: 'Last Approved By', field: 'LastApproveBy', width: 100,
      //   filterParams: { applyButton: true, clearButton: true }, sortable: true,
      //   filter: true, cellStyle: {
      //     'align-items': 'center', display: 'flex'
      //   }
      // },
      {
        headerName: 'ShortCode', field: 'Short_Code', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      {
        headerName: 'Plant', field: 'Plant', width: 100,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },

      {
        headerName: 'Unit', field: 'Unit', width: 100,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },

      {
        headerName: 'Department', field: 'Department', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },

      {
        headerName: 'Section 1', field: 'SectionL1', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },

      {
        headerName: 'Section 2', field: 'SectionL2', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },

      {
        headerName: 'Section 3', field: 'SectionL3', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      // {
      //   headerName: 'Total Requistion', field: 'Total_Requestion_Code', width: 100,
      //   filterParams: { applyButton: true, clearButton: true }, sortable: true,
      //   filter: true, cellStyle: {
      //     'align-items': 'center', display: 'flex'
      //   }
      // }
    ];
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => this.shortCodeSuggestion.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  public shortCodeKeyUp($event, manPowerItem, idx: number): void {
    if ([9, 38, 40].indexOf($event.keyCode) > -1) {
      if (manPowerItem.Short_Code && manPowerItem.Short_Code.trim()) {
        this.onSearchShortCode(manPowerItem);
      }

      if ($event.keyCode === 38) {
        document.getElementById(`Short_Code_${1000 + idx - 1}`).focus();
      } else if ($event.keyCode === 40) {
        document.getElementById(`Short_Code_${1000 + idx + 1}`).focus();
      }
    }
  }

  onClearShortCode(manPowerItem) {
    if (manPowerItem.shortCodeData) {
      manPowerItem.shortCodeData.Remaing = manPowerItem.shortCodeData.Remaing + 1;
      manPowerItem.shortCodeData = null;
    }

    manPowerItem.Short_Code = null;
    manPowerItem.RequisitionNbr = null;
    manPowerItem.departmentControlKey = null;
    manPowerItem.sectionControlKey1 = null;
    manPowerItem.sectionControlKey2 = null;
    manPowerItem.sectionControlKey3 = null;
  }

  autoAutoAssignPrefilledReq() {
    // let i = 0;

    this.manPowerList.forEach((manPowerItem) => {
      // if (i < 5) {
      //   manPowerItem.Short_Code = 'G102PKING';
      // }
      // i++;

      if (manPowerItem.IsApproved === 1 || manPowerItem.IsApproved === 3) {
        manPowerItem.readOnly = true;
      } else {
        manPowerItem.readOnly = false;
      }

      // For testing auto assign / pre fill short code
      // if (manPowerItem.GatePassNo === 11201952) {
      //   manPowerItem.Short_Code = 'G208PKL1';
      //   manPowerItem.ManpowerRequisiton_Code = 1555;
      // }

      if (manPowerItem.Short_Code) {
        this.onSearchShortCode(manPowerItem, false, true, true); // send true convert from false kh 12-12-2020
        if (!manPowerItem.shortCodeData && !manPowerItem.readOnly) {
          manPowerItem.RequisitionNbr = null;
          manPowerItem.Short_Code = null;
        }
      }
    });
  }

  public onSearchShortCode(manPowerItem: any, reqSelected?, reqAssign?, noError?): void {

    if (!manPowerItem.Short_Code || !manPowerItem.Short_Code.trim()) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please enter short code</span>`,
        type: 'error'
      },
        setTimeout(() => {
          if (noError) return;
          this.openAlert();
        }, 10);
      return;
    }

    if (manPowerItem.shortCodeData) {
      manPowerItem.shortCodeData.Remaing = manPowerItem.shortCodeData.Remaing + 1;
      manPowerItem.shortCodeData = null;
    }

    const found = this.shiftRequistionData.find((item) => {
      // if (item.Short_Code === parseInt(manPowerItem.Short_Code, 10)
      //   && item.CategoryWorkmenMIL_Code === manPowerItem.CategoryMIL_Code
      //   && item.Remaing > 0) {
      //   return true;
      // }
      if (item.Short_Code === manPowerItem.Short_Code
        && item.ManpowerRequisiton_Code === manPowerItem.ManpowerRequisiton_Code
        && item.Remaing === 0) {
        if (reqAssign) { return (item.ManpowerRequisiton_Code === manPowerItem.ManpowerRequisiton_Code); }
        // return true;
      } else if (item.Short_Code === manPowerItem.Short_Code
        && item.CategoryWorkmenMIL_Code === manPowerItem.CategoryMIL_Code
        && item.Shift_Code === manPowerItem.Shift_Code
        && item.Remaing > 0) {
        if (reqSelected && this.requisitionSelectedData) {
          return (item.ManpowerRequisiton_Code === this.requisitionSelectedData.ManpowerRequisiton_Code);
        }
        if (reqAssign) {
          return (item.ManpowerRequisiton_Code === manPowerItem.ManpowerRequisiton_Code);
        }
        return true;
      }

      return false;
    });

    if (!found) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Assigned Manpower doesn't match the requirement!</span>`,
        type: 'error'
      },
        setTimeout(() => {
          if (noError) return;
          this.openAlert();
        }, 10);
      return;
    }
    const reqFound = this.stepsData[0].rowData.find((item) => {
      return item.ManpowerRequisiton_Code === found.ManpowerRequisiton_Code;
    });

    if (!reqFound) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Requistion not found!</span>`,
        type: 'error'
      },
        setTimeout(() => {
          if (noError) return;
          this.openAlert();
        }, 10);
      return;
    }

    if (!manPowerItem.readOnly) {
      found.Remaing = found.Remaing - 1;
    }

    manPowerItem.shortCodeData = found;

    this.storeRequestNo = reqFound.RequestNo;
    this.requisitionSelectedData = reqFound;

    this.storeRequistionItem = manPowerItem;
    this.storeRequistionItem.RequisitionNbr = reqFound.RequestNo;
    this.storeRequistionItem.ManpowerRequisiton_Code = reqFound.ManpowerRequisiton_Code;

    this.createSetterObj(noError);
    // this.updateTrainingStatus(manPowerItem); // Todo: Rakesh
  }

  public updateTrainingStatus(manPowerItem: any): void {
    if (!this.storeRequistionItem.RequisitionNbr) {
      return;
    }

    const trainingData = this.labourTrainingDetailData.find((item) => {
      if (item.GatePassNo === manPowerItem.GatePassNo) {
        return false;
      }

      if (item.SectionPlantID === manPowerItem.sectionItems3) {
        return false;
      }
    });

    if (!trainingData) {
      manPowerItem.DeptTraningStatus = 1; // Not Done/Attend
    } else if (trainingData.Status === 'Approved') {
      manPowerItem.DeptTraningStatus = 2; // Expired/Deacitve
    } else if (trainingData.Status === 'Pending') {
      if (manPowerItem.AbsentDays) {
        manPowerItem.DeptTraningStatus = 5; // Refresher Training
      } else {
        manPowerItem.DeptTraningStatus = 1; // Active
      }
    }

    this.validateTrainingPrintButton();
  }

  public validateTrainingPrintButton() {
    const foundIndex = this.manPowerList.find((manPowerItem) => {
      if (manPowerItem.RequisitionNbr
        && manPowerItem.DeptTraningStatus > 0
        && manPowerItem.DeptTraningStatus !== 1) {
        return true;
      }
      return false;
    });

    this.hasTrainingData = (foundIndex) ? true : false;
  }

  public generateTrainingData() {
    const trainingManpower = this.manPowerList.filter((manPowerItem) => {
      if (this.storeRequistionItem.RequisitionNbr
        && manPowerItem.DeptTraningStatus > 0
        && manPowerItem.DeptTraningStatus !== 1) {
        return true;
      }
      return false;
    });

    return trainingManpower.map((manPowerItem) => {
      // Contractor name, Gatepass, Labour Name, Plant to Section 3, Traingin Status
      const plant = this.itemsPlant.find(item => item.id === manPowerItem.plantControlKey);
      const unit = manPowerItem.unitItems.find(item => item.id === manPowerItem.unitControlKey);
      const deparment = manPowerItem.departMentItems.find(item => item.id === manPowerItem.departmentControlKey);
      const section1 = manPowerItem.sectionItems1.find(item => item.id === manPowerItem.sectionControlKey1);
      const section2 = manPowerItem.sectionItems2.find(item => item.id === manPowerItem.sectionControlKey2);
      const section3 = manPowerItem.sectionItems3.find(item => item.id === manPowerItem.sectionControlKey3);

      let status = '';
      switch (manPowerItem.DeptTraningStatus) {
        case 1:
          status = 'Done';
          break;
        case 2:
          status = 'Not Done';
          break;
        case 3:
          status = 'Expired';
          break;
        case 4:
          status = 'Expire Soon';
          break;
        case 5:
          status = 'Refresher Training';
          break;
      }

      return {
        // ContractorName: '',
        Gatepass: manPowerItem.GatePassNo,
        LabourName: manPowerItem.FullName,
        Plant: plant ? plant.name : '',
        Unit: unit ? unit.name : '',
        Deparment: deparment ? deparment.name : '',
        Section1: section1 ? section1.name : '',
        Section2: section2 ? section2.name : '',
        Section3: section3 ? section3.name : '',
        Status: status
      };
    });
  }

  public trainingDataExcel() {
    const trainingData = this.generateTrainingData();
    this.reportExportService.exportAsExcelFile(trainingData, 'Training', this.myDate);
  }

  public trainingDataPrint() {
    this.trainingData = this.generateTrainingData();
    setTimeout(() => {
      this.btnPrint.nativeElement.click();
    }, 250);
  }

  public bindShortCodeSuggestion(manPowerItem: any): void {
    // this.shiftRequistionData = [
    //   {
    //     "ManpowerRequisiton_Code": 100,
    //     "Shift_Code": 101,
    //     "Requirement": 14,
    //     "CategoryWorkmenMIL_Code": 931,
    //     "Remaing": 5,
    //     "Date": "2020-06-01T00:00:00",
    //     "Allocation": 14,
    //     "Short_Code": 12345,
    //     "Section_Code": 105
    //   }
    // ];

    const foundList = this.shiftRequistionData.filter((item) => {
      if (item.CategoryWorkmenMIL_Code === manPowerItem.CategoryMIL_Code
        && item.Shift_Code === manPowerItem.Shift_Code  // Rakesh 
        && item.Remaing > 0) {
        return true;
      }

      return false;
    }).map(item => `${item.Short_Code}`);

    this.shortCodeSuggestion = foundList.filter((value, index) => {
      return foundList.indexOf(value) === index;
    });
  }

  public bindUnitFilterList() {
    const tmpList = [];
    const uniqueCodes = {};
    this.manPowerList.forEach((item) => {
      if (item.unitItems) {
        item.unitItems.forEach((unit) => {
          if (!uniqueCodes[unit.id]) {
            tmpList.push({
              code: unit.id,
              name: unit.name
            });
            uniqueCodes[unit.id] = true;
          }
        });
      }
    });

    this.unitFilterList = tmpList;
  }

  public bindShiftFilterList() {
    this.manPowerService.getShiftTypeGroups().subscribe((response: any[]) => {
      this.shiftGroupTypeList = response;
    });
    // const tmpList = [];
    // const uniqueCodes = {};
    // this.manPowerList.forEach((item) => {
    //   if (item.shiftItems) {
    //     item.shiftItems.forEach((shift) => {
    //       if (!uniqueCodes[shift.id]) {
    //         tmpList.push({
    //           code: shift.id,
    //           name: shift.name
    //         });
    //         uniqueCodes[shift.id] = true;
    //       }
    //     });
    //   }
    // });

    // this.shiftFilterList = tmpList;
  }

  public bindCategoryList() {
    const tmpList = [];
    const uniqueCodes = {};
    this.manPowerList.forEach((item) => {
      if (!uniqueCodes[item.CategoryMIL_Code]) {
        tmpList.push({
          code: item.CategoryMIL_Code,
          name: item.CategoryName
        });
        uniqueCodes[item.CategoryMIL_Code] = true;
      }
    });

    this.categoryList = tmpList;
  }

  public bindShortCodeList() {
    const tmpList = [];
    const uniqueCodes = {};
    this.shiftRequistionData.forEach((item) => {
      if (item.Short_Code && !uniqueCodes[item.Short_Code]) {
        tmpList.push(item.Short_Code);
        uniqueCodes[item.Short_Code] = true;
      }
    });

    this.shortCodeList = tmpList;
  }

  public onFilterUnit($event?) {
    this.allFilter();
    // this.onCategoryFilter(null);
    // if (this.filterUnitCode) {
    //   this.manPowerList = this.manPowerList.filter((item) => {
    //     return item.unitControlKey && item.unitControlKey === this.filterUnitCode;
    //   });
    // } else {
    //   this.manPowerList = this.manPowerList;
    // }
  }

  public onFilterShift($event?) {
    this.allFilter();
    // this.onCategoryFilter(null);
    // if (this.filterShiftTypeGroupCode) {
    //   this.manPowerList = this.manPowerList.filter((item) => {
    //     return item.ShiftTypeGroup_Code && parseInt(item.ShiftTypeGroup_Code, 10) === this.filterShiftTypeGroupCode;
    //   });
    // } else {
    //   this.manPowerList = this.manPowerList;
    // }
  }
  public onFilterStatus($event?) {
    this.allFilter();
    // this.onCategoryFilter(null);
    // if (this.filterShiftTypeGroupCode) {
    //   this.manPowerList = this.manPowerList.filter((item) => {
    //     return item.ShiftTypeGroup_Code && parseInt(item.ShiftTypeGroup_Code, 10) === this.filterShiftTypeGroupCode;
    //   });
    // } else {
    //   this.manPowerList = this.manPowerList;
    // }
  }

  public onFilterShortCode($event?) {
    this.allFilter();
    // this.onCategoryFilter(null);
    // if (this.filterShortCode) {
    //   this.manPowerList = this.manPowerList.filter((item) => {
    //     return item.Short_Code && item.Short_Code.toLowerCase().indexOf(this.filterShortCode.toLowerCase()) > -1;
    //   });
    // } else {
    //   this.manPowerList = this.manPowerList;
    // }
  }

  public onCategoryFilter($event) {
    this.allFilter();
    // if (this.filterCategory) {
    //   this.manPowerList = this.manPowerListCopy.filter(item => item.CategoryMIL_Code === this.filterCategory);
    // } else {
    //   this.manPowerList = this.manPowerListCopy;
    // }
  }

  public allFilter() {

    let tmpList = [];
    if (this.filterCategory) {
      tmpList = this.manPowerListCopy.filter(item => item.CategoryMIL_Code === this.filterCategory);
    } else {
      tmpList = this.manPowerListCopy;
    }

    if (this.filterUnitCode) {
      tmpList = tmpList.filter((item) => {
        return item.unitControlKey && item.unitControlKey === this.filterUnitCode;
      });
    }

    if (this.filterShiftTypeGroupCode) {
      tmpList = tmpList.filter((item) => {
        return item.ShiftTypeGroup_Code && parseInt(item.ShiftTypeGroup_Code, 10) === this.filterShiftTypeGroupCode;
      });
    }

    if (this.filterShortCode) {
      tmpList = tmpList.filter((item) => {
        return item.Short_Code && item.Short_Code.toLowerCase().indexOf(this.filterShortCode.toLowerCase()) > -1;
      });
    }

    if (this.filterStatusCode) {
      tmpList = tmpList.filter((item) => {
        return item.IsApproved === this.filterStatusCode;
      });
    }


    this.manPowerList = tmpList;
  }

  public onFormControl(rowDataItem: any, onlyServiceCall?: boolean): void {
    const dataItem: GetRequestionList = {
      search: '',
      companyCodes: this.companyCode,
      stateCodes: '',
      plantCodes: this.plantCode.toString(),
      unitCodes: '',
      departmentCodes: '',
      sectionCodes: '',
      contractorCodes: this.contractorCode,
      sDate: this.date,
      eDate: this.date,
      userId: GLOBAL.USER_ID,
      shiftCode: '',
      shiftTypeGroupCodes: '',
      shiftGroupCodes: '',

    };
    this.storeRequistionItem = rowDataItem;
    this.isCommonRequistionSelection = rowDataItem || onlyServiceCall ? false : true;
    if (this.stepsData.length === 0 || this.stepsData.length > 0) {
      this.getRequisitionList(dataItem, onlyServiceCall);
    } else {
      this.isRequistionList = true;
      if (onlyServiceCall) return;
      setTimeout(() => {
        this.stepsComp.openModel();
      }, 500);
    }
  }

  public saveDraftDataData(event: any, manPowerList: any): void {
    const saveLabourMovementsArray: SaveLabourMovement[] = [];
    this.manPowerList.forEach((labourMovementData: any) => {
      const saveLabourMovement: SaveLabourMovement = new SaveLabourMovement();
      saveLabourMovement.ManpowerRequisiton_Code = '';
      saveLabourMovement.GatePassRequest_Code = '';
      saveLabourMovement.NewWorkOrder_Code = '';
      saveLabourMovement.WorkOrder_Code = '';
      saveLabourMovement.ULC = labourMovementData.ULC;
      saveLabourMovement.Plant_Code = labourMovementData.plantControlKey;
      saveLabourMovement.Unit_Code = labourMovementData.unitControlKey;
      saveLabourMovement.Department_Code = labourMovementData.departmentControlKey;
      saveLabourMovement.ECPolicy_Code = '';
      saveLabourMovement.EntryDateTime = '';
      saveLabourMovement.GatePassNo = '';
      saveLabourMovement.ContractorVendor_Code = this.contractorCode;
      saveLabourMovement.section_Code = labourMovementData.sectionControlKey;
      saveLabourMovement.InPunchDateTime = labourMovementData.inPunchControlKey;
      saveLabourMovement.OutPunchDateTime = labourMovementData.outPunchControlKey;
      saveLabourMovement.License_Code = '';
      saveLabourMovement.IsSubmited = '';
      saveLabourMovementsArray.push(saveLabourMovement);
    });
    this.manPowerService.saveLabourMovementData(saveLabourMovementsArray).subscribe((response: boolean) => {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Save Draft Successfully.</span>`,
        type: 'success'
      };
      setTimeout(() => {
        this.openAlert();
      }, 10);
    });
  }

  /**
   * @descriptions Invoke this method when click on expire list.
   */
  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

  public postAttendenceAproveData(event: any, manPowerData: any): void {

    // const startCnt = this.p === 1 ? 0 : (this.p * 10 - 10);
    // const endCnt = startCnt + 10;
    // const sliceCnt = endCnt <= total ? endCnt : total;

    const postData = this.manPowerList.filter((e) => {
      return e.RequisitionNbr && e.plantControlKey && e.unitControlKey
        && e.departmentControlKey && e.sectionControlKey1 && e.sectionControlKey2
        && e.sectionControlKey3 && e.shiftControlKey && e.natureOfWorkControlKey
        && e.skillControlKey;
    });

    const foundIndex = postData.findIndex(item => item.shortCodeData && item.shortCodeData.Remaing < 0);
    if (foundIndex > -1) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Allocatted more than it's requirements!</span>`,
        type: 'error'
      };
      setTimeout(() => {
        this.openAlert();
      }, 10);
      return;
    }
    console.log('postData', postData);

    const attendenceAproveArray: AttendanceApprove[] = [];

    postData.forEach((labourMovementData: any) => {
      const attendenceAprove: AttendanceApprove = new AttendanceApprove();
      attendenceAprove.ManpowerRequisiton_Code = labourMovementData.ManpowerRequisiton_Code;
      attendenceAprove.ManpowerRequisiton_No = labourMovementData.RequisitionNbr;
      attendenceAprove.GatePassRequest_Code = '';
      attendenceAprove.NewWorkOrder_Code = labourMovementData.natureOfWorkControlKey;
      attendenceAprove.WorkOrder_Code = '';
      attendenceAprove.ULC = labourMovementData.ULC;
      attendenceAprove.Plant_Code = labourMovementData.plantControlKey;
      attendenceAprove.OldUnit_Code = labourMovementData.Unit_Code;
      attendenceAprove.Unit_Code = labourMovementData.unitControlKey;
      attendenceAprove.Department_Code = labourMovementData.departmentControlKey;
      attendenceAprove.ShiftCode = labourMovementData.shiftControlKey;
      attendenceAprove.ECPolicy_Code = '';
      const dateObj = this.date.split('T')[0];
      attendenceAprove.EntryDateTime = dateObj.toString();
      attendenceAprove.GatePassNo = labourMovementData.GatePassNo;
      attendenceAprove.ContractorVendor_Code = this.contractorCode;
      attendenceAprove.section_Code = labourMovementData.sectionControlKey3;
      attendenceAprove.InPunchDateTime = labourMovementData.InPunch;
      attendenceAprove.OutPunchDateTime = labourMovementData.OutPunch;
      attendenceAprove.License_Code = '';
      attendenceAprove.IsSubmited = 'true';
      attendenceAprove.LabourMovement_Code = labourMovementData.Labour_Code;
      attendenceAprove.Action = '';
      attendenceAprove.ApprovedOT = '';
      attendenceAprove.ApprovedWH = '';
      attendenceAprove.Comment = '';
      attendenceAprove.RoleCode = GLOBAL.USER.ROLE_CODE;
      attendenceAprove.Status = 'Submitted'; // Kh 16-05-2020
      attendenceAprove.UserId = GLOBAL.USER_ID;
      attendenceAprove.SkillCode = labourMovementData.skillControlKey;
      attendenceAprove.NatureOfWorkCode = labourMovementData.natureOfWorkControlKey;
      attendenceAprove.CategoryMIL_Code = labourMovementData.CategoryMIL_Code;
      attendenceAproveArray.push(attendenceAprove);
    });

    this.manPowerService.postAttendenceApprove(attendenceAproveArray).subscribe((response: any) => {
      this.alertOption = {
        title: 'Saved!!!',
        html: `<span class='customClass'>Data Saved Successfully.</span>`,
        type: 'success'
      };
      setTimeout(() => {
        const alertRef = this.dialogerror.show();
        alertRef.then((e) => {
          this.onCloseAction();
        });
      }, 10);
    });
  }

  public onCloseActionOfGrid(value: any): void {

  }

  /** Invoke when close button trigger */
  public onCloseAction(): void {
    this.activeModal.dismiss('Cross click');
  }

  public getSelectedRowData(itemData: any): void {
    if (this.isCommonRequistionSelection) {
      this.commonRequisitionNbr = itemData.data.RequestNo;
      this.commonRequisitionSelectedData = itemData.data;
    } else {
      this.storeRequestNo = itemData.data.RequestNo;
      this.requisitionSelectedData = itemData.data;
    }
  }

  public sortDataBySelectedReqUnit() {
    if (this.commonRequisitionNbr) {
      const temp1 = this.manPowerList.filter(item => item.unitControlKey === this.commonRequisitionSelectedData.unitControlKey);
      const temp2 = this.manPowerList.filter(item => item.unitControlKey !== this.commonRequisitionSelectedData.unitControlKey);
      this.manPowerList = temp1.concat(temp2);
    }
  }

  public onEmitData(): void {

  }

  assignRequistionNo(manPowerItem): void {
    if (!this.commonRequisitionSelectedData) {
      alert('Please select Common Requistion No.');
      return;
    }

    manPowerItem.Short_Code = this.commonRequisitionSelectedData.Short_Code;
    this.requisitionSelectedData = JSON.parse(JSON.stringify(this.commonRequisitionSelectedData));
    this.onSearchShortCode(manPowerItem, true);

    /*** Do not remove below commented code */
    // if (manPowerItem.shortCodeData) {
    //   manPowerItem.shortCodeData.Remaing = manPowerItem.shortCodeData.Remaing + 1;
    //   manPowerItem.shortCodeData = null;
    // }

    // this.storeRequistionItem = manPowerItem;
    // this.storeRequistionItem.RequisitionNbr = this.commonRequisitionNbr;

    // this.storeRequestNo = this.commonRequisitionNbr;
    // this.requisitionSelectedData = JSON.parse(JSON.stringify(this.commonRequisitionSelectedData));
    // this.createSetterObj();
  }

  public onSubmitAction(item: any, id): void {
    console.log('Selected requ: ', item);
    if (this.storeRequistionItem) {
      this.storeRequistionItem.Short_Code = this.requisitionSelectedData.Short_Code;
      this.onSearchShortCode(this.storeRequistionItem, true);
      /*** Do not remove below commented code */
      // this.storeRequistionItem.RequisitionNbr = this.storeRequestNo;
      console.log('Dialog 1 selected data:', this.storeRequistionItem);
      console.log('Dialog 2 selected data;', this.requisitionSelectedData);

      console.log('plant data:', this.itemsPlant);
      /*** Do not remove below commented code */
      // this.createSetterObj();
    } else {
      this.sortDataBySelectedReqUnit();
    }

    item.activeModal.dismiss('Cross click');
  }

  private createSetterObj(noError?) {
    if (this.storeRequistionItem.IsCommonAllow === 1) {
      this.storeRequistionItem.unitControlKey = this.requisitionSelectedData.unitControlKey;
    }
    if (this.storeRequistionItem.unitControlKey !== this.requisitionSelectedData.unitControlKey) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Unit not match for selected Requistion</span>`,
        type: 'error'
      },
        setTimeout(() => {
          if (noError) return;
          this.openAlert();
        }, 10);
      this.storeRequistionItem.RequisitionNbr = null;
      if (this.storeRequistionItem.shortCodeData) {
        this.storeRequistionItem.shortCodeData.Remaing = this.storeRequistionItem.shortCodeData.Remaing + 1;
        this.storeRequistionItem.shortCodeData = null;
      }
      return;
    }

    if (this.storeRequistionItem.plantControlKey) {
      if (this.requisitionSelectedData.plantControlKey === this.storeRequistionItem.plantControlKey) {
        const unitItems = this.unitDepartmentSectionTreeData
          .find(itm => itm.Plantcode0 === this.storeRequistionItem.plantControlKey).Values
          .map((e) => { return { id: e.Unitcode0, name: e.Unit0 }; });

        if (this.storeRequistionItem.unitControlKey) {
          if (this.storeRequistionItem.unitControlKey === this.requisitionSelectedData.unitControlKey) {
            if (!this.storeRequistionItem.departmentControlKey) {
              this.getDepartMentData(null, this.storeRequistionItem);
            }
          } else {
            return false;
          }
        } else {
          this.storeRequistionItem.unitItems = unitItems;
          this.storeRequistionItem.unitControlKey = this.requisitionSelectedData.unitControlKey;
          this.getDepartMentData(null, this.storeRequistionItem);
        }
        this.storeRequistionItem.departmentControlKey = this.requisitionSelectedData.departmentControlKey;
        this.getSectionData1(null, this.storeRequistionItem);
        this.storeRequistionItem.sectionControlKey1 = this.requisitionSelectedData.sectionControlKey1;
        this.getSectionData2(null, this.storeRequistionItem);
        this.storeRequistionItem.sectionControlKey2 = this.requisitionSelectedData.sectionControlKey2;
        this.getSectionData3(null, this.storeRequistionItem);
        this.storeRequistionItem.sectionControlKey3 = this.requisitionSelectedData.sectionControlKey3;

        // if (!this.storeRequistionItem.departmentControlKey) {
        //   this.storeRequistionItem.departmentControlKey = this.requisitionSelectedData.departmentControlKey;
        // }
        // if (!this.storeRequistionItem.sectionControlKey1) {
        //   this.getSectionData1(null, this.storeRequistionItem);
        //   this.storeRequistionItem.sectionControlKey1 =
        //     this.storeRequistionItem.sectionControlKey1 ? this.storeRequistionItem.sectionControlKey1
        //       : this.requisitionSelectedData.sectionControlKey1;
        // }
        // if (!this.storeRequistionItem.sectionControlKey2) {
        //   this.getSectionData2(null, this.storeRequistionItem);
        //   this.storeRequistionItem.sectionControlKey2 =
        //     this.storeRequistionItem.sectionControlKey2 ? this.storeRequistionItem.sectionControlKey2
        //       : this.requisitionSelectedData.sectionControlKey2;
        // }
        // if (!this.storeRequistionItem.sectionControlKey3) {
        //   this.getSectionData3(null, this.storeRequistionItem);
        //   this.storeRequistionItem.sectionControlKey3 =
        //     this.storeRequistionItem.sectionControlKey3 ? this.storeRequistionItem.sectionControlKey3
        //       : this.requisitionSelectedData.sectionControlKey3;

        //   this.storeRequistionItem.ManpowerRequisiton_Code = this.requisitionSelectedData.ManpowerRequisiton_Code;
        // }

        if (!this.storeRequistionItem.shortCodeData && this.requisitionSelectedData.Short_Code) {
          this.storeRequistionItem.Short_Code = this.requisitionSelectedData.Short_Code;
        }
      }
    }
  }

  public ngOnInit(): void {

    const userData = localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')) || null;
    if (userData) {
      this.userData = userData.FirstName;
    }
    setTimeout(() => {
      this.sectionTreeList = [
        {
          id: 1,
          name: 'Faizal',
          checked: true,
          children: [
            {
              id: 2,
              name: 'ronak',
              checked: false
            },
            {
              id: 3,
              name: 'afzal',
              checked: false
            }
          ]
        },
        {
          id: 4,
          name: 'ashok',
          checked: false
        }
      ];
    });
    // this.getPlantData();
  }

  onTableBodyScroll($event) {
    this.tableHeader.nativeElement.scrollLeft = $event.target.scrollLeft;
  }

  /**
     * Invoke this method and it is set array list data of `AllContractor` for `rowData`.
     */
  private setStepArray(): void {
    const firstSteps = {
      id: 1,
      type: 'grid',
      rowSelection: 'single',
      title: 'Manpower Requistion List',
      button: { skip: false, cancel: false, submit: true, prev: false, next: false, close: true },
      header: true,
      footer: false,
      isAboveElementRef: false,
      isBelowElementRef: false,
      columnDef: this.requistionColumnDefs,
      rowData: this.requistionRowData,
      gridOptions: this.requistionGridOptions
    };
    this.stepsData = [firstSteps];
  }

  public onPlantItemSelect(selectedDropDownValue: { id: number, name: string }, rowData: any): void {

    this.plantCode = selectedDropDownValue.id;
    this.unitCode = null;
    rowData.unitControlKey = '';
    rowData.unitItems = [];
    rowData.UnitdepartmentRelControlKey = '';
    rowData.departmentControlKey = '';
    rowData.departMentItems = [];
    rowData.sectionControlKey1 = '';
    rowData.sectionItems1 = [];
    rowData.sectionControlKey2 = '';
    rowData.sectionItems2 = [];
    rowData.sectionControlKey3 = '';
    rowData.sectionItems3 = [];
    this.getUnitData(selectedDropDownValue, rowData);
  }

  public onUnitItemSelect(selectedDropDownValue: { id: number, name: string }, rowData: any): void {
    console.log('on unit item select :', selectedDropDownValue);
    this.unitCode = selectedDropDownValue.id;
    this.departmentCode = null;
    rowData.UnitdepartmentRelControlKey = '';
    rowData.departmentControlKey = '';
    rowData.departMentItems = [];
    rowData.sectionControlKey1 = '';
    rowData.sectionItems1 = [];
    rowData.sectionControlKey2 = '';
    rowData.sectionItems2 = [];
    rowData.sectionControlKey3 = '';
    rowData.sectionItems3 = [];
    this.getDepartMentData(selectedDropDownValue, rowData);
  }

  // public onDepartmentItemSelect(selectedDropDownValue: { id: number, name: string }, sectionData: any): void {
  //   //
  //   this.getSectionData(selectedDropDownValue, sectionData);
  //   sectionData.sectionControlKey = '';
  //   sectionData.sectionItems = [];
  //   sectionData.shiftControlKey = '';
  //   sectionData.shiftItems = [];
  //   sectionData.natureOfWorkControlKey = '';
  //   sectionData.NatureofWorkItems = [];
  // }

  public onDepartmentItemSelect(selectedDropDownValue: { id: number, name: string }, rowData: any): void {

    rowData.sectionControlKey1 = '';
    rowData.sectionItems1 = [];
    rowData.sectionControlKey2 = '';
    rowData.sectionItems2 = [];
    rowData.sectionControlKey3 = '';
    rowData.sectionItems3 = [];
    this.getSectionData1(selectedDropDownValue, rowData);
  }

  public onSectionItemSelect1(selectedDropDownValue: { id: number, name: string }, rowData: any): void {
    rowData.sectionControlKey2 = '';
    rowData.sectionItems2 = [];
    rowData.sectionControlKey3 = '';
    rowData.sectionItems3 = [];
    this.getSectionData2(selectedDropDownValue, rowData);
  }

  public onSectionItemSelect2(selectedDropDownValue: { id: number, name: string }, rowData: any): void {
    rowData.sectionControlKey3 = '';
    rowData.sectionItems3 = [];
    this.getSectionData3(selectedDropDownValue, rowData);
  }

  public onShiftItemSelect(selectedDropDownValue: { id: number, name: string }, natureOfWorkData: any): void {
    // this.getNatureOfWorkData(selectedDropDownValue, natureOfWorkData);
    // natureOfWorkData.natureOfWorkControlKey = '';
    // natureOfWorkData.NatureofWorkItems = [];
  }

  public setManPowerData(eventData: any, manPowerItem: any): void {
  }

  public onNatureOfWorkItemSelect(selectedDropDownValue: { id: number, name: string }, rowData: any): void {
    // this.getSkillByNatureOfWork(selectedDropDownValue, skillData);
    // skillData.skillControlKey = '';
    // skillData.SkillItems = [];
    rowData.itemsSkill = this.itemsNatureOfWork.filter(itm => itm.id === rowData.natureOfWorkControlKey)
      .map((e) => { return { id: e.skillId, name: e.skillName }; });

    if (rowData.itemsSkill.length === 1) {
      rowData.skillControlKey = rowData.itemsSkill[0].id;
    } else {
      rowData.skillControlKey = null;
    }
  }

  public getUnitData(plantCodes: { id: number, name: string }, rowData: any): void {
    rowData.unitItems = this.unitDepartmentSectionTreeData
      .find(itm => itm.Plantcode0 === rowData.plantControlKey).Values
      .map((e) => { return { id: e.Unitcode0, name: e.Unit0 }; });

    if (rowData.unitItems.length === 1) {
      rowData.unitControlKey = rowData.unitItems[0].id;
      this.getDepartMentData(rowData.unitItems[0], rowData);
    }
  }

  public getDepartMentData(unitCodes: { id: number, name: string }, rowData: any): void {
    rowData.departMentItems = this.unitDepartmentSectionTreeData
      .find(itm => itm.Plantcode0 === rowData.plantControlKey).Values
      .find(itm => itm.Unitcode0 === rowData.unitControlKey).Values
      .map((e) => { return { id: e.UnitDeptID0, name: e.Department0 }; });

    if (rowData.departMentItems.length === 1) {
      rowData.departmentControlKey = rowData.departMentItems[0].id;
      this.getSectionData1(rowData.departMentItems[0], rowData);
    }
  }

  // public getSectionData(departmentCodes: { id: number, name: string }, unitDataFun: any): void {
  //   //
  //   // tslint:disable-next-line: max-line-length
  //   const dataItem: SectionList = { search: '', parentSectionCode: '', plantCode: this.plantCode,
  //  departmentCode: departmentCodes.id, unitCode: this.unitCode, companyCode: '' };
  //   this.manPowerService.getDepartmentPlantUnitSectionTree(
  //     dataItem.plantCode, dataItem.unitCode, dataItem.departmentCode).subscribe((value: any) => {
  //       if (value) {
  //         const array: { id: number, name: string }[] = [];
  //         value.forEach((item: any, index: number) => {
  //           array.push({ id: item.Section_Code, name: item.Name });
  //         });
  //         this.itemssection = array;
  //         // unitDataFun.sectionItems = array;
  //         this.bindSectionEdit(value, false, unitDataFun, departmentCodes);
  //       }
  //     });
  // }

  public getSectionData1(departmentCodes: { id: number, name: string }, rowData: any): void {
    try {

      rowData.sectionItems1 = this.unitDepartmentSectionTreeData
        // .find(itm => itm.Company_Code === this.companyCode).PlantTree
        .find(itm => itm.Plantcode0 === rowData.plantControlKey).Values
        .find(itm => itm.Unitcode0 === rowData.unitControlKey).Values
        .find(itm => itm.UnitDeptID0 === rowData.departmentControlKey).Values
        .map((e) => { return { id: e.LevelID0, name: e.SectionName0 }; });

      if (rowData.sectionItems1.length === 1) {
        rowData.sectionControlKey1 = rowData.sectionItems1[0].id;
        this.getSectionData2(rowData.sectionItems1[0], rowData);
      }
    } catch (e) {
      console.log('error', e);
      rowData.sectionItems1 = [];
      rowData.sectionControlKey1 = null;
    }
  }

  public getSectionData2(sectionCodes: { id: number, name: string }, rowData: any): void {
    try {
      rowData.sectionItems2 = this.unitDepartmentSectionTreeData
        // .find(itm => itm.Company_Code === this.companyCode).PlantTree
        .find(itm => itm.Plantcode0 === rowData.plantControlKey).Values
        .find(itm => itm.Unitcode0 === rowData.unitControlKey).Values
        .find(itm => itm.UnitDeptID0 === rowData.departmentControlKey).Values
        .find(itm => itm.LevelID0 === rowData.sectionControlKey1).Values
        .map((e) => { return { id: e.LevelID1, name: e.SectionName1 }; });

      if (rowData.sectionItems2.length === 1) {
        rowData.sectionControlKey2 = rowData.sectionItems2[0].id;
        this.getSectionData3(rowData.sectionItems2[0], rowData);
      }
    } catch (e) {
      console.log('error', e);
      rowData.sectionItems2 = [];
      rowData.sectionControlKey2 = null;
    }
  }

  public getSectionData3(sectionCodes: { id: number, name: string }, rowData: any): void {
    try {
      rowData.sectionItems3 = this.unitDepartmentSectionTreeData
        // .find(itm => itm.Company_Code === this.companyCode).PlantTree
        .find(itm => itm.Plantcode0 === rowData.plantControlKey).Values
        .find(itm => itm.Unitcode0 === rowData.unitControlKey).Values
        .find(itm => itm.UnitDeptID0 === rowData.departmentControlKey).Values
        .find(itm => itm.LevelID0 === rowData.sectionControlKey1).Values
        .find(itm => itm.LevelID1 === rowData.sectionControlKey2).Values
        .map((e) => { return { id: e.LevelID2, name: e.SectionName2 }; });

      if (rowData.sectionItems3.length === 1) {
        rowData.sectionControlKey3 = rowData.sectionItems3[0].id;
      }
    } catch (e) {
      console.log('error', e);
      rowData.sectionItems3 = [];
      rowData.sectionControlKey2 = null;
    }
  }

  public getShiftData(plantCodes: { id: number, name: string }, unitDataFun: any): void {
    //
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, shiftTypeGroupCode: number } = { search: '', plantCode: plantCodes.id, shiftTypeGroupCode: this.shiftTypeGroupCode };
    this.manPowerService.shiftMaster(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.forEach((item: any, index: number) => {
          array.push({ id: item.Shift_Code, name: item.Shift });
        });
        this.itemShiftData = array;
        unitDataFun.shiftItems = array;
        this.itemPlantFieldValue = plantCodes.name;
      }
    });
  }

  public getNatureOfWorkData(plantCodes: { id: number, name: string }, unitDataFun: any): void {
    //
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string } = { search: '' };
    this.manPowerService.natureOfWork(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.NatureOfWork_Code, name: item.Name });
        });
        this.itemsNature = array;
        unitDataFun.NatureofWorkItems = array;
        this.itemPlantFieldValue = plantCodes.name;
      }
    });
  }

  public getSkillData(plantCodes: { id: number, name: string }, skillDataFun: any): void {
    //
    // tslint:disable-next-line: max-line-length
    const dataItem: { search: string, plantCode: number, parentSkillCode: number, tradeCode: number } = { search: '', plantCode: this.plantCode, parentSkillCode: this.parentSkillCode, tradeCode: this.tradeCode };
    this.manPowerService.skillmaster(dataItem).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.Table.forEach((item: any, index: number) => {
          array.push({ id: item.Skill_Code, name: item.SkillName });
        });
        this.itemsSkill = array;
        skillDataFun.SkillItems = array;
        this.itemPlantFieldValue = plantCodes.name;
      }
    });
  }

  public getSkillByNatureOfWork(plantCodes: { id: number, name: string }, skillDataFun: any): void {
    // tslint:disable-next-line: max-line-length
    this.manPowerService.skillmasterByNatureofWork(plantCodes.id).subscribe((value: any) => {
      if (value) {
        const array: { id: number, name: string }[] = [];
        value.Data.forEach((item: any, index: number) => {
          array.push({ id: item.Skill_Code, name: item.Name });
        });
        this.itemsSkill = array;
        skillDataFun.SkillItems = array;
        this.itemPlantFieldValue = plantCodes.name;
      }
    });
  }

  /** Invoke this method and it is get requisition row data */
  private getRequisitionList(dataItem: GetRequestionList, onlyServiceCall?: boolean): void {

    this.manPowerService.getRequisitionListNew(dataItem).subscribe((value: any) => {
      this.total = this.manPowerList.length;
      console.log('abc', value);
      console.log('abc', this.manPowerList);
      console.log('12', this.total);
      this.requistionRowData = this.setManPowerResponse(value.Data.Table);
      this.setStepArray();
      console.log('xyz', this.requistionRowData);
      this.isRequistionList = true;
      this.autoAutoAssignPrefilledReq();
      if (onlyServiceCall) return;
      setTimeout(() => {
        this.stepsComp.openModel();
      }, 500);
    });
  }

  private setManPowerResponse(response: any[]): any[] {

    const finalGridData = [];
    response.forEach((dataObject: any) => {
      const existObj = finalGridData.find(item => item.RequestNo === dataObject.RequestNo);
      if (existObj) {
        const existDateObj = existObj.childSections.find(item => item.Date === dataObject.Date);
        if (existDateObj) {
          existDateObj.dateChildSections.push({
            ShiftName: dataObject.ShiftName,
            Requrement: dataObject.Requrement,
            Assign: dataObject.Assign,
            ContractorName: dataObject.ContractorName
          });
        } else {
          existObj.childSections.push(
            {
              Date: dataObject.Date,
              dateChildSections: [
                {
                  ShiftName: dataObject.ShiftName,
                  Requrement: dataObject.Requrement,
                  Assign: dataObject.Assign,
                  ContractorName: dataObject.ContractorName
                }
              ]
            }
          );
        }
      } else {
        const manPowerObj = {
          ManpowerRequisiton_Code: dataObject.ManpowerRequisiton_Code,
          RequestNo: dataObject.RequestNo,
          RequestDate: dataObject.RequestDate,
          ReqStartDate: dataObject.ReqStartDate,
          ReqEndDate: dataObject.ReqEndDate,
          RequestDays: dataObject.RequestDays,
          ManpowerTypeMIL_Code: dataObject.ManpowerTypeMIL_Code,
          RequetedBy: dataObject.RequetedBy,
          LastApproveBy: dataObject.LastApproveBy,
          Plant: dataObject.Plant,
          plantControlKey: dataObject.Plant_Code,
          Unit: dataObject.Unit,
          unitControlKey: dataObject.Unit_Code,
          Department: dataObject.Department,
          departmentControlKey: dataObject.Department_Code,
          SectionL1: dataObject.SectionL1,
          sectionControlKey1: dataObject.Section_CodeL1,
          SectionL2: dataObject.SectionL2,
          sectionControlKey2: dataObject.Section_CodeL2,
          SectionL3: dataObject.SectionL3,
          sectionControlKey3: dataObject.Section_CodeL3,
          Shift_Code: dataObject.Shift_Code,
          Short_Code: dataObject.Short_Code,
          childSections: [
            {
              Date: dataObject.Date,
              dateChildSections: [
                {
                  ShiftName: dataObject.ShiftName,
                  Requrement: dataObject.Requrement,
                  Assign: dataObject.Assign,
                  ContractorName: dataObject.ContractorName
                }
              ]
            }
          ]
        };
        finalGridData.push(manPowerObj);
      }
    });
    // finalGridData = finalGridData.sort((item: any)=> item.Date);
    finalGridData.forEach((data: any) => {
      data.childSections = data.childSections.sort((a, b) => {
        return <any>new Date(a.Date) - <any>new Date(b.Date);
      });

      data.childSections.forEach((childData: any) => {
        childData.dateChildSections = childData.dateChildSections.sort((a, b) => {
          return <any>new Date(a.Date) - <any>new Date(b.Date);
        });
      });
    });
    console.log('finalGridData', finalGridData);
    return finalGridData;
  }

  private bindSection1Tree(filteredData, isEdit: boolean, fieldRefrence: any) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            children: this.bindSection2Tree(subDataFilter, val.SectionPlantRelationId, isEdit, fieldRefrence)
          }
        );
      } else {
        let isCheck = false;
        const sectionCodes = fieldRefrence.sectionItems;
        if (sectionCodes) {
          if (sectionCodes.find(item => item.id === val.SectionPlantRelationId)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        }
        convertedData.push(
          {
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section1',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  private bindSectionEdit(response: any[], isEdit: boolean, fieldRefrence: any, departmentItem: { id: number, name: string }) {
    // this.sectionTreeList = this.bindCompanyTree(response, isEdit);
    const convertedData = [];
    response.forEach((val) => {
      val.PlantTree.forEach((plant) => {
        plant.UnitTree.forEach((unit) => {
          unit.DepartmentTree.forEach((department) => {
            department.SectionTree.forEach((section1) => {
              convertedData.push(section1);
            });
          });
        });
      });
    });
    console.log('convertedData', convertedData);
    const array = this.bindSection1Tree(convertedData, isEdit, fieldRefrence);
    this.sectionData = array;
    fieldRefrence.sectionItems = array;
    this.itemSectionFieldValue = departmentItem.name;
  }

  private bindSection2Tree(filteredData, section1Code, isEdit: boolean, fieldRefrence: any) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.SectionList;
      if (subDataFilter.length > 0) {
        convertedData.push(
          {
            section1Code,
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section2',
            isActive: false,
            selected: false,
            children: this.bindSectionTree(subDataFilter, section1Code, val.SectionPlantRelationId, isEdit, fieldRefrence)
          }
        );
      } else {
        let isCheck = false;
        const sectionCodes = fieldRefrence.sectionItems;
        if (sectionCodes) {
          if (sectionCodes.find(item => item.id === val.SectionPlantRelationId)) {
            isCheck = true;
            console.log('isCheck', isCheck);
          }
        }
        convertedData.push(
          {
            section1Code,
            id: val.SectionPlantRelationId,
            sectionCode: val.SectionPlantRelationId,
            name: val.Name,
            currentname: 'section2',
            isActive: false,
            selected: false,
            checked: isCheck
          }
        );
      }
    });
    return convertedData;
  }

  private bindSectionTree(filteredData, section1Code, section2Code, isEdit: boolean, fieldRefrence: any) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      let isCheck = false;
      const sectionCodes = fieldRefrence.sectionItems;
      if (sectionCodes) {
        if (sectionCodes.find(item => item.id === val.SectionPlantRelationId)) {
          isCheck = true;
          console.log('isCheck', isCheck);
        }
      }
      convertedData.push(
        {
          section1Code,
          section2Code,
          id: val.SectionPlantRelationId,
          sectionCode: val.SectionPlantRelationId,
          name: val.Name,
          currentname: 'section3',
          isActive: false,
          selected: false,
          checked: isCheck
        }
      );
    });
    return convertedData;
  }

  private dateConvertor(reportData: any): string {
    if (!reportData) {
      return '';
    }
    const date = new Date(reportData);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug',
      'Sept', 'Oct', 'Nov', 'Dec'];
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
  }
  nestGroupBy = (seq, keys) => {

    // tslint:disable-next-line: curly
    if (!keys.length)
      return seq;
    // tslint:disable-next-line: no-var-keyword
    // tslint:disable-next-line: prefer-const
    let first = keys[0];
    const rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), (value) => {
      return this.nestGroupBy(value, rest);
    });
  }
}
