import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoanNRecoveriesRegisterFromCRoutingModule } from './loan-n-recoveries-register-from-c-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AgGridModule } from 'ag-grid-angular';
import { CoreModule } from 'src/app/core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoanNRecoveriesRegisterFromCComponent } from './loan-n-recoveries-register-from-c.component';
import { LoanNRecoveriesFromCComponent } from './loan-n-recoveries-from-c/loan-n-recoveries-from-c.component';

@NgModule({
  declarations: [
    LoanNRecoveriesRegisterFromCComponent,
    LoanNRecoveriesFromCComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AgGridModule.withComponents([]),
    CoreModule.forRoot(),
    NgbModule,
    LoanNRecoveriesRegisterFromCRoutingModule
  ],
  entryComponents: [
    LoanNRecoveriesFromCComponent,
  ],
})
export class LoanNRecoveriesRegisterFromCModule { }
