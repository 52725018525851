import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, NgModel, FormBuilder, FormControl } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, GridOptions } from 'ag-grid-community';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
// tslint:disable-next-line:max-line-length
import { AgNumberCountComponent } from 'src/app/core/components/grid-wrapper/helper/ag-number-count/ag-number-count.component';
// tslint:disable-next-line:max-line-length
// import { COFFService } from './coff.service';
// tslint:disable-next-line:max-line-length
import { AgActionCellDocRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-doc-render/ag-action-cell-doc-render.component';
import { ManpowerRequestStatus } from 'src/app/man-power/man-power.modal';
import { COFFService } from '../coff.service';
import * as moment from 'moment';
import { EntryfordayService } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.service';
import { GLOBAL } from 'src/app/app.globals';
// tslint:disable-next-line:max-line-length

@Component({
  selector: 'app-report-view-list-coff',
  templateUrl: './report-view-list-coff.component.html',
  styleUrls: ['./report-view-list-coff.component.css']

})
export class ReportViewListCoffComponent implements OnInit {

  @Input() public roleExistForRule1: boolean;
  @Input() public roleExistForRule2: boolean;

  @Input() public requetData;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('actionTemplateRef') public actionTemplateRef: any;
  public weekOffDetailsForm: FormGroup;
  public steps: any[];
  selectedCount = 0;
  private defaultColDef;
  public actionFormGroup: FormGroup;
  private defaultColDef1;
  private editType;
  public modalRef: any;
  selectedRows: any[] = [];
  rowDataAll = [];
  rowClassRules;
  requiredFlag = false;
  private isRowSelectable;
  getRowNodeId;
  gridColumnApi;
  gridApi: GridApi;
  gridOptions: GridOptions;
  columnDefs;
  public rowData = [];
  data: any;
  fb: any;
  formGroup: any;
  hr;
  er;
  shead;
  approve = false;
  contractor;
  hrlogin = false;
  erlogin = false;
  reject = false;
  contractorlogin = false;
  sectionheadlogin = false;
  roleCode;
  rowSelection;
  groupingMeta = ['d'];
  actionForm = new FormGroup({
    Comments: new FormControl(''),

  });
  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }

  onGridReady(params) {

    this.gridApi = params.api;
  }


  constructor(private coffServices: COFFService,
    private router: Router,
    private entryfordayService: EntryfordayService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private logHelperService: LogHelperService
  ) {
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
      // editable: true,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },

      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 50;
        }
        return 30;
      },
      // isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return true; // !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        // params.api.sizeColumnsToFit();
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.resetRowHeights();
        params.api.sizeColumnsToFit();
      },
      onColumnResized: (params) => {
        params.api.resetRowHeights();
      },
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      rowMultiSelectWithClick: true,
      pagination: true,
      headerHeight: 40,
      isFullWidthCell: (rowNode) => {
        return rowNode.data.fullWidth;
      },
      // getRowStyle: (params) => {

      //   if (params.data.IsDeleted === true) {
      //     return { color: 'red' };
      //   }
      //   if (params.data.RefAttPunch_Code > 0) {
      //     return { color: 'blue' };
      //   }
      //   return null;
      // }
    };

    // this.getRowNodeId = function (data) {
    //   return data;
    // };
  }
  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs = [
      {
        headerName: '', field: '', width: 30,
        pinned: 'left',
        sortable: false, suppressMenu: true,
        cellRenderer: this.rowIndexCellRenderer,
        filter: false,
        // checkboxSelection: true,
        // function (params) { return params.data.Status != "Approved" },
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          console.log('checkboxSelection', params.data);
          
          if(params.data.Status === 'Approved'){
            return false;
          }
          if (this.roleExistForRule1 && params.data.Status === 'Submitted'){
            return false;
          }
          if (this.roleExistForRule2 && !params.data.Status){
            return false;
          }
          return true;
          // return params.data.group ? '' : params.node.rowIndex;
        },

      },
      {
        headerName: '#',
        width: 40,
        pinned: 'left',
        suppressMenu: true,
        cellRendererFramework: AgNumberCountComponent
      },
      // {
      //   headerName: 'RequestApprover_Code', field: 'RequestApprover_Code', width: 70, filterParams:
      //     { applyButton: true, clearButton: true, visible:false },
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'

      // },
      // {
      //   headerName: 'RequestLevel_Code', field: 'RequestLevel_Code', width: 70, filterParams:
      //     { applyButton: true, clearButton: true, visible:true },
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'

      // },
      {
        headerName: 'Code', field: 'GatepassNo', width: 70, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'

      },
      {
        headerName: 'Name', field: 'FullName', width: 200, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'left' }, headerClass: 'align-center'
      },
      {
        headerName: 'Plant', field: 'Plant', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Unit', field: 'Unit', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Department', field: 'Department', width: 100, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Generated On', field: 'GeneratedDate', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Day', field: 'Day', width: 70, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'C-OFF', field: 'TotalGeneratedValue', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'IN', field: 'UnitIn', width: 40, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'OUT', field: 'UnitOut', width: 40, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'WHR', field: 'WHR', width: 40, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Attendance', field: 'Attendance', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Status', field: 'Status', width: 70, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Approved By', field: 'LastApprovedByName', width: 120, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'Reason', field: 'Reason', width: 70, filterParams:
      //     { applyButton: true, clearButton: true }, filter: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      // {
      //   headerName: 'View', field: '', width: 30,
      //   sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellDocRendererComponent
      // },
    ];

  }

  onRowSelected(event) {
    if(event.data.Status === 'Approved'){
      return;
    }
    if (this.roleExistForRule1 && event.data.Status === 'Submitted'){
      return;
    }
    if (this.roleExistForRule2 && !event.data.Status){
      return;
    }

    this.contractor = this.roleCode.filter(list => list.Role_Code === 119);
    this.er = this.roleCode.filter(list => list.Role_Code === 120);
    this.hr = this.roleCode.filter(list => list.Role_Code === 118);
    this.shead = this.roleCode.filter(list => list.Role_Code === 107);
    if (this.contractor.length > 0) {
      if (event.data.Status === 'Submitted' || event.data.Status === 'Approved') {
        this.logHelperService.logError({
          message: 'This Records have already Submitted'
        }); this.gridApi.forEachNodeAfterFilter((node) => {
          // select the node
          node.setSelected(false);
        });
      }
    }
    if (this.er.length > 0) {

      if (event.data.RequestLevel_Code === null && event.data.Status === null) {
        this.logHelperService.logError({
          message: 'You do not have access to submit for this plant'
        }); this.gridApi.forEachNodeAfterFilter((node) => {
          // select the node
          node.setSelected(false);
        });
      }
    }
    if (this.er.length > 0 || this.hr.length > 0 || this.shead.length > 0) {
      if (event.data.Status === 'Approved') {
        this.logHelperService.logApproved({
          message: 'Do you want to rejected'
        });
        this.openActionModal();

      }
    }

    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    }
  }


  ngOnInit() {
    this.rowSelection = 'multiple';
    this.loadData();
    this.getRoleCodeByUserID();
    // this.getEmployee();
    // this.getCOFFListing();
    // this.getCoffFilterData();

  }

  loadData() {
    this.entryfordayService.getCoffListingData(this.requetData).subscribe((response: any[]) => {
      console.log(response);
      this.rowData = response;
      // this.openModal1();

      // this.entryfordayService.getPlantwiseTimeCardReportData(formValue.JSONText).subscribe((response: any[]) => {

    });
  }

  getRoleCodeByUserID() {

    this.coffServices.getRoleByUserID().subscribe((res: any) => {

      if (res.Status) {
        this.roleCode = res.Data;
        this.hr = this.roleCode.filter(list => list.Role_Code === 118);
        this.contractor = this.roleCode.filter(list => list.Role_Code === 119);
        this.er = this.roleCode.filter(list => list.Role_Code === 120);
        this.shead = this.roleCode.filter(list => list.Role_Code === 107);
        if (this.hr.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = true;
          this.erlogin = true;
          this.contractorlogin = false;
        }
        // tslint:disable-next-line: brace-style
        else if (this.contractor.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = false;
          this.erlogin = true;
          this.contractorlogin = true;
        }
        // tslint:disable-next-line: brace-style
        else if (this.er.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = true;
          this.erlogin = true;
          this.contractorlogin = false;
        }
        // tslint:disable-next-line: brace-style
        else if (this.shead.length > 0)
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = true;
          this.erlogin = false;
          this.contractorlogin = false;
        }
        // tslint:disable-next-line: brace-style
        else
        // tslint:disable-next-line: brace-style
        {
          this.hrlogin = false;
          this.contractorlogin = false;
          this.erlogin = false;
        }
        // tslint:disable-next-line: brace-style
      }
    });
  }
  // public openModalAction(): void {
  //   const modalRef = this.modalService.open(BulkWeekoffChangeComponent,
  //     { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' }
  //   );
  //   modalRef.componentInstance.save1.subscribe(() => {
  //     modalRef.close('');
  //     this.getLabourWeekOffs();
  //   });
  // }

  // getCOFFListing() {
  //   this.coffServices.getAllCOFFData().subscribe((res: any) => {
  //     console.log(res);
  //     // this.rowData = res.Data.Table;
  //     if (res.length > 0) {
  //       // this.rowData = res.Data.Table;
  //       this.rowDataAll = res;
  //       const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
  //       this.rowData = tempRowData;

  //     } else {
  //       this.rowData = [];
  //     }
  //   });
  // }

  // getCoffFilterData() {
  //   this.coffServices.getCoffFilterData().subscribe((res: any) => {
  //     console.log(res);
  //     // this.rowData = res.Data.Table;
  //     if (res.length > 0) {
  //       // this.rowData = res.Data.Table;
  //       this.rowDataAll = res;
  //       const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
  //       this.rowData = tempRowData;

  //     } else {
  //       this.rowData = [];
  //     }
  //   });
  // }

  // getCoffFilterData() {

  //   this.coffServices.getCoffFilterData().subscribe((res: any) => {
  //     console.log(res.Data.Table);
  //     // this.rowData = res.Data.Table;
  //     if (res.Data.Table.length > 0) {
  //       // this.rowData = res.Data.Table;
  //       this.rowDataAll = res.Data.Table;
  //       const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res.Data.Table);
  //       this.rowData = tempRowData;
  //     } else {
  //       this.rowData = [];
  //     }
  //   });
  // }

  getEmployee() {
    const formValue = this.formGroup.getRawValue();
    formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
    formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    // const sDate = formValue.JSONText.Date;
    // const eDate = formValue.JSONText.EDate;
    let contCode = '';
    let subcontCode = '';
    let ptCode = '';

    if (formValue.JSONText.Contractors) {
      contCode = formValue.JSONText.Contractors.join(',');
    } else {
      contCode = null;
    }

    if (formValue.JSONText.Plant_Code) {
      ptCode = formValue.JSONText.Plant_Code;
    } else {
      ptCode = null;
    }
    if (formValue.JSONText.SubContractors) {
      subcontCode = formValue.JSONText.SubContractors;
    } else {
      subcontCode = null;
    }


    this.entryfordayService.getCoffFilterData({
      UserId: GLOBAL.USER_ID,
      StartDate: formValue.JSONText.Date,
      EndDate: formValue.JSONText.EDate,
      plant_Code: ptCode,
      contractors: contCode,
      subContractors: subcontCode,
    }).subscribe((response: any[]) => {

      if (response && response.length > 0) {
        this.rowData = response;
      } else {
        // message for data not found
      }
      // this.reportValueData = response;
      // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, this.reportValueData);
      // this.rowData = tempRowData;
      // console.log('Output', tempRowData);
    });

  }

  onApprove() {
    this.selectedRows = this.gridApi.getSelectedRows();
    if (this.selectedRows.length > 0) {
      // tslint:disable-next-line: ter-indent
      this.coffServices.approveLabourweekoffchangerequest(this.selectedRows).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Labour Week Off change Request Approved successfully'
        });
        // this.getCOFFListing();
      });
    } else {
      this.logHelperService.logError({
        message: 'Please Select Alteast One Record'
      });
    }
  }


  deleterecord(item) {
    const data = {
      WOChange_Code: item.WOChange_Code,
    };
    this.coffServices.deleterecord(data).subscribe((dt: any[]) => {
      console.log('after approve:', dt);
      this.logHelperService.logSuccess({
        message: 'Data  Succesfully Deleted.'
      });

      // this.getCOFFListing();
    });
  }

  docClick(item) {

    this.modalRef = this.modalService.open(this.tempRef,
      { size: 'lg', backdrop: 'static' });
    this.data = item;
    if (this.data.Status === 'Submitted') {
      this.approve = true;
      this.reject = false;
    } else {
      this.reject = true;
      this.approve = false;
    }
  }

  public openActionModal(): void {

    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length > 0) {
      // tslint:disable-next-line: ter-indent
      this.modalRef = this.modalService.open(this.actionTemplateRef,
        { size: 'sm' }
      );
      this.actionFormGroup = this.fb.group({
        Comments: [''],
        Status: [ManpowerRequestStatus.APPROVED]
      });
      this.modalRef.componentInstance.closeModal.subscribe((value: string) => {
      });
    } else {
      this.logHelperService.logError({
        message: 'Please Select Alteast One Record'
      });
    }

  }

  onCloseAction() {
    this.activeModal.close();
  }

  public onCloseModel(): void {
    this.modalRef.close('click');
  }
  public onCancel(): void {
    this.modalRef.close('click');
  }

  public onapprove(data): void {
    const iteams = {
      ulc: data.ULC,
      gatepassno: data.GatepassNo,
      generatedDate: data.GeneratedDate,
      totalGeneratedValue: data.TotalGeneratedValue,
      coffcode: data.COFF_Code,
      CreateBy: GLOBAL.USER_ID
    };
    this.coffServices.approveddata(iteams).subscribe((dt: any[]) => {
      console.log('after approve:', dt);
      this.logHelperService.logSuccess({
        message: 'Data  Succesfully Approved.'
      });
      this.onCloseModel();
      // this.getCOFFListing();
    });
  }

  // public onSubmit1(): void {
  //   const formValue = this.formGroup.getRawValue();
  //   if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
  //     if (formValue.JSONText.Plant_Code > 0) {
  //       if (formValue.JSONText.Contractors > 0) {
  //         formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
  //         formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
  //         console.log('StartDate', formValue.JSONText.Date);
  //         console.log('EndDate', formValue.JSONText.EDate);
  //         console.log('xyz', this.formGroup.getRawValue());

  //         let contCode = '';
  //         let subcontCode = '';
  //         let ptCode = '';


  //         if (formValue.JSONText.Contractors) {
  //           contCode = formValue.JSONText.Contractors.join(',');
  //         } else {
  //           contCode = null;
  //         }
  //         if (formValue.JSONText.SubContractors) {
  //           subcontCode = formValue.JSONText.SubContractors;
  //         } else {
  //           subcontCode = null;
  //         }
  //         if (formValue.JSONText.Plant_Code) {
  //           ptCode = formValue.JSONText.Plant_Code;
  //         } else {
  //           ptCode = null;
  //         }
  //         formValue.filterText = {
  //           UserId: GLOBAL.USER_ID,
  //           StartDate: formValue.JSONText.Date,
  //           EndDate: formValue.JSONText.EDate,
  //           plant_Code: ptCode,
  //           contractors: contCode,
  //           subContractors: subcontCode,

  //         };
  //         console.log('filter', formValue.filterText);
  //         this.entryfordayService.getCoffListingData(formValue.filterText).subscribe((response: any[]) => {
  //           console.log(response);
  //           // this.openModal1();

  //           // this.entryfordayService.getPlantwiseTimeCardReportData(formValue.JSONText).subscribe((response: any[]) => {
  //           this.openReportViewModal(response);
  //         });
  //       } else {
  //         this.logHelperService.logError({
  //           message: 'Please Select Contractor'
  //         });
  //       }
  //     }
  //     // tslint:disable-next-line: brace-style
  //     else {
  //       this.logHelperService.logError({
  //         message: 'Please Select plant'
  //       });
  //     }
  //   }
  //   // tslint:disable-next-line: brace-style
  //   else {
  //     this.logHelperService.logError({
  //       message: 'Please Select Date'
  //     });
  //   }

  // }
  // openReportViewModal(response: any[]): void {
  //   const formValue = this.formGroup.getRawValue();
  //   // tslint:disable-next-line: indent
  //   if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
  //     formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
  //     formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
  //   }
  //   const modalRef = this.modalService.open(ReportViewListCoffComponent,
  //     { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
  //   // response.forEach((item: any) => {
  //   //   if (item.SubContractor) {
  //   //     item.ContractorNameWithSubContractor = `${item.ContractorName}[${item.SubContractor}]`;
  //   //   } else {
  //   //     item.ContractorNameWithSubContractor = item.ContractorName;
  //   //   }
  //   // });
  //   modalRef.componentInstance.selectedReportData = formValue.JSONText;
  //   modalRef.componentInstance.rowData = response;
  //   // modalRef.componentInstance.closeModal.subscribe((value: string) => {
  //   //   modalRef.close(value);
  //   // });
  // }

  onApproveClick() {

    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    const newdata = this.selectedRows.map(v => ({
      ...v, UserID: GLOBAL.USER_ID

    }));
    if (newdata.length > 0 && newdata[0].Status === 'Submitted') {

      // tslint:disable-next-line: ter-indent
      this.coffServices.clickapproveddataprocess(newdata).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Coff Approved successfully'
        });
        this.loadData();
        this.onCloseModel();
        // this.getCOFFListing();
      });
    } else {
      this.logHelperService.logError({
        message: 'You can not approved without submitted records'
      }); this.gridApi.forEachNodeAfterFilter((node) => {
        // select the node
        node.setSelected(false);
      });

    }
  }

  onSubmit() {

    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    const newdata = this.selectedRows.map(v => ({
      ...v, UserID: GLOBAL.USER_ID

    }));
    if (newdata.length > 0 && newdata[0].Status === null) {
      if (newdata.length > 50 && newdata[0].Status === null) {
        {
          this.logHelperService.logError({
            message: 'You can only fifty records Submitted at a time'
          }); this.gridApi.forEachNodeAfterFilter((node) => {
            // select the node
            node.setSelected(false);
          });
        }

      } else {
        // tslint:disable-next-line: ter-indent
        this.coffServices.clickapproveddata(newdata).subscribe((dt: any[]) => {
          console.log('after approve:', dt);
          this.logHelperService.logSuccess({
            message: 'Coff Submitted successfully'
          });
          this.loadData();
          this.onCloseModel();
          // this.getCOFFListing();
        });

      }

    } else {
      this.logHelperService.logError({
        message: 'This Records have already Submitted'
      }); this.gridApi.forEachNodeAfterFilter((node) => {
        // select the node
        node.setSelected(false);
      });
    }

  }

  onRejectedClick() {

    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    const newdata = this.selectedRows.map(v => ({
      ...v, UserID: GLOBAL.USER_ID

    }));
    if (newdata.length > 0 && newdata[0].Status === 'Approved') {
      // tslint:disable-next-line: ter-indent
      this.coffServices.clickrejecteddata(newdata).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.logHelperService.logSuccess({
          message: 'Coff Rejected successfully'
        });
        this.loadData();
        this.onCloseModel();
        // this.getCOFFListing();
      });
    } else {
      this.logHelperService.logError({
        message: 'You can not rejected without approved records'
      }); this.gridApi.forEachNodeAfterFilter((node) => {
        // select the node
        node.setSelected(false);
      });

    }

  }

}
