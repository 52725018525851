import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
declare var $: any;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable()
export class ContractorsService {

  public myData: string;

  constructor(
    private httpClient: HttpClient
  ) { }

  // getAllContractors(contractorType: number) {
  //   const model = {
      // UserId: GLOBAL.USER.LOGGED_IN_USER,
      // drpfilterwork: contractorType,
      // search: '',
      // sDate: '',
      // eDate: '',
      // selectedField: '',
      // isMain: contractorType,
      // contractor_Code: '',
      // ExcludeContractor_Code: '',
      // workOrderCode: '',
      // UserID: GLOBAL.USER.LOGGED_IN_USER,
      // UserID: GLOBAL.USER_ID,
      // RoleCode: GLOBAL.USER.ROLE_CODE,
      // RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_Grid}`, model);
  // }

  getAllContractors(contractorType: number) {
    // const queryString = $.param({
    //   // UserId: GLOBAL.USER.LOGGED_IN_USER,
    //   // drpfilterwork: contractorType,
    //   search: null,
    //   sDate: null,
    //   eDate: null,
    //   selectedField: null,
    //   isMain: contractorType,
    //   contractor_Code: null,
    //   ExcludeContractor_Code: null,
    //   workOrderCode: null,
    //   // UserID: GLOBAL.USER.LOGGED_IN_USER,
    //   userid: GLOBAL.USER_ID,
    //   // RoleCode: GLOBAL.USER.ROLE_CODE,
    //   RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    // });
    ////  return this.httpClient.post(`${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_Grid}`, model);

    let payload = {
      search: null,
      sDate: null,
      eDate: null,
      selectedField: "Company,ContractorVendor_Code,EmailID,ShortCode,PANNo,IsContactor,Status",
      isMain: contractorType,
      contractor_Code: null,
      ExcludeContractor_Code: null,
      workOrderCode: null,
      userid: GLOBAL.USER_ID,
      // RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      Plant_Code: null
    }

    // return this.httpClient.request(
    //   GLOBAL.HTTP_POST,
    //   `${GLOBAL.APIS.WORK_ORDER.SP_Listing_Contractor_Filter_V1}?${queryString}`,
    //   //  GLOBAL.HTTP_GET,
    //   // `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_Grid}?${queryString}`,
    //   httpOptions
    // );

    httpOptions['body'] = payload

    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.WORK_ORDER.SP_Listing_Contractor_Filter_V1}`,
      //  GLOBAL.HTTP_GET,
      // `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_Grid}?${queryString}`,
      httpOptions
    );
  }
  //  return this.httpClient.request(
  //  GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_Grid}?${queryString}`,
  // httpOptions
  // );
  // }
  // getLeaveTypeData() {
  //   const model = {
  //     Search: '',
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveType}`, model);
  // }

  //    return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveType}?Search=&CountryCode=`,
  // httpOptions
  // );
  // }

  // getLeaveTypes(contractorcode1) {
  //   const model = {
  //     // contractorcode: contractorcode
  //     contractorcode: contractorcode1
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveTypes}`, model);
  // }
  //  return this.httpClient.request(
  //  GLOBAL.HTTP_GET,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveTypes}?${model}`,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LeaveTypes}?`,
  // httpOptions
  // );
  // }


  // addLeave(leavetypes): Observable<Object> {
  //   const body = JSON.stringify(leavetypes);
  //   httpOptions['body'] = body;
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_POST,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LEAVETYPE_DATA}`,
  //     httpOptions
  //   );
  // }

  // public addLeave(leavetypes: any[]): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LEAVETYPE_DATA}`;
  //   return this.httpClient.post(url, leavetypes).pipe(
  //     map((response: any) => response.Data));
  // }

  // getContractor() {
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_CONTRACTOR}`,
  //     httpOptions
  //   );
  // }

  /**
   * @description add new contractor
   * @author Amit Mahida
   * @param {*} contractorParams
   * @returns {Observable<Object>}
   * @memberof ContractorsService
   */
  addContractor(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.CREATE_CONTRACTOR}`,
      httpOptions
    );
  }

  // Get Contractor code
  // getContractorCode() {
  //   return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_CODE);
  // }

  // get Complience code
  // getComplienceInfoCode() {
  //   return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_COMPLIENCE_INFO_CODE);
  // }

  // Get Contractor Role code
  getContractorRolecode() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTOR_ROLE_CODE);
  }

  // Get Contractor code
  getDocumentTypes() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES);
  }
  public getDocumentTypesPageModuleWise(data) {
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE}`, data);
  }

  //// Update Contractor Person
  // updateContractorContactState  (ContactState) {
  //    var response = this.httpClient({
  //        method: "post",
  //        url: globalapipath + "ContractorManagement/UpdateContractorContactState",
  //        data: JSON.stringify(ContactState),
  //        dataType: "json"
  //    });
  //    return response;
  // }


  //// get Contractor By Id
  // GetContractorContactStateDataByID  (concontactID) {
  //    var response = this.httpClient({
  //        method: "get",
  //        url: globalapipath + "ContractorManagement/GetContractorStateDataForEdit",
  //        params: {
  //            contractorContactCode: JSON.stringify(concontactID)
  //        }
  //    });
  //    return response;
  // }


  // get All Contractor Data
  getContractorData(userId, drpfilterwork) {
    // const response = this.httpClient({
    //   method: 'get',
    //   url: globalapipath + 'ContractorManagement/GetContractorData',
    //   params: {
    //     UserId,
    //     drpfilterwork,
    //   }
    // });
    // return response;
  }

  // get MasterList Dropdown
  getMasterList() {
    const model = {
      Search: '',
      Plant_Code: '',
      ShiftTypeGroup: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA}`, model);
  }

  //    return this.httpClient.get(GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA);
  // }

  // date : 10-10-2017
  // get Country Dropdown
  getCountries() {
    const model = {
      Search: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}`, model);
  }

  // return this.httpClient.get(`${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}?Search=`);
  // }

  getStatesByCountryId(country) {
    const model = {
      Search: '',
      Country_Code: country !== null ? country : ''
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`, model);
  }

  getDistrictsByState(state) {
    const model = {
      Search: '',
      State_Code: state !== null ? JSON.stringify(state) : ''
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}`, model);
  }

  getCitiesByDistrict(district) {
    const model = {
      Search: null,
      District_Code: district !== null ? JSON.stringify(district) : ''
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}`, model);
  }

  getPOsByCity(city) {
    const model = {
      Search: null,
      City_Code: city !== null ? JSON.stringify(city) : '' 
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`, model);
  }

  // tslint:disable-next-line: function-name
  checkCompany(company, contractorCode) {

    const queryString = $.param({
      ContractorVendorCode: contractorCode,
      Company: company
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.Check_Company_Avaibilty}?${queryString}`,
      httpOptions
    );
  }
  checkShortCode(shortcode, contractorCode) {

    const queryString = $.param({
      shortcode,
      ContractorVendorCode: contractorCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.Check_ShortCode_Avaibilty}?${queryString}`,
      httpOptions
    );
  }
  checkEmail(email, contractorCode) {

    const queryString = $.param({
      email,
      ContractorVendorCode: contractorCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.Check_Email_Avaibilty}?${queryString}`,
      httpOptions
    );
  }
  checkPanCard(pancard, contractorCode) {

    const queryString = $.param({
      pancard,
      ContractorVendorCode: contractorCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.Check_PanCard_Avaibilty}?${queryString}`,
      httpOptions
    );
  }
  checkMobileNO(mobileNo, contractorCode) {

    const queryString = $.param({
      mobileNo,
      ContractorVendorCode: contractorCode,
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.Check_MobileNo_Avaibilty}?${queryString}`,
      httpOptions
    );
  }
  addNewContractor(contractorParams): Observable<Object> {

    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.SAVE_CONTRACTOR}`,
      httpOptions
    );
  }
  addContractorContactPerson(contactperson) {
    // const response = this.httpClient({
    //   method: 'post',
    //   url: globalapipath + 'ContractorManagement/InsertContactPersonData',
    //   data: JSON.stringify(contactperson),
    //   dataType: 'json'
    // });
    // return response;
  }

  // get Contractor By Id
  // getContractorContactDataByID(contractorContactCode) {
  //   const queryString = $.param({
  //     contractorContactCode
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTACT_BY_ID}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // get Contractor By Id
  getContractorIdentityDataByID(concontactID) {
    // const response = this.httpClient({
    //   method: 'get',
    //   url: globalapipath + 'ContractorManagement/GetContractorIdentityDataByID',
    //   params: {
    //     contractorContactCode: JSON.stringify(concontactID)
    //   }
    // });
    // return response;
  }

  // get Contractor By Id
  public getContractorByID(contractorCode, userRoleCode) {

    const queryString = $.param({
      contractorCode,
      userRoleCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_BY_ID}?${queryString}`,
      httpOptions
    );
  }

  // Update Contractor
  updateContractor(contractorParams) {
    const body = JSON.stringify(contractorParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPDATE}`,
      httpOptions
    );
  }

  // updateEmailID(data) {
  //   const body = JSON.stringify(data);
  //   httpOptions['body'] = body;
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_POST,
  //     `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.THIRD_PARTY_EMAILID_CHANGE}`,
  //     httpOptions
  //   );
  // }

  // Update Contractor
  submitForUser(contractor) {
    // const response = this.httpClient({
    //   method: 'post',
    //   url: globalapipath + 'ContractorManagement/SubmitForUser',
    //   data: JSON.stringify(contractor),
    //   dataType: 'json'
    // });
    // return response;
  }

  // get Contractor By Id
  actionSave(contractorID, userRoleCode, action) {
    // const response = this.httpClient({
    //   method: 'get',
    //   url: globalapipath + 'ContractorManagement/ActionSave',
    //   params: {
    //     contractorCode: contractorID,
    //     userRoleCodes: UserRoleCode.toString(),
    //     action
    //   }
    // });
    // return response;
  }

  // Update Contractor Person
  updateContractorContactPerson(contactPerosn) {
    // const response = this.httpClient({
    //   method: 'post',
    //   url: globalapipath + 'ContractorManagement/UpdateContractorContactPerson',
    //   data: JSON.stringify(ContactPerosn),
    //   dataType: 'json'
    // });
    // return response;
  }

  // Delete Country
  deleteContractor(contractor) {
    // const response = this.httpClient({
    //   method: 'post',
    //   url: globalapipath + 'ContractorManagement/DeleteContractor',
    //   params: {
    //     contractorCode: JSON.stringify(contractor)
    //   }
    // });
    // return response;
  }

  ///// Documnet in global setting
  // getECPolicies() {
  //   return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_EC_POLICY);
  // }

  // insert  doc.
  addContractorDoc(doc) {
    // const request = {
    //   method: 'POST',
    //   url: globalapipath + 'ContractorManagement/AddContractorDoc',
    //   data: doc,
    //   headers: {
    //     'Content-Type': undefined
    //   }
    // };
    // return this.httpClient(request);
  }

  complianceDetailValidation(data): Observable<any> {
    return this.httpClient.post(`${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.DUPLICATECHECK_CONTRACTOR_LEGISLATIONREGISTERNO}`, data);
  }

  /**
   * Get multi table list
   * @author Sagar Dhrangdhariya
   * @param {*} Contractor_Code
   * @return {*}  {Observable<any[]>}
   * @memberof ContractorsService
   */
  public getMultiTableList(Contractor_Code: any, contractorType: number): Observable<any[]> {
    const requestObj = {
      "Filter": {
        "Contractor_Code": Contractor_Code,
        "WorkOrder_Code": null,
        "subContractor_Code": null,
        "subWorkOder_Code": null,
        "RequireType": contractorType,
        "ActionType": 1,
        "UserId": GLOBAL.USER_ID
      }
    };

    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.CONTRACTOR_VIEW_DATAILS}`;
    return this.httpClient.post<any[]>(url, requestObj);
  }
}
