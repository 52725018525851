import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
declare var $: any;
import { Observable } from 'rxjs';
import { GLOBAL } from '../app.globals';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class GrievanceService {

  constructor(
    private httpClient: HttpClient
  ) { }


  // get grievance data
  getGrivanceData(filter, userEmail, id, userRoleCode) {
    const queryString = $.param({
      Filter: filter, UserEmail: userEmail, USerId: id, UserRoleCode: userRoleCode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.GRIVANCE_MANAGMENT.GATE_GRIVANCE_DATA_BY_FILTER}?${queryString}`,
      httpOptions
    );
  }

  getAllContractors(contractorType: number) {
    const queryString = $.param({
      UserId: GLOBAL.USER_ID,
      drpfilterwork: contractorType
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS}?${queryString}`,
      httpOptions
    );
  }

  getWorkOrderByContractor(ID) {
    const queryString = $.param({
      ID: JSON.stringify(ID)
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WO_CONTRACTOR_DATA}?${queryString}`,
      httpOptions
    );
  }


  /**GetMasterItemData */
  getMasterItemData() {
    const model = {
      Search: '',
      Plant_Code: '',
      ShiftTypeGroup: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA}`, model);
  }


  //    return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA}`,
  // httpOptions
  // );
  // }

  /**GetMasterItemData */
  getGrievanceData() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.GRIVANCE_MANAGMENT.GATE_MASTER_ITEM_GRIVANCE}`,
      httpOptions
    );
  }

  /**
  * GET PASS REQUEST
  * @author Sonal Prajapati
  */
  getPlants() {


    const model = {
      Search: '',
      Company: '',
      State: '',
      City: '',
      Country: '',
      UnitType: '',
      OwnerShip: '',
      LocatedAt: '',
      IsESICApplicable: '',
    };
    return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_PLANT_LIST}`, model);
  }

  // return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PLANTS);
  // }

  getLabourByWorkorder(ID) {
    const queryString = $.param({
      workorder: JSON.stringify(ID)
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_BY_WORK_ORDER}?${queryString}`,
      httpOptions
    );
  }
  getEICUserByWorkorer(ID) {
    const queryString = $.param({
      workorder: JSON.stringify(ID)
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GATE_EC_WORK_ORDER}?${queryString}`,
      httpOptions
    );
  }


  /**GetMasterItemData */
  getGrievanceCode() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.GRIVANCE_MANAGMENT.GATE_GRIVANCE_CODE}`,
      httpOptions
    );
  }

  // Get Contractor doc
  getDocumentTypes() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES);
  }
  postGrivance(grivance): Observable<Object> {
    const body = JSON.stringify(grivance);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.USER_MANAGEMENT.POST_GRIEVANCE}`,
      httpOptions
    );
  }

  // get Grivance By Id used to edit data
  getGrivanceByID(grivancecode) {
    const queryString = $.param({
      grivancecode
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.EDIT_GRIVANCE}?${queryString}`,
      httpOptions
    );
  }
}
