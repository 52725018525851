import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL, PLANT_UNIT_DEP_REQUEST } from "../../app.globals";
declare var $: any;
import { Observable, observable, BehaviorSubject } from "rxjs";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { map } from "rxjs/operators";
import { filter } from "lodash";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};
@Injectable({
  providedIn: "root",
})
export class LaboursService {
  // public formData: FormGroup;

  // private formData: FormGroup = new FormGroup();
  // public formData = this.formData.asObservable();

  private formData: BehaviorSubject<any> = new BehaviorSubject([]);
  public formData$ = this.formData.asObservable();
  private formdetail: BehaviorSubject<any> = new BehaviorSubject([]);
  public formdetail$ = this.formdetail.asObservable();
  private dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();
  formData1 = null;
  formdetail1 = null;

  constructor(private httpClient: HttpClient) {}

  setFormData(formData1) {
    this.formData.next(formData1);
  }

  getFormData() {
    return this.formData.asObservable();
  }

  setData(formdetail1) {
    this.formdetail.next(formdetail1);
  }

  getData() {
    return this.formdetail.asObservable();
  }

  shareData(data: any) {
    this.dataSource.next(data);
  }

  getAllLabours(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_LABOUR_DATA_NEW}`,
      httpOptions
    );
    // const model = {
    //   Contractor: '',
    // };
    // return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_LABOUR}`, model);
  }

  updateProfilePhotoAndFingers(payload: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_IMPORT_LABOUR_IMPORT}`, payload, httpOptions);
  }
  updateProfilePhoto(payload: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.LABOUR_PHOTO_UPLOAD}`, payload, httpOptions);
  }
  

  // const body = JSON.stringify(labourParams);
  // httpOptions['body'] = body;
  // return this.httpClient.request(
  //   GLOBAL.HTTP_POST,
  // `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_LABOUR}`,
  // httpOptions
  // );
  // }

  getLabourDetails(payload) {
    return this.httpClient.post(
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SINGLE_LABOUR_DATA}`
      ,payload,{ headers: { 'Content-Type': 'application/json' } });
  }

  getVillageByPostOffice(village) {
    const queryString = $.param({
      Search: "",
      PostOfficeCode: JSON.stringify(village),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_VILLAGE_BY_POSTOFFICE}?${queryString}`,
      httpOptions
    );
  }
  getPOsByCity(city) {
    // const queryString = $.param({
    //   Search: '',
    //   CityCode: JSON.stringify(city)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}?${queryString}`,
    //   httpOptions
    // );
    const model = {
      Search: "",
      City_Code: JSON.stringify(city),
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_PO_BY_CITY}`,
      model
    );
  }

  getStatesByCountryId(country) {
    // const queryString = $.param({
    //   Search: '',
    //   CountryCode: JSON.stringify(country)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}?${queryString}`,
    //   httpOptions
    // );

    let cc: number;
    if (country === "") {
      cc = 100;
    } else {
      cc = country;
    }

    const model = {
      Search: null,
      Country_Code: cc,
    };

    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_STATES_OF_COUNTRY}`,
      model
    );
  }
  getCitiesByDistrict(district) {
    // const queryString = $.param({
    //   Search: '',
    //   DistrictCode: JSON.stringify(district)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}?${queryString}`,
    //   httpOptions
    // );
    const model = {
      Search: null,
      District_Code: JSON.stringify(district),
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_CITY_OF_DISTRICT}`,
      model
    );
  }

  getDistrictsByState(state) {
    // const queryString = $.param({
    //   Search: '',
    //   StateCode: JSON.stringify(state)
    // });
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}?${queryString}`,
    //   httpOptions
    // );
    const model = {
      Search: null,
      State_Code: JSON.stringify(state),
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_DISTRICTS_OF_STATE}`,
      model
    );
  }

  /**
   * @description used to get all the labour data from the server.
   * @author Sonal Prajapati
   * @param labourParams a number type of the contractor.
   */

  // getAllWorkLabours(labourParams): Observable<Object> {
  //   const body = JSON.stringify(labourParams);
  //   httpOptions['body'] = body;
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_POST,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GATEPASS_REQUESTS}`,
  //     httpOptions
  //   );
  // }

  /**GetMasterItemData */
  getMasterItemData() {
    const model = {
      Search: "",
      Plant_Code: "",
      ShiftTypeGroup: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA}`,
      model
    );
  }

  //    return this.httpClient.request(
  //    GLOBAL.HTTP_GET,
  //  `${GLOBAL.APIS.USER_MANAGEMENT.GET_MASTER_DATA}`,
  //  httpOptions
  // );

  /**
   * GetCompanyData
   */
  getCompanyData() {
    //   const model = {
    //     Search: '',
    //     StatusOfIndustry: '',
    //     EntityTypeCode: '',
    //     LegalstatusCode: '',
    //     StateCode: '',
    //     CityCode: '',
    //     CountryCode: '',
    //   };
    //   return this.httpClient.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA}`, model);
    // }//dynmic query not support
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_COMPANY_MASTER_DATA}
?search=&StatusOfIndustry=&EntityTypeCode=&LegalStatusCode=&StateCode=&CityCode=&CountryCode=`,
      httpOptions
    );
  }

  /**
   * GetCompanyData
   */
  getLabourCode() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_CODE}`,
      httpOptions
    );
  }

  getLabourByCode(labourCode) {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_LABOUR_BY_CODE}?labourCode=${labourCode}`,
      httpOptions
    );
  }

  // tslint:disable-next-line: variable-name
  getLabourDetailByCode(Labour_Code) {
    const data = {
      // tslint:disable-next-line: object-literal-shorthand
      Labour_Code: Labour_Code,
      // tslint:disable-next-line: semicolon
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.MAN_POWER.GET_LABOUR_DETAIL_BY_CODE}`,
      data
    );
  }

  getLabourPfDetailByCode(Labour_Code) {
    const data = {
      // tslint:disable-next-line: object-literal-shorthand
      Labour_Code: Labour_Code,
      // tslint:disable-next-line: semicolon
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.MAN_POWER.GET_LABOUR_PFDETAIL_BY_CODE}`,
      data
    );
  }

  getLabourEsicDetailByCode(Labour_Code) {
    const data = {
      // tslint:disable-next-line: object-literal-shorthand
      Labour_Code: Labour_Code,
      // tslint:disable-next-line: semicolon
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.MAN_POWER.GET_LABOUR_ESICDETAIL_BY_CODE}`,
      data
    );
  }

  getLabourNomineeListFormPrint(Labour_Code: any, nominationFor: any) {
    const data = {
      Labour_code: Labour_Code,
      NominationFor: nominationFor,
      UserId: GLOBAL.USER_ID,
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.MAN_POWER.GET_LABOUR_NOMINEE_LIST_FOR_ESIC}`,
      data
    );
  }

  /**
   * getaAddressCode
   */
  getaAddressCode() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ADDRESS_CODE}`,
      httpOptions
    );
  }

  /**
   * getaAddressCode
   */
  getWorkSiteData() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_WORKSITE_DATA}`,
      httpOptions
    );
  }

  getGatePassRequest() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GATEPASS_REQUEST_CODE}`,
      httpOptions
    );
  }

  // ------------Permanent Address--------//
  getPermanetStateByCountry(country) {
    const queryString = $.param({
      ID: JSON.stringify(country),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_STATE_BY_COUNTRY}?${queryString}`,
      httpOptions
    );
  }
  /**
   * GET PASS REQUEST
   * @author Sonal Prajapati
   */
  // getPassRequest() {
  //   return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_GATEPASS_REQUESTS);
  // }
  /**
   * GET PASS REQUEST
   * @author Sonal Prajapati
   */
  getPlants() {
    const payload = {
      "search": null,
      "Company": 101,
      "State": null,
      "City": null,
      "Country": null,
      "UnitType": null,
      "selectedField": "Plant,Plant_Code,State_Code,State,SiteMILCode,SITE,Company_Code,IsESICCovered,ESICNumber",
      "OwnerShip": null,
      "LocatedAt": null,
      "IsESICApplicable": null,
      "WorkOrder": null,
      "SubWorkOrder": null,
      "UserID": GLOBAL.USER_ID,
      "RequireType": 0,
      "ActionType": 0
    }
    return this.httpClient
      .get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PLANTS}?Search=&Company=&StateCode=&CityCode=&CountryCode=
        &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`);
  }

  getUnits(plant) {
    const queryString = $.param({
      Search: "",
      PlantCode: JSON.stringify(plant),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.MASTER_DATA.GET_UNITS}?${queryString}`,
      httpOptions
    );
  }

  getMasterlistData() {
    return this.httpClient.get(
      `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?ShortCode=&Search=&PageCode=`
    );
  }

  /**
   * get shift group data
   *  @author Sonal Prajapati
   */
  getShiftGroupData() {
    // tslint:disable-next-line: prefer-template
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SHIFT_GROUP_DATA + "?Search=&PlantCode="
    );
  }

  /**
   * getSkillType
   * @author Sonal Prajapati
   */
  getSkillType() {
    const model = {
      Search: "",
      Plant_Code: "",
      ParentSkill_Code: "",
      Trade_Code: "",
    };
    // return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES}`, model);
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES}`;
    return this.httpClient
      .post<any>(url, model, httpOptions)
      .pipe(map((response: any) => response));
  }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SKILL_TYPES
  // + '?Search=&PlantCode=&ParentSkillCode=&TradeCode=');
  // }

  /**
   * getSectionData
   * @author Sonal Prajapati
   */
  // getSectionData() {
  //   // tslint:disable-next-line: prefer-template
  //   return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1
  //     + '?Search=&ParentSectionCode=&PlantCode=&DepartmentCode=&UnitCode=&CompanyCode=');
  // }

  getSectionData(
    parentCode,
    parentSectionCode,
    plant,
    department,
    unit,
    company
  ) {
    const queryString = $.param({
      Search: "",
      ParentCode: JSON.stringify(parentCode),
      ParentSectionCode: JSON.stringify(parentSectionCode),
      PlantCode: JSON.stringify(plant),
      DepartmentCode: JSON.stringify(department),
      UnitCode: JSON.stringify(unit),
      CompanyCode: JSON.stringify(company),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1}?${queryString}`,
      httpOptions
    );
  }

  /**
   * getDepartmentData
   * @author Sonal Prajapati
   */

  getSectionData1(
    parentCode,
    parentSectionCode,
    plant,
    department,
    unit,
    company
  ) {
    const queryString = $.param({
      Search: "",
      ParentCode: JSON.stringify(parentCode),
      ParentSectionCode: JSON.stringify(parentSectionCode),
      PlantCode: JSON.stringify(plant),
      DepartmentCode: JSON.stringify(department),
      UnitCode: JSON.stringify(unit),
      CompanyCode: JSON.stringify(company),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_SECTION_DATA1}?${queryString}`,
      httpOptions
    );
  }

  getDepartmentData(plant, unit, company) {
    const queryString = $.param({
      Search: "",
      PlantCode: JSON.stringify(plant),
      UnitCode: JSON.stringify(unit),
      CompanyCode: JSON.stringify(company),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DEPARTMENT_DATA}?${queryString}`,
      httpOptions
    );
  }

  /**
   * getDepartmentData
   * @author Sonal Prajapati
   */
  getStateData() {
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_STATE_DATA);
  }

  /**
   * getDepartmentData
   * @author Sonal Prajapati
   */
  getPinCodeData() {
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PINCODE_DATA);
  }

  /**
   * getWorkCategory
   * @author Sonal Prajapati
   */
  //   getWorkCategory() {
  //     return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_WORK_CATEGORY);
  //   }

  /**
   * getAllLabourDoc
   * @author Sonal Prajapati
   */
  getAllLabourDoc() {
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_LABOUR_DOC
    );
  }

  /**
   * getPostOfficeByCity
   * @author Sonal Prajapati
   */
  getPostOfficeByCity(district) {
    const queryString = $.param({
      ID: JSON.stringify(district),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_POST_OFFICE_BY_CITY}?${queryString}`,
      httpOptions
    );
  }

  // /**
  //  * getCityByDistrict
  //  * @author Sonal Prajapati
  //  */
  // getCityByDistrict(district) {
  //   const queryString = $.param({
  //     ID: JSON.stringify(district)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_CITY_BY_DISTRICT}?${queryString}`,
  //     httpOptions
  //   );
  // }

  // /**
  //  * getCityByDistrict
  //  * @author Sonal Prajapati
  //  */
  // getDistrictByState(district) {
  //   const queryString = $.param({
  //     ID: JSON.stringify(district)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_GET,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DISTRICT_BY_STATE}?${queryString}`,
  //     httpOptions
  //   );
  // }

  /**
   * getStateByCountry
   * @author Sonal Prajapati
   */
  getCountry() {
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_DATA}?Search=`,
    //   httpOptions
    // );

    const model = {
      Search: "",
    };
    return this.httpClient.post(
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_COUNTRY_LIST}`,
      model
    );
  }

  /**
   * get All trades
   * @author Sonal Prajapati
   */
  // getAllTrade() {
  //   const model = {
  //     Search: '',
  //   };
  //   return this.httpClient.post(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_TRADE}`, model);
  // }
  // tslint:disable-next-line: prefer-template
  // return this.httpClient.get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_TRADE}?Search=`);
  // }

  /**
   *getPostOfficeData
   * @author Sonal Prajapati
   */
  getPostOfficeData() {
    return this.httpClient.get(
      GLOBAL.APIS.LABOUR_MANAGEMENT.GET_POST_OFFICE_DATA
    );
  }

  /**
   *getCityData
   * @author Sonal Prajapati
   */
  getCityData() {
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_CITY_DATA);
  }
  /**
   *getCityData
   * @author Sonal Prajapati
   */
  getDistrictData() {
    return this.httpClient.get(GLOBAL.APIS.LABOUR_MANAGEMENT.GET_DISTRICT_DATA);
  }

  /**
   * getStateByCountry
   * @author Sonal Prajapati
   */
  getPlanByCompany(district) {
    const queryString = $.param({
      ID: JSON.stringify(district),
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_PLANT_BY_COMPANY}?${queryString}`,
      httpOptions
    );
  }

  // Get Contractor code
  getDocumentTypes() {
    return this.httpClient.get(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_DOC_TYPES);
  }
  public getDocumentTypesPageModuleWise(data) {
    return this.httpClient.post(
      `${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE_V1}`,
      data
    );
  }
  /**
   * @description used to get all the labour data from the server.
   * @author Sonal Prajapati
   * @param labourParams a number type of the contractor.
   */

  updateLabourContactDetails(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_UPDATE_LABOUR_CONTACT_DETAIL}`,
      httpOptions
    );
  }
  /**
   * @description used to get all the labour data from the server.
   * @author Sonal Prajapati
   * @param labourParams a number type of the contractor.
   */

  updateLabour(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_UPDATE_LABOUR}`,
      httpOptions
    );
  }

  // updateLabourPhoto(payload) : Observable<object> {
  //   httpOptions['body'] = payload;
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_POST,
  //     `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPDATE_LABOUR_PHOTO}`,
  //     httpOptions
  //   );
  // }
  /**
   * @description used to get all the labour data from the server.
   * @author Sonal Prajapati
   * @param {*} labourParams a number type of the contractor.
   */

  addLabour(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LABOUR_DATA}`,
      httpOptions
    );
  }

  deleteTableData(id, labourCode, requireType) {
    const httpOptions = {
      body: {
        Id: id,
        Labour_Code: labourCode,
        RequireType: requireType,
        ActionType: 0,
        UserId: GLOBAL.USER_ID,
      }
    };
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.LABOUR_INFO_DELETE}`,
      httpOptions
    )
  }

  deleteFamilyMember(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.DELETE_LABOUR_FAMILY_MEMBER_DATA}`,
      httpOptions
    );
  }

  deleteNomineeDetail(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.DELETE_LABOUR_NOMINEE_DATA}`,
      httpOptions
    );
  }

  addFamilyMember(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_INSERT_LABOUR_FAMILY_MEMBER_DATA}`,
      httpOptions
    );
  }

  updateFamilyMember(labourParams): Observable<Object> {
    const body = JSON.stringify(labourParams);
    httpOptions["body"] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_UPDATE_LABOUR_FAMILY_MEMBER_DATA}`,
      httpOptions
    );
  }

  public getAllContractorData(
    excludeContractorCode: string = ""
  ): Observable<any> {
    return this.httpClient
      .get(`${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isFilter=&isMain=
    &ExcludeContractor_Code=${excludeContractorCode}`);
  }

  // isESICWageCriteria(esicvalue) {
  //   const queryString = $.param({
  //     esicvalue: JSON.stringify(esicvalue)
  //   });
  //   return this.httpClient.request(
  //     GLOBAL.HTTP_POST,
  //     `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ESICWAGE}?${queryString}`,
  //     httpOptions
  //   );
  // }

  public formatToServerDate(date) {
    let a = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
    if (a === "Invalid date") {
      a = null;
    }
    return a;
  }

  public prepareReqData(data, userEmail) {
    const reqData = [];
    for (let i = 0; i < data.length; i++) {
      const obj = JSON.parse(JSON.stringify(data[i]));
      obj.UserEmail = userEmail;
      obj.Relation =
        obj.Relation && obj.Relation !== "" ? parseInt(obj.Relation, 10) : null;
      obj.Gender =
        obj.Gender && obj.Gender !== "" ? parseInt(obj.Gender, 10) : null;
      obj.MaritalStatus =
        obj.MaritalStatus && obj.MaritalStatus !== ""
          ? parseInt(obj.MaritalStatus, 10)
          : null;
      obj.Qualification =
        obj.Qualification && obj.Qualification !== ""
          ? parseInt(obj.Qualification, 10)
          : null;
      obj.QulificationStream =
        obj.QulificationStream && obj.QulificationStream !== ""
          ? parseInt(obj.QulificationStream, 10)
          : null;
      // tslint:disable-next-line:max-line-length
      obj.TechnicalQulification =
        obj.TechnicalQulification && obj.TechnicalQulification !== ""
          ? parseInt(obj.TechnicalQulification, 10)
          : null;
      obj.BankName =
        obj.BankName && obj.BankName !== "" ? parseInt(obj.BankName, 10) : null;
      obj.BloodGroup =
        obj.BloodGroup && obj.BloodGroup !== "" ? obj.BloodGroup : null;
      obj.SkillType =
        obj.SkillType && obj.SkillType !== ""
          ? parseInt(obj.SkillType, 10)
          : null;
      obj.Trade =
        obj.Trade && obj.Trade !== "" ? parseInt(obj.Trade, 10) : null;
      obj.Nationality =
        obj.Nationality && obj.Nationality !== ""
          ? parseInt(obj.Nationality, 10)
          : null;
      obj.Mobile =
        obj.Mobile && obj.Mobile !== "" ? parseInt(obj.Mobile, 10) : null;
      obj.BirthDate = this.formatToServerDate(obj.BirthDate);
      obj.PoliceVerificationDate = this.formatToServerDate(
        obj.PoliceVerificationDate
      );
      obj.DrivingLicenseExpireDate = this.formatToServerDate(
        obj.DrivingLicenseExpireDate
      );
      obj.PassportRegistraionDate = this.formatToServerDate(
        obj.PassportRegistraionDate
      );
      obj.PassportExpireDate = this.formatToServerDate(obj.PassportExpireDate);
      reqData.push(obj);
    }
    return reqData;
  }

  // Import Labour data
  public importData(data): Observable<any> {
    // const reqData = this.prepareReqData(data, userEmail);
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_IMPORT_LABOUR}`,
      httpOptions
    );
  }

   // Import Labour data
   public importDataLatest(data): Observable<any> {
    // const reqData = this.prepareReqData(data, userEmail);
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_IMPORT_LABOUR_IMPORT}`,
      httpOptions
    );
  }

  // Import Labour HR data
  public importDataHr(data): Observable<any> {
    // const reqData = this.prepareReqData(data, userEmail);
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.POST_IMPORT_LABOUR_HR}`,
      httpOptions
    );
  }

  public getPincodeDetails(pincodeList): Observable<any> {
    // const pincodes = pincodeList.join(",");
    const pincodes = filter(pincodeList,(pincode)=>!isNaN(+pincode)).join(',')
    return this.httpClient.get(
      `${GLOBAL.APIS.MASTER_DATA.GET_PINCODE_DETAILS_DATA}?zipcode=${pincodes}`
    );
  }

  public getDataFromZipcode(zipcode):Observable<any> {
    let payload = {

        Filter: {
          ZipCode: zipcode,
          Search: null,
          RequiredFields: null,
          RequireType: 0,
          ActionType: 0,
          UserID: GLOBAL.USER_ID
        },
        Fields: [
          {
            keyName: "Country_Code",
            DisplayName: "Country"
          },
          {
            KeyName: "State_Code",
            DisplayName: "State"
          },
          {
            KeyName: "District_Code",
            DisplayName: "District"
          },
          {
            KeyName: "City_Code",
            DisplayName: "City"
          },
          {
            KeyName: "PostOffice_Code",
            DisplayName: "Post",
          },
          {
            KeyName: "Pincode_Code",
            DisplayName: "Village",
            ExtraFields: ["ZipCode"]
          }
        ]
      };

      return this.httpClient.post<any[]>(`${GLOBAL.APIS.MASTER_DATA.GET_DATA_FROM_ZIPCODE}`, payload);

  }

  public getDepartmentPlantUnitSectionTree(): Observable<any> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TREE_New}?userId=100`;
    return this.httpClient.get(url).pipe(map((response: any) => response.Data));
  }

  public getDepartmentPlantUnitSectionData(): Observable<any> {
    PLANT_UNIT_DEP_REQUEST.Filter.UserId = GLOBAL.USER_ID;
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_DATA}`;
    return this.httpClient.post(url, PLANT_UNIT_DEP_REQUEST);
  }

  public validateWorkOrderContractor(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_WORK_ORDER_CONTACTOR}`,
      httpOptions
    );
  }

  public validateLicenceContractor(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_LICENCE_CONTACTOR}`,
      httpOptions
    );
  }

  public validateECPolicyContractor(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_EC_POLICY_CONTACTOR}`,
      httpOptions
    );
  }

  public validateULC(list): Observable<any> {
    httpOptions["body"] = JSON.stringify(list);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_ULC}`,
      httpOptions
    );
  }

  public validateGatePass(list): Observable<any> {
    httpOptions["body"] = JSON.stringify(list);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS}`,
      httpOptions
    );
  }

  public validateGatePassULC(data): Observable<any> {
    httpOptions["body"] = JSON.stringify(data);
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.LABOUR_MANAGEMENT.VALIDATE_GATE_PASS_ULC}`,
      httpOptions
    );
  }

  public globalSettingCode(code: string) {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_GLOBAL_SETTING}?Code=${code}`;

    return this.httpClient.get(url);
  }

  public getCommonSpUserPlantAcces() {
    const url  = `${GLOBAL.APIS.MASTER_DATA.GET_COMMON_SP_USERPLANTACCES}`;

    let payload = {
      UserID: GLOBAL.USER_ID,
      RequireType: 1,
      SiteMIL_Code: null,
      ActionType: null,
    };

    return this.httpClient.post<any[]>(url, payload);
  }

  getDemandtypes() {

  }

  getMasterListData_V2(shortCode: string): Observable<any> {
    const payload = {
      "Filter": {
        "ShortCode": shortCode,
        "Parent_Code": null,
        "Search": null,
        "Page_Code": 339,
        "RequireType": 1,
        "ActionType": 0,
        "UserId": 100
      },
      "Fields": [
        {
          "keyName": "Parent_Code",
          "DisplayName": "ParentName"
        },
        {
          "keyName": "MasterItem_Code",
          "DisplayName": "Name",
          "ExtraFields": [
            "ShortName",
            "ShortCode",
            "Page_Code",
            "Module_Code",
            "MouleName",
            "AppsGroups_Code",
            "AppGroupName",
            "IsDelete",
            "IsActive"
          ]
        }
      ]
    }

    return this.httpClient.post<any>(
      GLOBAL.APIS.COMPANY_COMMONS_MASTER.GETMASTERITEMLISTDATA_V2,
      payload
    );
  }

  public getContractors(isSubContractorList = false, Contractor_Code: any = '', workOrderCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.WORK_ORDER.GET_CONTRACTORS_SP}`

    const payload = {
      search: null,
      sDate: null,
      eDate: null,
      selectedField: null,
      isMain: isSubContractorList ? "0" : "1",
      Contractor_Code: Contractor_Code || null,
      ExcludeContractor_Code: Contractor_Code || null,
      workOrderCode: workOrderCode || null,
      Plant_Code: null,
      UserID: GLOBAL.USER_ID,
      RequirType: null
    }
    return this.httpClient.post<any[]>(url, payload)
  }
}
