import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  encryptData(data: any) {
    try {
      let getEncryptVal: any = CryptoJS.AES.encrypt(JSON.stringify(data), environment.encryptSecretKey).toString();
      return getEncryptVal;
    } catch (e) {
      return undefined;
    }
  }

  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)).value;
      }
      return data.value;
    } catch (e) {
      return undefined;
    }
  }

  setLocalStorage(opts: any) {
    let value: string = '' + this.encryptData({ value: opts.value });
    localStorage.setItem(Md5.hashAsciiStr(environment.baseUrl + opts.key).toString(), value);
  }

  getLocalStorage(opts: { key: any }) {
    let key = Md5.hashAsciiStr(environment.baseUrl + opts.key).toString();
    return this.decryptData(localStorage.getItem(key));
  }

  removeLocalStorage(opts: { key: any }) {
    let key = Md5.hashAsciiStr(environment.baseUrl + opts.key).toString();
    return localStorage.removeItem(key);
  }

  barrierKey: string = '';
  UUID: string = '';
  setHeaderEncryptData(data: any) {
    if (data != null && data != undefined && data != '') {
      this.generateUUID();
      let encryptData = data + this.UUID;
      let getEncryptVal: any = CryptoJS.SHA256(encryptData);
      this.barrierKey = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA1(getEncryptVal, environment.ForceApiKey));
    }
  }


  generateUUID() {
    let dictionary = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPWRSTUVWXYZ0123456789-!@#$%&=+";
    let UUID1 = '';
    for (let i = 0; i < 64; i++) {
      UUID1 += dictionary[Math.floor(Math.random() * dictionary.length)];
    }
    this.UUID = UUID1;
  }
}
