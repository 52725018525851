import { Component, OnInit } from '@angular/core';
import { LaboursService } from '../labours.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LabourProfileImageComponent } from '../create-sub-labour/labour-profile-image/labour-profile-image.component';
import { SharedService } from 'src/app/core/services';
import { GLOBAL } from 'src/app/app.globals';
import { GatePassRequestsService } from '../../gate-pass-requests/gate-pass-requests.service';
import { FileDoc, FileUploadService } from 'src/app/core/components';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.css']
})
export class UploadPhotoComponent implements OnInit {
  showModal: boolean = false;
  public activeModal: NgbModalRef | null = null;

  uploadedFiles: FileDoc[] = [];
  copyUploadedFiles: FileDoc[] = []
  public allowedFileTypes: any = GLOBAL.ALLOWED_FILE_TYPES;
  public maxSizeAllowed: any = "";
  documentTypes = [];
  globalSettings: any;

  loginUserId: string | null = GLOBAL.USER_ID;
  selectedLabour: any;

  leftFinger: string = '';
  rightFinger: string = '';
  fingers: string[] = ['Thumb', 'Index', 'Middle', 'Ring', 'Pinky'];

  leftHandData: any = {
    Thumb: null,
    Index: null,
    Middle: null,
    Ring: null,
    Pinky: null,
  };

  rightHandData: any = {
    Thumb: null,
    Index: null,
    Middle: null,
    Ring: null,
    Pinky: null,
  };

  photoFile: File | null = null;
  leftFingerFile: File | null = null;
  rightFingerFile: File | null = null;
  originalLabour: any;
  leftFingerImage: string = '';
  rightFingerImage: string = '';
  savedCroppedImage: string = '../assets/Image/Icon/default.png';

  constructor(
    private labourService: LaboursService,
    private modalService: NgbModal,
    public sharedService: SharedService,
    private gatePassRequestsService: GatePassRequestsService,
    private fileUploadService: FileUploadService,
  ) { }

  ngOnInit(): void {
    this.labourService.currentData.subscribe((result) => {
      if (result.function === 'onUploadPhoto' && result.data && result.type === 'selectedLabour') {
        this.getSingleLabour(result.data);
      }
    });
  
    this.getDocumentTypes();
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      if (res) {
        this.globalSettings = res.Data.Table;
        this.maxSizeAllowed =
          Number(
            this.globalSettings.find((item) => item.Code === "DOCSIZEL1").Value
          ) * 1024;
        this.allowedFileTypes = this.globalSettings.find(
          (item) => item.Code === "DOCFORMATL1"
        ).Value;
      }
    });
  }

  getDocumentTypes() {
    const res = {
      Page_Code: 196,
      RequireType: 1,
      ActionType: 0,
      UserId: GLOBAL.USER_ID,
      Search: null,
      SelectedField: "Name,DocumentType_Code,page_code",
    };
    this.gatePassRequestsService.getDocumentTypesPageModuleWise(res).subscribe((docTypes: any) => {
      this.documentTypes = docTypes;
    });
  }

  getSingleLabour(labourData: any): void {
    const reqObj = {
      Labour_Code: labourData.Labour_Code,
      RequireType: 0,
      ActionType: 0,
      UserId: this.loginUserId,
    };

    this.labourService.getLabourDetails(reqObj).subscribe((response) => {
      if (response) {
        this.selectedLabour = response[0];
        this.originalLabour = { ...response[0] };

        this.savedCroppedImage = this.selectedLabour.Photo || '../assets/Image/Icon/default.png';
        this.leftHandData = {
          Thumb: this.selectedLabour.LeftThumb,
          Index: this.selectedLabour.LeftIndex,
          Middle: this.selectedLabour.LeftMiddle,
          Ring: this.selectedLabour.LeftRing,
          Pinky: this.selectedLabour.LeftPinky,
        };

        this.rightHandData = {
          Thumb: this.selectedLabour.RightThumb,
          Index: this.selectedLabour.RightIndex,
          Middle: this.selectedLabour.RightMiddle,
          Ring: this.selectedLabour.RightRing,
          Pinky: this.selectedLabour.RightPinky,
        };

        this.leftFinger = this.detectFirstAvailableFinger(this.leftHandData);
        this.rightFinger = this.detectFirstAvailableFinger(this.rightHandData);

        this.leftFingerImage =
          this.leftHandData[this.leftFinger] || '../assets/Image/Icon/default_finger.png';
        this.rightFingerImage =
          this.rightHandData[this.rightFinger] || '../assets/Image/Icon/default_finger.png';
      }
    });

    this.showModal = true;
  }

  detectFirstAvailableFinger(handData: any): string {
    for (const finger of this.fingers) {
      if (handData[finger]) {
        return finger;
      }
    }
    return '';
  }

  captureImage(type: string): void {
    this.activeModal = this.modalService.open(LabourProfileImageComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    const selectedImage =
      type === 'photo'
        ? this.savedCroppedImage
        : type === 'left'
          ? this.leftHandData[this.leftFinger]
          : this.rightHandData[this.rightFinger];

    this.activeModal.componentInstance.image = selectedImage;

    this.activeModal.componentInstance.croppedImage.subscribe((arg: any) => {
      if (type === 'photo') {
        this.savedCroppedImage = arg;
        this.selectedLabour.Photo = arg;
      } else if (type === 'left') {
        this.leftFingerImage = arg;
        if (this.leftFinger) {
          this.leftHandData[this.leftFinger] = arg;
          this.selectedLabour[`Left${this.leftFinger}`] = arg;
        }
      } else if (type === 'right') {
        this.rightFingerImage = arg;
        if (this.rightFinger) {
          this.rightHandData[this.rightFinger] = arg;
          this.selectedLabour[`Right${this.rightFinger}`] = arg;
        }
      }
      this.activeModal.close();
    });

    this.activeModal.componentInstance.closeModal.subscribe(() => {
      this.activeModal.close();
      this.clearImageValues();
    });
  }
  
  clearImageValues(): void {
    this.savedCroppedImage = '';
    this.leftFingerImage = '';
    this.rightFingerImage = '';
    this.uploadedFiles = [];
  }

  onFingerSelect(hand: string, selectedFinger: string): void {
    if (hand === 'left') {
      this.leftFinger = selectedFinger;
    } else if (hand === 'right') {
      this.rightFinger = selectedFinger;
    }
  }

  onFileChange(event: Event, type: 'photo' | 'left' | 'right'): void {
    const input = event.target as HTMLInputElement;
    if (input && input.files && input.files[0]) {
      const file = input.files[0];

      this.convertToBase64(file, (base64Image: string) => {
        if (type === 'photo') {
          this.savedCroppedImage = base64Image;
        } else if (type === 'left') {
          this.leftFingerImage = base64Image;
          this.updateSelectedFingerData('Left', base64Image);
        } else if (type === 'right') {
          this.rightFingerImage = base64Image;
          this.updateSelectedFingerData('Right', base64Image);
        }
      });
    }
  }

  convertToBase64(file: File, callback: (base64Image: string) => void): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result as string);
    };
  }

  updateSelectedFingerData(hand: 'Left' | 'Right', base64Image: string): void {
    const selectedFinger = this[`${hand.toLowerCase()}Finger`];
    if (selectedFinger) {
      const fingerKey = `${hand}${selectedFinger}`;
      if (hand === 'Left') {
        this.leftHandData[fingerKey] = base64Image;
      } else if (hand === 'Right') {
        this.rightHandData[fingerKey] = base64Image;
      }
    }
  }

  updateDetails(): void {
    const foreignKey = '29BA8F92-E774-45D6-A07C-975827F49171';

    const payload: any = {
      [foreignKey]: {
        Labour_Code: {
          OldValue: this.originalLabour.Labour_Code,
          NewValue: this.selectedLabour.Labour_Code,
        },
        // Photo: {
        //   OldValue: this.originalLabour.Photo || null,
        //   NewValue: this.savedCroppedImage || null,
        // },
      },
    };

    const fieldsToCompare = [
      { key: 'LeftThumb', oldValue: this.originalLabour.LeftThumb, newValue: this.leftHandData.Thumb },
      { key: 'LeftIndex', oldValue: this.originalLabour.LeftIndex, newValue: this.leftHandData.Index },
      { key: 'LeftMiddle', oldValue: this.originalLabour.LeftMiddle, newValue: this.leftHandData.Middle },
      { key: 'LeftRing', oldValue: this.originalLabour.LeftRing, newValue: this.leftHandData.Ring },
      { key: 'LeftPinky', oldValue: this.originalLabour.LeftPinky, newValue: this.leftHandData.Pinky },
      { key: 'RightThumb', oldValue: this.originalLabour.RightThumb, newValue: this.rightHandData.Thumb },
      { key: 'RightIndex', oldValue: this.originalLabour.RightIndex, newValue: this.rightHandData.Index },
      { key: 'RightMiddle', oldValue: this.originalLabour.RightMiddle, newValue: this.rightHandData.Middle },
      { key: 'RightRing', oldValue: this.originalLabour.RightRing, newValue: this.rightHandData.Ring },
      { key: 'RightPinky', oldValue: this.originalLabour.RightPinky, newValue: this.rightHandData.Pinky },
    ];

    fieldsToCompare.forEach((field) => {
      if (field.oldValue !== field.newValue) {
        payload[foreignKey][field.key] = {
          OldValue: field.oldValue,
          NewValue: field.newValue,
        };
      }
    });

    // Send the payload
    this.labourService.updateProfilePhotoAndFingers(payload).subscribe((response) => {
      console.log('Payload sent successfully:', response);
    });
    this.uploadDocuments()
    this.onUploadLabourPhoto()
    this.closeModal();
  }
  uploadDocuments() {
    if (this.uploadedFiles.length) {
      const DocumentImageMasterViewModals: any = [];
      this.uploadedFiles.forEach((file) => {
        if (file.isUploaded) {
          DocumentImageMasterViewModals.push({
            ID: file.ID,
            DocumentTypeMasterList_Code: file.documentTypeCode,
          });
        } else {
          DocumentImageMasterViewModals.push({
            DocumentTypeMasterList_Code: file.documentTypeCode,
          });
        }
      });
    }
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      if (this.selectedLabour.Labour_Code) {
        this.fileUploadService.uploadFiles(this.uploadedFiles, this.selectedLabour, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_LABOUR_DOC).subscribe((res) => {
        });
      }
    }
  }

  onFilesChanged(newFiles: any) {
    const set = new Set(newFiles.filter(item => {
      if (item.ID) {
        return item.ID
      }
    }));
    const result = [];

    for (const item of this.copyUploadedFiles) {
      if (!set.has(item.ID)) {
        result.push(item);
      }
    }
  }

  onUploadLabourPhoto() {
    const reqObj = {
      User_Code: this.selectedLabour.Labour_Code,
      Photo: this.savedCroppedImage,
      UserID: this.loginUserId
    }
    this.labourService.updateProfilePhoto(reqObj).subscribe((response) => {
    });
  }

  closeModal() {
    this.showModal = false;
    this.clearForm();
  }

  clearForm() {
    this.leftFinger = '';
    this.rightFinger = '';
    this.photoFile = null;
    this.leftFingerFile = null;
    this.rightFingerFile = null;
  }
}
