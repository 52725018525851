import { Injectable, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef, ComponentFactory } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { FilterTypeEnum } from '../../attendance-approve/attendance-approve.model';
import { GLOBAL } from 'src/app/app.globals';

@Injectable()
export class LabourWeekOFfPrsenter {

  public reportData$: Observable<FormGroup>;
  private reportData: Subject<FormGroup>;
  public startDate$: Observable<string>;
  private startDate: Subject<string>;
  public contractor$: Observable<number>;
  private contractor: Subject<number>;
  public workOrder$: Observable<any>;
  private workOrder: Subject<any>;
  constructor(private fb: FormBuilder) {
    this.reportData = new Subject();
    this.reportData$ = this.reportData.asObservable();

    this.startDate = new Subject();
    this.startDate$ = this.startDate.asObservable();

    this.contractor = new Subject();
    this.contractor$ = this.contractor.asObservable();

    this.workOrder = new Subject();
    this.workOrder$ = this.workOrder.asObservable();
  }

  public buildForm(): FormGroup {
    return this.fb.group({
      FilterName: [null],
      JSONText: this.fb.group({
        Date: [null],
        groupBy: [null],
        punchingWiseFilter: [null],
        labourCode: [null],
        dataOrderBy: [null],
        CurrentStatus: '',
        Company_Code: [null],
        Plant_Code: [null],
        Unit_Code: [null],
        Department_Code: [null],
        CategoryWorkmenMIL_Code: [null],
        WorkmenCategoty_Code: [null],
        Section_Code: [null],
        sectionList: [null],
        workmanCategoryList: [null],
        ShiftGroupType_Code: [null],
        Shift_Code: [null],
        StartDate: [null],
        EDate: [null],
        Contractors: [null],
        WorkOrder_Code: [null],
        License_Code: [null],
        ESIC: [null],
        WCPolicy: [null],
        SubContractors: [null],
        SubWorkOrder_Code: [null],
        id: [null],
        SubLicense_Code: [null],
        SubESIC: [null],
        SubWCPolicy: [null],
        WorkSiteCategory: [null],
        Gender: [null],
        Skill_Code: [null],
        Trade_Code: [null],
        QualificationMasterList_Code: [null],
        TechQualificationMasterList_Code: [null],
        QualificationStreamMasterList_Code: [null],
        Religion_Code: [null],
        Cast_Code: [null],
        IsDomicial: [null],
        FirstAIDTraning: [null],
        FireFighter: [null],
        SAge: [null],
        EAge: [null],
        JobType: [null],
        NatureOfWork_Code: [null],
        WageCriteria: [null],
        ContactortMenType: [null],
        ExpiredMedicalPeriod: [null],
        ExpiredFireTraining: [null],
        PoliceVerifed: [null],
        LabourDemandType: [null],
        dateRange: [null],
        Page: [null],
        contAndSubCont: [null],
        formControlName: [null],
        isAttendanceStatus: [null],
        isApprove: [null],
        UserID: GLOBAL.USER_ID,
        JoiningOrResigning: [null],
        allmasterorspecific: [null],
        MTestOrITORPV: [null],
        ExcludeContractor: [null],
        previouswookoffmoovedate: [null],
        previousmoovedate: [null],
        upcomingwookoffmoovedate: [null],
        upcomingmoovedate: [null],
        pAplicableDate: [null],
        pWeekOffday: [null],
        AplicableDate: [null],
        WeekOffday: [null],
        ToDate: [null],
        FromDate: [null],
        Previousweekoff: [null],
        upcomingweekoff: [null],
        specificRequestDate: [null],
        permanentChange: [null],
        periodicChange: [null],
        RequestDate: [null],
        Specificweekoff: [null],
        Regularweekoff: [null],
        upcomingorprevious: [null],
        periodicorparmenent: [null],
        weekofftype: [null],
        LabourCodes: [null],
        ENDDate: [null],
        Labour_Code1: [null],
        weekOffDate: [null],
        weekOffShiftDate: [null]
      })
    });
  }

  // public buildFormForMedical(): FormGroup {
  //   return this.fb.group({
  //     FilterName: [null],
  //     JSONText: this.fb.group({
  //       Date: [null],
  //       dataOrderBy: [null],
  //       CurrentStatus: '',
  //       Company_Code: [null],
  //       Plant_Code: [null],
  //       Unit_Code: [null],
  //       Department_Code: [null],
  //       CategoryWorkmenMIL_Code: [null],
  //       WorkmenCategoty_Code: [null],
  //       Section_Code: [null],
  //       sectionList: [null],
  //       workmanCategoryList: [null],
  //       ShiftGroupType_Code: [null],
  //       Shift_Code: [null],
  //       StartDate: [null],
  //       EDate: [null],
  //       Contractors: [null],
  //       WorkOrder_Code: [null],
  //       License_Code: [null],
  //       ESIC: [null],
  //       WCPolicy: [null],
  //       SubContractors: [null],
  //       SubWorkOrder_Code: [null],
  //       id: [null],
  //       SubLicense_Code: [null],
  //       SubESIC: [null],
  //       SubWCPolicy: [null],
  //       WorkSiteCategory: [null],
  //       Gender: [null],
  //       Skill_Code: [null],
  //       Trade_Code: [null],
  //       QualificationMasterList_Code: [null],
  //       TechQualificationMasterList_Code: [null],
  //       QualificationStreamMasterList_Code: [null],
  //       Religion_Code: [null],
  //       Cast_Code: [null],
  //       IsDomicial: [null],
  //       NatureOfWork_Code: [null],
  //       ContactortMenType: [null],
  //       dateRange: [null],
  //       formControlName: [null],
  //       UserID: '',
  //       JoiningOrResigning: [null],
  //     })
  //   });
  // }
  public bindForm(formGroup: FormGroup, data: any) {
    formGroup.patchValue(data);
  }

  public saveContractorFilterData(contractorFormGroup: FormGroup) {
    if (contractorFormGroup.get('startDate').value !== '' || contractorFormGroup.get('contractorCode').value !== null
      || contractorFormGroup.get('workOrderNo').value !== null) {
      const data: any = contractorFormGroup.value;
      data.filterType = FilterTypeEnum.ContractorFilter;
      this.reportData.next(data);
    }
  }

  public onStartDateChange(startDate: string): void {
    if (startDate !== '') {
      this.startDate.next(startDate);
    }
  }

  public onContractorChange(contractorCode: string): void {
    if (contractorCode !== null) {
      this.contractor.next(parseInt(contractorCode, 10));
    } else {
      this.contractor.next(null);
    }
  }

  public onWorkOrderChange(contractorCode: string, workOrderNo: string): void {
    if (contractorCode !== null && workOrderNo !== null) {
      this.workOrder.next({
        contractorCode,
        workOrderNo
      });
    }
  }
}
