import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/app.globals';
declare var $: any;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class LabourWeekoffChangesService {

  constructor(private http: HttpClient) { }

  getAllLabourWeekOffs() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_All_LABOURWEEKOFF_DATA}?company_Code=&plant_Code=&labour_Code=`,
      // 'http://localhost:59557/api/Master/GetShiftGroup?Search=&PlantCode=',
      httpOptions
    );
  }
  getAllExceptionalAttendanceData() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_ALL_EXCEPTIONAL_DATA}?UserId=`,
      // 'http://localhost:59557/api/Master/GetShiftGroup?Search=&PlantCode=',
      httpOptions
    );
  }
  public approveLabourweekoffchangerequest(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.APPROVE_WEEK_OFF_CHANGE_REQUEST}`;
    return this.http.post(url, data).pipe(
      map((response: any) => response.Data));
  }
  getRoleByUserID() {
    const queryString = $.param({
      UserID: GLOBAL.USER_ID,
    });
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_RoleCodeByUserID}?${queryString}`,
      httpOptions
    );
  }

  deleterecord(data: any) {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.DELETE_RECORD}`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      map((response: any) => response));
  }
}
