import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import { GatePassRequestsService } from '../gate-pass-requests.service';
import { AttendanceService } from 'src/app/attendance/attendance.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css']
})
export class FilterPopupComponent implements OnInit {
  public tabListFilter = [
    { id: 1, name: 'COMPANY', active: true },
    { id: 2, name: 'GATE PASS', active: false },
    { id: 3, name: 'CONTRACTOR', active: false },
    { id: 4, name: 'SUB-CONTRACTOR', active: false }
  ];

  @Input() public filter;
  isSelectedTabId: number = 1;
  roles = GLOBAL.ROLES;

  savedFilters: any[] = [];
  contractors: any[] = [];
  workOrders: any[] = [];
  ecPoliciesByWo: any[] = [];
  subContractors: any[] = [];
  plants: any[] = [];
  departments: any[] = [];
  workmanCategory: any[] = [];
  skills: any[] = [];
  months: any[] = [];
  leavetypes: any[] = [];
  statelist: any[] = [];
  licensesByWo: any[] = [];
  subLicenses: any[] = [];
  subWorkOrders: any[] = [];
  subEcPoliciesByWo: any[] = [];
  selectedContractors: any;
  selectedWorkOrder: string = '';
  selectedWorkOrderCode: number | null = null;
  selectedSubContractor: any;
  isActiveFilter: string = '';

  selectedPlant: any;
  selectedDepartment: any;
  selectedSkill: any;
  selectedWorkmanCategory: any;

  divRolecode = true;

  advanceFilterForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private gatePassRequestsService: GatePassRequestsService,
    public sharedService: SharedService,
    public attendanceService: AttendanceService,
    private fb: FormBuilder
  ) { }

  async ngOnInit() {
    this.getContractors();
    this.getStates();
    this.getLeaveType();
    this.getMonth();
    this.getAllDepartments();
    this.getAllPlants();
    this.getAllSkills();
    this.getAllWorkSiteCategories();
    this.isActiveFilter = this.tabListFilter[0].name;

    this.advanceFilterForm = this.fb.group({
      Plant: new FormControl(''),
      Department: new FormControl(''),
      Skill: new FormControl(''),
      workmanCategory: new FormControl(''),
      Search: new FormControl(''),
      ULC: new FormControl(''),
      Status: new FormControl(''),
      Role: new FormControl(''),
      roleActionCode: new FormControl(''),
      labourdemandtype: new FormControl(''),
      gatePassStatus: new FormControl(''),
      filterstartdate: new FormControl(''),
      filterenddate: new FormControl(''),
      Contractor: new FormControl(''),
      WorkOrder: new FormControl(''),
      License: new FormControl(''),
      ESIC: new FormControl(''),
      ECPolicyCheck: new FormControl(''),
      ECPolicy: new FormControl(''),
      SubContractor: new FormControl(''),
      SubWorkOrder: new FormControl(''),
      SubLicense: new FormControl(''),
      SubESIC: new FormControl(''),
      SubECPolicyCheck: new FormControl(''),
      SubECPolicy: new FormControl(''),
    });
  }

  public onTabFilter(tab: any): void {
    this.isSelectedTabId = tab.id;
    this.isActiveFilter = tab.name;
    this.tabListFilter.forEach((item) => item.active = item.id === tab.id);
  }

  getContractors() {
    this.attendanceService.getContractors().subscribe((response: any[]) => {
      this.contractors = response || [];
    });
  }

  getWorkOrders(contractorCode: any): void {
    this.workOrders = [];
    this.attendanceService.getWorkOrdersByContractorCode(contractorCode, undefined).subscribe((response: any[]) => {
      this.workOrders = response;
    });
  }

  getStates(): void {
    this.gatePassRequestsService.getAllState().subscribe((response: any) => {
      this.statelist = response;
    });
  }

  getLeaveType(): void {
    this.gatePassRequestsService.getLeaveTypeData().subscribe((response: any) => {
      this.leavetypes = response;
    });
  }

  getMonth(): void {
    this.gatePassRequestsService.getMonth().subscribe((response: any) => {
      this.months = response;
    });
  }

  getAllDepartments() {
    this.gatePassRequestsService.getALLDepartments().subscribe((response: any) => {
      this.departments = response.Data || response || [];
    });
  }

  getAllWorkSiteCategories() {
    this.gatePassRequestsService.getALLWorkSiteCategories().subscribe((response: any[]) => {
      this.workmanCategory = response.filter((list) => list.Parent_Code === 858);
    });
  }

  getAllPlants() {
    this.gatePassRequestsService.getALLPlants().subscribe((response: any) => {
      this.plants = response.Data || response || [];
    });
  }

  getAllSkills() {
    this.gatePassRequestsService.getALLSkills().subscribe((response: any) => {
      this.skills = response.Data || response || [];
    });
  }

  onContractorChange(selectedContractor: any) {
    this.selectedContractors = selectedContractor;

    if (selectedContractor.ContractorVendor_Code) {
      this.getWorkOrders(selectedContractor.ContractorVendor_Code);
      this.getLicenseBySubContractors(selectedContractor.ContractorVendor_Code);
    } else {
      this.workOrders = [];
    }
  }

  onWorkOrderChange(selectedWorkOrder: any) {
    this.selectedWorkOrder = selectedWorkOrder.WorkOrderNo;
    this.selectedWorkOrderCode = selectedWorkOrder.WorkOrder_Code;
    this.getWorkOrderBySubContractors();
  }

  getWorkOrderBySubContractors(): void {
    this.attendanceService.getSubContractors(this.selectedContractors.ContractorVendor_Code, this.selectedWorkOrderCode)
      .subscribe((response: any[]) => {
        this.subContractors = response;
      });
  }

  getLicenseBySubContractors(contractorCode): void {
    this.attendanceService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.licensesByWo = response;
    });
  }


  onSubmitAdvanceFilter() {
    const formValues = this.advanceFilterForm.value;
    const filterObject = {
      Departmentcode: formValues.Department ? formValues.Department : null,
      Skilltypecode: formValues.Skill ? formValues.Skill : null,
      FilterStartDate: formValues.filterstartdate ? formValues.filterstartdate : null,
      FilterEndDate: formValues.filterenddate ? formValues.filterenddate : null,
      LabourDemandType: formValues.labourdemandtype ? formValues.labourdemandtype : null,
      GatePassStatus: formValues.gatePassStatus ? formValues.gatePassStatus : null,
      Licensecode: formValues.License ? formValues.License : null,
      MultipleRole: GLOBAL.ROLE_CODE,
      RoleCodeForApproval: formValues.Role ? formValues.Role : 0,
      Roleactioncode: formValues.roleActionCode ? formValues.roleActionCode : 0,
      // NatureOfWork: 0,
      Contractorvendorcode: formValues.Contractor ? formValues.Contractor : 0 || 0,
      Subcontractorcode: this.filter.subContractorCode || 0,
      Subworkordercode: this.filter.subWorkorderCode || 0,
      Sublicensecode: this.filter.subLicenseCode || 0,
      Subecpolicycode: this.filter.subeEcPolicyCode || 0,
      UserCode: GLOBAL.USER_ID,
      UserRoleCode: JSON.parse(GLOBAL.ROLE)[0].Role_Code,
      WcPolicy: 0,
      Mainesic: this.filter.mainEsic,
      Ecpolicycode: this.filter.ecpolicyCode,
      Search: this.filter.search,
      Workordercode: this.filter.workorderCode || 0,
      WorkSiteCategory: this.filter.worksiteCategoryCode || 0,
      Statecode: this.filter.statusCode,
      Plantcode: formValues.Plant ? formValues.Plant : null,
      ULC: formValues.ULC ? formValues.ULC : null,
    };

    console.log('::::::::::::::: filterObject :::::::::::::::', filterObject);
    this.activeModal.close(filterObject);
    this.resetFilter();
  }

  resetFilter() {
    this.selectedPlant = null;
    this.selectedDepartment = null;
    this.selectedSkill = null;
    this.selectedWorkmanCategory = null;
    this.filter.search = '';
    this.filter.ulc = '';
    this.filter.statusCode = null;
    this.filter.roleCodeForApproval = null;
    this.filter.roleActionCode = null;
    this.filter.labourDemandType = null;
    this.filter.gatePassStatus = false;
    this.filter.filterStartDate = null;
    this.filter.filterEndDate = null;
    this.selectedContractors = null;
    this.selectedWorkOrder = null;
    this.selectedSubContractor = null;
    this.filter.subWorkOrder = null;
  }

  onCloseAction(): void {
    this.resetFilter();
    this.activeModal.close();
  }
}
