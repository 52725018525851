import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContractorAdapter } from '../contractor-adapter/contractor.adpater';
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EcPoliciesService {

  constructor(private http: HttpClient,
    private ecPolicyAdapter: ContractorAdapter) { }

  /**
  * @description used to get all the work order.
  * @author Sonal Prajapati
  * @param contractorType a number type of the contractor.
  */
  getAllEcPolicies(contractorType: number) {

    /*const queryString = $.param({
      userid: GLOBAL.USER_ID,
      drpfilterwork: contractorType
    });
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_EC_POLICYS}?${queryString}`,
      httpOptions
    );*/
    let payload = {
      State_Code: null,
      Status_Code: null,
      Plant_Code: null,
      IsActive: null,
      sDate: null,
      eDate: null,
      search: null,
      NatureOfWork_Code: null,
      TotalAccupied: null,
      Contractor_Code: null,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      isContractorType: contractorType,
      selectedField: null,
      filterType: null,
      condition: null,
      UserId: GLOBAL.USER_ID
    }

    httpOptions['body'] = payload;
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ECPOLICY.SP_Listing_WCPolicy_Filter}`,
      httpOptions
    );
  }

  // public getDocumentTypes() {
  //
  // //	const url = `${GLOBAL.APIS.ECPOLICY.GET_DOC_TYPES}/documents`;
  //   const url = `${GLOBAL.APIS.ECPOLICY.GET_DOC_TYPES}`;
  //   return this.http.get<any[]>(url);
  // }
  public getDocumentTypes() {
    const url = `${GLOBAL.APIS.ECPOLICY.GET_DOC_TYPES}`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data));
  }
  public getDocumentTypesPageModuleWise(data) {
    return this.http.post(`${GLOBAL.APIS.COMPANY_COMMONS_MASTER.GET_DOCUMENTS_TYPES_PAGE_MODULE_WISE_V1}`, data);
  }

  public checkECPolicyNoAvailability(ecPolicyNo: string, ecPolicyCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.ECPOLICY.CheckECPolicyNoAvailability}?ECPolicyNo=${ecPolicyNo}&ECPolicyCode=${ecPolicyCode}`;
    return this.http.get<any[]>(url);
  }

  public addECPolicy(value: any) {
    const url = `${GLOBAL.APIS.ECPOLICY.ADD_ECPOLICY} `;
    return this.http.post(url, value).pipe(map((response: any) => response.Data));
  }
  getWCPolicy(contractorType: number) {

    /*const userId = GLOBAL.USER_ID;
    const queryString = $.param({
      userid: GLOBAL.USER_ID,
      // tslint:disable-next-line: object-shorthand-properties-first
      contractorType
    });
    const url = `${GLOBAL.APIS.ECPOLICY.GET_WC_POLICY}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
    &Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=
    &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=${contractorType}&UserId=${userId}`;
    return this.http.request(
      GLOBAL.HTTP_GET,
      url,
      httpOptions
    ).pipe(map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));*/

    let payload = {
      State_Code: null,
      Status_Code: null,
      Plant_Code: null,
      IsActive: null,
      sDate: null,
      eDate: null,
      search: null,
      NatureOfWork_Code: null,
      TotalAccupied: null,
      Contractor_Code: null,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      isContractorType: contractorType,
      selectedField: null,
      filterType: null,
      condition: null,
      UserId: GLOBAL.USER_ID
    }

    httpOptions['body'] = payload;
    return this.http.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.ECPOLICY.SP_Listing_WCPolicy_Filter}`,
      httpOptions
    );
  }

  public updateECPolicy(value: any) {
    const url = `${GLOBAL.APIS.ECPOLICY.UPDATE_ECPOLICY}`;
    return this.http.post(url, value).pipe(map((response: any) => response.Data));
  }

  public uploadDocuments(files: any[], params) {
    const url = `${GLOBAL.APIS.ECPOLICY.ADD_DOCUMENT}`;
    const userId = GLOBAL.USER_ID;
    const fd = new FormData();
    let docType = '';
    const filesToUpload = this.getNotUploadedItems(files);
    filesToUpload.forEach((fileDoc, index) => {
      fd.append(`file${String(index)}`, fileDoc['file'], fileDoc['file']['name']);
      docType += filesToUpload.length - 1 === index ? `${fileDoc.documentTypeCode}` : `${fileDoc.documentTypeCode},`;
    });
    fd.append('Image_Type', docType);
    fd.append('UserID', userId);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    });
    // headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.http.request(GLOBAL.HTTP_POST, url, requestOptions);
  }

  public getEcPolicyByPolicyNo(ecpolicyCode: string): Observable<any> {

    const url = `${GLOBAL.APIS.ECPOLICY.GET_ECPOLICY}?ecpolicyCode=${ecpolicyCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertToDataResponse<any>(response)));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS_SP}`;
    const payload = {
      "Search": null,
      "RequiredFields": "NatureOfWork_Code,Name,",
      "RequireType": 0,
      "ActionType": 0,
      "UserId": GLOBAL.USER_ID
    }
    return this.http
      .post<any[]>(url, payload)
      .pipe(map((response: any) => response));
  }

  public getMasterItemListByCode(shortCode: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST_SP}`;
    const payload = {
      "Filter": {
        "ShortCode": shortCode || "GM245",
        "Parent_Code": null,
        "Search": null,
        "Page_Code": 193,
        "RequireType": 1,
        "ActionType": 0,
        "UserId": 100
      },
      "Fields": [
        {
          "keyName": "Parent_Code",
          "DisplayName": "ParentName"
        },
        {
          "keyName": "MasterItem_Code",
          "DisplayName": "Name",
          "ExtraFields": ["ShortName", "ShortCode", "Page_Code", "Module_Code", "MouleName", "AppsGroups_Code", "AppGroupName", "IsDelete", "IsActive"
          ]
        }
      ]
    }
  
    return this.http.post<any[]>(url,payload);
  }

  public getPlants(companyCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS_SP}`;
    const payload = {
      "search": null,
      "Company": companyCode,
      "State": null,
      "City": null,
      "Country": null,
      "UnitType": null,
      "selectedField": "Plant,Plant_Code,State_Code,State,SiteMILCode,SITE,Company_Code,IsESICCovered,ESICNumber",
      "OwnerShip": null,
      "LocatedAt": null,
      "IsESICApplicable": null,
      "WorkOrder": null,
      "SubWorkOrder": null,
      "UserID": GLOBAL.USER_ID,
      "RequireType": 0,
      "ActionType": 0
    }
    return this.http
      .post<any[]>(url, payload)
  }

  public getContractors(isSubContractorList = false, contractorCode: any = '', workOrderCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS_SP}`
    const payload = {
      "search": null,
      "sDate": null,
      "eDate": null,
      "selectedField": null,
      "isMain": isSubContractorList ? "0" : "1",
      "Contractor_Code": contractorCode || null,
      "ExcludeContractor_Code": null,
      "workOrderCode": workOrderCode || null,
      "Plant_Code": null,
      "UserID": GLOBAL.USER_ID,
      "RequirType": null
    }
    return this.http.post<any[]>(url, payload)
  }

  public getWorkOrderFilterDataForSubWorkorder(contractorCode:any,subContractorCode: any, workOrderCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate='
    //   + '&eDate='
    //   + '&search='
    //   + '&selectedField='
    //   + '&filterType='
    //   + '&condition='
    //   + '&isActive='
    //   + '&workOrderType='
    //   + '&workOrderOwner_Code='
    //   + '&workOrderHOD_Code='
    //   + '&department_Code='
    //   + '&isContractorType='
    //   + '&Plant_Code='
    //   + '&NatureOfWork_Code='
    //   + '&NatureOfWork_Code='
    //   + '&Contractor_Code='
    //   + '&SubContractor_Code=' + subContractorCode
    //   + '&WorkOrder_Code='
    //   + '&SubWorkOrder_Code='
    //   + '&WorkSiteArea_Code='
    //   + '&IsHRCApplicable='
    //   + '&Status_Code='
    //   + '&UserId=';
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));

    const payload = {
      sDate: null,
      eDate: null,
      selectedField: null,
      filterType: null,
      condition: null,
      isActive: null,
      workOrderType: null,
      workOrderOwner_Code: null,
      workOrderHOD_Code: null,
      department_Code: null,
      isContractorType: null,
      Plant_Code: null,
      NatureOfWork_Code: null,
      Contractor_Code: contractorCode,
      SubContractor_Code: subContractorCode,
      WorkOrder_Code: workOrderCode,
      SubWorkOrder_Code: null,
      WorkSiteArea_Code: null,
      IsHRCApplicable: null,
      Status_Code: null,
      UserId: GLOBAL.USER_ID
    };
	return this.http.post<any[]>(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER, payload).pipe(
        map((response: any) => response));
  }


  public getWorkOrderFilterData(contractorCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    // const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
    //   + '?sDate='
    //   + '&eDate='
    //   + '&search='
    //   + '&selectedField='
    //   + '&filterType='
    //   + '&condition='
    //   + '&isActive='
    //   + '&workOrderType='
    //   + '&workOrderOwner_Code='
    //   + '&workOrderHOD_Code='
    //   + '&department_Code='
    //   + '&isContractorType='
    //   + '&Plant_Code='
    //   + '&NatureOfWork_Code='
    //   + '&NatureOfWork_Code='
    //   + '&Contractor_Code=' + contractorCode
    //   + '&SubContractor_Code='
    //   + '&WorkOrder_Code='
    //   + '&SubWorkOrder_Code='
    //   + '&WorkSiteArea_Code='
    //   + '&IsHRCApplicable='
    //   + '&Status_Code='
    //   + '&UserId=';
    // return this.http.get<any[]>(url).pipe(
    //   map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));

    const payload = {
      sDate: null,
      eDate: null,
      selectedField: null,
      filterType: null,
      condition: null,
      isActive: null,
      workOrderType: null,
      workOrderOwner_Code: null,
      workOrderHOD_Code: null,
      department_Code: null,
      isContractorType: null,
      Plant_Code: null,
      NatureOfWork_Code: null,
      Contractor_Code: contractorCode,
      SubContractor_Code: null,
      WorkOrder_Code: null,
      SubWorkOrder_Code: null,
      WorkSiteArea_Code: null,
      IsHRCApplicable: null,
      Status_Code: null,
      UserId: GLOBAL.USER_ID
    };
    return this.http.post<any[]>(GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER, payload).pipe(
      map((response: any) => response));
  }

  // getPOGlobalSetting(code: string): Observable<any> {
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  // }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }

  public getContractorsbydefault(userId: any, roleCode: any): Observable<any[]> {
    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORSBY_DEFAULT_SP}`;
    const payload = {
      "RequireType": 0,
      "ActionType": 0,
      "UserId": userId || GLOBAL.USER_ID
    }
    return this.http.post<any[]>(url, payload)
  }
}
