import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CellClickedEvent, GridApi, GridOptions } from 'ag-grid-community';
import { AgActionCellRendererComponent, AgNumberCountComponent } from 'src/app/core/components';
import { AgActionCellLockRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-lock-renderer/ag-action-cell-lock-renderer.component';
import { AgActionCellPermissionRendererComponent } from 'src/app/core/components/grid-wrapper/helper/ag-action-cell-permission-renderer/ag-action-cell-permission-renderer.component';
import { SharedService } from 'src/app/core/services';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  @ViewChild('resetpassword') resetpassword: TemplateRef<any>;
  @ViewChild('departmentPer') departmentPer: TemplateRef<any>;
  @ViewChild('accessPer') accessPer: TemplateRef<any>;
  // resetpasswordform = new FormGroup({
  //   // newpassword: new FormControl(''),
  //   userpassword: new FormControl(''),
  //   confirmpassword: new FormControl(''),
  //   // newpasswordx: new FormControl('')
  // });
  departmentPerform = new FormGroup({
    confirmpassword1: new FormControl(''),
    userpassword1: new FormControl('')
  });
  // accessPerform = new FormGroup({
  //   confirmpassword2: new FormControl(''),
  //   userpassword2: new FormControl('')
  // });
  iscontractor = 1;
  // tslint:disable-next-line:variable-name
  UserCode: string;
  // tslint:disable-next-line:variable-name
  UserID: number;
  loggedinUserID: string;
  fullName: number;
  isLocked: boolean;
  isActive: boolean;
  gridAPI: GridApi;
  gridOptions: GridOptions;
  public modalRef: any;
  public resetpw: any;
  public departmentpw: any;
  public accesspw: any;
  userId: string;
  public selectedRow: any[];
  selectedUser: any;
  orignalData = [];
  public roles: any[];
  resetpasswordform: FormGroup;

  constructor(
    private userManagementService: UserManagementService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private router: Router) { }

  async ngOnInit() {
    this.resetpasswordform = this.fb.group(
      {
        userpassword: ['', Validators.compose([Validators.required])],
        confirmpassword: ['', Validators.compose([Validators.required])],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
    this.getUserList(this.iscontractor);
    // this.getUserRoles();
  }

  columnDefs = [
    {
      headerName: '#', field: 'sortCode', width: 10,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
    }, {
      headerName: 'Full Name', field: 'FullName', width: 80, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    }, {
      headerName: ' User Code', field: 'UserCode', width: 30, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },
      tooltip: (params) => "Copy User Code",
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => {
              console.log('Text copied to clipboard:', cellValue);
            })
        }
      },
    }, {
      headerName: 'User Name', field: 'UserName', width: 60, sortable: true, filter: true,
      tooltip: (params) => "Copy User Name",
      filterParams: { applyButton: true, clearButton: true },
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => {
              console.log('Text copied to clipboard:', cellValue);
            })
        }
      },
    }, {
      headerName: 'Email ', field: 'AlternetEmailID', width: 60, sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },
      tooltip: (params) => "Copy Email",
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => {
              console.log('Text copied to clipboard:', cellValue);
            })
        }
      },
    }, {
      headerName: 'Mobile No', field: 'MobileNo', width: 40, sortable: true, filter: true, cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => "Copy Mobile No",
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
          navigator['clipboard'].writeText(cellValue)
            .then(() => {
              console.log('Text copied to clipboard:', cellValue);
            })
        }
      },
    },
    // {
    //   headerName: 'Is Organization User', field: 'IsOrganizationUser', width: 50, sortable: true, filter: true,
    //   filterParams: { applyButton: true, clearButton: true }
    // },
    {
      headerName: 'Status', field: '', width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellLockRendererComponent
    },
    // {
    //   headerName: 'Reset Password', field: '', width: 50,
    //   sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellActiveRendererComponent
    // },
    {
      headerName: 'Accesss and Permission', field: '', width: 50,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellPermissionRendererComponent
    },
    // {
    //   headerName: 'Is Active', field: '', width: 25,
    //   sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellActiveRendererComponent
    // },
    {
      headerName: 'Action', field: '', width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent,
      cellRendererParams: {
        isPasswordReset: true
      }
    }
  ];
  rowData: any;

  getUserList(iscontractor: number) {
    this.userManagementService.getUserList(iscontractor).subscribe((res: any) => {
      // console.log(res);
      if (res.length > 0) {
        this.rowData = res;
      }
      if (res.Data) {
        this.rowData = res.Data.Table;
      }
      // console.log(this.rowData);
    });
  }

  onTabChange(event) {
    this.getUserList(event.nextId);
  }

  editUser(item) {
    debugger;
    this.router.navigate([`/edit-user/${item.UserID}`]);
  }

  isLockUser(item) {
    this.UserID = item.UserID;
    this.isLocked = item.IsLocked;
    this.userManagementService.isLockUser(this.UserID, this.isLocked).subscribe((response: any) => {

      if (response.Data === true) {
        this.logHelperService.logSuccess({
          message: 'User is locked successfully'
        });
      } else if (response.Data === false) {
        this.logHelperService.logSuccess({
          message: 'User is unlocked successfully'
        });
      }
      this.getUserList(this.iscontractor);
    });
  }

  isActiveUser(item) {
    this.UserID = item.UserID;
    this.isActive = item.IsActive;
    this.userManagementService.isActiveUser(this.UserID, this.isActive).subscribe((response: any) => {

      if (response.Data === true) {
        this.logHelperService.logSuccess({
          message: 'User is activated successfully'
        });
      } else if (response.Data === false) {
        this.logHelperService.logSuccess({
          message: 'User is de-activated successfully'
        });
      }
      this.getUserList(this.iscontractor);
    });
  }

  resetPassword(item) {
    this.modalRef = this.modalService.open(this.resetpassword, { size: 'lg', backdrop: 'static', keyboard: false });
    this.resetpasswordform.reset();
    this.UserID = item.UserID;
  }

  public resetUserPassword() {
    this.resetpw = this.resetpasswordform.controls['userpassword'].value;
    if ((this.resetpasswordform.controls['userpassword'].value !== '' || this.resetpasswordform.controls['userpassword'].value !== null)
      // tslint:disable-next-line:max-line-length
      && (this.resetpasswordform.controls['confirmpassword'].value !== '' || this.resetpasswordform.controls['confirmpassword'].value !== null)) {
      this.userManagementService.resetPassword(this.UserID, this.resetpw).subscribe((response: any) => {

        if (response.Status === true) {
          this.logHelperService.logSuccess({
            message: 'User password is reset successfully'
          });
          this.resetpasswordform.reset();
        } else if (response.Status === false) {
          this.logHelperService.logSuccess({
            message: 'User password is not reset successfully'
          });
          this.resetpasswordform.reset();
        }
        this.getUserList(this.iscontractor);
        this.modalRef.close('click');
        this.resetpasswordform.reset();
        this.userManagementService.sendMail(this.UserID);
      });
    }
  }

  public clickdepartment($event): void {
    this.userManagementService.getFormData().subscribe((res: any) => {
      this.selectedRow = res;
    });
    const datobject: any = {
      Roles: this.selectedRow,
      UserID: localStorage.getItem('UserID'),
      SelectedUserID: this.UserID
    };
    this.userManagementService.addUserfordepartment(datobject).subscribe((response: any) => {
      if (response) {
        this.logHelperService.logSuccess({
          message: 'Department Access & Permission are successfully added.'
        });
        this.departmentPerform.reset();
        this.router.navigate(['user-management/user-list']);
      } else {
        this.logHelperService.logError({
          message: 'Department Access & Permission are not added!'
        });
        this.departmentPerform.reset();
        this.router.navigate(['user-management/user-list']);
      }
    });
  }

  public onCloseModel(): void {
    this.modalRef.close('click');
  }

  onAutoGeneratePassword() {
    const pswd = this.sharedService.generateRandomPassword(10);
    this.resetpasswordform.controls['userpassword'].setValue(pswd);
    this.resetpasswordform.controls['confirmpassword'].setValue(pswd);
  }

  departmentPermission(item) {
    this.modalRef = this.modalService.open(this.departmentPer,
      { size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'modal-full-size' });
    this.resetpasswordform.reset();
    this.UserID = item.UserID;
    this.fullName = item.FullName;
    this.selectedUser = item;
  }

  accessPermission(item) {
    this.modalRef = this.modalService.open(this.accessPer,
      { size: 'lg', backdrop: 'static', keyboard: false, windowClass: 'modal-full-size' });
    this.resetpasswordform.reset();
    this.UserID = item.UserID;
    this.selectedUser = item;
  }

  // public getUserRoles(): void {
  //   this.loggedinUserID = localStorage.getItem('UserID');
  //   this.userManagementService.getUserRoles(this.loggedinUserID).subscribe((response: any) => {
  //     this.roles = response;
  //   });
  // }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.resetpasswordform.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

}

export class ConfirmPasswordValidator {
  /**
   * Check matching password with confirm password
   * @param control AbstractControl
   */
  static MatchPassword(control: AbstractControl) {
    const password = control.get('userpassword').value;
    const confirmPassword = control.get('confirmpassword').value;
    if (password !== confirmPassword) {
      control.get('confirmpassword').setErrors({ confirmpassword: true });
    } else {
      return null;
    }
  }
}
