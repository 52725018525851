import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GridApi, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { GLOBAL } from 'src/app/app.globals';
import { SharedService } from 'src/app/core/services';
import { ApiCallService } from 'src/app/service/api-call.service';

@Component({
  selector: 'app-regular-attendance-approval',
  templateUrl: './regular-attendance-approval.component.html',
  styleUrls: ['./regular-attendance-approval.component.css']
})
export class RegularAttendanceApprovalComponent implements OnInit {

  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  modalRef: NgbModalRef;

  date = new Date();
  currentMonthStart = moment().startOf('month').toDate();
  currentMonthEnd = moment().endOf('month').toDate();

  reportDateRange: Date[] = [];
  selectedRows: any;

  gridColumnApi;
  gridApi: GridApi;
  gridOptions: GridOptions = {
    animateRows: true,
    rowSelection: 'multiple',
    rowHeight: 40,
    headerHeight: 40,
    pagination: false,
    suppressRowClickSelection: true,
  };

  rowData: any[] = []
  selectedRowData: any[] = []

  columnDefs = [
    { headerName: '', headerClass: 'custom-header', sortable: true, filter: 'agTextColumnFilter', field: 'index', cellClass: 'global-table-prev-cell-text-center', width: 50 },
    { headerName: 'Contractor', headerClass: 'custom-header', sortable: true, filter: 'agTextColumnFilter', width: 300, field: 'Contractor', cellClass: 'global-table-cell-text-center' },
    { headerName: 'WorkOrderNo', headerClass: 'custom-header', sortable: true, filter: 'agTextColumnFilter', field: 'WorkOrderNo', width: 180, cellClass: 'global-table-cell-text-center' },
    { headerName: 'Plant', headerClass: 'custom-header', sortable: true, field: 'Plant', width: 200, cellClass: 'global-table-cell-text-center' },
    { headerName: 'Unit', headerClass: 'custom-header', sortable: true, field: 'Unit', width: 200, cellClass: 'global-table-cell-text-center' },
    { headerName: 'Department', headerClass: 'custom-header', sortable: true, field: 'Department', width: 200, cellClass: 'global-table-cell-text-center' },
    { headerName: 'Section', headerClass: 'custom-header', sortable: true, field: 'Section', width: 180, cellClass: 'global-table-cell-text-center' },
    { headerName: '', headerClass: 'custom-header', sortable: true, width: 50, cellClass: 'global-table-cell-text-center', checkboxSelection: true, },
    { headerName: 'Total', headerClass: 'custom-header', sortable: true, field: 'Total', width: 100, cellClass: 'global-table-cell-text-center', onCellClicked: this.onTotalCellClicked.bind(this) },
  ];

  selectedRowcolumnDefs = [
    { headerName: '', headerClass: 'custom-header', sortable: true, width: 50, pinned: 'left', cellClass: 'global-table-prev-cell-text-center', checkboxSelection: true, },
    { headerName: 'GatePassNo', headerClass: 'custom-header', sortable: true, pinned: 'left', filter: 'agTextColumnFilter', field: 'GatePassNo', width: 110, cellClass: 'global-table-cell-text-center', },
    { headerName: 'Name', headerClass: 'custom-header', sortable: true, pinned: 'left', filter: 'agTextColumnFilter', field: 'Name', width: 180, cellClass: 'global-table-cell-text-center', },
    { headerName: 'AttendanceDate', headerClass: 'custom-header', sortable: true, field: 'AttendanceDate', width: 180, cellClass: 'global-table-cell-text-center', },
    { headerName: 'Shift', headerClass: 'custom-header', sortable: true, field: 'Shift', width: 80, cellClass: 'global-table-cell-text-center' },
    { headerName: 'Time', headerClass: 'custom-header', sortable: true, field: 'FormattedShiftTime', width: 80, cellClass: 'global-table-cell-text-center', },
    { headerName: 'In', headerClass: 'custom-header', sortable: true, field: 'FSInPunch', width: 180, cellClass: 'global-table-cell-text-center', },
    { headerName: 'Out', headerClass: 'custom-header', sortable: true, field: 'FSOutPunch', width: 180, cellClass: 'global-table-cell-text-center', },
    { headerName: 'Latein', headerClass: 'custom-header', sortable: true, field: 'LateInTime', width: 100, cellClass: 'global-table-cell-text-center', },
    { headerName: 'Early out', headerClass: 'custom-header', sortable: true, field: 'EarlyOutTime', width: 100, cellClass: 'global-table-cell-text-center', },
    { headerName: 'WHs', headerClass: 'custom-header', sortable: true, field: 'WorkingHours', width: 80, cellClass: 'global-table-cell-text-center', },
    { headerName: 'EWH', headerClass: 'custom-header', sortable: true, field: 'OTHours', width: 80, cellClass: 'global-table-cell-text-center', },
    { headerName: 'Status', headerClass: 'custom-header', sortable: true, field: 'Status', width: 80, cellClass: 'global-table-cell-text-center', },
  ];

  rowClassRules = {
    'global-table-even-row': (params) => params.node.rowIndex % 2 === 0,
    'global-table-odd-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,

  }

  constructor(
    private apiCall: ApiCallService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.setDefaultDateRange();
  }
 
  setDefaultDateRange() {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    this.reportDateRange = [startOfMonth, endOfMonth];
    const formattedStartDate = startOfMonth ? this.formatDate(startOfMonth) : '';
    const formattedEndDate = endOfMonth ? this.formatDate(endOfMonth) : '';
    this.attendanceApprovalList(formattedStartDate, formattedEndDate);
  }

  onDateRangeChange(event: any) {
    const [startDate, endDate] = this.reportDateRange;
    const formattedStartDate = startDate ? this.formatDate(startDate) : '';
    const formattedEndDate = endDate ? this.formatDate(endDate) : '';
    this.attendanceApprovalList(formattedStartDate, formattedEndDate);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  attendanceApprovalList(startDate, endDate) {
    const reqObj = {
      SDate: startDate,
      EDate: endDate,
      ContractorVendor_Code: null,
      WorkOrder_Code: null,
      SubContractorVendor_Code: null,
      SubWorkOrder_Code: null,
      RequireType: 0,
      ActionType: 0,
      UserID: GLOBAL.USER_ID
    };

    this.apiCall.getAttendenceApproval(reqObj).subscribe((response: any) => {
      this.groupDataByKeys(response)
    });
  }

  groupDataByKeys = (data: any[]) => {
    this.rowData = data.reduce((acc, current) => {
      const { Contractor, WorkOrderNo, Plant, Unit, Department, Section } = current;

      const existingGroup = acc.find(group =>
        group.Contractor === Contractor &&
        group.WorkOrderNo === WorkOrderNo &&
        group.Plant === Plant &&
        group.Unit === Unit &&
        group.Department === Department &&
        group.Section === Section
      );

      if (existingGroup) {
        existingGroup.items.push(current);
        existingGroup.Total = existingGroup.items.length;
      } else {
        acc.push({
          Contractor,
          WorkOrderNo,
          Plant,
          Unit,
          Department,
          Section,
          items: [current],
          Total: 1,
          index: acc.length + 1
        });
      }
      return acc;
    }, []);
  };

  onTotalCellClicked(event: any) {
    const rowData = event.data;
    const groupData = rowData.items;
    this.openPopup(groupData);
  }

  onSubmitData(action: string) {
    const selectedRows = this.gridApi.getSelectedRows();

    if (selectedRows.length === 0) {
      return;
    }
 
    selectedRows.forEach(row => {
      row.status = action;
    });
  }

  openPopup(groupData: any[]) {
    groupData.forEach((item) => {
      const shiftIn = item.ShiftInTime ? item.ShiftInTime.slice(0, 5) : "00:00";
      const shiftOut = item.ShiftOutTime ? item.ShiftOutTime.slice(0, 5) : "00:00";
      const formattedShiftTime = `${shiftIn} - ${shiftOut}`;
      item.FormattedShiftTime = formattedShiftTime;
    });
    this.selectedRowData = groupData;
    this.modalRef = this.modalService.open(this.templateRef, { windowClass: 'regular-attendence-approval-modal' });
  }  


  onSelectionChanged(event) {
    this.selectedRows = event.api.getSelectedRows();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
  }

  getRowNodeId(data: any) {
    return data.id;
  }
}
