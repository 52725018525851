import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GatePassAccociateInformation } from "../gatepass-request-process.model";
import { GatePassRequestsService } from "../../gate-pass-requests.service";
import {CommonService} from '../../../../common/common.service';

@Component({
  selector: 'app-gatepass-job-type',
  templateUrl: './gatepass-job-type.component.html',
  styleUrls: ['./gatepass-job-type.component.css']
})
export class GatepassJobTypeSelectionComponent implements OnInit {

  @Input() public stepData: any;

  @Output() public handleSave: EventEmitter<GatePassAccociateInformation>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<void>;

  public currentStep: number = 1;
  jobTypes;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public gatepassService: GatePassRequestsService,
    public commonService: CommonService,
  ) {
    this.handleSave = new EventEmitter<GatePassAccociateInformation>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<void>();
    this.formGroup = this.buildFormGroup();
  }

  public ngOnInit(): void {
    this.commonService.getJobTypes().subscribe((response)=>{
      this.jobTypes = response[0].Values;
      if (this.jobTypes.length === 1) {
        this.formGroup.controls['jobType'].setValue(this.jobTypes[0].MasterItem_Code);
      }
    });

    this.gatepassService.getRequestTypeText(this.stepData);
    if (this.stepData) {
      this.formGroup.patchValue({ ...this.stepData });
    }
  }

  public onNextAction(): void {
    this.handleSave.emit(this.formGroup.getRawValue());
  }

  public onPrevAction(): void {
    this.handlePrevAction.emit();
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  private buildFormGroup(): FormGroup {
    return this.fb.group({
      jobType: ['']
    })
  }

}
