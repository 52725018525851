// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: "http://localhost:8080/API/",
  BASE_URL_LOGIN: 'http://localhost:8080/',
  PHOTO_URL: 'http://localhost:8080/API/Documents/Photos/LabourPhotos/',
  DOC_URL: 'http://localhost:8080/API/Documents/TaskDocuments/',
  encryptSecretKey: "407708e72dc5113cb7e442f697033f69637510553aeb1db49eda6e8b48085930",
  ForceApiKey: "ulOiHF#ISNndbza6F55KBQU!Kk35vzMe4QYYMAiIPVL&am+Zzxy6$Ne57wNK6nVBhNiT2ld-JE7f5F8zfrgfKw172J*4iD+fh7VPGtM1NoG*DQC%H=yiGobTBp;9v$vO14SK",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
