import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractorsService } from '../contractors.service';
import { DatePipe } from '@angular/common';
import { AgNumberCountComponent } from 'src/app/core/components';
import { GLOBAL } from 'src/app/app.globals';

@Component({
  selector: 'app-contractor-details',
  templateUrl: './contractor-details.component.html',
  styleUrls: ['./contractor-details.component.css']
})
export class ContractorDetailsComponent implements OnInit {
  date = new DatePipe('en-US');
  @Input() data: any;
  @Input() contractorType: number;
  @Input() states: any;
  contractors: any;
  purchaseDetails: Array<any> = [];
  esicColDefs: any[] = [];
  subContractorDetails: Array<any> = [];
  licenseDetails: Array<any> = [];
  ecpolicyDetails: Array<any> = [];
  showGrid:boolean = false;
  @Output() public handleClose: EventEmitter<void> = new EventEmitter();
 
  documentColDefs: any[] =[];
  licenseColDefs: any[] =  [];
  ecpolicyColDefs: any[] =[];
  purchaseColDefs: any[] = [
    {
      headerName: "#",
      field: '',
      width: 5,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: "WorkOrder No",
      field: "WorkOrderNo",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Order Start Date",
      field: "OrderStartDate",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      valueFormatter: params => { return this.date.transform(params.value, 'short') }
    },
    {
      headerName: "Order End Date",
      field: "OrderEndDate",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      valueFormatter: params => { return this.date.transform(params.value, 'short') }
    },
    {
      headerName: "Person",
      field: "Person",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
  ];

  subContractorColDefs: any[] = [
    {
      headerName: "Vendor Code",
      field: "Vendor Code",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Sub Contractor",
      field: "Sub Contractor",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Email ID",
      field: "EmailID",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "PAN Number",
      field: "PAN Number",
      width: 10,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    }
  ];
  constructor(
    private contractorsService: ContractorsService) { }

  async ngOnInit() {    
    this.setESICCol();
   this.setLicenseCol();
    this.setEcpolicyCol();
   await  this.contractorsService.getContractorByID(this.data.ContractorVendor_Code, JSON.parse(localStorage.getItem('Role'))[0].Role_Code)
    .subscribe((res: any) => {
      this.contractors = res.Data;
     if(this.contractors){
      const res = {
        'Page_Code': 190,
        'RequireType': 0,
        'ActionType': 0,
        'UserId': GLOBAL.USER_ID
      }
        this.contractorsService.getDocumentTypesPageModuleWise(res).subscribe((res:any) => {
        this.contractors.DocumentImageMasterViews.map( function( row ) {
          let sendData = res.filter((x: any) => x.DocumentType_Code == row.DocumentTypeMasterList_Code);
          if(sendData.length > 0){
           
            let filename = row.UploadFileName;
            let iconContent='';
            if(filename != null || filename != ''){
              let fileType = filename.substring(filename.lastIndexOf('.') + 1);
             
              if (fileType === 'pdf') {
                  iconContent = '<i class="fa fa-file-pdf-o"></i>';
              }
              else if(fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
                iconContent = '<i class="fa fa-image"></i>';
              }
              else if (fileType === 'doc' || fileType === 'docx') {
                iconContent = '<i class="fa fa-file"></i>';
              } else if (fileType === 'xls' || fileType === 'xlsx') {
                iconContent = '<span class="icon-saD-doc-xlsx"></span>';
              } else {
                iconContent = '';
              }
              //return iconContent;
            }
            row.filesize = '-';
            return row.doc_type = '<a href="'+ GLOBAL.BASE_URL_DOC + row.ParentRootFolder+'" target="_blank">'+iconContent+' '+sendData[0].Name+'</a>';
          }
        });
        this.showGrid = true;
        this.setDocumentCol();
      });
     }
      });
   
   
    await this.contractorsService.getMultiTableList(this.data.ContractorVendor_Code, this.contractorType).subscribe((res: any) => {
      this.purchaseDetails = res.Table1;
      this.subContractorDetails = res.Table2;
      this.licenseDetails = res.Table3;
      this.ecpolicyDetails = res.Table4;
    });

  }
  setDocumentCol(){
    this.documentColDefs = [
      {
        headerName: "#",
        field: '',
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: "Document Type",
        field: "doc_type",
        width: 150,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "left" },
        cellRenderer: this.gethtmlcontent
      },
      {
        headerName: "Size",
        field: "filesize",
        width: 100,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
       // cellRenderer: this.getFileIcon
      },
    ];
  }
  setESICCol(){
    this.esicColDefs = [
      {
        headerName: "#",
        field: '',
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: "State",
        field: "State_Code",
        width: 65,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        //cellRenderer: function (params) { return this.getStateName(params.value);},
        cellRenderer: this.getStateName
      },
      {
        headerName: "ESIC Code",
        field: "RegistrationNumber",
        width: 30,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" }
      }
    ];
  }
  gethtmlcontent(param){
    return '<div>'+param.value+'</div>';
  }
  getStateName(stateCode){
      let stateList = JSON.parse(localStorage.getItem('stateList'));
      if(stateCode.value != null){
        let statename='';
        stateList.forEach((state) => {
          if (state.State_Code == stateCode.value) {
            statename = state.StateName;
          }
          });
        return statename;
      }else{
        return '';
      }
  }

  getFileIconWithfiletype(filename){
    if(filename != null || filename != ''){
      let fileType = filename.substring(filename.lastIndexOf('.') + 1);
      let iconContent='';
      if (fileType === 'pdf') {
          iconContent = '<i class="fa fa-file-pdf-o"></i>';
      }
      else if(fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        iconContent = '<i class="fa fa-image"></i>';
      }
      else if (fileType === 'doc' || fileType === 'docx') {
        iconContent = '<i class="fa fa-file"></i>';
      } else if (fileType === 'xls' || fileType === 'xlsx') {
        iconContent = '<span class="icon-saD-doc-xlsx"></span>';
      } else {
        iconContent = '';
      }
      return iconContent;
    }else{
      return '';
    } 
  }
  setLicenseCol(){
    this.licenseColDefs = [
      {
        headerName: "#",
        field: '',
        width: 5,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: "License No",
        field: "LicenseNo",
        width: 20,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" }
      },
      {
        headerName: "Star Date",
        field: "IssueDate",
        width: 20,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        //valueFormatter: params => { return this.date.transform(params.value, 'short') }
      },
      {
        headerName: "End Date",
        field: "ValidityDate",
        width: 20,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" },
        //valueFormatter: params => { return this.date.transform(params.value, 'short') }
      },
      {
        headerName: "Person",
        field: "Person",
        width: 20,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" }
      },
      {
        headerName: "Plant",
        field: "Plant",
        width: 15,
        sortable: false,
        suppressMenu: true,
        cellStyle: { "text-align": "center" }
      },
    ];
  }
  

setEcpolicyCol(){
	this.ecpolicyColDefs= [
    {
      headerName: "#",
      field: '',
      width: 5,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: "ECPolicy No",
      field: "ECPolicyNo",
      width: 20,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Star Date",
      field: "IssueDate",
      width: 20,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      //valueFormatter: params => { return this.date.transform(params.value, 'short') }
    },
    {
      headerName: "End Date",
      field: "ValidityDate",
      width: 20,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" },
      //valueFormatter: params => { return this.date.transform(params.value, 'short') }
    },
    {
      headerName: "Person",
      field: "Person",
      width: 20,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
    {
      headerName: "Plant",
      field: "Plant",
      width: 15,
      sortable: false,
      suppressMenu: true,
      cellStyle: { "text-align": "center" }
    },
  ];
}
  /**
   * To close modal popup
   * @author Sagar Dhrangdhariya
   * @memberof ContractorDetailsComponent
   */
  public onCloseAction() {
    this.handleClose.emit();
  }
}
