import { Component, OnInit, AfterViewInit, ViewChild, TemplateRef, HostListener } from '@angular/core';
import {
  BackdropColor,
  ModalConfig,
  ModalSize,
  ModelWindowColor,
  ModelDialogClass,
} from '../core/components/select-steps/select-steps-model/select-steps.model';
import { ModalTemplate, columnDefs, gridData } from './dashboard.model';
import { GetTemplateDirective } from '../core/directives/get-template.directive';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SharedService } from '../core/services';
import { DataSource } from '@angular/cdk/collections';
import { DashboardService } from './dashboard.service';
import { PieChartService } from './charts/pie-chart.service';
import { BarChartService } from './charts/bar-chart.service';
import { GroupBarChartService } from './charts/group-bar-chart.service';
import { DrilldownChartService } from './charts/drilldown-chart.service';
import { RowChartService } from './charts/row-chart.service';
import { IndiaMapService } from './charts/india-map.service';
import { LineChartService } from './charts/line-chart.service';
import { MenuSelectChartService } from './charts/menu-select-chart.service';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { GLOBAL } from '../app.globals';
import { ApiCallService } from '../service/api-call.service';

declare var d3: any;
declare var crossfilter: any;
declare var dc: any;
declare var $: any;

const heightOfContainer = 500;
const legendHeight = 150;
const legendY = heightOfContainer - legendHeight;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dispatch;

  oldChartWidth = 0;
  oldChartHeight = 0;

  // barPlantwiseRequirementvsShortageChart;
  barAgeWiseDeistributionChart;
  barSkillWiseShortagesChart;
  barNatureofworkBudgetvsActualCost;
  barUnitWisevsActualCost;
  barMonthwiseBudgetvsActualCost;
  barFunctionWisevsActualCost;
  // barSiteWiseShortageForTheDayChart;
  // rowSiteWisePresentStatus;
  // rowPlantWisePresentStatus;
  barPlantWiseRequirementVsShortageReq;
  pieSiteWiseNonCompliancePunchingChart;
  rowNonCompliancePunching;
  pieFuncationWisePrensentStatusChart;
  spanTotalMale;
  spanTotalFemale;
  spanTotalMaleMS;
  spanTotalFemaleMS;
  rowStateWiseDeistributionChart;
  pieDomicileStatisticChart;
  pieCastWiseDeistributionChart;
  pieRelegionWiseDeistributionChart;
  pieNatureofWorkWiseMPChart;
  rowDistricWiseDeistributionChart;
  pieSkillWiseDeistributionChart;
  pieWageCriteriaChart;
  pieQulificationDistributionChart;
  pieWorkSiteDistributionChart;
  pieWorkCatDistributionChart;
  barMonthlyBudgetVSActual;
  barSiteWiseBudgetVsActual;
  barFunctionWiseBudgetVSActual;
  barQuarterWiseBudgetVsActual;
  // barPlantWiseBudgetVsActual;
  // barUnitWiseBudgetVsActual;
  barNatureofworkWiseBudgetVsActual;
  rowContractorWiseContinuesWorkingChart;
  rowShiftWisePresentStatus;
  barCountVsOverOTChart;
  rowAbsentisumChart;
  pieTotalRequirementVsShortageChart;
  drillDownSiteWiseShortageForTheDayChart;
  drillDownPlantWisePresentStatus;
  drillDownPlantWiseBudgetVsActual;
  showDataTable = false;
  dataTableChart;
  dataTableDimension;
  dataTableDimensionBudget;

  lineBudgetvsActual;
  lineBudgetManpowervsActual;

  menuselectCategory;
  menuselectUnit;
  userId;

  constructor(
    private dashboardService: DashboardService,
    private pieChartService: PieChartService,
    private barChartService: BarChartService,
    private groupBarChartService: GroupBarChartService,
    private drilldownChartService: DrilldownChartService,
    private rowChartService: RowChartService,
    private indiaMapService: IndiaMapService,
    private lineChartService: LineChartService,
    private menuSelectChartService: MenuSelectChartService,
    private cookieService: CookieService,
    public sharedService: SharedService,
    public apiCall: ApiCallService
  ) {
    this.userId = Number(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserID'));
  }

  ngOnInit() {
    this.dispatch = d3.dispatch(
      'drillDownSiteWiseShortageForTheDayChartBack',
      'drillDownSiteWiseShortageForTheDayChartReset',
      'drillDownPlantWisePresentStatusBack',
      'drillDownPlantWisePresentStatusReset',
      'drillDownPlantWiseBudgetVsActualBack',
      'drillDownPlantWiseBudgetVsActualReset',
    );
    this.getAllDataSet();
    this.getAllDataSetBudget();
  }

  getAllDataSet() {
    const currentDate = moment(new Date()).format(this.sharedService.dateOnlyPickerSettings.requestFormat)
    const reqObj = {
      FDATE: currentDate,
      TDATE: currentDate,
      UserID: GLOBAL.USER_ID,
      Flag: 2,
    };
    this.apiCall.getAllDataSet(reqObj).subscribe(res => this.bindChart(res));
  }
  getAllDataSetBudget() {
    this.dashboardService.getAllDataSetBudget(this.userId, '', '').subscribe(res => this.bindChartbud(res));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeChart();
  }

  bindChart(dataReal) {
    const ndx1 = crossfilter(dataReal);
    const uLCWiseGroup = [];
    $.each(dataReal, (key: any, val: any) => {
      if (uLCWiseGroup.length === 0) {
        uLCWiseGroup.push({
          ULC: val.ULC,
          Gender: val.Gender,
          State: val.State,
          Cast: val.Cast,
          Domicile: val.Domicile,
          Religion: val.Religion,
          NatureofWork: val.NatureofWork,
          District: val.District,
          Skill: val.Skill,
          Age: val.Age,
          TotalReq: val.TotalReq,
          ActualDeployed: val.ActualDeployed,
          TotalShortage: val.TotalShortage
        });
      } else {
        const found = uLCWiseGroup.some(el => el.ULC === val.ULC);
        if (!found) {
          uLCWiseGroup.push({
            ULC: val.ULC,
            Gender: val.Gender,
            State: val.State,
            Cast: val.Cast,
            Domicile: val.Domicile,
            Religion: val.Religion,
            NatureofWork: val.NatureofWork,
            District: val.District,
            Skill: val.Skill,
            Age: val.Age,
            TotalReq: val.TotalReq,
            ActualDeployed: val.ActualDeployed,
            TotalShortage: val.TotalShortage
          });
        }
      }
    });
    const maleData = $.grep(uLCWiseGroup, (v: any) => {
      return v.Gender === 'Male' || v.Gender === 'MALE';
    });
    const malendx1 = crossfilter(maleData);
    const femaleData = $.grep(uLCWiseGroup, (v: any) => {
      return v.Gender === 'Female' || v.Gender === 'FEMALE';
    });
    const femalendx1 = crossfilter(femaleData);
    const goaStateData = $.grep(uLCWiseGroup, (v: any) => {
      return v.State === 'GOA';
    });
    const nonComplianceData = $.grep(dataReal, (v: any) => {
      return v.NonComplianceStatus != null && v.NonComplianceStatus !== '';
    });
    const ndxGoa = crossfilter(goaStateData);
    const uLCWisendx = crossfilter(uLCWiseGroup);
    const nonComplndx = crossfilter(nonComplianceData);

    this.dashboardService.getIndiaJson().subscribe((res) => {
      this.indiaMapService.generatemap(ndx1, res);
    });

    // tslint:disable-next-line:max-line-length
    // this.barPlantwiseRequirementvsShortageChart = this.getGroupBarChart(ndx1, '#barPlantwiseRequirementvsShortageChart', 'PlantName', 'Plant', 'Shortage', 300, 180);

    this.barNatureofworkBudgetvsActualCost = this.groupBarChartService.getGroupBarChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barNatureofworkBudgetvsActualCost',
      groupProperty: 'NatureofWork',
      width: 1000,
      height: 200,
      xLabel: 'Nature of Work',
      yLabel: 'Shortage',
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.barUnitWisevsActualCost = this.groupBarChartService.getGroupBarChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barUnitWisevsActualCost',
      groupProperty: 'UnitName',
      width: 800,
      height: 200,
      xLabel: 'Unit',
      yLabel: 'Shortage',
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.barMonthwiseBudgetvsActualCost = this.groupBarChartService.getGroupBarChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barMonthwiseBudgetvsActualCost',
      groupProperty: 'PayMonth',
      width: 390,
      height: 200,
      xLabel: 'Month',
      yLabel: 'Shortage',
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.barFunctionWisevsActualCost = this.groupBarChartService.getGroupBarChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barFunctionWisevsActualCost',
      groupProperty: 'WorkFunction',
      width: 300,
      height: 200,
      xLabel: 'Function',
      yLabel: 'Shortage',
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    // tslint:disable-next-line:max-line-length
    // this.barSiteWiseShortageForTheDayChart = this.getGroupBarChart(ndx1, '#barSiteWiseShortageForTheDayChart', 'WorkSite', 'Site', 'Shortage', 800, 180);

    this.barSkillWiseShortagesChart = this.groupBarChartService.getGroupBarChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barSkillWiseShortagesChart',
      groupProperty: 'Skill',
      width: 300,
      height: 200,
      xLabel: 'Skill',
      yLabel: 'Shortage',
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    // this.rowSiteWisePresentStatus = this.getGroupBarChartP(ndx1, '#rowSiteWisePresentStatus', 'Site', 'Site', 'Shortage', 650, 180);
    // tslint:disable-next-line:max-line-length
    // this.rowPlantWisePresentStatus = this.getGroupBarChartP(ndx1, '#rowPlantWisePresentStatus', 'PlantName', 'Plant', 'Shortage', 800, 180);

    this.barPlantWiseRequirementVsShortageReq = this.groupBarChartService.getGroupBarChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barPlantWiseRequirementVsShortageReq',
      groupProperty: 'PlantName',
      width: 300,
      height: 200,
      xLabel: 'Plant',
      yLabel: 'Shortage',
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    // Start Attendance
    this.rowContractorWiseContinuesWorkingChart = this.rowChartService.getRowChartContractorWiseChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#rowContractorWiseContinuesWorkingChart',
      groupByProperty: null,
      reduceProperty: null,
      width: 390,
      height: 280,

      gap: 4,
      yAxisLabel: 'Contractors',
      elasticY: true,
    });

    this.rowShiftWisePresentStatus = this.pieChartService.getRowChartShiftWisePresentStatus({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#rowShiftWisePresentStatus',
      groupByProperty: 'Shift',
      reduceProperty: null,
      width: 220,
      height: 200,
    });

    this.pieSiteWiseNonCompliancePunchingChart = this.pieChartService.getPieChart({
      crossfilter: nonComplndx,
      filterGroup: 'group1',
      chartId: '#pieSiteWiseNonCompliancePunchingChart',
      groupByProperty: 'Site',
      reduceProperty: 'NonComplianceStatus',
      width: 220,
      height: 200,
    });

    this.rowNonCompliancePunching = this.rowChartService.getRowChart({
      crossfilter: nonComplndx,
      filterGroup: 'group1',
      chartId: '#rowNonCompliancePunching',
      groupByProperty: 'NonComplianceStatus',
      reduceProperty: 'ULC',
      width: 240,
      height: 200,

      gap: 4,
      yAxisLabel: 'Non Compliance',
      xAxisLabel: '',
      elasticY: true,
    });

    this.barCountVsOverOTChart = this.barChartService.getBarCountVsOverOTChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#barCountVsOverOTChart',
      groupByProperty: null,
      reduceProperty: null,
      width: 240,
      height: 180,

      gap: 4,
      yAxisLabel: 'Count',
      xAxisLabel: 'OT',
      elasticX: true,
      elasticY: true,
    });

    this.rowAbsentisumChart = this.barChartService.getRowAbsentisumChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#rowAbsentisumChart',
      groupByProperty: null,
      reduceProperty: null,
      width: 240,
      height: 180,

      gap: 4,
      yAxisLabel: 'Absent',
      xAxisLabel: 'Day',
      elasticX: true,
      elasticY: true,
    });

    // this.pieTotalRequirementVsShortageChart = this.getPieTotalRequirementVsShortageChart(ndx1);
    this.pieChartService.getPartitionPieChart(ndx1);

    this.pieFuncationWisePrensentStatusChart = this.pieChartService.getPieChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieFuncationWisePrensentStatusChart',
      groupByProperty: 'WorkFunction',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,

      legend: true,
      legendValue: true,
      legendPerc: true,
      label: true,
      labelValue: true,
      labelPerc: true,
      // redius?: number;
      innerRadius: 30,
      // cx?: number;
      // cy?: number;
      slicesCap: 4,
      externalLabels: true,
      externalRadiusPadding: 30,
      renderLabel: true,
    });

    this.spanTotalMale = this.getCountChartGender(malendx1, '#spanTotalMale', true, false);
    this.spanTotalFemale = this.getCountChartGender(femalendx1, '#spanTotalFemale', false, true);
    this.spanTotalMaleMS = this.getCountChartGender(malendx1, '#spanTotalMaleMS', true, false);
    this.spanTotalFemaleMS = this.getCountChartGender(femalendx1, '#spanTotalFemaleMS', false, true);
    // this.getGroupBarChart(ndx1, '#rowSiteWisePrensentStatusChart', 'PayMonth', 'Month', 'Shortage', 390, 400);
    // End Attendance

    // Start Manpower
    this.rowStateWiseDeistributionChart = this.rowChartService.getRowChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#rowStateWiseDeistributionChart',
      groupByProperty: 'State',
      reduceProperty: 'ULC',
      width: 390,
      height: 590,

      gap: 4,
      yAxisLabel: 'States',
      xAxisLabel: '',
      elasticY: true,
    });

    this.pieDomicileStatisticChart = this.pieChartService.getPieChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieDomicileStatisticChart',
      groupByProperty: 'Domicile',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.pieCastWiseDeistributionChart = this.pieChartService.getPieChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieCastWiseDeistributionChart',
      groupByProperty: 'Cast',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.pieRelegionWiseDeistributionChart = this.pieChartService.getPieChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieRelegionWiseDeistributionChart',
      groupByProperty: 'Religion',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.pieNatureofWorkWiseMPChart = this.pieChartService.getPieChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieNatureofWorkWiseMPChart',
      groupByProperty: 'NatureofWork',
      reduceProperty: 'ULC',
      width: 250,
      height: 250,
    });

    this.rowDistricWiseDeistributionChart = this.rowChartService.getRowChart({
      crossfilter: ndxGoa,
      filterGroup: 'group1',
      chartId: '#rowDistricWiseDeistributionChart',
      groupByProperty: 'District',
      reduceProperty: 'ULC',
      width: 240,
      height: 180,

      gap: 4,
      yAxisLabel: 'Districts',
      xAxisLabel: '',
      elasticY: true,
    });

    this.pieSkillWiseDeistributionChart = this.pieChartService.getPieChart({
      crossfilter: ndxGoa,
      filterGroup: 'group1',
      chartId: '#pieSkillWiseDeistributionChart',
      groupByProperty: 'Skill',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.barAgeWiseDeistributionChart = this.barChartService.getBarAgeWiseDeistributionChart({
      crossfilter: uLCWisendx,
      filterGroup: 'group1',
      chartId: '#barAgeWiseDeistributionChart',
      groupByProperty: null,
      reduceProperty: null,
      width: 240,
      height: 180,

      gap: 4,
      yAxisLabel: 'Man Power',
      xAxisLabel: 'Age',
      elasticX: true,
      elasticY: true,
      // margins: {
      //   top: 20,
      //   right: 10,
      //   bottom: 30,
      //   left: 40
      // }
    });

    this.pieWageCriteriaChart = this.pieChartService.getPieChart({
      crossfilter: uLCWisendx,
      filterGroup: 'group1',
      chartId: '#pieWageCriteriaChart',
      groupByProperty: 'Skill',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.pieQulificationDistributionChart = this.pieChartService.getPieChartQualificationNotNull({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieQulificationDistributionChart',
      groupByProperty: 'Qulification',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.pieWorkSiteDistributionChart = this.pieChartService.getPieChartQualificationNotNull({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieWorkSiteDistributionChart',
      groupByProperty: 'WorkSite',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.pieWorkCatDistributionChart = this.pieChartService.getPieChartQualificationNotNull({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#pieWorkCatDistributionChart',
      groupByProperty: 'WorkCategory',
      reduceProperty: 'ULC',
      width: 220,
      height: 200,
    });

    this.barChartService.getRowNonDomicileStateWiseDistributionChart({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#rowNonDomicileStateWiseDistributionChart',
      groupByProperty: 'State',
      reduceProperty: null,
      width: 740,
      height: 180,

      gap: 4,
      yAxisLabel: 'Non-Domicile',
      xAxisLabel: 'Count',
      elasticX: true,
      elasticY: true,
    });

    this.drillDownSiteWiseShortageForTheDayChart = this.drilldownChartService.getDrillDownChartRequirementShortage({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#drillDownSiteWiseShortageForTheDayChart',
      width: 800,
      height: 200,
      dispatch: this.dispatch,
      margins: {
        left: 50,
        top: 0,
        right: 50,
        bottom: 50
      },
    });

    this.drillDownPlantWisePresentStatus = this.drilldownChartService.getDrillDownChartRequirementDeployed({
      crossfilter: ndx1,
      filterGroup: 'group1',
      chartId: '#drillDownPlantWisePresentStatus',
      width: 1000,
      height: 200,
      dispatch: this.dispatch,
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.dataTableDimension = ndx1.dimension((d) => { return 'All'; });
    // this.getD3pieChart();
  }
  bindChartbud(dataReal) {
    const ndx1 = crossfilter(dataReal);
    this.dataTableDimensionBudget = ndx1.dimension((d) => { return 'All'; });

    this.barMonthlyBudgetVSActual = this.groupBarChartService.getGroupBarChart1({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#barMonthlyBudgetVSActual',
      groupProperty: 'Month',
      width: 500,
      height: 200,
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.barSiteWiseBudgetVsActual = this.groupBarChartService.getGroupBarChart1({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#barSiteWiseBudgetVsActual',
      groupProperty: 'State',
      width: 500,
      height: 200,
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.barFunctionWiseBudgetVSActual = this.groupBarChartService.getGroupBarChart1({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#barFunctionWiseBudgetVSActual',
      groupProperty: 'Function',
      width: 500,
      height: 200,
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.barQuarterWiseBudgetVsActual = this.groupBarChartService.getGroupBarChart1({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#barQuarterWiseBudgetVsActual',
      groupProperty: 'Month',
      width: 500,
      height: 200,
      isQuarter: true,
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    // this.barPlantWiseBudgetVsActual = this.getGroupBarChart1(ndx1, '#barPlantWiseBudgetVsActual', 'Plant', '', '', 500, 200);
    // this.barUnitWiseBudgetVsActual = this.getGroupBarChart1(ndx1, '#barUnitWiseBudgetVsActual', 'Unit', '', '', 500, 200);

    this.barNatureofworkWiseBudgetVsActual = this.groupBarChartService.getGroupBarChart1({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#barNatureofworkWiseBudgetVsActual',
      groupProperty: 'NatureofWork',
      width: 1200,
      height: 200,
      margins: {
        left: 50,
        top: 50,
        right: 50,
        bottom: 50
      },
    });

    this.drillDownPlantWiseBudgetVsActual = this.drilldownChartService.getDrillDownPlantWiseBudgetVsActual({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#drillDownPlantWiseBudgetVsActual',
      width: 1000,
      height: 200,
      dispatch: this.dispatch,
      margins: {
        left: 50,
        top: 0,
        right: 50,
        bottom: 50
      },
    });

    this.menuselectUnit = this.menuSelectChartService.getMenuSelect({
      chartId: '#menuselectUnit',
      filterGroup: 'group2',
      crossfilter: ndx1,
      groupByProperty: 'Unit'
    });

    this.menuselectCategory = this.menuSelectChartService.getMenuSelect({
      chartId: '#menuselectCategory',
      filterGroup: 'group2',
      crossfilter: ndx1,
      groupByProperty: 'Function'
    });

    this.lineBudgetvsActual = this.lineChartService.getLineChartMonthWise({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#lineBudgetvsActual',
      groupByProperty: 'Month',
      reduceProperty: 'Budget',
      reduceProperty2: 'Actual',
      width: 400,
      height: 200,
      margins: {
        top: 20,
        right: 10,
        bottom: 30,
        left: 40
      }
    });

    this.lineBudgetManpowervsActual = this.lineChartService.getLineChartMonthWise({
      crossfilter: ndx1,
      filterGroup: 'group2',
      chartId: '#lineBudgetManpowervsActual',
      groupByProperty: 'Month',
      reduceProperty: 'Requirment',
      reduceProperty2: 'Present',
      width: 400,
      height: 200,
      margins: {
        top: 20,
        right: 10,
        bottom: 30,
        left: 40
      }
    });
    // Start Attendance
  }

  resizeChart() {
    // dc.chartRegistry.list().forEach((chart) => {
    //   if (chart.root().node()) {
    //     const bbox = chart.root().node().parentNode.getBoundingClientRect();
    //     chart.width(bbox.width).height(bbox.height).render();
    //     chart.width(bbox.width).render();
    //   }
    // });
  }

  getCountChartGender(ndx1, chartId, isMale, isFemale) {
    const chart = dc.numberDisplay(chartId);
    const dimension = ndx1.dimension((d) => {
      if (isMale) {
        return d.ULC && (d.Gender === 'Male' || d.Gender === 'MALE');
      }

      if (isFemale) {
        return d.ULC && (d.Gender === 'Male' || d.Gender === 'MALE');
      }

      return d.ULC;
    });

    const group = dimension.group().reduceCount((d) => {
      return d.Gender;
    });

    chart
      .formatNumber(d3.format('.'))
      // .valueAccessor(MaleDimension)
      .group(group);
    chart.render();
    return chart;
  }
  // End Attendance

  resetChartAll() {
    this.dispatchCall('drillDownSiteWiseShortageForTheDayChartReset', this.drillDownSiteWiseShortageForTheDayChart);
    this.dispatchCall('drillDownPlantWisePresentStatusReset', this.drillDownPlantWisePresentStatus);
    this.dispatchCall('drillDownPlantWiseBudgetVsActualReset', this.drillDownPlantWiseBudgetVsActual);

    dc.filterAll('group1');
    dc.renderAll('group1');

    dc.filterAll('group2');
    dc.renderAll('group2');
  }

  resetChart(chart, group?) {
    const group2 = group ? group : 'group1';
    chart.filterAll(group2);
    dc.redrawAll(group2);
  }

  dispatchCall(event, chart) {
    this.dispatch[event](chart);
  }

  showFullscreen($event, chart) {
    if (chart) {
      this.oldChartWidth = chart.width();
      this.oldChartHeight = chart.height();
      chart.width(window.innerWidth - 150).height(window.innerHeight - 150).render();
      const classList: DOMTokenList = $event.target.parentNode.parentNode.parentElement.classList;
      classList.add('fullscreen-chart');
    }
  }

  cancelFullscreen($event, chart) {
    this.showDataTable = false;
    if (this.dataTableChart) {
      $('#dataTableChart').html('');
      this.dataTableChart = null;
    }
    chart.width(this.oldChartWidth).height(this.oldChartHeight).render();
    const classList: DOMTokenList = $event.target.parentNode.parentNode.parentElement.classList;
    classList.remove('fullscreen-chart');
  }

  showDatatable(budgetTable?) {
    this.showDataTable = true;
    if (this.dataTableChart) {
      $('#dataTableChart').html('');
      this.dataTableChart = null;
    }
    setTimeout(() => {
      if (budgetTable) {
        this.dataTableChart = this.getDataTableChartBudget();
      } else {
        this.dataTableChart = this.getDataTableChart();
      }
    }, 100);
  }

  showChart() {
    if (this.dataTableChart) {
      $('#dataTableChart').html('');
      this.dataTableChart = null;
    }
    this.showDataTable = false;
  }

  getDataTableChart() {
    const group = this.dataTableDimension.group();
    const chart1 = dc.tableview('#dataTableChart', 'group1');
    chart1
      .dimension(this.dataTableDimension)
      .group(group)
      .columns([
        { title: 'FinYear', data: 'FinYear' },
        { title: 'PayMonth', data: 'PayMonth' },
        { title: 'PlantCode', data: 'PlantCode' },
        { title: 'PlantName', data: 'PlantName' },
        { title: 'PlantBudget', data: 'PlantBudget' },
        { title: 'UnitCode', data: 'UnitCode' },
        { title: 'UnitBudget', data: 'UnitBudget' },
        { title: 'DeptCode', data: 'DeptCode' },
        { title: 'TotalBudgetAmt', data: 'TotalBudgetAmt' },
        { title: 'TotalReq', data: 'TotalReq' },
        { title: 'ActualDeployed', data: 'ActualDeployed' },
        { title: 'TotalShortage', data: 'TotalShortage' },
        { title: 'ContractorCode', data: 'ContractorCode' },
        { title: 'Contractor', data: 'Contractor' },
        { title: 'ULC', data: 'ULC' },
        { title: 'LabourName', data: 'LabourName' },
        { title: 'Gender', data: 'Gender' },
        { title: 'Age', data: 'Age' },
        { title: 'Religion', data: 'Religion' },
        { title: 'Cast', data: 'Cast' },
        { title: 'Domicile', data: 'Domicile' },
        { title: 'Cost', data: 'Cost' },
        { title: 'State', data: 'State' },
        { title: 'District', data: 'District' },
        { title: 'NatureofWork', data: 'NatureofWork' },
        { title: 'Skill', data: 'Skill' },
        { title: 'Shift', data: 'Shift' },
        { title: 'PunchDate', data: 'PunchDate' },
        { title: 'AttendanceStatus', data: 'AttendanceStatus' },
        { title: 'NonComplianceStatus', data: 'NonComplianceStatus' },
        { title: 'WorkCategory', data: 'WorkCategory' },
        { title: 'WorkSite', data: 'WorkSite' },
        { title: 'WorkFunction', data: 'WorkFunction' },
        { title: 'Site', data: 'Site' },
        { title: 'WageCritiaria', data: 'WageCritiaria' },
        { title: 'Qulification', data: 'Qulification' },
      ])
      .enableAutoWidth(true)
      .enableColumnReordering(true)
      .enablePaging(true)
      .enablePagingSizeChange(true)
      .enableSearch(true)
      .enableScrolling(false)
      .scrollingOptions({
        scrollY: 'calc(100vh - 250px)',
        scrollCollapse: true,
        deferRender: true,
      })
      .rowId('Id')
      // .showGroups(true)
      // .groupBy('Expt')
      .responsive(true)
      .select(false)
      .fixedHeader(false)
      .buttons(['pdf', 'csv', 'excel', 'print'])
      .sortBy([['PlantName', 'asc']]);
    return chart1;
  }

  getDataTableChartBudget() {
    const group = this.dataTableDimensionBudget.group();
    const chart1 = dc.tableview('#dataTableChart', 'group2');
    chart1
      .dimension(this.dataTableDimensionBudget)
      .group(group)
      .columns([
        { title: 'State', data: 'State' },
        { title: 'Plant', data: 'Plant' },
        { title: 'Unit', data: 'Unit' },
        { title: 'Function', data: 'Function' },
        { title: 'Month', data: 'Month' },
        { title: 'Budget', data: 'Budget' },
        { title: 'NatureofWork', data: 'NatureofWork' },
        { title: 'Actual', data: 'Actual' }
      ])
      .enableAutoWidth(true)
      .enableColumnReordering(true)
      .enablePaging(true)
      .enablePagingSizeChange(true)
      .enableSearch(true)
      .enableScrolling(false)
      .scrollingOptions({
        scrollY: 'calc(100vh - 250px)',
        scrollCollapse: true,
        deferRender: true,
      })
      .rowId('Id')
      // .showGroups(true)
      // .groupBy('Expt')
      .responsive(true)
      .select(false)
      .fixedHeader(false)
      .buttons(['pdf', 'csv', 'excel', 'print'])
      .sortBy([['State', 'asc']]);
    return chart1;
  }
}
