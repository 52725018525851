import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from 'src/app/app.globals';
declare var $: any;
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class COFFService {

  constructor(private http: HttpClient) { }

  // getAllCOFFData() {
  //   const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_COFF_LISTING}`;
  //   const data = {
  //     UserID: GLOBAL.USER_ID,
  //   };
  //   return this.http.post<any>(url, data, httpOptions).pipe(
  //     map((response: any) => response));
  // }

  public approveLabourweekoffchangerequest(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.APPROVE_WEEK_OFF_CHANGE_REQUEST}`;
    return this.http.post(url, data).pipe(
      map((response: any) => response.Data));
  }
  getRoleByUserID() {
    const queryString = $.param({
      UserID: GLOBAL.USER_ID,
    });
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.GET_RoleCodeByUserID}?${queryString}`,
      httpOptions
    );
  }

  deleterecord(data: any) {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.DELETE_RECORD}`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      map((response: any) => response));
  }

  approveddata(iteams: any) {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.APPROVE_RECORD}`;
    return this.http.post<any>(url, iteams, httpOptions).pipe(
      map((response: any) => response));
  }

  public clickapproveddata(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.APPROVE_COFF_DATA}`;
    return this.http.post(url, data).pipe(
      map((response: any) => response.Data));
  }

  public clickrejecteddata(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.REJECTED_COFF_DATA}`;
    return this.http.post(url, data).pipe(
      map((response: any) => response.Data));
  }

  public clickapproveddataprocess(data: any[]): Observable<any[]> {
    const url = `${GLOBAL.APIS.ATTENDANCE_MANAGEMENT.APPROVE_COFF_DATA_PROCESS}`;
    return this.http.post(url, data).pipe(
      map((response: any) => response.Data));
  }
}
