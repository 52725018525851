import { Component, OnInit } from '@angular/core';
import { User } from './login-model';
import { LoginService } from './login.service';
import { Ng4LoadingSpinnerService } from '../core/components';
import { Router } from '@angular/router';
import { SharedService } from '../core/services';
import { GLOBAL } from '../app.globals';
import { CookieService } from 'ngx-cookie-service';
import { NotifyService } from '../common/notify.service';
import { AppQrCodeService } from '../core/components/app-qr-code/app-qr-code.service';
import { LogHelperService } from '../core/services/log-helper.service';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { url } from 'inspector';
import { AuthService } from '../service/auth.service';
import { LocalStorageService } from '../service/local-storage.service';

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User = {
    username: '',
    password: ''
  };
  errorMessage = '';
  cookiesExpireDays = 30;
  captchaResponse: string;
  globaldata = [];
  key: string;
  keyvalue: any;
  // loginForm: FormGroup;
  cookieValue;
  submitButton = false;
  Email: string;
  //url = 'https://stackoverflow.com/questions/65069343/show-user-a-fake-route-in-angular';
  responseMessage: string;

  constructor(
    private loginService: LoginService, private loadingService: Ng4LoadingSpinnerService,
    private router: Router, private sharedService: SharedService,
    private appQrCodeService: AppQrCodeService, private logHelperService: LogHelperService,
    private cookieService: CookieService, private notifyService: NotifyService,
    private http: HttpClient,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) { }

  ngOnInit() {
    // this.loginForm = new FormGroup({
    //   username: new FormControl(''),
    //   password: new FormControl(''),
    // });
    // this.getGlobalData();
    // this.login();  kh 06-04-2021   Role code not find Error after Logout
  }
  // temp commit for check project
  // checkcaptcha() {
  //   if (this.captchaResponse === '' || this.captchaResponse === undefined) {
  //     this.logHelperService.logError({
  //       message: 'Please resolve the captcha!'
  //     });
  //   } else {
  //     this.login();
  //   }
  // }

  login() {
    this.submitButton = true;
    if (!this.user.username || !this.user.password) {
      return false;
    }
    // const qrData = { zip: 54321, uid: 1070711001, city: 'Mumbai', addressLine1: 'Address A', state: 'Maharashtra' };
    // this.appQrCodeService.generateQRCode(JSON.stringify(qrData));
    // tslint:disable-next-line: indent
    this.loginService.login(this.user).subscribe((res: any) => {
      if (res) {
        this.notifyService.setShowSessionExpiry(false);
        // console.log('after login:', res);
        //  this.cookieService.deleteAll(); // for clear cookes when new user login
        /**
         * We'll check later for the set token issue in cookies
         */
        // this.cookieService.set('token', res.access_token, this.cookiesExpireDays);
        localStorage.setItem('token', res.access_token);
        localStorage.setItem('Role', res.Role);
        localStorage.setItem('RoleCode', JSON.parse(res.Role)[0].Role_Code);
        localStorage.setItem('UserEmailId', res.EmailAddress);
        localStorage.setItem('UserID', res.UserId);
        localStorage.setItem('UserName', res.Username);

        this.localStorage.setLocalStorage({ key: 'token', value: res.access_token });
        this.localStorage.setLocalStorage({ key: 'Role', value: res.Role });
        this.localStorage.setLocalStorage({ key: 'RoleCode', value: JSON.parse(res.Role)[0].Role_Code });
        this.localStorage.setLocalStorage({ key: 'UserEmailId', value: res.EmailAddress });
        this.localStorage.setLocalStorage({ key: 'UserID', value: res.UserId });
        this.localStorage.setLocalStorage({ key: 'UserName', value: res.Username });
        // GLOBAL.USER = {};
        // console.log(GLOBAL.USER);
        GLOBAL.USER.ROLE_CODE = Number(JSON.parse(res.Role)[0].Role_Code);
        GLOBAL.USER.LOGGED_IN_USER = Number(res.UserId);
        GLOBAL.USER.EMAIL_ID = String(res.EmailAddress);
        this.cookieValue = `UserID=${GLOBAL.USER.LOGGED_IN_USER}&RoleCode=${GLOBAL.USER.ROLE_CODE}
        &UserEmail=${GLOBAL.USER.EMAIL_ID}&UserName=${res.Username}`;
        this.cookieService.set('OceanSystem', this.cookieValue, this.cookiesExpireDays);
        this.localStorage.setLocalStorage({ key: 'ModuleWisePage', value: res.ModuleWisePage });
        // localStorage.setItem('token', res.access_token);
        localStorage.setItem('ModuleWisePage', res.ModuleWisePage);
        const userObj = {
          FirstName: res.FirstName,
          LastName: res.LastName,
          EmailAddress: res.EmailAddress,
          ModuleWisePage: res.ModuleWisePage,
          Role: res.Role,
          UserId: res.UserId,
          token: res.access_token
        };
        localStorage.setItem('user_data', JSON.stringify(userObj));
        this.localStorage.setLocalStorage({ key: 'user_data', value: JSON.stringify(userObj) });
        this.sharedService.getMenuListData().subscribe((response: any) => {
          let defaultUrl = '';
          const leftBarMenu = response.Data.find(item => item.MenuType === 1);
          this.authService.userPermission(response.Data);
          if (leftBarMenu && leftBarMenu.Menus) {
            const dashboardMenu = leftBarMenu.Menus.find(item => item.ShortCode === 'PG101');
            if (!dashboardMenu) {
              if (leftBarMenu.Menus.length
                && leftBarMenu.Menus[0].subMenus
                && leftBarMenu.Menus[0].subMenus.length
                && leftBarMenu.Menus[0].subMenus[0].PageLink) {
                defaultUrl = leftBarMenu.Menus[0].subMenus[0].PageLink;

              }
            } else if (JSON.parse(this.localStorage.getLocalStorage({ key: 'Role' }))[0].Role_Code == 119) {
              defaultUrl = 'contractor-dashboard';
            } else {
              defaultUrl = 'dash-board-statstics';
            }
            if (defaultUrl) {
              this.router.navigate([defaultUrl]);
              this.loginService.updateOnAuthChange(res);
            } else {
              this.logHelperService.logError({
                message: 'You do not have any access for this app! Please contact to your administrator.'
              })
            }
          }
        });
      }
    }, (error) => {
      if (error.error.error === 'invalid_grant') {
        this.errorMessage = 'Provided Username and password is incorrrect';
      }
      // tslint:disable-next-line: brace-style
      else if (error.error.error === 'Provided username is incorrrect')
      // tslint:disable-next-line: brace-style
      {
        this.errorMessage = 'Provided username is incorrrect ';
      }
      // tslint:disable-next-line: brace-style
      else if (error.error.error === 'Provided username and password is incorrrect')
      // tslint:disable-next-line: brace-style
      {
        this.errorMessage = 'Provided password is incorrrect';
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.errorMessage = 'Invalid API request';
      }
      this.loadingService.hide();
    });
  }

  // resolved(captchaResponse: string) {
  //   console.log(`Resolved captcha with response: ${captchaResponse}`);
  //   this.captchaResponse = captchaResponse;
  // }

  public getGlobalData() {
    this.loginService.getglobaldata().subscribe((response: any) => {
      this.globaldata = response;

      this.keyvalue = this.globaldata.filter(list => list.Code === 'RECAPTCHA101');
      if (this.keyvalue.length === 1) {
        this.key = this.keyvalue[0].Value;
      }
    });
  }

  forgotpwpopup() {
    //const apiUrl = `${GLOBAL.APIS.USER_MANAGEMENT.FORGOT_USER_PASSWORD}` // Replace with your backend API endpoint
    // Send a request to the server with the user's email
    this.loginService.forgotpassword(this.Email, browserWindowEnv.url).subscribe(
      (response: any) => {
        // Handle the response from the server
        this.responseMessage = response.message;
      },
      (error) => {
        // Handle any errors
        console.error('Error sending reset password email:', error);
        this.responseMessage = 'An error occurred while sending the reset password email.';
      }
    );
  }

}