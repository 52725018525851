import { Component, OnInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';
import { LaboursService } from './labours.service';
import {
  AgNumberCountComponent, AgSelectActionCellRendererComponent, SelectStepsComponent,
  AgActionCellRendererComponent
} from '../../core/components';
import {
  ModalConfig,
  BackdropColor,
  ModelDialogClass,
  ModalSize
} from '../../core/components/select-steps/select-steps-model/select-steps.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router  } from '@angular/router';
import { GridApi, GridOptions } from 'ag-grid-community';
import { LabourImportComponent } from '../labour-import/labour-import.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';


@Component({
  selector: 'app-labours',
  templateUrl: './labours.component.html',
  styleUrls: ['./labours.component.css']
})
export class LaboursComponent implements OnInit {
  /** form group */
  public labourDetailsForm: FormGroup;
  public totalcount = 0;
  public esicvalue: string;
  /** to-do */
  public steps: any[];
  public gridOptions: any[];
  displayContractorOnly = false;
  isShowSingleBtn: boolean = false;
  isShowAllBtn: boolean = false;
  @ViewChild(SelectStepsComponent) public templateRef: SelectStepsComponent;
  @ViewChild('templateRef') public templateContent: TemplateRef<any>;
  @ViewChild('uploadPhotoContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  isuploadPhoto: boolean = false
  
  /**
   * This hold the value
   */
  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    size: ModalSize.Medium,
    closeOnOutSideClick: false
  };
  //   private role: number;
  applicability: boolean;
  isContractor = false;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private labourService: LaboursService,
  ) {
    this.gridOptions = [];
    const roleCode = GLOBAL.USER.ROLE_CODE;
    this.isContractor = (roleCode === 119);
    this.columnDefs.find(item => item.field === 'ULC').hide = this.isContractor;
  }
  // let totalcount;
  /*requestPayload = {
    RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    UserId: GLOBAL.USER_ID,
    RequestCode: null,
    startRow: 0,
    endRow: this.totalcount,
    searchText: '',
    Subcontractor: 0
  };*/

  requestPayload = {
    SearchText: null,
    userid: GLOBAL.USER_ID,
    requestcode: null,
    rolecode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    subcontractor: null,
    PageNo: 1,
    RowCountPerPage: 50
  };
  rowData = [];
  columnDefs = [
    {
      headerName: 'Sr No.', field: '', minWidth: 50,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent ,
      pinned: 'left'
    },
    {
      headerName: 'Contractor', field: 'Company', autoHeight: true, cellClass: 'cell-wrap-text',
      minWidth: 190, sortable: false, filter: true, filterParams: { applyButton: true, clearButton: true }
      , pinned: 'left',
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'Request No', field: 'GatePassRequest', minWidth: 80,
      suppressMenu: true, pinned: 'left',
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'Full Name', field: 'FullName', autoHeight: true,  cellClass: 'cell-wrap-text',
      minWidth: 200, sortable: false, filter: true, pinned: 'left',
      filterParams: { applyButton: true, clearButton: true },
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'GatePass No', field: 'GatePassNo', autoHeight: true,  cellClass: 'cell-wrap-text',
      minWidth: 80, sortable: false, filter: true, pinned: 'left',
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'ULC', field: 'ULC', autoHeight: true,  cellClass: 'cell-wrap-text',
      minWidth: 75, sortable: false, filter: true, pinned: 'left',
      filterParams: { applyButton: true, clearButton: true }, hide: this.isContractor
    },

    {
      headerName: 'Gender', field: 'Gender',
      minWidth: 100, suppressMenu: true
    },
    {
      headerName: 'DOB', field: 'DOB', minWidth: 100, sortable: false, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Designation', field: 'TradeName', minWidth: 120, sortable: false, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Plant', field: 'Plant', minWidth: 100,
      suppressMenu: true,
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'Unit', field: 'Unit', minWidth: 100,
      suppressMenu: true
    },
    {
      headerName: 'Department', field: 'Department', minWidth: 120,
      suppressMenu: true
    },
    // {
    //   headerName: 'Section', field: 'Section', minWidth: 320,
    //   suppressMenu: true
    // },
    // {
    //   headerName: 'Section1', field: 'Section1', minWidth: 320,
    //   suppressMenu: true
    // },
    // {
    //   headerName: 'Section2', field: 'Section2', minWidth: 320,
    //   suppressMenu: true
    // },
    {
      headerName: 'Wage Criteria', field: 'strESICWageCriteria', minWidth: 120,
      sortable: false, filter: true,
      filterParams: { applyButton: true, clearButton: true },
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'ESIC No', field: 'ESICNo', minWidth: 100,
      suppressMenu: true,
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'PF No', field: 'PFNo', minWidth: 100,
      suppressMenu: true,
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'UAN', field: 'PFUANNo', minWidth: 100,
      suppressMenu: true,
      cellRenderer: function (params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>' },
      tooltip: (params) => params.value,
    },
    {
      headerName: 'Action', field: '', minWidth: 80,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent,
      pinned: 'right', 
      cellRendererParams: {
        isUploadPhoto: true,
      }
    }
    // {
    //   headerName: 'Work Category', field: 'WorkCategory', width: 400,
    //   suppressMenu: true
    // },
    // {
    //   headerName: 'Qualification', field: 'Qualification', width: 350,
    //   suppressMenu: true,
    // },
    // {
    //   headerName: 'Aadhar No', field: 'AadharNo', width: 300,
    //   suppressMenu: true,
    // },
    // {
    //   headerName: 'Birth Date', field: 'DOB', width: 250, sortable: false, filter: true,
    //   filterParams: { applyButton: true, clearButton: true },
    //    cellRenderer: (data) => {
    //       return data.value ? (new Date(data.value)).toLocaleDateString() : '';
    //   }
    // },
  ];
  public gridRowData: any[] = [];
  public gridColumnDefs = [
    {
      headerName: '#', field: '',
      width: 50, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'CCN', field: 'ContractorVendor_Code',
      width: 150, filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Vendor Code', field: 'ShortCode',
      width: 150, filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Contractor / Company', field: 'Company', width: 450, filterParams:
        { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true
    },
    {
      headerName: 'Email Id', field: 'EmailID', width: 450, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'PAN Nbr.', field: 'PANNo', width: 150, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Is Sub-Contractor', field: 'IsContactor', width: 150, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Status', field: 'StatusMasterlist_Code', width: 150, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 70, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: true, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public numberOfRowsPerPage = 10;
  public gridAPI: GridApi;
  public stepsGridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };
 
  ngOnInit() {
   // this.getGlobalSettings();
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
      this.displayContractorOnly = true;
    } else {
      this.displayContractorOnly = false;
    }
    this.getLabours(this.requestPayload);
    this.labourDetailsForm = this.formBuilder.group({
      aadharcard: ['', Validators.required],
      contractorselection: ['', Validators.required],
      applicability: ['', Validators.required],
      labour: ['', Validators.required],
      contractManType: ['', Validators.required],
      nationality: ['', Validators.required],
    });
    this.labourService.globalSettingCode('').subscribe((res:any) => {
      if(localStorage.RoleCode == '119'){
        let isShowSinglebutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELCONSIN');
        this.isShowSingleBtn = isShowSinglebutton[0].Value == "1" ? true : false;

        let isShowAllbutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELCONAll');
        this.isShowAllBtn = isShowAllbutton[0].Value == "1" ? true : false;
      }else{
        let isShowSinglebutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELHRSIN');
        this.isShowSingleBtn = isShowSinglebutton[0].Value == "1" ? true : false;

        let isShowAllbutton = res.Data.Table.filter((i) => i.Code == 'MOD152PG195IMPEXCELHRAll');
        this.isShowAllBtn = isShowAllbutton[0].Value == "1" ? true : false;
      }

    },(err) => {
      console.log("global setting err --->",err)
    });
    this.getAllcontractorData();
    // this.openImportExcel();
    // this.openImportForHRExcel();
    
  }
 
  private setStepArray(): void {
    // if (this.requestPayload.RoleCode === 118) {
    //
    // //   this.steps[0];
    // }
    // let step: any;
    if (JSON.parse(localStorage.getItem('Role'))[0].Role_Code === 119) {
      this.steps = [
        // {
        //   id: 2,
        //   type: 'radio',
        //   name: 'Please Aadhar Number.',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, next: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'aadharcard',
        //   inputs: [
        //     {
        //       label: 'Aadhar Number',
        //       value: 'Aadhar Number'
        //     },
        //     {
        //       label: 'Aadhar Name',
        //       value: 'Aadhar Name'
        //     },
        //   ]
        // },
        // {
        //   id: 3,
        //   type: 'radio',
        //   name: 'Please select Appropriate Aplicability.',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, next: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'applicability',
        //   inputs: [
        //     {
        //       label: 'Wage / Salary < 21000.00',
        //       value: 'Wage / Salary < 21000.00'
        //     },
        //     {
        //       label: 'Wage / Salary ≥ 21000.00)',
        //       value: 'Wage / Salary ≥ 21000.00)'
        //     },
        //   ]
        // },
        // {
        //   id: 4,
        //   type: 'radio',
        //   name: 'Contract man type of category please.',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, prevNext: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'contractManType',
        //   inputs: [
        //     {
        //       label: 'CCL',
        //       value: 'CCL'
        //     },
        //     {
        //       label: 'Computer Operator',
        //       value: 'Computer Operator'
        //     },
        //     {
        //       label: 'Other',
        //       value: 'OTHER'
        //     },
        //   ]
        // },
        // {
        //   id: 5,
        //   type: 'radio',
        //   name: 'Please select Nationality?',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, prevNext: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'nationality',
        //   inputs: [
        //     {
        //       label: 'Indian',
        //       value: 'INDIAN'
        //     },
        //     {
        //       label: 'Foreigner',
        //       value: 'FOREINER'
        //     },
        //   ]
        // },
        {
          id: 6,
          type: 'navigate',
          // routerLink: '/gate-pass-requests',
          routerLink: '/labour/create-sub-labour',
        },
      ];
    } else {
      this.steps = [
        {
          id: 1,
          type: 'grid',
          title: 'Contractor Selection',
          button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
          header: true,
          footer: true,
          columnDef: this.gridColumnDefs,
          rowData: this.gridRowData,
          gridOptions: this.stepsGridOptions,
          formControlName: 'contractorselection'
        },
        // {
        //   id: 2,
        //   type: 'radio',
        //   name: 'Please Aadhar Number.',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, next: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'aadharcard',
        //   inputs: [
        //     {
        //       label: 'Aadhar Number',
        //       value: 'Aadhar Number'
        //     },
        //     {
        //       label: 'Aadhar Name',
        //       value: 'Aadhar Name'
        //     },
        //   ]
        // },
        // {
        //   id: 3,
        //   type: 'radio',
        //   name: 'Please select Appropriate Aplicability.',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, next: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'applicability',
        //   inputs: [
        //     {
        //       label: 'Wage / Salary < 21000.00',
        //       value: 'Wage / Salary < 21000.00'
        //     },
        //     {
        //       label: 'Wage / Salary ≥ 21000.00)',
        //       value: 'Wage / Salary ≥ 21000.00)'
        //     },
        //   ]
        // },
        // {
        //   id: 4,
        //   type: 'radio',
        //   name: 'Contract man type of category please.',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, prevNext: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'contractManType',
        //   inputs: [
        //     {
        //       label: 'CCL',
        //       value: 'CCL'
        //     },
        //     {
        //       label: 'Computer Operator',
        //       value: 'Computer Operator'
        //     },
        //     {
        //       label: 'Other',
        //       value: 'OTHER'
        //     },
        //   ]
        // },
        // {
        //   id: 5,
        //   type: 'radio',
        //   name: 'Please select Nationality?',
        //   title: 'Select Criteria',
        //   button: { skip: false, cancel: false, submit: false, prevNext: true, close: false },
        //   header: true,
        //   footer: true,
        //   formControlName: 'nationality',
        //   inputs: [
        //     {
        //       label: 'Indian',
        //       value: 'INDIAN'
        //     },
        //     {
        //       label: 'Foreigner',
        //       value: 'FOREINER'
        //     },
        //   ]
        // },
        {
          id: 6,
          type: 'navigate',
          // routerLink: '/gate-pass-requests',
          routerLink: '/labour/create-sub-labour',
        },
      ];
    }
  }

  public openModalAction(): void {
    this.templateRef.openModel();
  }

  public onNextEvent(event): void {
    // let applicability: boolean;
    // console.log(event, this.labourDetailsForm);
    // console.log('Tejal', this.labourDetailsForm.controls.applicability.value);
    // let esicvalue: string;
    this.esicvalue = this.labourDetailsForm.controls.applicability.value;
    // if (this.esicvalue !== '') {
    //   this.isESICWageCriteria(this.esicvalue);
    // }
    if (this.labourDetailsForm.controls.applicability.value === 'Wage / Salary < 21000.00') {
      this.applicability = true;
    } else {
      this.applicability = false;
    }

    // else if (this.labourDetailsForm.controls.applicability.value === 'Wage / Salary ≥ 21000.00') {
    //   this.applicability = false;
    // }
    this.labourService.setFormData(this.applicability);
    this.labourService.setData(this.labourDetailsForm);
  }


  // isESICWageCriteria(esicvalue) {
  //   this.labourService.isESICWageCriteria(esicvalue).subscribe((res: any) => {
  //     this.applicability = res;
  //   });

  // }

  // public onPrevEvent(event): void {
  //   console.log(event, 'event');

  // }

  // public onEmitData(data: any): void {
  //   console.log('emitData', data);
  // }

  // public onButton(): void {
  //   console.log('button');
  // }


  /**
   * used to get all the labour data.
   */
  getLabours(request: any) {

    this.labourService.getAllLabours(request).subscribe((response: any) => {
      //this.rowData = response.A;
      this.rowData = response;
      this.totalcount = response.B;
    });
  }
  showModal:boolean = false
  editLabour(rowData) {
    this.router.navigate([`labour/edit-sub-labour/${rowData.Labour_Code}`]);
    this.showModal = false
  //  console.log('edit', rowData);
  }
  
  onUploadPhoto(rowData){
    this.labourService.shareData({ 'function': 'onUploadPhoto', 'data': rowData, 'type': 'selectedLabour'});  
    this.showModal = true
  }

  deleteLabour(rowData) {
    //
  }

  private getAllcontractorData(): void {
    this.labourService.getAllContractorData().subscribe((contractorResponse) => {
      //   this.gridRowData = contractorResponse.Data.Table;
      if (contractorResponse.length > 0) {
        this.gridRowData = contractorResponse;
      }
      if (contractorResponse.Data) {
        this.gridRowData = contractorResponse.Data.Table;
      }
      this.setStepArray();
    });

  }

  public getSelectedRowData(item: any, value: number): void {

    if (item.arrayItem[0].title === 'Select Contractor Selection') {
      // alert('Tejal');
    }
  }

  openImportExcel(): void {
    const modalRef = this.modalService.open(LabourImportComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' },
      );
      modalRef.componentInstance.isShowSingleButton = this.isShowSingleBtn;
      modalRef.componentInstance.isShowAllButton = this.isShowAllBtn;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
    modalRef.componentInstance.save.subscribe(() => {
      modalRef.close('');
      this.getLabours(this.requestPayload);
    });
  }
}
