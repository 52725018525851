import { Component, OnInit, ViewChild, Output, EventEmitter, } from '@angular/core';
import { AgActionCellRendererComponent } from '../../core/components';
import { Router } from '@angular/router';
import { CompanyCommonsMasterService } from '../../company-commons-master/company-commons-master.service';
import * as moment from 'moment';
import { FormGroup, FormControl, AbstractControl, ValidationErrors, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { SharedService } from 'src/app/core/services';
import { GridApi, GridOptions } from 'ag-grid-community';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { PolicyService } from './policy.service';
import { GLOBAL } from 'src/app/app.globals';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};


@Component({
  selector: 'app-list-of-policy',
  templateUrl: './list-of-policy.component.html',
  styleUrls: ['./list-of-policy.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class ListOfPolicyComponent implements OnInit {
  // forcontractorlogin = true;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  @Output() closeModal: EventEmitter<string>;
  @ViewChild('tempRef') public tempRef: any;
  @ViewChild('tempRef1') public tempRef1: any;
  @ViewChild('tempRef2') public tempRef2: any;
  @ViewChild('tempRef3') public tempRef3: any;
  @ViewChild('tempRef4') public tempRef4: any;
  @ViewChild('tempRef5') public tempRef5: any;
  @ViewChild('tempRef6') public tempRef6: any;
  @ViewChild('tempRef7') public tempRef7: any;
  public alertOption: SweetAlertOptions = {};
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public dateTimeRange: Date[];
  groupingMeta = [''];
  varcheck = 1;
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  columnDefs: any;
  columnDefs1: any;
  columnDefs2: any;
  columnDefs3: any;
  columnDefs4: any;
  columnDefs5: any;
  columnDefs6: any;
  columnDefs7: any;
  columnDefs8: any;
  rowData = [];
  rowData1 = [];
  rowData2 = [];
  rowData3 = [];
  rowData4 = [];
  rowData5 = [];
  rowData6 = [];
  rowData7 = [];
  rowData8 = [];
  public modalRef: any;
  save = false;
  update = false;
  shortCode: any;
  shortname: any;
  numberOfRowsPerPage = 10;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  rowClassRules;
  showSpinner: boolean;

  formGridData: any[] = [];
  formGridColumnDefs: any[] = [];

  public earlyGoingPolicyForm: FormGroup;
  public lateInPolicyForm: FormGroup;
  public absentPolicyForm: FormGroup;
  public halfdayPolicyForm: FormGroup;
  public otPolicyForm: FormGroup;
  public coffPolicyForm: FormGroup;
  public lunchPolicyForm: FormGroup;
  public weekoffPolicyForm: FormGroup;

  private buildEarlyGoingPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [''],
      IsDefault: [false],
      IsCompany: [false],
      MaxEarlyOut: ['', [Validators.required]],
      IsActive: [false],
      // IsApplicableEarlyIn: [false],
      MaxEarlyOutAllow: [null]
    }, [this.groupValidator]);
  };

  private buildLateInPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [null],
      IsDefault: [false],
      IsCompany: [false],
      MaxLateOut: [null],
      MaxLateIn: [null],
      MaxLateInAllow: [null],
      MaxLateOutAllow: [null],
      IsActive: [false]
    }, [this.groupValidator]);
  }

  private buildAbsentPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [null],
      IsDefault: [false],
      IsCompany: [false],
      MinTimeForAbsent: [null],
      IsActive: [false],
    }, [this.groupValidator]);
  }

  private buildHalfDayPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [null],
      IsDefault: [false],
      IsCompany: [false],
      MinHalfDayDuration: [null],
      MinTimeForAbsent: [null],
      IsActive: [null],
    }, [this.groupValidator]);
  }

  private buildOTPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [null],
      IsDefault: [false],
      IsCompany: [false],
      IsApplicable: [false],
      MinTimeForOT: [null],
      MaxTimeForOT: [null],
      EarlyCommingAdd: [null],
      IsActive: [false],
    }, [this.groupValidator]);
  }

  private buildCOffPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [null],
      IsDefault: [false],
      IsCompany: [false],
      MinOTRequired: [null],
      ValidityType: [null],
      COFFMinForHalfDay: [null],
      COFFMinForFullDay: [null],
      IsAutoAuthorize: [null],
      IsActive: [false],
    }, [this.groupValidator]);
  }

  private buildLunchPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: ['', [Validators.required]],
      Version: [0],
      IsLabour: [true],
      ValiduptoDate: [''],
      IsDefault: [false],
      IsCompany: [false],
      IsLunchTimeApplicable: [false],
      ConsiderLunchTime: [null],
      AllowBeforeLunchIn: [null],
      AllowAfterLunchIn: [null],
      AllowBeforeLunchOut: [null],
      AllowAfterLunchOut: [null],
      AddBLateInTotalEOut: [null],
      AddBEOutInTotalLateIn: [null],
      IsActive: [false],
    }, [this.groupValidator]);

  }

  private buildWeekOffPolicyFormGroup(): FormGroup {
    return this.fb.group({
      Company_Code: [null, [Validators.required]],
      Plant_Code: [null, [Validators.required]],
      Name: ['', [Validators.required]],
      ShortCode: [{ disabled: true, value: '' }, [Validators.required]],
      ApplicableDate: [null],
      ValiduptoDate: [null],
      Version: [0],
      IsLabour: [true],
      IsDefault: [false],
      IsCompany: [false],
      FirstWeek1: [null],
      FirstWeek2: ['Sunday'],
      IsPresentOTWeek1: [null],
      SecoundWeek1: [null],
      SecoundWeek2: ['Sunday'],
      IsPresentOTSWeek2: [null],
      ThirdWeek1: [null],
      ThirdWeek2: ['Sunday'],
      IsPresentOTTWeek3: [null],
      FourthWeek1: [null],
      FourthWeek2: ['Sunday'],
      IsPresentOTFWeek4: [null],
      FifthWeek1: [null],
      FifthWeek2: ['Sunday'],
      IsPresentOTFTWeek5: [null],
      IsActive: [false],
      // IsPresentOTWeek1: [false],
      // IsPresentOTTWeek1: [false],
      // IsPresentOTFWeek1: [false],
      // IsPresentOTFTWeek1: [false],
    }, [this.groupValidator]);
  }

  public isValidStartDate = false;
  public minDate: Date = new Date();
  public minEndDate: Date;
  public isValidEndDate = false;
  public minComplitedDate: Date;
  masterData = [];
  statementType: any;
  public companyData: any[] = [];
  public plantData: any[] = [];
  editEarlyOutPolicyCode1: string;
  editLateInPolicyCode1: string;
  editAbsentPolicyCode1: string;
  editHalfDayPolicyCode1: string;
  editOTPolicyCode1: string;
  editCOffPolicyCode1: string;
  editLunchPolicyCode1: string;
  editWeekoffPolicyCode1: string;
  show = false;
  editMode = true;
  earlyoutpolicyCode: any;
  lateinpolicycode: any;
  absentpolicycode: any;
  halfdaypolicycode: any;
  otpolicycode: any;
  coffpolicycode: any;
  plantcode: any;

  public stepsGridOptions = <GridOptions>{
    suppressHorizontalScroll: true,
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private service: CompanyCommonsMasterService,
    private policyService: PolicyService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
    private sharedService: SharedService) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.closeModal = new EventEmitter();

    this.buildAllFormGroups();
  }

  ngOnInit() {
    this.varcheck = 1; // defult one pass
    this.getListOfPolicydata(this.varcheck);
    this.getPlantData();
    this.getCompanyData();
    // this.getEarlyOutPolicy();
    // this.getLateInPolicy();
    // this.getAbsentPolicy();
    // this.getHalfDayPolicy();
    // this.getOTPolicy();
    // this.getCOffPolicy();
    // this.getLunchPolicy();
    // this.getWeekoffPolicy();
  }

  private buildAllFormGroups(): void {
    this.earlyGoingPolicyForm = this.buildEarlyGoingPolicyFormGroup();
    this.lateInPolicyForm = this.buildLateInPolicyFormGroup();
    this.absentPolicyForm = this.buildAbsentPolicyFormGroup();
    this.halfdayPolicyForm = this.buildHalfDayPolicyFormGroup();
    this.otPolicyForm = this.buildOTPolicyFormGroup();
    this.coffPolicyForm = this.buildCOffPolicyFormGroup();
    this.lunchPolicyForm = this.buildLunchPolicyFormGroup();
    this.weekoffPolicyForm = this.buildWeekOffPolicyFormGroup();
  }

  getCompanyData() {
    return this.service.getCompanyMasterData().subscribe((res: any) => {
      this.companyData = res && res.Data && res.Data.Table ? res.Data.Table : [];
      if (this.companyData.length === 1) {
        this.earlyGoingPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.lateInPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.absentPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.halfdayPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.otPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.coffPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.lunchPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
        this.weekoffPolicyForm.controls['Company_Code'].setValue(this.companyData[0].Company_Code);
      }
    });
  }

  getPlantData() {
    return this.service.getPlantMasterData().subscribe((res: any) => {
      // this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
      this.plantData = res || [];
      if (this.plantData.length === 1) {
        this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.lateInPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.absentPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.halfdayPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.otPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.coffPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.lunchPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
        this.weekoffPolicyForm.controls['Plant_Code'].setValue(this.plantData[0].Plant_Code);
      }
    });
  }

  getListOfPolicydata(varcheck: any) {

    const check1 = varcheck;
    const model = {
      Check: check1,
      // User_Id: GLOBAL.USER_ID
      User_Id: 142
    };
    this.service.getListOfPolicy(model).subscribe((res: []) => {
      if (res.length > 0) {
        if (check1 === 1) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData = res;
          this.createColumnDef();
          // this.columnDefs = this.createDynamicColumnDef(res);
          console.log();
        } else if (check1 === 2) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData1 = res;
          this.createColumnDef1();
        } else if (check1 === 3) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData2 = res;
          this.createColumnDef2();
        } else if (check1 === 4) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData3 = res;
          this.createColumnDef3();
        } else if (check1 === 5) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData4 = res;
          this.createColumnDef4();
        } else if (check1 === 6) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData5 = res;
          this.createColumnDef5();
        } else if (check1 === 7) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData6 = res;
          this.createColumnDef6();
        } else if (check1 === 8) {
          // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
          this.rowData7 = res;
          this.createColumnDef7();
        }
        // else if (check1 === 9) {
        //   const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
        //   this.rowData8 = tempRowData;
        //   this.createColumnDef8();
        // }
      }
    });
  }

  onTabChange($event) {
    this.getListOfPolicydata($event.nextId);
  }

  getEarlyOutPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // EarlyOutPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MaxEarlyOut: '',
      IsApplicableEarlyIn: '',
      MaxEarlyIn: '',
      MaxEarlyInAllow: '',
      MaxEarlyOutAllow: '',
      StatementType: 'Select'
    };

    this.service.getEarlyGoingPolicy(model).subscribe((response: any[]) => {
      this.rowData = response;

      console.log('xyz', (response));

    });
  }

  getLateInPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // LateInPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MaxLateOut: '',
      IsApplicableLateOut: '',
      MaxLateIn: '',
      MaxLateInAllow: '',
      MaxLateOutAllow: '',
      StatementType: 'Select'
    };

    this.service.getLateInPolicy(model).subscribe((response: any[]) => {


      // return this.service.getUnitwiseDetails().subscribe((response: any) => {
      this.rowData1 = response;



      console.log('xyz', (response));

    });

  }

  getAbsentPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // EarlyOutPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MinTimeForAbsent: '',
      WeekOffSingleSideAbsent: '',
      WeekOffBothSideAbsent: '',
      HolidaySingleSideAbsent: '',
      HolidayBothSideAbsent: '',
      CheckLateIn: '',
      MaxAllowPerMonthLateIn: '',
      IsLateInContinues: '',
      LateInMarkAbsent: '',
      CheckEarlyOut: '',
      MaxAllowPerMonthEarlyOut: '',
      IsEarlyOutContinued: '',
      EarlyOutMarkAbsent: '',
      StatementType: 'Select'
    };

    this.service.getAbsentPolicy(model).subscribe((response: any[]) => {


      // return this.service.getUnitwiseDetails().subscribe((response: any) => {
      this.rowData2 = response;



      console.log('xyz', (response));

    });

  }

  getHalfDayPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // LateInPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MinHalfDayDuration: '',
      MinTimeForAbsent: '',
      WeekOffSufixPrefixAbsent: '',
      HolidaySufixPrefixAbsent: '',
      StatementType: 'Select'
    };

    this.service.getHalfDayPolicy(model).subscribe((response: any[]) => {

      // return this.service.getUnitwiseDetails().subscribe((response: any) => {
      this.rowData3 = response;

      console.log('xyz', (response));

    });

  }

  getOTPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // OTPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      IsApplicable: '',
      MinTimeForOT: '',
      MaxTimeForOT: '',
      EarlyCommingAdd: '',
      StatementType: 'Select'
    };

    this.service.getOTPolicy(model).subscribe((response: any[]) => {

      this.rowData4 = response;

      console.log('xyz', (response));

    });

  }

  getCOffPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // EarlyOutPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      MinOTRequired: '',
      ValidityType: '',
      COFFMinForHalfDay: '',
      COFFMinForFullDay: '',
      IsAutoAuthorize: '',
      StatementType: 'Select'
    };

    this.service.getCOFFPolicy(model).subscribe((response: any[]) => {

      this.rowData5 = response;

      console.log('xyz', (response));

    });
  }

  getLunchPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // LunchPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      IsLunchTimeApplicable: '',
      ConsiderLunchTime: '',
      AllowBeforeLunchIn: '',
      AllowAfterLunchIn: '',
      AllowBeforeLunchOut: '',
      AllowAfterLunchOut: '',
      AddBLateInTotalEOut: '',
      AddBEOutInTotalLateIn: '',
      StatementType: 'Select'
    };

    this.service.getLunchPolicy(model).subscribe((response: any[]) => {

      this.rowData6 = response;
      this.rowData6.forEach((item: any) => {
        item.BeforeAfterIn = `${item.AllowBeforeLunchIn || '-'} | ${item.AllowAfterLunchIn || '-'}`;
        item.BeforeAfterOut = `${item.AllowBeforeLunchOut || '-'} | ${item.AllowAfterLunchOut || '-'}`;
      })
      console.log('xyz', (response));

    });
  }

  getWeekoffPolicy() {
    const model = {
      UserID: GLOBAL.USER_ID,
      // LunchPolicy_Code: '',
      Sys_Code: '',
      Company_Code: '',
      Plant_Code: '',
      Name: '',
      ShortCode: '',
      Version: '',
      IsLabour: '',
      ApplicableDate: '',
      ValiduptoDate: '',
      IsDefault: '',
      IsCompany: '',
      FirstWeek1: '',
      FirstWeek2: '',
      IsPresentOTWeek1: '',
      SecoundWeek1: '',
      SecoundWeek2: '',
      IsPresentOTSWeek2: '',
      ThirdWeek1: '',
      ThirdWeek2: '',
      IsPresentOTTWeek3: '',
      FourthWeek1: '',
      FourthWeek2: '',
      IsPresentOTFWeek4: '',
      FifthWeek1: '',
      FifthWeek2: '',
      IsPresentOTFTWeek5: '',
      StatementType: 'Select'
    };

    this.service.getWeekoffPolicy(model).subscribe((response: any[]) => {
      this.rowData7 = response;
    });
  }

  public openModal(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef,
      { size: 'lg', backdrop: 'static' });
    if (this.editEarlyOutPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.earlyGoingPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.earlyGoingPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }
  public openModal1(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef1,
      { size: 'lg', backdrop: 'static' });
    if (this.editLateInPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;
    }

    if (this.companyData.length === 1) {
      this.lateInPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
    }
    if (this.plantData.length === 1) {
      this.lateInPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
    }
  }
  public openModal2(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef2,
      { size: 'lg', backdrop: 'static' });
    if (this.editAbsentPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.absentPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.absentPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }
  public openModal3(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef3,
      { size: 'lg', backdrop: 'static' });
    if (this.editHalfDayPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.halfdayPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.halfdayPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }
  public openModal4(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef4,
      { size: 'lg', backdrop: 'static' });
    if (this.editOTPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.otPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.otPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }
  public openModal5(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef5,
      { size: 'lg', backdrop: 'static' });
    if (this.editCOffPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.coffPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.coffPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }
  public openModal6(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef6,
      { size: 'lg', backdrop: 'static' });
    if (this.editLunchPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.lunchPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.lunchPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }
  public openModal7(): void {
    // this.clearData();
    this.modalRef = this.modalService.open(this.tempRef7,
      { size: 'lg', backdrop: 'static' });
    if (this.editWeekoffPolicyCode1) {
      this.save = false;
      this.update = true;
    } else {
      this.update = false;
      this.save = true;

      if (this.companyData.length === 1) {
        this.weekoffPolicyForm.get('Company_Code').setValue(this.companyData[0].Company_Code);
      }
      if (this.plantData.length === 1) {
        this.weekoffPolicyForm.get('Plant_Code').setValue(this.plantData[0].Plant_Code);
      }
    }
  }

  public onCloseModel(): void {
    this.modalRef.close('click');
    this.clearData();
    // this.editholidayCode1 = null;
  }

  public onCancel(): void {
    this.modalRef.close('click');
    this.clearData();
    this.save = false;
    this.update = false;
  }

  private clearData() {
    this.earlyGoingPolicyForm.reset();
    // this.earlyGoingPolicyForm.patchValue({ IsLabour: true });

    this.lateInPolicyForm.reset();
    // this.lateInPolicyForm.patchValue({ IsLabour: true });

    this.halfdayPolicyForm.reset();
    // this.halfdayPolicyForm.patchValue({ IsLabour: true });

    this.absentPolicyForm.reset();
    // this.absentPolicyForm.patchValue({ IsLabour: true });

    this.lunchPolicyForm.reset();
    // this.lunchPolicyForm.patchValue({ IsLabour: true });

    this.otPolicyForm.reset();
    // this.otPolicyForm.patchValue({ IsLabour: true });

    this.coffPolicyForm.reset();
    // this.coffPolicyForm.patchValue({ IsLabour: true });

    this.weekoffPolicyForm.reset();
    // this.weekoffPolicyForm.patchValue({ IsLabour: true });
    this.buildAllFormGroups()


    this.formGridData = [];
  }


  private createColumnDef(): void {
    this.columnDefs = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'EarlyOutPolicy_Code', sortable: true,
        width: 30, filter: true, hide: false, cellStyle: { textAlign: 'center' },
      },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 70, filter: true },
      // { headerName: 'ShortCode', field: 'EShortCode', sortable: true, width: 100, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 70, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
      // { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
      { headerName: 'Default Policy', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 50, filter: true },
      // { headerName: 'IsCompany', field: 'IsCompany', sortable: true, width: 70, filter: true },
      { headerName: 'Early Out', field: 'MaxEarlyOut', sortable: true, width: 50, filter: true, cellStyle: { textAlign: 'center' }, },
      // { headerName: 'IsApplicableEarlyOut', field: 'IsApplicableEarlyOut', sortable: true, width: 70, filter: true },
      { headerName: 'Early In', field: 'MaxEarlyIn', sortable: true, width: 50, filter: true, cellStyle: { textAlign: 'center' }, },
      // { headerName: 'MaxEarlyInAllow', field: 'MaxEarlyInAllow', sortable: true, width: 70, filter: true },
      // { headerName: 'MaxEarlyOutAllow', field: 'MaxEarlyOutAllow', sortable: true, width: 70, filter: true },
      {
        headerName: 'Action', field: '', width: 40, headerComponentParams: { enableSorting: false },
        cellStyle: { textAlign: 'center' },
        cellRendererFramework: AgActionCellRendererComponent
      }
    ];
  }

  private createColumnDef1(): void {
    this.columnDefs1 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'LateInPolicy_Code', sortable: true, width: 40, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false
      },
      // { headerName: 'System Code', field: 'Sys_Code', sortable: true, width: 40, filter: true },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 80, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'ShortCode', field: 'LShortCode', sortable: true, width: 100, filter: true },
      // { headerName: 'ShortCode', field: 'ShortCode', sortable: true, width: 80, filter: true },
      // { headerName: 'Name', field: 'Name', sortable: true, width: 80, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 80, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 80, filter: true },
      // { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 80, filter: true },
      // { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 80, filter: true },
      { headerName: 'Default Policy', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 50, filter: true },
      // { headerName: 'IsCompany', field: 'IsCompany', sortable: true, width: 80, filter: true },
      { headerName: 'Late Out', field: 'MaxLateOut', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      // { headerName: 'IsApplicableLateOut', field: 'IsApplicableLateOut', sortable: true, width: 80, filter: true },
      { headerName: 'Late In', field: 'MaxLateIn', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Late In Allow', field: 'MaxLateInAllow', sortable: true, width: 60, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Late Out Allow', field: 'MaxLateOutAllow', sortable: true, width: 60, cellStyle: { textAlign: 'center' }, filter: true },
      {
        headerName: 'Action', field: '', width: 40,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }
    ];

  }

  private createColumnDef2(): void {
    this.columnDefs2 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'AbsentPolicy_Code', sortable: true, width: 40, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false,
      },
      // { headerName: 'ShortCode', field: 'ShortCode', sortable: true, width: 70, filter: true },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 70, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'ShortCode', field: 'AShortCode', sortable: true, width: 100, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
      // { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
      // { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
      { headerName: 'Default Policy', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 50, filter: true },
      { headerName: 'Company Policy', field: 'SetCompany', sortable: true, width: 50, filter: true },
      { headerName: 'Min Time', field: 'MinTimeForAbsent', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      // { headerName: 'WeekOffSingleSideAbsent', field: 'WeekOffSingleSideAbsent', sortable: true, width: 70, filter: true },
      // { headerName: 'WeekOffBothSideAbsent', field: 'WeekOffBothSideAbsent', sortable: true, width: 70, filter: true },
      // { headerName: 'MaxLateInAllow', field: 'MaxLateInAllow', sortable: true, width: 70, filter: true },
      // { headerName: 'MaxLateOutAllow', field: 'MaxLateOutAllow', sortable: true, width: 70, filter: true },
      {
        headerName: 'Action', field: '', width: 40,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }
    ];

  }

  private createColumnDef3(): void {
    this.columnDefs3 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'HalfDayPolicy_Code', sortable: true, width: 30, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false,
      },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 60, filter: true },
      // { headerName: 'ShortCode', field: 'HShortCode', sortable: true, width: 60, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
      // { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
      // { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
      { headerName: 'Default Policy', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 50, filter: true },
      // { headerName: 'IsCompany', field: 'IsCompany', sortable: true, width: 70, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Min Half Day Duration', field: 'MinHalfDayDuration', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Min Time For Absent', field: 'MinTimeForAbsent', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      // { headerName: 'WeekOffSufixPrifixAbsent', field: 'WeekOffSufixPrifixAbsent', sortable: true, width: 70, filter: true },
      // { headerName: 'HolidaySufixPrifixAbsent', field: 'HolidaySufixPrifixAbsent', sortable: true, width: 70, filter: true },
      {
        headerName: 'Action', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }
    ];

  }

  private createColumnDef4(): void {
    this.columnDefs4 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'OTPolicy_Code', sortable: true, width: 30, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false,
      },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 60, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'ShortCode', field: 'OTShortCode', sortable: true, width: 100, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
      // { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
      // { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
      { headerName: 'IsDefault', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 50, filter: true },
      // { headerName: 'IsCompany', field: 'IsCompany', sortable: true, width: 70, filter: true },
      { headerName: 'Min Time', field: 'MinTimeForOT', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Max Time', field: 'MaxTimeForOT', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Early Comming Add', field: 'EarlyCommingAdd', sortable: true, cellStyle: { textAlign: 'center' }, width: 60, filter: true },
      // { headerName: 'ClaimAfterMinHour', field: 'ClaimAfterMinHour', sortable: true, width: 70, filter: true },
      // { headerName: 'WeeklyPresentAsOT', field: 'WeeklyPresentAsOT', sortable: true, width: 50, filter: true },
      {
        headerName: 'Action', field: '', width: 40,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }
    ];

  }

  private createColumnDef5(): void {
    this.columnDefs5 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'COFFPolicy_Code', sortable: true, width: 30, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false,
      },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 50, filter: true },
      // { headerName: 'Short Code', field: 'CPShortCode', sortable: true, width: 50, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'Is Labour', field: 'IsLabour', sortable: true, width: 40, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Applicable Date', field: 'ApplicableDate', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Valid upto Date', field: 'ValiduptoDate', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Default Policy', field: 'SetDefault', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Company Policy', field: 'SetCompany', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Min OT Required', field: 'MinOTRequired', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Validity Type', field: 'validity Type', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Min For Half Day', field: 'COFFMinForHalfDay', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      // tslint:disable-next-line: max-line-length
      { headerName: 'Min For Full Day', field: 'COFFMinForFullDay', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      {
        headerName: 'Action', field: '', width: 40,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }
    ];

  }

  private createColumnDef6(): void {
    this.columnDefs6 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'LunchPolicy_Code', sortable: true, width: 40, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false,
      },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 50, filter: true },
      // { headerName: 'Short Code', field: 'LPShortCode', sortable: true, width: 50, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
      { headerName: 'Applicable Date', field: 'ApplicableDate', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Valid upto Date', field: 'ValiduptoDate', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Default Policy', field: 'IsDefault', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Company Policy', field: 'IsCompany', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      // { headerName: 'IsLunchTimeApplicable', field: 'SetLunch', sortable: true, width: 70, filter: true },
      { headerName: 'Lunch Time', field: 'ConsiderLunchTime', sortable: true, width: 50, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'Before LunchIn', field: 'AllowBeforeLunchIn', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      { headerName: 'After LunchIn', field: 'AllowAfterLunchIn', sortable: true, width: 70, cellStyle: { textAlign: 'center' }, filter: true },
      {
        headerName: 'Action', field: '', width: 40,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }
    ];

  }

  private createColumnDef7(): void {
    this.columnDefs7 = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRenderer: this.rowIndexCellRenderer,
      },
      {
        headerName: 'ID', field: 'WeekOFFPolicy_Code', sortable: true, width: 30, filter: true,
        cellStyle: { textAlign: 'center' },
        hide: false,
      },
      // { headerName: 'System Code', field: 'Sys_Code', sortable: true, width: 40, filter: true },
      // { headerName: 'Company', field: 'CompanyName', sortable: true, width: 100, filter: true },
      { headerName: 'Plant', headerClass: 'text-left', field: 'PlantName', sortable: true, width: 50, filter: true },
      // { headerName: 'ShortCode', field: 'WPShortCode', sortable: true, width: 50, filter: true },
      { headerName: 'Name', headerClass: 'text-left', field: 'PolicyName', sortable: true, width: 80, filter: true },
      // { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
      // { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
      // { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
      // { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },

      { headerName: 'Default Policy', field: 'SetDefault', cellStyle: { textAlign: 'center' }, sortable: true, width: 70, filter: true },
      { headerName: 'Company Policy', field: 'SetCompany', cellStyle: { textAlign: 'center' }, sortable: true, width: 70, filter: true },

      { headerName: 'FirstWeek1', headerClass: 'text-left', field: 'FirstWeek1', sortable: true, width: 70, filter: true },
      { headerName: 'FirstWeek2', headerClass: 'text-left', field: 'FirstWeek2', sortable: true, width: 70, filter: true },
      // { headerName: 'IsPresentOTWeek1', field: 'IsPresentOTWeek1', sortable: true, width: 70, filter: true },
      { headerName: 'SecoundWeek1', headerClass: 'text-left', field: 'SecoundWeek1', sortable: true, width: 70, filter: true },
      { headerName: 'SecoundWeek2', headerClass: 'text-left', field: 'SecoundWeek2', sortable: true, width: 70, filter: true },
      // { headerName: 'IsPresentOTSWeek2', field: 'IsPresentOTSWeek2', sortable: true, width: 70, filter: true },
      { headerName: 'ThirdWeek1', headerClass: 'text-left', field: 'ThirdWeek1', sortable: true, width: 70, filter: true },
      { headerName: 'ThirdWeek2', headerClass: 'text-left', field: 'ThirdWeek2', sortable: true, width: 70, filter: true },
      // { headerName: 'IsPresentOTTWeek3', field: 'IsPresentOTTWeek3', sortable: true, width: 70, filter: true },
      { headerName: 'FourthWeek1', headerClass: 'text-left', field: 'FourthWeek1', sortable: true, width: 70, filter: true },
      { headerName: 'FourthWeek2', headerClass: 'text-left', field: 'FourthWeek2', sortable: true, width: 70, filter: true },
      // { headerName: 'IsPresentOTFWeek4', field: 'IsPresentOTFWeek4', sortable: true, width: 70, filter: true },
      // { headerName: 'FifthWeek1', field: 'FifthWeek1', sortable: true, width: 70, filter: true },
      // { headerName: 'FifthWeek2', field: 'FifthWeek2', sortable: true, width: 70, filter: true },
      // { headerName: 'IsPresentOTFTWeek5', field: 'IsPresentOTFTWeek5', sortable: true, width: 70, filter: true },

      {
        headerName: 'Action', field: '', width: 40,
        sortable: false, suppressMenu: true, cellStyle: { textAlign: 'center' }, cellRendererFramework: AgActionCellRendererComponent
      }

    ];

  }

  // private createColumnDef8(): void {
  //   this.columnDefs8 = [
  //     {
  //       headerName: '#', field: '', width: 50,
  //       sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
  //     },
  //     {
  //       headerName: 'ShiftConfigPolicy_Code', field: 'ShiftConfigPolicy_Code', sortable: true, width: 70, filter: true,
  //       hide: false,
  //     },
  //     { headerName: 'System Code', field: 'Sys_Code', sortable: true, width: 40, filter: true },
  //     { headerName: 'Company Code', field: 'Company_Code', sortable: true, width: 70, filter: true },
  //     { headerName: 'ShortCode', field: 'ShortCode', sortable: true, width: 70, filter: true },
  //     { headerName: 'Name', field: 'Name', sortable: true, width: 70, filter: true },
  //     { headerName: 'Plant Code', field: 'Plant_Code', sortable: true, width: 70, filter: true },
  //     { headerName: 'Version', field: 'Version', sortable: true, width: 70, filter: true },
  //     { headerName: 'IsLabour', field: 'IsLabour', sortable: true, width: 70, filter: true },
  //     { headerName: 'ApplicableDate', field: 'ApplicableDate', sortable: true, width: 70, filter: true },
  //     { headerName: 'ValiduptoDate', field: 'ValiduptoDate', sortable: true, width: 70, filter: true },
  //     { headerName: 'IsDefault', field: 'IsDefault', sortable: true, width: 70, filter: true },
  //     { headerName: 'IsCompany', field: 'IsCompany', sortable: true, width: 70, filter: true },
  //     { headerName: 'Name', field: 'Name', sortable: true, width: 70, filter: true },
  //     { headerName: 'IsShiftBasedAccess', field: 'IsShiftBasedAccess', sortable: true, width: 70, filter: true },
  //     { headerName: 'IsPredefined', field: 'IsPredefined', sortable: true, width: 70, filter: true },
  //     { headerName: 'IsRealTimeMonitoring', field: 'IsRealTimeMonitoring', sortable: true, width: 70, filter: true },
  //     {
  //       headerName: 'Action', field: '', width: 50,
  //       sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
  //     }
  //   ];

  // }

  public saveAndNew(): void {
    const value = this.earlyGoingPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editEarlyOutPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.earlyGoingPolicyForm.valid) {
      const data = {
        ...value,
        EarlyOutPolicy_Code: this.editEarlyOutPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editEarlyOutPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID,
        MaxEarlyOut: +value.MaxEarlyOut
      }

      if (selectedFilterDataObj) {
        this.service.getUpdateEarlyGoingPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'Policy Updated successfully'
            });
            // this.getEarlyOutPolicy();
            this.getListOfPolicydata(1);
            this.modalRef.close();
            this.clearData();
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      } else {
        this.service.getAddEarlyGoingPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'Policy added successfully'
            });
            // this.getEarlyOutPolicy();
            this.getListOfPolicydata(1);

            this.modalRef.close();
            this.clearData();
          }
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotary Fields'
      });
    }
  }


  public editEarlyOutPolicyCode($event): void {
    this.editEarlyOutPolicyCode1 = $event.Policy_Code;
    if (this.editEarlyOutPolicyCode1) {
      this.openModal();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getEarlyGoingPolicyProfile(this.editEarlyOutPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }
        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'EarlyPolicy');
        //  this.editshiftCode1 = shift;
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        // this.earlyGoingPolicyForm.controls['EarlyOutPolicy_Code'].setValue(shift[0].EarlyOutPolicy_Code);
        // this.earlyGoingPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.earlyGoingPolicyForm.controls['Company_Code'].disable();
        this.earlyGoingPolicyForm.controls['Plant_Code'].disable();

        this.earlyGoingPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.earlyGoingPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.earlyGoingPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.earlyGoingPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.earlyGoingPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.earlyGoingPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.earlyGoingPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        // this.earlyGoingPolicyForm.controls['IsPlantDefault'].setValue(shift[0].IsPlantDefault);
        this.earlyGoingPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.earlyGoingPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.earlyGoingPolicyForm.controls['MaxEarlyOut'].setValue(shift[0].MaxEarlyOut);
        this.earlyGoingPolicyForm.controls['Version'].setValue(shift[0].Version);
        // this.earlyGoingPolicyForm.controls['IsApplicableEarlyIn'].setValue(shift[0].IsApplicableEarlyIn);
        // this.earlyGoingPolicyForm.controls['MaxEarlyIn'].setValue(shift[0].MaxEarlyIn);
        // this.earlyGoingPolicyForm.controls['MaxEarlyInAllow'].setValue(shift[0].MaxEarlyInAllow);
        // this.earlyGoingPolicyForm.controls['MaxEarlyOutAllow'].setValue(shift[0].MaxEarlyOutAllow);
        // this.earlyGoingPolicyForm.controls['CreatedDate'].setValue(shift[0].CreatedDate);
        // this.earlyGoingPolicyForm.controls['CreateBy'].setValue(shift[0].CreateBy);
        // this.earlyGoingPolicyForm.controls['ModifiedDate'].setValue(shift[0].ModifiedDate);
        // this.earlyGoingPolicyForm.controls['ModifiedBy'].setValue(shift[0].ModifiedBy);
        // this.earlyGoingPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        // this.earlyGoingPolicyForm.controls['IsDelete'].setValue(shift[0].IsDelete);
        // this.earlyGoingPolicyForm.controls['IsSync'].setValue(shift[0].IsSync);
        // this.earlyGoingPolicyForm.controls['IsPlantDefault'].setValue(shift[0].IsPlantDefault);
        // this.earlyGoingPolicyForm.controls['StatementType'].setValue(shift[0].StatementType);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  public saveAndNew1(): void {
    console.log('form data open modal', this.lateInPolicyForm.getRawValue());
    const value = this.lateInPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editLateInPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.lateInPolicyForm.valid) {
      const data = {
        ...value,
        LateInPolicy_Code: this.editLateInPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editLateInPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   LateInPolicy_Code: this.editLateInPolicyCode1,
      //   Name: value.Name,
      //   Company_Code: value.Company_Code,
      //   ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   // Version: value.Version,
      //   // IsLabour: value.IsLabour,
      //   ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   // IsCompany: value.IsCompany,
      //   MaxLateOut: value.MaxLateOut,
      //   // IsApplicableLateOut: value.IsApplicableLateOut,
      //   MaxLateIn: value.MaxLateIn,
      //   MaxLateInAllow: value.MaxLateInAllow,
      //   MaxLateOutAllow: value.MaxLateOutAllow,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      //   Version: this.editLateInPolicyCode1
      // };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateLateInPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'LateInPolicy Updated successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(2);

            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddLateInPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'LateInPolicy added successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(2);
          }
        });
      }
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.logHelperService.logError({
        message: 'Please Fill Mandotary Fields'
      });
    }
  }

  public editLateInPolicyCode($event): void {
    this.editLateInPolicyCode1 = $event.Policy_Code;
    if (this.editLateInPolicyCode1) {

      this.openModal1();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getLateInPolicyProfile(this.editLateInPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }
        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'LateInPolicy');

        //  this.editshiftCode1 = shift;
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        //  }
        // this.lateInPolicyForm.controls['LateInPolicy_Code'].setValue(shift[0].LateInPolicy_Code);
        // this.lateInPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.lateInPolicyForm.controls['Company_Code'].disable();
        this.lateInPolicyForm.controls['Plant_Code'].disable();

        this.lateInPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.lateInPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.lateInPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.lateInPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.lateInPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.lateInPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.lateInPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.lateInPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.lateInPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.lateInPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.lateInPolicyForm.controls['MaxLateOut'].setValue(shift[0].MaxLateOut);
        // this.lateInPolicyForm.controls['IsApplicableLateOut'].setValue(shift[0].IsApplicableLateOut);
        this.lateInPolicyForm.controls['MaxLateIn'].setValue(shift[0].MaxLateIn);
        this.lateInPolicyForm.controls['MaxLateInAllow'].setValue(shift[0].MaxLateInAllow);
        this.lateInPolicyForm.controls['MaxLateOutAllow'].setValue(shift[0].MaxLateOutAllow);
        // this.lateInPolicyForm.controls['CreatedDate'].setValue(shift[0].CreatedDate);
        // this.lateInPolicyForm.controls['CreateBy'].setValue(shift[0].CreateBy);
        // this.lateInPolicyForm.controls['ModifiedDate'].setValue(shift[0].ModifiedDate);
        // this.lateInPolicyForm.controls['ModifiedBy'].setValue(shift[0].ModifiedBy);
        this.lateInPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        // this.lateInPolicyForm.controls['IsDelete'].setValue(shift[0].IsDelete);
        // this.lateInPolicyForm.controls['IsSync'].setValue(shift[0].IsSync);
        // this.lateInPolicyForm.controls['IsPlantDefault'].setValue(shift[0].IsPlantDefault);
        // this.lateInPolicyForm.controls['StatementType'].setValue(shift[0].StatementType);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  public saveAndNew2(): void {

    console.log('form data open modal', this.absentPolicyForm.getRawValue());
    const value = this.absentPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editAbsentPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.absentPolicyForm.valid) {
      const data = {
        ...value,
        AbsentPolicy_Code: this.editAbsentPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editAbsentPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   AbsentPolicy_Code: this.editAbsentPolicyCode1,
      //   Name: value.Name,
      //   Sys_Code: value.Sys_Code,
      //   Company_Code: value.Company_Code,
      //   // ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   // Version: value.Version,
      //   // IsLabour: value.IsLabour,
      //   // ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   // ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   IsCompany: value.IsCompany,
      //   MinTimeForAbsent: value.MinTimeForAbsent,
      //   // WeekOffSingleSideAbsent: value.WeekOffSingleSideAbsent,
      //   // WeekOffBothSideAbsent: value.WeekOffBothSideAbsent,
      //   // HolidaySingleSideAbsent: value.HolidaySingleSideAbsent,
      //   // HolidayBothSideAbsent: value.HolidayBothSideAbsent,
      //   // CheckLateIn: value.CheckLateIn,
      //   // MaxAllowPerMonthLateIn: value.MaxAllowPerMonthLateIn,
      //   // IsLateInContinues: value.IsLateInContinues,
      //   // LateInMarkAbsent: value.LateInMarkAbsent,
      //   // CheckEarlyOut: value.CheckEarlyOut,
      //   // MaxAllowPerMonthEarlyOut: value.MaxAllowPerMonthEarlyOut,
      //   // IsEarlyOutContinued: value.IsEarlyOutContinued,
      //   // EarlyOutMarkAbsent: value.EarlyOutMarkAbsent,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      // };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateAbsentPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'AbsentPolicy Updated successfully'
            });
            this.getListOfPolicydata(3);

            this.modalRef.close();
            this.clearData();
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddAbsentPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'AbsentPolicy added successfully'
            });
            this.getListOfPolicydata(3);

            this.modalRef.close();
            this.clearData();
          }
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotary Fields'
      });
    }
  }


  public editAbsentPolicyCode($event): void {
    // this.openModal2();
    this.editAbsentPolicyCode1 = $event.Policy_Code;
    if (this.editAbsentPolicyCode1) {

      this.openModal2();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getAbsentPolicyProfile(this.editAbsentPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }

        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'AbsentPolicy');

        //  this.editshiftCode1 = shift;
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        //  this.absentPolicyForm.controls['AbsentPolicy_Code'].setValue(shift[0].AbsentPolicy_Code);
        //  this.absentPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.absentPolicyForm.controls['Company_Code'].disable();
        this.absentPolicyForm.controls['Plant_Code'].disable();

        this.absentPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.absentPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.absentPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.absentPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.absentPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.absentPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.absentPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.absentPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.absentPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.absentPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.absentPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.absentPolicyForm.controls['MinTimeForAbsent'].setValue(shift[0].MinTimeForAbsent);
        // this.absentPolicyForm.controls['WeekOffSingleSideAbsent'].setValue(shift[0].WeekOffSingleSideAbsent);
        // this.absentPolicyForm.controls['WeekOffBothSideAbsent'].setValue(shift[0].WeekOffBothSideAbsent);
        // this.absentPolicyForm.controls['HolidaySingleSideAbsent'].setValue(shift[0].HolidaySingleSideAbsent);
        // this.absentPolicyForm.controls['HolidayBothSideAbsent'].setValue(shift[0].HolidayBothSideAbsent);
        // this.absentPolicyForm.controls['CheckLateIn'].setValue(shift[0].CheckLateIn);
        // this.absentPolicyForm.controls['MaxAllowPerMonthLateIn'].setValue(shift[0].MaxAllowPerMonthLateIn);
        // this.absentPolicyForm.controls['IsLateInContinues'].setValue(shift[0].IsLateInContinues);
        // this.absentPolicyForm.controls['LateInMarkAbsent'].setValue(shift[0].LateInMarkAbsent);
        // this.absentPolicyForm.controls['CheckEarlyOut'].setValue(shift[0].CheckEarlyOut);
        // this.absentPolicyForm.controls['MaxAllowPerMonthEarlyOut'].setValue(shift[0].MaxAllowPerMonthEarlyOut);
        // this.absentPolicyForm.controls['IsEarlyOutContinued'].setValue(shift[0].IsEarlyOutContinued);
        // this.absentPolicyForm.controls['EarlyOutMarkAbsent'].setValue(shift[0].EarlyOutMarkAbsent);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }


  public saveAndNew3(): void {

    console.log('form data open modal', this.halfdayPolicyForm.getRawValue());
    const value = this.halfdayPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editHalfDayPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.halfdayPolicyForm.valid) {
      const data = {
        ...value,
        HalfDayPolicy_Code: this.editHalfDayPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editHalfDayPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   HalfDayPolicy_Code: this.editHalfDayPolicyCode1,
      //   Name: value.Name,
      //   Sys_Code: value.Sys_Code,
      //   Company_Code: value.Company_Code,
      //   ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   // Version: value.Version,
      //   // IsLabour: value.IsLabour,
      //   // ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   // ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   // IsCompany: value.IsCompany,
      //   MinHalfDayDuration: value.MinHalfDayDuration,
      //   MinTimeForAbsent: value.MinTimeForAbsent,
      //   // WeekOffSufixPrefixAbsent: value.WeekOffSufixPrefixAbsent,
      //   // HolidaySufixPrefixAbsent: value.HolidaySufixPrefixAbsent,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      // };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateHalfDayPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'HalfDayPolicy Updated successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(4);
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddHalfDayPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'HalfDayPolicy added successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(4);
          }
        });
      }
    }
  }

  public editHalfdayPolicyCode($event): void {
    // this.openModal3();
    this.editHalfDayPolicyCode1 = $event.Policy_Code;
    if (this.editHalfDayPolicyCode1) {

      this.openModal3();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getHalfdayPolicyProfile(this.editHalfDayPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }

        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'HalfDayPolicy');

        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        // this.halfdayPolicyForm.controls['HalfDayPolicy_Code'].setValue(shift[0].HalfDayPolicy_Code);
        // this.halfdayPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.halfdayPolicyForm.controls['Company_Code'].disable();
        this.halfdayPolicyForm.controls['Plant_Code'].disable();
        this.halfdayPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.halfdayPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.halfdayPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.halfdayPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.halfdayPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.halfdayPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.halfdayPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.halfdayPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.halfdayPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.halfdayPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.halfdayPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.halfdayPolicyForm.controls['MinHalfDayDuration'].setValue(shift[0].MinHalfDayDuration);
        this.halfdayPolicyForm.controls['MinTimeForAbsent'].setValue(shift[0].MinTimeForAbsent);
        // this.halfdayPolicyForm.controls['WeekOffSufixPrefixAbsent'].setValue(shift[0].WeekOffSufixPrefixAbsent);
        // this.halfdayPolicyForm.controls['HolidaySufixPrefixAbsent'].setValue(shift[0].HolidaySufixPrefixAbsent);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  public clearEarlyGoingPolicyForm(): void {
    this.earlyGoingPolicyForm.reset();
  }

  public clearLateInPolicyForm(): void {
    this.lateInPolicyForm.reset();
  }

  public clearAbsentPolicyForm(): void {
    this.absentPolicyForm.reset();
  }

  public clearHalfDayPolicyForm(): void {
    this.halfdayPolicyForm.reset();
  }

  public clearExtraHoursPolicyForm(): void {
    this.otPolicyForm.reset();
  }

  public clearCoffPolicyForm(): void {
    this.coffPolicyForm.reset();
  }

  public clearLunchPolicyForm(): void {
    this.lunchPolicyForm.reset();
  }

  public clearWeekPolicyForm(): void {
    this.weekoffPolicyForm.reset();
  }

  checkShortCode3() {
    // tslint:disable-next-line: ter-indent
    this.service.getCheckShortCode3(this.halfdayPolicyForm.controls.ShortCode.value,
      this.editHalfDayPolicyCode1).subscribe((res: any) => {
        this.shortCode = res[0].HalfDayPolicy_Code;
        if (this.shortCode != null) {
          if (this.editHalfDayPolicyCode1 != null) {
            if (this.shortCode != null) {
              if (this.editHalfDayPolicyCode1 !== res[0].HalfDayPolicy_Code) {
                this.logHelperService.logError({
                  // tslint:disable-next-line: max-line-length
                  message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
                });
                this.halfdayPolicyForm.controls['ShortCode'].reset();
              }
            }
          } else if (this.shortCode != null) {
            this.logHelperService.logError({
              // tslint:disable-next-line: max-line-length
              message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
            });
            this.halfdayPolicyForm.controls['ShortCode'].reset();
          }
        }
        return true;
      });
  }


  public saveAndNew4(): void {

    console.log('form data open modal', this.otPolicyForm.getRawValue());
    const value = this.otPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editOTPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.otPolicyForm.valid) {
      const data = {
        ...value,
        OTPolicy_Code: this.editOTPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editOTPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   OTPolicy_Code: this.editOTPolicyCode1,
      //   Name: value.Name,
      //   // Sys_Code: value.Sys_Code,
      //   Company_Code: value.Company_Code,
      //   // ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   // Version: value.Version,
      //   // IsLabour: value.IsLabour,
      //   // ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   // ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   // IsCompany: value.IsCompany,
      //   IsApplicable: value.IsApplicable,
      //   MinTimeForOT: value.MinTimeForOT,
      //   MaxTimeForOT: value.MaxTimeForOT,
      //   EarlyCommingAdd: value.EarlyCommingAdd,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      // };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateOTPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'OTPolicy Updated successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(5);
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddOTPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'OTPolicy added successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(5);
          }
        });
      }
    }
    // else {
    // //   this.logHelperService.logError({
    // //     message: 'Please Fill Mandotary Fields'
    // //   });
    // // }
  }



  public editOTPolicyCode($event): void {
    // this.openModal4();
    this.editOTPolicyCode1 = $event.Policy_Code;
    if (this.editOTPolicyCode1) {

      this.openModal4();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getOTPolicyProfile(this.editOTPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }

        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'ExtraHoursPolicy');

        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        // this.otPolicyForm.controls['OTPolicy_Code'].setValue(shift[0].OTPolicy_Code);
        // this.otPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.otPolicyForm.controls['Company_Code'].disable();
        this.otPolicyForm.controls['Plant_Code'].disable();
        this.otPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.otPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.otPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.otPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.otPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.otPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.otPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.otPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.otPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.otPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.otPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.otPolicyForm.controls['IsApplicable'].setValue(shift[0].IsApplicable);
        this.otPolicyForm.controls['MinTimeForOT'].setValue(shift[0].MinTimeForOT);
        this.otPolicyForm.controls['MaxTimeForOT'].setValue(shift[0].MaxTimeForOT);
        this.otPolicyForm.controls['EarlyCommingAdd'].setValue(shift[0].EarlyCommingAdd);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }


  public saveAndNew5(): void {
    console.log('form data open modal', this.coffPolicyForm.getRawValue());
    const value = this.coffPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editCOffPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.coffPolicyForm.valid) {
      const data = {
        ...value,
        COFFPolicy_Code: this.editCOffPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editCOffPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   COFFPolicy_Code: this.editCOffPolicyCode1,
      //   Name: value.Name,
      //   Sys_Code: value.Sys_Code,
      //   Company_Code: value.Company_Code,
      //   ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   Version: value.Version,
      //   IsLabour: value.IsLabour,
      //   ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   IsCompany: value.IsCompany,
      //   MinOTRequired: value.MinOTRequired,
      //   ValidityType: value.ValidityType,
      //   COFFMinForHalfDay: value.COFFMinForHalfDay,
      //   COFFMinForFullDay: value.COFFMinForFullDay,
      //   IsAutoAuthorize: value.IsAutoAuthorize,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      // };
      if (selectedFilterDataObj) {
        this.service.getUpdateCOFFPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'COFFPolicy Updated successfully'
            });
            this.getListOfPolicydata(6);
            this.modalRef.close();
            this.clearData();
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddCOFFPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'COFFPolicy added successfully'
            });
            this.getListOfPolicydata(6);
            this.modalRef.close();
            this.clearData();
          }
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotary Fields'
      });
    }
  }

  public editCOffPolicyCode($event): void {
    // this.openModal5();
    this.editCOffPolicyCode1 = $event.Policy_Code;
    if (this.editCOffPolicyCode1) {

      this.openModal5();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getCOFFPolicyProfile(this.editCOffPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }
        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'C-OFFPolicy');

        //  this.editshiftCode1 = shift;
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        // this.coffPolicyForm.controls['COFFPolicy_Code'].setValue(shift[0].COFFPolicy_Code);
        // this.coffPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.coffPolicyForm.controls['Company_Code'].disable();
        this.coffPolicyForm.controls['Plant_Code'].disable();
        this.coffPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.coffPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.coffPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.coffPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.coffPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.coffPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.coffPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.coffPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.coffPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.coffPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.coffPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.coffPolicyForm.controls['MinOTRequired'].setValue(shift[0].MinOTRequired);
        this.coffPolicyForm.controls['ValidityType'].setValue(shift[0].ValidityType);
        this.coffPolicyForm.controls['COFFMinForHalfDay'].setValue(shift[0].COFFMinForHalfDay);
        this.coffPolicyForm.controls['COFFMinForFullDay'].setValue(shift[0].COFFMinForFullDay);
        this.coffPolicyForm.controls['IsAutoAuthorize'].setValue(shift[0].IsAutoAuthorize);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }
  CheckShortCode5() {
    // tslint:disable-next-line: ter-indent
    this.service.getCheckShortCode5(this.coffPolicyForm.controls.ShortCode.value,
      this.editCOffPolicyCode1).subscribe((res: any) => {
        this.shortCode = res[0].COFFPolicy_Code;
        if (this.shortCode != null) {
          if (this.editCOffPolicyCode1 != null) {
            if (this.shortCode != null) {
              if (this.editCOffPolicyCode1 !== res[0].COFFPolicy_Code) {
                this.logHelperService.logError({
                  // tslint:disable-next-line: max-line-length
                  message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
                });
                this.coffPolicyForm.controls['ShortCode'].reset();
              }
            }
          } else if (this.shortCode != null) {
            this.logHelperService.logError({
              // tslint:disable-next-line: max-line-length
              message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
            });
            this.coffPolicyForm.controls['ShortCode'].reset();
          }
        }
        return true;
      });
  }

  public saveAndNew6(): void {
    console.log('form data open modal', this.lunchPolicyForm.getRawValue());
    const value = this.lunchPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editLunchPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.lunchPolicyForm.valid) {
      const data = {
        ...value,
        LunchPolicy_Code: this.editLunchPolicyCode1 || null,
        ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editLunchPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   LunchPolicy_Code: this.editLunchPolicyCode1,
      //   Name: value.Name,
      //   Sys_Code: value.Sys_Code,
      //   Company_Code: value.Company_Code,
      //   ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   Version: value.Version,
      //   IsLabour: value.IsLabour,
      //   ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   IsCompany: value.IsCompany,
      //   IsLunchTimeApplicable: value.IsLunchTimeApplicable,
      //   ConsiderLunchTime: value.ConsiderLunchTime,
      //   AllowBeforeLunchIn: value.AllowBeforeLunchIn,
      //   AllowAfterLunchIn: value.AllowAfterLunchIn,
      //   AllowBeforeLunchOut: value.AllowBeforeLunchOut,
      //   AllowAfterLunchOut: value.AllowAfterLunchOut,
      //   AddBLateInTotalEOut: value.AddBLateInTotalEOut,
      //   AddBEOutInTotalLateIn: value.AddBEOutInTotalLateIn,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      // };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateLunchPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'LunchPolicy Updated successfully'
            });
            // this.getEarlyOutPolicy();
            this.getListOfPolicydata(7);
            this.modalRef.close();
            this.clearData();
            // this.plantMaster();
            // this.unitMaster();
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddLunchPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'LunchPolicy added successfully'
            });
            this.getListOfPolicydata(7);
            this.modalRef.close();
            this.clearData();
          }
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotary Fields'
      });
    }
  }

  public editLunchPolicyCode($event): void {
    // this.openModal6();
    this.editLunchPolicyCode1 = $event.Policy_Code;
    if (this.editLunchPolicyCode1) {

      this.openModal6();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getLunchPolicyProfile(this.editLunchPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }

        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'LunchPolicy');

        //  this.editshiftCode1 = shift;
        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        // this.lunchPolicyForm.controls['LunchPolicy_Code'].setValue(shift[0].LunchPolicy_Code);
        // this.lunchPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.lunchPolicyForm.controls['Company_Code'].disable();
        this.lunchPolicyForm.controls['Plant_Code'].disable();
        this.lunchPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.lunchPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.lunchPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.lunchPolicyForm.controls['Name'].setValue(shift[0].Name);
        this.lunchPolicyForm.controls['Version'].setValue(shift[0].Version);
        this.lunchPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.lunchPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.lunchPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.lunchPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.lunchPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.lunchPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.lunchPolicyForm.controls['IsLunchTimeApplicable'].setValue(shift[0].IsLunchTimeApplicable);
        this.lunchPolicyForm.controls['AllowBeforeLunchIn'].setValue(shift[0].AllowBeforeLunchIn);
        this.lunchPolicyForm.controls['AllowAfterLunchIn'].setValue(shift[0].AllowAfterLunchIn);
        this.lunchPolicyForm.controls['ConsiderLunchTime'].setValue(shift[0].ConsiderLunchTime);
        this.lunchPolicyForm.controls['AllowBeforeLunchOut'].setValue(shift[0].AllowBeforeLunchOut);
        this.lunchPolicyForm.controls['AllowAfterLunchOut'].setValue(shift[0].AllowAfterLunchOut);
        this.lunchPolicyForm.controls['AddBLateInTotalEOut'].setValue(shift[0].AddBLateInTotalEOut);
        this.lunchPolicyForm.controls['AddBEOutInTotalLateIn'].setValue(shift[0].AddBEOutInTotalLateIn);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  CheckShortCode6() {
    // tslint:disable-next-line: ter-indent
    this.service.getCheckShortCode6(this.lunchPolicyForm.controls.ShortCode.value,
      this.editLunchPolicyCode1).subscribe((res: any) => {
        this.shortCode = res[0].LunchPolicy_Code;
        if (this.shortCode != null) {
          if (this.editLunchPolicyCode1 != null) {
            if (this.shortCode != null) {
              if (this.editLunchPolicyCode1 !== res[0].LunchPolicy_Code) {
                this.logHelperService.logError({
                  // tslint:disable-next-line: max-line-length
                  message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
                });
                this.lunchPolicyForm.controls['ShortCode'].reset();
              }
            }
          } else if (this.shortCode != null) {
            this.logHelperService.logError({
              // tslint:disable-next-line: max-line-length
              message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
            });
            this.lunchPolicyForm.controls['ShortCode'].reset();
          }
        }
        return true;
      });
  }

  public saveAndNew7(): void {
    console.log('form data open modal', this.weekoffPolicyForm.getRawValue());
    const value = this.weekoffPolicyForm.getRawValue();
    const selectedFilterDataObj = this.editWeekoffPolicyCode1;
    if (selectedFilterDataObj) {
      this.statementType = 'Update';
    } else {
      this.statementType = 'Insert';
    }
    if (this.weekoffPolicyForm.valid) {
      const data = {
        ...value,
        WeekOFFPolicy_Code: this.editWeekoffPolicyCode1 || null,
        // ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
        // ValiduptoDate: value.ValiduptoDate ? moment(value.ValiduptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat) : null,
        StatementType: this.editWeekoffPolicyCode1 ? 'Update' : 'Insert',
        UserID: GLOBAL.USER_ID
      }
      // const data = {
      //   WeekOFFPolicy_Code: this.editWeekoffPolicyCode1,
      //   Name: value.Name,
      //   Sys_Code: value.Sys_Code,
      //   Company_Code: value.Company_Code,
      //   ShortCode: value.ShortCode,
      //   Plant_Code: value.Plant_Code,
      //   // Version: value.Version,
      //   // IsLabour: value.IsLabour,
      //   // ApplicableDate: moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   // ValiduptoDate: moment(value.ValiduptoDate).format(this.sharedService.datePickerSettings.requestFormat),
      //   IsDefault: value.IsDefault,
      //   IsCompany: value.IsCompany,
      //   FirstWeek1: value.FirstWeek1,
      //   FirstWeek2: value.FirstWeek2,
      //   IsPresentOTWeek1: value.IsPresentOTWeek1,
      //   SecoundWeek1: value.SecoundWeek1,
      //   SecoundWeek2: value.SecoundWeek2,
      //   IsPresentOTSWeek2: value.IsPresentOTSWeek2,
      //   ThirdWeek1: value.ThirdWeek1,
      //   ThirdWeek2: value.ThirdWeek2,
      //   IsPresentOTTWeek3: value.IsPresentOTTWeek3,
      //   FourthWeek1: value.FourthWeek1,
      //   FourthWeek2: value.FourthWeek2,
      //   IsPresentOTFWeek4: value.IsPresentOTFWeek4,
      //   FifthWeek1: value.FifthWeek1,
      //   FifthWeek2: value.FifthWeek2,
      //   IsPresentOTFTWeek5: value.IsPresentOTFTWeek5,
      //   StatementType: this.statementType,
      //   UserID: GLOBAL.USER_ID,
      // };
      if (selectedFilterDataObj)
      // tslint:disable-next-line: brace-style
      {
        this.service.getUpdateWeekoffPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'WeekOffPolicy Updated successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(8);
          }
        });
      }
      // tslint:disable-next-line: brace-style
      else
      // tslint:disable-next-line: brace-style
      {
        this.service.getAddWeekoffPolicy(data).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'WeekOffPolicy added successfully'
            });
            this.modalRef.close();
            this.clearData();
            this.getListOfPolicydata(8);
          }
        });
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Fill Mandotary Fields'
      });
    }
  }

  public editWeekoffPolicyCode($event): void {
    // this.openModal7();
    this.editWeekoffPolicyCode1 = $event.Policy_Code;
    if (this.editWeekoffPolicyCode1) {

      this.openModal7();
      this.editMode = true;
      // tslint:disable-next-line: max-line-length
      this.service.getWeekoffPolicyProfile(this.editWeekoffPolicyCode1).subscribe((response: any) => {
        const shift = response;
        if (!shift) {
          return;
        }

        this.getPolicyHistory(shift[0].Policy_Code, shift[0].Plant_Code, shift[0].ShortCode, 'WeekOffPolicy');

        // if (shift[0].CoveredInESIC === true)
        // // tslint:disable-next-line: brace-style
        // {
        //   this.show = true;
        // } else {
        //   this.show = true;
        // }
        // this.weekoffPolicyForm.controls['WeekOFFPolicy_Code'].setValue(shift[0].WeekOFFPolicy_Code);
        // this.weekoffPolicyForm.controls['Sys_Code'].setValue(shift[0].Sys_Code);
        this.weekoffPolicyForm.controls['Company_Code'].disable();
        this.weekoffPolicyForm.controls['Plant_Code'].disable();
        this.weekoffPolicyForm.controls['Plant_Code'].setValue(shift[0].Plant_Code);
        this.weekoffPolicyForm.controls['Company_Code'].setValue(shift[0].Company_Code);
        this.weekoffPolicyForm.controls['ShortCode'].setValue(shift[0].ShortCode);
        this.weekoffPolicyForm.controls['Name'].setValue(shift[0].Name);
        // this.weekoffPolicyForm.controls['Version'].setValue(shift[0].Version);
        // this.weekoffPolicyForm.controls['IsLabour'].setValue(shift[0].IsLabour);
        this.weekoffPolicyForm.controls['ApplicableDate'].setValue(shift[0].ApplicableDate);
        this.weekoffPolicyForm.controls['ValiduptoDate'].setValue(shift[0].ValiduptoDate);
        this.weekoffPolicyForm.controls['IsDefault'].setValue(shift[0].IsDefault);
        this.weekoffPolicyForm.controls['IsActive'].setValue(shift[0].IsActive);
        this.weekoffPolicyForm.controls['IsCompany'].setValue(shift[0].IsCompany);
        this.weekoffPolicyForm.controls['FirstWeek1'].setValue(shift[0].FirstWeek1);
        this.weekoffPolicyForm.controls['FirstWeek2'].setValue(shift[0].FirstWeek2);
        this.weekoffPolicyForm.controls['IsPresentOTWeek1'].setValue(shift[0].IsPresentOTWeek1);
        this.weekoffPolicyForm.controls['SecoundWeek1'].setValue(shift[0].SecoundWeek1);
        this.weekoffPolicyForm.controls['SecoundWeek2'].setValue(shift[0].SecoundWeek2);
        this.weekoffPolicyForm.controls['IsPresentOTSWeek2'].setValue(shift[0].IsPresentOTSWeek2);
        this.weekoffPolicyForm.controls['ThirdWeek1'].setValue(shift[0].ThirdWeek1);
        this.weekoffPolicyForm.controls['ThirdWeek2'].setValue(shift[0].ThirdWeek2);
        this.weekoffPolicyForm.controls['IsPresentOTTWeek3'].setValue(shift[0].IsPresentOTTWeek3);
        this.weekoffPolicyForm.controls['FourthWeek1'].setValue(shift[0].FourthWeek1);
        this.weekoffPolicyForm.controls['FourthWeek2'].setValue(shift[0].FourthWeek2);
        this.weekoffPolicyForm.controls['IsPresentOTFWeek4'].setValue(shift[0].IsPresentOTFWeek4);
        this.weekoffPolicyForm.controls['FifthWeek1'].setValue(shift[0].FifthWeek1);
        this.weekoffPolicyForm.controls['FifthWeek2'].setValue(shift[0].FifthWeek2);
        this.weekoffPolicyForm.controls['IsPresentOTFTWeek5'].setValue(shift[0].IsPresentOTFTWeek5);
      });
    }
    // tslint:disable-next-line: brace-style
    else {
      this.editMode = false;
    }
  }

  CheckShortCode7() {
    // tslint:disable-next-line: ter-indent
    this.service.getCheckShortCode7(this.weekoffPolicyForm.controls.ShortCode.value,
      this.editWeekoffPolicyCode1).subscribe((res: any) => {
        this.shortCode = res[0].WeekOFFPolicy_Code;
        if (this.shortCode != null) {
          if (this.editWeekoffPolicyCode1 != null) {
            if (this.shortCode != null) {
              if (this.editWeekoffPolicyCode1 !== res[0].WeekOFFPolicy_Code) {
                this.logHelperService.logError({
                  // tslint:disable-next-line: max-line-length
                  message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
                });
                this.weekoffPolicyForm.controls['ShortCode'].reset();
              }
            }
          } else if (this.shortCode != null) {
            this.logHelperService.logError({
              // tslint:disable-next-line: max-line-length
              message: 'This field requires a unique entry. This Short Code has already been used with other Firm / Company. Please enter different Short Code.'
            });
            this.weekoffPolicyForm.controls['ShortCode'].reset();
          }
        }
        return true;
      });
  }

  public onValidateDatesChange(): void {
    const value = this.earlyGoingPolicyForm.getRawValue();
    const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
    if ((applicabledate == null || validuptodate == null)) {
      this.logHelperService.logError({
        message: 'Applicable Date and ValidUpto Date are required.'
      });
    } else if ((applicabledate != null && validuptodate != null)
      && (validuptodate) < (applicabledate)) {
      this.logHelperService.logError({
        message: 'ValidUpto Date should be grater then Applicable Date.'
      });
      this.earlyGoingPolicyForm.controls['ValidUptoDate'].setValue(null);
    }
  }
  public onValidateDatesChange1(): void {
    const value = this.lateInPolicyForm.getRawValue();
    const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
    if ((applicabledate == null || validuptodate == null)) {
      this.logHelperService.logError({
        message: 'Applicable Date and ValidUpto Date are required.'
      });
    } else if ((applicabledate != null && validuptodate != null)
      && (validuptodate) < (applicabledate)) {
      this.logHelperService.logError({
        message: 'ValidUpto Date should be grater then Applicable Date.'
      });
      this.lateInPolicyForm.controls['ValidUptoDate'].setValue(null);
    }
  }
  public onValidateDatesChange2(): void {
    const value = this.absentPolicyForm.getRawValue();
    const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
    if ((applicabledate == null || validuptodate == null)) {
      this.logHelperService.logError({
        message: 'Applicable Date and ValidUpto Date are required.'
      });
    } else if ((applicabledate != null && validuptodate != null)
      && (validuptodate) < (applicabledate)) {
      this.logHelperService.logError({
        message: 'ValidUpto Date should be grater then Applicable Date.'
      });
      this.absentPolicyForm.controls['ValidUptoDate'].setValue(null);
    }
  }
  public onValidateDatesChange3(): void {
    const value = this.halfdayPolicyForm.getRawValue();
    const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
    if ((applicabledate == null || validuptodate == null)) {
      this.logHelperService.logError({
        message: 'Applicable Date and ValidUpto Date are required.'
      });
    } else if ((applicabledate != null && validuptodate != null)
      && (validuptodate) < (applicabledate)) {
      this.logHelperService.logError({
        message: 'ValidUpto Date should be grater then Applicable Date.'
      });
      this.halfdayPolicyForm.controls['ValidUptoDate'].setValue(null);
    }
  }
  public onValidateDatesChange4(): void {
    const value = this.otPolicyForm.getRawValue();
    const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
    if ((applicabledate == null || validuptodate == null)) {
      this.logHelperService.logError({
        message: 'Applicable Date and ValidUpto Date are required.'
      });
    } else if ((applicabledate != null && validuptodate != null)
      && (validuptodate) < (applicabledate)) {
      this.logHelperService.logError({
        message: 'ValidUpto Date should be grater then Applicable Date.'
      });
      this.otPolicyForm.controls['ValidUptoDate'].setValue(null);
    }
  }
  // public onValidateDatesChange5(): void {
  //   // const value = this.coffPolicyForm.getRawValue();
  //   // const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
  //   // const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
  //   // if ((applicabledate == null || validuptodate == null)) {
  //   //   this.logHelperService.logError({
  //   //     message: 'Applicable Date and ValidUpto Date are required.'
  //   //   });
  //   // } else if ((applicabledate != null && validuptodate != null)
  //   //   && (validuptodate) < (applicabledate)) {
  //   //   this.logHelperService.logError({
  //   //     message: 'ValidUpto Date should be grater then Applicable Date.'
  //   //   });
  //   //   this.coffPolicyForm.controls['ValidUptoDate'].setValue(null);
  //   // }
  // }
  // public onValidateDatesChange6(): void {
  //   const value = this.coffPolicyForm.getRawValue();
  //   const applicabledate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
  //   const validuptodate = moment(value.ValidUptoDate).format(this.sharedService.datePickerSettings.requestFormat);
  //   if ((applicabledate == null || validuptodate == null)) {
  //     this.logHelperService.logError({
  //       message: 'Applicable Date and ValidUpto Date are required.'
  //     });
  //   } else if ((applicabledate != null && validuptodate != null)
  //     && (validuptodate) < (applicabledate)) {
  //     this.logHelperService.logError({
  //       message: 'ValidUpto Date should be grater then Applicable Date.'
  //     });
  //     this.coffPolicyForm.controls['ValidUptoDate'].setValue(null);
  //   }
  // }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }


  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      // alertRef.then((e) => {
      // });
    }, 10);
  }


  updateNumberOfRowsPerPage() {
    this.gridAPI.paginationSetPageSize(this.numberOfRowsPerPage);
    this.gridAPI.sizeColumnsToFit();
  }

  onEdit(params) {
    console.log('edit');
  }

  onDelete(params) {
    console.log('delete');
  }

  onWeekoffChange() {
    const value = this.weekoffPolicyForm.getRawValue();
    const FirstWeek1 = value.FirstWeek1;
    const FirstWeek2 = value.FirstWeek2;
    if (FirstWeek2 === FirstWeek1) {
      this.logHelperService.logError({
        message: 'This Day Alredy Selected'
      });
      this.weekoffPolicyForm.controls['FirstWeek2'].setValue(null);
    }
  }

  onWeekoffChange1() {
    const value = this.weekoffPolicyForm.getRawValue();
    const SecoundWeek1 = value.SecoundWeek1;
    const SecoundWeek2 = value.SecoundWeek2;
    if (SecoundWeek2 === SecoundWeek1) {
      this.logHelperService.logError({
        message: 'This Day Alredy Selected'
      });
      this.weekoffPolicyForm.controls['SecoundWeek2'].setValue(null);
    }
  }

  onWeekoffChange2() {
    const value = this.weekoffPolicyForm.getRawValue();
    const ThirdWeek1 = value.ThirdWeek1;
    const ThirdWeek2 = value.ThirdWeek2;
    if (ThirdWeek2 === ThirdWeek1) {
      this.logHelperService.logError({
        message: 'This Day Alredy Selected'
      });
      this.weekoffPolicyForm.controls['ThirdWeek2'].setValue(null);
    }
  }

  onWeekoffChange3() {
    const value = this.weekoffPolicyForm.getRawValue();
    const FourthWeek1 = value.FourthWeek1;
    const FourthWeek2 = value.FourthWeek2;
    if (FourthWeek2 === FourthWeek1) {
      this.logHelperService.logError({
        message: 'This Day Alredy Selected'
      });
      this.weekoffPolicyForm.controls['FourthWeek2'].setValue(null);
    }
  }

  onWeekoffChange4() {
    const value = this.weekoffPolicyForm.getRawValue();
    const FifthWeek1 = value.FifthWeek1;
    const FifthWeek2 = value.FifthWeek2;
    if (FifthWeek2 === FifthWeek1) {
      this.logHelperService.logError({
        message: 'This Day Alredy Selected'
      });
      this.weekoffPolicyForm.controls['FifthWeek2'].setValue(null);
    }
  }

  public getPolicyNumber(formGroup: FormGroup, policyType: string): void {
    const plantValue = formGroup.get('Plant_Code').value;
    if (plantValue) {
      const requestObj = {
        StatementType: policyType,
        Plant_code: plantValue,
        User_Id: GLOBAL.USER_ID
      }
      this.policyService.getPolicyNumber(requestObj).subscribe((res: any) => {
        formGroup.get('ShortCode').setValue(res && res.length ? res[0].ShortCode : '');
      });
    } else {
      formGroup.get('ShortCode').setValue('');
    }
  }

  public getPolicyHistory(policyCode: string, plantValue: string, serialNo: string, policyType: string): void {
    this.formGridData = [];
    this.formGridColumnDefs = [];

    const requestObj = {
      StatementType: policyType,
      // Policy_Code: policyCode,
      Policy_Code: 0,
      Plant_code: plantValue,
      PolicySerialNo: serialNo,
      User_Id: GLOBAL.USER_ID
    }
    this.policyService.getPolicyHistory(requestObj).subscribe((res: any) => {
      this.formGridData = res;
      this.formGridColumnDefs = this.setFormGridColumnDef(res);
    });
  }

  private setFormGridColumnDef(list: any[]): any[] {
    const columns = [];
    if (list && list.length) {
      const firstObj = list[0];
      Object.keys(firstObj).forEach((item: any) => {
        columns.push({
          headerName: item, field: item, sortable: true, width: 40, filter: true,
          cellStyle: { textAlign: 'center' }, hide: false,
        });
      });
    }
    return columns;
  }

  private groupValidator(group: AbstractControl): ValidationErrors | null {
    const fromCtrlValue = group.get('ApplicableDate').value;
    const toCtrlValue = group.get('ValiduptoDate').value;
    if (!fromCtrlValue || !toCtrlValue) {
      return null;
    }

    return new Date(fromCtrlValue) > new Date(toCtrlValue) ? { message: 'Applicable Date should be less then Valid upto Date.' } : null;
  }

  private createDynamicColumnDef(data: any[]): any[] {
    const columns: any[] = [];
    if (data && data.length) {
      Object.keys(data[0]).forEach((item: any) => {
        columns.push({
          headerName: item,
          headerClass: 'text-left',
          field: item,
          sortable: true,
          width: 70,
          filter: true
        });
      });

      columns.push({
        headerName: 'Action', field: '', width: 40, headerComponentParams: { enableSorting: false },
        cellStyle: { textAlign: 'center' },
        cellRendererFramework: AgActionCellRendererComponent
      });
    }

    return columns;
  }

}
