import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-slip-print',
  templateUrl: './pay-slip-print.component.html',
  styleUrls: ['./pay-slip-print.component.css']
})
export class PaySlipPrintComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
